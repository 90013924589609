import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-table-attribute',
  templateUrl: './table-attribute.component.html',
  styleUrls: ['./table-attribute.component.scss','../../cart-button/cart-button.component.scss'],
})


export class TableAttributeComponent implements OnInit {
  @Input() product_detail;
  constructor(public db:DbService) { }

  ngOnInit() {}

  check_active(item,option){
    // console.log("check active,",item,option)
    if(item.ids){
      let v = item.ids.split('\n');
        return  v.includes(option.name)
    } else {
      if(option.is_pre_selected == 1){
        return option.is_pre_selected == 1
      }
    }
   
  }

  check_table_radio(item,options){
    let temp =''
  
    if(item.ids){
      let v = item.ids.split('\n');
      options.map(res =>{
          v.map(value =>{
              if(value == res.name){
                  temp =  res.name
                  // console.log(res)
              }
          })
      })
    } else {
      options.map(option =>{
        if(option.is_pre_selected == 1){
          return option.name
        }
      })
    }
    // console.log(temp)
    return temp
  }


    // Check attribute lable
    check_attribute_label(data,item){
      let temp_label ='Select'
      if(data && data.length != 0){
        data.map(res =>{
          if(item.ids){
            let v = item.ids.split('\n');
              if(v.includes(res.name))  {
                temp_label = res.option_value
              }
          } else {
            temp_label = res.option_value
          } 
        })
      }
      return temp_label;
    }

    table_addtocart(data){
        this.product_detail.count = data.count
        this.product_detail.price = data.price
        this.product_detail.ids = data.ids;
        this.product_detail.selected_cart_id = data.selected_cart_id;
        this.product_detail.selected_attribute = data.selected_attribute

          if(data.count != 0){
            this.db.update_cart(this.product_detail,'');
          } else if(data.count == 0) {
            this.db.addtocart(this.product_detail);
          }
    }
    
    
    table_removetocart(data){
        // console.log('data',data)
        this.product_detail.selected_cart_id = data.selected_cart_id;
        this.product_detail.count = data.count
        // if( data.product_attributes && data.product_attributes.length != 0){
        //     this.db.removetocart(data);
        // } else if(data.product_attributes && data.product_attributes.length == 0){
        //   this.removetocart(this.product_detail);
        // }
         this.db.removetocart(this.product_detail);
    }


    

changed_table_dropdown(event,item,attribute){
  item.attribute = this.product_detail.product_attributes[0].attribute
    this.select_attribute(item,attribute,event.detail.value);
  // (click)="item.attribute = product_info.product_attributes[0].attribute" (click)="select_attribute(item,attribute,option.name)"
  }
  
  
  //parent is main attribute single  option  ex:curry cut
  //Value is other attribute option
  select_attribute(parent_attribute,child_attribute,attr_option){ 
    this.product_detail.other_attributes.map(res =>{
        if(res.name == child_attribute.name){
            res.options.map(option=>{
                 option.is_select_parent = parent_attribute;
                if(option.name == attr_option){
                    option.is_pre_selected = 1; 
                } else {
                    option.is_pre_selected = 0;
                }
            })
        }
    })
    // console.log()
    this.get_selected_attributes(parent_attribute.name);
  }
  
  
  
  
  get_selected_attributes(parent_id){
     this.product_detail.product_attributes[0].options.map(res => {
        if(res.name == parent_id){
  
          let ids = ''
          let separate_ids = ''
          let selected_attribute = ''
          let separate_selected_Attribute = ''
  
          this.product_detail.other_attributes.map(res =>{
            res.options.map((option,index)=>{
                if(option.is_pre_selected == 1 && option.is_select_parent && option.is_select_parent != ''){
                    // console.log(option.is_select_parent.name)
                    separate_ids = option.is_select_parent.name+'\n'
                    separate_selected_Attribute = ' <div class="attribute"><span class="attr-title">'+option.is_select_parent.attribute+'</span> : <span>'+option.is_select_parent.option_value+'</span> </div>'
                    ids += option.name+'\n';
                    selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+option.option_value+'</span> </div>'
                }
            })
          })
      
           ids = separate_ids + ids
           selected_attribute = separate_selected_Attribute + selected_attribute
  
           
            res.selected_cart_id = this.check_cart_id(ids);
            res.ids = ids;
            res.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
            res.count = this.check_attribute(res.ids);
  
          var data = {
            "product": this.product_detail.name,
            "attribute_id":  res.ids,
            "cart_qty": this.db.plusCount(res.count),
            "variant_html" : res.selected_attribute
          }
      
          this.db.validate_attributes_stock(data).subscribe(data => {
            // console.log(data)
            if(data.message.status == "Success"){
              res.price = data.message.price,
              res.old_price = data.message.old_price
              res.discount_label = data.message.discount.discount_label
              res.discount_rule = data.message.discount.discount_rule
              res.stock_alert_message = undefined
              this.product_detail.stock_alert_message = undefined
              this.product_detail.stock = data.message.stock
              res.stock = data.message.stock
            }else if(data.message.status == 'Failed'){
              res.price = data.message.price,
              res.old_price = data.message.old_price
              res.stock_alert_message = data.message.message
              this.product_detail.stock_alert_message = data.message.message
              this.product_detail.stock = data.message.stock
              res.stock = data.message.stock ? data.message.stock : 0
            }
          })
  
         
        }
      })
  
  
  
    // console.log(ids);
    // console.log(selected_attribute);
    // this.product_detail.ids = ids;
    // this.product_detail.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
  }
  
  
  
  
  check_cart_id(id){
    let cart_id ='';
    (this.db.marketplace_items && this.db.fl_items).find( (res:any) =>{
      if(res.attribute_ids == id){
        if(res.quantity != 0){
          cart_id = res.name
        }
      }
    })
    return cart_id;
  }

  check_attribute(id){
    var cnt = 0;
    (this.db.marketplace_items && this.db.fl_items).find( (res:any) =>{
      // console.log(id + " ==== " + res.attribute_ids)
      if(res.attribute_ids == id){
        cnt += res.quantity
      }
    })
    return cnt;
  }

  
}
