import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

import { IonContent } from '@ionic/angular';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.scss'],
})
export class Footer2Component implements OnInit {


  @ViewChild(IonContent) private content!: IonContent;
  admin_url;
  // copy_rights__ = false;
  email: any;
  submitted = false;
  constructor(public db: DbService, public router: Router) {
    // this.email = new FormControl('' , [Validators.email,Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
  }

  ngOnInit() {
    // console.log(this.db.footer_info.layout_json)
    // if(localStorage['customerName_vendor'] && this.db.website_settings.enable_multi_vendor == 1){
    //   this.db.customerName_vendor = localStorage['customerName_vendor'];
    //   localStorage['vendor_status'] ? this.db.customer_vendor_status = localStorage['vendor_status'] : '';
    //   localStorage['admin_url'] ?  this.admin_url =  localStorage['admin_url'] : this.admin_url = undefined;
    // } 

  }

  redirect(route) {
    this.router.navigateByUrl('/' + route);
  }
  //   check_Copyrights(item){
  //    if(item.section_name == 'Footer Copyrights'){
  //     this.copy_rights__ =  true;
  //     return true;
  //    }else{
  //     return false;
  //    }
  // }

  save_sub() {
    this.submitted = true;
    if (this.email && this.email != '') {
      let email = this.validateEmail(this.email)
      if(email != null || email != undefined){
        console.log(email);
        let data = { email: this.email }
        this.db.post_subscribe(data).subscribe((res: any) => {
          if (res.message == "Success") {
            // console.log(res)
            this.db.alert_animate.next('Newsletter Subscribed Successfully');
          }
          else
            this.db.alert_animate.next(res.message);
        })
      }else{
        this.db.alert('Enter valid email..!')
      }
    } else {
      this.db.alert('Email is Required..!');
    }
  }


  validateEmail(email: any) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };




  title_with_link1 = [
    {
      title: 'About Us',
      route: '/p/about-us',
    },
    {
      title: 'Terms & Condition',
      route: '/p/terms-condition',
    },
    {
      title: 'Privacy Policy',
      route: '/p/privacy-policy',
    },
    {
      title: 'Cancellation',
      route: 'p/return-policy',
    },
    {
      title: 'Contact Us',
      route: '/contact-us'
    },
    //  {
    //   title : 'Vendor Registration',
    //   route:'/vendor-registration'
    //  }
  ]

  title_with_link2 = [
    {
      title: 'My Profile',
      route: '/my-profile/dashboard',
    },
    {
      title: 'View Cart',
      route: '/my-profile/yourcart',
    },
    {
      title: 'My Wishlist',
      route: '/my-profile/wishlist',
    },
    {
      title: 'My Orders',
      route: '/my-profile/my-orders',
    },
    {
      title: 'Blogs',
      route: '/blog-list',
    },
    {
      title: 'FAQ',
      route: '/p/faqs',
    },
    //{
    //  title: 'Affiliate program',
    //  route:'/affiliate-program',
    //},

    // {
    //   title : this.db.cust_name? 'Logout':'Sign In',
    //   route:'/contact-us'
    //  }

  ]


  navigate(item) {
    // || item.title == 'View Cart' || item.title == 'My Wishlist' 
    if (this.db.cust_name || item.title == "Blogs" || item.title == "Affiliate program") {
      this.router.navigateByUrl(item.route)
      // this.move_top();
    } else {
      this.db.openLogin()
    }
  }


  login_a_logout() {
    this.db.cust_name ? this.db.logout() : this.db.openLogin()
  }

  // move_top(){
  //   this.content.scrollToTop(); 
  // }

}
