
  <!-- <ion-skeleton-text *ngIf="!youtube_id" animated></ion-skeleton-text> -->
  <div class="video-popup">
    <ion-buttons (click)="close_popup()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-buttons>
    <iframe  [src]="sanitize_popup(youtube_id)" frameborder="0" allow="autoplay" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>
  <!-- <div *ngIf="!youtube_id">
    <img src="assets/imgs/2.jpg" alt="">
  </div> -->
  <!-- allow="autoplay"; -->