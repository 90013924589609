import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.page.html',
  styleUrls: ['./product-popup.page.scss'],
})
export class ProductPopupPage implements OnInit {

  @Input() categories;
  constructor(public db:DbService,private modalCtrl:ModalController,public router:Router) { }

  ngOnInit() {
  }

  choosed(category_child){
      this.db.mobile_category.parent = category_child
      this.router.navigateByUrl('/shop/'+category_child.route);
      this.modalCtrl.dismiss();
  }

  choose(){
    this.modalCtrl.dismiss();
  }
}

