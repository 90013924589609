
<ion-header *ngIf="db.ismobile">
  <ion-toolbar  >
    <ion-back-button defaultHref="" (click)="navigation()"  slot="start"></ion-back-button>
    <div class="d__flex search" >
      <!-- <ion-input name="search"  (ionChange)="db.search_location($event)"  placeholder="Search location.."></ion-input> -->
       <input #addresstext class="input" placeholder="Search your location" type="text" [(ngModel)]="autocompleteInput">
    </div>
  </ion-toolbar>
</ion-header>

<div class="location-dropdown-box" *ngIf="!db.ismobile">
      
  <div class="d__flex just-s top__container">
    <h6 class="locat_heading">Choose Your Location</h6>
    <!-- (this.db.location && !this.db.available_location )== undefined ? null  : modalCtrl.dismiss()  -->
    <ion-icon (click)="!db.location_error && db.location ? db.location_popup = false : null" class="close-icon mouse" name="close-outline"></ion-icon>
  </div>

  <h6 class="current_location_wb m-0 fnt-14 d__flex gap-5"  >
    <span class="webkit-text fnt-14" [style.--line]="1" *ngIf="db.location" >{{db.location}} </span>
  </h6>

  <div  class="d__flex location__input" #location_dropdownn>
    <div class="location__search w-100" >
      <input #addresstext class="input" placeholder="Search your location" type="text" [(ngModel)]="autocompleteInput">
      <!-- <ion-input class="fnt-14" placeholder="search location" #addresstext  (ionChange)="db.search_location($event)" (click)="location_dropdown = true" ></ion-input>
      <ul #locations *ngIf="location_dropdown" class="local__locations fnt-12"><li class="webkit-text" [style.--line]="2" *ngFor="let item of db.location_sugesstions | slice:0:20; let i = index" [id]="i+'d'" [class.active_dropdown]="i == active_dropdwon" (click)="db.validate_location(item)" (click)="db.location_sugesstions = []" > {{item.description}}  </li> </ul> -->
    </div>
    <!-- <span> OR </span> -->
     <ion-button fill="clear" class="m-0 location-button-wb ion-text-capitalize" (click)="currentlocation()"> <ion-icon name="locate-outline"></ion-icon> </ion-button>
  </div>

  <div class="border-b"></div>
 
   <div class="top__locations" *ngIf="db.website_settings.location_areas && db.website_settings.location_areas.length != 0 && db.website_settings.enable_zipcode == 1 && db.website_settings.radius_based_validation != 1 && db.website_settings.enable_multi_store != 1">
    <h6 class="locat_heading">Top Locations</h6>
    <div class="avail__locations d__flex gap-10" >
      <div class="avail__locat mouse"  *ngFor="let item of (db.website_settings.location_areas || this.db.area_list)" (click)="db.zipcode_availbility('local',item)" [class.active_area]="zip_code(item.zipcode)" [class.active-area]='item.area +","+item.city+"-"+item.zipcode == db.location'> 
        <p class="m-0 p_area">{{item.area}}</p>
      </div>
    </div>
  </div>

<!-- warning -->
  <p class="m-0 location-error mt-10" *ngIf="db.check()">Sorry! we are not providing service to your location. </p>
  <p  class="m-0 location-error" *ngIf="db.location_info.permission_error && !db.location">Please manually Give permission to access to your location</p>

  <div class="top__locations" *ngIf="db.website_settings && db.website_settings.enable_multi_store == 1">
    <h6 class="locat_heading">Top Locations</h6>
   
 
    <!-- Desktop Breadcrumb -->
    <!-- <ul *ngIf="pincodes && pincodes.length != 0 && false" class="Bread-crumb">
      <li class="d__flex"> <a class="mr-5 mouse" (click)="modalCtrl.dismiss();" routerDirection="forward" >Home</a>    </li>
      <li class="d__flex" > <ion-icon class="mr-5" name="chevron-forward"></ion-icon> <a class="mr-5 mouse" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}" (click)="bread_crumb(selected_city)">{{selected_city}}</a> </li>
    </ul>
-->

 <div>
     <div  class="avail__locations d__flex gap-10" >
      <div class="avail__locat mouse"  *ngFor="let item of db.website_settings.locations" [class.active-area]='item.name == db.location_info.city' (click)="selected_cities(item)" >
        <p class="m-0 p_area">{{item.name}}</p>
        <!--  {{item.city}} - {{item.zipcode}} -->
      </div>
     </div>

    <div *ngIf="pincodes && pincodes.length != 0" class="avail__pincode d__flex gap-10">
      <div class="mouse"  *ngFor="let item of pincodes" (click)="selected_pin(item)"> 
        <p class="m-0 p_area">{{item.area}} - {{item.zipcode}}</p>
      </div>
    </div>
    
    <div *ngIf="" class="avail__pincode d__flex gap-10">
      <div class="mouse"  *ngFor="let item of pincodes" (click)="selected_pin(item)"> 
        <p class="m-0 p_area">{{item.area}} - {{item.zipcode}}</p>
      </div>
    </div>

  </div>
</div>
  <!-- <p class="ion-text-center m-0"  *ngIf="!db.check()">-- OR --</p> -->
  <!-- <p class="m-0 location-error mt-10" *ngIf="db.check()">Sorry! we are not providing service to your location. </p> -->
</div>

<div class="location-container" *ngIf="db.ismobile">
<!-- Warning.. -->

<div *ngIf="db.available_location && false" class="location__warning fnt-12 d__flex">
  <ion-icon name="warning-outline"></ion-icon>
  <p  class="m-0" *ngIf="db.selected_address">Shipping is not available to your location! {{db.location}}</p>
</div>

<div *ngIf="db.website_settings && db.website_settings.enable_multi_store == 1">
  <ion-item button class="fnt-13" *ngFor="let item of db.website_settings.locations" (click)="selected_cities(item)" (click)="db.choose_multistore_location(item)">
    <div>{{item.name}}</div>
  </ion-item>
</div>

<!-- Google map location -->
<div class="search__location" *ngIf="db.location_sugesstions.length != 0 && db.website_settings.enable_map == 0">
  <ion-item button class="fnt-13" *ngFor="let item of db.location_sugesstions | slice:0:50" (click)="db.validate_location(item,'')">
    <!-- <div>{{item.description}}</div> -->
  </ion-item>
</div>


<div class="search__location" *ngIf="db.location_sugesstions.length == 0 && db.website_settings.enable_map == 0 && db.website_settings.enable_multi_store != 1">
  <ion-item button class="fnt-13" *ngFor="let item of db.website_settings.location_areas" (click)="db.zipcode_availbility('local',item)">
    <div>{{item.area}} {{item.city}} - {{item.zipcode}}</div>
  </ion-item>
</div>


  <!-- <div class="inner"></div> -->
<!-- enable_multi_store -->
<!-- db.location_sugesstions.length == 0 && db.website_settings && db.website_settings.enable_multi_store == 0 && false -->
  <div *ngIf="db.website_settings && db.website_settings.enable_map == 1">
    {{db.location_info.address}}
    {{db.available_location}}
    {{db.location_error}}
    <app-maps></app-maps>
  </div>
</div>