<div>
     <!-- Our Team -->
    <div class="main_container align_center {{item.class_name}}" *ngIf="item.section_type == 'Static Section' && item.section_name =='Our Team 1'">
      <div class="creative_header">
        <h1 class="ourteam_header_title title"><span class="fnt-700 title">{{item.title}}</span></h1>
        <div class="ourteam_content subtitle"> 
          {{item.subtitle}}
        </div>
      </div>
      <div class="flex_box ourteam_images">
         <div *ngFor="let data of item.cardimage"  class="ourteam_card">
         <div  class="ourteam_image">
            <img [src]="db.product_img(data.card_image)" alt="">
            <div class="ourteam_details">
               <div class="ourteam_info">
               <div class="ourteam_name">{{data.card_title}}</div>
               <div class="ourteam_role">{{data.designation}}</div> 
            </div>
            </div>
         </div>           
         </div>
      </div>
    </div>

   <div class="main_container align_center {{item.class_name}}" *ngIf="item.section_type == 'Static Section' && item.section_name =='Our Team 2'">
      <div class="creative_header">
        <h1 class="ourteam_header_title"><span class="fnt-700 title">{{item.title}}</span></h1>
        <div class="ourteam_content subtitle">
          {{item.subtitle}}
        </div>
      </div>
      <div class="flex_box ourteam_two_images">
        <div *ngFor="let data of item.cardimage" class="ourteam_card">
          <div class="creative_image">
            <img [src]="db.product_img(data.card_image)" alt="">
            <div class="creative_details">
              <div class="ourteam_name">{{data.card_title}}</div>
              <div class="ourteam_role">{{data.designation}}</div>
              <div class="flex_box creative_icons">
               <!-- <img [src]="db.product_img(data.linkedin)" alt="">
               <img [src]="db.product_img(data.mail)" alt="">
               <img [src]="db.product_img(data.skype)" alt=""> -->
               <ion-icon name="logo-skype"></ion-icon>
               <ion-icon name="logo-linkedin"></ion-icon>
               <ion-icon name="mail-outline"></ion-icon>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main_container align_center" *ngIf="item.section_type == 'Static Section' && item.section_name =='Our Team 3'">
      <div class="flex_box ourteam_two_images">
        <div class="ourteam_two_card">
          <h1 class="ourteam_header_title"> <span class="title">{{item.title}}</span></h1>
          <div class="ourteam_content subtitle">
            {{item.subtitle}}
          </div>
        </div>
        <div *ngFor="let data of item.cardimage" class="ourteam_card">
          <div class="ourteam_two_image">
            <img [src]="db.product_img(data.card_image)" alt="">
            <div class="ourteam_two_details">
              <div class="ourteam_two_name">{{data.card_title}}</div>
              <div class="ourteam_role">{{data.designation}}</div>
              <div class="flex_box creative_icons">
                <ion-icon name="logo-skype"></ion-icon>
                <ion-icon name="logo-linkedin"></ion-icon>
                <ion-icon name="mail-outline"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main_container align_center" *ngIf="item.section_type == 'Static Section' && item.section_name =='Our Team 4'">
      <div class="creative_header">
        <h1 class="ourteam_header_title"> <span class="title">{{item.title}}</span></h1>
        <div class="underline"></div>
      </div>
      <div class="flex_box ourteam_images">
        <div *ngFor="let data of item.cardimage" class="ourteam_three_card">
          <div class="ourteam_three_image">
            <img [src]="db.product_img(data.card_image)" alt="">
          </div>
          <div style="margin-top: 10px;font-family: outfit;">
            <div class="ourteam_three_name">{{data.card_title}}</div>
            <div class="ourteam_three_role">{{data.designation}}</div>
          </div>
        </div>
      </div>
    </div>
<!-- new section by bala -->
   
<!-- Section team2- the creative Team (section-5) -->
<div  *ngIf="item.section_type == 'team-section1' && item.section_name == 'Our Team 5'">
<div class="container main-width">
  <div class="section_title title_width_center text-center">
      <h2 class="fnt-40 fnt_600">{{item.title}} <span class="title1 color_sample">{{item.title1}}</span></h2>
      <h2 class="desc_class desc_width_center fnt-18 fnt_400">{{item.sub_title}}</h2>
  </div>
  <ul class="image-circle d__flex __wrap">
      <li class="content__elements mb-20 d__flex" *ngFor="let data of item.data;let i = index">
          <div class="round_img"> 
            <img src="{{db.product_img(data.image)}}" alt="Team Image"></div>
          <h2 class="image-name fnt-18 fnt_600">{{data.name}}</h2>
          <h2 class="image_tag m-0 fnt-14 fnt_400 desc_color">{{data.tag}}</h2>
      </li>
  </ul>
</div></div>

<!-- Section team2- Our Experts (section-6) -->
<div  *ngIf="item.section_type == 'team-section2' && item.section_name == 'Our Team 6'" >
<div class="container main-width">
  <div class="section_title title_width_center text-center">
      <h2 class="fnt-40 title_border fnt_600">{{item.title}}<span class="title1 color_sample">{{item.title1}}</span></h2>
      <div class="title-border"></div>
  </div>
  <ul class="image-circle juscenter_ d__flex __wrap">
      <li class="content__elements mb-20 d__flex" *ngFor="let data of item.data;let i = index">
          <div class="round_img round_img_"> 
            <img src="{{db.product_img(data.image)}}" alt="Team Image"></div>
          <h2 class="image-name fnt-18 fnt_600">{{data.name}}</h2>
          <h2 class="image_tag m-0 fnt-16 fnt_400 desc_color">{{data.tag}}</h2>
          <h2 class="desc_class team_txt_width text-center fnt-14 fnt_400">{{data.description}}</h2>
      </li>
  </ul>
</div></div>

<!-- Section team2- Key people (section-7) -->
<div  *ngIf="item.section_type == 'team-section3' && item.section_name == 'Our Team 7'">
<div class="container main-width" >
  <div class="section_title mb-40">
      <h2 class="fnt-20 fnt_400 m-0 desc_color side-line">{{item.sub_title}}</h2>
      <h2 class="fnt-38 fnt_500 m-0">{{item.title}}</h2>
  </div>
  <ul class="card_image_block d__flex __wrap">
      <li class="t-profile_image" *ngFor="let data of item.data;let i = index">
          <div class="t-profile__img">
              <img src={{data.image}} alt="Profile Image">
              </div>
          <div class="t-profile__content d__flex text-center">
              <h2 class="fnt-27 fnt_500 mb-20">{{data.name}}</h2>
              <h2 class="t-profile__sub-name m-0 fnt-18 fnt_400">{{data.tag}}</h2>
              <div class="d__flex t-profile__icons fnt-20">
                <!-- <img class="mouse" src="{{db.product_img(data.image)}}" alt="social media icon"> -->
                <ion-icon class="mouse" name="logo-skype"></ion-icon>
                <ion-icon class="mouse" name="logo-linkedin"></ion-icon>
                <ion-icon class="mouse" name="mail"></ion-icon>
              </div>
          </div>
      </li>
  </ul>
</div></div>

<!-- Section team2- creative people behind (section-8) -->
<div  *ngIf="item.section_type == 'Static Section' && item.section_name == 'Our Team 8'">
  <div  class="container main-width sec_gap d__flex">
     <div class="card_title_sections">
      <h2 class="fnt-14 fnt_600  color_sample">{{item.sub_title}}</h2>
      <h2 class="fnt-40 fnt_600">{{item.title}}</h2>
     <div class="card-vision" *ngFor="let data of item.content_title" >
        <h2 class="fnt-20 fnt_600  color_sample">{{data.title}}</h2>
        <h2 class="fnt-16 fnt_500 lh-class desc_class">{{data.sub_title}}</h2>
     </div>
     </div>
     <ul class="card_image_sections d__flex __wrap">
         <li class="card_image__elements mb-20" *ngFor="let data of item.data_list">
             <div class="d__flex img_div mb-20">
             <div class="card_image_img"> 
             <img src="{{db.product_img(data.image)}}" alt="Team Image"></div></div>
             <div class="name_tag">
                 <h2 class="fnt-20 fnt_500 m-0">{{data.name}}</h2>
             <h2 class="fnt-16 fnt_400 m-0 desc_color">{{data.sub_name}}</h2>  </div> 
         </li>
     </ul>
 </div></div>

</div>
