<div class="container">
    <h2 class="fnt-20 myAccount-titles" *ngIf="!db.ismobile">Customer Dashboard</h2>

    <div class="d__flex just-c">
        <div class="order-item">
            <h3>{{db.dashboard_info.today_orders_count ? db.dashboard_info.today_orders_count : 0}}</h3>
            <div>Today Orders</div>
        </div>
        <div class="order-item">
            <h3>{{db.dashboard_info.week_orders_count ? db.dashboard_info.week_orders_count : 0}}</h3>
            <div>This Week Orders</div>
        </div>
        <div class="order-item">
            <h3>{{db.dashboard_info.monthly_count ? db.dashboard_info.monthly_count : 0}}</h3>
            <div>This Month Orders</div>
        </div>
        <div class="order-item">
            <h3>{{db.dashboard_info.all_count ? db.dashboard_info.all_count : 0}}</h3>
            <div>All Time Orders</div>
        </div>
    </div>


    <div>

        <div class="d__flex just-s px-b-10">
            <h2 class="fnt-16">Recent Orders</h2>
            <ion-button class="fnt-13 ion-text-capitalize border-radius-4px" fill="clear" routerLink="/my-profile/my-orders">View All</ion-button>
        </div>
        
        <div>
            <div class="table__data" *ngIf="!db.ismobile ">
                <div class="table__container d__flex table__header">
                    <div>Order Id</div>
                    <div>Order Date</div>
                    <div>Order Status</div>
                    <div>Payment Status</div>
                    <div>Total Price</div>
                    <div>Paid Amount</div>
                    <div>Refund Amount</div>
                    <div>Refund Status</div>
                </div>
                <!--  [ngClass]="item.name == (choosed_order && choosed_order.name)?'active-order':''" (click)="choosed(item)" -->
                <div class="main-item mouse" *ngFor="let item of db.dashboard_info.recent_orders;let i =index" routerLink="/my-profile/order/{{item.name}}">
                    <div class="table__container d__flex table__items">
                        <div>{{item.name}}</div>
                        <div>{{item.order_date | date: 'dd-MMM-yyyy'}}</div>
                        <div><span class="dot" [class.success]="item.status == 'Completed'" [class.shipped]="item.status == 'Shipped'" [class.placed]="item.status == 'Placed'" [class.cancelled]="item.status == 'Cancelled'"></span>{{item.status}}</div>
                        <div><span class="dot" [class.success]="item.payment_status == 'Paid'" [class.pending]="item.payment_status == 'Pending'"></span>{{item.payment_status}}</div>
                        <div class="open_sans__font"> {{item.total_amount | currency:'&#8377;'}}</div>
                        <div class="open_sans__font"> {{item.paid_amount | currency:'&#8377;'}}</div>
                        <div class="open_sans__font"> {{item.refund_amount || 0 | currency:'&#8377;'}}</div>
                        <div><span class="dot" [class.success]="item.refund_status == 'Processed'" [class.pending]="item.refund_status == 'Pending'" [class.no_refund]="item.refund_status === null"></span>  {{item.refund_status ? item.refund_status : 'NA'}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>