<div class="menu__content">
  <div class="inner__menu__content">
    <div class="d__flex user">
      <ion-icon class="menu-user" slot="start" src="assets/imgs/favi_farmlink.svg"></ion-icon>
    <div class="store_selection mouse">
      <!-- <div class="market_change d__flex just-s" routerLink="{{!db.cust_email && !db.cust_name ? '/location' : '/tabs/home'}}" (click)="menuCtrl.close()"> -->
      <div class="market_change d__flex just-s" (click)="select_center()">
       <div>
          <h6 class="m-0 fnt-11">{{db.center_name ? db.center_name : 'Centre'}}</h6>
          <div class="store_ position-relative  fnt-13 webkit-text" [style.--line]="1">{{ db.replace_txt(db.store_name)}} <span *ngIf="!db.cust_email && !db.cust_name && db.center_name && false" class="fnt-12 fnt-600">/ {{db.center_name}}</span></div>
       </div>

       <ion-icon class="fnt-12" slot="end" name="chevron-down-outline"></ion-icon>
       
      </div>
    </div>
     <!--  <div><ion-icon class="menu-user" slot="start" name="person-circle-outline"></ion-icon></div>
      <div class="user-title">
        <p *ngIf="!db.cust_email && !db.cust_name">Hi,</p>
        <p *ngIf="db.cust_name || db.guest_cust_name" class="m-0">{{db.cust_name || db.guest_cust_name}}</p>
      </div> -->
    </div>
    <div *ngIf="show_center || (!db.cust_email && !db.cust_name)" class="store_list">
      <!-- <ion-menu-toggle *ngFor="let item of store_settings">   -->
        <!-- <ion-item button *ngIf="item.enable == 1" lines="none" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
           <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon>  ->
          <div class="d__flex store_n" >
            <ion-checkbox [checked]="get_store_value(item)" slot="start"></ion-checkbox>
            <ion-label>{{item.display_name}}</ion-label>
          </div>
          <ion-icon slot="end" class="" name="chevron-forward-outline"></ion-icon>
        </ion-item> -->
        <ion-menu-toggle>
        <ion-radio-group class="store_selections" [value]="db.store_name" (ionChange)="store_select($event)" >
            <ion-item lines="none" *ngFor="let item of store_settings" [class.active__radio__option]="db.store_name == item.title" >
              <ion-radio slot="start" [value]="item.title"></ion-radio>
              <div>
                <p class="m-0 fnt-13" > {{item.display_name}}</p> 
              </div>
              <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
            </ion-item>
          </ion-radio-group>
      </ion-menu-toggle>
    </div>

    <div class="background-white">
        <div *ngFor="let item of menu">

          <ion-menu-toggle >  
            <ion-item button *ngIf="item.enable == 1 && item.title !='Policies' && item.title !='Market Place Settings' && item.title !='Altlyfes' && item.title !='Shop'" lines="none" [routerLink]="item.route" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
              <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
              <ion-label>{{item.title}}</ion-label>
              <!-- <ion-icon (click)="show_more()" *ngIf="db.ismobile && item.title =='Policies'" [name]="!showmore1?'add-outline':'remove-outline'"></ion-icon> -->
            </ion-item>
          </ion-menu-toggle>

        <div *ngIf="item.title =='Altlyfes'" routerDirection="forward" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
          <div  class="">
            <ion-menu-toggle *ngFor="let item of altlyfes" >  
              <ion-item button *ngIf="item.enable == 1" lines="none" (click)="db.route_url(item.route)" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
                <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
                <ion-label >{{item.title}}</ion-label>
                <!-- <ion-icon (click)="show_more()" *ngIf="db.ismobile && item.title =='Policies'" [name]="!showmore1?'add-outline':'remove-outline'"></ion-icon> -->
              </ion-item>
            </ion-menu-toggle>
          </div>
          
        </div>  

        <div *ngIf="item.title =='Shop'" (click)="showmore3 = !showmore3" routerDirection="forward" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
          <!--   -->
          <ion-item button lines="none" routerDirection="forward" [class.menu_list_title]="showmore3" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
            <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
            <ion-label class="ml-16">{{item.title}}</ion-label>
            <ion-icon *ngIf="db.ismobile && item.title =='Shop'" [name]="showmore3 ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          </ion-item>
          <!--  -->
          <div *ngIf="showmore3" class="policy" [class.bg_light]="showmore3">
            <ion-menu-toggle *ngFor="let item of shop" >  
              <ion-item button *ngIf="item.enable == 1" lines="none" [routerLink]="item.route" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
                <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
                <ion-label>{{item.title}}</ion-label>
                <!-- <ion-icon (click)="show_more()" *ngIf="db.ismobile && item.title =='Policies'" [name]="!showmore1?'add-outline':'remove-outline'"></ion-icon> -->
              </ion-item>
            </ion-menu-toggle>
          </div>
        </div>
        
        <div *ngIf="item.title =='Sell With Us'" routerDirection="forward" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
          <ion-item (click)="showmore4 = !showmore4" [class.menu_list_title]="showmore4" lines="none" routerDirection="forward" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
            <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
            <ion-label class="ml-16">{{item.title}}</ion-label>
            <ion-icon *ngIf="db.ismobile && item.title =='Sell With Us'" [name]="showmore4 ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          </ion-item>
          <div *ngIf="showmore4" class="policy" [class.bg_light]="showmore4">
            <div *ngFor="let item of Sell_with_us">
              <ion-menu-toggle *ngIf="item.title !='Seller Community'">  
                <ion-item *ngIf="item.enable == 1" lines="none" (click)="db.route_url(item.route)" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
                  <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
                  <!-- <ion-icon class="menu-icon" name="remove-outline"></ion-icon> -->
                  <ion-label>{{item.title}}</ion-label>
                </ion-item>
              </ion-menu-toggle>
              <div *ngIf="item.title =='Seller Community'" (click)="showmore5 = !showmore5" [class.bg_light]="showmore5">
                <ion-item  lines="none" [class.menu_list_title]="showmore5">
                  <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
                  <ion-label class="ml-16">{{item.title}}</ion-label>
                  <ion-icon *ngIf="db.ismobile && item.title =='Seller Community'" [name]="showmore5 ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
                </ion-item>
                <div *ngIf="showmore5" class="policy" [class.bg_light]="showmore5">
                  <ion-menu-toggle *ngFor="let item of seller_community">  
                    <ion-item *ngIf="item.enable == 1" lines="none" [routerLink]="item.route" routerDirection="back" routerLinkActive="active-item" >
                      <!-- [routerLinkActiveOptions]="{exact :true}" -->
                      <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
                      <!-- <ion-icon class="menu-icon" name="remove-outline"></ion-icon> -->
                      <ion-label>{{item.title}}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </div>
              </div> 
            </div>
          </div>
        </div>

        <!-- <div *ngIf="item.title =='Market Place Settings'" (click)="showmore6 =! showmore6" routerDirection="forward" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
     
          <ion-item button lines="none" routerDirection="forward" [class.menu_list_title]="showmore6" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
            <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
            <ion-label class="ml-16">{{item.title}}</ion-label>
            <ion-icon *ngIf="db.ismobile && item.title =='Market Place Settings'" [name]="showmore6 ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          </ion-item>
 
          <div *ngIf="showmore6" class="policy" [class.bg_light]="showmore6">
            <ion-menu-toggle *ngFor="let item of store_settings" (click)="store_select(item.title)">  
              <ion-item button *ngIf="item.enable == 1" lines="none" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
                <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
                <ion-label>{{item.title}}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </div> -->

          <ion-item button lines="none" *ngIf="item.title =='Policies'" [class.menu_list_title]="showmore1" (click)="item.market_place = false" (click)="showmore1 = !showmore1" routerDirection="forward" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
            <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
            <ion-label>{{item.title}}</ion-label>
            <ion-icon *ngIf="db.ismobile && item.title =='Policies'" [name]="showmore1 ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          </ion-item>

        </div> 

        <div *ngIf="showmore1" class="policy" [class.bg_light]="showmore1">
          <ion-menu-toggle *ngFor="let item of policy" >  
            <ion-item button *ngIf="item.enable == 1" lines="none" [routerLink]="item.route" routerDirection="back" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact :true}">
              <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
              <ion-label>{{item.title}}</ion-label>
              <!-- <ion-icon (click)="show_more()" *ngIf="db.ismobile && item.title =='Policies'" [name]="!showmore1?'add-outline':'remove-outline'"></ion-icon> -->
            </ion-item>
          </ion-menu-toggle>
        </div>

        <!--  -->
        <ion-menu-toggle>
          <ion-item *ngIf="db.cust_name || db.guest_cust_name" class="logout_" lines="none" (click)="db.logout()">
            <ion-icon class="menu-icon" src="/assets/icon/logout.svg"></ion-icon> 
            <ion-label>Logout</ion-label>
          </ion-item>
        </ion-menu-toggle>
        
        <ion-menu-toggle>
          <ion-item *ngIf="!db.cust_name && !db.guest_cust_name" class="logout_" lines="none" (click)="db.registerform = false" routerLink="/register"  routerDirection="back">
            <ion-icon class="menu-icon" src="/assets/icon/login.svg"></ion-icon> 
            <ion-label>Login</ion-label>
          </ion-item>
        </ion-menu-toggle>
        
    </div>  
    <div *ngIf="db.app_version" class="txt-c">
      <p class="app_version">App Version {{db.app_version}}</p>
    </div>
    <div class="d__flex just-end social__icons" *ngIf="db.website_settings && db.website_settings.app_settings && false" >
      <div class="ion-text-center">
        <p class="m-0 fnt-13">Connect with us</p>
        <div class="d__flex just-end gap-10 p-10">
          <a href="{{db.website_settings.app_settings.facebook_link}}" target="blank"><ion-icon src="/assets/imgs/facebook.svg"></ion-icon></a>
          <a href="{{db.website_settings.app_settings.instagram_link}}"><ion-icon src="/assets/imgs/instagram.svg"></ion-icon></a>
          <a href="{{db.website_settings.app_settings.twitter_link}}"><ion-icon src="/assets/imgs/twitter.svg"></ion-icon></a>
        </div>
      </div>
    </div>
  </div>            
</div>