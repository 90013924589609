<form class="p-10" [formGroup]="loginform" >
  <div class="input-item">
      <label  class="input__title">Email Address</label>
      <div class="custom-input d__flex" [ngClass]="focus.foucs1?'focused':''">
        <ion-icon *ngIf="!affiliated" name="mail-outline"></ion-icon>
        <ion-input (ionFocus)="focus.foucs1 = true" (ionBlur)="focus.foucs1 = false"  type="text" placeholder="Enter the Email" name="username" formControlName="username"> </ion-input>
      </div>
      <div *ngIf="submitted && !username?.valid"> 
        <p class="error" *ngIf="username.errors.required">Email-Id is required</p> 
        <p class="m-0 error" *ngIf="username.errors.pattern" >Email-Id is not valid</p>
        <!-- <p class="error" *ngIf="username.errors.email">Email-Id is not valid</p>   -->
      </div>
  </div>
  <div class="input-item">
     <!-- [class.fnt-20]="show_pass"  -->
      <label  class="input__title">Password</label>
      <div class="custom-input d__flex position-relative" [ngClass]="focus.foucs2?'focused':''">
        <div class="d__flex flex-85">
          <ion-icon *ngIf="!affiliated" class="mouse" (click)="show_pass = !show_pass" [name]="(show_pass)?'lock-closed-outline':'lock-open-outline'"></ion-icon>
          <ion-input [ngClass]="show_pass && password.valid ? 'fnt-20':''" (ionFocus)="focus.foucs2 = true" (ionBlur)="focus.foucs2 = false" [type]="(show_pass)?'password':'text'" (keyup.enter)="login()" placeholder="Enter the Password" name="password" formControlName="password"> </ion-input>
        </div>
        <ion-icon class="eye-icon" (click)="show_pass = !show_pass" [name]="show_pass? 'eye':'eye-off'"></ion-icon>
      </div>
      <p class="m-0 error" *ngIf="submitted && !password.valid && password.errors.required"> Password is required </p>
  </div>

  <div class="d__flex just-s for_remember">
    <div class="d__flex mouse">
      <ion-checkbox [checked]="remember_me_check" (ionChange)="remember_me($event)"  slot="start"></ion-checkbox>
      <ion-label class="ml-10 fnt-14" (click)="remember_me_check =! remember_me_check">Remember me</ion-label>
      <!-- <ion-checkbox [checked]="remember_me" (ionChange)="remember($event)"  slot="start"></ion-checkbox>
      <ion-label class="ml-10 fnt-14" (click)="remember_me =! remember_me">Remember me</ion-label> -->
    </div>

    <div class="forget fnt-13 ion-text-right" (click)="db.otp_form = true" (click)="forgot()">
      <span class="m-o fnt-14 mouse alternative">Forgot password?</span>
    </div>
  </div>

  
</form>
<div class="log-button ion-text-center">
  <ion-button expand="full" class="action-button" [ngClass]="shake?'shaking':''" fill="clear" (click)="login()">Sign In</ion-button>
  <p class="m-0 error" *ngIf="alert_message">{{alert_message}}</p>
</div>