
  <div class="Home_header">
    <div class="d__flex just-s">
      <div>
        <img class="home__logo mouse"  src="assets/imgs/white-logo.png" alt="">
      </div>
      <div  class="default_header desktop__header  d__flex" >
        <div  class="drop__down__header" *ngFor="let product of db.header_data.menus.slice(0,7)" (mouseenter)="product.show = true" (mouseleave)="product.show = false">
          <a  class="ion-text-center just-c d__flex main__category mouse" [routerLink]="product.redirect_url"  routerDirection="back" >
            <div *ngIf="product.menu_label" ><p class="m-0 text__color" [ngClass]="db.content_video?'white Text-color':'black '" [class.web_header]="web_header == true" [class.menu__text]="db.video_header">{{product.menu_text || product.menu_label}}</p></div>
          </a>
    
         <div *ngIf="product.show && product.child_menu.length != 0" class="sub-categoryy">
    
            <div #child_productdown class="childs first-level" [class.not__child]="product.separate_drop_down"  *ngFor="let item of product.child_menu"  (mouseenter)="item.show = true" (mouseleave)="item.show = false">
              <a class="d__flex sub__category mouse p-5 gap-10 just-s" (click)="product.show = false"  routerLink="/{{item.redirect_url}}">
                  <p class="m-0 " >{{item.menu_label}}</p><ion-icon name="chevron-forward-outline"></ion-icon>
              </a>
    
              
              <div  class="sub-child sub__children" *ngIf="item.show && item.child_menu.length != 0"  (mouseleave)="product.show = false && item.show = false" >
                <div button *ngFor="let sub_child of item.child_menu">
                  <a  class="d__flex sub__category mouse p-5 gap-10 just-s" routerLink="/{{sub_child.redirect_url}}" (click)="product.show = false && item.show = false" >
                    <p class="m-0 " > {{sub_child.menu_label}}</p>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                  </a>
                </div>
              </div>
    
            </div>
    
         </div>
        </div>
        <div *ngIf="db.header_info.enable_wishlist == 0 || db.header_info.enable_cart == 0 || db.header_info.enable_account_menu == 0" class="d__flex menu__buttons">
    
          <div *ngIf="db.header_info.enable_account_menu == 1"  #dropdown2 class="custom-button mouse ion-text-right position-relative"  (mouseenter)="(db.cust_name || db.guest_cust_name)?drop_down2 = true:null" (click)="db.openLogin()" (mouseleave)="drop_down2 = false">
    
            <div class="d__column" (click)="(db.cust_name || db.guest_cust_name) ? drop_down2 = !drop_down2:null">
              <!-- <ion-icon class="person-icon" name="person-outline"></ion-icon> -->
              <img class="icon" *ngIf="db.website_settings && db.website_settings.app_settings" src="assets/imgs/user-white.svg" alt=""> 
              <!-- <span [ngClass]="db.video_header?'white':'black'" class="webkit-text" [style.--line]="1">{{(db.cust_name || db.guest_cust_name) ? (db.cust_name || db.guest_cust_name):'Sign In'}}</span> -->
            </div>
    
            <div *ngIf="drop_down2 && (db.cust_name || db.guest_cust_name)"  class="dropdown-box">
              <ion-item button   *ngFor="let item of _menu" (click)="drop_down2 = false" [routerLink]="'/'+item.route" lines="none"> {{item.title}}</ion-item>
              <!-- <ion-item button  lines="none" (click)="drop_down2 = false" (click)="cart_pop.emit('wishcart')">  Wishlist</ion-item> -->
              <ion-item button  lines="none" (click)="drop_down2 = false" (click)="db.logout()"> Logout  </ion-item>
            </div>
    
          </div>
    
        </div>
    
      </div>
    </div>
      
  </div>