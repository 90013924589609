

<!-- [class.category_products]="type=='Category Products'" -->

<div class='relative' [class.horizantal_web]='(l_horizontal_image ||r_horizontal_image)'>

<!-- <div *ngIf="l_horizontal_image && !db.ismobile" [class]="l_horizontal_image && !db.ismobile ? 'h_img' :''"  [ngStyle]="img_style(bg_horizontal_image,'bg-image')">
    <img src="{{db.product_img(l_horizontal_image)}}" defaultImage="" alt="">
 </div> -->
 <!-- [ngStyle]="img_style(db.ismobile ? l_horizontal_image || r_horizontal_image:'','full-bg-img')" -->

<div #scrollContent   class='container' (scroll)="scrolled($event)" [ngClass]="scrollable ?'scroll__grids':'normal__grids'" *ngIf="background_tile == undefined" >
  <div *ngIf="arrow && left_arrow" [class]="l_horizontal_image ? 'l_back-s': 'back-s'" (click)="emitScroll('scroll','left',l_horizontal_image || r_horizontal_image)"><ion-icon name="chevron-back-outline"></ion-icon></div>
  

  <img class="l_r_horizontal_image" *ngIf="(l_horizontal_image ||r_horizontal_image) && db.ismobile" src="{{db.product_img(l_horizontal_image || r_horizontal_image)}}" defaultImage="" alt="">

  <div [class]="(l_horizontal_image || r_horizontal_image) ? 'grid__item_with_hor mouse item' :'mouse item grid__item'" [class.viewall]="viewall" [class.search__item]="search" *ngFor="let item of data">
    <!-- Image -->
    <div class="image img-wrapper" [ngClass]="search?'search':''" [routerLink]="true?'/pr/'+item.route:null">
        <img *ngIf="item.product_image || item.image" src="{{db.product_img(item.product_image || item.image)}}" defaultImage="" alt="">
        <span class="m-0" class="discount" *ngIf="item.discount_percentage">{{item.discount_percentage}}% Off</span>
        <p *ngIf="item.inventory_method == 'Track Inventory' && item.stock == 0 " class="no-stock">No stock</p>
    </div>
    
    <div class="heartt mouse">
      <!-- <ion-icon class="heart mouse"  (click)="(item.wish_count != 1)?addtowish(item,1):removewish(item, 0)" [src]="item.wish_count && (item.wish_count != 0)? '/assets/decimall-icon/wish-fill.svg':'/assets/decimall-icon/wish-line.svg'" [class.active-heart]="item.wish_count != 0" ></ion-icon> -->
      <img class="heart" button *ngIf="db.website_settings && db.website_settings.app_settings" (click)="(item.wish_count != 1)?addtowish(item,1):removewish(item, 0)" [src]="item.wish_count && (item.wish_count != 0) ? db.product_img(db.website_settings.app_settings.list_wishlist_filled) : db.product_img(db.website_settings.app_settings.list_wishlist)" alt=""> 
    </div>

    <!-- Title & Prices  *ngIf="item.short_description"-->
    <div class="description">
      <p class="m-0 cat-title fnt-600 product__name webkit-text" [style.--line]="1" [routerLink]="true?'/pr/'+item.route:null">{{item.item | titlecase}}</p>
      <div  class="sub__names" ><p class="m-0 sub__name webkit-text" [style.--line]="2" *ngIf="item.short_description && db.product_shrotdescription" > {{item.short_description}}  </p></div>
      <div class="star-rating" *ngIf="item.rating && false"> <ion-icon class="star" name="star" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon> </div>

      <div  *ngIf="(item.gross_weight || item.weight) && db.product_weight" class="d__flex weight__specify">
        <div *ngIf="item.gross_weight">Gross Wt:  {{item.gross_weight >= 1000?item.gross_weight/1000:item.gross_weight}}  {{item.gross_weight >= 1000?'Kg':'gms'}} </div>
        <span *ngIf="item.gross_weight"> / </span>
        <div [class.Net]="item.gross_weight" *ngIf="item.weight">Net Wt: {{item.weight >= 1000?item.weight/1000:item.weight}} {{item.weight >= 1000 ?'Kg':'gms'}} </div>
      </div>

      <div class="separator mt-5 d__flex just-s">

        <!-- prices -->
        <div class="prices">
          <p class="s-price l-t cat-price" [class.ml-5]="item.price" *ngIf="item.old_price != '0.00'" >{{item.old_price | currency:db.website_settings.currency}}</p>
          <p class="m-0 price cat-price" *ngIf="item.price != '0.00'" >{{item.price | currency:db.website_settings.currency}}</p>
        </div>

        <app-cart-button [product_info]="item" (addto_cart)="addto_cart.emit($event)" (removeto_cart)="removeto_cart.emit($event)"></app-cart-button>

      </div>

    </div>



  </div>
 
  <!-- <img *ngIf="r_horizontal_image && db.ismobile" src="{{db.product_img(r_horizontal_image)}}" defaultImage="" alt=""> -->

  <div *ngIf="arrow" [class]="r_horizontal_image ? 'r_next-s': 'next-s'" (click)="emitScroll('scroll','right',r_horizontal_image||l_horizontal_image)"><ion-icon name="chevron-forward-outline"></ion-icon></div>
</div>

<!-- <div *ngIf="r_horizontal_image && !db.ismobile" [class]="r_horizontal_image && !db.ismobile ? 'h_img' :''"  [ngStyle]="img_style(bg_horizontal_image,'full-bg-img')">
  <img src="{{db.product_img(r_horizontal_image)}}" defaultImage="" alt="">
</div> -->
</div>
















        <!-- Cart buttons  (click)="(item.count == 0)?add_cart(item,db.plusCount(item.count)):''" ( item.inventory_method == 'Track Inventory' && item.stock != 0) (click)="item.count == 0?addto_cart.emit(item):''"-->
        <!-- <div class="cart-buttons d__flex just-end mb-5" [class.add-cart-buttons]="item.count != 0" >
          <div *ngIf="!db.check_stocks(item)" class="cart__button ion-activatable ripple-parent" [class.added]="item.count == 0" (click)="( item.count == 0 && !(db.check_disabled(item)))?addto_cart.emit(item):''"  [class.disabled]="db.check_disabled(item)">
              <ion-ripple-effect *ngIf="item.count == 0"></ion-ripple-effect>
              <ion-button class="mouse __button" *ngIf="item.count && item.count != 0"  (click)="removeto_cart.emit(item)" fill="clear" ><span>-</span></ion-button>
              <ion-spinner  *ngIf="item.temp_disabled"></ion-spinner>

              <div class="d__flex">
                <ion-icon *ngIf="item.count == 0" src='/assets/districo/add-cart.svg'></ion-icon>
                <span class="add" [class.not__added]="item.count == 0" [class._added]="item.count != 0" *ngIf="!item.temp_disabled" > {{item.count && (item.count != 0)?item.count:'Add to Cart'}} </span>
              </div>
              
              <ion-button class="mouse __button"  *ngIf="item.count && item.count != 0" (click)="true?addto_cart.emit(item):''" [disabled]="db.check_disabled(item)" fill="clear" ><span>+</span></ion-button>
          </div>
          <ion-button *ngIf="db.check_stocks(item)" (click)="db.notify_me(item)"  class="notify_me" fill="clear">Notify Me</ion-button>
        </div> -->


            <!-- Cart buttons
    <div class="cart-buttons mb-10" >
      <div class="button"  (click)="(item.count == 0)?add_cart(item,db.plusCount(item.count)):''" >
        <div *ngIf="item && item.count && (item.count != 0)" class="btn mouse" (click)="remove_cart(item, db.removeCount(item.count))">
          <ion-icon  name="remove" ></ion-icon>
        </div>
        <span class="add"> {{(item && item.count && (item.count != 0))?item.count:'Add'}}</span>
        <div class="mouse btn" (click)="(item.count != 0)?add_cart(item,db.plusCount(item.count)):''">
          <ion-icon name="add" ></ion-icon>
        </div>
      </div>
    </div> -->


        <!-- <div class="cart-buttons d__flex just-end" >
          <div *ngIf="!db.check_stocks(item)" class="cart__button ion-activatable ripple-parent" [class.added]="item.count == 0" (click)="( item.count == 0 && !(db.check_disabled(item)))?addto_cart.emit(item):''"  [class.disabled]="db.check_disabled(item)">
              <ion-ripple-effect *ngIf="item.count == 0"></ion-ripple-effect>
              <ion-button class="mouse __button" *ngIf="item.count && item.count != 0"  (click)="removeto_cart.emit(item)" fill="clear" ><span>-</span></ion-button>
              <ion-spinner  *ngIf="item.temp_disabled"></ion-spinner>
            
           <div class="add_button d__flex">
              <ion-icon *ngIf="item.count == 0" src="/assets/decimall-icon/add-cart.svg"></ion-icon> 
              <span class="add" [class.not__added]="item.count == 0" [class._added]="item.count != 0" *ngIf="!item.temp_disabled" >{{item.count && (item.count != 0)?item.count:'ADD'}} </span>
            </div> 
              
              <ion-button class="mouse __button"  *ngIf="item.count && item.count != 0" (click)="true?addto_cart.emit(item):''" [disabled]="db.check_disabled(item)" fill="clear" ><span>+</span></ion-button>
          </div>
          <ion-button *ngIf="db.check_stocks(item)" (click)="db.notify_me(item)"  class="notify_me" fill="clear">Notify Me</ion-button>
        </div> -->
