<ion-content class="bg">
  <div *ngIf="categories">
    <div class="category-head">
      <ion-text>Categories</ion-text>
      <ion-icon (click)="choose()" name="close-outline"></ion-icon>
    </div>
    <div class="item_con" button *ngFor="let item of categories" detail (click)="choosed(item)">
      <a *ngIf="item.products_count" class="d__flex mouse child__1 gap-10 category-list" [routerLink]="db.ismobile  ? '/shop/'+item.route : null">
        <div class="category__img" *ngIf="db.ismobile"> <img [src]="db.product_imgs(item.mobile_image)"></div>
        <p class="m-0 category__name_child1">{{item.category_name}}</p>
        <div class="count-c d__flex">
          <p class="m-0" *ngIf="db.ismobile && item.products_count"><small>{{item.products_count}} Items</small></p>
          <ion-icon name='chevron-forward-outline'></ion-icon>
        </div>
      </a>
    </div>
  </div>
</ion-content>
