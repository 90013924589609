

<form class="p-10" [formGroup]="register_form">
  <div class="input-item">
    <label  class="input__title" *ngIf="labels">{{db.website_settings && db.website_settings.account_settings.enable_lastname == 1 ? 'First Name':'Name'}}</label>
    <div class="custom-input d__flex" [ngClass]="focus.foucs1?'focused':''">
      <ion-icon *ngIf="!affiliated" name="person-outline"></ion-icon>
      <ion-input (ionFocus)="focus.foucs1 = true" (ionBlur)="focus.foucs1 = false" type="text" placeholder="{{db.website_settings && db.website_settings.account_settings.enable_lastname == 1 ? 'First Name':'Name'}}" name="first_name" formControlName="first_name" [(ngModel)]="register_data.first_name"> </ion-input>
    </div>
    <p class="m-0 error" *ngIf="submitted && !first_name.valid && first_name.errors.required"> First Name is required </p>
  </div>
  <div class="input-item" *ngIf="db.website_settings && db.website_settings.account_settings.enable_lastname == 1">
      <label  class="input__title" *ngIf="labels">Last Name</label>
      <div class="custom-input d__flex" [ngClass]="focus.foucs2?'focused':''">
        <ion-icon *ngIf="!affiliated" name="person-outline"></ion-icon>
        <ion-input (ionFocus)="focus.foucs2 = true" (ionBlur)="focus.foucs2 = false" type="text" placeholder="Lastname" name="last_name" formControlName="last_name"  [(ngModel)]="register_data.last_name"></ion-input>
      </div>
      <p class="m-0 error" *ngIf="submitted && !last_name.valid && last_name.errors.required" > Last Name is required </p>
  </div>
  <div class="input-item" >
      <label  class="input__title"  *ngIf="labels">Email</label>
      <div class="custom-input d__flex" [ngClass]="focus.foucs3?'focused':''">
        <ion-icon *ngIf="!affiliated" name="mail-outline"></ion-icon>
        <ion-input (ionFocus)="focus.foucs3 = true" (ionBlur)="focus.foucs3 = false" type="text" placeholder="Email" name="email" formControlName="email"  [(ngModel)]="register_data.email"> </ion-input>
      </div>
      <p class="m-0 error" *ngIf="submitted && !email.valid && email.errors.required" > Email-Id is required </p>
      <p class="m-0 error" *ngIf="submitted && !email.valid && email.errors.email" > Not a valid Email </p>
  </div>

  <div class="input-item">
    <div class="d__flex">
      <label  class="input__title" *ngIf="labels">Password </label>
      <div *ngIf="db.website_settings.password_validation" class="alert__">
        <div class="p_alert mouse" (mouseenter)="db.password_alert = true" (mouseleave)="db.password_alert = false">
          <img src="/assets/icon/help.svg">
        </div>
        <div *ngIf="db.password_alert" class="pop_up">
           <p class="m-0 txt fnt-12">Minimum of <span>{{db.website_settings.password_validation.min_password_length}}</span> character and must contain atleast <span>{{db.website_settings.password_validation.password_policy}}</span></p>
        </div>
      </div>
    </div>  
      <div class="custom-input d__flex" [ngClass]="focus.foucs4?'focused':''">
        <ion-icon *ngIf="!affiliated" (click)="show_pass =! show_pass" [name]="(show_pass)?'lock-closed-outline':'lock-open-outline'"></ion-icon>
        <ion-input (ionFocus)="focus.foucs4 = true" (ionBlur)="focus.foucs4 = false" [type]="(show_pass)?'password':'text'" placeholder="Password" name="pwd" formControlName="pwd"  [(ngModel)]="register_data.pwd"> </ion-input>
      </div>
      <p class="m-0 error" *ngIf="submitted && !pwd.valid && pwd.errors.required"> Password is required </p>
  </div>

  <div class="input-item" *ngIf="db.website_settings.enable_referral == 1 && false" >
    <label  class="input__title" *ngIf="labels">Referral</label>
    <div class="custom-input" [ngClass]="focus.foucs7?'focused':''">
      <ion-input (ionFocus)="focus.foucs7 = true" (ionBlur)="focus.foucs7 = false" type="text" placeholder="Referral" name="referral" formControlName="referral"  [(ngModel)]="register_data.referral"> <ion-icon name="person-outline"></ion-icon> </ion-input>
    </div>
    <!-- <p class="m-0 error" *ngIf="submitted && !pwd.valid && pwd.errors.required"> Password is required </p> -->
  </div>

  <div class="input-item" *ngIf="!affiliated">
    <label class="input__title" *ngIf="labels">Mobile Number</label>
    <div class="custom-input d__flex" [ngClass]="focus.foucs5?'focused':''">
      <ion-icon name="call-outline"></ion-icon>
      <ion-input (ionFocus)="focus.foucs5 = true" (ionBlur)="focus.foucs5 = false"  type="tele"  placeholder="Mobile Number" name="phone" formControlName="phone"  [(ngModel)]="register_data.phone"></ion-input>
    </div>
    <div class="error-container">
      <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.required"> mobile number is required </p>  
      <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.pattern" > Not a valid mobile number </p>  
      <!-- <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.pattern.actualValue &&phone.errors.pattern.actualValue == 'undefined'"> mobile number is required </p>
      <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.pattern.actualValue &&phone.errors.pattern.actualValue != 'undefined' && phone.errors.pattern" > Not a valid mobile number </p>   -->
    </div>
  </div>

  <!-- For Affiliated Register -->
  <div class="input-item" *ngIf="affiliated">
    <label  class="input__title" *ngIf="labels">Your Website</label>
    <div class="custom-input d__flex" [ngClass]="focus.foucs2?'focused':''">
      <!-- <ion-icon name="person-outline"></ion-icon> -->
      <ion-input (ionFocus)="focus.foucs2 = true" (ionBlur)="focus.foucs2 = false" type="text" placeholder="Website" name="website" formControlName="website"  [(ngModel)]="register_data.website"></ion-input>
    </div>
    <p class="m-0 error" *ngIf="submitted && !website.valid && website.errors.required"> website is required </p>
 </div>
 <div class="input-item" *ngIf="affiliated">
  <label  class="input__title" *ngIf="labels">How will you promote us?</label>
  <div class="custom-input d__flex" [ngClass]="focus.foucs2?'focused':''">
    <!-- <ion-icon name="person-outline"></ion-icon> -->
    <ion-input (ionFocus)="focus.foucs2 = true" (ionBlur)="focus.foucs2 = false" type="text" placeholder="Promote" name="promote_us" formControlName="promote_us"  [(ngModel)]="register_data.promote_us"></ion-input>
  </div>
  <p class="m-0 error" *ngIf="submitted && !promote_us.valid && promote_us.errors.required"> Promote is required </p>
</div>

<div class="input-item" *ngIf="affiliated">
  <label  class="input__title" *ngIf="labels">How did you hear about us?</label>
  <div class="custom-input d__flex" [ngClass]="focus.foucs2?'focused':''">
    <!-- <ion-icon name="person-outline"></ion-icon> -->
    <ion-input (ionFocus)="focus.foucs2 = true" (ionBlur)="focus.foucs2 = false" type="text" placeholder="about us" name="about_us" formControlName="about_us"  [(ngModel)]="register_data.about_us"></ion-input>
  </div>
  <!-- <p class="m-0 error" *ngIf="submitted && !last_name.valid && last_name.errors.required" > website is required </p> -->
</div>
</form>

<div class="log-button ion-text-center">
  <ion-button   class="action-button" [ngClass]="shake?'shaking':''" expand="full" fill="clear" (click)="!affiliated?register():affiliated_register()">{{!affiliated?'Register':'Join Now'}}</ion-button>
  <p class="m-0 error" *ngIf="alert_message">{{alert_message}}</p>
</div>