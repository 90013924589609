<!-- <ion-header *ngIf="db.ismobile">
  <ion-toolbar>
    <ion-title>make-return</ion-title>
  </ion-toolbar>
</ion-header> -->

<app-header [no_home]="true" [no_cart]="true" back_route="" [title]="'Return Items From Order -'+ order_id">
</app-header>
<ion-content>
  <div class="return" [class.p-10]="!db.ismobile">

    <div *ngIf="!db.ismobile" class="order__items">
      <h2 class="h2 m-0">
        <span>Return Items From Order</span> - {{order_id}}
      </h2>

      <ion-row class="d__flex headings">
        <div class="image_title item_name">Item Name</div>
        <!-- <div class="ion-text-center qty">SKU</div>
        <div class="ion-text-center price">Seller Name</div> -->
        <!-- <div class="ion-text-center div">RETURN</div> -->
        <!-- <div class="ion-text-center div">MRP TOTAL</div> -->
      </ion-row>

      <div class="product_box" *ngFor="let d of  return_products && return_products.eligible_items">
       <!-- *ngIf="this.db.website_settings.return_based_on == 'Delivery Date' ? currentDate < d.delivery_date : currentDate < return_products.order_date" -->
        <ion-row class="d__flex row" >
          
          <div class="d__flex image_title" size="7">
           
            <!-- <div class="image__container">
              <div class="free__item" *ngIf="d.is_free_item == 1">
                <div class="free__item__text fnt-12">FREE</div>
              </div>
              <img [src]="db.product_img(d.item_image)" />
            </div> -->

            <div>
              <div>{{d.item_name}}</div>
              <div class="attribute_desc" [innerHTML]="d.variant_text"></div>
              <p class="m-0 sold_by_d" *ngIf="d.business_name">Sold By : <span class="sold_by">{{d.business_name}}</span></p>
            </div>

          </div>

        <!-- <div class="ion-text-center qty">{{d.sku}}</div> -->
          <!-- <div class="ion-text-center price txt-cap">
            {{d.business_name}}
          </div> -->

          <div class="ion-text-center div" *ngIf="false">
            <div class="cart-buttons mb-10">
              <div class="cart__button   d__flex just-s ion-activatable ripple-parent">
                <ion-ripple-effect *ngIf="d.count == 0"></ion-ripple-effect>
                <ion-button [disabled]="d.count == 0" class="mouse minus_ __button" (click)="remove(d)" fill="clear">
                  <span>-</span></ion-button>
                <ion-spinner *ngIf="d.temp_disabled"></ion-spinner>
                <div class="d__flex">
                  <span class="add" [class.not__added]="d.count == 0" [class._added]="d.count != 0">
                    {{d.count && (d.count != 0)?d.count:'0'}}
                  </span>
                </div>
                <!-- <span class="add" [class.not__added]="item.count == 0" [class._added]="item.count != 0" *ngIf="!item.temp_disabled" > {{item.count && (item.count != 0)?item.count:'ADD'}} </span> -->
                <ion-button class="mouse __button" (click)="true?add(d):''" [disabled]="d.count == d.quantity"
                  fill="clear"><span>+</span></ion-button>
              </div>
            </div>
          </div>

          <!-- <div class="ion-text-center open_sans__font div">
            {{d.amount | currency:'&#8377;'}}
          </div> -->
          <!-- *ngIf="this.db.website_settings.return_based_on == 'Delivery Date' ? currentDate < d.delivery_date : currentDate < return_products.order_date" -->
          <div class="ion-text-center button_return">
            <ion-button fill="clear" (click)="return(d)">Make Return</ion-button>
          </div>

        </ion-row>
      </div>
    </div>

    <div *ngIf="db.ismobile" class="order__items">
      
      <!-- <div class="d__flex just-s heading_">
        <h6 class="h2">ITEM DETAILS</h6>
      </div> -->

      <div class="list_view" *ngFor="let d of return_products && return_products.eligible_items">
        <!-- *ngIf="this.db.website_settings.return_based_on == 'Delivery Date' ? currentDate < d.delivery_date : currentDate < return_products.order_date" -->
        <div class="d__flex gap-10 mb-5 list" >
          <div *ngIf="false" class="image__container__mb">
            <div class="free__item" *ngIf="d.is_free_item == 1">
              <div class="free__item__text">FREE</div>
            </div>
            <img [src]="db.product_img(d.item_image)" />
          </div>
          <div class="info__container_mb">
            <div class="mb-3 medium_name">{{d.item_name}}</div>
            <div class="attribute_desc" [innerHTML]="d.variant_text"></div>
            <p class="m-0 sold_by_d" *ngIf="d.business_name">Sold By : <span class="sold_by">{{d.business_name}}</span></p>
            <!-- <div class="d__flex gap-10">
              <div class="price_mb">
                <p class="m-0 fnt-9">Price</p>
                <div class="fnt-13 open_sans__font">{{d.price | currency:'&#8377;'}}</div>
              </div>
              <div class="qty_mb">
                <p class="m-0 fnt-9">Qty</p>
                <div class="fnt-13 open_sans__font">{{d.quantity}}</div>
              </div> 
              <div class="total_mb">
                <p class="m-0 fnt-9">Amount</p>
                <div class="fnt-13 open_sans__font">{{d.amount | currency:'&#8377;'}}</div>
              </div>
            </div> -->
            <!-- <ion-col size="3" class="ion-text-center">  </ion-col>
            <ion-col size="2" class="ion-text-center">  </ion-col>
            <ion-col size="2" class="ion-text-center">   </ion-col> -->
          </div>
        </div>
        <div class="d__flex make_return">
          <div class="ion-text-center div">
            <div class="cart-buttons d__flex just-c">

              <div class="cart__button ion-activatable ripple-parent d__flex" *ngIf="false">
                <ion-ripple-effect *ngIf="d.count == 0"></ion-ripple-effect>
                <ion-button [disabled]="d.count == 0" class="mouse minus_ __button" (click)="remove(d)" fill="clear">
                  <span>-</span></ion-button>
                <ion-spinner *ngIf="d.temp_disabled"></ion-spinner>
                <div class="d__flex">
                  <span class="add" [class.not__added]="d.count == 0" [class._added]="d.count != 0">
                    {{d.count && (d.count != 0)?d.count:'0'}}
                  </span>
                </div>
                <!-- <span class="add" [class.not__added]="item.count == 0" [class._added]="item.count != 0" *ngIf="!item.temp_disabled" > {{item.count && (item.count != 0)?item.count:'ADD'}} </span> -->
                <ion-button class="mouse plus_ __button" (click)="true?add(d):''" [disabled]="d.count == d.quantity"
                  fill="clear"><span>+</span></ion-button>
              </div>
              
            </div>
          </div>
          <!-- *ngIf="this.db.website_settings.return_based_on == 'Delivery Date' ? currentDate < d.delivery_date : currentDate < return_products.order_date" -->
          <div
            class="ion-text-center button_return">
            <ion-button fill="clear" (click)="return(d)">Make Return</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>