// import { Injectable } from '@angular/core';
// import {
//   ActivatedRouteSnapshot,
//   CanActivate,
//   RouterStateSnapshot,
//   UrlTree,
// } from '@angular/router';

// import { Observable } from 'rxjs';

// import { DbService } from '../services/db.service';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGuardGuard implements CanActivate {
//   constructor(public db:DbService){
//   }
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     // console.log(this.db.checkmobile())
//     return this.db.checkmobile();
//   }

// }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DbService } from '../services/db.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router, public db: DbService) {

    }
      canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // return true;
        if(this.auth.IsLoggedIn()){
          this.auth.get_path();
          return true;
        }
        this.router.navigate(['/login'])
        return false;
      }
    async check_login_details() {
        // localStorage.login_role ?  this.db.login_role = localStorage.login_role : null;
        // console.log(this.db.login_role);
        if (localStorage['CustomerId']) {
            if (localStorage['api_key'] && localStorage['api_secret']) {
                this.router.navigateByUrl('/tabs/home');
            }
        }
        this.auth.get_path();

    }

      
      


}


// export const canActivate = (
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot,
// ) => {
//     // const authService = inject(AuthenticationService);
//     // const router = inject(Router);

//     // if (auth.IsLoggedIn()) {
//     //     auth.get_path();
//     //     return true;
//     // } else {
//     //     // router.navigate(['/login'])
//     //     return false;
//     // }
//     if(localStorage.getItem('api_key')){
//         return true
//     }else{
//         return false
//     }

// };

// export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);

