import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-order-status-notify',
  templateUrl: './order-status-notify.component.html',
  styleUrls: ['./order-status-notify.component.scss'],
})
export class OrderStatusNotifyComponent implements OnInit {
  @Input() order_detail;
  @Input() affiliated;
  @Input() type;
  constructor(public db:DbService,public router:Router) { }

  ngOnInit() {}

  goto(){
    // this.db.ismobile?'/order-detail/ORD-00292':'/my-profile/order/ORD-00292'
    this.router.navigateByUrl(this.db.ismobile?'/':'/')
  }
}
