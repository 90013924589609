<ion-header>
  <ion-toolbar class="header_bg">
    <ion-title>Choose Your Reason</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="moile_locat" *ngIf="db.reason_list && db.reason_list.length != 0">
    <ion-item button lines="none" class="avail__loca mouse" *ngFor="let item of db.reason_list" (click)="select_reason(item)">
      <p class="m-0 p_area fnt-14">{{item.label}}</p>
      <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
    </ion-item>
  </div>
</ion-content>
