<ion-card *ngIf="type =='edit-profile'" class="profile_card edit_profile_card"  [class.mb_profile_card]="type == 'edit-profile'">

  <ul class="my_profile_menu" [class.mb_profile_menu]="type == 'edit-profile'">
    <li *ngFor="let item of profile_menu;let i = index"  class="menu_item mouse"  [class.active_menu]="item.isActive" (click)="check_active(profile_menu,i,item)">
      <h6 class="fnt-inter m-0 menu_name">{{item.name}}</h6>     
   </li>
  </ul>


</ion-card>
