<div *ngIf="!check_out">
  <form class="otp-form p-10" [formGroup]="otp_form">
    <div class="d__flex just-c otp-container">
      <div class="mobile-input d__flex w-100" [class.error_otp]="otp_verify && otp_submit && !otp_.valid && otp_.errors">
        <!-- <ion-icon *ngIf="!otp_verifyy" name="chevron-down-outline"></ion-icon> -->
        <ion-input *ngIf="!otp_verifyy" type="tele" class="otp-input" placeholder="Mobile number"
          formControlName="mobile_no" (keyup.enter)="sendLoginCode()" name="mobile_no" [(ngModel)]="mobile_no"> <span
            > +91 </span></ion-input>
        <ion-icon *ngIf="otp_verifyy" name="call-outline"></ion-icon>
        <ion-input *ngIf="otp_verifyy" type="number" class="otp-input" placeholder="OTP" name="otp" formControlName="otp"
          (keyup.enter)="verify_otp()" [(ngModel)]="otp"> </ion-input>
      </div>
      <div *ngIf="false">
        <ion-button class="mobile-button" [ngClass]="shake?'shaking':''" expand="full" fill="clear"
          (click)="verificationId?verify_otp():sendLoginCode()"><ion-icon
            name="arrow-forward-outline"></ion-icon></ion-button>
      </div>
    </div>
    <div *ngIf="otp_verify" class="error-container">
      <p class="m-0 error" *ngIf="otp_submit && !otp_.valid && otp_.errors">Please enter otp</p>
    </div>
    <div *ngIf="otp_verify" class="resend mouse" (click)="resend()">Resend OTP</div>
    <div class="error-container">
      <p class="m-0 error" *ngIf="otp_submit && !phonenumber.valid && phonenumber.errors.required">Enter Your Mobile
        Number</p>
      <p class="m-0 error" *ngIf="otp_submit && !phonenumber.valid && phonenumber.errors.pattern">Enter a valid Mobile
        Number</p>
    </div>
  </form>
  <div class="log-button ion-text-center">
    <ion-button expand="full" class="action-button" [ngClass]="shake?'shaking':''" fill="clear"
      (click)="verificationId?verify_otp():sendLoginCode()">{{verificationId ? 'Verify OTP':'Send OTP'}}</ion-button>
  </div>

</div>

<div *ngIf="check_out" class="d__flex paymentMethod">
  <ion-button *ngIf="!otp_verify && !db.checkout_cod" class="sendOtp_btn" fill="clear" (click)="sendLoginCode()">Send OTP</ion-button>
  <div *ngIf="otp_verify && !db.checkout_cod" class="d__flex grid">
    <form [formGroup]="otp_form" class="custom-input">
      <ion-input *ngIf="otp_verifyy" type="number" class="otp-input" placeholder="Enter OTP" name="otp"
        formControlName="otp" (keyup.enter)="verify_otp()" [(ngModel)]="otp"> </ion-input>
    </form>
    <div class="d__flex">
      <ion-button fill="clear" (click)="verify_otp()">Verify OTP</ion-button>
      <ion-button fill="clear" (click)="resend()">Resend OTP</ion-button>
    </div>
  </div>
  <!--  -->
  <div *ngIf="db.checkout_cod" class="d__flex verifield_container">
    <ion-icon src="/assets/districo/check-mark.svg"></ion-icon>
    <p class="verifield m-0">Verified</p>
  </div>
</div>

<div>
  <div id="sign-in-button"></div>
</div>