<div class="table__attributes" >
  <div  class="table__attribute mt-10" *ngFor="let item of product_detail.product_attributes[0].options">
      <div >

        <div class="table__header">
          <div class="image__container">
            <div class="attribute__image"><img [src]="db.product_img(item.image_attribute)"></div>
          <div class="mobile_image__container">
            <h6 class="attribute__heading">{{item.option_value}}</h6>
            <div class="d__flex gap-10" *ngIf="item.price"> <h6 class="m-0">{{item.price | currency:db.website_settings.currency}}</h6><p class="m-0 l-t fnt-12">{{item.old_price | currency:db.website_settings.currency}}</p></div>
          
            <!-- web -->
            <div *ngIf="!db.ismobile">
              <div class="attribute__heading_radio" *ngFor="let attribute of product_detail.other_attributes">
              <div  class="attribute__name">{{attribute.attribute}}</div>
                <!-- {{check_table_radio(item,attribute.options)}} -->

              <!-- Radio -->
              <div *ngIf="attribute.control_type == 'Radio Button List'">

                <!-- option.is_selected == 1 && option.is_select_parent.option_value == item.option_value -->
                
                <div class="d__flex flex-w" *ngIf="false">
                  <div [class.active__radio__option]="check_active(item,option)" class="radio__attribute__option mouse d__flex" *ngFor="let option of attribute.options" (click)="item.attribute = product_detail.product_attributes[0].attribute"  (click)="select_attribute(item,attribute,option.name)">
                    <div class="main-dot d__flex just-c"><div class="inner-dot d__flex"> <ion-icon src="/assets/icon/tick.svg"></ion-icon> </div> </div>
                    <div>{{option.option_value}}</div>
                  </div>
                </div>
                                <!-- custom radio  -->
                  <ion-radio-group class="d__flex flex-w gap-10" [value]="check_table_radio(item,attribute.options)">
                    <ion-item lines="none" [class.active__radio__option]="check_active(item,option)" class="radio__attribute__option mouse" *ngFor="let option of attribute.options" (click)="item.attribute = product_detail.product_attributes[0].attribute"  (click)="select_attribute(item,attribute,option.name)">
                      <!-- <div class="main-dot d__flex just-c"> <div class="inner-dot d__flex"> <ion-icon src="/assets/icon/tick.svg"></ion-icon> </div> </div> -->
                      <ion-radio [value]="option.name"></ion-radio><div>{{option.option_value}}</div>
                    </ion-item>
                  </ion-radio-group>
              </div>

              <!-- Drop down list -->
              <div class="attribute__drop__down " *ngIf="attribute.control_type == 'Dropdown List'">
                <!-- <div class="attribute__name">{{attribute.attribute}}</div> -->

                <div class="selected__drop__down" *ngIf="false" >
                  <div class="selected__txt" (click)="item.drop_down = 1"><div>{{check_attribute_label(attribute.options,item)}}</div> <ion-icon name="chevron-down-outline"></ion-icon></div>
                  <div *ngIf="item && item.drop_down && item.drop_down == 1"  (mouseleave)="item.drop_down = 0" class="drop__down__options">
                    <div [class.active__drop__option]="check_active(item,option)" class="drop__down__option mouse" *ngFor="let option of attribute.options" (click)="item.attribute = product_detail.product_attributes[0].attribute" (click)="select_attribute(item,attribute,option.name)">{{option.option_value}}</div>
                  </div>
                </div>

                <!-- (click)="item.attribute = product_info.product_attributes[0].attribute" (click)="select_attribute(item,attribute,option.name)" -->
                <ion-select interface="popover" (ionChange)="changed_table_dropdown($event,item,attribute)"  [value]="check_table_radio(item,attribute.options)">
                  <ion-select-option class="mouse" *ngFor="let option of attribute.options" [value]="option.name" > {{option.option_value}}</ion-select-option>
                </ion-select>
              </div>
              </div>
          </div>
          </div>
          </div>

          <!-- mobile -->
          <div *ngIf="db.ismobile">
            <div class="attribute__heading_radio" *ngFor="let attribute of product_detail.other_attributes">
            <div  class="attribute__name m-0">{{attribute.attribute}}</div>
              <!-- {{check_table_radio(item,attribute.options)}} -->

            <!-- Radio -->
            <div *ngIf="attribute.control_type == 'Radio Button List'">
              <!-- option.is_selected == 1 && option.is_select_parent.option_value == item.option_value -->

              <div class="d__flex flex-w" *ngIf="false">
                <div [class.active__radio__option]="check_active(item,option)" class="radio__attribute__option mouse d__flex" *ngFor="let option of attribute.options" (click)="item.attribute = product_detail.product_attributes[0].attribute"  (click)="select_attribute(item,attribute,option.name)">
                  <div class="main-dot d__flex just-c"><div class="inner-dot d__flex"> <ion-icon src="/assets/icon/tick.svg"></ion-icon> </div> </div>
                  <div>{{option.option_value}}</div>
                </div>
              </div>
                              <!-- custom radio  -->
                <ion-radio-group class="d__flex flex-w gap-10" [value]="check_table_radio(item,attribute.options)">
                  <ion-item lines="none" [class.active__radio__option]="check_active(item,option)" class="radio__attribute__option mouse" *ngFor="let option of attribute.options" (click)="item.attribute = product_detail.product_attributes[0].attribute"  (click)="select_attribute(item,attribute,option.name)">
                    <!-- <div class="main-dot d__flex just-c"> <div class="inner-dot d__flex"> <ion-icon src="/assets/icon/tick.svg"></ion-icon> </div> </div> -->
                    <ion-radio [value]="option.name"></ion-radio><div>{{option.option_value}}</div>
                  </ion-item>
                </ion-radio-group>
            </div>

            <!-- Drop down list -->
            <div class="attribute__drop__down " *ngIf="attribute.control_type == 'Dropdown List'">
              <!-- <div class="attribute__name">{{attribute.attribute}}</div> -->
              <!-- <div class="selected__drop__down" *ngIf="false" >
                <div class="selected__txt" (click)="item.drop_down = 1"><div>{{check_attribute_label(attribute.options,item)}}</div> <ion-icon name="chevron-down-outline"></ion-icon></div>
                <div *ngIf="item && item.drop_down && item.drop_down == 1"  (mouseleave)="item.drop_down = 0" class="drop__down__options">
                  <div [class.active__drop__option]="check_active(item,option)" class="drop__down__option mouse" *ngFor="let option of attribute.options" (click)="item.attribute = product_detail.product_attributes[0].attribute" (click)="select_attribute(item,attribute,option.name)">{{option.option_value}}</div>
                </div>
              </div> -->

              <!-- (click)="item.attribute = product_info.product_attributes[0].attribute" (click)="select_attribute(item,attribute,option.name)" -->
              <ion-select interface="popover" (ionChange)="changed_table_dropdown($event,item,attribute)"  [value]="check_table_radio(item,attribute.options)">
                <ion-select-option class="mouse" *ngFor="let option of attribute.options" [value]="option.name" > {{option.option_value}}</ion-select-option>
              </ion-select>
            </div>
            </div>
        </div>

          <div >
            <p class="no-stock" *ngIf="item.stock_alert_message">{{item.stock_alert_message}}</p>
          </div>
        </div>

        <!-- <div [innerHTML]="item.selected_attribute"></div> -->
        
        <!-- Table add to cart -->
        <!-- <div *ngIf="item.selected_attribute" [innerHTML]="item.selected_attribute"></div> -->

        <div class="cart-buttons d__flex just-end mouse" >
          <div class="cart__button ion-activatable ripple-parent"  [ngClass]="item.count == 0?'not__added':'__added'" (click)="(item.count == 0 && !item.stock_alert_message)?table_addtocart(item):''" [class.disabled]="item.count && item.count == 0 && item.stock_alert_message">

              <ion-ripple-effect *ngIf="item.count == 0"></ion-ripple-effect>

              <ion-button  class="mouse __button " *ngIf="item.count && item.count != 0"  (click)="table_removetocart(item)" fill="clear"><span>-</span></ion-button>

              <span class="add__txt"> {{item.count && (item.count != 0)?item.count:'Add to Cart'}} </span>
            
              <ion-button class="mouse __button" [class.disabled]="item.stock_alert_message || !(item.count < item.stock) " *ngIf="item.count && item.count != 0" (click)="(item.count != 0 && !item.stock_alert_message && item.count < item.stock )?table_addtocart(item):''"  fill="clear" ><span>+</span></ion-button>

          </div>
        </div>

      </div>
  </div>
</div>