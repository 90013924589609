


      <ion-slides *ngIf="category_slide" [options]="CatSlideOpts">
        <ion-slide> 
          <img  src="{{db.product_img(category_slide['image1'])}}" defaultImage="" alt="">
        </ion-slide>
        <ion-slide> 
          <img  src="{{db.product_img(category_slide['image2'])}}" defaultImage="" alt="">
        </ion-slide>
        <ion-slide> 
          <img  src="{{db.product_img(category_slide['image3'])}}" defaultImage="" alt="">
        </ion-slide>
        <ion-slide> 
          <img  src="{{db.product_img(category_slide['image4'])}}" defaultImage="" alt="">
        </ion-slide>
        <ion-slide> 
          <img  src="{{db.product_img(category_slide['image5'])}}" defaultImage="" alt="">
        </ion-slide>
        <ion-slide> 
          <img  src="{{db.product_img(category_slide['image6'])}}" defaultImage="" alt="">
        </ion-slide>
    </ion-slides>