import {
  Component,
  HostListener,
  OnInit,Input
} from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { OrderDetailPage } from 'src/app/pages/order-detail/order-detail.page';
import { DbService } from 'src/app/services/db.service';

import {
  ModalController,
  Platform,
} from '@ionic/angular';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  @Input() page_no = 1;
  order_sum  = true;
  hidelist = true;
  no_products = false;
  show_order = false;
  order_column = ['Order Id','Order Date','Order Status','Payment Status','Total Price','Paid Amount', 'Refund Amount', 'Refund Status']
  constructor(public db: DbService, private platform: Platform,private router:Router,private modalCtrl:ModalController,private route:ActivatedRoute,) {
  }

  ngOnInit() {
    this.page_no = 1;
    this.db.order_page_no = 1;
    this.db.no_orders_products = false;
    this.get_order_list();
    this.db.ismobile = this.db.checkmobile();
    this.db.ordered.subscribe(res =>{
      res == 'ordered' ? (this.db.order_page_no == 1 , this.get_order_list()) : null;

      if(res && res == 'ordered'){
        this.db.order_page_no == 1;
        this.db.no_orders_products = false;
        this.get_order_list();

      }
    })
  }

  get_status_col(status){
    if(status){
      let class_name = status.toLowerCase().replaceAll(' ','-')
      return class_name
    }
  }

  doRefresh(event) {
    this.db.order_page_no = 1;
    this.get_order_list();
    setTimeout(() => {
      event.target.complete();
    }, 700);
  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.db.ismobile = this.db.checkmobile();
  }


  
  get_order_list() {
    // this.show_order = false;
    let data = {
      page_no : this.db.order_page_no,
      page_length: 15,
    }

    this.db.order_lists(data).subscribe(res => {
      if (res.message && res.message.length != 0) {
        this.db.website_settings.order_info = false;
        this.show_order = true;
         this.db.order_page_no == 1 ?   this.db.order_items = res.message : null
          res.message.map(value =>{
            value.time =  this.formatAMPM(new Date(value.creation))
            this.db.order_page_no != 1 ? this.db.order_items.push(value) : null
          })
      } else {
        this.db.no_orders_products = true;
        this.show_order = true;
        if(this.db.order_page_no == 1){
          this.db.website_settings.order_info = true;
        }
      }
      // setTimeout(()=>{
      //   this.show_order = true;
      // },500)
    })
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


 async opendetail(order_detail){
    const modal = await this.modalCtrl.create({
      component: OrderDetailPage,
      cssClass:'order-detail-popup',
      componentProps:{
        order_detail : order_detail
      }
    })
      this.db.modal = true;
     await modal.present();
      await modal.onWillDismiss().then(res =>{
       if(res.data == 'reload'){
          this.get_order_list();
       }
     })

  }

  loadData(data) {
    if(!this.db.no_orders_products){
      this.db.order_page_no = this.db.order_page_no + 1;
      this.get_order_list();
    }
    setTimeout(()=>{ data.target.complete() },400);
  }
  showHide(){
    if(this.hidelist){
      this.hidelist= false;
    }else{
      this.hidelist= true;
    }
  }

  print(){
    window.print();
  }

  navigator_order_detail(item){
    this.db.website_settings.enable_multi_vendor == 1 ? this.router.navigateByUrl('/my-profile/order/'+item.name) : this.opendetail(item)
  }

}
