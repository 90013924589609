
<!-- <app-header [no_home]="true" title="{{address_type}} Address" [no_cart]="true" [modal]="true"></app-header> -->

<ion-header *ngIf="db.ismobile">
	<ion-toolbar>
		<ion-back-button  slot="start" (click)="modalCtrl.dismiss()" defaultHref=""></ion-back-button>
		<ion-title> {{address_type}} Address </ion-title>
	</ion-toolbar>
</ion-header>


<ion-content>
	<app-address-add [address_type]="address_type" [address_info]="address_info"></app-address-add>
</ion-content>


