import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marquee-text',
  templateUrl: './marquee-text.html',
  styleUrls: ['./marquee-text.component.scss']
})
export class MarqueeTextComponent implements OnInit {
  @Input() marqueeText: string = '';

  constructor() { }

  ngOnInit(): void {
  }
}
