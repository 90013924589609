<!-- <ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="" (click)="modalCtrl.dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title>Review-product</ion-title>
  </ion-toolbar>
</ion-header> -->

<app-header [title]="type == 'review'?'REVIEW PRODUCT':'ASK QUESTIONS'" [no_home]="true" [no_cart]="true"></app-header>

<ion-content>

    <div class="close-button" *ngIf="!db.ismobile">
      <ion-button fill="clear" (click)="modalCtrl.dismiss()"><ion-icon name="close-outline"></ion-icon></ion-button>
    </div>

  <div class="container" >
    <div *ngIf="!db.ismobile" class="border-b">
      <h6 class="title ">{{type == 'review'?'REVIEW PRODUCT':'ASK QUESTIONS'}}</h6>
    </div>
    <form [formGroup]="enquiryform">

        <div lines="none">
             <p class="input__title">Name</p>
            <div class="custom-input">
              <ion-input placeholder="First Name" name="user_name" formControlName="user_name"></ion-input>
            </div>
          </div>
        <div *ngIf="submitted && !user_name?.valid && ( user_name?.dirty|| user_name?.touched) ">
          <p *ngIf="user_name.errors.required">Name is required</p>
          <p *ngIf="user_name.errors.maxlength">Name is not valid</p>
        </div>

        <div lines="none">
          <p class="input__title">Email</p>
          <div class="custom-input">
            <ion-input placeholder="Email" name="customer_email" formControlName="customer_email"></ion-input>
          </div>
        </div>

        <div *ngIf="submitted && !customer_email?.valid && ( customer_email?.dirty || customer_email?.touched) ">
          <p color="danger" *ngIf=" customer_email.errors.required"> Email is required </p>
          <p color="danger" *ngIf=" customer_email.errors.email"> Invalid email id  </p>
          <!-- {{email.errors | json}} -->
        </div>

        <div lines="none" *ngIf="type == 'review'">
          <p class="input__title">Title</p>
          <div class="custom-input">
            <ion-input placeholder="Title" name="title" formControlName="title"></ion-input>
          </div>
        </div>
        <div *ngIf="submitted && !title?.valid">
          <p color="danger" *ngIf=" title.errors.required"> Title is required </p>
          <!-- {{email.errors | json}} -->
        </div>

        <!-- <div lines="none" *ngIf="type != 'review'">
          <p class="input__title">Phone Number</p>
          <div class="custom-input">
            <ion-input placeholder="Phone Number" name="title" formControlName="phone"></ion-input>
          </div>
        </div>
        <div *ngIf="submitted && !phone?.valid && ( phone?.dirty|| phone?.touched) ">
          <p *ngIf="phone.errors.required"> Phone Number is required </p>
          <p *ngIf="phone.errors.pattern"> Not a Valid Mobile number </p>
        </div> -->

        <div lines="none">
          <p class="input__title"> {{type == 'review'?'Message':'Question'}}</p>
          <div class="custom-input">
            <ion-textarea [placeholder]="type == 'review'?'Message':'Question'" name="message" formControlName="message"></ion-textarea>
          </div>
        </div>
        <div *ngIf="submitted && !message?.valid ">
          <p color="danger" *ngIf=" message.errors.required"> Message is required </p>
          <!-- {{email.errors | json}} -->
        </div>

        <div lines="none" class="m-7" *ngIf="type == 'review'">
          <ionic5-star-rating #rating activeIcon = "star"  defaultIcon = "star-outline"  activeColor = "#FF6E00" defaultColor = "#aaaaaa" readonly = "false"   fontSize = "32px" (ratingChanged)="logRatingChange($event)"  formControlName="rating"> </ionic5-star-rating>
        </div>
        <div *ngIf="submitted && !rating?.valid ">
          <p color="danger" *ngIf=" rating.errors.required"> Rating is required </p>
          <!-- {{email.errors | json}} -->
        </div>

  </form>
   <ion-button class="save-button" fill="clear" (click)="type == 'review'?enquiry_info():ask()"> {{type == 'review'?'Submit Review':'Ask Question'}}</ion-button>
</div>


</ion-content>
