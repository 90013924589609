import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  Meta,
  Title,
} from '@angular/platform-browser';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { QuickviewPage } from 'src/app/pages/quickview/quickview.page';
import { DbService } from 'src/app/services/db.service';
import { SeoService } from 'src/app/services/seo.service';
import {
  ActionSheetController,
  AlertController,
  IonContent,
  ModalController,
  Platform,
} from '@ionic/angular';
import { SelectAttributeComponent } from '../categories/select-attribute/select-attribute.component';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['../grid-view/grid-view.component.scss','./categorylist.component.scss'],
})

export class CategorylistComponent implements OnInit, OnDestroy {
  @Input() product_list;

  no_products = false;
  page_no = 1;
  sort = '';
  sort_text ;
  category_child;
  list_height;
  skeleton = true;
  sortby_drop_down = false;
  choosed_data:any;
  choosed_sub :any;
  modal_popup = true;
  product:any;

  @ViewChild('dropdown2', { read :ElementRef}) dropdown2!: ElementRef;
  @ViewChild(IonContent) private content!: IonContent;

  constructor(public db:DbService,private router:Router,private route:ActivatedRoute,private modalCtrl:ModalController,private alertCtrl:AlertController,private platform:Platform,private location:Location,private actionSheetCtrl:ActionSheetController,private meta:Meta,private metaTitle:Title,private changedetect:ChangeDetectorRef,private seo:SeoService) { 
    setTimeout(() => { this.skeleton = false;  }, 900);  
  }

  ngOnInit() {
    // console.log('123456789')
    this.list_height = window.innerHeight  - 20 - 135; 
    this.db.choosed_product_id = undefined;
    this.db.ismobile = this.db.checkmobile();
    this.db.product_box.row_count = this.db.website_settings.default_products_per_row;
    this.db.product_box.view = this.db.ismobile ? 'List View' : 'Grid View';
    let choosed_parent;
    this.route.params.subscribe((res:any) =>{
      this.product = res.product +'/'+ res.product_1;
      choosed_parent = res.product;
      this.choosed_sub = res.product + '/' + res.product_1;
       if(res.product && !res.product_1 && !res.product_2){
        this.db.choosed_product_id = this.route.snapshot.paramMap.get('product'); //for api call
        this.db.childs.child_1 = this.db.choosed_product_id // store child-1
        this.db.childs.child_2 = undefined
        this.db.childs.child_3 = undefined
        this.db.childs.child_2_name = undefined
        this.db.childs.child_3_name = undefined
        this.metaTitle.setTitle(this.db.childs.child_1_name);
      }else if(res.product && res.product_1  && !res.product_2){
          let d = this.route.snapshot.paramMap.get('product_1');
          this.db.childs.child_1 = res.product;
          this.db.childs.child_2 = this.db.childs.child_1 +'/' + res.product_1;
          this.db.choosed_product_id = this.db.childs.child_1 +'/' + d 
          this.db.childs.child_3 = undefined
          this.db.childs.child_3_name = undefined
          this.metaTitle.setTitle(this.db.childs.child_2_name);
      }else if(res.product && res.product_1 && res.product_2){
          this.db.childs.child_1 = res.product
          this.db.childs.child_2 = this.db.childs.child_1 +'/' + res.product_1;
          this.db.childs.child_3 =  this.db.childs.child_1 +'/' + res.product_1  +'/' + res.product_2; 
          this.db.childs.child_3_name = this.route.snapshot.paramMap.get('product_2');
          this.db.choosed_product_id = this.db.childs.child_3;
          this.metaTitle.setTitle(this.db.childs.child_3_name);  
      }
    })


    this.choosed_data = this.db.category.find(item => choosed_parent == item.route);
    this.db.choosed_brands = [];
    this.db.choosed_attributes = [];
      this.route.queryParams.subscribe( (queryparam:any) => {
          if(queryparam.brands && queryparam.brands != ''){
              this.page_no = 1;
              this.db.choosed_brands = queryparam.brands.split(",");

              if((this.db.category_filters && this.db.category_filters.brand_list && this.db.category_filters.brand_list.length != 0) && (this.db.choosed_brands && this.db.choosed_brands.length != 0)){
                this.db.category_filters.brand_list.map(res=>{
                  this.db.choosed_brands.map(r=>{
                    if(r == (res.unique_name ? res.unique_name : res.brand_name)){
                      res['checked'] = true;
                    }
                  })
              })
           }
           
          }
          this.get_cat_products('')
      })

      this.get_category();
      
      this.db.mycart_emit.subscribe(res => {
        res == 'getted' ?  this.get_recommeded() : null
      })

      this.unsubscribe = this.db.store_selection.subscribe(res=>{
        if(res == 'store_selected'){
          this.page_no = 1;
          this.db.get_category_page_filter(this.db.choosed_product_id);
          this.category_products();
        }
      })

  }

  unsubscribe:any;

  ngOnDestroy(): void {
    this.unsubscribe ? this.unsubscribe.unsubscribe() : null;
    // this.db.choosed_attributes = [];
  }

  

  ngAfterContentChecked() {
    this.changedetect.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.db.ismobile = this.db.checkmobile();
  }

  ionViewWillEnter() {
    // console.log(this.db.childs.child_1)
    this.content.scrollToTop(400);
    // this.db.product_box.view = "List View";
    // console.log(this.db.product_box.view);
    // document.getElementById(this.db.childs.child_1).scrollIntoView({ behavior: "smooth", block: "start",  inline: "nearest" });
  }
  // ionViewWillLeave(){
  //   this.db.product_box.view = "Grid View";
  // } 


  get_category() {
    if( this.db.category.length == 0){
      this.db.get_parent_category();
    } else {
        this.db.load_mobile_categories();
    }
  }

  get_products(){
    // this.get_category_page_filters(this.db.choosed_product_id)
    this.get_cat_products('');
    // console.log(this.db.choosed_attributes)
  }

  get_cat_products(sort) {
    this.db.cat_products =[];
    this.page_no = 1;
    this.no_products = false;
    this.skeleton = true;
    setTimeout(() => { this.skeleton = false;  }, 900);
    this.sort = sort;
    this.get_recommeded();
    this.sortby_drop_down ? this.sortby_drop_down = false : null
    // this.smoothscroll();
    this.category_products();
  }


  // skeleton = false;

  category_products() {
    // console.log(this.db.choosed_product_id)
    let attributes:any  = [];
    let temp_obj:any = {}

    // console.log('12345',this.db.choosed_attributes);

    // this.db.choosed_attributes.map(res =>{ 
    //     temp_obj.value = res.value.toString()
    //     temp_obj.attribute = res.attribute
    //     attributes.push(temp_obj)
    // })

    this.db.choosed_attributes.map(res =>{ 
    
      let value_op = ''
      res.value.map((r,i)=>{
        value_op = (i == 0) ?  (r + ',') : (value_op + r + ',');
        if(res.value.length == (i + 1)){
          let obj = {'attribute':res.attribute, 'value':value_op}
          attributes.push(obj);
        }
      })
    })

    let datas = {
      "route": this.db.choosed_product_id,
      // "category": this.db.choosed_product_id,
      "sort_by": this.sort  ,//sort,
      "min_price": this.db.minimax_price.mini_price,// min,
      "max_price": this.db.minimax_price.max_price ,//max,
      "page_no": this.page_no,//page,
      "page_size": this.db.website_settings.no_of_records_per_page,
      "brands": this.db.choosed_brands.toString(), //brands,   this.db.choosed_attributes.toString()
      "attributes": attributes, //attributes,
      "productsid": '' ,//productsid,
      "isMobile": 1,
      "rating": this.db.rating,
      centre: localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] : '',
      seller_classify : this.db.store_name
    }
    

    // this.get_category_page_filters(this.db.choosed_product_id)
    this.db.sub_product_category(datas).subscribe(res=>{
      if (res.message && res.message.length != 0) {
          if(this.page_no == 1){
            this.db.cat_products =  res.message;
          } else {
            this.db.cat_products = [...this.db.cat_products,...res.message]
            this.db.category_bar_web = true;
          }
        this.get_cart_item();
      } else {
        this.no_products = true;
        this.page_no == 1 ?  this.db.cat_products = [] : null;
      }
    })
  }


  get_category_page_filters(value){
    this.db.cat_products = [];
    let data = { route : value }
    this.db.get_category_page_filters(data).subscribe(res =>{      
      res.message.meta_info.image = res.message.meta_info.category_image
      this.seo.store_meta(res.message.meta_info)
      this.db.store_category_filters(res)
        
    })
  }

  get_recommeded() {
    this.db.cat_products.find(res => {
      res.count = this.db.checkcart(res.name)
      res.wish_count = this.db.checkWishCart(res.name);
      if (res.vendor_price_list && res.vendor_price_list.length != 0) {
        res.vendor_price_list.find(rec =>{
          if(rec.variants && rec.variants != 0){
              rec.variants.map(r =>{
                if(r.attribute_id == rec.variants[0].attribute_id && !r.selected_variant && !this.db.is_variant){
                  this.db.check_attribute_cart(res,res.vendor_price_list[0]);
                }else{
                  r.count = this.db.check_attribute(r.attribute_id);
                  res.count = r.count;
                }  
              })
          }
          else
            this.db.check_attribute_cart(res,res.vendor_price_list[0]);
        })
      }
    })
  }

  get_cart_item() {
    this.db.get_cart_item();
  }  

  loadData(data) {
    if(!this.no_products){
      this.page_no = this.page_no + 1;
      this.category_products();
    }
    setTimeout(()=>{ data.target.complete() },400);
    // document.querySelector('side-one').scrollTo(0,0);
  }

  variant_select(value){
    this.select_attribute(value);
  }

  //addtocart
  async addtocart(value) {
    this.db.seller_price = {};
    !value.ids ? (value.ids ='' ,value.selected_attribute ='') : null
    value.vendor_price_list[0].variants && value.vendor_price_list[0].variants.length == 0 ? this.db.addtocart(value) : this.select_attribute(value);
  }

  //Remove Cart
  async removetocart(value) {
    this.db.seller_price = {};
    value.vendor_price_list[0].variants && value.vendor_price_list[0].variants.length == 0 ? this.db.removetocart(value) : this.select_attribute(value);
    // value.has_variants == 1 ?  this.router.navigateByUrl('/pr/'+value.route) :  this.db.removetocart(value)
  }

  addtowish(value){
    this.db.seller_price = {};
    if(value.wish_count != 0){
      this.db.removewish(value)
    } else if(value.wish_count == 0){
      this.db.addtowish(value);
    //   if(value.has_variants == 0) {
    //     this.db.addtowish(value);
    //     !this.db.ismobile ? this.openQuickView(value) : '';
    //   } else {
    //       // this.db.ismobile? this.router.navigateByUrl('/pr/'+value.route) : this.openQuickView(value)
    //       this.router.navigateByUrl('/pr/'+value.route);
    //     // this.product_detail.ids ?   this.db.addtowish(value) : this.db.alert_animate.next("Please select attributes..!")
    //   }
    }
  }

  
  async openQuickView(product_info){

    let temp_path = '';
    const modal = await this.modalCtrl.create({
      component: QuickviewPage,
      // cssClass:  'attribute_change',
      componentProps: {  product_detail : product_info }
    })
    temp_path = location.pathname;
    this.location.replaceState('/pr/'+product_info.route);
    this.db.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.db.modal = false;
      this.location.replaceState(temp_path);
    }
  }


  async select_attribute(product_info){
    const modal = await this.modalCtrl.create({
      component: SelectAttributeComponent,
      cssClass:  this.db.ismobile ? 'attribute_change_mobile' : 'attribute_change_web',
      componentProps: {  product_detail : product_info }
    })

    this.db.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.db.modal = false;
    }
  }


  goto_detail(route){
    this.router.navigateByUrl('/pr/'+route)
  }

  ionViewDidEnter(){
    // console.log("working..")
    // console.log(this.db.childs.child_1)
    // document.getElementById(this.db.childs.child_1).scrollIntoView({ behavior: "smooth", block: "start",  inline: "nearest" });
  }
  
  smoothscroll(){
    // let val = document.getElementById("content") as HTMLInputElement
    // val.scrollIntoView({behavior:'smooth'});
    // window.scroll({   top: 0, left: 0,  behavior: 'smooth'  });
  }
  
  breadcrumb(child){
    if(child == 'child-1'){
      this.db.childs.child_2_name = undefined 
      this.db.childs.child_3_name = undefined 
      this.db.childs.child_2 = undefined
      this.db.childs.child_3 = undefined;
      this.db.choosed_product_id = this.db.childs.child_1;
      this.location.replaceState('/category/'+this.db.childs.child_1);
      this.get_cat_products('')
    } else if(child == 'child-2'){
      this.db.childs.child_3_name = undefined
      this.db.childs.child_3 = undefined
    } else if(child == 'child-3'){

    }
  }
  
   check_attribute_stock(detail){
      var data = {
        "product": detail.name,
        "attribute_id": detail.ids,
        "cart_qty": this.db.plusCount(detail.count),
        "variant_html" :''
      }
      this.db.validate_attributes_stock(data).subscribe(res => {
        if(res.message){
          if( res.message.status == "Success"){
            detail.stock = res.message.stock
            detail.discount_label = res.message.discount.discount_label
            detail.discount_rule = res.message.discount.discount_rule
            detail.stock_alert_message = undefined
          }else if( res.message.status == 'Failed'){
            detail.stock_alert_message = res.message.message
          }
        }
      })
      }

}




