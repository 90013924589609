<!-- <app-header [no_home]="true" [no_cart]="true"  title="My Cart"></app-header> -->
  <div class="container w-100">

    <div class="container-desc-header ion-text-center">
      <h1>Checkout As Guest</h1>
    </div>

    <form class="p-10" [formGroup]="loginform">

      <div class="input-item" >
        <label  class="input-title">Name</label>
          <div class="custom-input d__flex" [ngClass]="focus.foucs1?'focused':''">
            <ion-icon name="person-outline"></ion-icon>
            <ion-input (ionFocus)="focus.foucs1 = true" (ionBlur)="focus.foucs1 = false"  type="text" placeholder="Enter your name" name="first_name" formControlName="first_name"> </ion-input>
          </div>
        <div *ngIf="submitted && !first_name?.valid">
          <p class="error m-0" *ngIf="first_name.errors.required">First Name is required</p>
        </div>
      </div>

      <div class="input-item">
          <label  class="input-title">Email Address</label>
          <div class="custom-input d__flex" [ngClass]="focus.foucs2?'focused':''">
            <ion-icon name="mail-outline"></ion-icon>
            <ion-input (ionFocus)="focus.foucs2 = true" (ionBlur)="focus.foucs2 = false"  type="text" placeholder="Enter your e-mail" name="email" formControlName="email"> </ion-input>
          </div>
          <div *ngIf="submitted && !email?.valid">
            <p class="error" *ngIf="email.errors.required">Email-Id is required</p>
            <p class="error" *ngIf="email.errors.email">Email-Id is not valid</p>
          </div>
      </div>

      <div class="input-item">
        <label  class="input-title">Phone</label>
          <div class="custom-input d__flex" [ngClass]="focus.foucs3?'focused':''">
            <ion-icon name="phone-portrait-outline"></ion-icon>
            <ion-input (ionFocus)="focus.foucs3 = true" (ionBlur)="focus.foucs3 = false" [type]="(show_pass)?'phone':'text'" (keyup.enter)="login()" placeholder="Enter your phone number" name="phone" formControlName="phone"> </ion-input>
          </div>
        <div class="error-container">
          <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.required">Enter Your Mobile Number</p>
          <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.pattern">Enter a valid Mobile Number</p>
        </div>
      </div>

  </form>
  <div class="log-button ion-text-center">
    <ion-button expand="full" class="action-button" [ngClass]="shake?'shaking':''" fill="clear" (click)="login()">Proceed</ion-button>
    <p class="m-0 error" *ngIf="alert_message">{{alert_message}}</p>
  </div>

  <div *ngIf="db.ismobile && (!db.cust_name && !db.guest_cust_name)" class="log_sign" >
    <h5 class="m-0">Or With</h5>
  <div class="log-button ion-text-center">
    <ion-button expand="full" class="action-button" fill="clear" (click)="db.checkout_openLogin('login')">Login</ion-button>
    <ion-button expand="full" class="action-button" fill="clear" (click)="db.checkout_openLogin('signup')">Signup</ion-button>
  </div>
  </div>
  
</div>
