import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-review-product',
  templateUrl: './review-product.page.html',
  styleUrls: ['./review-product.page.scss'],
})
export class ReviewProductPage implements OnInit {
  @Input() product_id;
  @Input() type;
  enquiryform:any = FormGroup;
  submitted = false;
  constructor(private formbuilder:FormBuilder,public db:DbService,private formBuilder:FormBuilder,public modalCtrl:ModalController) { }

  ngOnInit() {
    // console.log(this.product_id);
    this.enquiryform = this.formbuilder.group({
      user_name: [this.db.cust_name,Validators.required],
      customer_email: [this.db.cust_email,[Validators.required,Validators.email]],
      title: ['', Validators.required],
      message: ['', Validators.required],
      rating:['',Validators.required],
      phone:[localStorage['Customerphone'], Validators.required]
    })


    // this.questionform = this.formbuilder.group({
    //   user_name: [this.db.cust_name,Validators.required],
    //   customer_email: [this.db.cust_email,[Validators.required,Validators.email]],
    //   // title: ['', Validators.required],
    //   message: ['', Validators.required],
    //   // rating:[''
    //  
    // })
  }

  get user_name() {
    return this.enquiryform.get('user_name');
  }

  get customer_email() {
    return this.enquiryform.get('customer_email');
  }

  get title() {
    return this.enquiryform.get('title');
  }

  get phone() {
    return this.enquiryform.get('title');
  }

  get message() {
    return this.enquiryform.get('message');
  }

  get rating() {
    return this.enquiryform.get('rating');
  }
  
  enquiry_info(){
    this.submitted = true
    // console.log(this.enquiryform)
      if (this.enquiryform.valid){
        // console.log(this.enquiryform.value)
       let form = this.enquiryform.value;
        form.item = this.product_id;
        this.db.insert_review(form).subscribe(data =>{  
          if (data && data.message && data.message.name) {
            this.modalCtrl.dismiss();
            this.db.alert("Thanks for your response..!");
          }else{
            var d = JSON.parse(data._server_messages);
            // var d1 = JSON.parse(d);
            // console.log(JSON.parse(d[0]),JSON.parse(d[1]))
            this.db.alert(JSON.parse(d[0]).message + JSON.parse(d[1]).message )
          }

        },error => {   console.log(JSON.stringify(error.json())); });
      }
  }

  logRatingChange(rating){
    // console.log("changed rating: ",rating);
  }


  ask(){
    // console.log(this.enquiryform)
    this.submitted = true
   this.enquiryform.get("title").setValue("ssss")
    if (this.enquiryform.valid){
    
     let form = this.enquiryform.value;
      form.item = this.product_id;

      let d = {product: this.product_id, sender_email : form.customer_email,sender_name:form.user_name,sender_phone:form.phone,question:form.message}
      this.db.insert_questionaproduct(d).subscribe(data =>{  
        if (data && data.message && data.message.name) {
          this.db.alert("Your request has been submitted successfully..!");
          this.modalCtrl.dismiss();
        }else{
          var d = JSON.parse(data._server_messages);
          // var d1 = JSON.parse(d);
          // console.log(JSON.parse(d[0]),JSON.parse(d[1]))
          this.db.alert(JSON.parse(d[0]).message + JSON.parse(d[1]).message )
        }

      },error => {   console.log(JSON.stringify(error.json())); });
    }
}

  
}
