import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-order-feedback',
  templateUrl: './order-feedback.page.html',
  styleUrls: ['./order-feedback.page.scss'],
})
export class OrderFeedbackPage implements OnInit {

  @Input() order_id;
  enquiryform:any = FormGroup;
  submitted = false;
  constructor(private formbuilder:FormBuilder,public db:DbService,private formBuilder:FormBuilder,public modalCtrl:ModalController) { }

  ngOnInit() {
    this.enquiryform = this.formbuilder.group({
      message: ['', Validators.required],
      ratings:['',Validators.required],
    })
  }

  get message() {
    return this.enquiryform.get('message');
  }

  get ratings() {
    return this.enquiryform.get('ratings');
  }

  submit(){
    this.submitted = true
      if (this.enquiryform.valid){
       let form = this.enquiryform.value;
        form.order_id = this.order_id;
        this.db.post_order_feedback(form).subscribe(data =>{  
          if (data && data.message && data.message.status == 'Success') {
            this.modalCtrl.dismiss('Success');
            this.db.alert("Thanks for your response..!");
          }else{
            this.db.alert(data.message.message)
          }
        },error => {   console.log(JSON.stringify(error.json())); });
      }
  }

  logRatingChange(rating){
    // console.log("changed rating: ",rating);
  }

}
