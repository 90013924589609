import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-address-add',
  templateUrl: './address-add.component.html',
  styleUrls: ['./address-add.component.scss'],
})
export class AddressAddComponent implements OnInit {
  @Input() address_type: any;
  @Input() address_info: any = {};
  @Input() page_type ;
  submitted = false;
  country_list: any = [];
  states_list: any = [];
  address_form:any = FormGroup;
  focus :any = {};
  error_message = '';
  autocompleteInput: any;
  @ViewChild('addresstext') addresstext!: ElementRef;
  
  constructor(private formBuilder:FormBuilder,private router:Router,public db:DbService,private modalCtrl:ModalController,private ngzone:NgZone) { 
    this.get_country_list();
  }



  ngOnInit() {

    // console.log(this.db.location_info.state)
    // console.log(this.address_info)
  	if(this.address_type == 'Add') {
      this.db.address  = {};
      this.address_info.first_name = localStorage['Customerfirst_name'] || '';
      this.address_info.last_name = localStorage['Customerlast_name'] || '';
      this.address_info.address = ''//this.db.location || '';
  		this.address_info.city = localStorage['city'] ? localStorage['city'] : ''//'Coimbatore';
      this.address_info.state = this.db.location_info.state ? this.db.location_info.state : '';//e,//   
  		this.address_info.zipcode = localStorage['zipcode'] ? localStorage['zipcode'] : '',//this.db.location_info.zipcode//this.address;
      this.address_info.house_type = "Individual";
      this.address_info.country =  this.db.website_settings.default_country //this.db.location_info.country// 
      this.address_info.landmark = ''
      this.address_info.phone = localStorage['Customerphone'];
      this.address_info.door_no = ''
      this.address_info.is_default = false
      this.db.location_info.address  = '' 
      
      
  	} else {
      // console.log(this.address_info)
      this.db.location_info.latitude = Number(this.address_info.latitude )
      this.db.location_info.longitude =  Number(this.address_info.longitude )
      this.db.located.next('located');
    }

    if((this.db.website_settings.enable_zipcode == 1 && this.db.website_settings.radius_based_validation != 1 )|| (this.db.website_settings.enable_zipcode == 0 && this.db.website_settings.radius_based_validation == 0 )){
      this.address_form = this.formBuilder.group({ 
        first_name: new FormControl((this.address_info.first_name || ''), Validators.required),
        last_name: new FormControl((this.address_info.last_name || '')),
        is_default : new FormControl(this.address_info.is_default || false),
        address: new FormControl((this.address_info.address || ''), Validators.required),
        city: new FormControl((this.address_info.city || ''), Validators.required),
        state : new FormControl((this.address_info.state || ''), Validators.required),
        // state:this.address_info.state.get('state').setValue(this.states_list[0].name),
        country: new FormControl((this.address_info.country || ''), Validators.required),
        // zipcode: new FormControl((this.address_info.zipcode || ''), [Validators.required,Validators.pattern('[0-9]{6}')]),
        zipcode: new FormControl((this.address_info.zipcode || '')),
        // house_type : new FormControl((this.address_info.house_type || ''), Validators.required),
        landmark : new FormControl((this.address_info.landmark || '')),
        // door_no : new FormControl((this.address_info.door_no || ''), Validators.required),
        phone: new FormControl((this.address_info.phone || ''), [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
      });
    } else if(this.db.website_settings.radius_based_validation == 1){
      this.address_form = this.formBuilder.group({ 
        address: new FormControl((this.address_info.address || ''), Validators.required), 
        house_type : new FormControl((this.address_info.house_type || ''), Validators.required),
        landmark : new FormControl((this.address_info.landmark || '')),
        door_no : new FormControl((this.address_info.door_no || ''), Validators.required),
        // zipcode: new FormControl((this.address_info.zipcode || ''), [Validators.required,Validators.pattern('[0-9]{6}')]),
        
      });
    }


    if(this.country_list.length == 0 && this.address_info.country) {
      this.country_list = [{'name': this.address_info.country}];
      this.address_form.value.country = this.address_info.country;
    }
    
    if(this.states_list.length == 0 && this.address_info.state) {
      this.states_list = [{ 'name': this.address_info.state}];
      // this.address_form.value.state = this.address_info.state;
    } 

    // this.get_state();
    // this.get_state_list();
  }


  get_state(){
    let data;
    this.db.get_states_lists(this.address_info.country).subscribe(res=>{
      // console.log(res)
    })
  }




  get landmark(){
    return this.address_form.get('landmark');
  } 
  
  get door_no(){
    return this.address_form.get('door_no');
  } 

  get first_name(){
    return this.address_form.get('first_name');
  } 

  get last_name(){
    return this.address_form.get('last_name');
  } 
  
  get address(){
    return this.address_form.get('address');
  }

  get zipcode(){
    return this.address_form.get('zipcode')
  }

  get phone(){
    return this.address_form.get('phone')
  }

  get city(){
    return this.address_form.get('city')
  }

  get country(){
    return this.address_form.get('country')
  }

  get house_type(){
    return this.address_form.get('house_type')
  }

  get state(){
    return this.address_form.get('state')
  }

  get is_default(){
    return this.address_form.get('is_default')
  }


  backbtn(){
    this.modalCtrl.dismiss();
  }



  get_country_list() {
  	this.db.get_country_list().subscribe((r: any) => {
  		this.country_list = r.message;
  		if(this.address_info.country) {
        this.address_form.value.country = this.address_info.country;
      }
  		this.get_state_list();
  	}, error => {	console.log(error)	})
  }


  get_state_list() {
  	this.db.get_states_lists(this.address_info.country).subscribe((r: any) => {
  		this.states_list = r.message;
      // console.log(this.states_list)
      // if(this.address_type == 'Add'){
      //   this.address_form.get('state').setValue(this.states_list[0].name);
      // }
      
  		if(this.address_info.state) {
        this.address_form.value.state = this.address_info.state;
      }else{
        this.address_info.state = this.states_list[0].name; 
        // console.log(this.address_form.value.state)
        // console.log(this.states_list[0].name)
      }
  	}, error => {	console.log(error)	})
  }


  value_changed(evt) {
  	if(evt.detail.value) {
  		this.address_info.country = evt.detail.value;
      this.get_state_list();
  	}
  }


  check_address_pincode(){
    this.submitted = true;

    this.address_form.controls['state'].setValue(this.db.address.state);

    // console.log(this.address_form,'form value')
    if(this.address_form.valid){
      this.db.website_settings.enable_zipcode == 1 ?  this.zipcode_check(this.zipcode.value) : (this.address_type == 'Edit'? this.edit_address() : this.insert_address());
      //  if(this.db.address_zipcode_check){
      //    console.log('1')
      //   this.address_type == 'Edit'? this.edit_address() : this.insert_address()
      //  }
    }
  }

  zipcode_check(zip_code){
    // console.log('5')
    this.db.pincode_availability({zipcode: zip_code}).subscribe(res =>{
      console.log(res)
      if(res.message.is_available == 1){

        this.db.address_zipcode_check = true;

        // console.log('11');
        this.address_type == 'Edit'? this.edit_address() : this.insert_address()
        setTimeout(() => {   window.dispatchEvent(new Event('resize')); }, 100);
      }else if(res.message.is_available == 0){
        this.db.alert('Sorry! we are not providing service to your location.');

        this.db.address_zipcode_check = false;

        // console.log('12')
        setTimeout(() => {   window.dispatchEvent(new Event('resize')); }, 100);
      }
    })
  }
  

// house_type
  insert_address() {
    // this.zipcode.setValue(this.db.location_info.zipcode)
    // this.city.setValue(this.db.location_info.city)
    // this.state.setValue(this.db.location_info.state)
    // this.country.setValue(this.db.location_info.country)
    // console.log(this.address_form)
    // this.address_info = this.address_form.value;

    // console.log(this.address_form,'form values')
    this.submitted = true;
    // this.address_info.city = this.db.location_info.city || this.db.address.city;
    // this.address_info.customer = localStorage.customerRefId;
    // this.address_info.addr1 = this.address_info.address;
    // this.address_info.pincode = this.db.location_info.zipcode || this.db.address.zipcode;
    // this.address_info.latitude = this.db.location_info.latitude
    // this.address_info.longitude = this.db.location_info.longitude
    this.address_info = this.address_form.value;

    if(this.db.website_settings.enable_map == 1){
      this.zipcode.setValue(this.db.location_info.zipcode)
      this.city.setValue(this.db.location_info.city)
      this.address_info.pincode = this.db.location_info.zipcode;
      this.address_info.latitude = this.db.location_info.latitude
      this.address_info.longitude = this.db.location_info.longitude
      this.address_info.city = this.db.location_info.city
    } else {
      this.address_info.pincode = this.zipcode.value
    }
 
    this.address_info.customer = localStorage['customerRefId'];
    this.address_info.addr1 = this.address_info.address;
    
    // console.log(this.address_info)
    if(this.address_form.valid) {
      this.db.insert_address({'data': JSON.stringify(this.address_info)}).subscribe((r: any) => { 
        if(r.message.status != 'failed'){
          this.db.get_address();
          if(this.db.modal){
            // console.log('this.db.modal',this.db.modal);
            this.modalCtrl.dismiss({'status': 'success'});
            // this.modalCtrl.dismiss({'status': 'success'});
          //  && this.address_type != 'Edit'
            // this.db.ismobile ? this.router.navigateByUrl('/yourcart') : null
          }
        }else {
          if(this.db.modal){
            this.db.alert(r.message.message)
            this.modalCtrl.dismiss({'status': 'failed'});
          }
        }
      }, error => {  console.log(error); })
    }
  }



  dismiss_modal() {
    this.modalCtrl.dismiss({'status': 'closed'});  
  }



  edit_address() {
    console.log(this.address_info,'form values')
    // console.log(this.address_form.value)
    this.submitted = true;
    this.address_info.customer = localStorage['customerRefId'];
    this.address_info.first_name = this.address_form.value['first_name'];
    this.address_info.last_name = this.address_form.value['last_name'];
    this.address_info.addr1 = this.address_form.value.address;
    this.address_info.pincode = this.address_form.value.zipcode;
    this.address_info.city = this.address_form.value.city;
    this.address_info.state =  this.address_form.value.state;
    this.address_info.country = this.address_form.value.country;
    this.address_info.house_type = this.address_form.value.house_type;
    this.address_info.phone = this.address_form.value.phone;
    this.address_info.landmark = this.address_form.value.landmark;
    this.address_info.door_no = this.address_form.value.door_no;
    this.address_info.is_default = this.address_form.value.is_default;
    this.address_info.latitude = this.db.location_info.latitude
    this.address_info.longitude = this.db.location_info.longitude

    if(this.address_form.valid) {
      this.db.update_address({'data': JSON.stringify(this.address_info)})
      .subscribe((r: any) => {
        this.modalCtrl.dismiss({'status': 'success'}) 
          // !this.page_type ?  : this.db.get_address();
        // this.db.ismobile ? this.router.navigateByUrl('/yourcart') : null
      }, error => { console.log(error);})
    }
  }

 
  // ngAfterViewInit() {
  //     this.db.getPlaceAutocomplete(this.addresstext.nativeElement)
  // }


}
