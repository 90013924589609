<div class="container">

    <ion-refresher slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <h2 class="fnt-20 myAccount-titles" *ngIf="!db.ismobile">Reward Points</h2>

    <div *ngIf="db.loyalty_details.total_available_points != 0">
        <div class="d__flex wallet__container">
            <div class="wallet__info total">
                <div class="wallet__title">{{db.loyalty_details && db.loyalty_details.total_points? (db.loyalty_details.total_points | number: '1.0-0'):0 }}</div>
                <div class="wallet__text fnt-12">Total Points</div>
            </div>
            <div class="wallet__info current">
                <div class="wallet__title">{{db.loyalty_details && (db.loyalty_details.total_available_points | number: '1.0-0') ?db.loyalty_details.total_available_points:0 }}</div>
                <div class="wallet__text fnt-12 webkit-text" [style.--line]="1">Available Points</div>
            </div>
            <div class="wallet__info locked">
                <div class="wallet__title">{{db.loyalty_details && db.loyalty_details.redeemed_points?db.loyalty_details.redeemed_points:0 }}</div>
                <div class="wallet__text fnt-12">Redeemed</div>
            </div>
        </div>


        <div class="table__data mt-10" *ngIf="db.loyalty_details && db.loyalty_details.transactions && !db.ismobile">
            <div class="table__container d__flex table__header">
                <div>Referance</div>
                <div>Transaction Date</div>
                <!-- <div>Status</div> -->
                <!-- <div>Transaction Type</div> -->
                <div class="ion-text-center">Points</div>
                <!-- <div *ngIf="item.is_expired != 0">Expiry Date</div> -->
            </div>
            <!--  [ngClass]="item.name == (choosed_order && choosed_order.name)?'active-order':''" (click)="choosed(item)" -->
            <div class="main-item mouse" *ngFor="let item of db.loyalty_details.transactions;let i =index">
                <div class="table__container d__flex table__items">
                    <div>{{item.reference}}</div>
                    <div>{{item.creation | date:'medium'}}</div>
                    <!-- <div><span class="dot" [class.success]="item.status == 'Credited'" [class.shipped]="item.status == 'Shipped'" [class.placed]="item.status == 'Placed'"></span>{{item.status}}</div> -->
                    <!-- <div>{{item.transaction_type}}</div> -->
                    <div class="ion-text-center"> {{item.loyalty_points }}</div>
                    <!-- <div *ngIf="item.is_expired != 0"></div> -->
                    <!-- <div><span class="dot" [class.success]="item.payment_status == 'Paid'" [class.pending]="item.payment_status == 'Pending'" ></span>{{item.payment_status}}</div> -->
                </div>
            </div>
        </div>

        
        <div class="table__data" *ngIf="db.loyalty_details && db.loyalty_details.transactions && db.ismobile">

            <div class="main-item mouse" *ngFor="let item of db.loyalty_details.transactions;let i =index">
                <div class="table__items d__flex just-s">
                    <div>
                        <div class="fnt-14">{{item.reference}}</div>
                        <div class="fnt-11 op-7">{{item.creation | date:'medium'}}</div>
                        <!-- <div *ngIf="item.is_expired != 0"></div> -->
                    </div>
                    <div class="fnt-14"> {{item.loyalty_points }}</div>
                </div>
            </div>
        </div>

    </div>

    <ion-infinite-scroll *ngIf="db.loyalty_details.total_available_points != 0 && no_points" threshold="300px" (ionInfinite)="loadData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText=""></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <div class="oops-icon1" *ngIf="db.loyalty_details.total_available_points == 0">
        <ion-icon src="/assets/icon/empty_stack_icons/Gift-null.svg"></ion-icon>
        <h6 class="m-0 fnt-12 oops-text">No Reward Transactions Founded...</h6>
        <!-- <ion-button fill="clear" class="sub_now" routerLink="/">Add Product</ion-button> -->
    </div>
</div>