<h2 class="fnt-20 myAccount-titles" *ngIf="!db.ismobile && no_footer"> Your cart</h2>
<!-- && !db.mycart && !no_title &&  -->
<div [class]="!db.ismobile?'oops-icon1':'oops__'" [class.yorcart_wish_web]="yorcart_wish_web" *ngIf="(db.ismobile || yorcart_wish_web || yorcart_wish_webs) && (db.marketplace_items && db.marketplace_items.length == 0 &&  db.fl_items && db.fl_items.length == 0)">
  <!-- <ion-icon src="/assets/icon/empty_stack_icons/no-cart.svg"></ion-icon> -->
  <img *ngIf="db.website_settings && db.website_settings.app_settings" class="icon" button [src]="db.product_img(db.website_settings.app_settings.no_cart)">
  <h6 class="m-0 fnt-12 oops-text">No Cart Items Found!</h6>
  <p class="m-0">Sorry, there’re no items in your cart!</p>
</div>
<div class="cart-container cartt" [class.mb-65]="db.ismobile && url == 'yourcart'" >
  <div *ngIf="db.ismobile && (db.fl_items && db.fl_items.length != 0 || db.marketplace_items && db.marketplace_items.length != 0)">
    <!-- <ion-icon class="location-icon" src="/assets/icon/delivery-location.svg"></ion-icon> -->
    <div class="fnt-16 color bold_name-15-500 mx-10 mt-5">Delivered to</div>
    <!-- <div (click)="db.open_address_popup()" class="small__heading small__heading_change" >Change</div> -->
    <div class="address__" *ngIf="db.business_address">
      <h6 class="m-0 head">{{db.business_address.business_name}}</h6>
      <div class="fnt-13 choosed__address"> {{db.business_address.business_address}} {{db.business_address.business_landmark}} {{db.business_address.business_city}} {{db.business_address.business_state}} {{db.business_address.business_country}} - {{db.business_address.business_zip}}.</div>
      <p class="m-0 fnt-13">Ph: {{db.business_address.business_phone}}</p>
    </div>
  </div>
  <div class="list-view" [ngClass]="(!db.ismobile && margin_bottom) ? 'mb-60':''">


    <div *ngIf="db.marketplace_items && db.marketplace_items.length != 0"  class="sections">
      <div class="head_div" >
        <ion-label class="m-0 bold_name-15">Farmlink</ion-label>
        <P class="m-0 count_v" *ngIf="db.marketplace_items">{{db.marketplace_items.length}} <span>Item</span><span *ngIf="db.marketplace_items.length != 1">s</span> </P>
      </div>

      <div [class.height]="db.marketplace_items && db.marketplace_items.length > 2" class="list_view ion-padding" slot="content">
        <!--[class.list-item]="!db.ismobile" [ngClass]="db.ismobile && cart_len != i ? 'list-item' : ''"-->
        <div div class="d__flex list-item" [class.two_col]="no_title && !db.ismobile" *ngFor="let item of (marketplace_items_show_more ? db.marketplace_items : db.marketplace_items.slice(0,2)) ;index as i">
          <div class="free__item" *ngIf="item.is_free_item == 1">
            <div class="free__item__text">FREE</div>
          </div>
          <!-- <ng-container *ngIf="market_len != i"> -->
          <div class="image" [class.page-design]="db.path == '/my-profile/yourcart'">
            <img src="{{db.product_imgs(item.image)}}" />
          </div>
          <div class="item-info">
            <div>
              <!-- <p *ngIf="item.vendor_name" class="m-0 small_name gray_cr">{{item.vendor_name}}</p> -->
              <p class="m-0 product__name medium_name">{{item.product_name}}</p>
              <!-- <p class="m-0 product__name">{{item.product_name}}</p> -->
            </div>
            <div>
              <div class="attribute_d" [innerHTML]="item.attribute_description"></div>
              <p class="m-0 sold_by_d" *ngIf="item.vendor_name">Sold By : <span class="sold_by">{{item.vendor_name}}</span></p>
              <p class="m-0" *ngIf="item.note">Note: {{item.note}}</p>
            </div>
            <div class="d__flex just-s">
              <p class="m-0 price sans-font">{{item.price |currency:db.website_settings.currency}}</p>
              <div class="d__flex just-end" *ngIf="!no_title">
                <div class="cart-buttons" *ngIf="item.is_free_item != 1">
                  <div class="cart__button __added position-relative">
                    <ion-spinner *ngIf="item.temp_disabled"></ion-spinner>
                    <div class="mouse __button __button_1 ion-text-center" *ngIf="item.is_free_item != 1" (click)="remove(item,'remove')" fill="clear"><span>-</span></div>
                    <span class="add__txt"> {{item.quantity}} </span>
                    <div class="mouse __button ion-text-center" (click)="addtocart(item)" [class.disabled]="item && item.no_stock" fill="clear"><span>+</span></div>
                  </div>
                </div>
                <div color="danger" class="car__delete__button" fill="clear">
                  <ion-icon *ngIf="item.is_free_item != 1" color="danger" src="/assets/icon/delete.svg" class="mouse trash__icon" (click)="remove(item,'delete')"></ion-icon>
                </div>
              </div>
            </div>
            <!-- <div class="location__warning fnt-12" *ngIf="item && item.no_stock && (db.marketplace_items && db.marketplace_items.length != 0) ">No stock available.!</div> -->
          </div>
          <!-- </ng-container> -->
        </div>
      </div>
      <div *ngIf="db.marketplace_items && db.marketplace_items.length > 2" (click)="marketplace_items_show_more =! marketplace_items_show_more" class="d__flex mouse show_more">
        <p class="m-0 title_ small_name">{{marketplace_items_show_more ? 'Show Less' : 'Show More'}}</p>
        <ion-icon [name]="marketplace_items_show_more ? 'chevron-up-outline': 'chevron-down-outline'"></ion-icon>
      </div>

    </div>

       <div *ngIf="db.fl_items && db.fl_items.length != 0" class="sections">
        <div class="head_div" >
          <ion-label class="m-0 bold_name-15">Direct</ion-label>
          <P class="m-0  count_v" *ngIf="db.fl_items" slot="end">{{db.fl_items.length}} <span>Item</span><span *ngIf="db.fl_items.length != 1">s</span> </P>
        </div>
        <div [class.height]="db.marketplace_items && db.marketplace_items.length > 2" class="list_view fl_store ion-padding" slot="content">
          <!--[class.list-item]="!db.ismobile" [ngClass]="db.ismobile && cart_len != i ? 'list-item' : ''"-->
          <div div class="d__flex list-item" [class.two_col]="no_title && !db.ismobile" *ngFor="let item of (fl_items_show_more ? db.fl_items : db.fl_items.slice(0,2));index as i">
            <div class="free__item" *ngIf="item.is_free_item == 1">
              <div class="free__item__text">FREE</div>
            </div>
            <!-- <ng-container *ngIf="cart_len != i"> -->
            <div class="image" [class.page-design]="db.path == '/my-profile/yourcart'">
              <img src="{{db.product_imgs(item.image)}}" />
            </div>
            <div class="item-info">
              <div>
                <!-- <p *ngIf="item.vendor_name" class="m-0 small_name gray_cr">{{item.vendor_name}}</p> -->
                <p class="m-0 product__name medium_name">{{item.product_name}}</p>
              </div>
              <div>
                <div class="attribute_d" [innerHTML]="item.attribute_description"></div>
                <p class="m-0 sold_by_d" *ngIf="item.vendor_name">Sold By : <span class="sold_by">{{item.vendor_name}}</span></p>
                <p class="m-0" *ngIf="item.note">Note: {{item.note}}</p>
              </div>
              <!-- <div>
                                    <p class="m-0" *ngIf="item.note">Sold by: {{item.note}}</p>
                                </div> -->
              <div class="d__flex just-s">
                <p class="m-0 price sans-font">{{item.price |currency:db.website_settings.currency}}</p>
                <div class="d__flex just-end" *ngIf="!no_title">
                  <div class="change_button_color cart-buttons" *ngIf="item.is_free_item != 1">
                    <div class="cart__button __added position-relative">
                      <ion-spinner *ngIf="item.temp_disabled"></ion-spinner>
                      <div class="mouse __button __button_1 ion-text-center" *ngIf="item.is_free_item != 1" (click)="remove(item,'remove')" fill="clear"><span>-</span></div>
                      <span class="add__txt"> {{item.quantity}} </span>
                      <div class="mouse __button ion-text-center" (click)="addtocart(item)" [class.disabled]="item && item.no_stock" fill="clear"><span>+</span></div>
                    </div>
                  </div>
                  <div color="danger" class="car__delete__button" fill="clear">
                    <ion-icon *ngIf="item.is_free_item != 1" color="danger" src="/assets/icon/delete.svg" class="mouse trash__icon" (click)="remove(item,'delete')"></ion-icon>
                  </div>
                </div>
              </div>
              <!-- <div class="location__warning fnt-12" *ngIf="item && item.no_stock && (db.fl_items && db.fl_items.length != 0) ">No stock available.!</div> -->
            </div>
            <!-- </ng-container> -->
            <!-- <ng-template #showmore>
                            <div class="d__flex" ngIf="db.fl_items.length > 2" (click)="cart_len = db.fl_items.length == cart_len ? 2 : db.fl_items.length">
                                <p>Show more</p>
                                <ion-icon [name]="item.show_more ? 'chevron-down-outline' : 'chevron-up-outline'"></ion-icon>
                            </div>
                        </ng-template> -->
          </div>
        </div>

        <div *ngIf="db.fl_items && db.fl_items.length > 2" (click)="fl_items_show_more =! fl_items_show_more" class="d__flex mouse show_more">
          <p class="m-0 title_ small_name">{{fl_items_show_more ? 'Show Less' : 'Show More'}}</p>
          <ion-icon [name]="fl_items_show_more ? 'chevron-up-outline': 'chevron-down-outline'"></ion-icon>
        </div>

       </div>
       <div class="mt-b-35-15" [ngClass]="db.ismobile?'ion-text-center':'ion-text-right'" *ngIf="db.ismobile && db.edit_order_id && url == 'yourcart'">
        <ion-button class="action-button" [class.w-20]="!db.ismobile" fill="clear" routerLink="/shop/all-fruits">Add Items</ion-button>
      </div>
  </div>
  <div class="mt-b-35-15" [ngClass]="db.ismobile?'ion-text-center':'ion-text-right'" *ngIf="!db.ismobile && !db.mycart && !no_title && db.cart_count != 0">
    <ion-button class="action-button" [class.w-20]="!db.ismobile" fill="clear" routerLink="/checkout">Checkout</ion-button>
  </div>
  <div class="address__info" *ngIf="db.marketplace_items && db.marketplace_items.length != 0 && db.ismobile && db.selected_address && db.selected_address.name && false">
    <div class="address__footer mouse" (click)="(db.cust_name || db.guest_cust_name)?db.open_address_popup():db.openLogin()">
      <div class="d__flex just-s" *ngIf="!db.selected_address.name">
        <div class="fnt-13 small__heading">Shipping Address</div>
        <ion-button fill="clear fnt-13">{{(db.cust_name || db.guest_cust_name)?'Select Address':'Login'}}</ion-button>
      </div>
      <div class="delivery_Address gap-5" *ngIf="db.selected_address && db.selected_address.name">
        <ion-icon class="custom-icon" src="/assets/icon/delivery-location.svg"></ion-icon>
        <div class="w-100">
          <div class="d__flex just-s fnt-13 small__heading">
            <div>Delivery Address</div>
            <div class="small__heading small__heading_change">Change</div>
          </div>
          <div class="fnt-12 choosed__address"> {{db.selected_address.address}} {{db.selected_address.landmark}} {{db.selected_address.city}} - {{db.selected_address.zipcode}}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="db.available_location && db.ismobile && db.marketplace_items.length != 0 " class="location__warning fnt-12 d__flex">
    <ion-icon name="warning-outline"></ion-icon>
    <p class="m-0">Shipping is not available to your location!</p>
  </div>
  <!-- <div *ngIf="!db.selected_not_currect && !db.available_location && db.ismobile && db.marketplace_items.length != 0" class="location__warning fnt-12 d__flex">
        <ion-icon name="warning-outline"></ion-icon>
        <p  class="m-0" *ngIf="!db.selected_not_currect">Your current location and selected address must not be same!</p>
     </div> -->
  <div class="delivery__slots" *ngIf="db.ismobile">
    <div *ngIf="db.shipping_settings && db.shipping_settings.selected_shipping_method_name && false" class="bg-white p-10 mb-5" (click)="open_delivery_slot()">
      <div class="d__flex shipping_Method gap-5">
        <ion-icon class="custom-icon" src="/assets/icon/shipping-method.svg"></ion-icon>
        <div class="w-100">
          <div class="d__flex just-s fnt-13 small__heading">
            <div>Shipping Method</div>
            <div class="small__heading small__heading_change">Change</div>
          </div>
          <div class="fnt-13"> {{db.shipping_settings.selected_shipping_method_name}} </div>
        </div>
      </div>
    </div>
    <div *ngIf="db.selected_slot.length != 0 && false" class="bg-white p-10 mb-5" (click)="open_delivery_slot()">
      <div class="d__flex Delivery_slot  gap-5">
        <ion-icon class="custom-icon" src="/assets/icon/delivery-slot.svg"></ion-icon>
        <div class="w-100">
          <div class="d__flex just-s fnt-13 small__heading">
            <div>Delivery slot</div>
            <div class="small__heading small__heading_change">Change</div>
          </div>
          <div class="fnt-13">{{db.selected_slot_label.date}} {{db.selected_slot_label.time}} </div>
        </div>
        <div *ngIf="false">
          <h6 class="small__heading m-0 fnt-13">Delivery slot</h6>
          <div class="fnt-13">{{db.selected_slot_label.date}} {{db.selected_slot_label.time}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="p-10" *ngIf="db.you_may_like && db.you_may_like.length !=0 && db.path != '/checkout' && db.path?.split('/')[1] != 'my-profile'">
        <h6>Before you checkout</h6>
        <app-productbox-home [scrollable]="db.ismobile?'grid-1-50':'grid-2'" [data]="db.you_may_like"> </app-productbox-home>
    </div> -->
  <!--  && db.ismobile -->
  <div class="bill__info_yourcart" *ngIf="db.marketplace_items && db.marketplace_items.length != 0 && (summary_bill || (!summary_bill && db.ismobile)) && false">
    <h6 class="small__heading">Bill Details</h6>
    <div class="p-10">
      <ion-item lines="none">
        <ion-label>Sub Total</ion-label>
        <ion-label slot="end" class="ion-text-right" *ngIf="db.cart_data && db.cart_data.cart">{{db.cart_data.cart.total | currency: db.website_settings.currency}}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Delivery Charges</ion-label>
        <ion-label slot="end" class="ion-text-right"> {{ db.shipping_settings.shipping_charges ? (db.shipping_settings.shipping_charges | currency: db.website_settings.currency) : (0 | currency: db.website_settings.currency )}}</ion-label>
      </ion-item>
      <ion-item *ngIf="db.website_settings.included_tax != 1" lines="none">
        <ion-label>Tax</ion-label>
        <ion-label slot="end" class="ion-text-right"> <span *ngIf="db.cart_data && db.cart_data.cart && db.cart_data.cart.tax">{{db.cart_data.cart.tax |currency: db.website_settings.currency}}</span><span *ngIf="!(db.cart_data && db.cart_data.cart && db.cart_data.cart.tax)" class="tax-price">{{0 |currency: db.website_settings.currency}}</span> </ion-label>
      </ion-item>
    </div>
    <ion-item class="total" lines="none">
      <ion-label>Total</ion-label>
      <ion-label slot="end" class="ion-text-right" *ngIf="db.cart_data && db.cart_data.cart">{{db.cart_data.cart.total + db.shipping_settings.shipping_charges + db.cart_data.cart.tax |currency: db.website_settings.currency}}</ion-label>
    </ion-item>
  </div>
  <!-- <div *ngIf="db.ismobile" style="height: 55px;"></div> -->
  <div class="mobile__footer " *ngIf="(db.fl_items && db.fl_items.length !=0 || db.marketplace_items && db.marketplace_items.length != 0) && db.ismobile">
    <div class="address__footer" *ngIf="false">
      <div class="d__flex just-s" *ngIf="!db.selected_address.name && false">
        <div class="fnt-13 small__heading">Delivered to</div>
        <ion-button fill="clear fnt-13" (click)="db.cust_name?db.open_address_popup():db.openLogin()">{{db.cust_name?'Select Address':'Login'}}</ion-button>
      </div>
      <div class="d__flex gap-5" *ngIf="db.selected_address.name">
        <ion-icon class="location-icon" src="/assets/icon/delivery-location.svg"></ion-icon>
        <div class="w-100">
          <div class="d__flex just-s fnt-13 small__heading">
            <div>Delivered to</div>
            <!-- <div (click)="db.open_address_popup()" class="small__heading small__heading_change" >Change</div> -->
          </div>
          <div class="fnt-13 choosed__address"> {{db.selected_address.address}} {{db.selected_address.landmark}} {{db.selected_address.city}} - {{db.selected_address.zipcode}}</div>
        </div>
      </div>
    </div>
    <!-- [routerLink]="db.selected_address && db.selected_address.name?'/checkout':null" -->
    <div class="mobile__cart__footer d__flex just-s">
      <div class="d__flex gap-5">
        <ion-icon class="cart-icon" src="/assets/icon/mycart.svg"></ion-icon>
        <div>
          <div class="item__count fnt-12 ion-text-center"> {{db.cart_count}} items </div>
          <div class="price__count fnt-13" *ngIf="db.cart_data && db.cart_data.cart"> {{db.cart_data.cart.total |currency:db.website_settings.currency}}</div>
        </div>
      </div>
      <ion-button routerLink="/checkout" fill="clear" class="d__flex ion-text-capitalize mouse gap-5 proceed  fnt-14 choosed"> Checkout
        <!-- <ion-icon class="mt-4" name="chevron-forward-outline"></ion-icon> -->
      </ion-button>
      <ion-button fill="clear" *ngIf="(db.cust_name || db.guest_cust_name) && !check_methods() && false" class="d__flex ion-text-capitalize mouse gap-5 proceed  fnt-14 choosed" (click)="!(db.selected_address && db.selected_address.name)?db.open_address_popup():open_delivery_slot()">{{!(db.selected_address && db.selected_address.name)?'Choose Address':'Choose Delivery Method'}}
        <ion-icon class="mt-4" name="chevron-forward-outline"></ion-icon>
      </ion-button>
      <ion-button fill="clear" *ngIf="!db.cust_name && !db.guest_cust_name && false" class="d__flex gap-5 mouse proceed  fnt-14 choosed ion-text-capitalize" (click)="go_login()">Login to Choose Delivery Address
        <ion-icon class="mt-4" name="chevron-forward-outline"></ion-icon>
      </ion-button>
      <!-- [skipLocationChange]="true" -->
      <ion-button fill="clear" *ngIf="check_methods() && false" [disabled]="db.available_location" class="d__flex mouse gap-5 proceed  fnt-14 choosed ion-text-capitalize" [routerLink]="!db.available_location?'/checkout':null">Proceed to pay
        <ion-icon class="mt-4" name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
  <div *ngIf="!db.ismobile && db.cart_count != 0 && db.mycart && !no_footer && yorcart_wish_web" class="cart__footer">
    <div class="d__flex just-s cart__desc">
      <div class="clear__cart mouse d__flex" (click)="db.clearcart()">
        <ion-icon src="/assets/icon/delete.svg"></ion-icon>
        <div class="clearcart">Clear cart</div>
      </div>
      <div class="subtotal gap-10">
        <span class="total__title">Subtotal:</span> <span class="price__title" *ngIf="db.cart_data"> {{db.cart_data.cart.total |currency:db.website_settings.currency}}</span>
      </div>
    </div>
    <div>
      <ion-button (click)="close_menu()" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact:true}" fill="clear" expand="full" (click)="check_stocks()">Proceed To Checkout</ion-button>
    </div>
  </div>
</div>
<!-- <ion-footer translucent="true" class="ion-no-border" mode="md" *ngIf="db.cart_count != 0 && false">
    <ion-toolbar color="translucent">
      <div class="d__flex mx-10 just-s">
        <h5 class="footer_header fnt-16"> {{db.cart_count}} <span *ngIf="db.cart_count == 1 || db.cart_count == 0" class="id9">Item</span><span *ngIf="db.cart_count != 1 && db.cart_count != 0" class="id9">Items</span> | <span class="font-secondary">₹</span> <span *ngIf="db.cart_data && db.cart_data.cart && db.cart_data.cart.total">{{db.cart_data.cart.total}}</span></h5>
        <ion-button (click)="close_menu()"   routerDirection="root" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact:true}" fill="clear" expand="full" class="footer_proc" (click)="check_stocks()">Checkout</ion-button>
      </div>
    </ion-toolbar>
  </ion-footer>
 -->
