<div class="sharing__container">
  <div class="inner__sharing">
    <div class="d__flex just-s">
      <h6>Share this {{item}}</h6>
      <ion-icon (click)="modalCtrl.dismiss()" class="close-icon mouse" name="close-outline"></ion-icon>
    </div>

      <ion-item>
        <ion-input [value]="share_url"></ion-input>
      </ion-item>

      <ul class="mt-10">
        <li><a class="d__flex gap-10" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=someone@gmail.com&body={{share_url}}" target="_blank"> <img src="/assets/icon/referral/mail.svg"> Email</a></li>
        <li><a class="d__flex gap-10" href="https://www.facebook.com/sharer/sharer.php?u={{share_url}}" target="_blank">  <img src="/assets/icon/referral/fb.svg"> Facebook</a></li>
        <li><a class="d__flex gap-10" href="https://twitter.com/share?url={{share_url}}" target="_blank">  <img src="/assets/icon/referral/twitter.svg"> Twitter</a></li>
        <li><a class="d__flex gap-10" href="http://pinterest.com/pin/create/link/?url={{share_url}}" target="_blank">  <img src="/assets/icon/referral/pinterest.svg"> Pinterest</a></li>
      </ul>
  </div>

  <!-- mailto:abc@abc.com?subject=See Meatton&body={{share_url}} -->
  <!-- href="" -->
<!-- <a target="_blank" title="Share this page" href="http://www.sharethis.com/share?url=[INSERT URL]&title=[INSERT TITLE]&summary=[INSERT SUMMARY]&img=[INSERT IMAGE URL]&pageInfo=%7B%22hostname%22%3A%22[INSERT DOMAIN NAME]%22%2C%22publisher%22%3A%22[INSERT PUBLISHERID]%22%7D"><img width="86" height="25" alt="Share this page" src="http://w.sharethis.com/images/share-classic.gif"></a -->
  <!-- <ion-button (click)="dismiss()" expand="full" fill="clear" > Close  </ion-button> -->
</div>