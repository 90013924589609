import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { QuickviewPage } from 'src/app/pages/quickview/quickview.page';
import { RegisterPage } from 'src/app/pages/register/register.page';
import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';
import { SelectAttributeComponent } from '../categories/select-attribute/select-attribute.component';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss'],
})
export class ProductBoxComponent implements OnInit {
  // @ViewChild('scrollContent') scroll: ElementRef;
  @ViewChild('scrollable_cnt') scrollable_cnt!:ElementRef;
  @ViewChild('left_arrow') left_arrow!:ElementRef;
  @ViewChild('right_arrow') right_arrow!:ElementRef;
  @Input() scroll_img;
  // @Input() row;
  @Input() data;
  @Input() border_color;
  @Input() change_button_color;
  @Input() type;
  @Input() arrow = true;
  @Input() background_tile;
  @Input() background_image;
  @Input() category_p;
  @Input() search;
  @Input() viewall;
  @Input() short_description;
  @Input() image_product;
  @Input() list_view;

  @Input() create_grid_2;

  @Output() add_wish = new EventEmitter();
  @Output() remove_wish = new EventEmitter();
  @Output() addto_cart = new EventEmitter();
  @Output() removeto_cart = new EventEmitter();
  @Output() variant_select = new EventEmitter();

  @Input() scrollable;

 

  modal_popup = true;
  // left_arrow = false;
  constructor(private mc:ModalController,private router:Router,public db:DbService,private modalCtrl:ModalController,private changedetect:ChangeDetectorRef,private location:Location) {  }

  ngOnInit() {
    this.db.ismobile = this.db.checkmobile();
    this.db.product_box.view = this.db.ismobile ? 'List View' : 'Grid View';
  }

  @HostListener('window:resize', ['$event'])
  private func(){
    this.db.product_box.view = this.db.ismobile ? 'List View' : 'Grid View';
     this.db.ismobile = this.db.checkmobile();
  }

  img_style(data, type) {
    if (type == 'color') {
      return { 'background': data };
    }else if (type == 'img') {
      return { 'background': 'url(' + this.db.product_img(data) + ')center center / 100% auto no-repeat', 'background-size': 'contain' };
    }else if (type == 'colorcode') {
      return { 'color': data };
    }else if (type == 'bgcolor') {
      return { 'background': data, 'color': data };
    } else if (type == 'full-bg-img') {
      return { 'background': 'url(' + this.db.product_img(data) + ') no-repeat', 'background-size': '230px', 'background-position-y': 'center'};
    } else if (type == 'bg-image') {
      return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat'}
    } else if (type == 'About-bg-image') {
      return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat',"background-attachment": "fixed","background-position": "center","background-size": "cover"}
    }
  }

 async addtowish(data,) {
    // data.wish_count  = count;
    if(localStorage['customerRefId']){
      // data.product_attributes && data.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+data.route) : this.add_wish.emit(data);
      this.add_wish.emit(data)
    } else {
        if (this.modal_popup && !this.db.cust_name){
          this.modal_popup = false;
          const modal = await this.modalCtrl.create({
            component: RegisterPage,
            cssClass: 'auth-popup',
            componentProps: {
              type: 'login'
            }
          })
          this.db.opened_login_modal = true;
          await modal.present();
          await modal.onWillDismiss().then( res =>{
            this.modal_popup = true;
            this.add_wish.emit(data);
           });
        }
    } 
  }
  

  removewish(data,count) {
      data.wish_count  = count;
     this.remove_wish.emit(data);
  }


  // emitScroll(scroll, direction,img) {
  //   if(img){
  //     if (direction == 'right') {
  //       this.scroll.nativeElement.scrollLeft += 620;
  //      } else if (direction == 'left') {
  //     this.scroll.nativeElement.scrollLeft -= 620;
  //      }
  //   } else {
  //     if (direction == 'right') {
  //       this.scroll.nativeElement.scrollLeft += 602;
  //      } else if (direction == 'left') {
  //     this.scroll.nativeElement.scrollLeft -= 602;
  //      }
  //   }
    
  // }

  // scrolled(event){
  //   if(event.target.scrollLeft != 0){
  //     this.left_arrow = true;
  //   }else if(event.target.scrollLeft == 0){
  //     this.left_arrow = false;
  //   }
  // } 

  checkOverflow (element) {
    if(element){
      return element.offsetHeight < element.scrollHeight ||  element.offsetWidth < element.scrollWidth;
    } else {
      return false;
    }
  }


  scroll(direction){
    if (direction == 'prev') {  
      // d.scrollLeft -= 250;
        this.scrollable_cnt.nativeElement.scrollLeft -= 650;
    } else if (direction == 'next') {
      // d.scrollLeft += 250;
        this.scrollable_cnt.nativeElement.scrollLeft += 650;
    }
  }

  scrolled(event){
    !this.db.ismobile ? this.scrollable_cnt.nativeElement.offsetWidth + this.scrollable_cnt.nativeElement.scrollLeft == this.scrollable_cnt.nativeElement.scrollWidth ? this.right_arrow.nativeElement.classList.add('hiding') : this.right_arrow.nativeElement.classList.remove('hiding') : null
    !this.db.ismobile ? this.scrollable_cnt.nativeElement.scrollLeft == 0 ? this.left_arrow.nativeElement.classList.add('hiding') :  this.left_arrow.nativeElement.classList.remove('hiding') : null
  }

  ngAfterContentChecked() {
    this.changedetect.detectChanges();
  }


  async openQuickView(product_info){
    // console.log("CHECK")
    this.db.quick_view_none = false;
    let temp_path = '';
    const modal = await this.modalCtrl.create({
      component: QuickviewPage,
      cssClass:  'quick-view-popup',
      componentProps: {  product_detail : product_info }
    })
    temp_path = location.pathname;
    this.location.replaceState('/pr/'+product_info.route);
    this.db.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.db.quick_view_none = true;
      this.db.modal = false;
      this.location.replaceState(temp_path);
    }else{
      this.db.quick_view_none = true;
    }
  }
  
  goto_detail(route){
    this.router.navigateByUrl('/pr/'+route)
  }
  
  async select_attribute(product_info){
    const modal = await this.modalCtrl.create({
      component: SelectAttributeComponent,
      cssClass:  this.db.ismobile ? 'attribute_change_mobile' : 'attribute_change_web',
      componentProps: {  product_detail : product_info }
    })

    this.db.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.db.modal = false;
    }
  }


}
