import {
  DatePipe,
  Location,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import {
  getAuth,
  provideAuth,
} from '@angular/fire/auth';
import {
  getDatabase,
  provideDatabase,
} from '@angular/fire/database';
import {
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';
import { StarRatingModule } from 'ionic5-star-rating';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CookieService } from 'ngx-cookie-service';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { environment } from 'src/environments/environment';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
// import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import {
  IonicModule,
  IonicRouteStrategy,
} from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx'
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ComponentsModule,
    ReactiveFormsModule, 
    FormsModule,
    IonicModule.forRoot(),
    IonicModule.forRoot({mode:'md'}),
    AppRoutingModule, RouterModule,
    HttpClientModule,
    LazyLoadImageModule,
    StarRatingModule,
    NgxImgZoomModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions())
  ],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Geolocation,/* GooglePlus, */ SmsRetriever,Location,DatePipe,CookieService,AppVersion,InAppBrowser],
  bootstrap: [AppComponent],
})
export class AppModule {}
