<div class="container mini-100 position-relative">
  <div class="address__container h-100" [class.p-30]="modal">
    <h2 class="fnt-20 myAccount-titles" *ngIf="!db.ismobile"> {{modal?'Delivery':''}} Address</h2>
    <div>
      <div class="address__lists p-10">
        <ion-list class="address__list position-relative p-10 mouse" [class.selected__address]="item.name == (db.selected_address && db.selected_address.name)" *ngFor="let item of db.address_list;let i=index" (click)="db.ismobile ? db.choose_address_phone(item) : db.choose_address(item)" (click)="db.ismobile?select_address(item):null">
          <h6 class="customer-name">{{item.first_name}} {{item.last_name}}</h6>
          <p class="address fnt-14 m-5">
            {{item.door_no}} {{item.address }}, {{item.city}} - {{item.zipcode}} , {{item.landmark}}<br>
            {{item.state}}, {{item.country}} <span *ngIf="item.phone">Contact: {{item.phone}}</span></p>
          <div class="action__buttons">
            <div class="d__flex">
              <ion-button class="edit__button ion-text-capitalize" fill="clear" color="success" size="small" (click)="db.edit_address(item)">
                <ion-icon color="success" src="/assets/icon/edit.svg"></ion-icon>
              </ion-button>
              <span> | </span>
              <ion-button class="delete__button ion-text-capitalize" fill="clear" color="danger" size="small" (click)="db.delete_address(item,i)">
                <ion-icon color="danger" src="/assets/icon/delete.svg"></ion-icon>
              </ion-button>
            </div>
          </div>
        </ion-list>
        <ion-list class="address__list d__flex just-c mouse" (click)="db.add_address()">
          <div class="d__flex just-c">
            <ion-icon name="add-outline"></ion-icon> Add Address
          </div>
        </ion-list>
      </div>
    </div>
  </div>
  <div class="save__button" *ngIf="!db.ismobile && modal &&  this.db.address_list.length != 0">
    <ion-button fill="clear" class="ion-text-capitalize" (click)="select_address(db.selected_address)">Save Changes</ion-button>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="db.ismobile">
    <ion-fab-button (click)="db.add_address()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</div>
