import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  FiltersPopupPage,
} from 'src/app/pages/filters-popup/filters-popup.page';
import {
  ProductPopupPage,
} from 'src/app/pages/product-popup/product-popup.page';
import { DbService } from 'src/app/services/db.service';

import {
  ActionSheetController,
  ModalController,
} from '@ionic/angular';
import { Router,ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss'],
})
export class MobileFiltersComponent implements OnInit {
  @Output() get_cat_products = new EventEmitter();
  @Input() category_filters;
  // sub_val = false;
  @Input() choosed_data;
  @Input() choosed_sub;
  @Input() pr;
  active;
  active2;
  // is_active = true;
  // sub_data: any = [];
  constructor(private actionSheetCtrl:ActionSheetController,public db:DbService,private modalCtrl:ModalController,public location:Location,private activeRoute:ActivatedRoute, public router :Router) { 
    // this.activeRoute.queryParams.subscribe((params)=>{
      console.log(this.choosed_data)
    // })
  }

  ngOnInit() {


    if(this.db.childs.child_2 && this.choosed_data){
      this.choosed_data.child.map((res,i)=>{
         if(res.route == this.db.childs.child_2){
          this.active = i;
          setTimeout(() => {
            let check = document.getElementById(this.active + 'scroll') as HTMLInputElement;
            check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
          }, 700);
          this.db.sub_data = res.child;
          if(this.db.childs.child_3 && this.db.sub_data.length != 0){
            this.db.sub_data.map((r,j)=>{
              if(r.route == this.db.childs.child_3){
                setTimeout(() => {
                  let check = document.getElementById(j + 'scroll') as HTMLInputElement;
                  check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
                }, 700);
              }
            })
          }
         }
      })
    }
    // this.sub_val = false;
    // this.addActiveClass();
  }

  // ngAfterContentInit(){
  //   setTimeout(()=>{
  //     this.activeRoute.params.subscribe((res:any)=>{
  //         // console.log(this.pr)
  //         // console.log(this.choosed_data.child)
  //         // console.log(res);
  //         let data;
  //         this.db.active_cat = this.pr;
  //         for(let i=0; i<this.choosed_data.child.length; i++){
  //           if(this.pr == this.choosed_data.child[i].route){
  //              this.sub_data = this.choosed_data.child[i].child;
  //              data = document.getElementById(JSON.stringify(i)+'scroll')
  //             // data.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  //             // console.log(data)
  //           }
  //         }
  //         data.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  //       })
  //   },2000) 
  // }

  // ngAfterViewInit(){
  //   setTimeout( () =>{  
  //     let val = document.getElementById(this.db.childs.child_1+'g') as HTMLInputElement
  //     val.scrollIntoView({behavior:'auto',inline:'center',block:'center'})
  //   },500 )
  // }

  change_view() {
    this.db.product_box.view == 'Grid View' ?  this.db.product_box.view = 'List View' :  this.db.product_box.view = 'Grid View';
  }

  @HostListener('window:resize', ['$event'])
  private func(){
    this.db.product_box.view == 'Grid View';
    this.db.ismobile = this.db.checkmobile();
  }


  async select_sort(data) {
    const actionsheet = await this.actionSheetCtrl.create({
      header: data,
      buttons: this.db.sortings
    })
    await actionsheet.present();
    const { role } = await actionsheet.onDidDismiss();
    if(role != 'backdrop'){
      this.db.minimax_price.mini_price = '';
      this.db.minimax_price.max_price = '';
      this.get_cat_products.emit(role);
    }
  }


  async select_filter(data){

    // this.db.get_category_page_filter(this.db.choosed_product_id);

    const modal = await this.modalCtrl.create({
      component:FiltersPopupPage,
      componentProps:{
        // brands : this.category_filters,
        brands : this.db.category_filters
      }
    })
    await modal.present();
    
    let value =await modal.onWillDismiss();
    if(value.data == 'success'){
      this.get_cat_products.emit('')
    }

  }


  // AddClass
  // addActiveClass(){
  //     setTimeout(()=>{
  //       const myTag = document.querySelector(".active-category-name");
  //       if(myTag && myTag.classList.contains('active-category-name')){
  //         myTag.classList.add('scroll_To_center');
  //         this.removeActiveCenterClass(myTag);
  //       }
  //     }, 500);
  // }

  
  // / removeActiveCenterClass
  //  removeActiveCenterClass(arg){
  //     setTimeout(()=>{
  //       arg.classList.remove('scroll_To_center');
  //     }, 100);
  // }


  async open_product_popup(){
    const modal = await this.modalCtrl.create({
      component: ProductPopupPage,
      componentProps: {   categories : this.db.category }
    })
    await modal.present();
    let { data } = await modal.onWillDismiss();
    if(data && data != ''){
      this.db.mobile_category.parent = data
      this.gotoo(data,'child-1','')
    }
  }

  gotoo(route,child_no, index){

    // this.sub_val = true;
    if(route.default_view && !this.db.ismobile){
      this.db.product_box.view = route.default_view;
    } else {
      this.db.product_box.view = this.db.ismobile ? 'List View' : 'Grid View';
    }
    this.db.cat_bar = route.route;
    // console.log(route)
    // console.log(this.pr)
    if(child_no == 'child-1'){
        this.db.childs.child_1 = route.route;
        // this.db.mobile_category.child = []
        this.db.mobile_category.child = route
        this.db.childs.child_3,this.db.childs.child_2 = undefined;
        // [routerLink]="db.ismobile ? '/cl/'+item.route : '/c/'+item.route"
        // this.db.ismobile ? this.router.navigateByUrl('/cl/'+route.route) :  this.router.navigateByUrl('/c/'+route.route);
    } else if(child_no == 'child-2'){
      // this.is_active = true;
        this.db.childs.child_2 = route.route;
        this.db.mobile_category.child = route;
        // this.db.childs.child_3 = [];
        this.db.childs.child_3 = undefined;
        this.active = index;
        let check = document.getElementById(this.active + 'scroll') as HTMLInputElement
        check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
        // this.db.ismobile ? this.router.navigateByUrl('/cl/'+this.db.childs.child_1+'/'+route.route) :  this.router.navigateByUrl('/c/'+this.db.childs.child_1+'/'+route.route);
    }else if(child_no == 'child-3'){
      // this.is_active = false;
       this.db.childs.child_3 = route.route;
       this.active2 = index;
       let check = document.getElementById(this.active2 + '_scroll') as HTMLInputElement
       check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
      //  console.log("Child3",this.db.childs.child_3);
    }
      this.db.choosed_product_id = route.route;
      if(child_no == 'all-2' && this.db.choosed_product_id == undefined){
        this.db.childs.child_2 = '';
        this.db.sub_data = []
        this.db.childs.child_3 = undefined;
        this.db.choosed_product_id =  route;

        this.location.replaceState("/shop/" + route);
        // this.router.navigate(['/shop/' + route]);
        this.get_cat_products.emit('');
        // let check = document.getElementById(route + '_scroll') as HTMLInputElement
        // check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
      }
      if(child_no == 'all-3' && this.db.choosed_product_id == undefined){
        this.db.childs.child_2 = route;
        this.db.childs.child_3 = undefined;
        this.db.choosed_product_id = route;
        this.location.replaceState("/shop/" + this.db.choosed_product_id);
        // this.router.navigate(['/shop/' + this.db.choosed_product_id]);
        this.get_cat_products.emit('');
        // let check = document.getElementById(route + '_scroll') as HTMLInputElement
        // check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
      }

      // && child_no != 'all'
    if(this.db.choosed_product_id &&  this.db.choosed_product_id != '' && child_no != 'all-3' && child_no != 'all-2'){
      this.location.replaceState("/shop/"+ route.route);
      // this.router.navigate(['/shop/'+route.route]);
        this.get_cat_products.emit('');
    }
    //  this.addActiveClass();
     this.db.get_category_page_filter(this.db.choosed_product_id);
     this.db.load_mobile_categories();
  }



  show_view(data){
    // console.log(document.getElementById(data).scrollIntoView())
    let val = document.getElementById(data) as HTMLInputElement
    val.scrollIntoView({behavior:'auto',inline:'center',block:'center'})
    // console.log(d)
    // d.;
    // d.classList.add('selected__message')
  }

}
