<div class="d__flex desktop-filters-row">

    <div *ngIf="db.choosed_attributes.length != 0 || db.choosed_brands.length != 0">
        <ion-chip class="fnt-14 fnt-500"  (click)="clearall()">Clear All</ion-chip>
    </div>
  
    <div *ngFor="let item of db.choosed_attributes; let i = index" class="d__flex">  
        <ion-chip class="fnt-14" *ngFor="let value of item.value">{{value.includes('_') ? value.replace('_',' ') : value }} <ion-icon (click)="removeattribute(item.attribute,value)" name="close-outline"></ion-icon></ion-chip>
        <!-- <ion-chip *ngFor="let value of item.value">{{value.replace('_',' ')}} <ion-icon (click)="removeattribute(item.attribute,value)" name="close-outline"></ion-icon></ion-chip> -->
        <!-- <ion-chip *ngFor="let value of item.value.split(',')">{{value}} <ion-icon (click)="removeattribute(item.attribute,value)" name="close-outline"></ion-icon></ion-chip> -->
    </div>
  
    <div *ngFor="let item of db.choosed_brands;let i = index" class="d__flex">
      <ion-chip >{{item.includes('_') ? item.replace('_',' ') : item}} <ion-icon (click)="goroutes(item,i)"   name="close-outline"></ion-icon></ion-chip>
      <!-- <ion-chip >{{item}} <ion-icon (click)="goroutes(item,i)"   name="close-outline"></ion-icon></ion-chip> -->
          <!-- {{db.choosed_brands | json}} -->
        <!-- <ion-chip *ngFor="let item of db.choosed_brands;let i = index">{{item}} <ion-icon (click)="db.choosed_brands.splice(i,1)" (click)="goroute()"   name="close-outline"></ion-icon></ion-chip> -->
    </div>
  
    <div *ngIf="db.rating && db.rating != ' '">
        <ion-chip > <ion-icon class="star-icon" name="star"></ion-icon> Rating {{db.rating}} + <ion-icon (click)="db.rating = ''" (click)="goroute()"   name="close-outline"></ion-icon></ion-chip>
    </div>
  
  </div>