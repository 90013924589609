<div class="page-builders">
  <app-mobile-header2 *ngIf="db.ismobile && home"></app-mobile-header2>
  <div class="mini-100" [style.overlay__bg]="db.ismobile && home" [class.mb-60]="db.ismobile && (db.path == '/' || db.path == '/home') && db.path != '/tabs/home'">
    <div *ngFor="let item of viewContent">
      <!-- Mobile category-->
      <div class="d__flex gap-10 mobile__unique__category" *ngIf="db.ismobile && item.section_type == 'Custom Section' && item.section_name =='Mobile Category List'">
        <div *ngFor="let item of db.category" class="mouse category__item" routerLink="/c/{{item.route}}" routerDirection="forward">
          <div class="category__img" *ngIf="item.mobile_image"> <img [src]="db.product_img(item.mobile_image)"></div>
          <p class="m-0 category__name" [class.active-category-name]="db.childs.child_1 == item.name">{{item.category_name}}</p>
        </div>
      </div>
      <!-- Sliders -->
      <div *ngIf="item.section_type == 'Slider' && (item.section_name == 'Slider' || item.section_name == 'Slider(Image & Video)')">
        <div class="position-relative main-width margin-t-30">
          <div *ngIf="!db.ismobile && item.data" class="back" loop="slider" (click)="slidePrev()">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </div>
          <ion-slides class="web-slider mouse" pager="true" [options]="db.ismobile?mobile_slider:web_slider" loop="true">
            <ion-slide *ngFor="let data of item.data" (click)="redirect_next_page(data.redirect_url)">
              <img *ngIf="!db.ismobile" src="{{db.product_img(data.web_image)}}" defaultImage="" alt="">
              <img *ngIf="db.ismobile" src="{{db.product_img(data.mobile_app_image)}}" defaultImage="" alt="">
            </ion-slide>
          </ion-slides>
          <div *ngIf="!db.ismobile && item.data" class="next" (click)="slideNext()">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
      </div>
      <div *ngIf="item.section_name == 'Content Slider' && item.section_type == 'Static Section'" class="{{item.class_name}} m-10 sliders_sec" [class.main-width-full]="item.is_full_width == 1" [class.main-width]="item.is_full_width == 0">
        <div class="position-relative" style="min-height: 203px;">
          <div *ngIf="!db.ismobile" class="back" loop="slider" (click)="slidePrev()">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </div>
          <ion-slides class="web-slider mouse" pager="true" [options]="db.ismobile?mobile_slider:web_slider" loop="true">
            <ion-slide *ngFor="let data of item.sliders" (click)="redirect_next_page(data.redirect_url1)">
              <!-- <div> -->
                <img *ngIf="!db.ismobile && data.web_image1" src="{{db.product_img(data.web_image1)}}" class="web_image1" defaultImage="" alt="">
                <img *ngIf="db.ismobile && data.mobile_image1" src="{{db.product_img(data.mobile_image1)}}" class="mobile_image1" defaultImage="" alt="">
              <!-- </div> -->
            </ion-slide>
          </ion-slides>
          <div *ngIf="!db.ismobile" class="next" (click)="slideNext()">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
      </div>
      <!-- Section with  -->
      <div class="d__flex main-width Section_With_Content_and_Image bg_image" [class.about_us]="about_us && db.ismobile" *ngIf="item.section_type == 'Static Section' && item.section_name == 'Section With Content and Image'" [style.background-image]="'url('+db.product_img(item.bg_image)+')'">
        <div class="left_div">
          <div class="section">
            <p class="title-with m-0">{{item.title}}</p>
            <p class="sub_title m-0">{{item.subtitle}}</p>
            <p class="content m-0" [innerHtml]="item.content"></p>
            <ion-button *ngIf="item.btn_text" class="button" fill="clear" [routerLink]="item.btn_link" [style.color]="item.btn_text_color" [style.background]="item.btn_bg_color">{{item.btn_text}}</ion-button>
          </div>
        </div>
        <div *ngIf="item.right_image" class="right_div">
          <div class="image">
            <img src="{{db.product_img(item.right_image)}}" alt="">
          </div>
        </div>
      </div>

      <!-- Static Category List -->
      <div class="d__flex category_list main-width bg_image" [class.about_us]="about_us && db.ismobile" *ngIf="item.section_type == 'Static Section' && item.section_name == 'Static Category List'">
       <ul class="scroll d__flex">
         <li [routerLink]="i.redirect_url" class="grid-1-5" *ngFor="let i of item.list_items">
           <div>
             <img src="{{db.product_img(i.image)}}" alt="">
           </div>
           <div class="txt-c">
             <p class="sub_title">{{i.title}}</p>
           </div>
         </li>
       </ul>
      </div>
      <!-- Static Category List End -->

      <!-- Section with content -->
      <div class="d__flex main-width Section_With_Content_and_Image bg_image" [class.about_us]="about_us && db.ismobile" *ngIf="item.section_type == 'Static Section' && item.section_name == 'About us with image - creative'" [style.background-image]="'url('+db.product_img(item.bg_image)+')'">
        <div class="left_div">
          <div class="section">
            <p class="title-with m-0">{{item.title}}</p>
            <p class="sub_title m-0">{{item.subtitle}}</p>
            <p class="content m-0" [innerHtml]="item.aboutus_content"></p>
            <ion-button *ngIf="item.btn_text" class="button" fill="clear" [routerLink]="item.btn_link" [style.color]="item.btn_text_color" [style.background]="item.btn_bg_color">{{item.btn_text}}</ion-button>
          </div>
        </div>
        <div *ngIf="item.aboutus_img" class="right_div">
          <div class="image">
            <img src="{{db.product_img(item.aboutus_img)}}" alt="">
          </div>
        </div>
      </div>
      <!-- Banner 1 , 2 and 3 ,4 Column -->
      <div class="main-width section-padding" *ngIf="(item.section_name == 'Banner 1 Column' || item.section_name == 'Banner 2 Column' || item.section_name == 'Banner 3 Column' || item.section_name == 'Banner 4 column') && item.section_type == 'Static Section'" [style.background-image]="'url('+db.product_img(item.bg_img)+')'">
        <!-- <h2 class="title-card title">{{item.title}}</h2> -->
        <h2 *ngIf="item.title" class="bannerTitle">{{item.title}}</h2>
        <div class="banners d__flex hover15 column" [class.gap-15]="item.section_name == 'Banner 3 Column'">
          <div class="mouse ion-text-center imagehead" *ngIf="item.banner1 || item.image1" [class]="{'banner_1':(item.section_name == 'Banner 1 Column'),'banner_2':(item.section_name == 'Banner 2 Column'),'banner_3':(item.section_name == 'Banner 3 Column'),'banner_4':(item.section_name == 'Banner 4 column')}" (click)="redirect_next_page(item.banner1_route)">
            <img src="{{db.product_img(item.banner1 || item.image1)}}" alt="">
          </div>
          <div class="mouse ion-text-center imagehead" *ngIf="item.banner2 || item.image2" [class]="{'banner_1':(item.section_name == 'Banner 1 Column'),'banner_2':(item.section_name == 'Banner 2 Column'),'banner_3':(item.section_name == 'Banner 3 Column'),'banner_4':(item.section_name == 'Banner 4 column')}" (click)="redirect_next_page(item.banner2_route)">
            <img src="{{db.product_img(item.banner2 || item.image2)}}" alt="">
          </div>
          <div class="mouse ion-text-center imagehead" *ngIf="item.banner3 || item.image3" [class]="{'banner_1':(item.section_name == 'Banner 1 Column'),'banner_2':(item.section_name == 'Banner 2 Column'),'banner_3':(item.section_name == 'Banner 3 Column'),'banner_4':(item.section_name == 'Banner 4 column')}" (click)="redirect_next_page(item.banner3_route)">
            <img src="{{db.product_img(item.banner3 || item.image3)}}" alt="">
          </div>
          <div class="mouse ion-text-center imagehead" *ngIf="item.banner4 || item.image4" [class]="{'banner_1':(item.section_name == 'Banner 1 Column'),'banner_2':(item.section_name == 'Banner 2 Column'),'banner_3':(item.section_name == 'Banner 3 Column'),'banner_4':(item.section_name == 'Banner 4 column')}" (click)="redirect_next_page(item.banner4_route)">
            <img src="{{db.product_img(item.banner4 || item.image4)}}" alt="">
          </div>
        </div>
      </div>
      <!-- Fullwidth Content -->
      <div class="d__flex main-width1 full-header Section_With_Content_and_Image bg_image" [class.about_us]="about_us && db.ismobile" *ngIf="item.section_type == 'Static Section' && item.section_name == 'Fullwidth Banner'" [style.background-image]="'url('+db.product_img(item.bg_image)+')'">
        <div *ngIf="item.aboutus_img" class="">
          <div class="image">
            <img src="{{db.product_img(item.aboutus_img)}}" alt="">
          </div>
        </div>
      </div>
      <!-- Banner 5 -->
      <div class="main-width1 section-padding" *ngIf="item.section_name == 'Banner 5 Column' && item.section_type == 'Static Section'" [style.background-image]="db.product_img(item.bg_img)" [style.background-image]="'url('+db.product_img(item.bg_img)+')'">
        <h2 *ngIf="item.title" class="text-center title-card title">{{item.title}}</h2>
        <div class="banners d__flex hover14 column">
          <div *ngIf="item.banner1 || item.image1" class="banner_5 imagehead" (click)="redirect_next_page(item.banner1_route)">
            <img src="{{db.product_img(item.banner1 || item.image1)}}" alt="">
            <p class="cat-p">{{item.title1}}</p>
          </div>
          <div *ngIf="item.banner2 || item.image2" class="banner_5 imagehead" (click)="redirect_next_page(item.banner2_route)">
            <img src="{{db.product_img(item.banner2 || item.image2)}}" alt="">
            <p class="cat-p">{{item.title2}}</p>
          </div>
          <div *ngIf="item.banner3 || item.image3" class="banner_5 imagehead" (click)="redirect_next_page(item.banner3_route)">
            <img src="{{db.product_img(item.banner3 || item.image3)}}" alt="">
            <p class="cat-p">{{item.title3}}</p>
          </div>
          <div *ngIf="item.banner4 || item.image4" class="banner_5 imagehead" (click)="redirect_next_page(item.banner4_route)">
            <img src="{{db.product_img(item.banner4 || item.image4)}}" alt="">
            <p class="cat-p">{{item.title4}}</p>
          </div>
          <div *ngIf="item.banner5 || item.image5" class="banner_5 imagehead" (click)="redirect_next_page(item.banner5_route)">
            <img src="{{db.product_img(item.banner5 || item.image5)}}" alt="">
            <p class="cat-p">{{item.title5}}</p>
          </div>
        </div>
      </div>
      <!-- Banner 6 -->
      <div class="main-width1 section-padding" *ngIf="item.section_name == 'Banner 6 Column' && item.section_type == 'Static Section'" [style.background-image]="db.product_img(item.bg_img)" [style.background-image]="'url('+db.product_img(item.bg_img)+')'">
        <h2 class="text-center title-card title">{{item.title}}</h2>
        <div class="banners d__flex grid-banner-main hover15 column">
          <div class="banner-c1">
            <div *ngIf="item.banner1 || item.image1" class="banner_5 imagehead leftbanner" (click)="redirect_next_page(item.banner1_route)">
              <img src="{{db.product_img(item.banner1 || item.image1)}}" alt="">
            </div>
          </div>
          <div class="grid-banner">
            <div *ngIf="item.banner2 || item.image2" class="banner_5 imagehead centerbanner" (click)="redirect_next_page(item.banner2_route)">
              <img src="{{db.product_img(item.banner2 || item.image2)}}" alt="">
            </div>
            <div *ngIf="item.banner3 || item.image3" class="banner_5 imagehead centerbanner" (click)="redirect_next_page(item.banner3_route)">
              <img src="{{db.product_img(item.banner3 || item.image3)}}" alt="">
            </div>
            <div *ngIf="item.banner4 || item.image4" class="banner_5 imagehead centerbanner" (click)="redirect_next_page(item.banner4_route)">
              <img src="{{db.product_img(item.banner4 || item.image4)}}" alt="">
            </div>
            <div *ngIf="item.banner5 || item.image5" class="banner_5 imagehead centerbanner" (click)="redirect_next_page(item.banner5_route)">
              <img src="{{db.product_img(item.banner5 || item.image5)}}" alt="">
            </div>
          </div>
          <div class="banner-c2">
            <div *ngIf="item.banner6 || item.image6" class="banner_5 imagehead rightbanner" (click)="redirect_next_page(item.banner6_route)">
              <img src="{{db.product_img(item.banner6 || item.image6)}}" alt="">
            </div>
          </div>
        </div>
      </div>


      <div class="grid__view main-width w-100 row-pad row-pad1" *ngIf="(item.section_type == 'Lists' ) && item.data && item.data.length != 0 && (item.section_name == 'Product List')">
        <div class="d__flex header_wth_bg" [ngClass]="db.ismobile?'just-s':'just-s'" [ngStyle]="db.img_style(item.background_image,'img')">
          <!-- <div *ngIf="!db.ismobile"></div> -->
          
          <div *ngIf="item.title" class="title_with_back_g ">
            <h6 class="title-card title" [style.color]="item.heading_text_color ? item.heading_text_color: null"> {{item.title}}</h6>
          </div>

          <div class="d__flex Product_list">

            <div *ngIf="left_arrow && !db.ismobile" class="back-s" (click)="emitScroll('scroll','left')">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </div>

            <div class="list_sec" *ngFor="let value of item.data">
              <div class="image"><img src="{{db.product_img(value.product_image)}}" defaultImage="" alt=""/></div>
              <h6 [style.--line]="1" class="m-0 label webkit-text">{{value.item}}</h6>
            </div>

            <div *ngIf="!db.ismobile" class='next-s' (click)="emitScroll('scroll','right')">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
          </div> 

        </div>

       
      </div>


      <!-- List items scrollable grid  -->
      <div class="grid__view main-width row-pad" [style.background]="item.background_color?item.background_color:null" [class.featured_Product]="item.section_name == 'Featured Product'" *ngIf=" (item.section_type == 'Predefined Section' || item.section_type == 'Lists' || item.section_type == 'Custom Section') && item.data && item.data.length != '0' && (item.section_name != 'Shop By Category' && item.section_name != 'Product List' && item.section_name != 'Category Listing Mobile' && item.section_name != 'Category List Mobile' && item.section_name != 'Blog List' && item.section_name != 'Testimonials' && item.section_name != 'Shop By Cateogry' &&  item.section_name != 'Mobile Category List' && item.section_name != 'Categories List' && item.section_name != 'Category List' && item.section_name != 'Brands' && item.section_name != 'Category Products With Horizontal Background' && item.section_name != 'FAQs' && item.section_name != 'Category Products' && item.section_name != 'FAQ Style 1' && item.section_name != 'FAQ Style 2' && item.section_name != 'Blog Category Posts' && item.section_name != 'FAQ Category Posts')">
        <div class="d__flex header_wth_bg" [ngClass]="db.ismobile?'just-s':'just-c'" [ngStyle]="db.img_style(item.background_image,'img')">
          <div class="title_with_back_g">
            <!-- border grid_view_title-line -->
            <h6 class="title-card title" [style.color]="item.heading_text_color ? item.heading_text_color: null">{{item.title ? item.title : item.section_name}}<span class="title-card title second-color"> {{item.title1}}</span></h6>
            <!--  <span class="linecenter mobile-line"></span> -->
          </div>
            <div class="mouse view_all mr-5" [style.background]="item.view_all_bg_color?item.view_all_bg_color:null"  button (click)="item.route ? go_via_route(item.route):redirect_next_page(item.view_all_route)">
               <p class="m-0 " [style.color]="item.view_all_text_color?item.view_all_text_color:null">View All</p>
               <!-- <ion-icon name="arrow-forward-outline"></ion-icon> -->
           </div>
        </div>
        <app-product-box [change_button_color]="true" [scrollable]="db.ismobile?'grid-1-50':'grid-5'" [data]="item.data" (addto_cart)="addtocart($event)" (removeto_cart)="removetocart($event)" (add_wish)="addtowish($event)" (remove_wish)="addtowish($event)"> </app-product-box>
        <!-- <app-grid-view [scrollable]="true" [arrow]="!db.ismobile"  [bg_horizontal_image]="item.background_image" [img]="item.horizontal_image" [position]="item.position" (add_wish)="db.addtowish($event)" (remove_wish)="db.removewish($event)" (addto_cart)="addtocart($event)" (removeto_cart)="removetocart($event)" [data]="item.data" [type]="item.section_name"></app-grid-view> -->
      </div>
      <!-- horizontal -->
      <div class="grid__view main-width row-pad row-pad1" [class.featured_Product]="item.section_name == 'Featured Product'" *ngIf=" (item.section_type == 'Predefined Section' || item.section_type == 'Lists' || item.section_type == 'Custom Section') && item.data && item.data.length != '0' && (item.section_name == 'Category Products With Horizontal Background')">
        <div class="d__flex header_wth_bg" [ngClass]="db.ismobile?'just-s':'just-s'" [ngStyle]="db.img_style(item.background_image,'img')">
          <div *ngIf="!db.ismobile"></div>
          <div class="title_with_back_g ">
            <h6 class="title-card title" [style.color]="item.heading_text_color ? item.heading_text_color: null"> {{item.title ? item.title : item.section_name}}<span class="title-card title second-color"> {{item.title1}}</span></h6>&nbsp;
            <!-- <span class="linecenter mobile-line"></span> -->
          </div>
          <div class="mouse d__flex view_all mr-5" [style.background]="item.view_all_bg_color?item.view_all_bg_color:null"  button (click)="item.route ? go_via_route(item.route):redirect_next_page(item.view_all_route)">
               <p class="m-0 " [style.color]="item.view_all_text_color?item.view_all_text_color:null">View All</p>
               <!-- <ion-icon name="arrow-forward-outline"></ion-icon> -->
           </div>
        </div>
        <!-- [class.d__flex]="!db.ismobile" -->
        <div class="h_image_container" [class.d__flex]="!db.ismobile" [style.background]="item.bg_color?item.bg_color:null" [style.border-color]="item.border_color?item.border_color:null">
          <!-- *ngIf="!db.ismobile" -->
          <div class="h_image" ><img  src="{{db.product_img(item.horizontal_image)}}" defaultImage="" alt=""></div>
          <div class="product_box">
            <app-product-box [change_button_color]="true" [scroll_img]="item.horizontal_image" [scrollable]="db.ismobile?'grid-1-50':'grid-4'" [data]="product" [border_color]="item.border_color" (addto_cart)="addtocart($event)" (removeto_cart)="removetocart($event)" (add_wish)="addtowish($event)" (remove_wish)="addtowish($event)"> </app-product-box>
          </div>
        </div>
        <!-- <app-grid-view [scrollable]="true" [arrow]="!db.ismobile"  [bg_horizontal_image]="item.background_image" [img]="item.horizontal_image" [position]="item.position" (add_wish)="db.addtowish($event)" (remove_wish)="db.removewish($event)" (addto_cart)="addtocart($event)" (removeto_cart)="removetocart($event)" [data]="item.data" [type]="item.section_name"></app-grid-view> -->
      </div>
      <!-- blog list -->
      <ion-grid class="main-width1  blog__container row-pad " *ngIf="item.section_type == 'Predefined Section' && item.section_name == 'Blog List'" [style.background]="item.background_color?item.background_color:null">
        <ion-row class="text-center">
          <ion-col>
            <!-- <h4 class="sub-title">{{item.sub_title}}</h4> -->
            <div class="d__flex just-s" [class.py-10]="db.ismobile" [ngStyle]="db.img_style(item.background_image,'img')">
              <div *ngIf="!db.ismobile"></div>
              <div class="title_with_back_g d__flex" [class.just-c]="!db.ismobile">
                <span class="left"></span>
                <h2 *ngIf="!db.ismobile" class="title-card" [class.ion-text-left]="db.ismobile">{{item.title}}</h2>
                <h2 *ngIf="db.ismobile" [style.color]="item.heading_text_color ? item.heading_text_color: null" class="title-card" [class.ion-text-left]="db.ismobile">{{item.section_name}}</h2>
                <span class="right"></span>
                <!-- <div class="image"><img src="/assets/districo/title-line.svg" defaultImage="" alt="" /></div> -->
              </div>
              <div class="mouse d__flex view_all mr-5" button [style.background]="item.view_all_bg_color?item.view_all_bg_color:null" routerLink="/blog-list">
                <p class="m-0 " [style.color]="item.view_all_text_color?item.view_all_text_color:null">View more</p>
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
              <!-- <a *ngIf="db.ismobile" class="view-all-link" >
                 <ion-icon name="caret-forward-outline" class="forward-icon"></ion-icon>View All
               </a> -->
            </div>
            <ul class="home-card3-blog  d__flex">
              <li *ngFor="let data of item.data">
                <div class="card1 blog-s">
                  <a class="pos-r" [routerLink]="['/blog-detail/',data.route]">
                    <div class="img-wrapper blog-img">
                      <img src="{{db.product_img(data.thumbnail_image)}}">
                      <!-- <span class="ltx-date-big"><span class="ltx-date-day" [innerHTML]="changeDate(data.published_on)"></span><span class="ltx-date-my">{{data.published_on | date:'MMM yyyy' }}</span></span> -->
                    </div>
                  </a>
                  <a>
                    <a [routerLink]="['/blog-detail/',data.route]">
                      <h6 class="webkit-text" [style.--line]="1">{{data.title}}</h6>
                    </a>
                    <p class="webkit-text" [style.--line]="2">{{data.blog_intro}}</p>
                  </a>
                  <div class="d__flex date_w_button just-s">
                    <span class="date"><span class="post_on">Posted on :</span> <span class="date-day" [innerHTML]="changeDate(data.published_on)"></span><span class="date-my">{{data.published_on | date:'MMM yyyy' }}</span></span>
                    <ion-button class="blog-btn" fill="clear" [routerLink]="['/blog-detail/',data.route]">View More</ion-button>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <div class="view_m_b py-10" *ngIf="!db.ismobile">
               <ion-button class="blog-btn view-more-btn" fill="clear" routerLink="/blog-list">View All</ion-button>
             </div> -->
          </ion-col>
        </ion-row>
      </ion-grid>
      <div *ngIf="item.section_name == 'About Section' && item.section_type == 'Static Section'" class="about__section">
        <div>
          <img [src]="db.product_img(item.icon1)" alt="">
          <p class="m-0">{{item.title1}}</p>
        </div>
        <div>
          <img [src]="db.product_img(item.icon2)" alt="">
          <p class="m-0">{{item.title2}}</p>
        </div>
        <div>
          <img [src]="db.product_img(item.icon3)" alt="">
          <p class="m-0">{{item.title3}}</p>
        </div>
      </div>
      <!-- Banner 1 Colum With Title -->
      <div class="p-5" *ngIf="db.ismobile && item.section_type == 'Static Section' && item.section_name == 'Banner 1 Column With Title'">
        <div class="p-5">
          <h6 class="m-0 fnt-18">{{item.title}}</h6>
          <p class="m-0 fnt-12">{{item.subtitle}}</p>
        </div>
        <div class="image">
          <img src="{{db.product_img(item.banner1)}}" alt="">
        </div>
      </div>
      <!-- Column 2 Offers Banner-->
      <div class="p-10-l-r" *ngIf="item.section_type == 'Static Section' && item.section_name == 'Column 2 Offers Banner'">
        <div class="p-10-b">
          <h6 class="m-0 fnt-18">{{item.title}}</h6>
        </div>
        <div class="d__flex col-2-b">
          <div class="image">
            <img src="{{db.product_img(item.banner1)}}" alt="">
          </div>
          <div class="image">
            <img src="{{db.product_img(item.banner2)}}" alt="">
          </div>
        </div>
      </div>
      <!-- Column 3 Offers Banner-->
      <div class="p-10-l-r" *ngIf=" item.section_type == 'Static Section' && item.section_name == 'Column 3 Offers Banner'">
        <div class="p-10-b">
          <h6 class="m-0 fnt-18 fnt-700">{{item.title}}</h6>
        </div>
        <div class="d__flex col-3-b">
          <div class="image">
            <img src="{{db.product_img(item.banner1)}}" alt="">
          </div>
          <div class="image">
            <img src="{{db.product_img(item.banner2)}}" alt="">
          </div>
          <div class="image">
            <img src="{{db.product_img(item.banner3)}}" alt="">
          </div>
        </div>
      </div>
      <!-- News letter -->
      <div class="news-letter" *ngIf="!db.ismobile && item.section_type == 'Static Section'  && item.section_name == 'Newsletter'" [ngStyle]="db.img_style(item.background_image,'bg-image')">
        <div>
          <h2 *ngIf="item.title">{{item.title}}</h2>
          <span *ngIf="item.subtitle">{{item.subtitle}}</span>
          <div class="sus-button d__flex">
            <form class="p-10" [formGroup]="news_letters_email_form">
              <div class="input-item">
                <div class="custom-input d__flex" [ngClass]="focus.foucs1?'focused':''">
                  <ion-input (ionFocus)="focus.foucs1 = true" (ionBlur)="focus.foucs1 = false" type="text" placeholder="Enter Your Email Address" name="news_letters_email" formControlName="news_letters_email"> </ion-input>
                </div>
                <div *ngIf="submitted && !news_letters_email?.valid">
                  <p class="error" *ngIf="news_letters_email.errors.required">Email-Id is required</p>
                  <p class="error" *ngIf="news_letters_email.errors.email">Email-Id is not valid</p>
                </div>
              </div>
            </form>
            <!-- <ion-input placeholder="Enter Your Email Address" formControlName=""></ion-input> -->
            <ion-button expand="full" (click)="news_letters()">SUBSCRIBE NOW</ion-button>
          </div>
        </div>
      </div>
      <!-- App Download -->
      <ion-grid *ngIf="item.section_type == 'Static Section' && item.section_name == 'App Download'" [ngStyle]="img_style(item.section_bg,'full-bg-img_App_download')" class="img-bg1 bg-gray pad-40">
        <ion-row class="main-width">
          <ion-col class="table-c">
            <div class="table-c1 left">
              <h2 *ngIf="item.title" class="title-card mar-b-0 text-left f40" [innerHTML]="item.title"><br><span>{{item.title1}}</span> {{item.title2}}</h2>
              <span class="line"></span>
              <p class="app_desc" *ngIf="item.description" [innerHTML]="item.description"></p>
              <div class="mar-t-30 appicon">
                <a href="{{item.btn1_link}}"><img src="{{db.product_img(item.bt1_bg)}}"></a>
                <a href="{{item.btn2_link}}"><img src="{{db.product_img(item.btn2_bg)}}" class="pad-l-5"></a>
              </div>
            </div>
          </ion-col>
          <ion-col class="img text-center">
            <img src="{{db.product_img(item.right_image)}}">
          </ion-col>
        </ion-row>
      </ion-grid>
      <!-- Categories -->
      <div class="category-listing mb-20" *ngIf="item.section_type == 'Custom Section' && item.section_name == 'Category List' ">
        <app-marquee-text [marqueeText]="db.website_settings && db.website_settings.market_place_settings && db.website_settings.market_place_settings.order_cancel_disclaimer ? db.website_settings.market_place_settings.order_cancel_disclaimer : ''"></app-marquee-text>
        <div class="d__flex just-s">
          <h6 class="title-card px-10">{{item.title?item.title : item.section_name}}</h6>
          <div class="view_all mr-5 px-10" routerLink="/tabs/category"> <p class="m-0">See All</p> </div>
        </div>
        <ul class="d__flex gap-10">
          <li [class.display_none]="data.products_count <= 0" class="category-item" *ngFor="let data of item.data">
            <div class="image txt-c" [routerLink]="true?'/category-landing/'+data.route:null">
              <img *ngIf="data.category_image" src="{{db.product_img(data.category_image || data.image)}}" defaultImage="" alt="">
            </div>
            <div class="ion-text-center">
              <p class="m-0 cat-title webkit-text" [style.--line]="1">{{data.category_name | titlecase}}</p>
            </div>
          </li>
        </ul>
      </div>
      <!-- Mobile categories -->
      <!-- <div class="d__flex" *ngIf="item.section_type == 'Custom Section' && item.section_name == 'Category List' ">
         <div *ngFor="let data of item.data">
           <img [src]="db.product_img(data.category_image)">
           <div>{{data.category_name}}</div>
         </div>
       </div> -->
      <!-- Four Column-->
      <div class="four-column-section" *ngIf="db.ismobile && item.section_type == 'Lists' && item.section_name == 'Category Listing Mobile'">
        <div class="d__flex just-s p-10" [ngStyle]="db.img_style(item.background_image,'bg-image')">
          <div>
            <h6 class="m-0" [style.color]="item.heading_text_color">{{item.title}}</h6>
            <p class="sub-title m-0" [style.color]="item.heading_text_color">{{item.subtitle}}</p>
          </div>
          <div class="view-all">
            <ion-button class="m-0 fnt-12" fill="clear" [style.background]="item.view_all_bg_color" [routerLink]="item.view_all_route" [style.color]="item.view_all_text_color">{{item.view_all_text}}</ion-button>
            <ion-icon *ngIf="!item.view_all_text" name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
        <div class="d__flex four-column p-10" [style.background-color]="item.background_color? item.background_color: ''">
          <div class="product" *ngFor="let data of item.data">
            <div class="image">
              <img [lazyLoad]="db.product_img(data.image)">
            </div>
            <div class="fnt-12 ion-text-center">{{data.item_title}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="item.section_type == 'Static Section' && item.section_name =='Left Content Right Image with Subtitle'"
        class="container_reverce d__flex main-width {{item.class_name}} animate">
        <div class="content_container">
          <h5 class="top_title subtitle mobile_center heading">{{item.subtitle}}</h5>
          <h2 class="title">{{item.title}}</h2>
          <p class="description_text description mobile_center">
            {{item.description}}
          </p>
          <div *ngIf="item.btn_text" class="knowmore_button txt-c"
            (click)="redirect_next_page(item.btn_text.btn_redirect_url)">
            {{item.btn_text.btn_text}}
          </div>
        </div>
        <div class="image_container">
          <div class="image txt-r">
            <img [src]="db.product_img(item.right_img)" alt="banner_img">
          </div>
        </div>
      </div>


      <div class="Left_Content_Right_Image Left_Content_Right_Image_ctr bg_image {{item.class_name}} animate"
      [class.about_us]="about_us && db.ismobile"
      *ngIf=" item.section_type == 'Static Section' && item.section_name == 'Left Content Right Image with Centred Title'"
      [style.background-image]="'url('+db.product_img(item.bg_image)+')'"
      [class.main-width-full]="item.is_full_width == 1" [class.main-width]="item.is_full_width == 0">
     
      <h2 class="m-0 title">{{item.title}}</h2>

      <div class="d__flex wrap">
        <div class="left_div">
          <div class="section">
            <!-- <h2 class="m-0 title">{{item.title}} <span class="title1">{{item.title1}}</span></h2> -->
            <!-- <h4 class="m-0 subtitle">{{item.content}}</h4> -->
            <p class="content" [innerHtml]="item.content"></p>
          </div>
        </div>
        <div *ngIf="item.right_image" class="right_div">
          <div class="image txt-r">
            <img src="{{db.product_img(item.right_image)}}" alt="" class="right_image1 right_image">
          </div>
        </div>
      </div>
    </div>

      <div class="Left_Content_Right_Image bg_image {{item.class_name}} animate"
        [class.about_us]="about_us && db.ismobile"
        *ngIf=" item.section_type == 'Static Section' && item.section_name == 'Left Content Right Image'"
        [style.background-image]="'url('+db.product_img(item.bg_image)+')'"
        [class.main-width-full]="item.is_full_width == 1" [class.main-width]="item.is_full_width == 0">
        <div class="d__flex wrap">
          <div class="left_div">
            <div class="section">
              <h2 class="m-0 title">{{item.title}} <span class="title1">{{item.title1}}</span></h2>
              <h4 class="m-0 subtitle">{{item.subtitle}}</h4>
              <p class="content" [innerHtml]="item.content"></p>
              <ion-button *ngIf="item.btn_text" class="button" [class.mobile_left_img]="db.ismobile" fill="clear" (click)="db.route_url(item.redirect_url)" [style.color]="item.btn_text_color" [style.background]="item.btn_bg_color">{{item.btn_text}}</ion-button>
            </div>
          </div>
          <div *ngIf="item.right_image" class="right_div">
            <div class="image txt-r">
              <img src="{{db.product_img(item.right_image)}}" alt="" class="right_image1 right_image">
            </div>
          </div>
        </div>
      </div>

      <!-- Three column section -->
      <div class="column-3" *ngIf="db.ismobile && ( item.section_type == 'Lists') && item.data && item.data.length != '0' && item.section_name != 'Shop By Category' && item.section_name == 'Category Products 1 - 3 List'">
        <div class="d__flex just-s header-row">
          <div class="d__flex">
            <div class="image"><img src="{{db.product_img(item.title_image)}}"></div>
            <div>
              <h6 class="m-0">{{item.title}}</h6>
              <p class="m-0 fnt-14">{{item.subtitle}}</p>
            </div>
          </div>
          <div class="view-all">
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>
        <ion-row>
          <ion-col class="col-1" size="6" [routerLink]="true?'/pr/'+item.data[0].route:null">
            <div class="image"><img src="{{db.product_img(item.data[0].product_image)}}"></div>
            <div class="description">
              <p class="m-0 product-name">{{item.data[0].item | titlecase}}</p>
              <p class="m-0 product-price" *ngIf="item.data[0].price != '0.00'">{{item.data[0].price | currency:db.website_settings.currency}}</p>
            </div>
          </ion-col>
          <ion-col class="col-2" size="6">
            <div class="">
              <div class="grid-1" [routerLink]="true?'/pr/'+item.data[1].route:null">
                <div class="image"><img src="{{db.product_img(item.data[1].product_image)}}"></div>
                <div class="description">
                  <p class="m-0 product-name">{{item.data[1].item | titlecase}}</p>
                  <p class="m-0 product-price" *ngIf="item.data[1].price != '0.00'">{{item.data[1].price | currency:db.website_settings.currency}}</p>
                </div>
              </div>
              <div class="grid-2" [routerLink]="true?'/pr/'+item.data[2].route:null">
                <div class="image"><img src="{{db.product_img(item.data[2].product_image)}}"></div>
                <div class="description">
                  <p class="m-0 product-name">{{item.data[2].item | titlecase}}</p>
                  <p class="m-0 product-price" *ngIf="item.data[2].price != '0.00'">{{item.data[2].price | currency:db.website_settings.currency}}</p>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
      <!-- [ngStyle]="db.img_style(item.bg,'full-bg-img')" -->
      <ion-grid class="birthday_club" *ngIf="item.section_type == 'Static Section' && item.section_name == 'BirthDay Club Registration'">
        <ion-icon class="lft-img" src="/assets/imgs/lft-bdy.svg"></ion-icon>
        <ion-row class="text-center main-width11">
          <div class="ion-text-center w-100">
            <div class="ion-text-center headings">
              <h6 class="fnt-20 m-0 ion-text-center">Birthday club</h6>
              <p class="m-0 fnt-12">Register to get offers</p>
            </div>
            <div>
              <form [formGroup]="emailform">
                <ion-item lines='none'>
                  <ion-input type="number" [class.focused]="focus.birth_date" (ionFocus)="focus.birth_date = true" (ionBlur)="focus.birth_date = false" name="birth_date" formControlName="birth_date" required="true" placeholder="Enter your Birth Date"></ion-input>
                </ion-item>
                <div *ngIf="submitted && !birth_date?.valid">
                  <p class="error m-0" *ngIf="birth_date.errors.required">Birthday date is required</p>
                </div>
                <ion-item lines="none">
                  <ion-select name="month" [class.focused]="focus.month" (ionFocus)="focus.month = true" (ionBlur)="focus.month = false" required="true" formControlName="month" class="form-input form-select" interface="popover" value="{{birthday_info.month}}">
                    <ion-select-option *ngFor="let m of month_list" value="{{m.name}}">{{m.name}}</ion-select-option>
                  </ion-select>
                </ion-item>
                <div *ngIf="submitted && !month?.valid">
                  <p class="error m-0" *ngIf="month.errors.required">Birthday date is required</p>
                </div>
                <ion-item lines='none'>
                  <ion-input type="email" [class.focused]="focus.email" (keyup.enter)="birthday_club()" (ionFocus)="focus.email = true" (ionBlur)="focus.email = false" required="true" name="email" formControlName="email" placeholder="Enter your email address"></ion-input>
                </ion-item>
                <div *ngIf="submitted && !email.valid">
                  <p class="m-0 error" *ngIf="email.errors.required"> Email-Id is required </p>
                  <p class="m-0 error" *ngIf="email.errors.email"> Not a valid Email </p>
                </div>
              </form>
              <div class="ion-text-center">
                <ion-button fill="clear" (click)="birthday_club()">Register</ion-button>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </ion-row>
        <ion-icon class="rht-img" src="/assets/imgs/rht-bdy.svg"></ion-icon>
      </ion-grid>
      <div class="d__flex" *ngIf="item.section_type == 'Predefined Section' && item.section_name == 'My Orders And Wishlist' && db.cust_name">
        <img *ngIf="!db.ismobile" class="w-100" [src]="db.product_img(item.orders_bg)" routerLink="/my-profile/my-orders">
        <img *ngIf="db.ismobile" class="w-50" [src]="db.product_img(item.orders_mobile_bg)" routerLink="/my-orders">
        <img *ngIf="!db.ismobile" class="w-100" [src]="db.product_img(item.wishlist_bg)" routerLink="/my-profile/wishlist">
        <img *ngIf="db.ismobile" class="w-50" [src]="db.product_img(item.mobile_wishlist_bg)" routerLink="/tabs/wishlist">
      </div>
      <div *ngIf="item.section_type == 'Static Section' && item.section_name == 'Categories List'">
        <h6 class="title-card pl-10"><strong>{{item.title}}</strong></h6>
        <app-sliders *ngIf="db.ismobile" class="mobile-category" [category_slide]="item"></app-sliders>
        <div class="desktop-category">
          <div class="image"><img lazyLoad="{{db.product_img(item['image1'])}}" defaultImage="" alt=""></div>
          <div class="image"><img lazyLoad="{{db.product_img(item['image2'])}}" defaultImage="" alt=""></div>
          <div class="image"><img lazyLoad="{{db.product_img(item['image3'])}}" defaultImage="" alt=""></div>
          <div class="image"><img lazyLoad="{{db.product_img(item['image4'])}}" defaultImage="" alt=""></div>
          <div class="image"><img lazyLoad="{{db.product_img(item['image5'])}}" defaultImage="" alt=""></div>
          <div class="image"><img lazyLoad="{{db.product_img(item['image6'])}}" defaultImage="" alt=""></div>
        </div>
      </div>
      <div class="container {{item.class_name}} animate" *ngIf="item.section_type == 'Custom Section' && (item.section_name =='FAQ Style 1' || item.section_name =='FAQ Style 2' || item.section_name == 'FAQ Category Posts')">
        <app-faq [item]="item"></app-faq>
      </div>
      <!-- Sub Header -->
      <div *ngIf="item.section_type == 'Static Section' && item.section_name =='Sub Header'" class="aboutus bt1_bg {{item.class_name}}" [ngStyle]="img_style(item.bt1_bg,'full-bg-img__')">
        <!-- <ion-row>    text-align: center;
    margin-top: 0;
    padding-top: 30px;
      <ion-col> -->
        <div class="header-title">
          <h1 class="white-text_ title">{{item.title}}</h1>
          <div class="Breadcrumb mb-15 robot-font">
            <span class="mr-5 mouse white-text font-14" routerLink="/" routerDirection="root">Home</span>
            <ion-icon class="top-v  white-text" name="chevron-forward"></ion-icon>
            <span class="mouse mr-5 primary primary-name white-text font-14">{{item.title}}</span>
          </div>
        </div>
        <!-- </ion-col>
   </ion-row> -->
      </div>
      <!--Shop by Category section for mobile-->
      <div class="row-pad " *ngIf="item.section_type == 'Predefined Section'&& item.data && item.data.length != '0' && item.section_name == 'Shop By Category' && false ">
        <div class="title_with_back_g d__flex">
          <span class="left"></span>
          <h2 class="title-card shop_title">{{item.section_name}}</h2>
          <span class="right"></span>
          <!-- <div class="image"><img src="/assets/districo/title-line.svg" defaultImage="" alt="" /></div> -->
        </div>
        <div class="mobile-category-section d__flex just-c d__grid">
          <a class="card-category category-product ion-text-center" *ngFor="let list of item.data;let index= index" [routerLink]="!db.ismobile?'/c/'+list.route:'/c/'+list.route">
            <div class="image"><img *ngIf="list.mobile_image" src="{{db.product_img(list.mobile_image)}}" defaultImage="" alt="" /></div>
            <p class="cat-p">{{list.category_name}} </p>
          </a>
        </div>
      </div>
      <!--Shop by Category section for web -->
      <div class="shop_by_cat main-width1" *ngIf="item.section_type == 'Predefined Section' && item.data && item.data.length != '0' && (item.section_name == 'Shop By Category' || item.section_name == 'Shop By Cateogry') ">
        <h2 class="title-card shop_title">{{item.section_name}}</h2>
        <div class="d__grid1">
          <a class="category-product_shop ion-text-center card3 mouse " *ngFor="let list of item.data;let index= index" [routerLink]="!db.ismobile?'/c/'+list.route:'/c/'+list.route">
            <div class="image img-wrapper"><img *ngIf="list.mobile_image" src="{{db.product_img(list.mobile_image)}}" defaultImage="" alt="" /></div>
            <p class="cat-p-shop webkit-text" [style.--line]="1">{{list.category_name}} </p>
          </a>
        </div>
      </div>
      <!--Categories List -->
      <div class="row-pad main-width1 shopbycategory" *ngIf="item.section_type == 'Lists' && item.data && item.data.length != '0' && item.section_name == 'Categories List'">
        <div class="header_wth_bg">
          <h2 class="title-card ">{{item.title}}</h2>
          <span></span>
          <!-- <div class="image"><img src="/assets/districo/title-line.svg" defaultImage="" alt="" /></div> -->
        </div>
        <div class="cat_list_card3_g">
          <a class="category-product  ion-text-center cat_list_card3 mouse " *ngFor="let list of item.data;let index= index" [routerLink]="!db.ismobile?'/c/'+list.route:'/c/'+list.route">
            <div class="image img-wrapper"><img *ngIf="list.image" src="{{db.product_img(list.image)}}" defaultImage="" alt="" /></div>
            <p *ngIf="list.item_name || list.item_title" class="cat-p">{{list.item_name || list.item_title}} </p>
          </a>
        </div>
      </div>
      <!--category list -->
      <div class="row-pad main-width1 section-padding" *ngIf="item.section_type == 'Lists' && item.data && item.data.length != '0' && item.section_name == 'Category List'">
        <div class="d__flex just-c">
          <div class="title_with_back_g">
            <h6 class="title-card title">{{item.title}}<span class="title-card title second-color"> {{item.title1}}</span></h6>
            <span class="linecenter mobile-line"></span>
          </div>
        </div>
        <div class="cat_list_card3_g">
          <a class="category-product  c_list ion-text-center cat_list_card3 mouse " *ngFor="let list of item.data;let index= index" [routerLink]="!db.ismobile?'/c/'+list.route:'/c/'+list.route">
            <div class="image img-wrapper"><img *ngIf="list.image" src="{{db.product_img(list.image)}}" defaultImage="" alt="" /></div>
            <p *ngIf="list.item_name || list.item_title" class="cat-p">{{list.item_name || list.item_title}} </p>
          </a>
        </div>
      </div>
      <!-- Brands -->
      <div class="Brands main-width1" *ngIf="item.section_type == 'Custom Section' && item.section_name == 'Brands'">
        <div class="d__flex" [class.just-c]="!db.ismobile" [class.just-s]="db.ismobile" [ngStyle]="db.img_style(item.background_image,'img')">
          <!-- <div *ngIf="!db.ismobile"></div> -->
          <div class="title_with_back_g  d__flex" [class.margin-none]="item.section_name == 'Brands'" [class.just-c]="!db.ismobile">
            <span class="left"></span>
            <h2 class="title-card">{{item.section_name}}</h2>
            <span class="right"></span>
            <!-- <div class="image"><img src="/assets/districo/title-line.svg" defaultImage="" alt="" /></div> -->
          </div>
        </div>
        <div class="relative">
          <div #scrollContent class="container d__flex" (scroll)="scrolled($event)">
            <div *ngIf="left_arrow && !db.ismobile" class="back-s" (click)="emitScroll('scroll','left')">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </div>
            <div class="grid__item mouse" *ngFor="let list of item.data">
              <div class="image" routerLink="/brands/{{list.route}}">
                <img *ngIf="list.brand_logo" src="{{db.product_img(list.brand_logo)}}" defaultImage="" alt="">
              </div>
            </div>
            <div *ngIf="!db.ismobile" class='next-s' (click)="emitScroll('scroll','right')">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
          </div>
        </div>
        <!-- Banner 4 column -->
        <div class="d__flex" *ngIf="item.section_name == 'Banner 4 Column' || item.section_name == '4 Column Banner Mobile' && false">
          <div *ngIf="item.banner1 || item.image1"><img lazyLoad="{{db.product_img(item.banner1 || item.image1)}}" alt="" defaultImage=""></div>
          <div *ngIf="item.banner2 || item.image2"><img lazyLoad="{{db.product_img(item.banner2 || item.image2)}}" alt="" defaultImage=""></div>
          <div *ngIf="item.banner3 || item.image3"><img lazyLoad="{{db.product_img(item.banner3 || item.image3)}}" alt="" defaultImage=""></div>
          <div *ngIf="item.banner4 || item.image4"><img lazyLoad="{{db.product_img(item.banner4 || item.image4)}}" alt="" defaultImage=""></div>
        </div>
        <!-- 4 Column Product Images -->
        <div *ngIf="item.section_name == '4 Column Product Images'" [ngStyle]="db.img_style(item.background_color,'color')">
          <ion-item [ngStyle]="db.img_style(item.background_image,'img')">
            <h2 [ngStyle]="db.img_style(item.color,'colorcode')">{{item.headertext}}</h2>
          </ion-item>
          <div><img lazyLoad="{{db.product_img(item.product1)}}" alt="" /></div>
          <div><img lazyLoad="{{db.product_img(item.product2)}}" alt="" /></div>
          <div><img lazyLoad="{{db.product_img(item.product3)}}" alt="" /></div>
          <div><img lazyLoad="{{db.product_img(item.product4)}}" alt="" /></div>
        </div>
        <!-- 2 column video section -->
        <div class="" *ngIf="item.section_type == 'Static Section' && item.section_type == '2 Column Video Section'">
        </div>
        <!-- 3 column video section -->
        <div class="" *ngIf="item.section_type == 'Static Section' && item.section_type == '3 Column Video Section'">>
        </div>
        <!-- 4 column video section -->
        <div class="" *ngIf="item.section_type == 'Static Section' && item.section_type == '3 Column Video Section'">
        </div>
      </div>
    </div>
  </div>
</div>
