import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-tabs-button',
  templateUrl: './tabs-button.component.html',
  styleUrls: ['./tabs-button.component.scss'],
})
export class TabsButtonComponent implements OnInit {

  @Output() menu_name = new EventEmitter<any>();
  @Input() tabs_array;
  @Input() nobackground;
  @Input() type;
  @Input() border_none;
  @Input() sticky;
  @Input() padding;
  @Input() change_color_none;

  constructor(public route:Router,public db:DbService) { }

  ngOnInit() {
    this.sub = this.db.tab_center.subscribe(res=>{
      console.log(res)
      if(res){
       let check = document.getElementById(res + 'scroll') as HTMLInputElement;
        if(check){
          check.scrollIntoView({ behavior: 'auto',block :'center' , inline :'center' })
        }
      }
    })
  }

  sub:any
  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  check_active(tabs_array,index,tab){
     if(tabs_array && !this.change_color_none){
      tabs_array.map((t,i)=>{
         if(i==index){
          t.isActive=true;
         
         }
         else{
          t.isActive=false
         }
      })
     }
     if(tab){ 
      this.menu_name.emit(tab);
      let check = document.getElementById(index + 1 +'scroll') as HTMLInputElement;
      check.scrollIntoView({ behavior: 'auto',block :'center' , inline  :'center' })
    } 
    //  this.route.navigateByUrl('Profile-candidate/'+tab)
 }
}
