import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import $ from 'jquery';
import { LoadingController, ModalController } from '@ionic/angular';
// import { ProfilePicEditComponent } from '../profile-pic-edit/profile-pic-edit.component';
import { takeUntil, take } from 'rxjs/operators';   
import { from } from 'rxjs';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
@Input() type;
@Input() dashboard_info;
@Input() type_profile;
@Input() profile_menu;
@Output() menu_name = new EventEmitter<any>();
 count;
 count1;
  constructor(public db:DbService, public modalCtrl : ModalController) { 
   
  }

  ngOnInit() {

  }



check_active(tabs_array,index,tab){
  if(tabs_array){
   tabs_array.map((t,i)=>{
      if(i==index){
       t.isActive=true
      }
      else{
       t.isActive=false
      }
   })
  }
  if(tab){ 
   this.menu_name.emit(tab);
 } 
}


}
