<div>
  <div class="d__flex categories_ gap-10 mobile__unique__category" *ngIf="choosed_data && choosed_data.child && choosed_data.child.length != 0">
    <!-- {{item.route}} -->
    <div class="all_" (click)="gotoo(db.childs.child_1,'all-2', -1)" [ngClass]="(!db.childs.child_2) ? 'selected': db.childs.child_1.route == pr ? 'selected' : ''" [id]="-1+'scroll'">
      <h6 class="m-0">All</h6>
    </div>
    <div class="d__flex data_list" [class.display_none]="item.products_count <= 0" *ngFor="let item of choosed_data.child; let i = index" (click)="db.sub_data = item.child" [ngClass]="(item.route == db.childs.child_2)?'selected':''" (click)="gotoo(item,'child-2',i)" [id]="i+'scroll'">
      <img *ngIf="item.products_count > 0" class="" [src]="db.product_img(item.mobile_image)" alt="">
      <h6 *ngIf="item.products_count > 0" class="m-0 webkit-text" [style.--line]="1">{{item.category_name}}</h6>
    </div>
    <!-- <div class="d__flex data_list" *ngFor="let item of choosed_data.child; let i = index" (click)="sub_data = item.child" [ngClass]="(item.route == db.childs.child_2)?'selected':''" [class.selected]="item.route == pr && db.cat_bar == pr " (click)="gotoo(item,'child-2',i)" [id]="i+'scroll'">
                <img src="" alt="">
                <h6 class="m-0 webkit-text" [style.--line]="1">{{item.category_name}}</h6>
            </div> -->
  </div>
  <!-- <div>

        </div> -->
  <div class="d__flex categories_ gap-10 px-10" *ngIf="db.sub_data && db.sub_data.length != 0">
    <div class="third_cat" (click)="gotoo(db.childs.child_2,'all-3', -1)" [ngClass]="(!db.childs.child_3 && db.childs.child_2) ?'active':''" [id]="-1+'_scroll'">
      <h6 class="m-0">All</h6>
    </div>
    <!-- <div class="third_cat" (click)="gotoo(db.childs.child_2,'all-3', -1)" [ngClass]="is_active ?'active':''" [id]="-1+'_scroll'"><h6 class="m-0"  >All</h6></div> -->
    <div class="d__flex data_list__" *ngFor="let item of db.sub_data; let i = index" [ngClass]="(item.route == db.childs.child_3) ?'active':''" (click)="gotoo(item,'child-3',i)" [id]="i+'_scroll'">
      <!-- <img src="" alt=""> -->
      <h6 *ngIf="item.products_count > 0" class="m-0 webkit-text" [style.--line]="1">{{item.category_name}}</h6>
    </div>
  </div>
</div>
<!-- Mobile Options -->
<div class="mobile-opts">
  <div class="mobile-option">
    <div class="option" (click)="change_view()">
      <ion-icon [name]="(db.product_box.view != 'Grid View') ? 'apps-sharp' : 'list-outline'"></ion-icon>
      <ion-label>{{ (db.product_box.view == 'Grid View') ? 'List':'Grid'}}</ion-label>
    </div>
    <div class="option" (click)="select_sort('sort')">
      <!-- <ion-icon name="swap-horizontal-outline"></ion-icon> -->
      <ion-icon src="/assets/icon/sort_by.svg"></ion-icon>
      <ion-label>Sort By</ion-label>
    </div>
    <div class="option" (click)="select_filter('filter')">
      <!-- <ion-icon name="funnel-outline"></ion-icon> -->
      <ion-icon src="/assets/icon/filters.svg"></ion-icon>
      <ion-label>Filter</ion-label>
    </div>
    <!-- <div class="fresh_offer">
                <h4 class="m-0">Explore the</h4>
                <h3 class="m-0">Fresh offering</h3>
            </div> -->
  </div>
  <!-- *ngIf="db.parent_category"  -->
  <div class="d__flex gap-10 mobile__unique__category" *ngIf="false">
    <!--  [routerLink]="db.ismobile ? '/cl/'+item.route : '/c/'+item.route" -->
    <div *ngFor="let item of db.category;let i = index;" [id]="item.route+'g'" class="mouse category__item" (click)="show_view(item.route+'g')" (click)="gotoo(item,'child-1','')">
      <!-- <div class="category__img" *ngIf="item.mobile_image"> <img  [src]="db.product_img(item.mobile_image)"></div> -->
      <p class="m-0 category__name" [class.active-category-name]="db.childs.child_1 == item.route">{{item.category_name}}</p>
    </div>
  </div>
  <div class="mobile-category-row d__flex" *ngIf="db.mobile_category.parent && db.mobile_category.parent.child && db.mobile_category.parent.child.length != 0 && false">
    <div class="d__flex mobile-main-category just-s mobile-category-item mouse" *ngIf="db.mobile_category.parent && db.show_parent_and_Category" (click)="open_product_popup()">
      <div class="image" *ngIf="db.mobile_category.parent.mobile_image && false"><img src="{{db.product_img(db.mobile_category.parent.mobile_image)}}" defaultImage="" alt=""></div>
      <p class="m-0">{{db.mobile_category.parent.category_name}}</p>
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>
    <!-- <div  [class]="db.show_parent_and_Category ? 'parent_child d__flex' :'d__flex category__childrens_div'" *ngIf="db.mobile_category.child ">
                <div class="mobile-category-item category__childrens d__flex mouse"   *ngFor="let item of db.mobile_category.child.child" (click)="gotoo(item,'child-2','')" [ngClass]="(item.route == db.childs.child_2)?'active-child':''" [class.active-category__childrens_selected]="db.childs.child_2 == item.route" >
                    <div class="image" *ngIf="item.mobile_image" ><img src="{{db.product_img(item.mobile_image)}}" defaultImage="" alt=""> </div>
                    <p class="m-0 mobile__category__name" >{{item.category_name}}</p>
                </div>
            </div> -->
  </div>
  <!-- {{db.mobile_category.child | json}} -->
  <div class="d__flex sub-children-row" *ngIf="db.mobile_category.child && db.mobile_category.child.length != 0 && false">
    <!-- <div class="sub-child mouse" *ngIf=" mobile_child.route" (click)="gotoo(db.childs.child_2,'all')"  [ngClass]="(!db.childs.child_3)?'active-child':''">All</div> -->
    <div class="sub-child mouse" *ngFor="let sub_child of db.mobile_category.child.child" [ngClass]="(sub_child.route == db.childs.child_3)?'active-child':''">
      <!-- {{sub_child.route}} = {{db.childs.child_3}} -->
      <p class="m-0 fnt-14" (click)="gotoo(sub_child,'child-3','')"> {{sub_child.category_name}}</p>
    </div>
  </div>
</div>
