import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import {
  AlertController
} from '@ionic/angular';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss'],
})
export class WishListComponent implements OnInit {
  @Input() no_footer;
  @Input() load;
  @Input() yorcart_wish_web;
  constructor(public db: DbService,private alertCtrl: AlertController) {

  }

  ngOnInit() {
    this.db.show_wish = false;
    if (this.load) 
      this.get_cart_item();
     else 
      this.get_cart_item();
  }

  doRefresh(event) {
    this.get_cart_item();
    setTimeout(() => {
      event.target.complete();
    }, 700);
  }

  get_cart_item() {
    if (localStorage['customerRefId'] != undefined) 
      this.db.get_cart_item();
  }

  removewish(list, que) {
    var datas = {
      name: list.name,
      customer: localStorage['customerRefId'],
      user: ""
    }
    this.db.delete_cart_items(datas).subscribe(data => {
      this.get_cart_item()
    }, error => {
       console.log(JSON.stringify(error.json()));
    });
  }

  addtowish(list, que) {
    var datas = {
      item_code: list.product,
      qty: que,
      qty_type: "",
      rate: list.price,
      cartType: "Shopping Cart",
      customer: localStorage['customerRefId'],
      attribute: '',
      attribute_id: ''
    }
    this.db.insert_cart_items(datas).subscribe(data => {
      this.get_cart_item()
      this.removewish(list, que)
    }, error => { console.log(JSON.stringify(error.json())); });
  }


  movetocart(list) {
    let data = {
      name: list.name,
    }
    this.db.movetocart(data).subscribe(res => {
      if (res.message && res.message.status == 'Failed') {
        this.db.alert(res.message.message);
        // this.db.alert('Sorry! no stock available');
        return;
      }
      else{
        this.get_cart_item();
      }
    }, error => { console.log(JSON.stringify(error.json())); })
  }

  async moveallto_Cart() {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: 'Are you sure to Move all to cart items?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => { }
        },
        {
          text: 'Ok',
          handler: () => {
            this.moveallto_Carts()
          }
        }
      ]
    })

    await alert.present();
  }

  moveallto_Carts() {
    let data = { user: localStorage['customerUser_id'], customer: localStorage['customerRefId'] }
    this.db.movealltocart(data).subscribe(res => {
      if (res.message.status == "Failed") 
        this.db.alert(res.message.message);
      else if (res.message.status == "Success") {
        this.get_cart_item();
        this.db.wishcart = false;
        this.db.mycart = true;
      }
    }, error => { console.log(JSON.stringify(error.json())); })
  }

}
