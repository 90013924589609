
<!-- clients1 -->
<div class="Brands main-width animate {{item.class_name}}" *ngIf="item.section_type == 'Static Section' && item.section_name =='Clients Style 1'">
    <div class="d__flex" [class.just-c]="!db.ismobile" [class.just-s]="db.ismobile" [ngStyle]="db.img_style(item.background_image,'img')">
    <div class="box__1" [class.margin-none]="item.section_name == 'Brands'" [class.just-c]="!db.ismobile">
       <h2 *ngIf="item.title" class="m-0 txt-c head heading title">{{item.title}}<span class="span spantitle">{{item.spantitle}}</span></h2>
       <h4 *ngIf="item.subtitle" class="m-0 txt-c sub_head__ heading subtitle">{{item.subtitle}}</h4>
     </div>
    </div>
   <div class="relative">
    <div *ngIf="item.logos && item.logos.length != 0 " #scrollContent class="container d__flex">
       <div *ngIf="!db.ismobile" class="back-s" (click)="emitScroll('scroll','left')">
        <!--  -->
         <ion-icon name="chevron-back-outline"></ion-icon>
       </div>
       <div class="grid__item mouse" *ngFor="let list of item.logos">
        <div class="image">
          <img  *ngIf="list.logo" src="{{db.product_img(list.logo)}}" defaultImage="" alt="">
        </div>
       </div>
       <div *ngIf="!db.ismobile" class='next-s' (click)="emitScroll('scroll','right')">
        <!--  -->
         <ion-icon name="chevron-forward-outline"></ion-icon>
       </div>
    </div>
   </div>
 </div>
 
 <!-- clients2 -->
 <div class="Brands client2  main-width animate {{item.class_name}}" *ngIf="item.section_type == 'Static Section' && item.section_name =='Clients Style 2'">
    <div class="d__flex" [class.just-c]="!db.ismobile" [class.just-s]="db.ismobile" [ngStyle]="db.img_style(item.background_image,'img')">
    <div class="box__1" [class.margin-none]="item.section_name == 'Brands'" [class.just-c]="!db.ismobile">
       <h2 *ngIf="item.title" class="my-15 txt-c fnt-600 fnt-40 heading title">{{item.title}} <span class="span spantitle">       {{item.spantitle}}</span></h2>
       <h4 *ngIf="item.subtitle" class="m-0 txt-c sub_head__ heading subtitle">{{item.subtitle}}</h4>
     </div>
    </div>
   <div class="relative">
    <div *ngIf="item.logos && item.logos.length != 0 " #scrollContent class="container d__flex">
       <div class="grid__item mouse" *ngFor="let list of db.ismobile ? item.logos : item.logos.slice(0,5)">
        <div class="">
          <img *ngIf="list.logo" src="{{db.product_img(list.logo)}}" defaultImage="" alt="">
        </div>
       </div>
    </div>
 
    <div *ngIf="item.logos && item.logos.length > 5 && !db.ismobile " #scrollContent class="container d__flex">
       <div class="grid__item mouse" *ngFor="let list of item.logos.slice(5)">
        <div class="">
          <img *ngIf="list.logo" src="{{db.product_img(list.logo)}}" defaultImage="" alt="">
        </div>
       </div>
    </div>
 
   </div>
 </div>
 