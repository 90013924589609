<div class="mainwidth" id="content">
  <!-- <pre>
        {{db.childs | json}}
    </pre> -->
  <!-- Desktop secondary bar -->
  <div class="container-header d__flex just-s" *ngIf="!db.ismobile">
    <!-- Desktop Breadcrumb db.cat_products.length != 0 &&-->
    <ul class="Bread-crumb">
      <li class="d__flex"> <a class="mr-5 mouse" routerLink="/" routerDirection="forward">Home</a> </li>
      <!-- 1{{db.childs.child_1_name | json}} 2{{db.childs.child_2_name | json}}  3{{db.childs.child_3_name | json}} -->
      <li class="d__flex" *ngIf="db.childs.child_1_name">
        <ion-icon class="mr-5" name="chevron-forward"></ion-icon> <a class="mr-5 mouse" (click)="breadcrumb('child-1')" [routerLink]="'/shop/'+db.childs.child_1" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_1_name}}</a>
      </li>
      <li class="d__flex" *ngIf="db.childs.child_2_name">
        <ion-icon class="mr-5" name="chevron-forward"></ion-icon> <a class="mr-5 mouse" (click)="breadcrumb('child-2')" [routerLink]="'/shop/'+db.childs.child_2" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_2_name}}</a>
      </li>
      <li class="d__flex" *ngIf="db.childs.child_3_name">
        <ion-icon class="mr-5" name="chevron-forward"></ion-icon><a class="mr-5 mouse" [routerLink]="'/shop/'+db.childs.child_3" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_3_name}}</a>
      </li>
    </ul>
    <!-- Desktop Sort Bar -->
    <div class="sort-buttons d__flex" *ngIf="!db.ismobile">
      <div class="d__flex">
        <div class="txt">Sort by</div>
        <div class="mouse selected d__flex" (click)="sortby_drop_down?sortby_drop_down = false:sortby_drop_down = true"><span class="fnt-14">{{sort == ''?'Relevance':sort_text}}</span>
          <ion-icon [name]="!sortby_drop_down?'caret-down-outline':'caret-up-outline'"></ion-icon>
        </div>
      </div>
      <ul *ngIf="sortby_drop_down" class="sortby-dropdown" (mouseleave)="sortby_drop_down = false">
        <li class="fnt-14" *ngFor="let item of db.sortings" (click)="sort_text = item.text" (click)="get_cat_products(item.role)">{{item.text}} </li>
      </ul>
    </div>
  </div>
  <div class="list-container">
    <!-- ( db.category_filters && (db.category_filters.attribute_list.length != 0 || category_filters.brand_list.length != 0 || category_filters.category_list.all_categories.length != 0 || db.category_filters.category_list.child_categories.length != 0 || db.category_filters.category_list.current_category.length != 0)) -->
    <div class="side-one" *ngIf="!db.ismobile" [style.height]="list_height  + 'px'">
      <app-category-bar [brands]="db.category_filters" (filter)="get_cat_products('')"></app-category-bar>
      <app-filters [brands]="db.category_filters" (filter)="get_products()"></app-filters>
    </div>
    <div class="side-two" [class.w-100]=" !(db.category_filters && (db.category_filters.attribute_list.length != 0 || db.category_filters.brand_list.length != 0 || db.category_filters.category_list.all_categories.length != 0 || db.category_filters.category_list.child_categories.length != 0 ))">
      <app-applied-filters [category_filters]="db.category_filters" (get_cat_products)="get_cat_products($event)"></app-applied-filters>
      <div>
        <app-mobile-filters *ngIf="db.ismobile" [choosed_sub]="choosed_sub" [choosed_data]="choosed_data" [category_filters]="db.category_filters" (get_cat_products)="get_cat_products($event)" [pr]="product"></app-mobile-filters>
        <app-product-box [change_button_color]="false" [list_view]="true" [scrollable]="false" [data]="db.cat_products" (addto_cart)="addtocart($event)" (removeto_cart)="removetocart($event)" (variant_select)="variant_select($event)" (add_wish)="addtowish($event)" (remove_wish)="addtowish($event)" [short_description]="db.website_settings && db.website_settings.show_short_description ==1"> </app-product-box>
        <ion-infinite-scroll *ngIf="!no_products" threshold="800px" (ionInfinite)="loadData($event)">
          <!-- && db.path?.split('/')[1] != 'shop' -->
          <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText=""></ion-infinite-scroll-content>
        </ion-infinite-scroll>
        <ion-infinite-scroll class="d__flex just-end" *ngIf="!no_products && db.path?.split('/')[1] == 'shop' && false" threshold="800px" (click)="loadData($event)">
          <ion-button class="ion__button" fill="clear">Load More</ion-button>
        </ion-infinite-scroll>
      </div>
    </div>
  </div>
</div>
<!-- [class]="db.ismobile ? 'oops-icon1': 'oops-icon1 oops-icon1_c_l'" -->
<div class="oops-icon1" [class.oops-icon2]="db.website_settings.enable_left_panel == 1" *ngIf="db.cat_products.length == 0 && no_products">
  <!-- <ion-icon src="/assets/icon/empty_stack_icons/no-product.svg"></ion-icon> -->
  <img *ngIf="db.website_settings && db.website_settings.app_settings" class="icon" button [src]="db.product_img(db.website_settings.app_settings.nofound_img)">
  <h6 class="m-0 fnt-12 oops-text">No Product Found!</h6>
  <p>Sorry, there’re no products for this search!</p>
</div>
<div class="mobile__footer" *ngIf="db.ismobile && db.cat_products.length != 0">
  <div class="mobile__cart__footer d__flex just-s">
    <div class="d__flex gap-5">
      <div class="d__flex gap-5">
        <div class="item__count fnt-12 ion-text-center"> {{db.cart_count}} items </div>
        <div *ngIf="db.cart_data && db.cart_data.cart">|</div>
        <div class="open_sans__font price__count fnt-13" *ngIf="db.cart_data && db.cart_data.cart"> {{db.cart_data.cart.total |currency:db.website_settings.currency}}</div>
      </div>
    </div>
    <!-- <ion-button routerLink="/wishlist" fill="clear" class="d__flex ion-text-capitalize mouse gap-5 proceed  fnt-14 choosed"> View Wishlist
        </ion-button> -->
    <ion-button routerLink="/yourcart" fill="clear" class="d__flex ion-text-capitalize mouse gap-5 proceed  fnt-14 choosed"> View Cart
      <!-- <ion-icon class="mt-4" name="chevron-forward-outline"></ion-icon> -->
    </ion-button>
  </div>
</div>
