
<div class="mobile-search-bar d__flex " *ngIf="!select_store">

  <div *ngIf="!db.cust_name && false" class="d__flex gap-5 mobile-location" routerLink="/location">
    <div> <ion-icon class="locat-icon" src="/assets/districo/location.svg"></ion-icon></div>
    <div>
      <p class="m-0 fnt-12 webkit-text" [style.--line]="1">
        <!-- <ion-icon class="location-icon pad-r-5"src="/assets/districo/location.svg"></ion-icon> -->
        Centre <ion-icon name="chevron-down-outline"></ion-icon>
      </p>
      <!-- <p class="m-0 fnt-12 webkit-text" [style.--line]="1" > {{db.center_name}} -->
        <!-- <ion-icon name="chevron-down-outline"></ion-icon>
      </p> -->
      <p class="m-0 fnt-10 location_txt webkit-text" [style.--line]="1" *ngIf="!db.center_name">Choose your Centre </p>
      <p class="m-0 fnt-10 location_txt webkit-text" [style.--line]="1" *ngIf="db.center_name">{{db.center_name}}</p>
      <!-- <p class="m-0 fnt-10 location_txt webkit-text" [style.--line]="1" *ngIf="db.area">{{db.area}} </p> -->
    </div>
  </div>

  <ion-input  placeholder="search by items name.."  (click)="goto_search()">
    <!-- <ion-icon [src]="db.product_img(db.website_settings.app_settings.header_search_icon)"  class="mobile-search"></ion-icon> -->
    <img class="mobile-search" *ngIf="db.website_settings && db.website_settings.app_settings" [src]="db.product_img(db.website_settings.app_settings.header_search_icon)" alt="">
  </ion-input>


  <!-- <ion-icon  routerLink="/search" routerDirection="forward" class=" search-icon" slot="end" src="/assets/icon/search.svg"></ion-icon> -->
  
</div>



<!-- <div *ngIf="select_store" class="select_store">
  <div class="d__flex just-s choose_store">
    <h6 class="locat_heading m-0 fnt-15 fnt-600">Choose Your Store</h6>
    <ion-icon (click)="modalCtrl.dismiss()" class="close-icon mouse" name="close-outline"></ion-icon>
  </div>
  <div class="d__flex flex-w gap-10 mx-10">
    <div class="store mouse" *ngFor="let item of stores">
      <p fill="clear" (click)="store_select(item.name)">{{item.name}}</p>
    </div>
  </div>
</div> -->