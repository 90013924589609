<ion-content>
  <div class="container" *ngIf="product_detail">
    <div class="tostifyy tostify__alert">
      <div class="tostify d__flex " *ngFor="let item of alert_animatings;let i= index" (click)="alert_animatings.splice(i,1)">
        <div> <span>{{item}}</span></div>
        <ion-icon name="close-outline"></ion-icon>
      </div>
    </div>
    <div class="top__container">
      <div class="image">
        <div *ngIf="!db.ismobile && false" class="sub-images">
          <div class="image mouse" *ngFor="let image of product_detail.images" [ngClass]="(main_image == (image.product_image || image.image))?'active-image':''" (mouseenter)="main_image = (image.product_image || image.image)" (click)="main_image = (image.product_image || image.image)">
            <img *ngIf="image.detail_thumbnail" src="{{db.product_img(image.detail_thumbnail)}}" alt="" defaultImage>
          </div>
        </div>
        <div class="main-image" *ngIf="!db.ismobile">
          <!-- *ngIf="main_image" -->
          <img  src="{{db.product_imgs(main_image)}}" defaultImage="" src="">
          <span class="m-0" class="discount" *ngIf="product_detail.discount_percentage">{{product_detail.discount_percentage}}% Off</span>
        </div>
        <!-- <img [src]="product_detail.product_image"> -->
      </div>
      <div class="side__container">
        <div class="mobile__desc">
          <div>
            <div class="d__flex just-s">
              <h6 class="product__name webkit-text" [style.--line]="2">{{product_detail.item}}</h6>
              <ion-icon (click)="modalCtrl.dismiss()" class="close-icon mouse" name="close-outline"></ion-icon>
              <div *ngIf="!db.ismobile && false" class="d__flex mouse" (click)="db.share('product')"> <span>Share</span>
                <ion-icon name="arrow-redo-outline" class="share-icon"></ion-icon>
              </div>
            </div>
            <div class="">
              <p class="m-0 sub__name webkit-text" [style.--line]="2" *ngIf="product_detail.short_description && db.product_shrotdescription"> {{product_detail.short_description}} </p>
              <div class="star-rating" *ngIf="product_detail.rating">
                <ion-icon class="star" name="star" *ngFor="let item of [0,1,2,3,4] | slice:0:product_detail.rating"></ion-icon>
              </div>
            </div>
            <div class="m-0 brand-name" *ngIf="product_detail.brands && product_detail.brands.length !=0 ">by <span class="alternative" *ngFor="let b of product_detail.brands">{{b.brand_name}}</span></div>
            <div class="product__categories fnt-14" *ngIf="product_detail.product_categories && product_detail.product_categories.length != 0">
              <span>Categories : </span> <a *ngFor="let item of product_detail.product_categories;let i = index" routerLink="/c/{{item.category}}">{{item.category_name}} <span *ngIf="i > i-1"></span></a>
            </div>
            <div *ngIf="(product_detail.gross_weight || product_detail.weight) && db.product_weight" class="d__flex weight__specify">
              <div *ngIf="product_detail.gross_weight">Gross Wt: {{product_detail.gross_weight >= 1000?product_detail.gross_weight/1000:product_detail.gross_weight}} {{product_detail.gross_weight >= 1000?'Kg':'gms'}} </div>
              <span *ngIf="product_detail.gross_weight"> / </span>
              <div [class.Net]="product_detail.gross_weight" *ngIf="product_detail.weight">Net Wt: {{product_detail.weight >= 1000?product_detail.weight/1000:product_detail.weight}} {{product_detail.weight >= 1000 ?'Kg':'gms'}} </div>
            </div>
          </div>
          <p *ngIf="product_detail.short_description" class="m-0 sub__name">{{product_detail.short_description}}</p>
          <div class="sold_by" *ngIf="product_detail.vendor_price_list && product_detail.vendor_price_list.length">
            <p class="m-0">Sold By: <span class=""> {{ product_detail.vendor_price_list[0].vendor_name}}</span></p>
          </div>
          <p class="prices">
            <span class="m-0 price" *ngIf="product_detail.price">{{product_detail.price | currency:db.website_settings.currency}}</span>
            <span class="m-0 s-price l-t" *ngIf="product_detail.old_price != 0">{{product_detail.old_price | currency:db.website_settings.currency}}</span>
          </p>
        </div>
        <!--Products Attribute Variants -->
        <div class="attributes" *ngIf="product_detail.product_attributes && product_detail.product_attributes.length != 0">
          <!--normal attribute  -->
          <app-normal-attribute *ngIf="product_detail.show_price != 0" [product_detail]="product_detail" [main_image]="main_image"></app-normal-attribute>
          <!-- Table format -->
          <app-table-attribute *ngIf="product_detail.show_price == 0" [product_detail]="product_detail"></app-table-attribute>
        </div>
        <div class=" main-buttons gap-10 my-10">
          <app-cart-button [big_button]="true" [product_info]="product_detail" (addto_cart)="addtocart(product_detail,'shopping')" (removeto_cart)="removetocart(product_detail)"></app-cart-button>
          <ion-button *ngIf="false" class="wishlist__button" fill="clear" (click)="!db.cust_email && !db.cust_name ? db.close_modal() : null" (click)="addtocart(product_detail,'wishlist')">
            <ion-icon [src]="product_detail.wish_count && product_detail.wish_count != 0 ? 'assets/icon/wish/wishlist-filled.svg':'assets/icon/wish/Vector.svg'"></ion-icon>
          </ion-button>
          <ion-button class="share__button" fill="clear" (click)="db.share('product')">
            <ion-icon name='arrow-redo-outline'></ion-icon>
          </ion-button>
        </div>
        <div class="fnt-14 alternative" *ngIf="product_detail.show_inventory == 1 && product_detail.product_attributes.length == 0 && !(product_detail.inventory_method == 'Track Inventory' && product_detail.stock == 0)">In Stock</div>
        <p class="m-0 fnt-12 no-stock" *ngIf="product_detail.stock_alert_message">{{product_detail.stock_alert_message}}</p>
        <p *ngIf="product_detail.inventory_method == 'Track Inventory' && product_detail.stock == 0">No stock</p>
        <div *ngIf="!db.ismobile">
          <div class="highlights" *ngIf="product_detail.highlights && product_detail.highlights.length != 0">
            <div>
              <h6 class="title__heading">Highlights</h6>
            </div>
            <ul>
              <li *ngFor="let list of product_detail.highlights">{{list.highlights}}</li>
            </ul>
          </div>
          <div class="descriptions">
            <ion-accordion-group class="mar-t-30" [value]="['four']">
              <ion-accordion *ngIf="product_detail.full_description" value="four" class="">
                <ion-item slot="header" class="accordion-radius">
                  <div class=" d__flex just-s">
                    <h6 class="bold_name-15-500 fnt-16">Product Detail</h6>
                  </div>
                </ion-item>
                <div *ngIf="db.ismobile" class="ion-padding product_details" slot="content" [innerHTML]="product_detail.full_description">
                </div>
                <div *ngIf="!db.ismobile" class="ion-padding null-padding2" slot="content">
                  <div class="product-desc">
                    <div class="desc">
                      <div [class.main-desc]="db.ismobile" [innerHTML]="product_detail.full_description"> </div>
                    </div>
                  </div>
                </div>
              </ion-accordion>
            </ion-accordion-group>
            <div *ngIf="product_detail.return_description" class="return-policy">
              <div>
                <h6 class="title__heading" *ngIf="product_detail.return_description">Return Policy</h6>
              </div>
              <div class="m-0 return-desc" [ngClass]="showmore.showmore2?'showmore':'hide'">{{product_detail.return_description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tostifyy success__alert">
    <div class="tostify d__flex " *ngFor="let item of add_animatings;let i =index" (click)="add_animatings.splice(i,1)">
      <div class="success-txt d__flex">
        <div class="image" *ngIf="item.images; else detail_img"><img src="{{db.product_img(item.images[0].detail_thumbnail )}}"></div>
        <ng-template #detail_img>
          <div class="image"><img src="{{db.product_img(item.product_image)}}"></div>
        </ng-template>
        <div class="text_grid">
          <span class="title">{{item.item}}</span>
          <span class="sucss">Added to cart</span>
        </div>
      </div>
      <ion-icon name="close-outline"></ion-icon>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf="db.ismobile">
  <ion-toolbar>
    <div class="items_done ">
      <div class="items_done_div d__flex">
        <div class="d__flex gap-10 cart_footer_mobile">
          <ion-icon class="icon" button src="/assets/icon/cart.svg"></ion-icon>
          <div class="text"><span>{{db.cart_count}} </span> </div>
          <div class="text"><span> Item </span></div>
        </div>
        <ion-button class="p" fill="clear" (click)="modalCtrl.dismiss()">
          <p class="p_done m-0">Done</p>
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
