import { isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Inject,
  Injectable,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import {
  Meta,
  Title,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
// import { Checkout } from 'capacitor-razorpay';
// import { AllInOneSDK } from 'capacitor-paytm-allinone';
import { CookieService } from 'ngx-cookie-service';
import OneSignal from 'onesignal-cordova-plugin';
import {
  Observable,
  Subject,
} from 'rxjs';
// import {
//   SubscribePlanPage,
// } from 'src/app/pages/subscribe-plan/subscribe-plan.page';
import { environment } from 'src/environments/environment';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Share } from '@capacitor/share';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  AnimationController,
  Platform,
} from '@ionic/angular';

import {
  GuestLoginComponent,
} from '../components/auth/guest-login/guest-login.component';
import { AddAddressPage } from '../pages/add-address/add-address.page';
import { AddressListPage } from '../pages/address-list/address-list.page';
import { LocationPage } from '../pages/location/location.page';
import { RegisterPage } from '../pages/register/register.page';
import { SocialSharePage } from '../pages/social-share/social-share.page';
import { MobileHeader2Component } from '../components/headers/mobile-header2/mobile-header2.component';
import { SelectAttributeComponent } from '../components/categories/select-attribute/select-attribute.component';
import { ExceptionCode } from '@capacitor/core';
// import { Geolocation } from '@capacitor/geolocation';
// var crypto = require('crypto');
import * as crypto from "crypto-browserify";
// import * as stream from "stream-browserify";
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';

// declare var Razorpay: any;
@Injectable({
  providedIn: 'root'
})

export class DbService {
  loader;
  oneSignalId;
  domain = environment.app_config.domain;
  domainurl = environment.app_config.domain
  domaintype = environment.app_config.domain_type
  baseUrl = `${this.domaintype}://${this.domainurl}/`;
  baseResource = `${this.domaintype}://${this.domainurl}/api/resource/`;
  baseMethod = `${this.domaintype}://${this.domainurl}/api/method/`;
  live_url = ''
  route = environment.app_config.route;
  store_name = 'All Stores';
  seller_price: any = {};
  vendor_price_list: any = [];
  apiUrl_common = 'ecommerce_business_store.ecommerce_business_store.v2.common.'
  apiUrl_carts = 'ecommerce_business_store.ecommerce_business_store.v2.cart.'
  apiUrl_customer = 'ecommerce_business_store.ecommerce_business_store.v2.customer.'
  apiUrl_product = 'ecommerce_business_store.ecommerce_business_store.v2.product.'
  apiUrl_category = 'ecommerce_business_store.ecommerce_business_store.v2.category.'
  apiUrl_checkout = 'ecommerce_business_store.ecommerce_business_store.v2.checkout.'
  apiUrl_orders = 'ecommerce_business_store.ecommerce_business_store.v2.orders.'
  apiUrl_vendors = 'ecommerce_business_store.ecommerce_business_store.v2.vendor.'
  apiUrl_masters = 'ecommerce_business_store.ecommerce_business_store.v2.masters.'
  apiUrl_ccavenue = 'ecommerce_business_store.ecommerce_business_store.v2.ccavenue.'
  businessId = environment.app_config.businessId;
  added_animate = new Subject(); //#Animate
  alert_animate = new Subject();
  scroll_top = new Subject();
  ismobile; //#check mobile
  cust_email;  //#customer Email
  cust_name; //#Customer Name
  category_list: any = [];
  website_settings: any = {};
  website_settings_getted = new Subject();
  wallet_settings_getted = new Subject();
  business_info: any = {};
  youtube_id;
  location_error = false;
  location;
  location_sugesstions: any = [];
  city;
  location_info: any = { zipcode: '', country: '', state: '', city: '', address: '', latitude: '', longitude: '' }
  map_register_info: any = { zipcode: '', country: '', state: '', city: '', address: '', latitude: '', longitude: '' }
  located = new Subject()
  paymentgateway_settings: any = {};
  razorpay_settings: any = {};
  // razorpay_payment = new Subject();
  modal = false;
  location_popup = false;
  cart_count = 0; //My cart count
  wish_count = 0; //Wishlist count
  marketplace_items: any = []; //Market place Cart  items
  fl_items: any = []; //fl Store items
  flStore_wishlist: any = []; //fl Store Wish list Items
  marketPlace_wishlist: any = []; // Market place wish list Items
  you_may_like: any = [];
  cart_data: any = {}; // //Cart data
  wishcart = true; //side cart active
  mycart = false; //side cart active
  wish_cart_emit = new Subject(); //wish cart emitt function..
  mycart_emit = new Subject(); //Add to cart emitt functin...,
  remove_brands = new Subject();
  load_map = new Subject();
  address_list: any = []; //address lists
  area_list: any = []; //available area list
  selected_address: any = {}; //address list
  httpOptions;
  httpHeaders;
  normal_login = true; //Selected login typenormal_login
  slider_button = true;
  product_box = { view: 'Grid View', row_count: 5 }
  attribute_id: any; // attribute popup
  childs: any = {}; //Selected child attributes
  current_category_info: any = {}
  cart_emitted;
  registerform;
  otp_form = false;
  guest_form;
  cat_products: any = [];
  category_filters: any;
  stored_filters:any;
  path: any; //current router Path
  minimax_price: any = {}; //Filters
  choosed_brands: any = []; //choosed category brands
  choosed_attributes: any = []; //Choosed category attributes
  choosed_product_id;//choosed produt id
  category: any = []; //product categories
  rating;
  total_amount: any = 0;
  discount_info: any = {};
  tabs_val = true;
  delivery_slots: any = ['tt'];
  selected_slot: any = [];
  selected_slot_label: any = {};
  discounts: any = { discount_amount: 0, discount: '' };
  coupon_discounts: any = { discount_amount: 0, discount: '', coupon_code: '', discount_products: [] }
  shipping_methods: any = [];
  shipping_settings: any = { shipping_charges: 0 };
  shipping_selected = new Subject();
  available_location = false;
  billing_address: any = {}
  opened_login_modal;
  modal_popup = true;//temporary modal popup check
  scroll_event: any = { desktop_header: true, mobile_header: false, no_search_icon: false, back_button: false }; //Scrolling data
  video_header = false;
  content_video = false;
  secondary_header = true;
  subscription_plan_info_status;
  subscribe_emit = new Subject();
  wallet_detail: any = {};
  loyalty_details: any = {}
  order_items: any = [];
  ordered = new Subject();
  dashboard_info: any = {}
  otp_send = new Subject();
  calculate = new Subject();
  store_selection = new Subject();
  tab_center = new Subject();
  sub_data: any = [];
  modal_stacks: any = [];
  isBrowser;
  is_footer: any;
  app_version;
  current_page_builder_data;
  viewcontent_serach: any = [];
  viewContent: any = [];
  r_pay_color = "#e53e36"
  selected_not_currect;
  category_bar_web;
  header;
  parent_category;
  show_All_Category: any
  show_parent_and_Category: any;
  product_weight;
  product_shrotdescription;
  mobile_category: any = { parent: [], child: [], sub_child: [] }
  android = false;
  current_device = '';
  customerName_vendor;
  cutomer_vendor_email;
  customer_vendor_pwd;
  customer_vendor_status;
  customer_mobile_no;
  make_return;
  checkout_cod = false
  referral: any = { pages: [], title: undefined, details: undefined }
  pdfFilePath = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  address: any = {};
  pincode_address = false;
  guest_cust_email;
  guest_cust_name;
  check_redif;
  share_url;
  get_referal_dashboard_values;
  ip;
  register_vendor = true;
  password_alert = false;
  product_name = false;
  address_disable = true
  address_zipcode_check = true;
  zipcode_base_search = true;
  account_settings: any = {};
  current_vendor_info = {};
  search_productss: any = [];
  search_shop: any = [];
  shop_list: any = [];
  header_info;
  footer_info;
  header_data;
  check_stock = false;
  video_list;
  other = false;
  vimeo;
  selected_plan: any = {};
  affiliated = false;
  affiliated_status = false;
  pre_select = false;
  customer_role = 0;
  // blog_list;
  driver_proofs: any = [];
  login_role;
  Referal = false;
  header_menu;
  product_info: any = {
    product: {
      product_attributes: [],
      product_images: [],
      product_categories: [],
    },
  };
  ref_doc_type: any;
  filter: any;
  doc_type: any;
  ad_name: any;
  map_fields_get = new Subject();
  flMarket = false;
  show_wish: boolean = false;
  cat_bar:any;
  active_cat:any;
  active_category = new Subject();
  is_variant = false;
  quick_view_none = true;
  order_page_no = 1;
  no_orders_products = false;
  center_name:any; //center name
  edit_order_id:any;
  warehouse_list:any = [];
  reason_list:any =[];
  business_address;
  inAppBrowserCloseAfterSuccess=false;
  // merchant_id = environment.app_config.merchant_id;

  constructor(private http: HttpClient, private menuCtrl: MenuController, private alertCtrl: AlertController, private platform: Platform, private router: Router, private navCtrl: NavController, private geo: Geolocation, private modalCtrl: ModalController,private animationCtrl: AnimationController, private ngzone: NgZone, private loadingCtrl: LoadingController, @Inject(PLATFORM_ID) private platformId, private cookie: CookieService, private metaTitle: Title, private meta: Meta, private iab: InAppBrowser) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.httpHeaders = new HttpHeaders({ 'withCredentials': 'true' });
    
  }

  get_ip() {
    this.http.get<{ ip: string }>('https://jsonip.com').subscribe(data => {
      this.ip = data.ip;
    })

  }
  
  // Check mobile or web
  checkmobile() {
    let width = this.platform.width();
    // console.log("Screen resized..", width);
    if (width > 768) {
      this.ismobile = false;
      return false
    } else if (width < 768) {
      this.ismobile = true;
      return true;
    }
  }


  // Check web image image
  product_img(data) {
    if (data) {
      if (data.indexOf('https') == -1) {
        return this.baseUrl + data;
      } else if (data.indexOf('https') == 0) {
        return data
      }
    }
  }

  product_imgs(data) {
    if (data) {
      if (data.indexOf('https') == -1) {
        return this.baseUrl + data;
      } else if (data.indexOf('https') == 0) {
        return data
      }
    } else {
      return '/assets/imgs/L-no-product-image.jpg';
    }
  }

  route_url(url) {
    if (url) {
      if (url.indexOf('https') == -1) {
        this.router.navigateByUrl(url);
      } else if (url.indexOf('https') == 0) {
        window.open(url, '_blank');

      }
    }
  }

  authendicate() {
    if (localStorage['api_key'] && localStorage['api_secret'] && localStorage['CustomerId']) {

    } else if (!localStorage['api_key'] && !localStorage['api_secret'] && !localStorage['CustomerId']) {
      localStorage.clear();
      this.router.navigate(["/login"]);
    }
  }

  cart_popup(data) {
    this.slider_button = false;
    if (data == 'mycart') {
      this.wishcart = false;
      this.mycart = true;
    } else if (data == 'wishcart') {
      this.wishcart = true;
      this.mycart = false;
    }
    this.menuCtrl.open();
    this.getShippingMethods();
  }

  get_blog() {
    
  }


  get_business_id() {
    if (localStorage['businessid']) {
      this.businessId = localStorage['businessid'];
      this.location = localStorage['setlocation'];
    } else 
      localStorage['businessid'] = this.businessId;
    
    this.get_home_data();
  }

  img_style(data, type) {
    if (type == 'color') {
      return { 'background': data };
    } else if (type == 'img') {
      return { 'background': 'url(' + this.product_img(data) + ')center center / 100% auto no-repeat', 'background-size': 'contain' };
    } else if (type == 'colorcode') {
      return { 'color': data };
    } else if (type == 'bgcolor') {
      return { 'background': data, 'color': data };
    } else if (type == 'full-bg-img') {
      return { 'background': 'url(' + this.product_img(data) + ') no-repeat', 'background-size': 'contain !important' }
    } else if (type == 'bg-image') {
      return { 'background': 'url(' + this.product_img(data) + ') no-repeat' }
    } else if (type == 'width') {
      return { 'width': data };
    } else if (type == 'margin') {
      return { 'margin': data };
    }
  }

  async select_store() {

    let modal = await this.modalCtrl.create({
      // MobileHeader2Component
      component: LocationPage,
      cssClass: this.ismobile ? 'store-actionSheet' : 'store-web',
      componentProps: {
        select_store: true
      },
      enterAnimation: this.enterAnimation ,
      leaveAnimation: this.leaveAnimation,
    });
    await modal.present();
    await modal.onWillDismiss().then(res => {

    });
  }

    // ion-model slide left transaction
    enterAnimation = (baseEl: HTMLElement) => { 
      var root:any = baseEl.shadowRoot;
      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
  
      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('.modal-wrapper')!)
        .keyframes([
          this.ismobile ? { offset: 0, opacity: '0.09', transform: 'translateY(100%)' } : { offset: 0, opacity: '0.09', transform: 'translateX(-100%)' },
          this.ismobile ? { offset: 1, opacity: '1', transform: 'translateY(0)' } : { offset: 1, opacity: '1', transform: 'translateX(0)' },
        ]);
      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(500)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };
  
    leaveAnimation = (baseEl: HTMLElement) => {
      return this.enterAnimation(baseEl).direction('reverse');
    };
  

  get_home_data() {
    var data = {
      application_type: this.ismobile ? "mobile" : "web",
      seller_classify : localStorage['store_name'] ? localStorage['store_name'] : this.store_name,
      route: this.route,
      centre: localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] : ''
    }
    this.get_mobile_homepage(data).subscribe(res => {
      this.current_page_builder_data = data.application_type
      if (res.message.sub_header) {
        this.viewContent.push(res.message.sub_header);
      }
      this.viewContent = res.message.page_content;
      if (this.viewContent) {
        this.viewContent.map(res => {
          if (res.btn != undefined) {
            res.btn ? res.btn_text = JSON.parse(res.btn) : '';
          }
          if (res.btn1 != undefined) {
            res.btn1 ? res.btn_text1 = JSON.parse(res.btn1) : '';
          }
        })

      }
      if (res.message.footer_content) {
        this.footer_info = res.message.footer_content;
        this.footer_info.layout_json = this.footer_info.layout_json_data;
        this.check_footer_layout()
      }
      if (res.message.header_content) {
        this.header_info = res.message.header_content;
        this.header_data = this.website_settings.default_header;
      } else {
        this.header_info = this.website_settings.default_header;
      }
      if (res.message.header_content) {
        this.category_list = res.message.header_content.menus;
      }
      this.get_cart_item();
      this.metaTitle.setTitle('Farmlink');
      this.meta.updateTag({name : "description",content :'Farmlink'});
      this.meta.updateTag({property : "og:description",content :'Farmlink'} );
      this.meta.updateTag({ property : "twitter:description",content :'Farmlink'} ); 
    }, error => {
      // console.log(JSON.stringify(error.json())); 
    })
  }


  is_show = false;
  searchproducts(keyword) {
    var data = { "searchTxt": keyword, page_no: 1, page_len: 15, centre: localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] :'' }
    this.search_products(data).subscribe(data => {
       if(data && data.message && data.message.length != 0){
        this.search_productss = data.message;

       }else{
        this.search_productss = [];
        this.is_show = true
       }
    });
  }

  searchshops(keyword) {
    // console.log(keyword);
    var data = { "searchTxt": keyword, page_no: 1, page_size: 12 }
    this.search_shops(data).subscribe(data => {
      data && data.message && data.message.length != 0 ? (this.search_shop = data.message) : setTimeout(() => { this.search_shop = [] }, 500);
    });
  }

  get_category_page_filter(value) {
    // console.log("Category page filters calling...", value)
    let data = { route: value, centre : localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] : '', seller_classify : localStorage['store_name'] ? localStorage['store_name'] : this.store_name }
    this.get_category_page_filters(data).subscribe(res => {
      res.message.meta_info.image = res.message.meta_info.category_image
      this.store_meta(res.message.meta_info);
      this.store_category_filters(res)

    })
  }

  store_meta(data) {
    this.metaTitle.setTitle(data.meta_title);
    this.meta.updateTag({ property: 'og:title', content: data.meta_title });
    this.meta.updateTag({ property: 'twitter:title', content: data.meta_title });
    this.meta.updateTag({ name: 'keywords', content: data.meta_keywords });

    this.meta.updateTag({ name: "description", content: data.meta_description });
    this.meta.updateTag({ property: "og:description", content: data.meta_description });
    this.meta.updateTag({ property: "twitter:description", content: data.meta_description });

    this.product_img(data.image) ? this.meta.updateTag({ property: "og:image", content: this.product_img(data.image) }) : null
    this.product_img(data.image) ? this.meta.updateTag({ property: "twitter:image", content: this.product_img(data.image) }) : null

  }

  store_category_filters(res) {
    if(res && res.message && res.message.attribute_list && res.message.attribute_list.length != 0){
      let filters = res.message.attribute_list.filter(r=>{
        return (r.options && r.options.length != 0)
      })
      res.message.attribute_list = filters
    }
    if (res.message) {
      this.category_filters = res.message;
      let value = JSON.stringify(this.category_filters);
      this.stored_filters = JSON.parse(value);


      if(this.choosed_brands && this.choosed_brands.length != 0 && (this.category_filters && this.category_filters.brand_list && this.category_filters.brand_list.length != 0)){
        this.category_filters.brand_list.map(res=>{
          this.choosed_brands.map(r=>{
            if(r == (res.unique_name ? res.unique_name : res.brand_name)){
              res['checked'] = true;
            }
          })
       })
      }




      if(this.choosed_attributes && this.choosed_attributes.length != 0 && (this.category_filters && this.category_filters.attribute_list && this.category_filters.attribute_list.length != 0)){
       
        this.choosed_attributes.map(r=>{
          let find_value = this.category_filters.attribute_list.find(res=>  { return res.attribute_unique_name.toLowerCase() == r.attribute.toLowerCase() })
          if((find_value && find_value.options && find_value.options.length != 0) && (r.value && r.value.length != 0)){
            
              r.value.map(va=>{
                let options = find_value.options.find(op=> va == (op.unique_name ? op.unique_name : op.option_value));
                if(options){
                  options['checked'] = true;
                }
              })
          }
        }) 
      }

      this.category_filters && this.category_filters.category_list.current_category ? this.store_breadcrumb(this.category_filters.category_list.current_category) : null
    }
  }
  store_breadcrumb(data) {
   
  }


  db_check(attributes, attribute) {
    this.category_filters.attribute_list.map(value => {
      if (attributes == value.attribute_unique_name) {
        value.options.map(res => {
          if ((attribute).includes(res.unique_name)) {
            res.choosed = 1
          } else {
            res.choosed = 0
          }
        })
      }
    })
  }


  get_parent_category() {
     this.get_website_settings()
  }


  load_mobile_categories() {
    if (this.category.length != 0) {
      this.category.map(res => {

        if (res.route == this.childs.child_1) {
          this.mobile_category.parent = res
          this.childs.child_1_name = res.category_name
        }

        res.child.map(childs => {
          if (childs.route == this.childs.child_2) {
            this.childs.child_2_name = childs.category_name
            this.mobile_category.child = childs
          }

          childs.child.map(sub_child => {
            if (sub_child.route == this.childs.child_3) {
              this.childs.child_3_name = sub_child.category_name;
            }
          })

        })
      })
    } else {
      this.get_parent_category()
    }
  }



  // Add to Wish
  addtowish(data) {
    if (!localStorage['api_key'] && !localStorage['api_secret'] && !localStorage['CustomerId']) {
      this.router.navigate(["/login"]);
    }else{
    var datas = {
      item_code: data.name,
      qty: data.wish_count ? data.wish_count : 1,
      qty_type: "",
      rate: data.price,
      cart_type: "Wishlist",
      attribute: data.selected_attribute ? data.selected_attribute : '',
      attribute_id: data.ids ? data.ids : '',
      business: this.seller_price.business ? this.seller_price.business : data.vendor_price_list.length > 0 ? data.vendor_price_list[0].business :'',
    }
    this.insert_cart_items(datas).subscribe(data => {
      if(data.message.status == 'Failed')
        this.alert(data.message.message);
      else
        this.get_cart_item();
    }, error => {
      // console.log(JSON.stringify(error.json()));
    });
   }
  }


  removewish(data) {
    let cartid = '';
    if (this.flStore_wishlist && this.flStore_wishlist.length != 0) {
      this.flStore_wishlist.find((res: any) => {
        if (res.product == data.name) {
          cartid = res.name
        }
      })
    }
    if (this.marketPlace_wishlist && this.marketPlace_wishlist.length != 0) {
      this.marketPlace_wishlist.find((res: any) => {
        if (res.product == data.name) {
          cartid = res.name
        }
      })
    }

    if (cartid && cartid != '') {
      var datas = {
        name: cartid,
        customer: localStorage['customerRefId'],
        user: ""
      }
      this.delete_cart_items(datas).subscribe(data => {

        this.get_cart_item()
      }, error => {
        //  console.log(JSON.stringify(error.json()));
      });
    }
  }

  //addtocart
  async addtocart(value) {
    value.count != 0 ? this.update_cart(value,'') : this.adding(value,'')
  }


  async select_addtocart(value,option) {
    value.count != 0 ? this.update_cart(value,option) : this.adding(value,option)
  }



  adding(value,option) {
    if (!localStorage['api_key'] && !localStorage['api_secret'] && !localStorage['CustomerId']) {
      this.router.navigate(["/login"]);
    }else{
      var datas = {
        item_code: value.name,
        qty: this.plusCount(value.count),
        qty_type: '',
        cart_type: "Shopping Cart",
        customer: localStorage['customerRefId'],
        attribute: value.selected_attribute,
        attribute_id: value.ids,
        business: this.seller_price.business ? this.seller_price.business : value.vendor_price_list.length > 0 ? value.vendor_price_list[0].business :'',
        
      }
      value.temp_disabled = true;
      option ? option.temp_disabled = true : null
      value.disable_add_to_cart_button = 1
      this.insert_cart_items(datas).subscribe(data => {
          value.temp_disabled = false;
          option ? option.temp_disabled = false : null;
          value.disable_add_to_cart_button = 0
          if (data.message && data.message.status == "Failed") {
    
            if (this.website_settings.enable_multi_store == 1) {
              this.alert_cart(data.message.message, value);
            } else if (this.website_settings.enable_multi_vendor == 1) {
              this.alert(data.message.message);
            }else{
              setTimeout(() =>{ this.alert(data.message.message); },1000);   
            }
          } else {
            this.added_animate.next(value);
            if (localStorage['customerRefId'] == undefined) {
              localStorage['customerRefId'] = data.message.customer
              localStorage['guestRefId'] = data.message.customer
            }
          }
          this.get_cart_item();
      }, error => {
        //  console.log(JSON.stringify(error.json()));
      });

    }

  }


  async update_cart(list,option) {
    this.update_cart_item(list, 'add',option);
  }


  removecart(value, option) {
    
    if (!value.selected_cart_id ) {
      let array = [...this.marketplace_items, ...this.fl_items]
      array.map(res => {
        if (res.product == value.name) {
          value.selected_cart_id = res.name
        }
      })
    }
    option ? option.temp_disabled = true : null
    
    var datas = { name: value.selected_cart_id }
    this.delete_cart_items(datas).subscribe(data => {
      value.selected_cart_id = '';
      option ? option.temp_disabled = false : null
      this.get_cart_item()
    }, error => {
      option ? option.temp_disabled = false : null
    });
  }


  removetocart(value) {
    if(value.minimum_order_qty && value.minimum_order_qty == value.count)
      this.removecart(value, '')
    else
      this.removeCount(value.count) == 0 ? this.removecart(value, '') : this.update_cart_item(value, 'remove','');
  }

  select_removetocart(value,option) {
    this.removeCount(value.count) == 0 ? this.removecart(value, option) : this.update_cart_item(value, 'remove',option);
  }


  async update_cart_item(list, type,option) {
    if (!list.selected_cart_id) {
        let array = [...this.marketplace_items, ...this.fl_items]
        array.map(res => {
          if (res.product == list.name) {
            list.selected_cart_id = res.name
          }
        })
      }
   
    list.temp_disabled = true
    option ? option.temp_disabled = true : null
    list.disable_add_to_cart_button = 1


    var datas = { name: list.selected_cart_id, qty: type == 'add' ? this.plusCount(list.count) : this.removeCount(list.count), qty_type: "" ,business:list.business}

    this.update_cart_items(datas).subscribe(data => {
      list.temp_disabled = false;
      option ? option.temp_disabled = false : null
      list.disable_add_to_cart_button = 0
      if (data.message.status == 'Failed') {
        this.alert(data.message.message)
      } else {
        this.added_animate.next(list);
      }
      this.get_cart_item();

    }, error => {
      // console.log(JSON.stringify(error.json()));
    });

  }


  get_cart_item() {
    if (localStorage['customerRefId'] != undefined) {
      var datas = { customer: localStorage['customerRefId'], user: "" }
      this.get_cart_items().subscribe(data => {
        this.store_cart_datas(data);
        this.mycart_emit.next('getted');
      });
    } else {
      setTimeout(() => { this.mycart_emit.next('getted'); }, 400)
    }
  }


  store_cart_datas(data) {
    this.show_wish = false;
    if (data.message.cart && data.message.cart != null && (data.message.cart.marketplace_items.length != 0 || data.message.cart.fl_items.length != 0)) {
      this.cart_data = data.message;
      this.cart_count = data.message.cart.marketplace_items.length + data.message.cart.fl_items.length
    } else {
      this.cart_data = {}
      this.cart_data.getted = true;
      this.cart_count = 0;
      this.total_amount = 0;
      this.shipping_settings.selected_shipping_method_name = undefined
      this.selected_slot = [];
    }
    if (data.message.cart && data.message.cart != null && data.message.cart.marketplace_items.length != 0) {
      this.marketplace_items = data.message.cart.marketplace_items;
    } else {
      this.marketplace_items = [];
    }
    if (data.message.cart && data.message.cart != null && data.message.cart.fl_items.length != 0) {
      this.fl_items = data.message.cart.fl_items;
    } else {
      this.fl_items = [];
    }
    let items = [...this.marketplace_items,...this.fl_items]
    if(items.length == 0){
      this.edit_order_id = undefined;
      localStorage.removeItem('edit_order_id');
    }
    if (data.message.wishlist && data.message.wishlist != null && (data.message.wishlist.fl_items.length != 0 || data.message.wishlist.marketplace_items.length != 0)) {
      this.wish_count = data.message.wishlist.fl_items.length + data.message.wishlist.marketplace_items.length;
    } else {
      this.wish_count = 0;
    }
    if (data.message.wishlist && data.message.wishlist != null && data.message.wishlist.fl_items.length != 0) {
      this.show_wish = false;
      this.flStore_wishlist = data.message.wishlist.fl_items;
      setTimeout(() => {
        this.show_wish = true;
      }, 500);

    } else {
      this.show_wish = true;
      this.flStore_wishlist = [];
    }
    if (data.message.wishlist && data.message.wishlist != null && data.message.wishlist.marketplace_items.length != 0) {
      this.show_wish = false;
      this.marketPlace_wishlist = data.message.wishlist.marketplace_items;
      setTimeout(() => {
        this.show_wish = true;
      }, 300);
    } else {
      this.marketPlace_wishlist = [];
      this.show_wish = true;
    }
    if (data.message.you_may_like && data.message.you_may_like.length != 0) {
      this.you_may_like = data.message.you_may_like;
    }
  }


  store_customer_info(res) {
    localStorage['CustomerId'] = res.message[0].email;
    this.cust_email = res.message[0].email;
    localStorage['email'] = res.message[0].email;
    this.cust_name = res.message[0].full_name;
    localStorage['CustomerName'] = res.message[0].full_name;
    localStorage['referral_code'] = res.message[0].referral_code
    res.message[0].api_key ? localStorage['api_key'] = res.message[0].api_key : null;
    res.message[0].api_secret ? localStorage['api_secret'] = res.message[0].api_secret : null;
    localStorage['Customerphone'] = res.message[0].phone;
    localStorage['Customerfirst_name'] = res.message[0].first_name
    localStorage['Customerlast_name'] = res.message[0].last_name
    localStorage['customerRefId'] = res.message[0].name
    localStorage['customerUser_id'] = res.message[0].user_id;
    var business_addr_info:any ={};
    business_addr_info.business_name = res.message[0].business_name
    business_addr_info.business_phone = res.message[0].business_phone
    business_addr_info.business_address = res.message[0].business_address
    business_addr_info.business_landmark = res.message[0].business_landmark
    business_addr_info.business_city = res.message[0].business_city
    business_addr_info.business_state = res.message[0].business_state
    business_addr_info.business_zip = res.message[0].business_zip
    business_addr_info.business_country = res.message[0].business_country
    this.center_name = res.message[0].center ? res.message[0].center : localStorage['CenterName'];
    localStorage['Business_address'] = JSON.stringify(business_addr_info); 
    localStorage.removeItem('guestRefId');
    this.address_list = res.message[0].address;
    this.address_list && this.address_list.length != 0 ? this.choose_default_address() : null;
    if (localStorage['CustomerPwd'] == undefined)
      res.message[0].new_password ? localStorage['CustomerPwd'] = res.message[0].new_password : localStorage['CustomerPwd'] = res.message[0].set_new_password
    localStorage['player_id'] && localStorage['player_id'] != "undefined" && localStorage['player_id'] != undefined ? (this.update_onsignal_id(true).subscribe(res => { console.log("one signal id updated..", res) })) : null
  }

  store_customer_info_ventor(res) {
    localStorage['CustomerId'] = res.message[0].contact_email;
    this.cust_email = res.message[0].contact_email;
    localStorage['email'] = res.message[0].contact_email;
    this.cust_name = res.message[0].contact_person;
    localStorage['CustomerName'] = res.message[0].contact_person;
    res.message[0].api_key ? localStorage['api_key'] = res.message[0].api_key : null;
    res.message[0].api_secret ? localStorage['api_secret'] = res.message[0].api_secret : null;
    localStorage['Customerphone'] = res.message[0].contact_number;
    localStorage['vendor_business_id'] = res.message[0].name
    localStorage['customerUser_id'] = res.message[0].user_id;
    localStorage.removeItem('guestRefId');
    if (res.message[0].roles_list && res.message[0].roles_list.length != 0) {
      res.message[0].roles_list.map(role => {
        if (role == 'Vendor') {
          localStorage['role'] = role;
          this.login_role = localStorage['role']
        }
      })
    }

    if (res.message[0].roles_list && res.message[0].roles_list.length != 0) {
      let data = res.message[0].roles_list.find(r => r == 'Blogger')
      if (data == 'Blogger') {
        localStorage['customer_role'] = 1;
        this.customer_role = 1;
      }
    }
    if (localStorage['CustomerPwd'] == undefined)
      res.message[0].new_password ? localStorage['CustomerPwd'] = res.message[0].new_password : localStorage['CustomerPwd'] = res.message[0].set_new_password
    res.message.address && res.message.address.length != 0 ? this.choose_default_address() : null
    localStorage['player_id'] && localStorage['player_id'] != "undefined" && localStorage['player_id'] != undefined ? (this.update_onsignal_id(true).subscribe(res => { console.log("one signal id updated..", res) })) : null
  }


  store_vendor_login(res, type) {
    if (type == 'register') {
      this.cust_name = res.message.full_name;
      localStorage['CustomerName'] = res.message.full_name;
      localStorage['customerName_vendor'] = res.message.full_name;
      localStorage['vendor_email'] = res.message.email;
      localStorage['vendor_new_password'] = res.message.new_password;
      localStorage['mobile_no'] = res.message.mobile_no
      this.customerName_vendor = localStorage['customerName_vendor'];
      this.cutomer_vendor_email = localStorage['vendor_email']
      this.customer_vendor_pwd = localStorage['vendor_new_password'];
      this.customer_mobile_no = res.message.mobile_no;
    } else if (type == 'login') {
      this.cust_name = res.full_name;
      localStorage['CustomerName'] = res.full_name;
      localStorage['customerName_vendor'] = res.full_name;
      this.customerName_vendor = localStorage['customerName_vendor']
      localStorage['mobile_no'] = res.message.phone
      this.customer_mobile_no = res.message.phone;
    }

  }

  product_buy_Together(res) {
    var datas: any = [];
    res.map(value => {
      var data = {
        item_code: value.name,
        qty: this.plusCount(value.count),
        qty_type: 'add',
        rate: value.price,
        attribute: value.selected_attribute,
        attribute_id: value.ids,
      }
      datas.push(data)
    })
    var Values = {
      items: JSON.stringify(datas),
      customer: localStorage['customerRefId'],
      cartType: "Shopping Cart",
      is_gift_card: ' ',
      sender_name: ' ',
      sender_email: ' ',
      sender_message: ' ',
      recipient_email: ' ',
      recipient_date_of_birth: ' ',
      recipient_name: ' ',
      device_type: '',
      certificate_type: ' ',
      certificate_occasion: ''
    }
    // this.ismobile?'Android Mobile App':'Website'
    this.insertcart_multiItems(Values).subscribe(res => {
      if (res.message) {
        this.get_cart_item();
        setTimeout(() => {
          this.ismobile ? this.router.navigateByUrl('/yourcart') : this.router.navigateByUrl('/checkout');
        }, 800)
      }
    })
  }


  replace_txt(text){
    if(text == 'FL Store')
      return 'Direct'
    else if (text == 'FL MarketPlace')
      return 'Farmlink'
    else
      return text;
  }

  vendor_storage() {
    this.cust_name = localStorage['CustomerName']
    this.customerName_vendor = localStorage['customerName_vendor'];
    this.cutomer_vendor_email = localStorage['vendor_email']
    this.customer_mobile_no = localStorage['mobile_no'];
  }

  store_geust_customer_info(res) {
    localStorage['customerRefId'] = res[0].name
    this.guest_cust_email = res[0].email;
    localStorage['guest_email'] = res[0].email;
    this.guest_cust_name = res[0].first_name;
    this.cust_name = res[0].first_name;
    localStorage['guest_CustomerName'] = res[0].first_name;
    localStorage['guest_Customerphone'] = res[0].phone;
    localStorage['customerRefId'] = res[0].name;
  }


  sass_login(usr, pwd) {
    var logindata = { usr: usr, pwd: pwd, domain: this.domain, "get_user_token": 1 }
    this.sasslogin(logindata).subscribe(data => {
      if (data.message.api_key) {
        localStorage['api_key'] = data.message.api_key;
        localStorage['api_secret'] = data.message.api_secret;
      }
    }, err => { console.error(err); })
  }


  // Check attribute lable
  check_attribute_label(data) {
    let temp_label = 'Select'
    if (data && data.length != 0) {
      data.map(res => {
        if (res.is_pre_selected == 1) {
          temp_label = res.option_value
        }
      })
    }
    return temp_label;
  }

  async notify_me(item) {
    if (this.cust_name) {
      let data = { "attributeId": '', "sender_name": this.cust_name, "sender_email": this.cust_email, "product": item.name }
      let datas = { data: JSON.stringify(data) }
      this.insert_stockavail_notification(datas).subscribe(res => {
        if (res.message.user_name) {
          this.alert('We will notify you once the product is available');
        }
      })
    } else {
      if (this.modal_popup && !this.cust_name) {
        this.modal_popup = false;
        const modal = await this.modalCtrl.create({
          component: RegisterPage,
          cssClass: 'auth-popup',
          componentProps: {
            type: 'login'
          }
        })
        this.opened_login_modal = true;
        await modal.present();
        await modal.onWillDismiss().then(res => {
          this.modal_popup = true;
          this.cust_name ? this.notify_me(item) : null
        });
      }
    }
  }


  check_stocks(item) {
    this.check_stock = true;
    return (item.inventory_method == 'Track Inventory' && (item.stock == 0 || item.stock <= item.count) && item.count == 0)
  }

  check_attribute_stock(no_stock, item) {
    return (item.count == 0 && no_stock) ? true : false
  }

  check_disabled(item) {
    return item.disable_add_to_cart_button == 1
  }

  checkcart(id) {
    var cnt = 0;
    let array = [...this.marketplace_items, ...this.fl_items];
    array.find(res => { if (res.product == id && res.is_free_item != 1) { cnt += res.quantity } })
    return cnt;
  }

  checkcart1(id) {
    var cnt = 0;
    let array = [...this.marketplace_items, ...this.fl_items];
    array.find(res => { if (res.product == id) { cnt += res.quantity } })
    return cnt;
  }


  checkWishCart(id) {
    var cnt = 0;
    let array = [...this.flStore_wishlist, ...this.marketPlace_wishlist];
    array.find(res => { if (res.product == id) { cnt += res.quantity } })
    return cnt;
  }

  getShippingMethods() {
    this.get_shipping_method().subscribe((r: any) => {
      if (r.message != undefined)
        this.shipping_methods = r.message;
      if (this.shipping_methods && this.shipping_methods.length > 0)
        this.getSlot(this.shipping_methods[0].name)
    }, error => {
     
    });
  }


  getSlot(delivery_method) {
    let slots: any = [];
    var data = { shipping_method: delivery_method, customer_id: localStorage['customerRefId'] }
    this.get_cart_delivery_slots(data).subscribe(res => {
      if (res.message) 
        this.delivery_slots = res.message;
        if(this.delivery_slots[0].dates_lists && this.delivery_slots[0].dates_lists.length == 1) {
          this.delivery_slots[0].selected_time_slots = this.delivery_slots[0].dates_lists[0]
          if(this.delivery_slots[0].dates_lists[0].slots.length == 1){    
            this.delivery_slots[0].selected_time_slots.selected_time = this.delivery_slots[0].dates_lists[0].slots[0]
          }
        }
    })

    if(this.edit_order_id){
      this.order_info(this.edit_order_id)
    }

  }

  get_order_infos:any;
  order_info(data){
  
    this.get_order_info({order_id: data}).subscribe(res =>{
       if(res && res.message){
        this.get_order_infos = res.message;
       }
    })

  }

  // clear Cart 
  async clearcart() {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: this.edit_order_id ? 'Are you sure you want to cancel? This will clear all items in your cart.' : 'Are you sure to clear all cart items?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => { }
        },
        {
          text: 'Ok',
          handler: () => {
            let data = { customer: localStorage['customerRefId'] }
            this.clear_cartitem(data).subscribe(res => {
              this.get_cart_item();
            })
          }
        }
      ]
    })

    await alert.present();
  }
  
  async clearEditOrderCart() {
    try {
      let data = { customer: localStorage['customerRefId'] }
      this.clear_cartitem(data).subscribe(res => {
        this.get_cart_item();
      })
    } catch (error) {
      console.log("error",error)
    }
  }

  get_retuen_products(order_id) {
    let data = {
      order_id: order_id
    }
    this.get_return_request_info(data).subscribe(res => {
      let return_products = res.message;
      return_products.eligible_items && return_products.eligible_items.length != 0 ? this.make_return = true : this.make_return = false
    })
  }

  select_shipping_method(shippingmethod_id) {

    this.shipping_methods.map(res => {
      if (res.name == shippingmethod_id) {
        this.shipping_settings.selected_shipping_method_name = res.shipping_method_name
      }
    })

    this.shipping_settings.selected_shipping_method = shippingmethod_id;

    var ship_data = {
      shipping_addr: this.selected_address.name,
      subtotal: this.cart_data.cart.total,
      shipping_method: this.shipping_settings.selected_shipping_method,
      cartId: this.cart_data.cart.name,
    }

    this.calculate_vendor_based_shipping_charges(ship_data).subscribe((data: any) => {
      if (data.message) {
        this.shipping_settings.shipping_charges = data.message.shipping_charges
        this.shipping_settings.shipping_tax = data.message.tax_rate
      }
      this.shipping_selected.next('shipping_charges')
      this.get_order_discounts();
    })
  }

  get_order_discounts() {
    var data = {
      subtotal: this.cart_data.cart.total,
      customer_id: localStorage['customerRefId'],
      shipping_method: this.shipping_settings.selected_shipping_method,
      shipping_charges: this.shipping_settings.shipping_charges
    }


    this.get_order_discount(data).subscribe((res: any) => {

      if (res && res.message && res.message.status == 'Success') {
        this.discounts.discount_amount = res.message.discount_amount;
        let discount_response = res.message.discount_response;
        res.message.discount_response.shipping_discount == 1 ? this.shipping_settings.shipping_charges = res.message.discount_response.shipping_charges : null
        res.message.discount_response.shipping_discount == 1 ? this.shipping_settings.shipping_label = res.message.discount_response.shipping_label : null
        this.discounts.discount = res.message.discount_response.shipping_discount_id

        if (discount_response.cashback && discount_response.cashback == 1) {
          this.discount_info.cashback_amount = discount_response.cashback_amount;
          this.discounts.discount = discount_response.discount_rule;
        }

        this.calculate.next('calculate');
        if(res.message.discount_response.product_discount){
          if(res.message.discount_response.products_list){
            res.message.discount_response.products_list.map(value =>{
              if(value.discount){
                this.discounts.discount_amount +=  value.discount
              }
              this.coupon_discounts.discount_products.push(value)
            })
          }

        }

      } else {
      }
    }, error => {
    });

  }

  promo() {
    if (this.coupon_discounts.coupon_code != '') {
      var data = {
        coupon_code: this.coupon_discounts.coupon_code,
        subtotal: this.cart_data.cart.total,
        customer_id: localStorage['customerRefId'],
        shipping_method: this.shipping_settings.selected_shipping_method,
      }

      this.validate_coupon(data).subscribe(data => {
        if (data.message.status == 'success') {
          this.coupon_discounts.discount_amount = data.message.discount_amount
          this.coupon_discounts.discount = data.message.discount
          localStorage['coupon__code'] = data.message.discount

          if (data.message.discount_response.free_item == 1) {
            data.message.discount_response.products_list.map(res => {
              res.is_free_item = 1
            })
            this.coupon_discounts.discount_products = data.message.discount_response.products_list;
            this.marketplace_items = [...this.marketplace_items, ...data.message.discount_response.products_list]
            this.fl_items = [...this.fl_items, ...data.message.discount_response.products_list]
          }

          let discount_response = data.message.discount_response;
          if (discount_response.cashback && discount_response.cashback == 1) {
            this.discount_info.cashback_amount = discount_response.cashback_amount;
            this.discounts.discount = discount_response.discount_rule;
          }
        } else {
          this.coupon_discounts.discount_amount = 0
          this.coupon_discounts.discount = data.message.discount
          this.alert(data.message.message)
        }
        this.calculate.next('calculate');
      }, error => {
        this.alert('Invalid Coupon Code')
        this.coupon_discounts.coupon_code = '';
      });
    } else {
      this.alert('Please Enter Coupon Code')
    }
  }

  cancel_promo() {
    this.coupon_discounts = { discount_amount: 0, discount: '', coupon_code: '', discount_products: [] }
    this.cart_data.cart ? this.marketplace_items = this.cart_data.cart.items : null
    this.calculate.next('calculate');
  }

  async alert_cart(data, value) {
    data = data + 'Click yes to clear all cart items';
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: data,
      buttons: [{
        text: 'No',
        handler: () => {
        }
      },
      {
        text: 'Yes',
        handler: () => {
          this.clearcart_base_location(value);
        }
      }]
    })
    await alert.present();
    await alert.onWillDismiss().then(res => {
    })
  }


  async alert_pincode(data) {
    data = data + 'Click yes to change a address';
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: data,
      buttons: [{
        text: 'No',
        handler: () => {
        }
      },
      {
        text: 'Yes',
        handler: () => {
          this.add_address();
        }
      }]
    })
    await alert.present();
    await alert.onWillDismiss().then(res => {
    })
  }

  async clearcart_base_location(value) {
    let data = { customer: localStorage['customerRefId'] }
    this.clear_cartitem(data).subscribe(res => {
      this.get_cart_item();
      this.addtocart(value);
    })
  }

  async alert(data) {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: data,
      buttons: ['Ok']
    })
    await alert.present();
    await alert.onWillDismiss().then(res => {
    })
  }

  check_footer() {

    let data;
    if (this.footer_info && this.footer_info.length != 0) {
      this.footer_info.map((res, i) => {

        if (res.section_name == 'Footer Download App' && res.section_type == 'Static Section') {
          data.Footer_Download_App = res;
        } else {
          data = res;
        }
      })

      this.footer_info = this.footer_info.filter(item => item.section_name !== 'Footer Download App')

    }
  }

  check_footer_layout() {
    if (this.footer_info.layout_json) {
      this.footer_info.layout_json.map(res => {
        res.columns.map((rec, index) => {
          let check = this.footer_info.items.find(res => res.column_index == index);
          if (check)
            rec.layout_data = check.items;
        })
      })
    }
  }

  get_website_settings() {
    var data={
      "centre": localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] :'',
      "seller_classify": localStorage['store_name'] ? localStorage['store_name'] : this.store_name
    }
    this.get_all_website_settings(data).subscribe(res => {
      this.website_settings = res.message;
      // if(this.category.length ==0){
        this.category = this.website_settings.all_categories;
        this.viewcontent_serach = this.website_settings.all_categories; 
        this.ismobile ? this.load_mobile_categories() : null
      // }else{
        // this.ismobile ? this.load_mobile_categories() : null
      // }
      // console.log(this.category);
      this.footer_info = (this.website_settings.default_footer && this.website_settings.default_footer) ? this.website_settings.default_footer : [];
      this.footer_info.layout_json = this.footer_info.layout_json_data;
      this.check_footer_layout();
      this.website_settings_getted.next('done');
      this.header_info = this.website_settings.default_header;
      // this.header_data = this.website_settings.default_header;
      this.product_box.row_count = this.website_settings.default_products_per_row

      if (this.website_settings.enable_guest_checkout == 1 && localStorage['guest_CustomerName']) {
        this.guest_cust_email = localStorage['guest_email'];
        this.guest_cust_name = localStorage['guest_CustomerName'];
        this.cust_name = localStorage['guest_CustomerName']
      }

      if (this.website_settings.enable_multi_store == 1) {
        if (this.website_settings.enable_zipcode == 1) {
          if (this.website_settings.radius_based_validation == 1) {

          } else {
            // this.get_areas();
            this.get_business_id();
          }
        }
        // } this.get_business_info();
      } else if (this.website_settings.app_settings) {
        this.business_info.business_address = this.website_settings.app_settings.address;
        this.business_info.contact_number = this.website_settings.app_settings.business_phone;
        this.business_info.contact_email = this.website_settings.app_settings.business_email;
      }
      else {
      }
    })

  }

  dismiss() {
    if (this.ismobile) {
      if (this.opened_login_modal) {
        this.modalCtrl.dismiss()
      } else {
        this.ismobile ? this.router.navigate(['/']) : this.router.navigate(['/']);
      }
    } else {
      this.modalCtrl.dismiss();
    }
  }



  async openLogin() {
    if (this.modal_popup && !(this.cust_name || this.guest_cust_name)) {
     
      this.opened_login_modal = true;
      this.modal_popup = false;
      this.modal = true;
      const modal = await this.modalCtrl.create({
        component: RegisterPage,
        cssClass: 'auth-popup',
        componentProps: {
          type: 'login',
          modal: true
        }
      })

      await modal.present();

      await modal.onWillDismiss().then(res => {
        this.modal_popup = true;
        this.opened_login_modal = false;
        this.get_address();
        this.get_cart_item();
        if (this.ismobile && this.cust_name) {
          this.getShippingMethods();
        }
        this.modal = false;
      });
    }
  }

  async guest_login() {
    if (this.modal_popup && !this.cust_name) {
      this.opened_login_modal = true;
      this.modal_popup = false;
      this.modal = true;
      const modal = await this.modalCtrl.create({
        component: GuestLoginComponent,
        cssClass: 'auth-popup',
        componentProps: {
          type: 'login',
          modal: true
        }
      })

      await modal.present();

      await modal.onWillDismiss().then(res => {
        this.modal_popup = true;
        this.opened_login_modal = false;
        this.get_address();
        this.get_cart_item();
        if (this.ismobile && this.cust_name) {
          this.getShippingMethods();
        }
        this.modal = false;
      });
    }
  }


  async checkout_openLogin(data) {

    this.otp_form = false;

    if (data == 'login') {
      this.registerform = false;
      this.guest_form = false;
    } else if (data == 'signup') {
      this.registerform = true;
      this.guest_form = false;
    } else if (data == 'guest') {
      this.guest_form = true;
    }

    if (this.modal_popup && !this.cust_name) {
      // this.modal_popup = false;
      this.opened_login_modal = true;
      this.modal_popup = false;
      this.modal = true;
      const modal = await this.modalCtrl.create({
        component: RegisterPage,
        cssClass: 'auth-popup',
        componentProps: {
          type: 'login',
          modal: true,
          checkout_signup: true
        }
      })
      await modal.present();

      await modal.onWillDismiss().then(res => {
        this.modal_popup = true;
        this.opened_login_modal = false;
        this.get_address();
        this.get_cart_item();
        if (this.ismobile && this.cust_name) {
          this.getShippingMethods();
        }
        this.modal = false;

      });
    }
  }

  async add_address() {
    let modal = await this.modalCtrl.create({
      component: AddAddressPage,
      cssClass: this.website_settings.radius_based_validation == 1 ? 'address-popup-map' : 'address-popup',
      componentProps: {
        address_type: 'Add',
        modal: true,

      }
    });
    await modal.present();
    this.modal = true;
    let { data } = await modal.onWillDismiss();

    if (data && data.status == 'success') {
      // this.db.get_address();
      this.modal = false;
    }
  }


  async edit_address(value) {
    let modal = await this.modalCtrl.create({
      component: AddAddressPage,
      cssClass: this.website_settings.radius_based_validation == 1 ? 'address-popup-map' : 'address-popup',
      componentProps: {
        address_type: 'Edit',
        address_info: value,
        modal: true
      }
    });
    this.modal = true;
    await modal.present();
    let { data } = await modal.onWillDismiss();
    if (data && data.status == 'success') {
      this.modal = false;
      this.get_address();
    }
  }

  delete_address(value, index) {
    var data = { id: value.name, customer: localStorage['customerRefId'] }
    this.delete_addres(data).subscribe(data => {
      this.address_list.splice(index, 1)
      this.get_address();
    }, error => {
      // console.log(JSON.stringify(error.json()));
    });
  }


  async open_address_popup() {
    if ((this.cust_name || this.guest_cust_name) && this.modal_popup) {
      let modal = await this.modalCtrl.create({
        component: AddressListPage,
        cssClass: 'address-popup',
        componentProps: {
          title: 'Add',
          modal: true,
          modal_contrl: true
        }
      });
      this.modal_popup = false
      this.modal = true
      await modal.present();
      await modal.onWillDismiss().then(res => {
        this.modal = false
        this.modal_popup = true
      });
    }
  }


  // for subscription
  async buy_now() {
    if (localStorage['customerRefId']) {
      if (this.subscription_plan_info_status == 'Active') {
        this.alert('User is already subscribed !')
      } else {
        this.open_subscriber();
      }
    } else if (!localStorage['customerRefId']) {
      const modal = await this.modalCtrl.create({
        component: RegisterPage,
        cssClass: 'auth-popup',
        componentProps: {
          type: 'login',
        },
      });
      this.modal = true;
      await modal.present();
      let data = modal.onWillDismiss();
      if (await data && localStorage['customerRefId']) {
        this.open_subscriber();
      }
    }
  }


  async open_subscriber() {
    
  }


  async logout() {
    const alert = await this.alertCtrl.create({
      header: 'Logout',
      message: 'Are you sure do you want to logout..?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => { this.alertCtrl.dismiss() }
        },
        {
          text: 'Ok',
          handler: () => {
            this.update_onsignal_id(false).subscribe(res => { console.log("one signal id updated..", res) })
            this.cust_name = undefined;
            this.cust_email = undefined;
            localStorage['Address'] = undefined
            this.normal_login = true;
            this.delivery_slots = [];
            this.selected_slot = [];
            this.shipping_methods = [];
            this.shipping_settings = {};
            this.billing_address = {};
            this.selected_address = {};
            this.discounts = { discount_amount: 0, discount: '' };
            this.coupon_discounts = { discount_amount: 0, discount: '', coupon_code: '' }
            this.cart_data = {}
            this.marketplace_items = [];
            this.cart_count = 0;
            this.wish_count = 0;
            this.total_amount = 0;
            this.address_list = [];
            // this.cust_address =  {};
            this.discount_info = {};
            this.shipping_settings = { shipping_charges: 0 }
            this.customerName_vendor = undefined;
            this.cutomer_vendor_email = undefined;
            this.customer_vendor_pwd = undefined;
            this.customer_mobile_no = undefined;
            this.customerName_vendor = undefined;
            this.guest_cust_name = undefined;
            this.guest_cust_email = undefined;
            this.login_role = undefined;
            this.center_name = undefined;
            this.customer_role = 0;
            localStorage.clear();
            // !this.ismobile ? this.router.navigateByUrl('/') : this.router.navigateByUrl('/location');
            this.router.navigateByUrl('/')
            this.get_cart_item();
            this.get_warehouse();
            this.get_website_settings();
            
            // this.ismobile ? this.router.navigate(['/']) : this.router.navigate(['/']);
          }
        }
      ]
    })
    await alert.present();
  }

  get_warehouse(){
      this.get_centre_list().subscribe(res =>{
        if(res && res.message.status == 'Success'){
          this.warehouse_list = res.message.data;
          !localStorage['CenterName'] ? this.ismobile ? this.router.navigateByUrl('/location') : this.center_modal() : this.center_name = localStorage['CenterName'];
        }
      })
    }

  customer_logout_f_vendor(res, type) {
    this.cust_name = undefined;
    this.customerName_vendor = undefined;
    this.cutomer_vendor_email = undefined;
    this.customer_vendor_pwd = undefined;
    this.customer_vendor_status = undefined;
    this.cust_email = undefined;
    localStorage['Address'] = undefined
    this.delivery_slots = [];
    this.selected_slot = [];
    this.shipping_methods = [];
    this.shipping_settings = {};
    this.billing_address = {};
    this.selected_address = {};
    this.discounts = { discount_amount: 0, discount: '' };
    this.coupon_discounts = { discount_amount: 0, discount: '', coupon_code: '' }
    this.cart_data = {}
    this.marketplace_items = [];
    this.cart_count = 0;
    this.wish_count = 0;
    this.total_amount = 0;
    this.shipping_settings = { shipping_charges: 0 }
    localStorage.clear();
    this.store_vendor_login(res, type);
  }


  back() {
    if (this.modal) {
      this.modal = false;
      this.modalCtrl.dismiss();
    } else {
      this.navCtrl.back();
    }
  }

  reload() {
    window.location.reload();
  }

  get windowRef() {
    return window;
  }


  plusCount(count) {
    count = count + 1;
    return count;
  }


  removeCount(count) {
    if (count != 0) {
      count = count - 1;
      return count;
    } else {
      return 0;
    }
  }


  cartChange(data) {
    if (data == 'mycart') {
      this.wishcart = false;
      this.mycart = true;
    } else if (data == 'wishcart') {
      this.wishcart = true;
      this.mycart = false;
    }
  }


  prev_scroll;
  scrolled(data) {
    this.prev_scroll = data.detail.scrollTop
    if (data.detail.scrollTop < 80 && this.content_video) {
      // console.log(data.detail,"--DATA")
      this.video_header = true;
    } else {
      this.video_header = false;
    }

    if (data.detail.scrollTop < 150) {
      // console.log(data.detail)
      this.scroll_event.desktop_header = true;
    } else {
      this.scroll_event.desktop_header = false
    }

    if (data.detail.scrollTop < 200) {
      this.scroll_event.mobile_header = false;
    } else {
      this.scroll_event.mobile_header = true;
    }


    if (data.detail.scrollTop < 61) {
      this.scroll_event.no_search_icon = false;
    } else {
      this.scroll_event.no_search_icon = true;
    }

    if (data.detail.scrollTop < 61) {
      this.scroll_event.back_button = false;
    } else {
      this.scroll_event.back_button = true;
    }
  }


  async share(item) {
    if (this.ismobile) {

      let share_url;
      if (this.website_settings.enable_referral == 1) {
        share_url = this.website_settings.app_settings.website_url + location.pathname + '?refer=' + localStorage['referral_code']
        // location.pathname == '/my-profile/referral' ? share_url = this.website_settings.app_settings.website_url +'/home?refer='+localStorage.referral_code : share_url = this.website_settings.app_settings.website_url + location.pathname +'?refer='+localStorage.referral_code 
      } else {
        share_url = window.location.href
      }

      // console.log(this.website_settings.app_settings.website_url)
      await Share.share({
        title: 'See cool stuff',
        text: 'Really awesome thing you need to see right here',
        url: share_url,
        dialogTitle: 'Share with buddies',
      });
      // console.log(Share)
    } else {
      const modal = await this.modalCtrl.create({
        component: SocialSharePage,
        cssClass: 'sharing-popup',
        componentProps: {
          item: item
        }
      })
      await modal.present();
    }
  }



  async get_address() {
    this.customer_Info({ user: localStorage['customerRefId'] }).subscribe(data => {
      if (data.message && data.message[0] && data.message[0].address && data.message[0].address.length != 0) {
        this.address_list = data.message[0].address;
        this.choose_default_address();
      } else {
        this.selected_address = undefined
        localStorage['Address'] = undefined
      }
    }, error => {
      //  console.log(JSON.stringify(error.json())); 
    });
  }


  choose_default_address() {
    // this.selected_address = this.cust_address;
    this.selected_address = this.address_list[0];
    localStorage['Address'] = JSON.stringify(this.selected_address);
    this.address_list.map(res => {
      if (res.is_default == 1) {
        this.selected_address = res;
        localStorage['Address'] = JSON.stringify(this.selected_address);
      }
    })

    // to change that and then check yourcart page in mobile view
    this.choose_address_phone(this.selected_address);
  }


  choose_address(address) {
    this.selected_address = address
    this.billing_address = address
    this.shipping_settings.selected_shipping_method = undefined
    this.shipping_settings.selected_shipping_method_name = undefined
    // this.check_area();
    this.location_info.latitude = Number(address.latitude)
    this.location_info.longitude = Number(address.longitude)
    this.zipcode_availbility(address.zipcode, 'empty')
  }


  // used to => no navigation  to unavilable-location
  choose_address_phone(address) {
    this.selected_address = address
    this.billing_address = address
    this.shipping_settings.selected_shipping_method = undefined
    this.shipping_settings.selected_shipping_method_name = undefined
    // this.check_area();
    this.location_info.latitude = Number(address.latitude)
    this.location_info.longitude = Number(address.longitude)
    this.website_settings.enable_zipcode == 1 ? this.zipcode_availbility_phone(address.zipcode, 'empty') : this.available_location = false;
  }


  // used to => no navigation  to unavilable-location
  zipcode_availbility_phone(zip_code, address) {
    zip_code == 'local' ? zip_code = address.zipcode : ''
    this.pincode_availability({ zipcode: zip_code }).subscribe(res => {
      if (res.message.is_available == 1) {
        this.location_error = false;
        this.available_location = false;
        if (address != 'empty') {
          address.zipcode ? this.store_local_location(address, zip_code) : this.store_map_location(address, zip_code)
          this.ismobile ? this.router.navigateByUrl('/tabs/home') : (this.modal_stacks.includes('location-modal') ? this.modalCtrl.dismiss() : null);
        }
        this.modal_stacks.includes('location-modal') ? this.modalCtrl.dismiss() : null
      } else {
        this.location_error = true;
        res.message.message
        this.available_location = true;
        // this.ismobile ? this.router.navigateByUrl('/unavilable-location') : '';
      }
      setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 100);
    })
  }

  empty_address() {
    this.address.zipcode = '';
    this.address.country = '';
    this.address.city = '';
    this.address.state = '';
  }

  search_location(event) {
    if (event.detail.value != '' && event.detail.value.length == 6) {
      let GoogleAutocomplete = new google.maps.places.AutocompleteService();
      GoogleAutocomplete.getPlacePredictions({ input: event.detail.value, componentRestrictions: { country: 'IN', }, types: ['(regions)'] },
        (predictions, status) => {
          status == "ZERO_RESULTS" ? this.empty_address() : null
          this.ngzone.run(() => {
            this.location_sugesstions = []
            if (predictions && predictions.length != 0) {
              predictions.forEach((prediction) => {
                if (prediction) {
                  this.location_sugesstions.push(prediction)
                }
              });
              this.store_for_address(event.detail.value);
            }
          });
        }
      );
    } else {
      this.location_sugesstions = []
    }
  }

  store_for_address(item) {
    let d: any = this.location_sugesstions.find(r => item == r.structured_formatting.main_text);
    this.validate_location_entered_pincode(d);
  }

  validate_location_entered_pincode(data) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: data.place_id }, (data, status) => {
      this.address.show = false;
      if (status == google.maps.GeocoderStatus.OK) {
        data[0].address_components.map(res => {
          if (res.types[0] == "postal_code") {
            this.address.zipcode = res.long_name;
          }
          res.types[0] == "country" ? this.address.country = res.long_name : null
          res.types[0] == "administrative_area_level_1" ? this.address.state = res.long_name : null

          if (res.types[0] == "locality") {
            this.address.city = res.long_name
          } else {
            res.types[0] == "administrative_area_level_2" ? this.address.city = res.long_name : null;
            setTimeout(() => { data[0].address_components.length == 3 ? (this.address_disable = false, this.address.city = '') : this.address_disable = true, window.dispatchEvent(new Event('resize')); }, 1500);
          }
        })
      }
    })
  }
  getPlaceAutocompletd_pincode(input) {
    const autocomplete = new google.maps.places.Autocomplete(input,
      {
        componentRestrictions: { country: 'IN' },
        types: ['(regions)']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', (res) => {
      const place = autocomplete.getPlace();
      this.validate_location_pincode(place)
    });
  }


  validate_location_pincode(data) {
    this.location_info.latitude = data.geometry.location.lat();
    this.location_info.longitude = data.geometry.location.lng();
    this.located.next('located');
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: data.place_id }, (data, status) => {
      this.address.show = false;
      if (status == google.maps.GeocoderStatus.OK) {
        data[0].address_components.map(res => {
          
          if (res.types[0] == "postal_code") {
            this.address.zipcode = res.long_name;
          }
          res.types[0] == "country" ? this.address.country = res.long_name : null
          res.types[0] == "administrative_area_level_1" ? this.address.state = res.long_name : null

          if (res.types[0] == "locality") {
            this.address.city = res.long_name
          } else {
            res.types[0] == "administrative_area_level_2" ? this.address.city = res.long_name : null
          }
        })
      }
    })
  }

  choose_multistore_location(data) {
    if (this.website_settings && this.website_settings.enable_multi_store == 1) {
      this.businessId = data.business;
      localStorage['businessid'] = this.businessId;
      this.city = data.city;
      localStorage['city'] = data.city;
      this.location_info.city = localStorage['city'];
      this.get_home_data();
    } else {
      this.businessId = data.business
      this.city = data.core_city
      this.location = data.core_city
    }
  }


  validate_location(data, type) {
    this.location_info.latitude = data.geometry.location.lat();
    this.location_info.longitude = data.geometry.location.lng();
    this.located.next('located');
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: data.place_id }, (data, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        data[0].address_components.map(res => {
          if (res.types[0] == "postal_code") {
            this.location_info.zipcode = res.long_name;
            this.zipcode_availbility(this.location_info.zipcode, data);
          }
          res.types[0] == "country" ? this.location_info.country = res.long_name : null
          res.types[0] == "administrative_area_level_1" ? this.location_info.state = res.long_name : null
          res.types[0] == "locality" ? (this.city, this.location_info.city = res.long_name) : null
          this.location = data[0].formatted_address;
          this.location_info.address = data[0].formatted_address.split(this.location_info.city)[0].trim();
          if (type == 'get_map_fields') {
            this.map_fields_get.next('get_values')
          }
        })
      }
    })
  }


  decode(latlng, type) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (data, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        data[0].address_components.map(res => {
          this.location_info.latitude = data[0].geometry.location.lat();
          this.location_info.longitude = data[0].geometry.location.lng();
          if (res.types[0] == "postal_code") {
            this.location_info.zipcode = res.long_name;
            this.zipcode_availbility(this.location_info.zipcode, data);
          }
          res.types[0] == "country" ? this.location_info.country = res.long_name : null
          res.types[0] == "administrative_area_level_1" ? this.location_info.state = res.long_name : null
          res.types[0] == "locality" ? (this.city, this.location_info.city = res.long_name) : null
          this.location = data[0].formatted_address;
          this.location_info.address = data[0].formatted_address.split(this.location_info.city)[0].trim();
          if (type == 'get_map_fields') {
            this.map_fields_get.next('get_values')
          }
        })
      }
    })
  }

  getPlaceAutocomplete(input, type) {
    const autocomplete = new google.maps.places.Autocomplete(input,
      {
        componentRestrictions: { country: 'IN' },
        types: ['establishment']  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', (res) => {
      // console.log(res);
      const place = autocomplete.getPlace();
      // console.log(place);
      this.validate_location(place, type)
    });
  }


  async callfromappts_current_locate() {
    if (localStorage['city']) {
      this.location = localStorage['city'];
    } else {
      this.geo.getCurrentPosition().then((resp) => {
        this.location_info.latitude = resp.coords.latitude //13.0381896 //
        this.location_info.longitude = resp.coords.longitude// 80.1565461 //
        this.located.next('located');
        let locations = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
        this.decode(locations, '');
      }).catch((error) => {
        this.ismobile && this.website_settings.enable_multi_store == 1 ? this.router.navigateByUrl('/unavilable-location') : null;
      });
    }

  }



  async current_locate(type) {
    this.location_error = false;
    localStorage.removeItem("city");
    this.geo.getCurrentPosition().then((resp) => {
      this.location_info.permission_error = false
      this.location_info.latitude = resp.coords.latitude //13.0381896 //
      this.location_info.longitude = resp.coords.longitude// 80.1565461 //
      this.located.next('located');
      let locations = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
      this.decode(locations, type);      
    }).catch((error) => {
      error.message == 'User denied Geolocation' ? this.location_info.permission_error = true : this.location_info.permission_error = false
      this.ismobile && this.website_settings.enable_multi_store == 1 ? this.router.navigateByUrl('/unavilable-location') : null;
    });
  }




  get_firstlevel_customers(data): Observable<any> {
    let endpoint = "loyalty.affiliate_marketing.api.get_firstlevel_customers"
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  get_referal_dashboard_data(data): Observable<any> {
    let endpoint = "loyalty.affiliate_marketing.api.get_referal_dashboard_data"
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  store_cust_info() {
    if ((localStorage['customerRefId'] && localStorage['CustomerName'] != '') || localStorage['vendor_business_id']) {
      this.cust_email = localStorage['CustomerId'];
      this.cust_name = localStorage['CustomerName'];
      this.store_name = localStorage['store_name'] ? localStorage['store_name'] : this.store_name;
      this.login_role = localStorage['role'] ? localStorage['role'] : null
      this.edit_order_id = localStorage['edit_order_id'] ? localStorage['edit_order_id']  : undefined;
      if (localStorage['customer_role']) {
        this.customer_role = localStorage['customer_role'];
      }

    } else {
      this.login_role = localStorage['role'] ? localStorage['role'] : null
    }

    if (!this.cust_name && localStorage['guestRefId'] && localStorage['guestRefId'] != '') {
      this.check_guest_customer({ customer_id: localStorage['guestRefId'] }).subscribe(res => {
        localStorage['customerRefId'] = res.message.customer_id
        localStorage['guestRefId'] = res.message.customer_id
      })
    }
  }


  store_info() {

    if (localStorage['city'] && localStorage['setlocation']) {
      this.city = localStorage['city']
      this.location_info.city = localStorage['city']
      this.location = localStorage['setlocation']
      this.location_info.state = localStorage['state']
    } else {
      this.website_settings_getted.subscribe(res => {
        res == 'done' && this.website_settings.enable_multi_vendor == 0 && (this.website_settings.enable_multi_store == 1 || this.website_settings.enable_map == 1) ? this.location_popup = true : null
      })
    }

    
  }


  get_areas() {
    this.get_area_list().subscribe(res => {
      this.area_list = res.message
    })
  }


  check() {
    return this.location_error
  }

  async location_modal() {
    const modal = await this.modalCtrl.create({
      component: LocationPage,
      cssClass: (this.website_settings.location_areas && this.website_settings.location_areas.length != 0) ? "location-modal-locations" : "location-modal",
      backdropDismiss: (this.location && !this.available_location) == undefined ? false : true

    })
    this.modal_stacks.push('location-modal');
    await modal.present()
    await modal.onWillDismiss().then(res => {
      this.modal_stacks = this.modal_stacks.filter(item => item !== 'location-modal')
    })
  }


  async center_modal() {
    const modal = await this.modalCtrl.create({
      component: LocationPage,
      cssClass: this.ismobile ? "location-modal-locations" : "store-web",
      backdropDismiss: (!localStorage['CenterName'] || !this.center_name) ? false : true
    })
    await modal.present()
    await modal.onWillDismiss().then(res => {
      
    })
  }


  zipcode_availbility(zip_code, address) {
    zip_code == 'local' ? zip_code = address.zipcode : '';
    if (this.website_settings.enable_zipcode == 1) {
      if (this.website_settings.radius_based_validation == 1) {
        this.radius_base(zip_code, address);
      } else 
        this.zipcode_base(zip_code, address);
    } else 
      this.available_location = false;
  }

  // radius based
  radius_base(zip_code, address) {
    let d = { "business": this.businessId, lat: this.location_info.latitude, lng: this.location_info.longitude }
    this.check_distance_by_latlng(d).subscribe(res => {
      if (res.message.status == 'success') {
        this.location_error = false;
        this.available_location = false;
        if (address != 'empty') {
          address.zipcode ? this.store_local_location(address, zip_code) : this.store_map_location(address, zip_code)
          this.ismobile ? this.router.navigateByUrl('/tabs/home') : (this.modal_stacks.includes('location-modal') ? this.modalCtrl.dismiss() : null);
        }
        this.modal_stacks.includes('location-modal') ? this.modalCtrl.dismiss() : null
      } else {
        this.location_error = res.message.message;
        this.available_location = true;
      }
      setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 100);
    })
  }

  // zipcode based
  zipcode_base(zip_code, address) {
    this.pincode_availability({ zipcode: zip_code }).subscribe(res => {
      if (res.message.is_available == 1) {
        this.location_error = false;
        this.available_location = false;
        if (address != 'empty') {
          address.zipcode ? this.store_local_location(address, zip_code) : this.store_map_location(address, zip_code)
          if (this.website_settings.enable_location == 0) {
            this.ismobile ? this.router.navigateByUrl('/tabs/home') : (this.modal_stacks.includes('location-modal') ? this.modalCtrl.dismiss() : null);
          }
        }
        this.modal_stacks.includes('location-modal') ? this.modalCtrl.dismiss() : null
      } else {
        this.location_error = true;
        this.available_location = true;
        this.ismobile && this.website_settings.enable_multi_store == 1 ? this.router.navigateByUrl('/unavilable-location') : (!this.modal_stacks.includes('location-modal') ? null : null);
      }
      setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 100);
    })
  }

  store_map_location(address, zip_code) {
    localStorage['address'] = address[0];
    localStorage.removeItem('pincode_area');
    localStorage['setlocation'] = address[0].formatted_address;
    this.location = localStorage['setlocation'];
  }


  store_local_location(address, zip_code) {
    this.location_info.city = address.city
    this.city = address.city
    localStorage['city'] = address.city
    localStorage['zipcode'] = zip_code;

    if (this.website_settings.enable_multi_store == 1) {
      localStorage['state'] = address.info.state
      this.location_info.state = address.info.state
      localStorage['setlocation'] = address.area + "," + address.city + "-" + address.zipcode
      this.location = address.area + "," + address.city + "-" + address.state + "-" + address.zipcode
    } else {
      this.location = address.area + "," + address.city + "-" + address.zipcode
      localStorage['setlocation'] = address.area + "," + address.city + "-" + address.zipcode
    }
  }

  get_paymentgateway_method(): Observable<any> {
    let endpoint = this.apiUrl_ccavenue + 'settings';
    return this.get(this.baseMethod + endpoint);
  }
  get_paymentgateway_settings() {
    this.get_paymentgateway_method().subscribe((r: any) => {
      if (r.message != undefined)
        this.paymentgateway_settings = r.message;
    }, error => {
      console.log('Online Payment is disabled')
    });

  }

  get_razorpay_settings() {

  }

  async deposit() {
    const alert = await this.alertCtrl.create({
      header: 'Deposit Wallet',
      inputs: [
        {
          name: 'amount',
          type: 'number',
          placeholder: 'Enter amount'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {   // console.log('Confirm Cancel');
          }
        },
        {
          text: 'Ok',
          // handler: (alertData) => { this.load_razorpay(alertData.amount, "Insert Wallet Amount") }
        }
      ]
    })
    await alert.present();
  }

  ccav_encrypt(body,workingKey) {
    var m = crypto.createHash('md5');
    m.update(workingKey);
   	var key = m.digest();
    var iv = '\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f';	// change this
    var cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
    var encoded = cipher.update(body,'utf8','hex');
	  encoded += cipher.final('hex');
    return encoded;
  }

  async submit_payment_form(order_id, amount, customer_name='', retry_count=0) {
    try {
      if (retry_count >= this.paymentgateway_settings.max_retries) {
        this.alert('Maximum payment retries reached');
        return
      }
     const ccav_response = this.baseMethod + `ecommerce_business_store.ecommerce_business_store.v2.ccavenue.response_handler`;
      console.log("ccav_response",ccav_response)
      const body = {
        billing_name:localStorage['CustomerName'],
        billing_email:localStorage['email'],
        billing_tel:localStorage['Customerphone'],
        billing_address:JSON.parse(localStorage['Business_address']).business_address,
        billing_city:JSON.parse(localStorage['Business_address']).business_city,
        billing_state:JSON.parse(localStorage['Business_address']).business_state,
        billing_country:JSON.parse(localStorage['Business_address']).business_country,
        billing_zip: JSON.parse(localStorage['Business_address']).business_zip,
        merchant_param1:localStorage['CenterName'],
        merchant_id:this.paymentgateway_settings.ccav_merchant_id,
        "order_id":order_id,
        "currency":"INR",
        "amount": amount, // parseFloat(amount).toFixed(2),
        "redirect_url":ccav_response,
        "cancel_url":ccav_response,
        "merchant_param2": `token ${localStorage['api_key']}##${localStorage['api_secret']}`,
        "language":"EN",
        "payment_option": this.paymentgateway_settings.ccav_payment_option,
        "card_type":this.paymentgateway_settings.ccav_card_type,
        "card_name": this.paymentgateway_settings.ccav_card_name
      }

      const formData = new FormData();
      Object.keys(body).forEach(key => formData.append(key, body[key]));
      const queryString = new URLSearchParams(formData as any).toString()    
      const encodedBody = this.ccav_encrypt(queryString,this.paymentgateway_settings.ccav_working_key)

      if(this.ismobile && this.platform.is('android')) {
        const formContent = `
                  <form action="${this.paymentgateway_settings.ccav_url}" name="myForm" id="myForm" method="post">
                    <input type="hidden" name="encRequest" value="${encodedBody}">
                    <input type="hidden" name="access_code" value="${this.paymentgateway_settings.ccav_access_code}">
                    <input type="hidden" name="debug" value="${queryString}">
                    <script language="javascript">document.getElementById('myForm').submit();</script>
                  </form>`;
    
        let formContentUrl = 'data:text/html;base64,' + btoa(unescape(encodeURIComponent(formContent)))
        let browser = this.iab.create(formContentUrl, '_blank','location=no,zoom=no')

        browser.on('loadstart').subscribe((event) => {     
          const url = event.url
          if(url.match(/thankyou/)){
            this.inAppBrowserCloseAfterSuccess=true
            setTimeout(async () =>{
              browser.close()
            },1000)
          }
        })
  
        browser.on('loaderror').subscribe((event) => {
          this.alert(event)
        })

        browser.on('exit').subscribe(async () => {
          if(this.inAppBrowserCloseAfterSuccess){
            await this.order_payment_capture(undefined,order_id);
            this.inAppBrowserCloseAfterSuccess = false
          } else {
            await this.order_payment_capture(undefined,order_id, true);
          }
        })
  
      } else {
        //creating form
        const form_ccavenue = document.createElement('form');
        form_ccavenue.method = 'POST';
        form_ccavenue.target = "_self"
        form_ccavenue.action = this.paymentgateway_settings.ccav_url;
        form_ccavenue.style.display = 'none';
        form_ccavenue.append('Content-Type', 'application/x-www-form-urlencoded');
        form_ccavenue.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8')

        //encRequest
        const mapInput = document.createElement('input');
        mapInput.type = 'hidden';
        mapInput.name = 'encRequest';
        mapInput.value = encodedBody;
        form_ccavenue.appendChild(mapInput)
  
        //access_code
        const mapInput1 = document.createElement('input');
        mapInput1.type = 'hidden';
        mapInput1.name = 'access_code';
        mapInput1.value = this.paymentgateway_settings.ccav_access_code;
        form_ccavenue.appendChild(mapInput1)
  
        // debug
        const mapInputDBG = document.createElement('input');
        mapInputDBG.type = 'hidden';
        mapInputDBG.name = 'debug';
        mapInputDBG.value = queryString;
        form_ccavenue.appendChild(mapInputDBG)

        //appending to body
        document.body.appendChild(form_ccavenue)
        form_ccavenue.submit()
      }     
    } catch (error) {
      console.log("error", error)
      this.alert("There is an issue with the payment gateway, please try again.")
      await this.order_payment_capture(undefined,order_id, true);
    }
  }

  async load_razorpay(amount, description) {
    // var options = {
    //   "key": this.razorpay_settings.api_key,
    //   "amount": (amount * 100).toString(),
    //   "currency": "INR",
    //   "name": environment.app_config.app_name,
    //   "description": "Payment for" + description,
    //   "image": (this.razorpay_settings.site_logo ? this.product_img(this.razorpay_settings.site_logo) : null),
    //   "prefill": {
    //     "name": localStorage['customerName'],
    //     "email": localStorage['vendorId'] ? localStorage['email'] : localStorage['email'],
    //     "contact": localStorage['Customerphone']
    //   },
    //   "theme": {
    //     "color": this.r_pay_color
    //   },
    //   "modal": {
    //     "backdropclose": false,
    //     "ondismiss": () => { this.payemnet_error_callback(description, 'error') }
    //   },
      
    // };
    // this.payment_checkoutplugin_razorpay(options, amount, description)
  }

  async payment_checkoutplugin_razorpay(options, amount, description) {
    // try {
    //   let data = (await Checkout.open(options));
    //   data['response']['amount'] = amount
    //   data['response']['description'] = description

    //   if (description == 'Insert Wallet Amount') {
    //     this.wallet_payment_capture(amount, data['response']['razorpay_payment_id'])
    //   } else if (description == 'Order Repay') {
    //     this.razorpay_payment.next(data['response']);
    //   } else if (this.check_which(description)[0] == 'Subscription' || this.check_which(description)[0] == 'Subscription Repay') {
    //     this.subscripiton_payment_capture(data['response']['razorpay_payment_id'], this.check_which(description)[1])
    //   } else {
    //     this.order_payment_capture(data['response']['razorpay_payment_id'], description);
    //   }
    // } catch (error) {
    //   if (description == 'Insert Wallet Amount') {

    //   } else if (description == 'Order Repay') {

    //   } else if (this.check_which(description)[0] == 'Subscription' || this.check_which(description)[0] == 'Subscription Repay') {

    //   } else {
    //     this.order_payment_capture(undefined, description);
    //   }
    //   this.alert('Payment Failed'); //Doesn't appear at all
    // }
  }

  payment_Success_callback(data) {
    if (data['response']['description'] == 'Insert Wallet Amount') {
      this.wallet_payment_capture(data['response']['amount'], data['response']['razorpay_payment_id'])
    } else if (data['response']['description'] == 'Order Repay') {
      // this.razorpay_payment.next(data['response']);
    } else if (this.check_which(data['response']['description'])[0] == 'Subscription' || this.check_which(data['response']['description'])[0] == 'Subscription Repay') {
      this.subscripiton_payment_capture(data['response']['razorpay_payment_id'], this.check_which(data['response']['description'])[1])
    } else {
      this.order_payment_capture(data['response']['razorpay_payment_id'], data['response']['description']);
    }
  }

  payemnet_error_callback(description, error) {
    if (description == 'Insert Wallet Amount') {

    } else if (description == 'Order Repay') {

    } else if (this.check_which(description)[0] == 'Subscription' || this.check_which(description)[0] == 'Subscription Repay') {

    } else {
      this.order_payment_capture(undefined, description);
    }
    this.alert('Payment Failed'); //Doesn't appear at all
  }

  check_which(data) {
    let d = data.split(',')
    return d
  }

  wallet_payment_capture(amount, transaction_id) {
    let data = {
      customer_id: localStorage['customerRefId'],
      payment_method: 'Razor Pay',
      transaction_id: transaction_id,
      amount: Number(amount),
      order_from: 'Android Mobile App'
    }
    this.insert_wallet_order(data).subscribe(res => {
      if (res.message.status == 'success') {
        this.get_wallet_detail(1);
      }
      // console.log(res);
    })
  }


  async order_payment_capture(id, order_id, payment_failed_or_navigated_back_without_payment=false) {
    let loader = await this.loadingCtrl.create({ message: 'Please Wait...' });
    await loader.present();
    if (id) {
      var updatedate = { 'order_id': order_id, 'transaction_id': id }
      this.update_order_status(updatedate).subscribe(r => {
        this.success(order_id);
      }, error => {
        // console.log(JSON.stringify(error.json()));
      });
    } else {
      if(payment_failed_or_navigated_back_without_payment) {
        this.failed(order_id);
      } else {
        this.success(order_id); 
      }
    }
  }

  loader_dismiss() {
    setTimeout(() => { this.loadingCtrl.dismiss();; })
  }

  subscripiton_payment_capture(id, order_id) {
    let data = {
      'order_id': order_id,
      'transaction_id': id,
      capture: 1
    }
    this.update_order_status(data).subscribe(res => {
      if (res.message.status == true) {
        this.subscribe_emit.next("modal closed")
        this.alert('Subscription is sucessfully completed.')
        this.modalCtrl.dismiss({ 'status': 'success' });
      }
    })
  }


  success(order_id) {
    this.loader_dismiss();
    this.delivery_slots = [];
    this.selected_slot = [];
    this.shipping_methods = [];
    this.shipping_settings = {};
    this.cart_data = {};
    this.marketplace_items = [];
    this.fl_items = [];
    this.cart_count = 0;
    this.total_amount = 0;
    this.wish_count = 0;
    this.get_cart_item();
    if (localStorage['guest_CustomerName']) {
      this.router.navigate(['/thankyou-success', order_id], { replaceUrl: true })
      this.guest_cust_name = undefined;
      this.guest_cust_email = undefined;
      localStorage.clear();
    } else {
      //this.alert(`thankyou/${order_id}`)
      this.router.navigate(['/thankyou-success', order_id], { replaceUrl: true })
    }
  }

  failed(order_id) {
    this.loader_dismiss();
    this.delivery_slots = [];
    this.selected_slot = [];
    this.shipping_methods = [];
    this.shipping_settings = {};
    this.cart_data = {};
    this.marketplace_items = [];
    this.fl_items = [];
    this.cart_count = 0;
    this.total_amount = 0;
    this.wish_count = 0;
    this.get_cart_item();
    if (localStorage['guest_CustomerName']) {
      this.router.navigate(['/thankyou', order_id], { replaceUrl: true })
      this.guest_cust_name = undefined;
      this.guest_cust_email = undefined;
      localStorage.clear();
    } else {
      //this.router.navigate(['/thankyou', order_id], { skipLocationChange: true, queryParamsHandling: 'merge'})
      this.router.navigate(['/thankyou', order_id], { replaceUrl: true })
    }
  }

  get_wallet_detail(page_no) {
    let data = { list_type: 'app_pay', vendor: localStorage['customerRefId'], page_no: page_no, page_len: (this.ismobile ? 10 : 20) }
    this.get_wallet_details(data).subscribe(res => {
      if (res.message.length != 0) {
        // this.payment_bank.wallet = true;
        if (res.message[0].transactions.length != 0) {
          this.website_settings.no_more_wallet_history = false;
          page_no == 1 ? (this.wallet_detail = res.message[0]) : (this.wallet_detail = [...this.wallet_detail, ...res.message[0]])
        } else {
          this.website_settings.no_more_wallet_history = true;
        }

        this.website_settings.wallet_no_history = false
      } else {
        this.website_settings.wallet_no_history = true
      }
    })
  }

  //  // Call this function when your app starts
  OneSignalInit(key) {
    OneSignal.setAppId(key);
    OneSignal.setNotificationOpenedHandler((data) => {
      if (data.notification.body.indexOf('Your order') != -1 || data.notification.body.indexOf('your order') != -1) {
        // this.nav.push(OrderPage,{name:data.notification.additionalData['add_data'], type:'order'})
      }
      else {
        const routUrl = data.notification.additionalData['url']
        this.get_all_route({ 'route': routUrl }).subscribe((res) => {
          console.log(res, "response", data.notification.additionalData['url'])
          if (res.message.type == 'Product Category') {
            this.router.navigateByUrl('/shop/' + routUrl)
          } else if (res.message.type == 'Product') {
            this.router.navigateByUrl('/pr/' + routUrl)
          }
        })
      }
    });
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log('User accepted notifications: ' + accepted);
    });
    OneSignal.getDeviceState((res) => {
      localStorage['player_id'] = res.userId;
      localStorage['customerRefId'] && localStorage['player_id'] && localStorage['player_id'] != "undefined" && localStorage['player_id'] != undefined ? this.update_onsignal_id(true).subscribe(res => { console.log("one signal id updated..", res) }) : null
    })
    // }
  }

  get_business_info() {
    let data = { "name": this.businessId ? this.businessId : 'BS-00001' }
    this.get_business_detail(data).subscribe(res => {
      this.business_info = res.message[0]
    })
  }

  get(endpoint: string) {
    if (localStorage['api_key'] != undefined) {
      this.httpHeaders = new HttpHeaders({ 'Authorization': 'token ' + localStorage['api_key'] + ":" + localStorage['api_secret'] });
    }
    if((localStorage['customerRefId']) && localStorage['api_key']) {
      this.httpOptions = ({ headers: this.httpHeaders });
    } else {
      this.httpOptions = {};
    }
    return this.http.get(endpoint, this.httpOptions);
  }

  postmethod(endpoint: string, data) {
    if (localStorage['api_key'] != undefined) {
      this.httpHeaders = new HttpHeaders({ 'Authorization': 'token ' + localStorage['api_key'] + ":" + localStorage['api_secret'] });
    }
    if ((localStorage['customerRefId'] || localStorage['vendor_business_id']) && localStorage['api_key']) {
      this.httpOptions = ({ headers: this.httpHeaders });
    } else {
      this.httpOptions = {};
    }
    return this.http.post(endpoint, data, this.httpOptions);
  }


  // apiUrl_common

  get_all_website_settings(data): Observable<any> {
    let endpoint = this.apiUrl_common + 'get_all_website_settings_dynamic';
    return this.postmethod(this.baseMethod + endpoint, data);
    // let endpoint = this.apiUrl_common + 'get_all_website_settings';
    // return this.get(this.baseMethod + endpoint);
  }

  forget_password(data): Observable<any> {
    // let endpoint = 'frappe.core.doctype.user.user.reset_password';
    let endpoint = this.apiUrl_common + 'send_user_forget_pwd_mail';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // apiUrl_carts
  get_cart_items(): Observable<any> {
    let endpoint = this.apiUrl_carts + 'get_cart_items';
    return this.get(this.baseMethod + endpoint);
  }

  movetocart(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'move_item_to_cart';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  delete_cart_items(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'delete_cart_items';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_cart_items(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'update_cartitem';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  validate_attributes_stock(data): Observable<any> {
    data.customer = localStorage['customerRefId']
    let endpoint = this.apiUrl_carts + 'validate_attributes_stock';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  validate_product_cart_qty(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'validate_product_cart_qty';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_cart_items(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'insert_cart_items';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  movealltocart(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'move_all_tocart';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_cartitems_stock_mob(data): Observable<any> {
    let endpoint = this.apiUrl_carts + 'validate_cart_items_stock';
    return this.get(this.baseMethod + endpoint);
  }

  //apiUrl vendor

  create_return_request(data): Observable<any> {
    // let datas = { data: JSON.stringify(data) }
    // let endpoint = this.apiUrl_vendors + 'create_return_request';
    let endpoint = this.apiUrl_orders + 'create_return_request';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // apiUrl_customer
  get_customer_dashboard(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'get_customer_dashboard';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  order_lists(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'get_customer_order_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  customer_Info(info): Observable<any> {
    let guest = (localStorage['guestRefId'] && localStorage['guestRefId'] != '') ? localStorage['guestRefId'] : null
    info.guest_id = guest;
    let endpoint = this.apiUrl_customer + 'get_customer_info';
    return this.postmethod(this.baseMethod + endpoint, info);
  }

  insert_address(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'insert_address';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_update_customer(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'insert_update_customer';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_address(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'update_address';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  delete_addres(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'delete_address';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_customer_messages(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'get_customer_messages';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_contact_form(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'insert_contact_enquiry';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  cancel_order(data) {
    let endpoint = this.apiUrl_customer + 'cancel_order';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // for getting order details with messages
  get_order_info(data): Observable<any> {
    let endpoint = this.apiUrl_customer + "get_order_info"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  send_otp(data): Observable<any> {
    let endpoint = this.apiUrl_customer + "send_otp"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  verify_otp(data): Observable<any> {
    let endpoint = this.apiUrl_customer + "verify_otp"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  enable_edit_order(data): Observable<any> {
    let endpoint = this.apiUrl_orders + "edit_order?order_id=" + data.order_id;
    return this.get(this.baseMethod + endpoint);
  }

  update_order(data): Observable<any> {
    var update_data = { data: JSON.stringify(data) }
    let endpoint = this.apiUrl_orders + "update_order";
    return this.postmethod(this.baseMethod + endpoint,update_data);
  }

  get_order_invoices(data): Observable<any> {
    let endpoint = this.apiUrl_orders + "get_order_invoices"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

 post_order_feedback(data): Observable<any>{
   let endpoint = this.apiUrl_orders + "post_order_feedback";
    return this.postmethod(this.baseMethod + endpoint, data);
 }

 
  getLogin(accountInfo: any): Observable<any> {
    let endpoint = this.apiUrl_customer + 'login';
    return this.postmethod(this.baseMethod + endpoint, accountInfo);
  }

  get_vendor_Login(accountInfo: any): Observable<any> {
    let endpoint = this.apiUrl_customer + 'login';
    return this.postmethod(this.baseMethod + endpoint, accountInfo);
  }

  get_collection_products(data: any): Observable<any> {
    let endpoint = this.apiUrl_product + 'get_collection_products';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  wallet_details(data): Observable<any> {
    let endpoint = this.apiUrl_customer + 'get_wallet_details';
    return this.get(this.baseMethod + endpoint);
  }

  // apiUrl_product
  search_products(data): Observable<any> {
    this.website_settings.enable_multi_store == 1 ? data.business = this.businessId : null;
    let endpoint = this.apiUrl_product + 'get_search_products';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  sub_product_category(data): Observable<any> {
    this.website_settings.enable_multi_store == 1 ? data.business = this.businessId : null;
    let endpoint = this.apiUrl_product + 'get_category_products';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_review(data): Observable<any> {
    var datas = { data: JSON.stringify(data) }
    let endpoint = this.apiUrl_product + 'insert_review';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_product_details(data): Observable<any> {
    let endpoint = this.apiUrl_product + 'get_product_details'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // apiUrl_category
  get_parent_categories(data): Observable<any> {
    this.website_settings.enable_multi_store == 1 ? data.business = this.businessId : null;
    let endpoint = this.apiUrl_category + 'get_parent_categories';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // get_category_page_data
  get_category_page_filters(data): Observable<any> {
    let endpoint = this.apiUrl_category + 'get_category_filters'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // apiUrl_checkout
  get_states_lists(country): Observable<any> {
    let endpoint = this.apiUrl_checkout + 'get_country_states?country=' + country;
    return this.get(this.baseMethod + endpoint);
  }

  get_payment_method(data): Observable<any> {
    let endpoint = this.apiUrl_checkout + 'get_payment_methods';
    return this.postmethod(this.baseMethod + endpoint, data)
  }

  get_shipping_method(): Observable<any> {
    let endpoint = this.apiUrl_checkout + 'get_shipping_methods';
    return this.get(this.baseMethod + endpoint);
  }

  calculate_vendor_based_shipping_charges(data): Observable<any> {
    // calculate_shipping_charges
    let endpoint = this.apiUrl_checkout + 'calculate_shipping_charges';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  calculate_restaurant_ship_charge(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.calculate_restaurant_ship_charge';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_order_discount(data): Observable<any> {
    let endpoint = this.apiUrl_checkout + 'get_order_discount';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  validate_coupon(data): Observable<any> {
    let endpoint = this.apiUrl_checkout + 'validate_coupon'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_cart_delivery_slots(data): Observable<any> {
    let endpoint = this.apiUrl_checkout + 'get_cart_delivery_slots';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  // apiUrl_orders
  get_return_request_info(data): Observable<any> {
    let endpoint = this.apiUrl_orders + 'get_return_request_info?order_id='+data.order_id;
    return this.get(this.baseMethod + endpoint);
  }

  insertOrder(data): Observable<any> {
    var datas = { data: JSON.stringify(data) }
    let endpoint = this.apiUrl_orders + 'insert_order';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_category_landing_data(data): Observable<any> {
    let endpoint = this.apiUrl_product + 'get_category_landing_data';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  reorder(data) {
    let endpoint = this.apiUrl_orders + 'reorder';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_cust_phone(phone): Observable<any> {
    let endpoint = 'Customers?filters=[["phone","=",' + phone + ']]'
    // let endpoint = 'Customers?fields=["email","phone","name"]'
    return this.get(this.baseResource + endpoint);
  }

  // Masters api url

  get_centre_list(): Observable<any> {
    let endpoint =  this.apiUrl_masters + 'get_centres'
    return this.get(this.baseMethod + endpoint);
  }

  get_cancel_reasons():Observable<any>{
    let endpoint = this.apiUrl_masters + 'get_cancel_reasons';
    return this.get(this.baseMethod + endpoint);
  }



  get_app_version(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/app_version.json";
    return this.http.get(endpoint);
  }

  get_oneSignal_id(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/one_signal.json";
    return this.http.get(endpoint);
  }

  log_rocket(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/logrocket.json"; //go1grocery
    return this.http.get(endpoint);
  }

  footer_section(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/footer.json"; //go1grocery
    return this.http.get(endpoint);
  }

  register(data): Observable<any> {
    data = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_customers';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  // insert_affiliate_registration
  insert_affiliate_registration(data): Observable<any> {
    data = { data: JSON.stringify(data) }
    let endpoint = 'loyalty.affiliate_marketing.api.insert_affiliate_registration';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_edit_address(data): Observable<any> {
    var datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_customers';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  sasslogin(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.login_customer';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_guest_customer(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.check_guest_customer';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_social_login(data): Observable<any> {
    var datas = {
      data: JSON.stringify(data),
      get_user_token: 0
    }
    let endpoint = "ecommerce_business_store.ecommerce_business_store.mobileapi.social_login_customer"
    return this.postmethod(this.baseMethod + endpoint, datas)
  }

  get_mobile_homepage(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.v2.common.get_page_content';
    return this.postmethod(this.baseMethod + endpoint, data)
  }

  get_scroll_content(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.cms.doctype.web_page_builder.web_page_builder.get_scroll_content';
    return this.postmethod(this.baseMethod + endpoint, data)
  }

  insertcart_multiItems(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insertcart_multiItems';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  clear_cartitem(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.clear_cartitem';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_area_list(): Observable<any> {
    let data = ''
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_all_area_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_google_locations(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_google_locations';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  pincode_availability(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_pincode_availability';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_distance_by_zipcode(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_distance_by_zipcode';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_distance_by_latlng(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_distance_by_latlng';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_expected_delivery(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_user_location_delivery'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_product_enquiries(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_product_enquiries';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_razorpaysetting(): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.razor_pay_settings';
    return this.get(this.baseMethod + endpoint)
    // return null
  }

  update_user(data, Id): Observable<any> {
    var datas = {
      doc: JSON.stringify(data)
    }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.update_doc';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_generalsetting(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_general_settings';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_shipping_settings(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_settings_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_menu_availability(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_cartitems_stock'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_checkout_guest_customer(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.doctype.customers.customers.update_checkout_guest_customer';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_order_details(data): Observable<any> {
    let endpoint = "ecommerce_business_store.ecommerce_business_store.api.get_order_details"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_country_list(): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_country_list';
    return this.get(this.baseMethod + endpoint);
  }

  get_product_detail(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_product_details?route=' + data
    return this.get(this.baseMethod + endpoint);
  }

  acctount_setting_info(data): Observable<any> {
    let endpoint = 'Customers/' + data
    return this.get(this.baseResource + endpoint);
  }

  ssr_test(): Observable<any> {
    return this.http.get('https://innila-default-rtdb.firebaseio.com/ssr_test.json')
  }

  get_meta(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_meta_info'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  test_detail(data): Observable<any> {
    let endpoint = 'https://mi2.tridotstech.com/api/method/community.events.api.get_event_details'
    return this.postmethod(endpoint, data);
  }

  get_page_details(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_page_details';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_questionaproduct(data): Observable<any> {
    var datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_questionaproduct';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_product_reviews_list(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.doctype.product.product.get_product_reviews_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_email_subscription(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_email_subscription';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  search_shops(data): Observable<any> {
    this.website_settings.enable_multi_store == 1 ? data.business = this.businessId : null;
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_vendor_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  add_ticket(data): Observable<any> {
    let endpoint = 'Message Forum'
    return this.http.post(this.baseResource + endpoint, data);
  }

  post_message_reply(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.post_message_reply'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_message_comments(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_message_comments'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  wallet_amount(data): Observable<any> {
    data = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.create_customer_wwd_request'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_wallet_details(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.accounts.api.get_wallet_details'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_wallet_order(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_wallet_order'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_customer_points(data): Observable<any> {
    let endpoint = 'loyalty.loyalty.api.get_customer_points'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_point_history(data): Observable<any> {
    let endpoint = 'loyalty.loyalty.api.get_point_history'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  checkout_redeem_points(data): Observable<any> {
    let endpoint = 'loyalty.loyalty.api.checkout_redeem_points'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_password(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.update_password'
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  get_business_detail(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_business_detail';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_web_subscription(data): Observable<any> {
    let endpoint = 'subscription.subscription.api.insert_web_subscription';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  renew_web_subscription(data): Observable<any> {
    let endpoint = 'subscription.subscription.api.renew_web_subscription';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_order_status(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.update_order_status'
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  insert_stockavail_notification(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_stockavail_notification';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_brand_based_products(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_brand_based_products';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  firebase_watchig(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/web_version.json"; //go1grocery
    return this.http.get(endpoint);
  }

  firebase_watchig_categoryMenu(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/category-menu.json"; //go1grocery
    return this.http.get(endpoint);
  }

  firebase_watchig_mobile_Product_List(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/mobile-product-list.json"; //go1grocery
    return this.http.get(endpoint);
  }

  firebase_watchig_caegory_list(): Observable<any> {
    let endpoint = environment.firebaseConfig.databaseURL + "/category_list.json"; //go1grocery
    return this.http.get(endpoint);
  }

  update_onsignal_id(enabled): Observable<any> {
    var playerId = { device_id: localStorage['player_id'],enabled: enabled ?  1 : 0}
    let endpoint = this.apiUrl_customer + 'update_device_id';
    return this.postmethod(this.baseMethod + endpoint,playerId);
  }

  store_referral(queryparam) {
    if (queryparam.refer && queryparam.refer != '') {
      if (!this.cookie.get('refer') && this.cookie.get('refer') != queryparam.refer) {
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 30);
        this.cookie.set('refer', queryparam.refer, dateNow)
        this.update_refer(window.location.href).subscribe(res => {
        })
      }
    }
  }

  update_refer(link): Observable<any> {
    var test = { referral_code: this.cookie.get('refer'), referred_from: this.ismobile ? 'Mobile Web' : 'Website', referred_link: link, ip_address: this.ip }
    let endpoint = 'Referred Link'
    return this.http.post(this.baseResource + endpoint, test);
  }

  check_birthdayclub(data): Observable<any> {
    let endpoint = 'Customers?filters=[["email","=","' + data + '"]]';
    return this.get(this.baseResource + endpoint);
  }

  inert_birthdayclub(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_doc';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_all_route(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_all_route';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_vendor_based_products(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_vendor_details';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_return_request_details(): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_return_request_details';
    return this.get(this.baseMethod + endpoint);
  }

  login(data): Observable<any> {
    let endpoint = '/login';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  vendor_login(data) {
    let endpoint = 'login';
    return this.get(this.baseMethod + endpoint + '?usr=' + data.usr + '&pwd=' + data.pwd);
  }

  FAQ_questions() {
    let endpoint = 'FAQ';
    return this.get(this.baseMethod + endpoint);
  }

  get_vendor_register(data): Observable<any> {
    let datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_page';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  insert_business(data): Observable<any> {
    let datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_business';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }


  get_all_products() {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.pos.get_all_products';
    return this.postmethod(this.baseMethod + endpoint, { business: localStorage['business'] });
  }

  get_all_category(data, limit_page_length, limit_start): Observable<any> {
    let endpoint = 'Product Category?fields=["name","category_name","display_order","is_active","menu_image","mobile_image","category_image","name"]&limit_page_length=' + limit_page_length + '&limit_start=' + limit_start;
    return this.get(this.baseResource + endpoint)
  }

  add_menu_category(data, type): any {
    var datas = {
      data: JSON.stringify(data)
    }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_product_category'
    return this.postmethod(this.baseMethod + endpoint, datas)
  }

  get_doc_list(data) {
    let endpoint = "ecommerce_business_store.ecommerce_business_store.mobileapi.get_doc_list"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  uploadfiles(data): any {
    var datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.upload_images'
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  remove_image(data): any {
    var endpoint = 'ecommerce_business_store.ecommerce_business_store.api.delete_current_img';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  get_categories_pos(data): any {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.category_list'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_categories(data): any {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_Product_Categories'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_brands(): Observable<any> {
    let endpoint = 'Product Brand?fields=["name","brand_name"]';
    return this.get(this.baseResource + endpoint);
  }

  get_specification_group(): Observable<any> {
    let endpoint = 'Specification Group?fields=["name","specification_group_name"]';
    return this.get(this.baseResource + endpoint);
  }

  get_specification_attributes(): Observable<any> {
    let endpoint = 'Specification Attribute?fields=["name","attribute_name"]';
    return this.get(this.baseResource + endpoint);
  }

  get_product(data): any {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.pos.get_product_details_data'
    return this.postmethod(this.baseMethod + endpoint, { product_id: data.Product });
  }

  get_all_attributes(): any {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_all_attributes'
    return this.get(this.baseMethod + endpoint);
  }

  add_menu_item(data): any {
    var datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_products'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_products(data): Observable<any> {
    var datas = { data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_products'
    return this.postmethod(this.baseMethod + endpoint, datas);
  }


  create_variant_combinations(data): Observable<any> {
    // console.log(data)
    var datas = { attributes: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.doctype.product.product.create_variant_combinations'
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_doc_data(doctype): Observable<any> {
    let datas = { 'doctype': doctype }
    let endpoint = this.apiUrl_common + "get_doc_meta"
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_otp(endpoint: string, data) {
    this.httpOptions = {};
    return this.http.post(endpoint, data, this.httpOptions);
  }

  get_registration_details(data): Observable<any> {
    let datas = { 'customer_id' : data, token:localStorage['otp_token']}
    let endpoint = this.apiUrl_customer + 'get_registration_details';
    return this.get_otp(this.baseMethod + endpoint, datas);
  }

  update_registration_details(data): Observable<any> {
    data.token = localStorage['otp_token']
    let endpoint = this.apiUrl_customer + 'update_registration_details';
    return this.get_otp(this.baseMethod + endpoint, data);
  }

  get_link_field_options_child(refdoc): Observable<any> {

    if (refdoc == "Classified Category") {
      this.filter = `?limit_page_length=100&fields=["category_name","name","parent_category"]&order_by=name%20asc`
    } else if (refdoc == "Job Category") {
      this.filter = `?limit_page_length=100&fields=["category_name","name","parent_category"]&order_by=category_name%20asc`
    } else if (refdoc == 'Job Role') {
      this.filter = `?fields=["*"]`
    } else if (refdoc == 'Expected Salary Type') {
      
    }

    let endpoint = this.ref_doc_type + this.filter;
    return this.get(this.baseResource + endpoint);
  }

  web_form_dynamic(data): Observable<any> {
    let endpoint = "Web Form/" + data;
    return this.get(this.baseResource + endpoint);
  }

  get_link_field_options_parent(refdoc): Observable<any> {

    if (refdoc == "Classified Category") {
      this.filter = '?limit_page_length=100&filters=[["is_group","=","1"]]&fields=["category_name","name"]&order_by=name%20asc'
    }
    else if (refdoc == 'Job Category') {
      this.filter = '?limit_page_length=100&filters=[["is_group","=","1"]]&fields=["category_name","name"]&order_by=category_name%20asc'
    } else if (refdoc == 'Job Role') {
      // this.filter= `?fields=["*"]`
      this.filter = '?limit_page_length=100&order_by=name%20asc&filters=[["is_group","=",1]]'
    } else if (refdoc == 'Expected Salary Type') {
      this.filter = ''
    }

    let endpoint = this.ref_doc_type + this.filter;
    return this.get(this.baseResource + endpoint);
  }

  // Get
  page_length = "?limit_page_length=100&order_by=name%20asc"
  get_link_field_options(): Observable<any> {
    if (this.ref_doc_type == 'Recruiter Company Size') {
      this.page_length = "?limit_page_length=100&order_by=creation%20desc"
    } else if (this.ref_doc_type == 'Country') {
      this.page_length = '?filters=[["enabled","=",1]]&limit_page_length=10000&order_by=name%20asc'
    }else if(this.ref_doc_type == 'State'){
      this.page_length = '?limit_page_length=10000&order_by=name%20asc'
    }
    let endpoint = this.ref_doc_type + this.page_length;
    return this.get(this.baseResource + endpoint);
  }

  c_city_state:any = ''
  get_link_field_city_options(data): Observable<any> {
    let endpoint = this.c_city_state;
    return this.get(this.baseResource + endpoint);
  }


  // Upload image 
  upload_image(data: any): Observable<any> {
    let endpoint = "File";
    return this.postmethod(this.baseResource + endpoint, data);
  }

  upload_image_url(unique_name: any): Observable<any> {
    let endpoint = "File/";
    return this.get(this.baseResource + endpoint + unique_name);
  }

  async filSizeAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-alert-class',
      header: "File Size Exceeded.!",
      message: 'Please Upload Files Below Size Of 10.0 MB...!',
      buttons: [{
        text: 'OK',
        handler: () => {
          this.alertCtrl.dismiss();
        }
      }]
    });
    await alert.present();
  }

  close_modal() {
    this.modalCtrl.dismiss();
  }

  check_device_type() {
    if (this.platform.is('android')) {
      return 'Android Mobile App'
    }
    else if (this.platform.is('ios')) {
      return 'IOS Mobile App'
    }
    else {
      return 'Website'
    }
  }

  get_all_categories(): any {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_all_categories'
    return this.get(this.baseMethod + endpoint);
  }

  post_subscribe(data: any): any {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_email_subscription'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  getRandomColor() {
    return this.bg_colors[Math.floor(Math.random() * this.bg_colors.length)];
  }

  getRandomColor1() {
    // var color = Math.floor(0x1000000 * Math.random()).toString(16);
    // return '#' + ('000000' + color).slice(-6);
    var letters = 'BCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  getRandomColor2() {
    var letters = 'BCDEF'.split('');
    var color = '#';
    for (var i = 6; i < 12; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  bg_colors = [

    {
      'background_color': '#0cbaba',
      'background_image': 'linear-gradient(315deg, #0cbaba 0%, #380036 74%)'
    },
    {
      'background_color': '#7f5a83',
      'background_image': 'linear-gradient(315deg, #7f5a83 0%, #0d324d 74%)'
    },
    {
      "background_color": "#eec0c6",
      "background_image": "linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%)"
    },
    {
      "background_color": "#63d471",
      "background_image": "linear-gradient(315deg, #63d471 0%, #233329 74%)"
    },
    {
      'background_color': '#fce043',
      'background_image': 'linear-gradient(315deg, #fce043 0%, #fb7ba2 74%)'
    },
    {
      "background_color": "#f6f0c4",
      "background_image": "linear-gradient(315deg, #f6f0c4 0%, #d99ec9 74%)"
    },
    {
      "background_color": "#a40606",
      "background_image": "linear-gradient(315deg, #a40606 0%, #d98324 74%)"
    }
  ]

  tabs_items = [
    {
      title: 'Home',
      route: '/tabs/home',
      icon: '/assets/icon/tab_icons/Home.svg',
      active_icon: '/assets/icon/tab_icons/Home-f.svg',
      tab: 'home',
      enable: 1,
    },
    {
      title: 'Category',
      route: '/tabs/category',
      icon: '/assets/icon/tab_icons/Category.svg',
      active_icon: '/assets/icon/tab_icons/Category-f.svg',
      tab: 'category',
      enable: 1
    },
    {
      title: 'Cart',
      route: '/tabs/yourcart',
      icon: '/assets/icon/tab_icons/cart.svg',
      active_icon: '/assets/icon/tab_icons/Cart-f.svg',
      tab: 'yourcart',
      enable: 1
    },
    {
      title: 'Account',
      route: '/tabs/my-profile',
      icon: '/assets/icon/tab_icons/profile.svg',
      active_icon: '/assets/icon/tab_icons/Profile-f.svg',
      tab: 'my-profile',
      enable: 1
    },
  ]

  menu = [
    {
      title: 'Home',
      route: '/tabs/home',
      icon: '/assets/icon/home.svg',
      enable: 1,
    },
    {
      title: 'Shop',
      route: '/p/homepage',
      icon: '/assets/icons/shop.svg',
      enable: 1
    },
     {
      title: 'About us',
      route:'/p/about-us',
      icon: '/assets/icon/About-us.svg',
      enable: 1
    },
    {
      title: 'Contact Us',
      route: '/contact-us',
      icon: '/assets/icon/contact-us.svg',
      enable: 1
    },
    {
      title: 'Market Place Settings',
      route: '',
      icon: '/assets/icons/market-place-settings.svg',
      enable: 0
    },
    {
      title: 'Policies',
      route: '/terms-condition',
      icon: '/assets/icon/terms.svg',
      enable: 1
    },
   
  ]

  sortings = [
    { text: 'Relevance', role: '' },
    { text: 'Name: A-Z', role: 'name_asc' },
    { text: 'Name: Z-A', role: 'name_desc' },
    { text: 'Price: Low-High', role: 'price_asc' },
    { text: 'Price: High-Low', role: 'price_desc' }
  ]

  tab_buttons(sub_header_data, route, type) {

    if (sub_header_data.length != 0) {
      sub_header_data.map((r,i)=> {
        if (r[type] == route) {
          r['isActive'] = true;
          this.tab_center.next(i + 1);
        } else {
          r['isActive'] = false;
        }

      })
    }

  }

  onSearch($event) {
    // this function must to declare
  }

  CustomSearch(term: string, item) {
    var String_list: any = typeof (item) === 'number' ? item.toString() : null;
    var String_list: any = typeof (item) === 'string' ? item : null;
    if (typeof (item) == 'object') {
      var keys = Object.keys(item);
      if (keys?.length == 1) {
        const [first] = keys;
        String_list = item[first];
      } else if (keys?.length > 1) {
        keys.map(res => {
          if (res == 'options' || res == 'language_name') {
            String_list = item[res];
          }
        })
      }
    }
    term = term.toLocaleLowerCase();
    return String_list.toLocaleLowerCase().indexOf(term) > -1;
  }

  preview_image(url) {
    window.open(url, '_blank');
  }

  attach_filter(data,color){
    var str = data.split('.');
     let value = str[1];
     value = value.toLowerCase();
      if(value && (value == 'apng' || value == 'avif' || value == 'gif' || value == 'jpeg' || value == 'jfif' || value == 'pjpeg' || value == 'pjp' || value == 'webp' || value == 'png' || value == 'svg' || value == 'jpg')){
        return `/assets/icon/msg/photo${color}.svg`
      }else if(value && value == 'pdf'){
        return `/assets/icon/msg/pdf${color}.svg`
      }else if(value && value == 'ppt'){
        return `/assets/icon/msg/ppt${color}.svg`
      }else if(value && (value == 'webm' || value == 'mpg' || value == 'mp2' || value == 'mpe' || value == 'ogg' || value == 'mp4'|| value == 'm4v' || value == 'avi' || value == 'wmv' || value == 'mov' || value == 'qt' || value == 'flv' || value == 'swf' || value == 'avchd' )){
        return `/assets/icon/msg/video${color}.svg`
      }else if(value && value == 'zip'){
        return `/assets/icon/msg/zip${color}.svg`
      }else{
        return `/assets/icon/msg/pdf${color}.svg`
      }
  }

  check_attribute_cart(detail,list){
    detail.vendor_price_list.find(res => {
      if(res.business == list.business){
        if(res.variants && res.variants.length != 0){
          let ids = '';
          let selected_attribute ='';
          let selected_cart_id =''
          res.variants.map(data =>{         
           if(data.attribute_id == res.variants[0].attribute_id && !data.selected_variant){
              data.is_pre_selected = 1;
              data.count = this.check_attribute(data.attribute_id);
              ids+=data.attribute_id;
              selected_cart_id = this.check_cart_id(ids);
              detail.count = data.count;
              selected_attribute += ' <div class="attribute"> <span>'+data.variant_text+'</span> </div>'
              detail.price = data.product_price;
              detail.old_price = data.old_price;
              detail.discount_percentage = data.discount_percentage;
              data.stock_alert_message = data.stock != 0 ? undefined : "No stock Available";
            }else{
              data.is_pre_selected = 0;
            } 
         })
         detail.selected_cart_id = selected_cart_id;
         detail.ids = ids;
         detail.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
        }
        else{
          detail.price = res.product_price;
          detail.old_price = res.old_price;
          detail.discount_percentage = res.discount_percentage;
        }
      }
    })
  }

  check_attribute(id){
    var cnt = 0;
    let array = [...this.marketplace_items,...this.fl_items]
    array.find( res =>{
      if(res.attribute_ids == id){
        cnt += res.quantity
      }
    })
    return cnt;
  }
    
    
  check_cart_id(id){
    let cart_id =''
    let array = [...this.marketplace_items,...this.fl_items]
    array.find( res =>{
      if(res.attribute_ids == id){
        if(res.quantity != 0){
          cart_id = res.name
        }
      }
    })
    return cart_id;
  }

  async select_attribute(product_info){
    const modal = await this.modalCtrl.create({
      component: SelectAttributeComponent,
      cssClass:  this.ismobile ? 'attribute_change_mobile' : 'attribute_change_web',
      componentProps: {  product_detail : product_info }
    })

    this.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.modal = false;
    }
  }

  store_login_info(queryparam:any){
    
    if(!this.cookie.get('password') && !this.cookie.get('username')){
      const dateNow = new Date();
      dateNow.setDate(dateNow.getDate()+14);
      this.cookie.set('username',queryparam.username,dateNow);
      this.cookie.set('password',queryparam.password,dateNow);
      this.cookie.set('customer_id',queryparam.customer_id,dateNow);
    }else if(this.cookie.get('customer_id') && this.cookie.get('customer_id') != queryparam.customer_id){
      const dateNow = new Date();
      dateNow.setDate(dateNow.getDate()+14);
      this.cookie.set('username',queryparam.username,dateNow);
      this.cookie.set('password',queryparam.password,dateNow);
      this.cookie.set('customer_id',queryparam.customer_id,dateNow);
    }
      
  }
}