<div class="attributes position-relative" *ngIf="product_detail.vendor_price_list && product_detail.vendor_price_list.length != 0">
  <ion-icon class="close_icon mouse" (click)="db.close_modal()" name="close-outline"></ion-icon>
        <!-- Attribute selection  -->
        <div class="d__flex top_head">
          <img [src]="db.product_imgs(product_detail.product_image || product_detail.image)" alt="">
          <h6 class="option_item">{{product_detail.item}}</h6>
        </div>
        <h6 class="m-0 title_ fnt-14 fnt-500">Choose an option for</h6>
        <div class="price_list__" *ngFor="let item of product_detail.vendor_price_list.slice(0,1)">
          <!-- [class.active_]="option.is_pre_selected && option.is_pre_selected == 1" -->
          <!-- <div> -->
            <ion-item class="choose_attribute" lines="none" *ngFor="let option of item.variants">
          <!-- (click)="select_attribute(option.name,item.attribute)" -->
            <div class="d__flex attribute__detal" *ngIf="option.price != '0.00'">
              <div class="attribute__">
                <div>
                  <ion-label class="ion-text-center fnt-12"> {{option.variant_text}}</ion-label>
                </div> 
                <div class="cart_buttons_container">
                  <div class="prices d__flex">
                    <p class="m-0 main-price fnt-15" *ngIf="option.price != '0.00'" ><span class="">{{db.website_settings.currency}}</span>{{option.product_price}}</p>
                    <p class="m-0 l-t old-price" *ngIf="option.old_price != '0.00'" ><span class=""></span>{{option.old_price | currency:db.website_settings.currency}}</p>
                  </div>
                  <div class="cart-buttons gap-10 position-relative">
                    <!-- [class.disabled]="check_disabled(option)" -->
                    <!-- && !(check_disabled(option)) -->
                    <ion-spinner *ngIf="option.temp_disabled"></ion-spinner>
                    <div *ngIf="!option.temp_disabled" class="cart__button ion-activatable ripple-parent position-relative"  (click)="( option.count == 0)?addtocart(product_detail,option,item.business):''" [ngClass]="option.count == 0?'not__added':'__added'">
                      <ion-ripple-effect *ngIf="option.count == 0"></ion-ripple-effect>
                      <ion-spinner *ngIf="option.temp_disabled"></ion-spinner>
                      <ion-button class="mouse __button __button_1" *ngIf="option.count && option.count != 0 && !option.temp_disabled" (click)="removetocart(product_detail,option,item.business)" fill="clear"><span>-</span></ion-button>
                      <div class="d__flex icon__Button">
                        <!-- <ion-icon *ngIf="option.count == 0 && big_button" src='/assets/districo/add-cart.svg'></ion-icon> -->
                        <ion-icon class="fnt-10" *ngIf="option.count == 0" src='/assets/districo/plus.svg'></ion-icon>
                        <span class="add__txt"> {{option.count != 0 ? option.count:''}} </span>
                      </div>
                      <ion-button class="mouse __button" *ngIf="option.count && option.count != 0 && !option.temp_disabled" (click)="addtocart(product_detail,option,item.business)" fill="clear"><span>+</span></ion-button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <app-cart-button class="cart__btns" [option]="product_detail" (click)="addtocart(product_detail)" (click)="removetocart(product_detail)"></app-cart-button> -->
              <!-- <p class="m-0 fnt-12 no-stock" *ngIf="option.stock_alert_message && option.price != '0.00'">{{option.stock_alert_message}}</p> -->
  
              <!-- <p class="m-0 fnt-12 no-stock" *ngIf="product_detail.stock_alert_message && option.price != '0.00'">{{product_detail.stock_alert_message}}</p> -->
            </div>
              <!-- <div class="d__flex just-s item_data">
                <div>
                </div>
              </div> -->
            </ion-item>
          <!-- </div> -->
        </div>
        <div *ngIf="db.ismobile" class="txt-r button_">
          <ion-button fill="clear" class="done txt__capital" (click)="db.close_modal()">Done</ion-button>
        </div>
</div>

<!-- <ion-footer *ngIf="db.ismobile">
  <ion-toolbar>
  </ion-toolbar>
</ion-footer> -->


<!-- <div class="attributes position-relative" *ngIf="product_detail.product_variants && product_detail.product_variants.length != 0 && false">
  <ion-icon class="close_icon" (click)="db.close_modal()" name="close-outline"></ion-icon>
        Attribute selection 
        <div>
          <h6 class="">Choose an option for</h6>
          <h6 class="option_item">{{product_detail.item}}</h6>
          [class.active_]="option.is_pre_selected && option.is_pre_selected == 1"
          <ion-item class="choose_attribute" lines="none" *ngFor="let option of product_detail.product_variants">
        (click)="select_attribute(option.name,item.attribute)"
          <div class="d__flex just-s attribute__detal" *ngIf="option.price != '0.00'">
            <div class="attribute__">
              <ion-label class="ion-text-center fnt-12"  [style.max-width]="'300px'"> {{option.combination_text}}</ion-label>
              <div class="prices d__flex">
                <p class="m-0 main-price fnt-15" *ngIf="option.price != '0.00'" ><span class="">{{db.website_settings.currency}}</span>{{option.price}}</p>
                <p class="m-0 l-t old-price" *ngIf="option.old_price != '0.00'" ><span class=""></span>{{option.old_price | currency:db.website_settings.currency}}</p>
              </div>
            </div>
            <div class="cart-buttons d__flex gap-10 position-relative">
              <div class="cart__button ion-activatable ripple-parent position-relative" [class.disabled]="db.check_disabled(product_detail)" (click)="( option.count == 0 && !(db.check_disabled(product_detail)))?addtocart(product_detail,option):''" [ngClass]="option.count == 0?'not__added':'__added'">
                <ion-ripple-effect *ngIf="option.count == 0"></ion-ripple-effect>
                <ion-button class="mouse __button" *ngIf="option.count && option.count != 0" (click)="removetocart(product_detail,option)" fill="clear"><span>-</span></ion-button>
                <ion-spinner *ngIf="option.temp_disabled"></ion-spinner>
                <div class="d__flex icon__Button">
                  <ion-icon *ngIf="option.count == 0 && big_button" src='/assets/districo/add-cart.svg'></ion-icon>
                  <ion-icon class="fnt-10" *ngIf="option.count == 0" src='/assets/districo/plus.svg'></ion-icon>
                  <span class="add__txt"> {{option.count != 0 ? option.count:''}} </span>
                </div>
                <ion-button class="mouse __button" *ngIf="option.count && option.count != 0" (click)="addtocart(product_detail,option)" fill="clear"><span>+</span></ion-button>
              </div>
            </div>
            <app-cart-button class="cart__btns" [option]="product_detail" (click)="addtocart(product_detail)" (click)="removetocart(product_detail)"></app-cart-button>
            <p class="m-0 fnt-12 no-stock" *ngIf="product_detail.stock_alert_message && option.price != '0.00'">{{product_detail.stock_alert_message}}</p>
          </div>
          </ion-item>
        </div>
</div> -->

