


<app-mobile-header *ngIf="desktop == undefined && db.path != '/page-not-found'" [clear_bg_a_border]="clear_bg_a_border" [cat_list]="cat_list" [tabs]="tabs" [home]="home" [back_route]="back_route" [no_home]="no_home" [no_cart]="no_cart" [title]="title" [no_search_icon]="no_search_icon"></app-mobile-header>


<!-- <ion-card *ngIf="!pageloaded" class="m0 p5 orderstoday animated fadeInUp border-f1">
  <ion-row>
    <ion-col col-12>
      <p class="m-0 p_1">
        <ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
      </p>
    </ion-col>
  </ion-row>
  <ion-row *ngFor="let detail of [1]">
    <ion-col col-12>
      <p>
        <ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
      </p>
    </ion-col>
  </ion-row>
</ion-card> -->
<!-- Web site header -->
<div *ngIf="desktop && !db.ismobile && db.path != '/page-not-found'" class="web__header"  (click)="close_menu()" [class.non_web__header]="db.content_video && db.path == '/'">
  <!-- [ngClass]="db.video_header?'non_web__header':''" -->
  <!--  -->
  <div>

    <div class="">
      <app-web-header (cart_pop)="cart_pop.emit($event)"  [cat_products]="cat_products" [default_header]="db.header_info"></app-web-header>
    </div>
 
    <div class="main-width" *ngIf="(db.header_info && db.header_info.is_static_menu == 1 && db.header_info.is_menu_full_width == 1)">
      <app-default-header  (cart_pop)="cart_pop.emit($event)" ></app-default-header>
    </div>
   
    <app-secondary-header  *ngIf="db.path?.split('/')[1] != 'my-profile' && db.path != '/checkout' && db.path != '/vendor-registration' && db.path?.split('/')[1] != 'thankyou' && db.secondary_header" [cat_products]="cat_products"></app-secondary-header>
  </div>

</div>







