
<div *ngIf="item.section_type == 'Custom Section' && (item.section_name =='FAQ Style 1' || item.section_name == 'FAQ Category Posts')" 
class="left__accordion_right_image section__border container_reverce main-width  {{item.class_name}} animate">
   <div  class="container__ d__flex">
      <div class="box__1">
         <h2 *ngIf="item.sub_title" class="m-0 head heading subtitle">{{item.sub_title}}</h2>
         
         <h4 *ngIf="item.title || item.section_name" class="m-0 sub_head heading title">{{item.title || item.section_name}}</h4>
         <ul *ngIf="item.data && item.data.length != 0" class="accordion">
            <li class="accordion_box" *ngFor="let data of item.data;let i = index" [class.active_box]="data.show">
            <div *ngIf="data.answer && data.question" (click)="open_close(i,item.data)" class="header__ mouse d__flex just-s">
               <h4 class="m-0 title__ heading width-90">{{data.question}}</h4>
               <ion-icon class="mouse" [name]="data.show ? 'chevron-up-outline':'chevron-down-outline'" ></ion-icon>
            </div>
            <p *ngIf="data.show" class="m-0 desc_class subtitle__ fnt-14">{{data.answer}}</p>
            </li>
         </ul>
      </div>
      <div class="box__2">
         <div class="image">
            <img class="right_image" *ngIf="item.right_image" src="{{db.product_img(item.right_image)}}">
         </div>
      </div>
   </div>
</div>

<div *ngIf="item.section_type == 'Custom Section' && item.section_name =='FAQ Style 2'"  
class="left__accordion_right_image section__border container_reverce main-width  {{item.class_name}} animate">
   <div  class="container__ d__flex">
      <div class="box__3">
         <h2 *ngIf="item.title"  class="m-0 head__ heading title">{{item.title}}</h2>
         <p *ngIf="item.sub_title" class="m-0 sub_head__ heading sub_title">{{item.sub_title}}</p>
      </div>
      <div class="box__1 box__4">

         <ul *ngIf="item.data && item.data.length != 0" class="accordion">
            <li class="accordion_box" *ngFor="let data of item.data;let i = index" [class.active_box__]="data.show">
            <div (click)="open_close(i,item.data)" class="header__ mouse d__flex just-s">
               <h4 class="m-0 title__ heading">{{data.question}}</h4>
               <ion-icon class="mouse" [name]="data.show ? 'chevron-up-outline':'chevron-down-outline'" ></ion-icon>
            </div>
            <p *ngIf="data.show" class="m-0 desc_class fnt-14">{{data.answer}}</p>
            </li>
         </ul>
      </div>
   </div>
</div>

<!-- Section faq-Having queries Button (section-3)-->
<div *ngIf="item.section_type == 'faq-section3' && item.section_name == 'Having queries Button'" class="container">
  <div class="top_section d__flex mb-20">
      <h2 class="fnt-48 fnt_500">{{item.title}}</h2>
      <ion-button class="ion-query-buttton">Having queries?</ion-button>
  </div>
  <ul class="accordion_faq">
      <li class="accordion_faq_box" *ngFor="let data of item.faq;let i = index"
          [class.active_faq_bgcolor]="data.show">
          <div *ngIf="data.answer && data.question" (click)="open_close(i,item.faq)"
              [class.active_faq_color]="data.show" class="accordion_faq_box_header d__flex curser_point">
              <h2 class=" qz-size fnt_400 faq--title webkit-text" [style.--line]="1">{{data.question}}</h2>
              <ion-icon class="fnt-32" [name]="data.show ? 'remove-circle-outline':'add-circle-outline'"></ion-icon>
          </div>
          <h2 *ngIf="data.show" class="fnt-16 fnt_400 desc_class faq_description">{{data.answer}}</h2>
      </li>
  </ul>
</div>

<!-- Section Faq-Table format (section-5)-->
<div  *ngIf="item.section_type == 'faq-section5' && item.section_name == 'Table format'" class="container">
  <div class="top_section d__flex mb-20">
      <h2 class="fnt-48 fnt_600 table_title_wid">{{item.title}}</h2>
      <div class="ask_more_btn d__flex color_purple curser_point">
          <h2 class="fnt-18 fnt_400 m-0">Ask more </h2>
          <ion-icon class="ion-icon-position" name="arrow-forward-outline"></ion-icon>
      </div>
  </div>
  <div class="table_title_faq d__flex">
      <h2 class="table_question fnt-16 fnt_600 desc_color">Questions</h2>
      <h2 class="table_answer fnt-16 fnt_600 desc_color">Answers</h2>
  </div>
  <div class="table_content_faq d__flex" *ngFor="let data of item.faq;let i = index">
      <h2 class="table_question fnt-20 fnt_600">{{data.question}}</h2>
      <h2 class="table_answer fnt-16 fnt_400 desc_class">{{data.answer}}</h2>
  </div>
</div>