import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// import { QuillModule } from 'ngx-quill';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClipboardModule } from 'ngx-clipboard';

import { IonicModule } from '@ionic/angular';

import { AddressAddComponent } from './address-add/address-add.component';
import { AddressComponent } from './address/address.component';
import {
  NormalAttributeComponent,
} from './attributes/normal-attribute/normal-attribute.component';
import {
  TableAttributeComponent,
} from './attributes/table-attribute/table-attribute.component';
import { GuestLoginComponent } from './auth/guest-login/guest-login.component';
import { NewSignupComponent } from './auth/new-signup/new-signup.component';
import {
  NormalLoginComponent,
} from './auth/normal-login/normal-login.component';
import { OtpLoginComponent } from './auth/otp-login/otp-login.component';
import { BrandComComponent } from './brand-com/brand-com.component';
import { CartButtonComponent } from './cart-button/cart-button.component';
import {
  AppliedFiltersComponent,
} from './categories/applied-filters/applied-filters.component';
import {
  CategoryBarComponent,
} from './categories/category-bar/category-bar.component';
import { FiltersComponent } from './categories/filters/filters.component';
import {
  MobileFiltersComponent,
} from './categories/mobile-filters/mobile-filters.component';
import { CategorylistComponent } from './categorylist/categorylist.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { DeliverySlotComponent } from './delivery-slot/delivery-slot.component';
import { Footer2Component } from './footer2/footer2.component';
import { ForgetComponent } from './forget/forget.component';
import { GridViewComponent } from './grid-view/grid-view.component';
import { HeaderComponent } from './header/header.component';
import {
  LocationPopupComponent,
} from './headers/location-popup/location-popup.component';
import {
  MobileHeaderComponent,
} from './headers/mobile-header/mobile-header.component';
import {
  MobileHeader2Component,
} from './headers/mobile-header2/mobile-header2.component';
import {
  SecondaryHeaderComponent,
} from './headers/secondary-header/secondary-header.component';
import { WebHeaderComponent } from './headers/web-header/web-header.component';
import { LogInComponent } from './log-in/log-in.component';
import { MapsComponent } from './maps/maps.component';
// import {
//   MessageBoardComponent,
// } from './messages/message-board/message-board.component';
// import {
//   MessageCmntComponent,
// } from './messages/message-cmnt/message-cmnt.component';
// import {
//   MessageInsertComponent,
// } from './messages/message-insert/message-insert.component';
// import {
//   MessageReplyComponent,
// } from './messages/message-reply/message-reply.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import {
  OrderStatusNotifyComponent,
} from './order-status-notify/order-status-notify.component';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { OrdersComponent } from './orders/orders.component';
import { PageBuilderComponent } from './page-builder/page-builder.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ProductBoxComponent } from './product-box/product-box.component';
import {
  ProductDetailsComponent,
} from './product-details/product-details.component';
import {
  ProductboxHomeComponent,
} from './productbox-home/productbox-home.component';
import { ProfileComponent } from './profile/profile.component';
// import { ReferalComponent } from './referal/referal.component';
import { RegisteRComponent } from './registe-r/registe-r.component';
import { ReturnResonsComponent } from './return-resons/return-resons.component';
import { RewardRedeemComponent } from './reward-redeem/reward-redeem.component';
import {
  SearchProductsComponent,
} from './search-products/search-products.component';
import { SlidersComponent } from './sliders/sliders.component';
// import { SubscribeComponent } from './subscribe/subscribe.component';
// import { SubscriptionComponent } from './subscription/subscription.component';
import { VideoPopupComponent } from './video-popup/video-popup.component';
import { WalletComponent } from './wallet/wallet.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { YourCartComponent } from './your-cart/your-cart.component';
import { DefaultHeaderComponent } from './headers/default-header/default-header.component';
// import { SubscriptionBoxComponent } from './subscription-box/subscription-box.component';
// import { BloggerDetailComponent } from './Blogger/blogger-detail/blogger-detail.component';
// import { BloggerListComponent } from './Blogger/blogger-list/blogger-list.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
// import { AddBlogComponent } from './Blogger/add-blog/add-blog.component';
// import { AdminCategoryListComponent } from './vendor/admin-category-list/admin-category-list.component';
// import { ViewCategoryComponent } from './vendor/view-category/view-category.component';
// import { ViewProductComponent } from './vendor/view-product/view-product.component';
// import { AdminProductListComponent } from './vendor/admin-product-list/admin-product-list.component';
// import { AttributesAddComponent } from './products-mgmt/attributes-add/attributes-add.component';
// import { AttributesEditComponent } from './products-mgmt/attributes-edit/attributes-edit.component';
// import { CategorySelectionComponent } from './products-mgmt/category-selection/category-selection.component';
// import { ImageAddComponent } from './products-mgmt/image-add/image-add.component';
// import { VariantsEditComponent } from './products-mgmt/variants-edit/variants-edit.component';
import { OurTeamComponent } from './page-sections/our-team/our-team.component';
import { TestimonialsComponent } from './page-sections/testimonials/testimonials.component';
import { ClientsComponent } from './page-sections/clients/clients.component';
import { FAQComponent } from './page-sections/faq/faq.component';
import { PricingComponent } from './page-sections/pricing/pricing.component';
// import { AttributesAddImgComponent } from './products-mgmt/attributes-add-img/attributes-add-img.component';
import { HomeHeaderComponent } from './headers/home-header/home-header.component';
import { ProfileCardComponent } from './profile card/profile-card/profile-card.component';
import { WizardFormsComponent } from './forms/wizard-forms/wizard-forms.component';
import { TabsButtonComponent } from './tabs-button/tabs-button.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectAttributeComponent } from './categories/select-attribute/select-attribute.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { MarqueeTextComponent } from './marquee-text/marquee-text.component';

@NgModule({
  declarations: [
    YourCartComponent,
    WishListComponent,
    WalletComponent,
    // SubscriptionComponent,
    // SubscribeComponent,
    SlidersComponent,
    RewardRedeemComponent,
    RegisteRComponent,
    ProfileComponent,
    PageBuilderComponent,
    OrdersComponent,
    OrderdetailComponent,
    MobileMenuComponent,
    MapsComponent,
    LogInComponent,
    HeaderComponent,
    GridViewComponent,
    ForgetComponent,
    Footer2Component,
    FiltersComponent,
    DeliverySlotComponent,
    DashBoardComponent,
    CategorylistComponent,
    CategoryBarComponent,
    BrandComComponent,
    AddressAddComponent,
    AddressComponent,
    CartButtonComponent,
    TableAttributeComponent,
    OtpLoginComponent,
    NewSignupComponent,
    OtpLoginComponent,
    RegisteRComponent,
    NormalLoginComponent,
    WebHeaderComponent,
    SecondaryHeaderComponent,
    MobileHeaderComponent,
    ProductBoxComponent,
    ProductDetailsComponent,
    AppliedFiltersComponent,
    MobileFiltersComponent,
    ReturnResonsComponent,
    // ReferalComponent,
    GuestLoginComponent,
    MobileHeader2Component,
    NormalAttributeComponent,
    SearchProductsComponent,
    // MessageBoardComponent,
    // MessageInsertComponent,
    // MessageReplyComponent,
    // MessageCmntComponent,
    OrderStatusNotifyComponent,
    LocationPopupComponent,
    VideoPopupComponent,
    ProductboxHomeComponent,
    PagenotfoundComponent,
    DefaultHeaderComponent,
    // SubscriptionBoxComponent,
    // BloggerDetailComponent,
    // BloggerListComponent,
    DynamicFormComponent,
    // AddBlogComponent,
    // AdminCategoryListComponent,
    // ViewCategoryComponent,
    // ViewProductComponent,
    // AdminProductListComponent,
    // AttributesAddComponent,
    // AttributesEditComponent,
    // CategorySelectionComponent,
    // ImageAddComponent,
    // VariantsEditComponent,
    ClientsComponent,
    FAQComponent,
    OurTeamComponent,
    PricingComponent,
    TestimonialsComponent,
    // AttributesAddImgComponent,
    HomeHeaderComponent,
    ProfileCardComponent,
    WizardFormsComponent,
    TabsButtonComponent,
    SelectAttributeComponent,
    TooltipComponent,
    MarqueeTextComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgSelectModule,
    IonicModule.forRoot(),
    // QuillModule.forRoot(),
  ],
  exports:[
    YourCartComponent,
    WishListComponent,
    WalletComponent,
    // SubscriptionComponent,
    // SubscribeComponent,
    SlidersComponent,
    RewardRedeemComponent,
    RegisteRComponent,
    ProfileComponent,
    PageBuilderComponent,
    OrdersComponent,
    OrderdetailComponent,
    MobileMenuComponent,
    MapsComponent,
    LogInComponent,
    HeaderComponent,
    GridViewComponent,
    ForgetComponent,
    Footer2Component,
    FiltersComponent,
    DeliverySlotComponent,
    DashBoardComponent,
    CategorylistComponent,
    CategoryBarComponent,
    BrandComComponent,
    AddressAddComponent,
    AddressComponent,
    CartButtonComponent,
    TableAttributeComponent,
    OtpLoginComponent,
    NewSignupComponent,
    OtpLoginComponent,
    RegisteRComponent,
    NormalLoginComponent,
    WebHeaderComponent,
    SecondaryHeaderComponent,
    MobileHeaderComponent,
    ProductBoxComponent,
    ProductDetailsComponent,
    AppliedFiltersComponent,
    MobileFiltersComponent,
    ReturnResonsComponent,
    // ReferalComponent,
    GuestLoginComponent,
    MobileHeader2Component,
    NormalAttributeComponent,
    SearchProductsComponent,
    // MessageBoardComponent,
    // MessageInsertComponent,
    // MessageReplyComponent,
    // MessageCmntComponent,
    OrderStatusNotifyComponent,
    LocationPopupComponent,
    VideoPopupComponent,
    ProductboxHomeComponent,
    PagenotfoundComponent,
    DefaultHeaderComponent,
    // SubscriptionBoxComponent,
    // BloggerDetailComponent,
    // BloggerListComponent,
    DynamicFormComponent,
    // AddBlogComponent,
    // AdminCategoryListComponent,
    // ViewCategoryComponent,
    // ViewProductComponent,
    // AdminProductListComponent,
    // AttributesAddComponent,
    // AttributesEditComponent,
    // CategorySelectionComponent,
    // ImageAddComponent,
    // VariantsEditComponent,
    ClientsComponent,
    FAQComponent,
    OurTeamComponent,
    PricingComponent,
    TestimonialsComponent,
    // AttributesAddImgComponent,
    HomeHeaderComponent,
    ProfileCardComponent,
    WizardFormsComponent,
    TabsButtonComponent,
    SelectAttributeComponent,
    TooltipComponent,
    MarqueeTextComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
