<div *ngIf="order_detail">
    <div *ngIf="order_detail.payment_method_name=='UPI Payment'">
<div class="d__flex just-s notify-container" *ngIf="order_detail.payment_status=='Pending'" [ngClass]="'failure'" [style.border-color]="'#ce211d96'">
    <div class="d__flex">
        <ion-icon class="alert" src="/assets/icon/order-notify/Failure.svg"></ion-icon>
        <div>
            <h4  class="m-0"  [style.color]="'#c90905'">Your payment has failed</h4>
            <p class="m-0 op-8" [ngClass]="db.ismobile?'fnt-12':'fnt-14'" [style.color]="'#c90905'">Please complete the payment to confirm the order</p>
        </div>
    </div>

    <ion-button class="ion-text-capitalize fnt-12"  [style.background]="'#c90905'" fill="clear"  (click)="db.submit_payment_form(order_detail.name,order_detail.outstanding_amount,order_detail.customer_name,order_detail.custom_payment_retries)">
        <ion-icon *ngIf="true" class="mr-5" name="reload-outline"></ion-icon>
        Pay Now
    </ion-button>
</div>
<div class="d__flex just-s notify-container" *ngIf="order_detail.payment_status=='Paid'" [ngClass]="'success'" [style.border-color]="'#073435'">
    <div class="d__flex">
        <ion-icon class="alert" src="/assets/icon/order-notify/Success.svg"></ion-icon>
        <div>
            <h4  class="m-0"  [style.color]="'#046306'">Payment success, your order is placed successfully</h4>
            <p class="m-0 op-8" [ngClass]="db.ismobile?'fnt-12':'fnt-14'" [style.color]="'#046306'">{{"Thank you for your purchase. Your order number is "+order_detail?.name}}</p>
        </div>
    </div>

    <ion-button class="ion-text-capitalize fnt-12"  [style.background]="'#046306'" fill="clear" (click)="goto()" >
        Continue Shopping
        <ion-icon *ngIf="order_detail?.docstatus" class="ml-5" name="chevron-forward-outline"></ion-icon>
    </ion-button>
</div>
</div>
</div>

<div *ngIf="order_detail">
    <div *ngIf="order_detail.payment_method_name=='Cash On Delivery'">
<div class="d__flex just-s notify-container" *ngIf="order_detail" [ngClass]="order_detail?.docstatus?'success':'failure'" [style.border-color]="order_detail?.docstatus?'#073435':'#ce211d96'">
    <div class="d__flex">
        <ion-icon class="alert" src="/assets/icon/order-notify/{{order_detail?.docstatus?'Success.svg':'Failure.svg'}}"></ion-icon>
        <div>
            <h4  class="m-0"  [style.color]="order_detail?.docstatus?'#046306 ':'#c90905'">{{!order_detail?.docstatus?'Your Payment Failed':'Your order placed successfully'}}</h4>
            <p class="m-0 op-8" [ngClass]="db.ismobile?'fnt-12':'fnt-14'" [style.color]="order_detail?.docstatus?'#046306 ':'#c90905'">{{!order_detail?.docstatus?"Sorry, We're not able to process your payment. Please try again":"Thank you for your purchase. Your order number is "+order_detail?.name}}</p>
        </div>
    </div>
    <!-- (click)="!order_detail?.docstatus?db.load_razorpay(order_detail.outstanding_amount,'Order Repay'): goto()" -->
    <ion-button class="ion-text-capitalize fnt-12"  [style.background]="order_detail?.docstatus?'#073435':'#c90905'" fill="clear" (click)="goto()"  >
        <ion-icon *ngIf="!order_detail?.docstatus" class="mr-5" name="reload-outline"></ion-icon>
        {{order_detail?.docstatus?'Continue Shopping':'Try Again'}}  
        <ion-icon *ngIf="order_detail?.docstatus" class="ml-5" name="chevron-forward-outline"></ion-icon>
    </ion-button>
</div>
</div>
</div>

<div class="d__flex just-s notify-container" *ngIf="type == 'onboard_screen'"  [style.border-color]="'#046306'">
    <div class="d__flex">
        <ion-icon class="alert" src="/assets/icon/order-notify/Success.svg"></ion-icon>
        <div>
            <h4  class="m-0"  [style.color]="'#046306'">Your registration completed successfully</h4>
            <!-- <p class="m-0 op-8" [ngClass]="db.ismobile?'fnt-12':'fnt-14'" [style.color]="order_detail?.docstatus?'#046306 ':'#c90905'">{{!order_detail?.docstatus?"Sorry, We're not able to process your payment. Please try again":"Thank you for your purchase. Your order number is "+order_detail?.name}}</p> -->
        </div>
    </div>
    <ion-button class="ion-text-capitalize fnt-12"  [style.background]="'#046306'" fill="clear" routerLink="/p/affiliated">
        <!-- <ion-icon *ngIf="!order_detail?.docstatus" class="mr-5" name="reload-outline"></ion-icon> -->
        Continue
        <ion-icon class="ml-5" name="chevron-forward-outline"></ion-icon>
    </ion-button>
</div>