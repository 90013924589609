
<div class="position-relative" >

  <div #left_arrow *ngIf="!db.ismobile && arrow && scrollable && checkOverflow(scrollable_cnt)" (click)="scroll('prev')" class="back-s hiding"><ion-icon name="chevron-back-outline"></ion-icon></div>

  <ul class="product__box grid" [class.list]="db.product_box.view == 'List View' && !scrollable" [ngClass]="scrollable?'scroll':'not-scroll'"  #scrollable_cnt (scroll)="scrolled($event)" >
    <img *ngIf="scroll_img && db.ismobile" class="scroll__img" src="{{db.product_img(scroll_img)}}" defaultImage="" alt="">
    <li *ngFor="let item of data" class="product"   [ngClass]="(db.product_box.view == 'Grid View' && !scrollable) ?(db.ismobile?'grid-2':'grid-'+db.product_box.row_count) :scrollable" [style.border-color]="border_color ? border_color:null">

      <div class="image" (mouseenter)="db.product_name = true" (mouseleave)="db.product_name = false">
        <img [routerLink]="true?'/pr/'+item.route:null" *ngIf="item.image || item.product_image" src="{{db.product_img(item.product_image || item.image)}}" defaultImage="" alt="">
        <span class="discount" *ngIf="item.discount_percentage">{{item.discount_percentage}}% Off</span>
        <p class="no-stock" *ngIf="item.inventory_method == 'Track Inventory' && item.stock == 0 && false" >No stock</p>
        <ion-button *ngIf="!scrollable && !db.ismobile" (click)="item.product_attributes.length == 0 ?openQuickView(item): goto_detail(item.route)" class="quick__view" fill="clear">Quick View</ion-button>
      </div>
      
      <div class="heartt mouse">
        <img class="heart" button *ngIf="db.website_settings && db.website_settings.app_settings" (click)="addtowish(item)" [src]="item.wish_count && (item.wish_count != 0) ? db.product_img(db.website_settings.app_settings.list_wishlist_filled) : db.product_img(db.website_settings.app_settings.list_wishlist)" alt=""> 
      </div>
      <div class="prices">
        <p class="m-0 main-price" *ngIf="item.price != '0.00'" >{{item.price | currency:db.website_settings.currency}}</p>
      </div>
      <!-- <div *ngIf="db.product_name" class="">
        <p  class="m-0 pop_up mouse webkit-text" [style.--line]="2">{{item.item}}</p>
      </div> -->
      <!-- <div class="description"> -->
        <!-- <a class="m-0 product__name mouse webkit-text" [style.--line]="2" [routerLink]="true?'/pr/'+item.route:null">{{item.item | titlecase}}</a> -->
        <!-- <div  class="sub__names" ><p class="m-0 sub__name webkit-text" [style.--line]="2" *ngIf="item.short_description && db.product_shrotdescription" > {{item.short_description}}  </p></div> -->
       
        <!-- <div class="star-rating" *ngIf="item.rating != 0;else norating;"> <ion-icon class="star" src="/assets/icon/star-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon> </div>
        <ng-template #norating class="star-rating" ><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5"></ion-icon></ng-template> -->
        
        <!-- <div class="star-rating" *ngIf="item.rating != 0;else norating;"> <ion-icon class="star" src="/assets/icon/star-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5-item.rating"></ion-icon> </div>
        <ng-template #norating class="star-rating" ><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5"></ion-icon></ng-template> -->


        <!-- <div class="star-rating" *ngIf="item.rating"> <ion-icon class="star" name="star"></ion-icon>{{item.rating}} </div> -->
        <!-- <div  *ngIf="(item.gross_weight || item.weight) && db.product_weight" class="d__flex weight__specify">
          <div *ngIf="item.gross_weight">Gross Wt:  {{item.gross_weight >= 1000?item.gross_weight/1000:item.gross_weight}}  {{item.gross_weight >= 1000?'Kg':'gms'}} </div>
          <span *ngIf="item.gross_weight"> / </span>
          <div [class.Net]="item.gross_weight" *ngIf="item.weight">Net Wt: {{item.weight >= 1000?item.weight/1000:item.weight}} {{item.weight >= 1000 ?'Kg':'gms'}} </div>
        </div> -->

        <!-- <div class="d__flex just-s buttons__container">
          <div class="prices">
            <p class="m-0 main-price" *ngIf="item.price != '0.00'" >{{item.price | currency:db.website_settings.currency}}</p>
            <p class="m-0 l-t old-price" *ngIf="item.old_price != '0.00'" >{{item.old_price | currency:db.website_settings.currency}}</p>
          </div>

                <app-cart-button class="cart__btns" [product_info]="item" (addto_cart)="addto_cart.emit($event)" (removeto_cart)="removeto_cart.emit($event)"></app-cart-button>
        </div> -->
                  <!-- <div class="d__flex just-s buttons__container">
        
                    <app-cart-button class="cart__btns" [product_info]="item" (addto_cart)="addto_cart.emit($event)" (removeto_cart)="removeto_cart.emit($event)"></app-cart-button>
                    </div> -->

      <!-- </div> -->
    </li>
  </ul> 
  <div #right_arrow *ngIf="!db.ismobile && arrow && scrollable  && checkOverflow(scrollable_cnt)" (click)="scroll('next')" class="next-s"><ion-icon name="chevron-forward-outline"></ion-icon></div>
  
</div>
