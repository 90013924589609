
<div class="position-relative" [class.mb-60]="list_view && db.ismobile">

  <div #left_arrow *ngIf="!db.ismobile && arrow && scrollable && checkOverflow(scrollable_cnt)" (click)="scroll('prev')" class="back-s hiding"><ion-icon name="chevron-back-outline"></ion-icon></div>

  <ul class="product__box grid" [class.create_grid_2]="create_grid_2" [class.list]="db.product_box.view == 'List View' && !scrollable" [ngClass]="scrollable?'scroll':'not-scroll'"  #scrollable_cnt (scroll)="scrolled($event)" >
    <img *ngIf="scroll_img && db.ismobile" class="scroll__img" src="{{db.product_img(scroll_img)}}" defaultImage="" alt="">
    <li *ngFor="let item of data" class="product" [ngClass]="(db.product_box.view == 'Grid View') ? (db.ismobile?'grid-2 product':!scrollable? 'product grid-'+ db.product_box.row_count : scrollable) : scrollable" [style.border-color]="border_color ? border_color:null">

      <div class="image" [class.align-c]="db.ismobile">
        <!-- <img [routerLink]="true?'/pr/'+item.route:null" *ngIf="!image_product && (item.image || item.product_image)" src="{{db.product_img(item.image || item.product_image)}}" defaultImage="" alt=""> -->
        <!-- <img [routerLink]="true?'/pr/'+item.route:null" *ngIf="image_product && (item.product_image || item.image)" src="{{db.product_img(item.product_image || item.image)}}" defaultImage="" alt=""> -->
        <img [routerLink]="true?'/pr/'+item.route:null" [src]="db.product_imgs(item.product_image || item.image)" defaultImage="" alt="">
        <span class="discount" *ngIf="item.discount_percentage">{{item.discount_percentage}}% Off</span>
        <p class="no-stock" *ngIf="item.inventory_method == 'Track Inventory' && item.stock == 0 && false" >No stock</p>
        <ion-button *ngIf="!scrollable && !db.ismobile" (click)="item.product_attributes && item.product_attributes.length != 0 ? goto_detail(item.route): openQuickView(item)" class="quick__view" fill="clear">Quick View</ion-button>
      </div>

      <div class="heartt mouse" *ngIf="false">
        <ion-icon class="heart" button  (click)="addtowish(item)" [src]="item.wish_count && (item.wish_count != 0) ? '/assets/icon/wish/wishlist-filled.svg' :  '/assets/icon/wish/Vector.svg' " ></ion-icon> 
      </div>
      <div *ngIf="item.is_fl_store" class="heartt">
        <ion-icon class="fl_store" [class.list_flstore]="db.product_box.view == 'List View' && !scrollable" button  src="/assets/imgs/direct-icon.svg" ></ion-icon> 
      </div>

      <div class="description">
        <!-- <p class="m-0 fnt-13" [routerLink]="true?'/pr/'+item.route:null" *ngIf="item.vendor_price_list && item.vendor_price_list.length != 0">Sold by <span class="available_store">{{ item.vendor_price_list[0].vendor_name }} <span *ngIf="item.vendor_price_list.length > 1"> + {{item.vendor_price_list.length -1}} more</span> </span></p> -->
        <!-- <p class="m-0 sold_by_d" *ngIf="item.vendor_price_list && item.vendor_price_list.length != 0"><span class="sold_by">{{item.vendor_price_list[0].vendor_name}} <span *ngIf="item.vendor_price_list.length > 1" class="more_"> Avl {{item.vendor_price_list.length - 1}}+ Stores</span> </span></p> -->
        <a class="m-0 product__name mouse webkit-text" [style.--line]="db.product_box.view == 'List View' && !scrollable ? '2' : '1'" [routerLink]="true?'/pr/'+item.route:null">{{item.item}}</a>
        <div  *ngIf="short_description" class="sub__names" ><p class="m-0 sub__name webkit-text" [style.--line]="2" *ngIf="item.short_description && db.product_shrotdescription" > {{item.short_description}}  </p></div>
       
        <!-- <div class="star-rating" *ngIf="item.rating != 0;else norating;"> <ion-icon class="star" src="/assets/icon/star-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon> </div>
        <ng-template #norating class="star-rating" ><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5"></ion-icon></ng-template> -->
        
        <!-- <div class="star-rating" *ngIf="item.rating != 0;else norating;"> <ion-icon class="star" src="/assets/icon/star-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5-item.rating"></ion-icon> </div>
        <ng-template #norating class="star-rating" ><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5"></ion-icon></ng-template> -->


        <!-- <div class="star-rating" *ngIf="item.rating"> <ion-icon class="star" name="star"></ion-icon>{{item.rating}} </div> -->
        <div  *ngIf="(item.gross_weight || item.weight) && db.product_weight" class="d__flex weight__specify">
          <div *ngIf="item.gross_weight">Gross Wt:  {{item.gross_weight >= 1000?item.gross_weight/1000:item.gross_weight}}  {{item.gross_weight >= 1000?'Kg':'gms'}} </div>
          <span *ngIf="item.gross_weight"> / </span>
          <div [class.Net]="item.gross_weight" *ngIf="item.weight">Net Wt: {{item.weight >= 1000?item.weight/1000:item.weight}} {{item.weight >= 1000 ?'Kg':'gms'}} </div>
        </div>
        <!-- *ngIf="item.vendor_price_list.length != 0"  variant_select.emit(item)-->

        <div class="variants_ mouse" *ngIf="item.has_variants && item.has_variants == 1;else no_variants" (click)="select_attribute(item)">
          <!-- <p class="" style="display: none;">variant</p>  -->
          <div *ngFor="let variant of item.vendor_price_list">
            <!-- <div *ngIf="variant.variants.length != 0"> -->
              <div *ngFor="let option of variant.variants">
                <div class="d__flex variants_d" *ngIf="option.is_pre_selected == 1">
                  <ion-label class="webkit-text" [style.--line]="1">{{option.variant_text}} </ion-label>
                  <ion-icon slot="end" name="chevron-down-outline"></ion-icon>
                </div>
              </div>
              <!-- div *ngFor="let option of variant.variants">
                <div class="d__flex variants_d" *ngIf="variant.default_variant.variant_text">
                  <ion-label class="webkit-text" [style.--line]="1">{{variant.default_variant.variant_text}} </ion-label>
                  <ion-icon slot="end" name="chevron-down-outline"></ion-icon>
                </div>
              </div> -->
            <!-- </div> -->
          </div>
        </div>
        <div class="price_c">
          <div class="prices" [class.list_price]="db.product_box.view == 'List View' && !scrollable" [routerLink]="true?'/pr/'+item.route:null">
            <p class="m-0 main-price" *ngIf="item.price != '0.00'" ><span class=""></span>{{item.price | currency:db.website_settings.currency}}</p>
            <p class="m-0 l-t old-price" *ngIf="item.old_price != '0.00'" >{{item.old_price | currency:db.website_settings.currency}}</p>
          </div>
        </div>

        <ng-template #no_variants>
          <!-- *ngIf="db.product_box.view != 'List View'" -->
          <div class="variants_ mouse" ></div>
        </ng-template>

        <div class="d__flex just-s">
          <!-- <div class="prices" [class.list_price]="db.product_box.view == 'List View' && !scrollable" [routerLink]="true?'/pr/'+item.route:null">
            <p class="m-0 main-price" *ngIf="item.price != '0.00'" ><span class=""></span>{{item.price | currency:db.website_settings.currency}}</p>
            <p class="m-0 l-t old-price" *ngIf="item.old_price != '0.00' && false" ><span class="">{{db.website_settings.currency}}</span>{{item.old_price | currency:db.website_settings.currency}}</p>
          </div> -->
          <p class="m-0 sold_by_d" *ngIf="item.vendor_price_list && item.vendor_price_list.length != 0"><span class="sold_by">Sold By {{item.vendor_price_list[0].vendor_name}} <span *ngIf="item.vendor_price_list.length > 1" class="more_"> Avl {{item.vendor_price_list.length - 1}}+ Stores</span> </span></p>
          <app-cart-button class="cart__btns" [change_button_color]="change_button_color" [product_info]="item" (addto_cart)="addto_cart.emit($event)" (removeto_cart)="removeto_cart.emit($event)"></app-cart-button>
        </div>
                  <!-- <div class="d__flex just-s buttons__container">
        
                    <app-cart-button class="cart__btns" [product_info]="item" (addto_cart)="addto_cart.emit($event)" (removeto_cart)="removeto_cart.emit($event)"></app-cart-button>
                    </div> -->

      </div>
    </li>
  </ul> 
  <div #right_arrow *ngIf="!db.ismobile && arrow && scrollable  && checkOverflow(scrollable_cnt)" (click)="scroll('next')" class="next-s"><ion-icon name="chevron-forward-outline"></ion-icon></div>
  
</div>