import { Component, OnInit, Input } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['../page-builder/page-builder.component.scss','./our-team.component.scss'],
})
export class OurTeamComponent implements OnInit {
  @Input() item;
  constructor(public db:DbService) { }

  ngOnInit() {
    // this.scrollTrigger('.animate');
  }
  // scrollTrigger(selector){
  //   let els
  //   els = document.querySelectorAll(selector)
  //   els = Array.from(els)
  //   console.log(els);
    
  //   els.forEach(el => {
  //       this.addObserver(el)
  //   })
  // }
  
  // addObserver(el){
  //   console.log(el);
    
  //   if(!('IntersectionObserver' in window)){
  //         console.log(el);      
  //           el.target.classList.add('active')
  //   }
  //   let observer = new IntersectionObserver((entries, observer) => {
  //       entries.forEach(entry => {
  //           if(entry.isIntersecting){
  //                 console.log("added");
  //                   entry.target.classList.add('active')
  //               observer.unobserve(entry.target)
  //           }
  //       })
  //   })
  //   observer.observe(el)
  // }
}
