import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filters-popup',
  templateUrl: './filters-popup.page.html',
  styleUrls: ['./filters-popup.page.scss'],
})
export class FiltersPopupPage implements OnInit {
  @Input() brands;
  constructor(public modalCtrl:ModalController) { }

  ngOnInit() {
  }

  // choosed(data){
  //     this.modalCtrl.dismiss();
  // }
}
