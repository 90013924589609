import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  ViewChild,
  Type,
  ElementRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import $ from 'jquery';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  ModalController,
  LoadingController
} from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { async } from '@angular/core/testing';
import { EditWebformchildPage } from 'src/app/pages/edit-webformchild/edit-webformchild.page';
import { WebformChildPage } from 'src/app/pages/webform-child/webform-child.page';

@Component({
  selector: 'app-wizard-forms',
  templateUrl: './wizard-forms.component.html',
  styleUrls: ['./wizard-forms.component.scss'],
})
export class WizardFormsComponent implements OnInit,AfterViewInit {
  submitted = false;
  captcha = false;
  signatureImg: any;
  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 700,
    'canvasHeight': 300
  };

  @Input() web_form;
  @Input() tabs_left: any;
  @Input() tabs_top: any;
  @Input() type: any
  @Input() isModel: any = false
  @Input() model_path: any;
  edit_data_details: any = {};
  @Input() forms_route;
  @Input() edit_form_values;
  @Input() edit_form;
  @Input() cancle_btn_hide;
  @Input() button_positions;
  @Output() goto_rating = new EventEmitter();
  @ViewChild('formDirective') private formDirective!: NgForm;
  @ViewChild('customInput', { static: false }) customInput!: ElementRef;
  @ViewChild('addresstext') addresstext!: ElementRef;

  @Output() scroll_to_top = new EventEmitter();


  signature_fieldname: any = [];
  signature_base64_url = [];

  categoryfile: any;
  categoryimagedata: any;

  field_name: any = [];
  base64_url: any = [];
  item;
  file_name: any = [];

  //End 
  url: any
  info: any = [];
  form_data: any = FormGroup;
  form_ctrl_data: any = {};
  // section_break_data;
  json_data;
  section_datas;
  wizard_json: any = [];

  doctype;
  form_tile;
  link_flelds_name: any = [];
  image_field_check = "no uploads";
  field_data;
  current_path = "events";
  img_file_name: any = [];
  current_page;
  page_props: any = {};
  retrict_duplicate = false;
  // form_values;
  navigation_count = 0;
  wizard_form;
  store_name_as_business = true;
  final_child_category = {};
  sub_category = true;
  tab_View_Ads_Navigation = [{ text: 'General', route: '/classifieds/webform/insert' }, { text: 'Jobs', route: '/jobs/webform/insert' }, { text: 'Autos', route: '/autos/webform/insert' }, { text: 'Realestate', route: '/realestates/webform/insert' }, { text: 'Roommate', route: '/roommates/webform/insert' }]
  send_data = {

  }

  selectedItems = {};
  dropdownSettings = {};
  profile_menu: any = []
  destroy;


  data: any = {};
  store_old_data: any = {};

  constructor(public location: Location, private http: HttpClient, public ref: ChangeDetectorRef, public db: DbService, private formBuilder: FormBuilder, public alertController: AlertController, public modalCtrl: ModalController, public router: Router, public route: ActivatedRoute, private loadingCtrl: LoadingController) {

  }

  ngOnInit() {
    // console.log(this.web_form)
    if (this.forms_route) {
      this.get_form(this.forms_route);
    } else {
      // console.log(this.web_form)
      this.get_form_values();
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true
    };

    this.destroy = this.db.map_fields_get.subscribe(res => {
      if (res && res == 'get_values') {
        this.get_map_values();
      }
    })

  }


  ngOnDestroy() {
    this.destroy.unsubscribe();
  }

  get_form(data) {
    this.db.web_form_dynamic(data).subscribe(res => {
      // console.log(res)
      if (res && res.data) {
        this.web_form = res.data;
        this.get_form_values();
      }
    })
  }

  get_form_values() {
    this.retrict_duplicate = false;
    this.isModel ? this.url = this.model_path : this.url = location.pathname
    this.isModel ? this.current_path = this.type : this.current_path = this.route.snapshot['_urlSegment'].segments[0].path

    this.web_form.web_form_fields.map(res => {
      if (res.fieldtype == 'Page Break') {
        res.array_list = [];
      }
    })

    this.field_data = this.web_form.web_form_fields;
    this.doctype = this.web_form.doc_type;
    this.form_tile = this.web_form.name;
    this.json_data = this.field_data;
    this.wizard_form = this.web_form.wizard_form;

    this.db.doc_type = this.doctype;
    this.db.ad_name = this.titleCase(this.form_tile);
    // this.db.ad_name = this.titleCase(this.doctype);
    this.get__initial_forms(this.navigation_count)
  }


  clear_image_data(type, value, field_name) {

    if (type == "edit") {
      this.edit_data_details[field_name] = undefined
      this.form_ctrl_data[field_name].setValue("")
      this.base64_url.splice(this.field_name.indexOf(field_name), 1);
      this.field_name.splice(this.field_name.indexOf(field_name), 1);
      this.file_name.splice(this.file_name.indexOf(field_name));
    }

    else if (type == "fresh") {
      this.form_ctrl_data[field_name].setValue("")
      this.base64_url.splice(this.field_name.indexOf(field_name), 1);
      this.field_name.splice(this.field_name.indexOf(field_name), 1)
      this.file_name.splice(this.file_name.indexOf(field_name));
    }

  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }



  // Title case the title 
  titleCase(str) {
    // console.log(str)
    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }

  menu_name(eve) {
    let obj = eve;
    this.submitted = true;
    this.scroll_to_top.emit('');
    console.log(this.navigation_count)
    // this.next_form();

  if(this.navigation_count != obj.index){
    if (this.form_data.status == "VALID" || (this.navigation_count > obj.index)) {
      // this.save_details1('image_none');
      // this.save_details('image_none');
      if((this.navigation_count > obj.index) || this.free_navigation){
        this.wizard_json[this.navigation_count].form_ctrl_data = this.form_data.value;
        this.store_old_datas(false);
        this.submitted = false;
        this.form_ctrl_data = {};
        this.check_navigation(obj.index + 1);
        this.db.tab_buttons(this.profile_menu, obj['name'], 'name');
        this.navigation_count = obj.index;
        this.get_forms(this.wizard_json, obj.index);
        this.store_header();
        this.filter_section_break();
        this.assign_final_data();
        this.form_data = this.formBuilder.group(
          this.form_ctrl_data
        );
      }else{
        this.save_details1('image_none');
      }

    }
  }


    // if (this.form_data.status == "VALID" && (this.navigation_count == (obj.index - 1)) || (this.navigation_count > obj.index) || this.free_navigation) {
    //   // this.save_details1('image_none');
    //   // this.save_details('image_none');
    //   this.wizard_json[this.navigation_count].form_ctrl_data = this.form_data.value;
    //   this.store_old_datas(false);
    //   this.submitted = false;
    //   this.form_ctrl_data = {};
    //   this.check_navigation(obj.index + 1);
    //   this.db.tab_buttons(this.profile_menu, obj['name'], 'name');
    //   this.navigation_count = obj.index;
    //   this.get_forms(this.wizard_json, obj.index);
    //   this.store_header();
    //   this.filter_section_break();
    //   this.assign_final_data();
    //   this.form_data = this.formBuilder.group(
    //     this.form_ctrl_data
    //   );
    // }

  }



  store_old_datas(type) {
    if (type) {
      this.store_old_data = { ...this.store_old_data, ...this.form_data.value };
    }
  }

  free_navigation = false;

  check_navigation(value) {
    if (!this.free_navigation) {
      this.free_navigation = (this.wizard_json.length == value) ? true : false;
    }
  }


  next() {
    this.submitted = true;
    // this.next_form();
    this.data = this.form_data.value;
    this.assign_image(this.data);

    // this.db.scroll_top.next('scroll');
    if (this.form_data.status == "VALID") {
      // this.save_details('image_none');
      if (this.navigation_count == 0) {
        // console.log(this.data)
        if (this.data.alternate_phone && (this.data.alternate_phone == this.data.phone)){
          this.db.alert('Phone number and alternate phone number should not be same')
        } else {
          this.next_form();
        }
      } else {
        this.next_form();
      }
      
    }

    // this.get_child_parent();

  }


  next_form() {

    this.save_details1('image_none');
    // this.wizard_json[this.navigation_count].form_ctrl_data = this.form_data.value;
    // this.store_old_datas(false);
    // this.form_ctrl_data = {};
    // this.submitted = false;
    // this.navigation_count = this.navigation_count + 1;
    // this.check_navigation(this.navigation_count);
    // let value = this.profile_menu.find(res => res.index == this.navigation_count)
    // this.db.tab_buttons(this.profile_menu, value.name, 'name');
    // // this.db.tab_buttons(this.profile_menu, value.name, 'name');
    
    // // this.db.progress_bar.next(this.navigation_count +  1) // progress_bar
    // this.get_forms(this.wizard_json, this.navigation_count)
    // this.store_header();
    // this.filter_section_break();
    // // this.check_assign_sec_break();
    // this.assign_final_data();
    // // this.form_data.reset();
    // // this.formGroups.reset();
    // this.form_data = this.formBuilder.group(
    //   this.form_ctrl_data
    // );
  }


  back() {
    // this.db.scroll_top.next('scroll');
    // this.db.profile_completion = this.db.profile_completion - 25;
    // this.db.profile_bar.next('load')
    this.scroll_to_top.emit('');
    delete this.form_data.controls;
    this.form_ctrl_data = {};
    this.submitted = false;
    // this.db.progress_bar.next(this.navigation_count) // progress_bar
    this.navigation_count = this.navigation_count - 1;
    let value = this.profile_menu.find(res => res.index == this.navigation_count)
    this.db.tab_buttons(this.profile_menu, value.name, 'name');
    this.get_forms(this.wizard_json, this.navigation_count);
    this.store_header();
    this.filter_section_break();
    this.assign_final_data();
    // this.form_data.reset();
    this.form_data = this.formBuilder.group(
      this.form_ctrl_data
    );

    // this.get_child_parent();
  }

  get__initial_forms(index) {
    this.getIp();
    this.get_device_type();

    index == 0 ? this.store_info(index) : this.get_forms(this.wizard_json, index)
    this.store_header();
    this.filter_section_break();
    this.assign_final_data();

    this.form_data = this.formBuilder.group(
      this.form_ctrl_data
    );

    // this.get_child_parent();

  }


  store_info(index) {

    this.wizard_json = [];
    let count = -1;
    // console.log(this.json_data.length)
    this.json_data.map((res, i) => {

      if (res.fieldtype == "Page Break") {
        this.wizard_json.push(res);
        count++;

        let obj = this.profile_menu.length == 0 ? { 'name': res.label, 'index': this.profile_menu.length, 'isActive': true } : { 'name': res.label, 'index': this.profile_menu.length }
        this.profile_menu.push(obj);
        // console.log(this.profile_menu);
        count == 1 ? this.get_forms(this.wizard_json, index) : '';
        this.wizard_json[count].form_ctrl_data = {};
      } else {
        this.wizard_json[count]['array_list'].push(res);
      }

      if (this.wizard_form == 0 && (this.json_data.length == (i + 1))) {
        this.wizard_json.push(res);
        this.get_forms(this.wizard_json, index)
      }
      // getting link field options  || res.fieldtype == "Multi_add"
      if (res.fieldtype == "Link" || res.fieldtype == "Link_multi_select") {
        this.link_flelds_name.push(res.options);
      } else if (res.fieldtype == "Select") {
        res.options = res.options.includes('\n') ? res.options.split('\n') : []
      }
    })

    // console.log(this.wizard_json)
  }

  ngAfterViewInit() {
    // console.log('loaderrrrrrrrrrrrrr');
    setTimeout(() => {

      this.info.map((res,i)=>{
        if(res.fieldname.includes('latitude')){
          let latitude = (this.edit_form_values && this.edit_form_values[res.fieldname]) ? this.edit_form_values[res.fieldname] : '';
          this.db.location_info['latitude'] = latitude;
        }else if(res.fieldname.includes('longitude')){
          let longitude = (this.edit_form_values && this.edit_form_values[res.fieldname]) ? this.edit_form_values[res.fieldname] : '';
          this.db.location_info['longitude'] = longitude;
        }
        if(this.info.length == (i + 1)){
          this.db.load_map.next('get');
        }
      })

      
    },3000)
    

    if (this.addresstext) {
      this.db.getPlaceAutocomplete(this.addresstext.nativeElement, 'get_map_fields')
    }
    this.remove_alphabets();

  }

  // ngAfterViewInit() {
   
  // }

  selected_values:any = [];


  get_forms(datas, index) {

    this.info = [];
    this.info = datas[index].array_list;
    let form_ctrl_data = datas[index].form_ctrl_data;
    // console.log(form_ctrl_data)
    // findLastIndex
    let table_check = this.info.lastIndexOf(res => (res.fieldtype == 'Check' && res.fieldname == 'allow_multiple_address' && (res.default && Number(res.default) == 0)))

    // console.log(table_check);

    if (table_check > 0) {
      // this.get_table_fields(table_check - 1);
      let delete_table = this.info.lastIndexOf(res => (res.fieldtype == 'Table' && (res.options && res.options == 'Customer Address')))
      this.info.splice(delete_table, 1);
    }

    this.info = this.info.filter(res => { return !res.hidden });

    let map_array = this.info.filter(res => { return res.fieldname == 'address_map' });

    // if(map_array && map_array.length != 0){
    //   map_array.map(res=>{ res.fieldtype = 'Geo Location' })
    // }


    this.info.map((res,i)=>{
      if(res.fieldname.includes('latitude')){
        let latitude = (this.edit_form_values && this.edit_form_values[res.fieldname]) ? this.edit_form_values[res.fieldname] : '';
        this.db.location_info['latitude'] = latitude;
      }else if(res.fieldname.includes('longitude')){
        let longitude = (this.edit_form_values && this.edit_form_values[res.fieldname]) ? this.edit_form_values[res.fieldname] : '';
        this.db.location_info['longitude'] = longitude;
      }
      if(this.info.length == (i + 1)){
        this.db.load_map.next('get');
      }
    })

    this.selected_values = [];
    // if(this.wizard_form == 1){

    if(this.edit_form && this.edit_form == 1){
      this.info.map((res,i) => {
        if (res.label && res.fieldtype != "Section Break" && res.fieldtype != "Column Break" && res.fieldtype != "Barcode" && res.fieldtype != "Button" && res.fieldtype != "Color" && res.fieldtype != "Duration" && res.fieldtype != "Dynamic Link" && res.fieldtype != "Fold" && res.fieldtype != "Geolocation" && res.fieldtype != "Heading" && res.fieldtype != "Image" && res.fieldtype != "Markdown Editor" && res.fieldtype != "Percent" && res.fieldtype != "Read Only" && res.fieldtype != "Rating" && res.fieldtype != "Table MultiSelect") {
  
            let value = (this.edit_form_values && this.edit_form_values[res.fieldname]) ? this.edit_form_values[res.fieldname] : '';
            
            res['value'] = value;
            res.placeholder = res.label ?   ((res.fieldtype == 'Select' || res.fieldtype == 'Link') ? ('Select ' + res.label) : ('Enter The ' + res.label)) : ''
            
            if (res.reqd == 1 && res.options != 'Email' && res.options != 'Phone') {
              if(res.max_length && res.max_length > 0){
                this.form_ctrl_data[res.fieldname] = new FormControl(value, [Validators.required,Validators.pattern("[0-9]{"+ res.max_length +"}")])
              }else{
                this.form_ctrl_data[res.fieldname] = new FormControl(value, Validators.required)
              }
            } else if (res.reqd == 1 && res.options == 'Email') {
              // this.form_ctrl_data[res.fieldname] = new FormControl(value, [Validators.required, Validators.email])
              this.form_ctrl_data[res.fieldname] = new FormControl(value, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
              // console.log(res)
            } else if (res.options == 'Email') {
              this.form_ctrl_data[res.fieldname] = new FormControl(value, Validators.email)
              // console.log(res)
            } else if (res.fieldtype == 'Check') {
              this.form_ctrl_data[res.fieldname] = new FormControl((res.default == '1' || res.default == '1') ? true : false)
            }else if (res.fieldtype == 'Phone' && res.reqd) {
              // res.fieldtype = 'Int';
              this.form_ctrl_data[res.fieldname] = new FormControl(value, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
            }else if (res.fieldtype == 'Phone' && !res.reqd) {
              // res.fieldtype = 'Int';
              this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
              }else if (res.fieldtype == 'Data' && res.options && res.options.toLowerCase() == 'map') {
              res.fieldtype = 'Geo Location'
              this.form_ctrl_data[res.fieldname] = new FormControl(value)
            }else if (res.fieldtype == 'Select' && res.fieldtype == 'Link') {
              this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false })
            } else {
              this.form_ctrl_data[res.fieldname] = new FormControl(value)
            }
        }
      })
      if(this.wizard_json && this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && this.wizard_json[1] ){
        this.form_ctrl_data['business_phone'] = new FormControl(this.wizard_json[0].form_ctrl_data.phone ? this.wizard_json[0].form_ctrl_data.phone : '');
        this.form_ctrl_data['business_address'] = new FormControl(this.wizard_json[0].form_ctrl_data.address ? this.wizard_json[0].form_ctrl_data.address : '') 
        this.form_ctrl_data['business_city'] = new FormControl(this.wizard_json[0].form_ctrl_data.city ? this.wizard_json[0].form_ctrl_data.city : '');
        this.form_ctrl_data['business_landmark'] = new FormControl(this.wizard_json[0].form_ctrl_data.landmark ? this.wizard_json[0].form_ctrl_data.landmark : '');
        this.form_ctrl_data['business_state'] = new FormControl(this.wizard_json[0].form_ctrl_data.state ? this.wizard_json[0].form_ctrl_data.state : '');
        this.form_ctrl_data['business_zip'] = new FormControl(this.wizard_json[0].form_ctrl_data.zipcode ? this.wizard_json[0].form_ctrl_data.zipcode : '');
        // this.form_ctrl_data['business_phone'] = (this.wizard_json[0] && this.wizard_json[0].form_ctrl_data &&  this.wizard_json[0].form_ctrl_data.phone ? this.wizard_json[0].form_ctrl_data.phone : '');
        // this.form_ctrl_data['business_address'] = (this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && this.wizard_json[0].form_ctrl_data.address ? this.wizard_json[0].form_ctrl_data.address : '');
        // this.form_ctrl_data['business_city'] = (this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && this.wizard_json[0].form_ctrl_data.city ? this.wizard_json[0].form_ctrl_data.city : '');
        // this.form_ctrl_data['business_landmark'] = (this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && this.wizard_json[0].form_ctrl_data.landmark ? this.wizard_json[0].form_ctrl_data.landmark : '');
        // this.form_ctrl_data['business_state'] = (this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && this.wizard_json[0].form_ctrl_data.state ? this.wizard_json[0].form_ctrl_data.state : '');
        // this.form_ctrl_data['business_zip'] = (this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && this.wizard_json[0].form_ctrl_data.zipcode ? this.wizard_json[0].form_ctrl_data.zipcode : '');
      }
    }else{
    this.info.map((res, i) => {
      if (res.label && res.fieldtype != "Section Break" && res.fieldtype != "Column Break" && res.fieldtype != "Barcode" && res.fieldtype != "Button" && res.fieldtype != "Color" && res.fieldtype != "Duration" && res.fieldtype != "Dynamic Link" && res.fieldtype != "Fold" && res.fieldtype != "Geolocation" && res.fieldtype != "Heading" && res.fieldtype != "Image" && res.fieldtype != "Markdown Editor" && res.fieldtype != "Percent" && res.fieldtype != "Read Only" && res.fieldtype != "Rating" && res.fieldtype != "Table MultiSelect") {

        let value = (form_ctrl_data[res.fieldname] && form_ctrl_data[res.fieldname] != "") ? form_ctrl_data[res.fieldname] : '';
        res['value'] = value;
        res.placeholder = res.label ?   ((res.fieldtype == 'Select' || res.fieldtype == 'Link') ? ('Select ' + res.label) : ('Enter The ' + res.label)) : ''

        if (res.reqd == 1 && res.options != 'Email' && res.options != 'Phone') {
          if (res.max_length && res.max_length > 0) {
            this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, [Validators.required, Validators.pattern("[0-9]{" + res.max_length + "}")])
          } else {
            this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, Validators.required)
          }
        } else if (res.reqd == 1 && res.options == 'Email') {
          this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
          // this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, [Validators.required, Validators.email])
          // console.log(res)
        } else if (res.options == 'Email') {
          this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, Validators.email)
          // console.log(res)
        } else if (res.fieldtype == 'Check') {
          this.form_ctrl_data[res.fieldname] = new FormControl((res.default == '1' || res.default == '1') ? true : false)
        } else if (res.fieldtype == 'Phone') {
          // res.fieldtype = 'Int';
          this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false }, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
        } else if (res.fieldtype == 'Data' && res.options && res.options.toLowerCase() == 'map') {
          res.fieldtype = 'Geo Location'
          this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false },)
        } else {
          this.form_ctrl_data[res.fieldname] = new FormControl({ value: value, disabled: res.read_only == 1 ? true : false })
        }
      }

      // console.log(this.form_ctrl_data,this.info,'fsd');

      // if((res.fieldtype == 'Select' || res.fieldtype == 'Link') && res.value){
      //   console.log(res.fieldname);
      //   // this.check_ng_select(res.fieldname);
      //   this.selected_values.push(res.fieldname);
      //  }

      //  if(this.info.length == (i + 1) && this.selected_values.length != 0){
      //    this.get_load_ng_select();
      //  }

    })
  }

    this.remove_alphabets();
    //  }
  }


  async get_load_ng_select(){
    let loader = await this.loadingCtrl.create({ message: '' });
    await loader.present();
     setTimeout(() => {
      this.selected_values.map((r,j)=>{
        this.check_ng_select(r);
        this.selected_values.length == (j + 1) ? loader.dismiss() : null
      })
     }, 700);
  }


  get_table_fields(index) {

    this.db.get_doc_data('Customer Address').subscribe(res => {

      let data = res.docs[0].fields;

      let count = 0;
      data.map(res => {

        if (res.fieldtype != "Section Break" && res.fieldtype != "Column Break" && res.fieldtype != "Barcode" && res.fieldtype != "Button" && res.fieldtype != "Color" && res.fieldtype != "Duration" && res.fieldtype != "Dynamic Link" && res.fieldtype != "Fold" && res.fieldtype != "Geolocation" && res.fieldtype != "Heading" && res.fieldtype != "Image" && res.fieldtype != "Markdown Editor" && res.fieldtype != "Percent" && res.fieldtype != "Read Only" && res.fieldtype != "Rating" && res.fieldtype != "Table MultiSelect") {
          this.info.splice((index + 1 + count), 0, res);
          count++
        }
      })
    })

  }

  call_uppy() {
    // console.log('nhiii')
    // this.db.uppy_upload.next('load');
    return true;
  }

  ref_doc: any = [];
  all_link_opts = {};
  salary_parent;

  current_gen_links(link_field_array) {
    // console.log("____________________________________________Modal Calling")
    link_field_array.map(refdoc => {
      if (refdoc == "Classified Category" || refdoc == "Job Category" || refdoc == "Job Role" || refdoc == "Expected Salary Type") {
        this.get_parent_options(refdoc)
      } else if (refdoc == "sugesstion1" || refdoc == "Salary" || refdoc == "working_time" || refdoc == 'year_amount' || refdoc == 'contract_type' || refdoc == 'Work_from_home_options' || refdoc == 'start_date' || refdoc == 'year' || refdoc == 'end_date' || refdoc == 'end_date_year' || refdoc == 'Gradution_Year' || refdoc == 'end_date_year' || refdoc == 'end_date_year') {
        this.get_parent_options_value(refdoc);
      }

      else {
        this.ref_doc.push(refdoc);
        this.db.ref_doc_type = refdoc;
        this.db.get_link_field_options().subscribe(res => {
          let res_data = res.data
          let link_opts: any = [];

          res_data.map(res => {
            link_opts.push(res.name)
          })
          this.all_link_opts[refdoc] = link_opts;
          if(refdoc == 'Country'){
            this.form_ctrl_data['country'] ? this.form_ctrl_data['country'].setValue('India'):'';
            this.form_ctrl_data['business_country'] ? this.form_ctrl_data['business_country'].setValue('India'):'';
          }
        })
      }
    })
  }
  // End


  get_parent_options_value(refdoc) {
    let link_opts: any = [];
    if (refdoc == "Salary") {
      link_opts = ["Hourly", 'Annual', 'Daily'];
    } else if (refdoc == "year_amount") {
      link_opts = ["35.000 to 37.49"];
    } else if (refdoc == "working_time") {
      link_opts = ["Day Time", "Night Time"];
    } else if (refdoc == "Work_from_home_options") {
      link_opts = ["Yes", "No"];
    } else if (refdoc == "contract_type") {
      link_opts = ["Full Time", "Part Time", "Remote", "Contract"];
    } else if (refdoc == "start_date" || refdoc == 'end_date') {
      link_opts = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    } else if (refdoc == "year" || refdoc == 'end_date_year' || refdoc == 'Gradution_Year') {
      var max = new Date().getFullYear()
      var min = max - 9
      var years: any = [];
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      link_opts = years;
    } else if (refdoc == "job_category") {
      link_opts = ["Health and LiveStyle"];
    } else if (refdoc == "Job_sub_category") {
      link_opts = ["Information Management"];
    } else if (refdoc == "Degree") {
      link_opts = ["PHD Doctorate", "Bachelors", "Masters"];
    } else if (refdoc == "sugesstion1") {
      link_opts = ["Ui/Ux Designer", "Product Designer"];
    } else if (refdoc == "sugesstion2") {
      link_opts = ["Creative & Designing"];
    } else if (refdoc == "Skills") {
      link_opts = ["Creative & Designing"];
    }

    this.all_link_opts[refdoc] = link_opts;
  }


  k = 1;
  child_category = {};

  get_parent_options(refdoc) {
    if (this.k == 1) {
      // console.log(refdoc,this.ref_doc);
      this.ref_doc.push(refdoc);
      this.db.ref_doc_type = refdoc;
      this.db.get_link_field_options_parent(refdoc).subscribe(res => {
        let res_data = res.data
        let link_opts: any = [];

        if (refdoc == "Classified Category") {
          res_data.map(res => {
            link_opts.push({ "options": res.category_name, "ids": res.name })
          })
        } else if (refdoc == "Job Category") {
          res_data.map(res => {
            link_opts.push({ "options": res.category_name, "ids": res.name })
          })
        } else if (refdoc == "Job Role") {
          res_data.map(res => {
            link_opts.push(res.name)
          })
        } else if (refdoc == "Expected Salary Type") {
          res_data.map(res => {
            link_opts.push({ "options": res.name })
          })
        }
        this.all_link_opts[refdoc] = link_opts;

        if (this.wizard_form != 1 && refdoc == "Job Role") {
          this.info.map((res, index) => {
            if (res.options && res.options == refdoc) {
              if (this.all_link_opts[refdoc].length != 0 && (res.multiselect_option && res.multiselect_option.length != 0)) {

              }
            }
          })
        }

      })
      this.k++
    }

    else if (this.k == 2) {
      this.k = 1;
      this.ref_doc.push(refdoc);
      this.db.ref_doc_type = refdoc;
      this.db.get_link_field_options_child(refdoc).subscribe(res => {
        let res_data = res.data
        let link_opts: any = [];

        if (refdoc != "Expected Salary Type") {
          res_data.map(each => {
            if (each.parent_category || each.parent_role) {
              link_opts.push({ "option": each.category_name || each.desired_role, "ids": each.name, "parent_category": each.parent_category || each.parent_role });
            }
          })
        } else {
          res_data.options.map(each => {
            if (each.salary) {
              link_opts.push({ "options": each.salary });
            }
          })
          this.final_child_category[refdoc] = link_opts;
        }

        this.child_category[refdoc] = link_opts;
        // console.log(this.child_category);
        // console.log(this.all_link_opts);

      })
    }
  }



  parent_id;

  // load_child_options(ref_doc) {
  //   // console.log("ref doc", ref_doc)
  //   // console.log("parent id",this.parent_id)
  //   this.sub_category = true;
  //   this.final_child_category = {};
  //   let values = []
  //   if (this.child_category && this.child_category[ref_doc]) {
  //     this.child_category[ref_doc].map(res => {
  //       if (res.parent_category == this.parent_id)
  //         values.push({ "options": res.option, "ids": res.ids })
  //     })
  //   }
  //   this.final_child_category[ref_doc] = values;
  //   // console.log(this.final_child_category[ref_doc])
  //   if (ref_doc == "Classified Category") {
  //     if (this.parent_id) {
  //       $("#subcategory").show();
  //     }
  //     else {
  //       $("#subcategory").hide();
  //     }
  //   }
  //   else if (ref_doc == "Job Category") {
  //     this.form_data.controls['sub_category'].reset()
  //     if (this.parent_id) {
  //       $("#sub_category").show();
  //     }
  //     else {
  //       $("#sub_category").hide();
  //     }
  //   }
  //   this.reset_hide_subcategory(ref_doc, values);
  // }

  hide_sub_category_fields_html(edit_values) {
    // console.log("this.form_ctrl_data",this.form_ctrl_data)
    // console.log("this.edit_data_details",this.edit_data_details)
    // console.log("this.final_child_category", this.final_child_category)
    // console.log("this.child_category",this.child_category)
    // console.log("Classified Category",this.child_category['Classified Category'])
    // console.log("Job Category",this.child_category['Job Category'])

    $(() => {

      if (edit_values && edit_values["sub_category"]) {
        // console.log('this.edit_data_details["sub_category"]',edit_values["sub_category"])
        $("#sub_category").show();
        this.sub_category = true;
      }

      else if (edit_values && edit_values["subcategory"]) {
        // console.log('this.edit_data_details["subcategory"]',edit_values["subcategory"])
        $("#subcategory").show();
        this.sub_category = true;
      }

      else {
        // console.log("main page loaded")
        $("#sub_category").hide();
        $("#subcategory").hide();
        this.sub_category = false;
      }

      $("textarea.native-textarea.sc-ion-textarea-md").parent().css("height", "100%")
      $("textarea.native-textarea.sc-ion-textarea-md").css("height", "100%")

    });
  }

  reset_hide_subcategory(ref_doc, values) {
    if (ref_doc == "Classified Category") {
      $("#subcategorychild").val("");
    }
    else if (ref_doc == "Job Category") {
      $("#sub_categorychild").val("");
    }
    if (values.length == 0) {
      $("#sub_category").hide();
      $("#subcategory").hide();
    }
  }

  // Filter the section for section break and if a form having without section breake last if conditon will work
  section_break_data = {};
  each_sec_data: any = [];
  section_break_name: any = [];
  test_section_break_data: any = [];
  test_section_break_name: any = [];
  store_field_type: any = [];
  // if api have column break or not column break and not have section breake the value will be sstore here
  no_sec_col: any = [];
  //end


  ng_select(eve,each){

    if(each.options == 'State'){

      let city = this.info.find(res=>{ return (res.options && (res.options == 'City' || res.option == 'business_city'))})

      if(city){
        this.form_ctrl_data[city.fieldname].setValue('')
      }

      this.db.c_city_state = 'City' + this.db.page_length + '&filters=[["state","=","' + eve + '"]]';
      this.db.get_link_field_city_options(eve).subscribe(res => {
        let res_data = res.data
        let link_opts: any = [];
  
        res_data.map(res => {
          link_opts.push(res.name)
        })

        this.all_link_opts['City'] = link_opts;
  
      })
    }else   if(each.options == 'Country'){


      let state = this.info.find(res=>{ return (res.options && res.options == 'State')})
      
      let city = this.info.find(res=>{ return (res.options && res.options == 'City')})
     
      if(state){
        this.form_ctrl_data[state.fieldname].setValue('')
      }

      if(city){
        this.form_ctrl_data[city.fieldname].setValue('');
        this.all_link_opts['City'] = [];
      }
      
      this.db.c_city_state = 'State' + this.db.page_length + '&filters=[["country","=","' + eve + '"]]';

      this.db.get_link_field_city_options(eve).subscribe(res => {
        let res_data = res.data
        let link_opts: any = [];
  
        res_data.map(res => {
          link_opts.push(res.name)
        })

        this.all_link_opts['State'] = link_opts;
  
      })
    }

  }


  // Setting margin value for each flex div
  // The css and the below value must be same for apply e:g flex:0 0 calac(%-flex_margin)
  flex_margin: any = "30px";
  // flex_margin:any = " 0 15px";
  // end var

  // Store field name && check it has lable or not
  // store_field_name;
  // count = 0;
  filter_section_break() {

    this.section_break_name = [];
    this.section_break_data = {};
    this.each_sec_data = [];
    this.section_break_name = [];
    this.test_section_break_data = [];
    this.test_section_break_name = [];
    this.store_field_type = [];
    this.no_sec_col = [];

    this.current_gen_links(this.link_flelds_name);
    this.info.map((res, index) => {

      this.store_field_type.push(res.fieldtype);
      if (res.fieldtype == "Section Break") {

        res.fieldname = 'Section' + index;
        // console.log(res.fieldname);
        // console.log(index);
        // console.log("i", this.i);
        // console.log(this.info.length);
        let k = index;
        let count = 0;
        // console.log(k)
        while (k < this.info.length) {
          // console.log(k)
          if (k != index) {
            // console.log(res.fieldname);
            // console.log("each fieldname", this.info[k].fieldname);
            if (this.info[k].fieldtype != "Section Break" && this.info[k].fieldtype != "Column Break") {
              // console.log("each fieldname", this.info[k].fieldname);
              // console.log(k)
              this.each_sec_data.push(this.info[k]);
            } else if (this.info[k].fieldtype == "Section Break") {
              break
            }
          }

          if (this.info[k].fieldtype == "Column Break") {
            count++
          }
          k++
        }

        this.section_break_data[res.fieldname] = this.each_sec_data;
        this.section_break_data[res.fieldname].count = count + 1;
        let p__flex = ((100 / (count + 1)) + '%');
        let flex_out = "0 0 calc(" + p__flex + " " + "-" + " " + this.flex_margin + ")";
        // console.log("percent", p__flex)
        this.section_break_data[res.fieldname].flex = flex_out.toString();
        // this.section_break_data[res.fieldname].label = res.label
        // if (res.label || !res.label) {
        //   if (!res.label) {
        //     this.section_break_data[res.fieldname].label = undefined;
        //   }
        //   else {
        //     this.section_break_data[res.fieldname].label = res.label
        //   }
        // }

        if (res.label) {
          this.section_break_data[res.fieldname].label = res.label;
        } else {
          this.section_break_data[res.fieldname].label = '';
        }

        if (res.heading) {
          this.section_break_data[res.fieldname].heading = res.heading;
        }

        if (res.btn) {
          this.section_break_data[res.fieldname].btn = res.btn;
        }

        this.test_section_break_data.push(res.fieldname);
        this.test_section_break_name.push(res.fieldname);
        this.each_sec_data = [];
        // console.log("Form",this.form_data);
        // if(this.wizard_json && this.wizard_json[1] && this.wizard_json[this.navigation_count].form_ctrl_data){
        //   this.wizard_json[1].form_ctrl_data.center = localStorage['CenterName'] ? localStorage['CenterName'] : ''
        //   // if(this.field_data)
        //   this.form_data.controls['center'].setValue(localStorage['CenterName'] ? localStorage['CenterName'] : '')
        // }
        // this.section_break_data[this.store_field_name] = this.each_sec_data;
        // this.section_break_data[this.store_field_name].count = this.count + 1;
        // let p__flex = ((100 / (this.count + 1)) + '%');
        // let flex_out = "0 0 calc(" + p__flex + " " + "-" + " " + this.flex_margin + ")";
        // // console.log("percent", p__flex)
        // this.section_break_data[this.store_field_name].flex = flex_out.toString();
        // if (res.label) {
        //   this.section_break_data[this.store_field_name].label = res.label
        // }
        // if (!this.section_break_name.includes(this.store_field_name)) {
        //   this.section_break_name.push(this.store_field_name);
        // }
        // console.log(this.k)
        // console.log("All section data", this.section_break_data)
        // console.log(this.section_break_name);
      }
    });

    if (!this.store_field_type.includes("Section Break")) {
      this.info.map(res => {
        if (res.fieldtype != "Column Break") {
          this.no_sec_col.push(res);
        }
      })
    }


    // console.log(this.section_break_data);

  }
  //End


  // Check and assign a section brake fields into another section break if section comes without label
  label_name;
  section_break_data_2: any = undefined;
  count = 0;
  check_assign_sec_break() {
    this.section_break_data_2 = undefined;
    this.count = 0;
    // console.log(this.test_section_break_name);
    // console.log(this.section_break_data);
    return new Promise<void>((resolve, reject) => {
      this.test_section_break_name.map((res, index) => {
        if (this.section_break_data[res] && this.section_break_data[res].label) {
          this.label_name = res;
        } else if (this.section_break_data[res] && !this.section_break_data[res].label) {
          // console.log("error", res)
          // console.log("each field", this.section_break_data[res])
          // console.log("each label_name", this.section_break_data[this.label_name])
          this.section_break_data[res].map(name => {
            // console.log("__________________________________________________________________sec data",name)
            this.section_break_data[this.label_name].push(name);
          })
          // // console.log("delete sections", this.section_break_data[res]);
          delete this.section_break_data[res];
          let index_value = this.test_section_break_data.indexOf(res)
          this.test_section_break_data.splice(index_value, 1);
        }
      })
      resolve();
    })
    // this.section_break_name = this.test_section_break_data
    // this.section_break_data_2 = this.section_break_data;
    // console.log("All section data-2", this.section_break_data)
    //   console.log('sec name', this.section_break_name)
  }


  // Assign final data ref
  async assign_final_data() {
    // await this.check_assign_sec_break();
    this.section_break_name = this.test_section_break_data
    this.section_break_data_2 = this.section_break_data;
    // console.log(this.section_break_name);
    // console.log("section name", this.section_break_name)
    // console.log("All section data-2", this.section_break_data)
  }

  // user IP address
  ip_address: any;
  posted_from;
  browser_name;
  getIp() {
    this.http.get<{ ip: string }>('https://jsonip.com').subscribe(data => {
      this.ip_address = data.ip
    })
  }

  get_device_type() {
    // console.log("_____________Device",this.db.check_device_type())
    this.posted_from = this.db.check_device_type();
    // this.posted_from='Website'
    if (this.posted_from == 'Mobile Web' || this.posted_from == 'Website') {
      let browser_name = this.getBrowser().split('/')
      if (browser_name && browser_name[0] != "unknown") {
        this.browser_name = browser_name[0]
      }
    }
  }



  async discard_changes1(section_value, related) {
    const alert = await this.alertController.create({
      header: 'Discard Changes',
      message: 'Are you sure do you want to discard a changes..?',
      buttons: [
        {
          text: 'No',
          handler: () => { this.alertController.dismiss() }
        },
        {
          text: 'Yes',
          handler: () => {
            this.section_break_name.map(section => {
              section_value[section].map((res, i) => {
                // console.log(res)
                if (res.related == related) {
                  res.is_show = !res.is_show;
                }
              })
            })
          }
        }
      ]
    })
    await alert.present();
  }

  assign_business(evt,each){
    if(each.fieldname == 'business_name' && this.store_name_as_business){
      this.form_data.controls['store_name'].setValue(evt ? evt.detail.value : '');
    }
  }


  check_box(each) {
    // console.log(each);
    let value = this.form_data.value[each.fieldname] ? (this.form_data.value[each.fieldname]) : '';
    if(each && each.fieldname == 'store_name_same_as_business_name'){
      this.store_name_as_business = value ? true : false;
      this.form_data.controls['store_name'].setValue(this.form_data.value && value ? this.form_data.value['business_name'] : '');
    }else if(each && each.fieldname == 'same_as_personal'){
      this.form_data.controls['business_phone'].setValue(this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && value ? this.wizard_json[0].form_ctrl_data.phone : '');
    }else if(each && each.fieldname == 'is_same_personal_address'){
      this.form_data.controls['business_address'].setValue(this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && value ? this.wizard_json[0].form_ctrl_data.address : '');
      this.form_data.controls['business_city'].setValue(this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && value ? this.wizard_json[0].form_ctrl_data.city : '');
      this.form_data.controls['business_landmark'].setValue(this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && value ? this.wizard_json[0].form_ctrl_data.landmark : '');
      this.form_data.controls['business_state'].setValue(this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && value ? this.wizard_json[0].form_ctrl_data.state : '');
      this.form_data.controls['business_zip'].setValue(this.wizard_json[0] && this.wizard_json[0].form_ctrl_data && value ? this.wizard_json[0].form_ctrl_data.zipcode : '');
    }
    // if (typeof (value) == 'boolean') {
    //   let date_end = this.info.find(res => res.fieldname == "date_end");
    //   let end_date_year = this.info.find(res => res.fieldname == "end_date_year");
    //   date_end.is_show = false;
    //   end_date_year.is_show = false;
    // } else if (typeof (value) == 'string') {
    //   let date_end = this.info.find(res => res.fieldname == "date_end");
    //   let end_date_year = this.info.find(res => res.fieldname == "end_date_year");
    //   date_end.is_show = true;
    //   end_date_year.is_show = true;
    // }
  }


  getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = "unkown";
    // Detect browser name
    browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
    browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
    browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
    browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
    browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
    browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
    browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
    browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
    browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;

    // detect browser version
    switch (browser) {
      case 'UCBrowser': return `${browser}/${this.browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
      case 'Edge': return `${browser}/${this.browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
      case 'GoogleBot': return `${browser}/${this.browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
      case 'Chromium': return `${browser}/${this.browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
      case 'Firefox': return `${browser}/${this.browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
      case 'Chrome': return `${browser}/${this.browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
      case 'Safari': return `${browser}/${this.browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
      case 'Opera': return `${browser}/${this.browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
      case 'IE': const version = this.browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
        return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
      default: return `unknown/0.0.0.0`;
    }
  }

  browserVersion(userAgent, regex) {
    return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
  }

  async discard_changes() {
    const alert = await this.alertController.create({
      header: 'Discard Changes',
      message: 'Are you sure do you want to discard a changes..?',
      buttons: [
        {
          text: 'No',
          handler: () => { this.alertController.dismiss() }
        },
        {
          text: 'Yes',
          handler: () => {
            // window.location.reload();
            this.router.navigateByUrl('/');
            this.form_data.reset();
          }
        }
      ]
    })
    await alert.present();
  }


  // edit submitted data
  async save_edit_details() {


  }


  //Image attach and Path finder 
  image_count: any = [];
  changeListener($event, each): void {
    this.image_count.push(each.fieldname);
    this.image_field_check = "false";
    this.readThis($event.target, each);
  }

  images_array: any = []

  async readThis(inputValue: any, each): Promise<void> {
    let loader = await this.loadingCtrl.create({ message: 'Please Wait...' });
    await loader.present();

    if (inputValue.files.length > 0) {
      var file: File = inputValue.files[0];
      var file_size = inputValue.files[0].size
      this.categoryfile = file.name
      var myReader: FileReader = new FileReader();

      myReader.onloadend = (e) => {

        this.categoryimagedata = myReader.result;
        // Push file name

        let img_data = {
          file_name: this.categoryfile,
          content: this.categoryimagedata,
          decode: "True",
          attached_to_doctype: "Customers",
          attached_to_name:localStorage['customerRefId']
        }

        if (file_size <= 10000000) {  //10Mb in BYtes

          this.db.upload_image(img_data).subscribe((res: any) => {
            let checks_rep = res ? true : false;
            let unique_name = res.data.name;
            if (checks_rep == true) {

              this.db.upload_image_url(unique_name).subscribe(url => {

                let file_url = url.data.file_url

                if (url) {
                  loader.dismiss()
                  each.file_url = file_url;
                  let value = this.images_array.find(res => res['fieldname'] == each.fieldname);
                  if (value) {
                    this.images_array.map(res => {
                      if (res['fieldname'] == each.fieldname) {
                        res['image_url'] = file_url
                      }
                    })
                  } else {
                    let obj: any = { 'fieldname': each.fieldname, 'image_url': file_url };
                    this.images_array.push(obj);
                    loader.dismiss()
                  }
                }

                let index_v = this.image_count.indexOf(each.fieldname);
                this.image_count.splice(index_v, 1);
                if (this.image_count.length == 0) {
                  this.image_field_check = "true";
                }

              }, error => { loader.dismiss() })
            }
          }, (error: any) => { loader.dismiss() })
        } else if (file_size > 10000000) { //10Mb in bytes
          loader.dismiss()
          this.db.filSizeAlert();
          this.base64_url.splice(this.field_name.indexOf(this.field_name[each.fieldname]), 1);
          this.field_name.splice(this.field_name.indexOf(this.field_name[each.fieldname]), 1);
          if (this.edit_data_details && this.edit_data_details[each.fieldname]) {
            this.edit_data_details[each.fieldname] = "";
          }
          let ind_v = this.image_count.indexOf(each.fieldname);
          this.image_count.splice(ind_v, 1);
          if (this.image_count.length == 0) {
            this.image_field_check = "true";
          }

          this.form_data.controls[each.fieldname].reset();

        } else if (file_size == 0) {
          loader.dismiss()
        }

      }
      myReader.readAsDataURL(file);
    }
  }

  readMultipleFile(inputValue: any, fieldname) {
    this.img_file_name = []
    if (inputValue.files.length > 0) {
      for (let i = 0; i <= inputValue.files.length - 1; i++) {
        // console.log(inputValue.files[i])
        var file: File = inputValue.files[i];
        var file_size = inputValue.files[i].size
        this.convertion(file_size, file, fieldname)
      }
    }

  }


  convertion(file_size, file, fieldname) {
    this.categoryfile = file.name
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      // console.log(myReader.result)
      this.categoryimagedata = myReader.result;
      // this.form_data.value[fieldname] = this.categoryimagedata

      // PUsh image base 64

      if (this.field_name.includes(fieldname)) {
        this.field_name.map((d, index) => {
          if (d == fieldname) {
            this.base64_url.splice(index, 1, this.categoryimagedata);
            this.field_name.splice(index, 1, fieldname);
          }
        })
      }

      else if (!this.field_name.includes(fieldname)) {
        this.field_name.push(fieldname);
        this.base64_url.push(this.categoryimagedata);
      }

      let img_data = {
        file_name: this.categoryfile,
        content: this.categoryimagedata,
        decode: "True",
        attached_to_doctype: "Customers",
        attached_to_name:localStorage['customerRefId']
        // file: this.categoryimagedata,
        // is_private: 0,
        // folder: "Home",
        // file_name:this.categoryfile
      }

      if (file_size <= 10000000) {  //10Mb in BYtes

        this.db.upload_image(img_data).subscribe(res => {

          let checks_rep = res ? true : false;

          let unique_name = res.data.name;

          if (checks_rep == true) {

            this.db.upload_image_url(unique_name).subscribe(url => {
              // console.log(url.data.file_url, "-----")
              let file_url = url.data.file_url

              if (url) {
                if (this.field_name.includes(fieldname)) {
                  this.field_name.map((d, index) => {
                    if (d == fieldname) {
                      this.img_file_name.push(file_url)
                      this.file_name.splice(index, 1, this.uniq(this.img_file_name));
                    }
                  })
                }

                else if (!this.field_name.includes(fieldname)) {
                  this.file_name.push(file_url)

                }

              }

              let index_v = this.image_count.indexOf(fieldname);
              this.image_count.splice(index_v, 1);
              if (this.image_count.length == 0) {
                this.image_field_check = "true";
              }

            })
          }
        })
      }
      else if (file_size > 10000000) {

        this.db.filSizeAlert();
        this.base64_url.splice(this.field_name.indexOf(this.field_name[fieldname]), 1);
        this.field_name.splice(this.field_name.indexOf(this.field_name[fieldname]), 1);
        let ind_v = this.image_count.indexOf(fieldname);
        this.image_count.splice(ind_v, 1);
        if (this.image_count.length == 0) {
          this.image_field_check = "true";
        }

        this.form_data.controls[fieldname].reset();

      }
      else if (file_size == 0) {
      }

    }
    myReader.readAsDataURL(file);
  }

  uniq(array) {
    return array.sort().filter(function (item, pos, ary) {
      return !pos || item != ary[pos - 1];
    });
  }






  // Convert base 64 into binary image data
  base64_binary(dataURI) {

    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;

  }


  // Child table function to get json data of child table
  all_child_data: any = [];
  c_field_name;
  child_data: any = {};
  each_child_data;
  child_table_field_name: any = [];
  child_header_label: any = [];
  header_flex_margin: string = "0px";
  test_child_data: any = {};

  store_header() {
    this.info.map((res, index) => {
      res.child_header = [];
      res.child_fields = [];
      if (res.fieldtype == "Table") {
        let child_table_name = res.options
        let doc_name: string = '';
        let table_array = child_table_name.split(" ");
        table_array.map(res => {
          // doc_name = doc_name.concat(res + '+')
          doc_name = doc_name.concat(res + ' ')
        })

        doc_name = doc_name.substring(0, doc_name.length - 1)
        this.db.get_doc_data(doc_name).subscribe(respon => {
          // let header_data = respon.docs[0].fields
          let header_data = respon.data.fields
          if (header_data) {
            header_data.map(header => {
              if (header.in_list_view) {
                res.child_header.push(header);
                this.child_header_label.push(header);

              }
            })
            let p__flex = ((100 / (this.child_header_label.length)) + '%');
            let flex_out = "0 0 calc(" + p__flex + " " + "-" + " " + this.header_flex_margin + ")";
            this.child_header_label = [];
            res.child_header.map(flex_res => {
              flex_res.flex = flex_out;
            })
          }
        })
      }
    })
  }


  // Modal pop-up
  store_child_fields: any = {};
  arrayFields: any = [];

  open_child_modal(child_table_name, child_field_name) {
    let doc_name: string = '';
    let table_array = child_table_name.split(" ");

    table_array.map(res => {
      // doc_name = doc_name.concat(res + '+')
      doc_name = doc_name.concat(res + ' ')

    })

    doc_name = doc_name.substring(0, doc_name.length - 1)
    let modal_class;
    if (this.db.ismobile) {
      modal_class = "mbl-webformchildPopup"
    } else if (!this.db.ismobile) {
      modal_class = "webformchildPopup"
    }

    // this.db.get_doc_data(doc_name).subscribe(res => {
    //   if (res) {
    //     this.pop_up_child(res, child_table_name, child_field_name, modal_class);
    //   }
    // })

    let check_array = this.arrayFields.find(res => res == child_field_name)

    if (check_array) {
      this.pop_up_child(this.store_child_fields[child_field_name], child_table_name, child_field_name, modal_class);
    } else {
      this.arrayFields.push(child_field_name);
      this.db.get_doc_data(doc_name).subscribe(res => {
        if (res) {
          this.pop_up_child(res, child_table_name, child_field_name, modal_class);
        }
        this.store_child_fields[child_field_name] = res;
      })
    }

    // if (!this.arrayFields.includes(child_field_name)) {
    //   this.arrayFields.push(child_field_name);
    //   this.db.get_doc_data(doc_name).subscribe(res => {
    //     if (res) {
    //       this.pop_up_child(res, child_table_name, child_field_name, modal_class);
    //     }
    //     this.store_child_fields[child_field_name] = res;

    //   })
    // } else if (this.arrayFields.includes(child_field_name)) {
    //   this.pop_up_child(this.store_child_fields[child_field_name], child_table_name, child_field_name, modal_class);
    // }

  }


  async pop_up_child(alldata, child_table_name, child_field_name, modal_class) {
    const modal = await this.modalCtrl.create({
      component: WebformChildPage,
      cssClass: modal_class,
      componentProps: {
        all_data: alldata,
        child_table_name: child_table_name,
        child_table_field_name: child_field_name,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.each_child_data = data.form_data;
      if (this.child_data[data.child_table_field_name]) {
        this.child_data[data.child_table_field_name].push(this.each_child_data)
        this.test_child_data = this.child_data;
        let datas = JSON.stringify(this.test_child_data[data.child_table_field_name]);
        this.form_ctrl_data[data.child_table_field_name].setValue(datas)
        // this.load_child_table(data);
      } else if (!this.child_data[data.child_table_field_name]) {
        this.all_child_data = [];
        this.all_child_data.push(this.each_child_data);
        this.child_data[data.child_table_field_name] = this.all_child_data
        this.test_child_data = this.child_data;
        let datas = JSON.stringify(this.test_child_data[data.child_table_field_name]);
        this.form_ctrl_data[data.child_table_field_name].setValue(datas)
      }
    }
    // this.store_header();
    console.log(this.test_child_data);
  }

  // Edit Form popup
  edit_child_modal(all_values, child_table_name, child_field_name, index_value) {



    let doc_name: string = '';
    let table_array = child_table_name.split(" ");

    table_array.map(res => {
      doc_name = doc_name.concat(res + ' ')

    })

    doc_name = doc_name.substring(0, doc_name.length - 1)

    let modal_class;
    if (this.db.ismobile) {
      modal_class = "mbl-editwebformchildPopup";
    } else if (!this.db.ismobile) {
      modal_class = "editwebformchildPopup";
    }


    let check_array = this.arrayFields.find(res => res == child_field_name)

    if (check_array) {
      this.edit_pop_up_child(all_values, this.store_child_fields[child_field_name], child_table_name, child_field_name, index_value, modal_class);
    } else {
      this.arrayFields.push(child_field_name);
      this.db.get_doc_data(doc_name).subscribe(res => {
        if (res) {
          this.edit_pop_up_child(all_values, this.store_child_fields[child_field_name], child_table_name, child_field_name, index_value, modal_class);
        }
        this.store_child_fields[child_field_name] = res;
      })
    }

  }


  async edit_pop_up_child(all_values, alldata, child_table_name, child_field_name, index_value, modal_class) {
    const modal = await this.modalCtrl.create({
      component: EditWebformchildPage,
      cssClass: modal_class,
      componentProps: {
        all_data: alldata,
        child_table_name: child_table_name,
        child_table_field_name: child_field_name,
        all_values: all_values,
        index_value: index_value
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.child_data[data.child_table_field_name][index_value] = data.form_data;
      // this.ref.detectChanges();
    }
  }


  // Select and unselect the list of child tables
  select_unselect(data, alldata, index_value) {
    if (data.select) {
      if (data.select == 1) {
        data.select = 0;
      } else if (data.select == 0) {
        data.select = 1;
        data.index_value = index_value;
      }
    } else if (!data.select) {
      data.select = 1;
      data.index_value = index_value;
    }

    this.overall_select_unselect(alldata, index_value);
  }

  overall_select_unselect(alldata, index_value) {
    let over_all_values: any = [];
    alldata.map(s => {
      if (s.select) {
        over_all_values.push(s.select)
      } else if (!s.select) {
        s.select = 0;
        over_all_values.push(s.select)
      }
    })

    if (over_all_values.includes(1)) {
      alldata.select = 1;
    } else if (!over_all_values.includes(1)) {
      alldata.select = 0;
    }

    this.all_select_unselect(over_all_values, alldata);
  }


  // Assign a valuse for select all and unselect all 
  all_select_unselect(values, alldata) {
    if (!values.includes(0)) {
      alldata.select_all = 1;
    } else if (values.includes(0)) {
      alldata.select_all = 0;
    }
  }


  // Select all and unselect all
  select_all(alldata) {
    alldata.map(s => { s.select = 1; })
    alldata.select_all = 1;
    alldata.select = 1;
  }

  unselect_all(alldata) {
    alldata.map(s => { s.select = 0; })
    alldata.select_all = 0;
    alldata.select = 0;
  }


  delete_records1(alldata, fieldname,obj) {
    alldata.select_all = 0;
    alldata.select = 0;
    obj.select = 1;
    if (alldata.select_all == 0) {
      alldata.map(del => {
        if (del.select == 1) {
          // this.test_child_data[fieldname].splice(del.index_value, 1);
          this.child_data[fieldname].splice(del.index_value, 1);
        }
      })
    } else if (alldata.select_all == 1) {
      this.child_data[fieldname] = [];
    }
  }

  // delete records
  delete_records(alldata, fieldname) {
    alldata.select = 0;
    if (alldata.select_all == 0) {
      alldata.map(del => {
        if (del.select == 1) {
          // this.test_child_data[fieldname].splice(del.index_value, 1);
          this.child_data[fieldname].splice(del.index_value, 1);
        }
      })
    } else if (alldata.select_all == 1) {
      this.child_data[fieldname] = [];
    }
  }


  nav_by_breadcrumb() {
    this.router.navigateByUrl("/" + this.current_path);
  }

  cancel_details() {
    this.form_data.reset();
    this.child_data = {};
    this.test_child_data = {};
    this.submitted = false;
    this.image_field_check == "true"
    this.isModel ? this.db.close_modal() : this.router.navigateByUrl('/' + this.doctype.toLowerCase() + 's')
  }






  // @HostListener('window:resize', ['$event'])
  // private func() {
  //   this.db.check_mobile();
  // }

  public resolved(captchaResponse: string): void {
    this.captcha = true;
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    // console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.captcha = false;
  }





  set_email_id() {
    Object.keys(this.form_ctrl_data).map(key => {
      if (key == 'email') {
        this.form_ctrl_data[key].setValue(localStorage['user_email_id'])
      }
    })
  }



  onSelectFile(event, item, options) {

    let fileSize = event.target.files[0].size; // you will get the uploaded file size

    let a = fileSize / 1048;

    if (a <= 2000) {
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        var file: File = event.target.files[0];
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            // console.log(event);
            //  console.log(file.name);
            let prescription_file = file.name;
            let imagedata = event.target.result;
            item.image_upload = event.target.result;

            let data = {
              "filename": prescription_file,
              "content": imagedata,
            }

            this.form_data[item.fieldname] = data;

          }

          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      this.db.alert('Maximum allowed file size is 2MB');
    }
  }


  onMulti_SelectFile(event, item, options) {
    // console.log(event)
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log( event.target.files[i]);
      let fileSize = event.target.files[i].size; // you will get the uploaded file size
      let a = fileSize / 1048;

      if (a <= 2000) {
        if (event.target.files[i]) {
          var filesAmount = event.target.files.length;
          var file: File = event.target.files[i];
          // for (let i = 0; i < filesAmount ; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            // console.log(event);
            // console.log(event.target.result);
            let prescription_file = file.name;
            let imagedata = event.target.result;
            item.image_upload = event.target.result;

            let data = {
              "filename": prescription_file,
              "content": imagedata,
            }

            //  console.log(data)

            if (item.multi_upload) {
              let datas: any = [];
              if (this.form_data[item.fieldname] && this.form_data[item.fieldname].length != 0) {
                //  console.log(this.form_data[item.fieldname])
                this.form_data[item.fieldname].map(res => {
                  datas.push(res)
                })
              } else {
                datas.push(data);
              }
              //  datas.push(data);
              this.form_data[item.fieldname] = datas;
            } else {
              this.form_data[item.fieldname] = data;
            }

          }
          reader.readAsDataURL(event.target.files[i]);
          //  }
        }
      } else {
        this.db.alert('Maximum allowed file size is 2MB');
      }
    }

  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // console.log('Back button pressed');
    $("ion-select-popover").hide();
    $("popover-content").hide();
  }


  assign_image(data) {
    if (this.images_array.length != 0) {
      this.images_array.map(res => {
        data[res.fieldname] = res['image_url']
      })
    }
  }

  load_child_table(data) {
    let value = Object.keys(data);
    value.map(r => {

      if (this.test_child_data && this.test_child_data[r]) {
        data[r] = this.test_child_data[r]
      }
    })

  }

  load_next_form() {
    this.wizard_json[this.navigation_count].form_ctrl_data = this.form_data.value;
    this.store_old_datas(false);
    this.form_ctrl_data = {};
    this.submitted = false;
    this.navigation_count = this.navigation_count + 1;
    this.check_navigation(this.navigation_count);
    let value = this.profile_menu.find(res => res.index == this.navigation_count);
    this.db.tab_buttons(this.profile_menu, value.name, 'name');
    // this.db.tab_buttons(this.profile_menu, value.name, 'name');
    
    // this.db.progress_bar.next(this.navigation_count +  1) // progress_bar
    this.get_forms(this.wizard_json, this.navigation_count)
    this.store_header();
    this.filter_section_break();
    // this.check_assign_sec_break();
    this.assign_final_data();
    // this.form_data.reset();
    // this.formGroups.reset();
    this.form_data = this.formBuilder.group(
      this.form_ctrl_data
    );
    // console.log(this.wizard_json[this.navigation_count].form_ctrl_data);
  }


  save_details1(type) {

    var data: any = {};

    if (type == '') {
      data = this.form_data.value;
      this.assign_image(data);
      this.load_child_table(data);
      this.submitted = true;
    } else {
      data = this.data
    }

    // data["doctype"] = 'Customer Registration';
    // data = { ...data, ...this.store_old_data };
    data.name = localStorage['customerRefId']
    data.center = localStorage['CenterName']
    // data.address_map ? delete data.address_map : null;
    // console.log(data);
    if (this.form_data.status == "VALID") {
      // console.log(data,'saddf')
      // console.log(data,'saddf')
      // this.db.insert_update_customer(data).subscribe(res => {
      this.db.update_registration_details(data).subscribe(res => {
        if (res && res.message && res.message.status == 'Success') {
          this.edit_form_values = res.message.data;
          if(type == ''){
            this.db.alert(res.message.message);
            this.router.navigateByUrl('/thankyou');
          }else{
            this.load_next_form();
            this.scroll_to_top.emit('');
          }
        } else{
          this.db.alert(res.message.message);
        } 

      })
    }

  }


  save_details(type) {

    var data: any = {};

    if (type == '') {
      data = this.form_data.value;
      this.assign_image(data);
      this.load_child_table(data);
      this.submitted = true;
    } else {
      data = this.data
    }

    data["doctype"] = 'Customer Registration';
    data = { ...data, ...this.store_old_data };
    if (this.form_data.status == "VALID") {
      this.db.insert_update_customer(data).subscribe(res => {
        if (res.status == 'Success') {
          type == '' ? this.router.navigateByUrl('/thankyou') : null;
        } else{
          this.db.alert(res.message);
        } 

      })
    }

  }

  focusof() {
    this.customInput?.nativeElement?.focus();
  }




  remove_alphabets() {
    setTimeout(() => {
      var inputBox = document.getElementById("inputBox");
      // console.log(inputBox)
      if (inputBox) {

        var invalidChars = ["-", "+", "e",];

        inputBox.addEventListener("keydown", function (e) {
          if (invalidChars.includes(e.key)) {
            e.preventDefault();
          }
        });

      }
    }, 1000)
  }

  map_focus() {
    this.db.getPlaceAutocomplete(this.addresstext.nativeElement, 'get_map_fields')
  }



  get_map_values() {


    if (this.db.location_info && Object.keys(this.db.location_info).length != 0) {
      Object.keys(this.db.location_info).map(res => {
        if (this.form_ctrl_data[res]) {

          // if(res.includes('latitude') || res.includes('longitude')){
          //   this.db.location_info[res] = this.db.location_info[res].toString();
          // }

          this.form_ctrl_data[res].setValue(this.db.location_info[res]);
          // this.check_ng_select(res);
        } else {
          //  console.log(Object.keys(this.db.location_info))
          let value = res;
          if (res == 'zipcode') {
            value = res.slice(0, 3);
          } else if (res == 'address') {
            //  value = 'business_address_asper_kyc_document';
            value = 'business_address';
          }
          let find = Object.keys(this.form_ctrl_data).find(r => r.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
          find = find == 'business_address_map' ? 'business_address' : find;
          find ? this.form_ctrl_data[find].setValue(this.db.location_info[res]) : null;


          // this.check_ng_select(find);
        }
      })

    }
    // 
  }

  check_ng_select(res){
    if(res &&  $('#' + res + 'ng-select')){
      // console.log( $('#' + res + 'ng-select'));
      $('#' + res + 'ng-select').addClass('ng-touched').removeClass('ng-untouched');
    }
  }


}
