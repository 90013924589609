<ion-header *ngIf="db.ismobile">
  <ion-toolbar>
    <ion-back-button defaultHref="/location" (click)="navigation()" slot="start"></ion-back-button>
    <ion-label>Choose Your Centre</ion-label>
  </ion-toolbar>
</ion-header>
<ion-content [ngClass]="db.ismobile ? 'bg':''">

  <!-- *ngIf="!db.ismobile" -->
  <div  class="">
    <ion-accordion-group>
      <ion-accordion value="first" class="main_page pb-10">
        <div button class="d__flex mouse" (click)="showmore =! showmore" slot="header" color="light">
          <div class="home__logo d__flex">
            <img class="mouse " [src]="'assets/imgs/favi_farmlink.svg'" alt="">
          </div>
          <div class="header_ d__flex just-s" [class.border_ra]="showmore">
            <div>
              <h6 class="m-0 fnt-11 fnt-500">{{db.center_name ? db.center_name : 'Centre'}}</h6>
              <ion-label class="fnt-13 fnt-600">{{db.replace_txt(db.store_name)}} <span *ngIf="!db.cust_email && !db.cust_name && db.center_name && false" class="fnt-12 fnt-600">/ {{db.center_name}}</span></ion-label>
            </div>
            <ion-icon class="fnt-15 drop_icon" slot="end" [name]="showmore ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
          </div>  
        </div>
        <div class="ion-padding p-0 store_values" slot="content" >
          <!-- <ion-item button lines="none" class="avail__loca mouse" *ngFor="let item of stores" (click)="store_select(item.name)">
            <ion-checkbox [checked]="db.store_name == item.name" slot="start"></ion-checkbox>
            <p class="m-0 p_area fnt-14">{{item.name}}</p>
            <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
          </ion-item> -->

          <ion-radio-group class="store_selection" [value]="db.store_name" (ionChange)="store_select($event)" >
            <ion-item lines="none" *ngFor="let item of stores" [class.active__radio__option]="db.store_name == item.name" >
              <ion-radio slot="start" [value]="item.name"></ion-radio>
              <div>
                <p class="m-0 fnt-13" > {{item.display_name}}</p> 
              </div>
              <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
            </ion-item>
          </ion-radio-group>
        </div>
      </ion-accordion>
    </ion-accordion-group>

    <div class="policy" *ngIf="db.cust_email && db.cust_name">
      <div *ngFor="let item of shop">  
        <ion-item button *ngIf="item.enable == 1" (click)="modalCtrl.dismiss()" lines="none" [routerLink]="item.route">
          <ion-icon class="menu-icon" [src]="item.icon?item.icon:''"></ion-icon> 
          <ion-label>{{item.title}}</ion-label>
          <ion-icon class="mr-5" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </div>
    </div>

    <div class="select_store">
      <!-- <div class="d__flex just-s choose_store p-15">
        <h6 class="locat_heading m-0 fnt-15 fnt-600">Choose Your Store</h6>
        <ion-icon (click)="db.center_name ? modalCtrl.dismiss() : null " class="close-icon mouse" name="close-outline"></ion-icon>
      </div>
      <ion-item button lines="none" class="avail__loca mouse" *ngFor="let item of stores" (click)="store_select(item.name)">
        <p class="m-0 p_area fnt-14">{{item.name}}</p>
        <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
      </ion-item> -->
    </div>
  </div>

  <div class="location-dropdown-box" *ngIf="!db.cust_email && !db.cust_name">
    <div class="d__flex just-s top__container p-10" *ngIf="!db.ismobile">
      <h6 class="locat_heading m-0 fnt-14 fnt-500">Choose Your Centre</h6>
      <!-- <ion-icon (click)="db.center_name ? modalCtrl.dismiss() : null " class="close-icon mouse" name="close-outline"></ion-icon> -->
    </div>
    <div class="top__locations">
      <!-- <div class="avail__locations d__flex gap-10 flex-w" *ngIf="db.warehouse_list && db.warehouse_list.length != 0 && !db.ismobile">
        <div class="avail__locat mouse" *ngFor="let item of db.warehouse_list" (click)="centre_selection(item)">
          <p class="m-0 p_area fnt-14">{{item.label}}</p>
        </div>
      </div> -->

      <!-- <div class="moile_locat" *ngIf="db.warehouse_list && db.warehouse_list.length != 0">
        <ion-item button lines="none" class="avail__loca mouse" *ngFor="let item of db.warehouse_list" (click)="centre_selection(item)">
          <ion-checkbox [checked]="db.center_name == item.label" slot="start"></ion-checkbox>
          <p class="m-0 p_area fnt-14">{{item.label}}</p>
          <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </div> -->

      <ion-radio-group *ngIf="db.warehouse_list && db.warehouse_list.length != 0" class="store_selection" [value]="db.center_name" (ionChange)="centre_selection($event)" >
        <ion-item lines="none" *ngFor="let item of db.warehouse_list" [class.active__radio__option]="db.center_name == item.label" slot="start" >
          <ion-radio slot="start" [value]="item.label"></ion-radio>
          <div>
            <p class="m-0 fnt-13" > {{item.label}}</p> 
          </div>
          <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-radio-group>
    </div>
  </div>
</ion-content>
