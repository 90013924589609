import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-delivery-slot',
  templateUrl: './delivery-slot.component.html',
  styleUrls: ['./delivery-slot.component.scss'],
})
export class DeliverySlotComponent implements OnInit {
  alert_message;
  @Input() slot_id:any;
  constructor(public db:DbService,public modalCtrl:ModalController) { }

  ngOnInit() {
    this.db.getSlot(this.slot_id)
    // console.log(this.db.delivery_slots);
    // console.log(this.db.shipping_methods)
  }

  changed_date(event,type){
    // console.log(event.detail.value)
    // if(event.detail.value){
        this.db.delivery_slots.map(res =>{

          if(event.detail.value != 'undefined'){
            if(type == 'date'){
              res.selected_time_slots = event.detail.value
            } 
          } else {
              res.selected_time_slots = undefined
          }
        })
      // }
    }

    changed_time(event,type){
      // console.log(event.detail.value)
      this.db.delivery_slots.map(res =>{
        if(event.detail.value != 'undefined'){
          if(type == 'time'){
              res.selected_time_slots.selected_time = event.detail.value
          } 
        } else {
           res.selected_time_slots.selected_time_slots = undefined
        }
      })
    }

    
    choose_slot(){
     if(!(this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method)) {
        this.alert_message ="Delivery";
     }  else if( this.db.delivery_slots.length != 0 && !(this.db.delivery_slots[0].selected_time_slots)) {
        this.alert_message ="slot_date";
     }else if(this.db.delivery_slots.length != 0 && !(this.db.delivery_slots[0].selected_time_slots.selected_time)) {
        this.alert_message ="slot_time";
     } else {
        this.modalCtrl.dismiss();
     }
    }


    // (click)="db.select_shipping_method(item.name)" 
    select_method(event){
      // console.log(event.detail.value)
      // console.log("selecting method")
      if((this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method && event.detail.value !=this.db.shipping_settings.selected_shipping_method  )|| (this.db.shipping_settings && !(this.db.shipping_settings.selected_shipping_method))){
        this.db.select_shipping_method(event.detail.value)
      }
        
    }

    dismiss(){
      this.modalCtrl.dismiss()
    }

    check_label(){
      if(!(this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method)) {
        return 'Choose Delivery method'
     }  else if( this.db.delivery_slots.length != 0 && !(this.db.delivery_slots[0].selected_time_slots)) {
        return 'Choose Delivery date'
     }else if(this.db.delivery_slots.length != 0 && !(this.db.delivery_slots[0].selected_time_slots.selected_time)) {
        return 'Choose Delivery slot time'
     } else {
        return 'Continue'
     }
    }


    check(slots){
        // this.db.delivery_slots[0].dates_lists[0].slots = []
        // this.db.delivery_slots[0].dates_lists[0].slots = slots.slots
        // console.log("delivery slots",this.db.delivery_slots)
        // console.log(slots)
    }

    select_radio(eve,item){
      console.log(item);
      let value = eve.detail.value;
      console.log(value);
      // item['selected_time_slots'] = {
      //   selected_time:''
      // };
      item['selected_time_slots']['selected_time'] = value;
      console.log(item);
    }
}
