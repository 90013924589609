<!-- <ion-spinner *ngIf="product_info.count"></ion-spinner> -->
<!-- [class.change_button_color]="change_button_color" -->
<div class="change_button_color cart-buttons d__flex gap-10 position-relative" [class.big__button]="big_button">
  <!-- *ngIf="!db.check_stocks(product_info) ;else notify;" -->
  <div  class="cart__button ion-activatable ripple-parent position-relative" (click)="( product_info.count == 0 && !(db.check_disabled(product_info)))?addto_cart.emit(product_info):''" (click)="vendor_price_event.emit(product_info)" [class.disabled]="db.check_disabled(product_info)" [ngClass]="product_info.count == 0?'not__added':'__added'">
    <ion-ripple-effect *ngIf="product_info.count == 0"></ion-ripple-effect>
    <ion-button class="mouse __button __button_1" *ngIf="product_info.count && product_info.count != 0" [disabled]="product_info.temp_disabled" [class.disabled]="product_info.temp_disabled" (click)="product_info.temp_disabled ? null: removeto_cart.emit(product_info)" (click)="vendor_price_event.emit(product_info)" fill="clear"><span>-</span></ion-button>
    <ion-spinner *ngIf="(!product_info.count || product_info.count < 0) && !(product_info.count == 0)"></ion-spinner>
    <ion-spinner *ngIf="product_info.count != 0 && product_info.temp_disabled"></ion-spinner>
    <div (click)="vendor_price_event.emit(product_info)" class="d__flex icon__Button">
      <!-- <ion-icon *ngIf="product_info.count == 0 && big_button" src='/assets/districo/add-cart.svg'></ion-icon> -->
      <ion-icon class="fnt-10" *ngIf="product_info.count == 0 && !big_button" src='/assets/districo/plus.svg'></ion-icon>
      <span class="add__txt"> {{product_info.count != 0 ? product_info.count:big_button ? 'Add to Cart':''}} </span>
    </div>
    <!-- <span class="add" [class.not__added]="product_info.count == 0" [class._added]="product_info.count != 0" *ngIf="!product_info.temp_disabled" > {{product_info.count && (product_info.count != 0)?product_info.count:'ADD'}} </span> -->
    <ion-button class="mouse __button" *ngIf="product_info.count && product_info.count != 0" (click)="true?addto_cart.emit(product_info):''" (click)="vendor_price_event.emit(product_info)" [disabled]="db.check_disabled(product_info)" fill="clear"><span>+</span></ion-button>
  </div>
  <!-- <ng-template #notify>
    <ion-button (click)="db.notify_me(product_info)" class="notify_me" fill="clear">
      <ion-icon class="notify_icon" *ngIf="product_info.count == 0" src='/assets/icon/notify.svg'></ion-icon>
      Notify Me
    </ion-button>
  </ng-template> -->
</div>
<!-- <div class="loader" *ngIf="product_info.count  || product_info.count == 0">
    <div class="circle one"></div>
    <div class="circle two"></div>
    <div class="circle three"></div>
  </div> -->
