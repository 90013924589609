import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';

import {
  PagenotfoundComponent,
} from './components/pagenotfound/pagenotfound.component';
// import { AuthGuardGuard } from './guards/auth-guard.guard';
// import { CategoryResolver } from './pages/category/category.resolver';
import {
  ProductDetailResolver,
} from './pages/product-detail/product-detail.resolver';
import { AuthGuardGuard } from './guards/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/home',
    // redirectTo: '/',
    pathMatch: 'full'
  },
  //    redirectTo: '/tabs/home',
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'test-payment',
    loadChildren: () => import('./pages/test-payment/test-payment.module').then( m => m.TestPaymentPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule),
    // resolve:{
    //   data : CategoryResolver
    // },
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'category/:route',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'shop/:product',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule),
    // resolve:{
    //   data : CategoryResolver
    // },
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'shop/:product/:product_1',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule),
    // resolve:{
    //   data : CategoryResolver
    // },
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'shop/:product/:product_1/:product_2',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule),
    // resolve:{
    //   data : CategoryResolver
    // },
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'yourcart',
    loadChildren: () => import('./pages/yourcart/yourcart.module').then( m => m.YourcartPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    // canActivate :[AuthGuardGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'pr/:product',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    // resolve: {
    //   data : ProductDetailResolver
    // },
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'popup-attribute',
    loadChildren: () => import('./pages/popup-attribute/popup-attribute.module').then( m => m.PopupAttributePageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'add-address',
    loadChildren: () => import('./pages/add-address/add-address.module').then( m => m.AddAddressPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'address-list',
    loadChildren: () => import('./pages/address-list/address-list.module').then( m => m.AddressListPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-profile/order/:order_id',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-profile/view-category/:category_id',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-profile/view-product/:category_id',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-profile/view-product',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    // canActivate:[AuthGuardGuard]
  },
  // {
  //   path: 'my-profile/my-orders',
  //   loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  // },

  // {
  //   path: 'my-profile/:data',
  //   loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  // },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders/my-orders.module').then( m => m.MyOrdersPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'order-detail/:order_id',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'search/:id',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'search/shop-list/:id',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'review-product',
    loadChildren: () => import('./pages/review-product/review-product.module').then( m => m.ReviewProductPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'contact-us/:type',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: 'product-popup',
    loadChildren: () => import('./pages/product-popup/product-popup.module').then( m => m.ProductPopupPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'filters-popup',
    loadChildren: () => import('./pages/filters-popup/filters-popup.module').then( m => m.FiltersPopupPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'forgetten',
    loadChildren: () => import('./pages/forgetten/forgetten.module').then( m => m.ForgettenPageModule)
  },
  // {
  //   path: 'why',
  //   loadChildren: () => import('./pages/why/why.module').then( m => m.WhyPageModule)
  // },
  // {
  //   path: 'story',
  //   loadChildren: () => import('./pages/story/story.module').then( m => m.StoryPageModule)
  // },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'quickview',
    loadChildren: () => import('./pages/quickview/quickview.module').then( m => m.QuickviewPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'cancellation-policy',
    loadChildren: () => import('./pages/cancelation-policy/cancelation-policy.module').then( m => m.CancelationPolicyPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'terms/:id',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule),
    // canActivate:[AuthGuardGuard]
  },
  // {
  //   path: 'about-us',
  //   loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  // },
  {
    path: 'p/:page_route',
    loadChildren: () => import('./pages/ecomerce-builder/ecomerce-builder.module').then( m => m.EcomerceBuilderPageModule),
    // canActivate:[AuthGuardGuard]
  },
  // {
  //   path: 'terms-condition',
  //   loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  // },
  {
    path: 'reward-points',
    loadChildren: () => import('./pages/redeem/redeem.module').then( m => m.RedeemPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallets/wallets.module').then( m => m.WalletsPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'social-share',
    loadChildren: () => import('./pages/social-share/social-share.module').then( m => m.SocialSharePageModule),
    canActivate:[AuthGuardGuard]
  },
  // {
  //   path: 'blog-list',
  //   loadChildren: () => import('./pages/blog-list/blog-list.module').then( m => m.BlogListPageModule),
  //   canActivate:[AuthGuardGuard]
  // },
  // {
  //   path: 'blog-list/:id',
  //   loadChildren: () => import('./pages/blog-list/blog-list.module').then( m => m.BlogListPageModule),
  //   canActivate:[AuthGuardGuard]
  // },
  // {
  //   path: 'blog-detail/:route',
  //   loadChildren: () => import('./pages/blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule),
  //   canActivate:[AuthGuardGuard]
  // },
  {
    path: 'comments',
    loadChildren: () => import('./pages/comments/comments.module').then( m => m.CommentsPageModule),
    canActivate:[AuthGuardGuard]
  },
  // {
  //   path: 'subscriptions',
  //   loadChildren: () => import('./pages/subscribe/subscribe.module').then( m => m.SubscribePageModule),
  //   canActivate:[AuthGuardGuard]
  // },
  // {
  //   path: 'subscribe-plan',
  //   loadChildren: () => import('./pages/subscribe-plan/subscribe-plan.module').then( m => m.SubscribePlanPageModule),
  //   canActivate:[AuthGuardGuard]
  // },
  {
    path: 'startup',
    loadChildren: () => import('./pages/startup/startup.module').then( m => m.StartupPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'location-selection',
    loadChildren: () => import('./pages/location-selection/location-selection.module').then( m => m.LocationSelectionPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'order-summary',
    loadChildren: () => import('./pages/order-summary/order-summary.module').then( m => m.OrderSummaryPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule),
    data: { preload: true },
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'thankyou/:order_id',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule),
    data: { preload: true },
  },
  {
    path: 'thankyou-success/:order_id',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule),
    data: { preload: true },
  },
  {
    path: 'viewall',
    loadChildren: () => import('./pages/viewall/viewall.module').then( m => m.ViewallPageModule)
  },
  {
    path: 'viewall/:data',
    loadChildren: () => import('./pages/viewall/viewall.module').then( m => m.ViewallPageModule)
  },
  {
    path: 'viewall/:data/:id',
    loadChildren: () => import('./pages/viewall/viewall.module').then( m => m.ViewallPageModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'brands/:brand',
    loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule),
    canActivate:[AuthGuardGuard]
   },
  {
  path: 'brands/:brand/:id',
  loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule),
  canActivate:[AuthGuardGuard]
 },
  {
    path: 'unavilable-location',
    loadChildren: () => import('./pages/unavilable-location/unavilable-location.module').then( m => m.UnavilableLocationPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'pincode-popup',
    loadChildren: () => import('./pages/pincode-popup/pincode-popup.module').then( m => m.PincodePopupPageModule),
    // canActivate:[AuthGuardGuard]
  },
  {
    path: 'make-return',
    loadChildren: () => import('./pages/make-return/make-return.module').then( m => m.MakeReturnPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'my-profile/reply/:name',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    canActivate:[AuthGuardGuard]
    // loadChildren: () => import('./pages/comment/comment.module').then( m => m.CommentPageModule)
  },
  {
    path: 'my-profile/edit-blog/:name',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    canActivate:[AuthGuardGuard]
    // loadChildren: () => import('./pages/comment/comment.module').then( m => m.CommentPageModule)
  },
  {
    path: 'my-profile/new-blog/:name',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'web-page',
    loadChildren: () => import('./pages/web-page/web-page.module').then( m => m.WebPagePageModule)
  },
  {
    path: 'web-page/:page_route',
    loadChildren: () => import('./pages/web-page/web-page.module').then( m => m.WebPagePageModule)
  },
  {
    path: 'web-page/:page_route/:page_route_1',
    loadChildren: () => import('./pages/web-page/web-page.module').then( m => m.WebPagePageModule)
  },
  {
    path: 'webform-child',
    loadChildren: () => import('./pages/webform-child/webform-child.module').then(m => m.WebformChildPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'edit-webformchild',
    loadChildren: () => import('./pages/edit-webformchild/edit-webformchild.module').then(m => m.EditWebformchildPageModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'category-landing',
    loadChildren: () => import('./pages/category-landing/category-landing.module').then( m => m.CategoryLandingPageModule)
  },
  {
    path: 'category-landing/:page_route',
    loadChildren: () => import('./pages/category-landing/category-landing.module').then( m => m.CategoryLandingPageModule)
  },

  // {
  //     path: '**', 
  //     redirectTo: '/'
  // },
  {
    path: 'page-not-found', 
    component: PagenotfoundComponent
  },
  {
      path: '**', 
      redirectTo: '/page-not-found'
  },
  {
    path: 'order-feedback',
    loadChildren: () => import('./pages/order-feedback/order-feedback.module').then( m => m.OrderFeedbackPageModule)
  },
  {
    path: 'order-reason',
    loadChildren: () => import('./pages/order-reason/order-reason.module').then( m => m.OrderReasonPageModule)
  },


  // {
  //   path: '**',
  //   component:HomePage
  // },


 
];

@NgModule({
  imports: [
      RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled' })
  ],
  // initialNavigation: 'enabled',
  exports: [RouterModule]
})
export class AppRoutingModule { }
