import { Component, OnInit,Output,Input,EventEmitter } from '@angular/core';
import { DbService } from 'src/app/services/db.service';


@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['../secondary-header/secondary-header.component.scss','../web-header/web-header.component.scss','./default-header.component.scss'],
})
export class DefaultHeaderComponent implements OnInit {

  drop_down2 = false;
  // @Output() openModal = new EventEmitter();
  @Output() cart_pop = new EventEmitter();
  @Input() web_header;
  constructor(public db: DbService) { }

  ngOnInit() {
     
  }

  
  _menu = [
    {
      title: 'My Profile',
      route: '/my-profile/dashboard'
    },
    {
      title: 'My Orders',
      route: '/my-profile/my-orders'
    },
  ]

}
