
<form [formGroup]="myform" *ngIf="form_data.length != 0" class="myform">
  <div *ngFor="let options of form_data" >
    <ng-container *ngIf="options.mapped_by == current_value">
      <h6 *ngIf="options.group_title" class="group-title">{{options.group_title}}</h6>
      <ng-container  *ngIf="options.group_fileds.length != 0" >
        <div [class.d__flex]="options.group_title != 'DESCRIPTION'"  class="flex-w gap-10">
          <div *ngFor="let item of options.group_fileds"   [ngClass]="item.input_type =='checkbox'?'ion-align-items-center d__flex gap-10 flex-100 m-0 cursor-pointer':''" [class.input-box]="!item.hidden" [style.flex]="'0 0 calc('+ (item.box_width ? item.box_width : box_width)   +' - 10px)'" [class.fullwidth]="item.width == 'full'" [class.profile_img]="item.profile_img == 'circle_center'" [class.ion-hide]="item.hidden && !item.empty"  [class.flex-100]="item.input_type =='textarea'">
            <ng-container *ngIf="!item.hidden">
              <div [title]="item.name" [class.check_box]="item.input_type=='checkbox'" class="m-0 label position-relative d__flex ion-align-items-center" (mouseenter)="item.show_info = true" (mouseleave)="item.show_info = false">
                <!--  (click)="item.input_type == 'checkbox' ?item.value?item.value = false:item.value = true:null" -->
                <span class="op-7" > {{item.label}}</span>
                <!-- <a target="_blank" [href]="item.value ? db.product_img(item.value) : null" class="file_image mouse" *ngIf="item.type == 'file'"><img class="image"  [src]="item.value ? db.product_img(item.value) : null"> </a> -->
                <div *ngIf="item.tooltip || item.type == 'password'" class="tooltips mouse">  
                  <img  src="/assets/icon/help.svg" (mouseenter)="item.show_tooltip = true" (mouseleave)="item.show_tooltip = false" alt="">
                  <span *ngIf="item.show_tooltip && item.type != 'password'" class="tooltiptext">{{item.tooltip}}</span>
                  <span class="tooltiptext" *ngIf="item.type == 'password' && item.show_tooltip && this.db.website_settings && this.db.website_settings.password_validation">{{"Minimum of "+ this.db.website_settings.password_validation.min_password_length +" character and must contain atleast " + this.db.website_settings.password_validation.password_policy}}</span>
                </div>
                
                <!--<span class="error_txt" *ngIf="submitted && myform.get(item.name).errors">
                  <span *ngIf="myform.get(item.name).errors.required">* required</span>  
                  <span *ngIf="myform.get(item.name).errors.email">* email</span>  
                  <span *ngIf="myform.get(item.name).errors.pattern">* mobile</span>  
                </span>-->

               </div>

              <ion-input *ngIf="item.input_type == 'input' && item.type != 'upload_file'  && item.type != 'date'" [name]="item.name" [disabled]="item.disabled" [type]="item.type" [accept]="item.accept ? item.accept : ''" (change)="item.type == 'file' ? onSelectFile($event,item,options) : null" [value]="item.value" [(ngModel)]="item.value"  [formControlName]="item.name" [placeholder]="item.placeholder"  [required]="item.required" [email]="item.validator == 'email'" [pattern]="item.pattern" (ionFocus)="item.focus = true" (ionBlur)="item.focus = false" [class.focus]="item.focus" ></ion-input>
      
              <input *ngIf="item.input_type == 'input' && item.type != 'upload_file' && item.type == 'date' && item.disabled"  [type]="item.type" [value]="item.value"  [(ngModel)]="item.value"  [formControlName]="item.name" [max]="item.min_max == 'Max' ? (item.is_today ?  today : tomorrow) : null" [min]="item.min_max == 'Min' ? (item.is_today ?  today : tomorrow) : null" [readonly]='true'>

              <input *ngIf="item.input_type == 'input' && item.type != 'upload_file' && item.type == 'date' && !item.disabled"  [type]="item.type" [value]="item.value"  [(ngModel)]="item.value"  [formControlName]="item.name" [max]="item.min_max == 'Max' ? (item.is_today ?  today : tomorrow) : null" [min]="item.min_max == 'Min' ? (item.is_today ?  today : tomorrow) : null" >

              <ion-checkbox *ngIf="item.input_type == 'checkbox__'" [name]="item.name" [formControlName]="item.name" [required]="item.required" [(ngModel)]="item.value" [value]="item.value" [checked]="item.value"   (ionChange)="checkbox_value_change($event,item,options)"></ion-checkbox >

              <div *ngIf="item.type == 'upload_file'" class="image-input" [class.circle]="item.width == 'full_width_circle'" [style.min-height]="item.height ? item.height + 'px' : null" >  
                <div class="picture"></div>
                <input [id]="item.name" [accept]="item.accept ? item.accept : ''" [multiple]="item.multi_upload ? item.multi_upload : false"  (change)="item.multi_upload ? onMulti_SelectFile($event,item,options) : onSelectFile($event,item,options)" type="file" data-max-size="2048"   [formControlName]="item.name" placeholder="Profile_picture" class="img-input">  
                <img *ngIf="item.image_upload != ''" class="profile__image"  [src]="item.image_upload">
                <label class="file__label d__flex" [for]="item.name">
                  <img class="mouse" [src]="item.image_upload ? '' : 'assets/icon/Add-media.svg'" alt="">
                  <!-- <p *ngIf="item.placeholder" class="p m-0 mouse" for="files">Upload your {{item.placeholder}} here</p> -->
                   <div class="d__flex upload_p mouse">
                    <p class="p m-0">Upload Photo</p>
                    <p class="p m-0"> (Max 2MB)</p>
                   </div>
                </label>
                <label *ngIf="item.edit_button == 1" class="file__label_edit" [for]="item.name"><ion-icon class="mouse" src="assets/icon/edit003.svg"></ion-icon> </label>
              </div>

              <ion-textarea [style.height]="item.height ? item.height : ''" *ngIf="item.input_type =='textarea'" [name]="item.name" [disabled]="item.disabled" [value]="item.value" [(ngModel)]="item.value" [formControlName]="item.name" [placeholder]="item.placeholder" [required]="item.required" (ionFocus)="item.focus = true" (ionBlur)="item.focus = false" [class.focus]="item.focus"></ion-textarea>
             
              <span  class="error_txt" *ngIf="submitted && myform.get(item.name).errors">
                <span *ngIf="myform.get(item.name).errors.required"><span>{{item.label ? item.label : item.placeholder }}</span> required</span>  
                <span *ngIf="myform.get(item.name).errors.email"><span>Enter a valid </span>{{item.label ? item.label : item.placeholder}}</span>  
                <span *ngIf="myform.get(item.name).errors.pattern"><span>Enter a valid </span>{{item.label ? item.label : item.placeholder}}</span>
              </span>

              <quill-editor class="text-editor flex-100" *ngIf="item.input_type == 'textEditor'" [formControlName]="item.name"></quill-editor>
              <ng-container  *ngIf="item.input_type == 'dropdown'">
                <ion-select [disabled]="item.disabled"  class="form-input form-select" interface="popover"  [value]="item.value ? item.value  : item.options[0].name"  (ionChange)="Options($event,item)">
                  <ion-select-option *ngFor="let c of item.options ? item.options : [] " value="{{c.name}}">{{c.name}}</ion-select-option>
                </ion-select>

                <!--<ion-select   *ngIf="item.type != 'normal'" (ionFocus)="!item.dropdown_options?get_dropdown(item):null"  interface="popover" [multiple]="item.multiple" [formControlName]="item.name" [placeholder]="item.placeholder" [required]="item.required" (ionFocus)="item.focus = true" (ionBlur)="item.focus = false" [class.focus]="item.focus" (ionChange)="item.dropdown_options?select_value_change($event,item):null">
                    <ion-select-option [value]=""></ion-select-option>
                    <ng-container *ngIf="item.dropdown_options; else no_options;">
                      <ion-select-option *ngFor="let v of item.dropdown_options" [value]="v.text">{{v.text}}</ion-select-option>
                    </ng-container>
                    <ng-template #no_options>
                      <ion-select-option *ngFor="let v of item.options?item.options:[]" >{{v.name}}</ion-select-option>
                    </ng-template>
                </ion-select>-->

              </ng-container>

              <ion-radio-group class="d__flex ion-radio-group" *ngIf="item.input_type == 'Radio_Button'" (ionChange)="Options($event,item)" [value]="item.value ? item.value  : item.options[0].name"  >
                <ion-item lines="none" *ngFor="let data of item.options ? item.options : []" class="no-bg fnt-13">
                    <ion-radio slot="start" [value]="data.name"></ion-radio>
                    <ion-label>{{data.name}}</ion-label>
                </ion-item>
              </ion-radio-group>
  
              <div class="w-100" *ngIf="item.input_type == 'checkbox'">
                <div class="d__flex check_box_line mouse"  *ngFor="let c of item.options ? item.options : []" (click)="item.disabled ? null : checkbox_value(c,item)">
                 <ion-checkbox [disabled]="item.disabled" [checked]="c.check == true"></ion-checkbox >
                 <p class="m-0 p_name">{{c.name}}</p>
                </div>
              </div>

            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>


