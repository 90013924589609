import {
  Component,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-reward-redeem',
  templateUrl: './reward-redeem.component.html',
  styleUrls: ['./reward-redeem.component.scss','../wallet/wallet.component.scss','../orders/orders.component.scss'],
})
export class RewardRedeemComponent implements OnInit {
  no_points = true;
  page_no = 1;
  constructor(public db:DbService) {
    this.get_customer_points();  
   }
 
  ngOnInit() { }

  get_customer_points(){
    var data = { customer:localStorage['customerRefId'], party_type:"Customers"}
    this.db.get_customer_points(data).subscribe(res =>{
      // console.log(res)
      this.db.loyalty_details = res.message;
      if(res.message.total_available_points != 0){
          // this.db.payment_bank.loyalty = true;
          // this.no_info = false
        
          this.get_point_history()
      } else {
          
          // this.no_info = true
          // this.db.payment_bank.loyalty = false;
      }
    })
  }

  
  get_point_history(){
    let data  = { customer: localStorage['customerRefId'], party_type: 'Customers',  page_no: this.page_no,  page_len: 10}
    this.db.get_point_history(data).subscribe(res =>{
      // console.log(res);
      if(res.message.length ){
        this.page_no == 1 ?  this.db.loyalty_details.transactions = res.message : ( this.db.loyalty_details.transactions = [...this.db.loyalty_details.transactions,...res.message])
      } else {
        this.no_points = false;
      }
      
    })
  }
 
  doRefresh(event) {
    this.page_no = 1;
    // console.log('Begin async operation');
    this.get_customer_points();  
    setTimeout(() => { event.target.complete(); }, 700);
  }


  loadData(data) {
    // console.log("loaded datas scrolled");
    if(this.no_points){
      this.page_no = this.page_no + 1;
      this.get_point_history();
    }
    setTimeout(()=>{ data.target.complete() },400);
  }

}
