<div class="container" [class.w320]="db.ismobile" [class.otp_form]="!db.normal_login" [class.web_container]="!db.ismobile && url =='login'">
  <div class="container-desc-header ion-text-center">

    <div class="d__flex gap-[8px]">

      <h1 class="m-0 headerName">Get started</h1>

      <div class="login_header mouse" routerLink="/">
        <img src="assets/imgs/favi_farmlink_L.svg" alt="">
      </div>

    </div>
    <div>
      <!-- routerLink="/web-page/customer-on-boarding" -->
      <p *ngIf="!db.normal_login" class="m-0">Don’t have an account? <span class="mouse user-select alternative"  (click)="db.close_modal()" (click)="db.normal_login?db.normal_login = false:db.normal_login = true" >Sign Up</span></p>
      <!-- <p *ngIf="db.normal_login" class="m-0">Login/ Signup</p> -->
      <!-- db.normal_login?db.normal_login = false:db.normal_login = true -->
      <!-- routerLink="/web-page/customer-on-boarding" -->
    </div>
  </div>
  <div class="w-100" *ngIf="!db.normal_login">
    <app-normal-login></app-normal-login>
  </div>
  <div class="w-100" *ngIf="db.normal_login">
    <app-otp-login></app-otp-login>
  </div>
  <!-- <div routerLink="/" *ngIf="!db.ismobile" class="back_home">
    <ion-button fill="clear"><ion-icon slot="start" name="chevron-back-outline"></ion-icon> Back to Home</ion-button>
  </div> -->
  <div class="ion-text-center login-with">
    <!-- <span> </span> -->
    <span *ngIf="false" class="m-0 ion-text-center">or </span>
    <ul *ngIf="false" class="d__flex just-c">
      <li class="mouse" (click)="db.normal_login?db.normal_login = false:db.normal_login = true">
        <ion-icon [src]="db.normal_login?'/assets/icon/otp.svg':'/assets/icon/mail.svg'"></ion-icon>  {{db.normal_login == false ? 'Signup With OTP' :'Login with Mail'}}
      </li>
      <!-- <li class="mouse" (click)="googleLogin()"><ion-icon src="/assets/icon/google.svg"></ion-icon></li> -->
      <!-- <li class="mouse" (click)="fb_login()"><ion-icon name="logo-facebook"></ion-icon></li> -->
    </ul>
    <div class="login_footer mouse" routerLink="/">
      <img src="/assets/imgs/login_footer.svg" alt="">
    </div>
  </div>
