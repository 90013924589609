import { initializeApp } from 'firebase/app';

export const environment = {
  production: false,
  app_config:{
    app_name : 'Farmlink',
    // domain : "dev.admin.farmlink.in",
    domain : "stage.admin.farmlink.in",
    // domain : "admin.farmlink.in",
    domain_type: "https",
    route : `home-page`,
    businessId : "BS-00001",
    live_url : "",
    captcha : '6Ld0KOklAAAAAEweVhNeNaxrxVVCV1n14KxxOoVv',
    databaseURL: "",
    playstore_id :'com.farmlink'

  },
  firebaseConfig : {
    apiKey: "AIzaSyCWxN1Ohe1QpO-U1yFlQEWyT7fgCMsoiGE",
    authDomain: "districo-tridots.firebaseapp.com",
    projectId: "districo-tridots",
    storageBucket: "districo-tridots.appspot.com",
    messagingSenderId: "714307355892",
    appId: "1:714307355892:web:3987b378f7525c7748caa1",
    measurementId: "G-80S4M0FC5V",
    databaseURL: "https://districo-tridots-default-rtdb.firebaseio.com",
  }
};
export const app  = initializeApp(environment.firebaseConfig)