import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.page.html',
  styleUrls: ['./add-address.page.scss'],
})
export class AddAddressPage implements OnInit {
  @Input() modal;
  @Input() address_type: any;
  @Input() address_info: any = {};

  constructor(public db:DbService,public formBuilder: FormBuilder, public modalCtrl: ModalController) { 

  }

  ngOnInit() {

  	}

}
