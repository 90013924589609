
<div class=" main-width" id="content">

    <!-- vendor_design -->
    <div *ngIf="type == 'vendor' && vendor_info && db.path?.split('/')[1] != 'vendor-products' && false" class="vendor_design" [style.background-color]="bg_color.background_color" [style.background-image]="bg_color.background_image">
         <!-- <p class="vendor_title m-0">Welcome to <span class="span">{{vendor_info.restaurant_name}}</span>'s store</p> -->
        
         <ul *ngIf="!db.ismobile" class="Bread-crumb">
            <li class="d__flex"> <a class="mr-5 mouse" routerLink="/" routerDirection="forward" >Home</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
            <li class="d__flex" *ngIf="bread_crumb"> <a class="mr-5 mouse" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{bread_crumb}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
            <!-- <li class="d__flex" *ngIf="db.childs.child_2_name"> <a class="mr-5 mouse" (click)="breadcrumb('child-2')" [routerLink]="'/c/'+db.childs.child_1+'/'+db.childs.child_2" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_2_name}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
            <li class="d__flex" *ngIf="db.childs.child_3_name"> <a class="mr-5 mouse" [routerLink]="'/c/'+db.childs.child_1+'/'+db.childs.child_2+'/'+db.childs.child_3" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_3_name}}</a></li> -->
        </ul>

         <div class="logo_share just-s d__flex">
             <div class="logo_e_title d__flex">
                <div class="image_logo">
                   <img src="{{db.product_img(vendor_info.restaurant_logo)}}" alt="" defaultImage>
                   <!-- <img src="/assets/icon/favicon.png" alt="" defaultImage> -->
                </div>

                <div class="p-l-10">
                  <h3 class="title webkit-text m-0" [style.--line]="db.ismobile ? 2 : 2">{{vendor_info.restaurant_name}}</h3>
                  <p class="desc m-0">{{vendor_info.city}}<span>,</span>{{vendor_info.state}}</p>
                </div>
             </div>

             <div class="share d__flex">
                 <ng-container *ngFor="let i of share">
                    <a *ngIf="i.title != 'Contact Shop Owner' ;else raise;" class="box d__flex mouse" (click)="goto(i.title)" >
                        <ion-icon [src]="i.icon"></ion-icon>
                         <p *ngIf="!db.ismobile" class="m-0">{{i.title}}</p>
                     </a>
                    <ng-template  #raise>
                        <div *ngIf="db.website_settings && db.website_settings.account_settings && db.website_settings.account_settings.enable_vendor_contact_form == 1"  class="box d__flex mouse" (click)="raiseQuestion()">
                            <ion-icon [src]="i.icon"></ion-icon>
                            <p *ngIf="!db.ismobile" class="m-0">{{i.title}}</p>
                        </div>
                    </ng-template>
                 </ng-container>
             </div>
         </div>
    </div>
    <!-- {{db.path?.split('/')[1]}} -->
    <div class="" *ngIf="db.path?.split('/')[1] != 'brands' && db.path?.split('/')[1] != 'search'">
        <ul *ngIf="!db.ismobile" class="Bread-crumb">
            <li class="d__flex"> <a class="mr-5 mouse" routerLink="/" routerDirection="forward" >Home</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
            <li class="d__flex" *ngIf="bread_crumb"> <a class="mr-5 mouse" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{bread_crumb}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
            <!-- <li class="d__flex" *ngIf="db.childs.child_2_name"> <a class="mr-5 mouse" (click)="breadcrumb('child-2')" [routerLink]="'/c/'+db.childs.child_1+'/'+db.childs.child_2" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_2_name}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
            <li class="d__flex" *ngIf="db.childs.child_3_name"> <a class="mr-5 mouse" [routerLink]="'/c/'+db.childs.child_1+'/'+db.childs.child_2+'/'+db.childs.child_3" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_3_name}}</a></li> -->
        </ul>
        <!-- && vendor_info.business_images.length == 1 -->
        <ion-grid *ngIf="vendor_info && vendor_info.length == 0">
            <div *ngFor="let item of [1]">
              <ion-row class="section_1">
                <ion-col *ngFor="let detail of [1]" class="box_1">
                  <ion-card class="m0 p5 orderstoday animated fadeInUp border-f1">
                    <ion-row>
                      <ion-col col-12>
                        <p class="m-0 p_1">
                          <ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
                        </p>
                      </ion-col>
                    </ion-row>
                  </ion-card>
                </ion-col>
              </ion-row>
            </div>
          </ion-grid>

        <div class="slider_img" *ngIf="vendor_info && vendor_info.business_images.length != 0">
            <div *ngIf="vendor_info.business_images.length == 1">
                <div *ngFor="let img of vendor_info.business_images; let i=index">
                    <img *ngIf="!db.ismobile" src="{{db.product_img(img.business_image)}}" alt="">
                    <img *ngIf="db.ismobile" src="{{db.product_img(img.mobile_image)}}" alt="">
                </div>
            </div>
            <!-- pager="true" -->
            <div *ngIf="vendor_info.business_images.length > 1">
                <div *ngIf="!db.ismobile" class="back"  (click)="slidePrev()"><ion-icon name="chevron-back-outline"></ion-icon></div>
    
                <ion-slides class="mobile-main-image" loop="true" [options]="db.ismobile?mobile_slider:web_slider">
                    <ion-slide *ngFor="let data of vendor_info.business_images; let i=index">
                        <img *ngIf="db.ismobile" src="{{db.product_img(data.mobile_image)}}" defaultImage="" alt="">
                        <img *ngIf="!db.ismobile" src="{{db.product_img(data.business_image)}}" defaultImage="" alt="">
                      <!-- <ion-skeleton-text *ngIf="skeleton &&  !main_image" animated class="m-0 max-height-slide"></ion-skeleton-text> -->
                      <!-- <img src="{{db.product_img(image.product_image)?db.product_img(image.product_image):db.product_img(image.image )}}" alt="" defaultImage> -->
                    </ion-slide>
                </ion-slides>
                <div *ngIf="!db.ismobile" class="next" (click)="slideNext()"><ion-icon name="chevron-forward-outline"></ion-icon></div>
            </div>
        </div>

        <!-- <div *ngIf="false"  class="main-width">
            <div class="position-relative" >
              <div *ngIf="!db.ismobile && item.data" class="back"  (click)="slidePrev()"><ion-icon name="chevron-back-outline"></ion-icon></div>

              <div *ngIf="!db.ismobile && item.data" class="next" (click)="slideNext()"><ion-icon name="chevron-forward-outline"></ion-icon></div>
            </div>
          </div> -->

        <div *ngIf="vendor_info" class="vendor_design d__flex" [class.just-s]="!db.ismobile" [class.just-c]="db.ismobile">
            <div [ngClass]="!db.ismobile?'d__flex con1':'d__flex'">
                <div class="image_logo">
                   <img *ngIf="vendor_info.restaurant_logo" src="{{db.product_img(vendor_info.restaurant_logo)}}" alt="" defaultImage>                   
                </div>
                <div class="p-l-10">
                   <h3 class="title webkit-text m-0 txt__capital" [style.--line]="db.ismobile ? 2 : 2">{{vendor_info.restaurant_name}}</h3>
                   <p class="desc m-0">{{vendor_info.city}}<span *ngIf="vendor_info.state">,</span>{{vendor_info.state}}</p>
                   <div class="share d__flex">
                       <ng-container *ngFor="let i of share">
                        <!-- ;else raise; -->
                          <a *ngIf="i.title != 'Contact Shop Owner'" class="box d__flex mouse" (click)="goto(i.title)" >
                              <ion-icon [src]="i.icon"></ion-icon>
                               <p  class="m-0">{{i.title}}</p>
                          </a>
                       </ng-container>
                       <div class="user txt-c con3 d__flex" *ngIf="db.ismobile">
                            <!-- <p>{{vendor_info.contact_person}}</p> -->
                            <a class="mouse" [class.d__flex]="db.ismobile" *ngIf="db.website_settings && db.website_settings.account_settings && db.website_settings.account_settings.enable_vendor_contact_form == 1" (click)="raiseQuestion()"><ion-icon slot="start" src="/assets/shapes/connect.svg"></ion-icon>{{db.ismobile?"contact":"Contact Shop Owner"}}</a>
                       </div>
                    </div>
                </div>
            </div>
            <div class="d__flex just-c con2" *ngIf="!db.ismobile && vendor_info.business_specifications && vendor_info.business_specifications.length != 0">
                <div class="content" *ngFor="let items of vendor_info.business_specifications">
                    <div>
                        <img src="{{db.product_img(items.icon)}}" alt="" defaultImage>
                        <!-- <img src="/assets/shapes/dispatch.svg" alt=""> -->
                    </div>
                    <p>{{items.content}}</p>
                </div>
                <!-- <div class="content">
                    <div>
                        <img src="/assets/shapes/ratings.svg" alt="">
                    </div>
                    <p>Having most recent views with 4 stars and above</p>
                </div> -->
                <!-- <div class="content">
                    <div>
                        <img src="/assets/shapes/reply.svg" alt="">
                    </div>
                    
                    <p>Has a history of replying message quickly</p>
                </div> -->
    
            </div>
            <div class="user txt-c con3" *ngIf="!db.ismobile" >
                <img *ngIf="vendor_info.restaurant_logo" src="{{db.product_img(vendor_info.restaurant_logo)}}" alt="" defaultImage>
                <img *ngIf="!vendor_info.restaurant_logo" src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" alt="" defaultImage>

                <p>{{vendor_info.contact_person}}</p>
                <a class="mouse" *ngIf="db.website_settings && db.website_settings.account_settings && db.website_settings.account_settings.enable_vendor_contact_form == 1" (click)="raiseQuestion()"><ion-icon slot="start" src="/assets/shapes/connect.svg"></ion-icon>Contact Shop Owner</a>
            </div>
    
        </div>
    </div>

    <div class="d__flex just-a con2" *ngIf="db.ismobile && false">
        <div class="content">
            <div>
                <img src="/assets/shapes/dispatch.svg" alt="">
            </div>
            <p>Fast dispatch</p>
        </div>
        <div class="content">
            <div>
                <img src="/assets/shapes/ratings.svg" alt="">
            </div>
            <p>4 & above stars</p>
        </div>
        <div class="content">
            <div>
                <img src="/assets/shapes/reply.svg" alt="">
            </div>
            
            <p>Quick reply</p>
        </div>
    </div>

    <div class="Tabs d__flex" *ngIf="db.path?.split('/')[1] != 'brands' && db.path?.split('/')[1] != 'search'">
        <h4 class="mouse" (click)="tabs_fun('Shop')" [class.selected]="shop">Shop</h4>
        <h4 class="mouse" (click)="tabs_fun('About')" [class.selected]="about">About</h4>
        <h4 class="mouse" (click)="tabs_fun('Reviews')" [class.selected]="review">Reviews</h4>
        <h4 class="mouse" (click)="tabs_fun('Policies')" [class.selected]="policies">Shop Policies</h4>
    </div>
<!-- 
    <pre>
        {{vendor_info | json}}
    </pre> -->
  <!-- Desktop secondary bar -->
          <div class="container-header d__flex just-s mainwidth" *ngIf="!db.ismobile">
  
              <!-- Desktop Breadcrumb -->
              <ul *ngIf="type != 'vendor'" class="Bread-crumb">
                  <li class="d__flex"> <a class="mr-5 mouse" routerLink="/" routerDirection="forward" >Home</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
                  <li class="d__flex" *ngIf="bread_crumb"> <a class="mr-5 mouse" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{bread_crumb}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
                  <!-- <li class="d__flex" *ngIf="db.childs.child_2_name"> <a class="mr-5 mouse" (click)="breadcrumb('child-2')" [routerLink]="'/c/'+db.childs.child_1+'/'+db.childs.child_2" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_2_name}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
                  <li class="d__flex" *ngIf="db.childs.child_3_name"> <a class="mr-5 mouse" [routerLink]="'/c/'+db.childs.child_1+'/'+db.childs.child_2+'/'+db.childs.child_3" routerDirection="root" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_3_name}}</a></li> -->
              </ul>

             <!-- Desktop Sort Bar -->
             <div class="sort-buttons d__flex" *ngIf="!db.ismobile && type != 'vendor' && false">
                  <div class="d__flex">
                      <div class="txt">Sort by</div>
                      <div class="mouse selected d__flex" (click)="sortby_drop_down?sortby_drop_down = false:sortby_drop_down = true"><span>{{sort == ''?'Relevance':sort_text}}</span>
                          <ion-icon [name]="!sortby_drop_down?'caret-down-outline':'caret-up-outline'"></ion-icon>
                      </div>
                  </div>
                  <ul *ngIf="sortby_drop_down" class="sortby-dropdown" (mouseleave)="sortby_drop_down = false">
                      <li *ngFor="let item of sortings" (click)="sort_text = item.text" (click)="get_cat_products(item.role)" >{{item.text}} </li>
                  </ul>
                  <div class="border" *ngIf="false">
                      <ion-button [ngClass]="(sort == '')?'active':''" (click)="get_cat_products('')" fill="clear">Relevance</ion-button>
                      <ion-button [ngClass]="(sort == 'name_asc')?'active':''" (click)="get_cat_products('name_asc')" fill="clear">Name:A-Z</ion-button>
                      <ion-button [ngClass]="(sort == 'name_desc')?'active':''" (click)="get_cat_products('name_desc')" fill="clear">Name:Z-A</ion-button>
                      <ion-button [ngClass]="(sort == 'price_desc')?'active':''" (click)="get_cat_products('price_desc')" fill="clear">Price:High-Low</ion-button>
                      <ion-button [ngClass]="(sort == 'price_asc')?'active':''" (click)="get_cat_products('price_asc')" fill="clear">Price:Low-High</ion-button>
                  </div>
             </div>
          </div>

     
   <div *ngIf="shop" class="list-container">

  <!-- product list -->
  <!-- && ( category_filters && (category_filters.attribute_list.length != 0 || category_filters.brand_list.length != 0 )) -->
     <!-- *ngIf="!db.ismobile && ( category_filters && (category_filters.attribute_list.length != 0 || category_filters.brand_list.length != 0 || category_filters.category_list.all_categories.length != 0 || category_filters.category_list.child_categories.length != 0 ))" -->
      <div class="side-one"  *ngIf="!db.ismobile && db.website_settings.enable_left_panel == 1 && type == 'vendor'" [style.height]="list_height  + 'px'">           
            <!-- <app-filters [brands]="category_filters"  (filter)="get_cat_products('')" ></app-filters> -->
            <div>
                <div class="category__ttl">Categories</div>
                <div>
                    <a class="d__flex p-10  mouse gap-10 border border-n" *ngFor="let item of (vendor_info && vendor_info.business_category?vendor_info.business_category:[])"  (click)="get_filtered_products(item.category_route)" [class.active_Category]="choosed_product_id == item.category_route" >
                        <!-- {{choosed_product_id}} = {{item.category_route}} -->
                        <div class="category__img" *ngIf="item.mobile_image && db.ismobile"> 
                            <img [src]="db.product_img(item.mobile_image)">
                        </div>
                        <ion-icon [name]="(item.child && item.child.length != 0 )?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
                        <p class="m-0 category__name_child1" >{{item.category_name}}</p>
                        <p class="m-0 count-c" *ngIf="db.ismobile && item.products_count"><small>{{item.products_count}} Items</small></p>
                    </a>
                </div>
                <!-- <app-category-bar (change)="get_cat_products('')"></app-category-bar> -->
            </div>
            <!-- <div *ngIf="type == 'vendor'" class="image_banner mouse">
                <img  src="/assets/icon/vendor/banner.png">
            </div> -->
      </div>


      <!-- {{type}} -->

      <div class="side-two" >

          <!-- <div class="d__flex desktop-filters-row">
              <div>
                  <ion-chip *ngIf="db.choosed_attributes.length != 0 || db.choosed_brands.length != 0" (click)="clearall()">Clear All</ion-chip>
              </div>
              <div *ngFor="let item of db.choosed_attributes; let i = index" class="d__flex">
                  <ion-chip *ngFor="let value of item.value">{{value.replace('_',' ')}} <ion-icon (click)="removeattribute(item.attribute,value)" name="close-outline"></ion-icon></ion-chip>
              </div>
              <div class="d__flex">
                  <ion-chip *ngFor="let item of db.choosed_brands;let i = index">{{item}} <ion-icon (click)="db.choosed_brands.splice(i,1)" (click)="goroute()"   name="close-outline"></ion-icon></ion-chip>
              </div>
              <div *ngIf="db.rating && db.rating != ' '">
                  <ion-chip > <ion-icon class="star-icon" name="star"></ion-icon> Rating {{db.rating}} + <ion-icon (click)="db.rating = ''" (click)="goroute()"   name="close-outline"></ion-icon></ion-chip>
              </div>
          </div> -->

          <div *ngIf="type == 'vendor'" class="search_sort d__flex just-s">

            <div class="search">
              <ion-input placeholder="search product.." [ngClass]="focus.foucs2?'focused':''" (ionChange)="get_search_vendor_products(vendor_info.name)"  name="search" [(ngModel)]="keyword"  [(ngModel)]="keywords__search" (ionBlur)="focus.foucs1 = false">
                <!-- (keyup.enter)="get_search_vendor_products($event)" -->
                    <ion-icon src="/assets/icon/search.svg" class="mobile-search" (click)="get_search_vendor_products(vendor_info.name)"></ion-icon>
              </ion-input>
            </div>
         
            <div class="sort-buttons d__flex" *ngIf="!db.ismobile && is_show_sort">
                <div class="d__flex">
                    <div class="txt">Sort by</div>
                    <div class="mouse selected d__flex" (click)="sortby_drop_down?sortby_drop_down = false:sortby_drop_down = true"><span>{{sort == ''?'Relevance':sort_text}}</span>
                        <ion-icon [name]="!sortby_drop_down?'caret-down-outline':'caret-up-outline'"></ion-icon>
                    </div>
                </div>
                <ul *ngIf="sortby_drop_down" class="sortby-dropdown" (mouseleave)="sortby_drop_down = false">
                    <li *ngFor="let item of sortings" (click)="sort_text = item.text" (click)="get_cat_products(item.role)" >{{item.text}} </li>
                </ul>
           </div>

          </div>
          <div class="side-one"  *ngIf="db.ismobile && db.website_settings.enable_left_panel == 1 && type == 'vendor'">           
            <!-- <app-filters [brands]="category_filters"  (filter)="get_cat_products('')" ></app-filters> -->
            <div>
                <!-- <div class="category__ttl">Categories</div> -->
                <div class="d__flex over-auto">
                    <a class="d__flex p-5 w-nowrap mouse gap-10 border-n" *ngFor="let item of (vendor_info && vendor_info.business_category?vendor_info.business_category:[])"  (click)="get_filtered_products(item.category_route)" [class.active_Category]="choosed_product_id == item.category_route" >
                        <!-- {{choosed_product_id}} = {{item.category_route}} -->
                        <div class="category__img" *ngIf="item.mobile_image && db.ismobile"> 
                            <img [src]="db.product_img(item.mobile_image)">
                        </div>
                        <!-- <ion-icon [name]="(item.child && item.child.length != 0 )?'chevron-down-outline':'chevron-forward-outline'"></ion-icon> -->
                        <p class="m-0 p-5 category__name_child1" >{{item.category_name}}</p>
                        <p class="m-0 count-c" *ngIf="db.ismobile && item.products_count"><small>{{item.products_count}} Items</small></p>
                    </a>
                </div>
                <!-- <app-category-bar (change)="get_cat_products('')"></app-category-bar> -->
            </div>
            <!-- <div *ngIf="type == 'vendor'" class="image_banner mouse">
                <img  src="/assets/icon/vendor/banner.png">
            </div> -->
      </div>

          <div>
              <!-- Mobile Options -->
              <div *ngIf="db.ismobile && false" class="mobile-opts" [class.is_show_sort]="!is_show_sort">

                <div class="mobile-option" >
                    <div class="option" (click)="change_view()">
                        <ion-icon [name]="(db.product_box.view != 'Grid View') ? 'apps-sharp' : 'list-outline'"></ion-icon>
                        <ion-label>{{ (db.product_box.view == 'Grid View') ? 'LIST':'GRID'}}</ion-label>
                    </div>
                    <div *ngIf="is_show_sort" class="option" (click)="select_sort('sort')">
                        <ion-icon name="swap-horizontal-outline"></ion-icon>
                        <ion-label>SORT</ion-label>
                    </div>
                    <div class="option" (click)="select_filter('filter')">
                        <ion-icon name="funnel-outline"></ion-icon>
                        <ion-label>FILTER</ion-label>
                    </div>
                </div>

              </div>

            <app-product-box [data]="brands_products"  (addto_cart)="addtocart($event)" (removeto_cart)="removetocart($event)" (add_wish)="addtowish($event)" (remove_wish)="addtowish($event)" > </app-product-box>

          <ion-infinite-scroll  *ngIf="!no_products" threshold="800px" (ionInfinite)="loadData($event)">
              <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText=""></ion-infinite-scroll-content>
          </ion-infinite-scroll>
      </div>

  </div>


 </div>

    <!-- <p *ngIf="!shop && vendor_info.length ==0" class="txt-c">Sorry, No Product for this seller!</p>  -->

    <div *ngIf="about" class="p-10">         
            <p class="about_restaurant"  [innerHtml]="vendor_info.about_restaurant"></p> 
            <p *ngIf="!vendor_info.about_restaurant" class="txt-c">Sorry, No about details for this seller!</p>    
    </div>
    <div *ngIf="review" class="p-10">
        <div class="review mb-15 p-10 border-bottom" *ngFor="let item of Info">
            <h4 class="m-0 title">{{item.customer}} ({{item.creation | date: 'dd-MM-yyyy'}})   </h4>
            <h4 class="fnt-16">{{item.review_title}}</h4>
            <p class="review-mess" [class.m-0]="db.ismobile" [innerHtml]="item.review_message" ></p>
            <div class="star-rating d__flex" *ngIf="item.rating != 0;else norating;"> <ion-icon class="star" src="/assets/icon/star-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5-(item.rating.toFixed())"></ion-icon> </div>
            <ng-template #norating ><div class="star-rating d__flex"><ion-icon class="star" src="/assets/icon/star-no-rating.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:5"></ion-icon></div></ng-template>
            <div class="d__flex rating_product">
                <img [src]="db.product_img(item.image)" alt="">
                <!--  -->
                <h5 routerLink="/pr/{{item.route}}" class="mouse">{{item.product_name}}</h5>
            </div>
        </div>
        <p *ngIf="Info.length == 0" class="txt-c">Sorry, No reviews yet for this seller!</p>
    </div>
    <div *ngIf="policies" class="p-10">
        <div class="p-10 policies"  *ngFor="let item of vendor_info.policies">
            <h4 class="fnt-18">{{item.policy_name}}</h4>
            <p class="border-bottom" [class.m-0]="db.ismobile" [innerHtml]="item.policy_description" ></p>
        </div>
        <p *ngIf="vendor_info.policies.length == 0" class="txt-c">Sorry, No policies yet for this seller!</p>
    </div>
</div>
<!-- vendor_info && vendor_info.business_images.length != 0 && type == 'vendor'?'oops-icon1_Vendor_page -->
<!-- <pre>{{brands_products | json}}</pre> -->
<div [class]="db.ismobile ? 'oops-icon1': 'oops-icon1 oops-icon1_c_l'" [class.oops-icon1_Vendor_page]="vendor_info && vendor_info.business_images && vendor_info.business_images.length != 0 && type == 'vendor'" [class.oops-icon1_Vendor]="vendor_info && vendor_info.business_images && vendor_info.business_images.length == 0" *ngIf="brands_products && brands_products.length == 0 && no_products && shop">
    <!-- <ion-icon src="/assets/icon/empty_stack_icons/no-product.svg"></ion-icon> -->
    <img *ngIf="db.website_settings && db.website_settings.app_settings" class="icon" button  [src]="db.product_img(db.website_settings.app_settings.nofound_img)">
    <h6 class="m-0 fnt-12 oops-text">No Product  Found!</h6>
    <p>Sorry, there’re no products for this search!</p>
</div>