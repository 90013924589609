import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
})
export class AppliedFiltersComponent implements OnInit {
  @Input() category_filters;
  @Output() get_cat_products = new EventEmitter();
  constructor(public db:DbService,private location:Location,private router:Router) { }

  ngOnInit() {}


  clearall(){
    this.db.choosed_brands = [];
    this.db.choosed_attributes = [];
    this.db.rating = ''
    this.location.replaceState(location.pathname);
    this.db.remove_brands.next('get');
    this.get_cat_products.emit('');
    // this.router.navigate([location.pathname]);
  }


  removeattribute(attributes,attribute){
    // console.log(this.db.choosed_attributes);
    // console.log(attributes)
    // console.log(attribute)
    let attribute_uniques:any = [];
    let value:any;
      this.category_filters.attribute_list.map( value =>{
        if(attributes == (value.attribute_unique_name ? value.attribute_unique_name : value.attribute)){
            value.options.map(res =>{
                if(attribute == (res.unique_name ? res.unique_name : res.option_value)){
                  res.choosed = 0
                  res.checked = false;
                } 
                if(res.choosed == 1){
                  attribute_uniques.push(res.unique_name);
                }
            })
        }
      })

      value = { "attribute": attributes, "value": attribute_uniques };

      this.db.choosed_attributes.find((res:any,index)=>{
        if(res && res.attribute == attributes){ 
          this.db.choosed_attributes.splice(index,1)
        }else if( res = undefined){
          this.db.choosed_attributes.splice(index,1)
        }
      })

      if(attribute_uniques.length != 0){
        this.db.choosed_attributes.push(value)
      }
      this.get_cat_products.emit('');

    //  console.log(this.db.choosed_attributes);

  }



  goroutes(value,i){

    this.category_filters.brand_list.map(res =>{
              if(value == res.unique_name){
                // res.choosed = 0
                res.checked = false;
              } 
    })

    this.db.choosed_brands.splice(i,1)

    // console.log(this.db.choosed_brands);

    if(this.db.choosed_brands.length != 0){
      this.router.navigate([location.pathname], { queryParams: { f : this.db.choosed_brands.toString()} });
    }else{
      this.router.navigate([location.pathname]);
      // this.router.navigate(['/users'], { queryParams: { filter: 'new' }, queryParamsHandling: 'merge' })
    }
  }

  goroute(){

    if(this.db.choosed_brands.length != 0){
      this.router.navigate([location.pathname], { queryParams: { f : this.db.choosed_brands.toString()} });
    }else{
      this.router.navigate([location.pathname]);
      // this.router.navigate(['/users'], { queryParams: { filter: 'new' }, queryParamsHandling: 'merge' })
    }
  }


}
