<div class="oops-icon1" *ngIf="brands && brands.brand_list && (brands.attribute_list.length == 0 && brands.category_list.all_categories.length == 0 && brands.category_list.child_categories.length == 0 )">
  <!-- <ion-icon src="/assets/icon/empty_stack_icons/no-filter.svg"></ion-icon> -->
  <img *ngIf="db.website_settings && db.website_settings.app_settings" class="icon" button [src]="db.product_img(db.website_settings.app_settings.no_filters)">
  <!-- <h6 class="m-0 fnt-12 oops-text">No Filters For This Items Found!</h6> -->
  <p class="m-0 fnt-12 oops-text">Sorry, there’re no filters for this items !</p>
</div>
<div class="category-container" *ngIf="false">
  <div *ngIf="!db.childs.child_2 && !db.childs.child_1">
    <p class="m-0 head"></p>
    <ul class="categories">
      <li class="mouse category-item category__name" *ngFor="let item of db.category" [ngClass]="(db.choosed_product_id == item.name)?'active-item':''" (click)="goforward(item.name)">{{item.category_name}}</li>
    </ul>
  </div>
  <div *ngIf="brands.category_list.parent_category">
    <p class="m-0 head">{{brands.category_list.parent_category.category_name}}</p>
    <ul class="categories">
      <li class="mouse category-item category__name" *ngFor="let item of brands.category_list.child_categories" [ngClass]="(db.choosed_product_id == item.name)?'active-item':''" (click)="goforward(item.name)">{{item.category_name}}</li>
    </ul>
  </div>
  <div *ngIf="brands.category_list.parent_category_child && brands.category_list.child_categories.length == 0">
    <!-- <p class="m-0 head" >{{brands.category_list.current_category.category_name}}</p> -->
    <ul class="categories">
      <li class="mouse category-item category__name" *ngFor="let item of brands.category_list.parent_category_child" [ngClass]="(db.choosed_product_id == item.name)?'active-item':''" (click)="goforward(item.name)">{{item.category_name}}</li>
    </ul>
  </div>
</div>
<!-- <div *ngIf="!ismobile">
    <app-category-bar (change)="filter.emit()"></app-category-bar>
</div> -->
<div class="container_div" *ngIf="brands">
  <div #brands_container class="brands-container" *ngIf="db.website_settings && db.website_settings.disable_brand_filter == 0 && (brands && brands.brand_list && brands && brands.brand_list.length != 0)">
    <p class="m-0 head">Brands</p>
    <ul class="brands mouse">
      <li class="brand-item d__flex" *ngFor="let item of brands.brand_list" (click)="choose_brands(item)">
        <!-- <div><ion-checkbox [checked]="db.choosed_brands.includes(item.option_value)"></ion-checkbox></div> -->
        <!-- <div> -->
          <ion-checkbox [checked]="item.checked"></ion-checkbox>
        <!-- </div> -->
        <p class="m-0 fnt-15 category__name">{{item.brand_name}} <span class="item-count">({{item.item_count}})</span></p>
      </li>
    </ul>
  </div>
  <div *ngIf="db.website_settings && db.website_settings.disable_brand_filter == 0 && brands">
    <div id="attributes-container" class="attributes-container" *ngFor="let item of brands.attribute_list">
      <p class="m-0 head">{{item.attribute}}</p>
      <ul class="attributes mouse">
        <!-- (click)="( value.choosed && value.choosed == 1)?value.choosed = 0:value.choosed = 1" -->
        <li class="brand-item d__flex" *ngFor="let value of item.options" (click)="choose_attributes(item,value)">
          <!-- <div><ion-checkbox [checked]="value.choosed == 1"></ion-checkbox></div> -->
          <!-- <div> -->
            <ion-checkbox [checked]="value.checked"></ion-checkbox>
          <!-- </div> -->
          <p class="m-0 brand-item fnt-15 category__name">{{value.option_value}} <span class="item-count">({{value.item_count}})</span></p>
        </li>
      </ul>
    </div>
  </div>
  <div class="ratings-container" *ngIf="false && db.website_settings && db.website_settings.disable_ratings_filter == 0">
    <p class="m-0 head">Ratings</p>
    <ion-radio-group [(ngModel)]="db.rating">
      <ul class="ratings mouse">
        <li class="rating d__flex" *ngFor="let item of stars" (click)="db.rating = item.value" (click)="filter.emit()">
          <ion-radio [value]="item.value"></ion-radio>
          <p *ngIf="item.icon" class="m-0 brand-item fnSt-15 category__name">
            <ion-icon class="star-icon" *ngFor="let s of item.icon" [name]="s"></ion-icon> <span *ngIf="item.title"> {{item.title}}</span>
          </p>
        </li>
      </ul>
    </ion-radio-group>
  </div>
  <div class="mini-max-container" *ngIf="db.website_settings && db.website_settings.disable_price_filter == 0">
    <p class="m-0 head">Price</p>
    <div class="mini-max d__flex gap-5">
      <ion-input class="fnt-14" placeholder="Min" [(ngModel)]="db.minimax_price.mini_price"></ion-input>
      <ion-input class="fnt-14" placeholder="Max" (keyup.enter)="filter.emit($event)" [(ngModel)]="db.minimax_price.max_price"></ion-input>
      <ion-button class="fnt-14" fill="clear" (click)="filter.emit($event)">GO</ion-button>
    </div>
  </div>
</div>
