<!-- <div class=""> -->
     <!-- <div class="tabs_container d__flex" [class.nobackground]="nobackground == 'nobackground'" [class.tabs_width]="type == 'kanban-report'" [class.full_width]="type == 'my_job'">
        <ion-button [disabled]="tab.disabled" fill="clear" *ngFor="let tab of tabs_array;let i=index" class="tab_button fnt-inter"[class.tab_btn2]="nobackground == 'nobackground'"[class.active]="tab.isActive" (click)="check_active(tabs_array,i,tab)" > {{tab.name}} </ion-button>
    </div>    -->
<!-- </div> -->

<!-- <div class="tabs"> -->
  <ul [class.sticky]="sticky" class="d__flex box_" [ngStyle]="{'padding' : padding ? padding: null}" [class.border_none]="border_none">
    <li *ngFor="let tab of tabs_array;let i=index" [id]="i + 1 + 'scroll'" [class.active_container]="tab.isActive" class="container mouse" (click)="check_active(tabs_array,i,tab)">
      <label class="label d__flex" role="button">
        <span class="title mouse">{{tab.name}}</span>
        <span *ngIf="tab.count" [ngStyle]="{'background':'#E7EEF6','color':'#7C7C7C'}" class="count_label">{{tab.count}}</span>
        <div class="slider">
          <div class="slider_indic"></div>
        </div>
      </label>
    </li>
  </ul>

<!-- </div> -->
