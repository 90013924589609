import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { RegisterPage } from 'src/app/pages/register/register.page';
import { ReviewProductPage } from 'src/app/pages/review-product/review-product.page';
import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  @Input() product_info;
  product_detail :any = {};
  related_products = [];
  recently_viewed = [];
  
  product_route;
  skeleton = true;
  main_image;
  showmore :any ={}
  buynow_clicked = false;
  variant_error = false;
  variant_error_shaking = false;
  modal_popup = true;
  constructor(public db:DbService,public router:Router,public modalCtrl:ModalController,private route:ActivatedRoute) {
    this.product_route = this.route.snapshot.paramMap.get('product'); 
    // console.log(this.product_route)
    this.showmore.showmore1 = true;
    setTimeout(() => { this.skeleton = false;  }, 850);
   }

  ngOnInit() {
    this.product_info ?  this.db.scroll_event.desktop_header = false :  this.db.scroll_event.desktop_header = true  
    this.db.ismobile = this.db.checkmobile();


        this.get_product_detail();

    this.db.mycart_emit.subscribe(res => {
      // console.log("res",res)
      res == 'getted' ? this.get_recommeded() : null  
      if(this.buynow_clicked){
        this.db.ismobile ?  this.router.navigateByUrl('/yourcart') : this.router.navigateByUrl('/checkout');
        this.buynow_clicked = false;
      }
    })


    this.db.alert_animate.subscribe(res =>{
      this.variant_error = true;
      this.variant_error_shaking = true;
      setTimeout( () => {  this.variant_error_shaking = false},700);
    })
  }


  get_product_detail() {
    // let data = { Product: this.product_route , isMobile:1 }
      let data = {
         route: this.product_route,
         customer : this.db.cust_name ? localStorage['customerRefId']: '',
         center: localStorage['CenterName']
      }
      if(this.product_info){
          this.product_detail = this.product_info
          this.check_image();
          this.get_cart_item();
      }  else { 
          this.db.get_product_details(data).subscribe(res => {
              this.store_product_detail(res)
        })
      }
  } 

  store_product_detail(res){
    this.product_detail = res.message;
    this.related_products = this.product_detail.related_products;
    this.recently_viewed = this.product_detail.products_purchased_together;
    this.check_image();
    this.get_cart_item();
    this.product_detail.item_categories && this.product_detail.item_categories.name ?   this.db.childs.child1 = this.product_detail.item_categories.name: null
    // console.log("product detail",this.product_detail);
  } 

  get_recommeded() {
    // console.log("getting recomeded calling...")
    this.variant_error ? this.variant_error = false : null
    this.product_detail.temp_disabled = false;
    this.product_detail.count = this.db.checkcart(this.product_detail.name);
    this.product_detail.wish_count = this.db.checkWishCart(this.product_detail.name);
    // console.log("checking cart count..",this.product_detail.count)
    if(this.product_detail.product_attributes && this.product_detail.product_attributes.length != 0){
      if(this.product_detail.show_price != 0){
        this.check_attribute_cart();
      } else if(this.product_detail.show_price == 0){
        this.get_selected_table_attribute()
      }
    }

    if(this.related_products){
      this.related_products.map((res:any) => {
        res.count = this.db.checkcart(res.name)
        res.wish_count = this.db.checkWishCart(res.name)
      })
    }

    if(this.recently_viewed){
      this.recently_viewed.map((res:any) => {
        res.count = this.db.checkcart(res.name)
        res.wish_count = this.db.checkWishCart(res.name)
      })
    }
  }


  get_cart_item(){
    this.db.get_cart_item();
  }


  async addtocart(value,type) {  
    // console.log(value);
   
    if(type == 'shopping'){

       if(value.count == 0) {

          if(this.product_detail.product_attributes && this.product_detail.product_attributes.length == 0) {
              value.product_attributes && value.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+value.route) : this.db.addtocart(value)
              // this.db.addtocart(value);
          } else {
            this.check_all_attributes_selected(type,value)
            // this.product_detail.ids ? this.db.addtocart(value) : this.db.alert_animate.next("Please select attributes..!")
          }

        } else {
          this.db.addtocart(value);
          // value.product_attributes && value.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+value.route) : this.db.addtocart(value)
          // this.db.update_cart(value);
        } 

        
    } else if(type == 'buy_now'){
      this.buynow_clicked = true;
      if(value.count == 0) {

         if(this.product_detail.product_attributes && this.product_detail.product_attributes.length == 0) {
             value.product_attributes && value.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+value.route) : this.db.addtocart(value)
             // this.db.addtocart(value);
         } else {
           this.check_all_attributes_selected(type,value)
           // this.product_detail.ids ? this.db.addtocart(value) : this.db.alert_animate.next("Please select attributes..!")
         }

       } else {
         this.db.addtocart(value);
         // value.product_attributes && value.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+value.route) : this.db.addtocart(value)
         // this.db.update_cart(value);
       } 

       
   }
    
    else if(type == 'wishlist'){
      if(value.wish_count != 0){
        this.db.removewish(value)
      } else if(value.wish_count == 0){
        if(this.product_detail.product_attributes.length == 0) {
          this.db.addtowish(value);
        } else {
          this.check_all_attributes_selected(type,value)
          // this.product_detail.ids ?   this.db.addtowish(value) : this.db.alert_animate.next("Please select attributes..!")
        }
         
      }
    }
  }


  check_all_attributes_selected(type,value){
    let ids = this.product_detail.ids.split('\n');
    ids = ids.filter(res => { return res != ''})
    if(ids.length == this.product_detail.product_attributes.length ){
          type == ('shopping' ||'buy_now') ? this.db.addtocart(value) : this.db.addtowish(value)
    } else {
          this.db.alert_animate.next("Please select All attributes..!")
    } 
  }

  show_more(button) {
    if(button == 'showmore1'){
      this.showmore.showmore1 ?  this.showmore.showmore1 = false : this.showmore.showmore1 = true
    } else  if(button == 'showmore2'){
      this.showmore.showmore2 ?  this.showmore.showmore2 = false : this.showmore.showmore2 = true
    } else  if(button == 'showmore3'){
      this.showmore.showmore3 ?  this.showmore.showmore3 = false : this.showmore.showmore3 = true
    } else  if(button == 'showmore4'){
      this.showmore.showmore4 ?  this.showmore.showmore4 = false : this.showmore.showmore4 = true
    }else  if(button == 'showmore5'){
      this.showmore.showmore5 ?  this.showmore.showmore5 = false : this.showmore.showmore5 = true
    }
  }

  removetocart(value) {
    // value.product_attributes && value.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+value.route) :  this.db.removetocart(value)
    this.db.removetocart(value)
  }

  //check active radio 
  check_active_radio(options){
    // console.log(options)
    let temp = ''
      options.map(res =>{
          if(res.is_pre_selected == 1){
            
              temp = res.name
          }
      })
      return temp;
  }

  select_dropdown(event,type){
    this.select(type,event.detail.value) 
  }

  select_radio(event,type){
    this.select(type,event.detail.value)
  }


  //TAble attribute
  get_selected_table_attribute(){
    this.product_detail.product_attributes[0].options.map(value =>{
        if(!value.ids){

          let ids = ''
          let separate_ids = ''
          let selected_attribute = ''
          let separate_selected_Attribute = ''
    
            separate_ids += value.name+'\n';
            separate_selected_Attribute += ' <div class="attribute"><span class="attr-title">'+this.product_detail.product_attributes[0].attribute+'</span> : <span>'+value.option_value+'</span> </div>'

            this.product_detail.other_attributes.map(res =>{
              res.options.map(option=>{
                option.is_select_parent = value
                if(option.is_pre_selected == 1){
                  ids += option.name+'\n';
                  selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+option.option_value+'</span> </div>'
                }
              })
            })
    
            ids = separate_ids + ids
            selected_attribute = separate_selected_Attribute + selected_attribute
    
          
            value.ids = ids;
            value.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
            value.count =  this.check_attribute(ids);
            // console.log("selecetd count  "+value.count  )
            value.selected_cart_id = this.check_cart_id(ids);

            var data = {
              "product": this.product_detail.name,
              "attribute_id":  value.ids,
              "cart_qty": this.db.plusCount(value.count),
              "variant_html" : value.selected_attribute
            }
        
              this.db.validate_attributes_stock(data).subscribe(data => {
                if(data.message && data.message.status == "Success"){
                  value.stock = data.message.stock
                  value.price = data.message.price,
                  value.old_price = data.message.old_price
                  value.discount_label = data.message.discount.discount_label
                  value.discount_rule = data.message.discount.discount_rule
                  value.stock_alert_message = undefined
                  this.product_detail.stock_alert_message = undefined
                }else if(data.message && data.message.status == 'Failed'){
                  value.price = data.message.price,
                  value.old_price = data.message.old_price
                  value.stock_alert_message = data.message.message
                  this.product_detail.stock_alert_message = data.message.message
                }
              })
    
        } else {
              value.count =  this.check_attribute(value.ids);
              value.selected_cart_id = this.check_cart_id(value.ids);
        }

    })
  }


  select(type,attribute){
      this.product_detail['product_attributes'].find(res => {
        if(res.attribute == type){
          res.options.map(d =>{ 
            if(d.name == attribute){
              d.is_pre_selected = 1
              if(d.images && d.images.length != 0 && res.control_type != 'Radio Button List'){
                  this.product_detail.images = d.images;  
                  this.check_image()
              }
            }else{
              d.is_pre_selected = 0
            }
          })
        }
      })
      this.check_attribute_cart();
      // this.check_attribute_stock();
  }


  check_attribute_cart(){  
    let ids = '';
    let selected_attribute ='';
    let selected_cart_id =''
    let selected_cart_count = ''
    this.product_detail['product_attributes'].find(res => {
      res.options.map(data =>{  
          data.iscart = this.check_attribute(data.name+'\n');
        if(data.is_pre_selected == 1){   
          ids+=data.name+'\n';
          selected_cart_id = this.check_cart_id(ids);
          selected_cart_count = data.iscart
          selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+data.option_value+'</span> </div>'
        } 
      })
    })
    this.product_detail.selected_cart_id = selected_cart_id
    this.product_detail.ids = ids;
    this.product_detail.count = selected_cart_count;
    this.product_detail.product_attributes.length > 1 ? this.product_detail.count =  this.check_attribute(this.product_detail.ids) : null 
    this.product_detail.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
    this.check_attribute_stock();
  }


  check_attribute_stock(){
      var data = {
        "product": this.product_detail.name,
        "attribute_id": this.product_detail.ids,
        "cart_qty": this.db.plusCount(this.product_detail.count),
        "variant_html" : ''
      }
      this.db.validate_attributes_stock(data).subscribe(res => {
        if(res.message){
          // this.product_detail.inventory_method = 'Track Inventory'
          if( res.message.status == "Success"){
            this.product_detail.stock = res.message.stock
            this.product_detail.price = res.message.price,
            this.product_detail.old_price = res.message.old_price
            this.product_detail.discount_label = res.message.discount.discount_label
            this.product_detail.discount_rule = res.message.discount.discount_rule
            this.product_detail.stock_alert_message = undefined
          }else if( res.message.status == 'Failed'){
            this.product_detail.stock = res.message.stock
            this.product_detail.price = res.message.price,
            this.product_detail.old_price = res.message.old_price
            this.product_detail.stock_alert_message = res.message.message
          }
        }
      })
  }

  
  check_image(){
    this.main_image = '';
      if(this.product_detail.images){
        this.main_image =  this.check_active_image(this.product_detail.images);
        console.log(this.main_image);
      }else{
        this.main_image = this.product_detail.product_image
        console.log(this.main_image);
      }
  }


  check_active_image(images){
      let temp = ''
      if(images.length != 0){
          images.map(res=>{
            if(res.is_primary == 1){        
              res.detail_image ?  temp = res.detail_image :  temp = res.product_image
            }
        })
      }
      return temp
  } 

  check_attribute(id){
    var cnt = 0;
    this.db.marketplace_items.find( res =>{
      // console.log(id + " ==== " + res.attribute_ids)
      if(res.attribute_ids == id){
        cnt += res.quantity
      }
    })
    return cnt;
  } 

  check_cart_id(id){
    let cart_id =''
    this.db.marketplace_items.find( res =>{
      if(res.attribute_ids == id){
        if(res.quantity != 0){
          cart_id = res.name
        }
      }
    })
    return cart_id;
  }

  async openReview(product_id,type){
    if(this.modal_popup && this.db.cust_name){
      const modal = await this.modalCtrl.create({
        component :ReviewProductPage,
        cssClass: 'review-poup',
        componentProps:{
          product_id : product_id,
          type: type
        }
      })
      this.db.modal = true;
      this.modal_popup = false;
      await modal.present();
      await modal.onWillDismiss().then( res =>{
        this.modal_popup = true;
        this.db.modal = false;
        res.role != "backdrop" ? this.get_product_detail() : null
       });
    } else if(this.modal_popup && !this.db.cust_name){
        this.check_login(product_id,type)
    }
  }

  async check_login(product_id,type) {
    if (this.modal_popup && !this.db.cust_name){
          this.modal_popup = false;
          const modal = await this.modalCtrl.create({
            component: RegisterPage,
            cssClass: 'auth-popup',
            componentProps: {
              type: 'login'
            }
          })
          this.db.opened_login_modal = true;
          await modal.present();
          await modal.onWillDismiss().then( res =>{
            this.modal_popup = true;
            this.db.cust_name ? this.openReview(product_id,type) : null
           });
      } 
  }
  

  related_addtocart(data){
    (this.product_detail.product_attributes && this.product_detail.product_attributes.length == 0 )? this.addtocart(data,'shopping') : this.router.navigateByUrl('/pr/'+data.route)
  }
  
  related_removetocart(data){
    (this.product_detail.product_attributes && this.product_detail.product_attributes.length == 0 )? this.removetocart(data) : this.router.navigateByUrl('/pr/'+data.route)
  }

  addtowish(value){
    if(value.wish_count != 0){
      this.db.removewish(value)
    } else if(value.wish_count == 0){
      if(value.product_attributes.length == 0) {
        this.db.addtowish(value);
      } else {
        this.db.ismobile? this.router.navigateByUrl('/pr/'+value.route) : this.router.navigateByUrl('/pr/'+value.route)
          // this.db.ismobile? this.router.navigateByUrl('/pr/'+value.route) : this.openQuickView(value)
        // this.product_detail.ids ?   this.db.addtowish(value) : this.db.alert_animate.next("Please select attributes..!")
      }
    }
  }

} 
