    <div>
      <!-- Testimonial one -->
       <div class="main_container align-center {{item.class_name}}" *ngIf="item.section_type == 'Custom Section' && item.section_name =='Testimonials Style 1'">
  
        <div class="position-relative">
          <div class="creative_header">
            <h1 class=" align_center fnt-40"><span class="fnt-500 purple_color title">{{item.title}}</span></h1>
          </div>
          <!-- <div *ngIf="!db.ismobile" class="back_btn"  (click)="slidePrev()"><ion-icon name="chevron-back-outline"></ion-icon></div> -->
          
          <ion-slides class="web-slider mouse testimonial_slides" pager="true" loop="true" [options]="db.ismobile?mobile_slider:web_slider_one">
            <ion-slide *ngFor="let data of item.data;let i=index">
              <!-- <div class="d__flex testimonial_card_div"> -->
                 <div class="testimonial_card">
                    <div class="testimonial_card_image txt-l">
                        <img [src]="db.product_img(data.image)" alt="">
                    </div>
                    <div class="testimonial_details">
                        <div class="testimonial_name">{{data.title}}</div>
                        <div class="ourteam_three_role">{{data.posted_by}}</div>
                        <div class="testimonial_content webkit-text" [style.--line]="5">{{data.content}}</div>
                        <div>
                          <ion-icon color="warning" name="star"></ion-icon>
                          <ion-icon color="warning" name="star"></ion-icon>
                          <ion-icon color="warning" name="star"></ion-icon>
                          <ion-icon color="warning" name="star"></ion-icon>
                          <ion-icon color="warning" name="star"></ion-icon>
                        </div>
                    </div>
                  </div>
              <!-- </div> -->
              
            </ion-slide>
          </ion-slides>
     
          <!-- <div *ngIf="!db.ismobile" class="next_btn" (click)="slideNext()"><ion-icon name="chevron-forward-outline"></ion-icon></div> -->
        </div>
      </div>
     
         <!-- Testimonial two -->
         <div class="main_container align-center {{item.class_name}}" *ngIf="item.section_type == 'Custom Section' && item.section_name =='Testimonials Style 2'">
           <div class="position-relative" >
             <div class="creative_header">
               <h1 class="fnt-600 align_center"><span class="purple_color title">{{item.title}}</span></h1>
             </div>
             <div class="flex_box testimonialtwo_cards">
               <div *ngFor="let data of item.data;let i=index" class="testimonialtwo_card">
                 <div class="testimonial_details">
                   <div>
                     <ion-icon color="warning" name="star"></ion-icon>
                     <ion-icon color="warning" name="star"></ion-icon>
                     <ion-icon color="warning" name="star"></ion-icon>
                     <ion-icon color="warning" name="star"></ion-icon>
                     <ion-icon color="warning" name="star"></ion-icon>
                   </div>
                   <div class="testimonialtwo_content webkit-text" [style.--line]="5">{{data.content}}</div>               
                 </div>
               <div class="flex_box testimonialtwo_profile">
                 <div class="testimonialtwo_card_image">
                   <img [src]="db.product_img(data.image)" alt="">
                 </div>
                 <div>
                   <div class="testimonial_name">{{data.title}}</div>
                   <div class="ourteam_three_role">{{data.posted_by}}</div>
                 </div>
               </div>
              </div>
             </div>
           </div>
         </div>
           <!-- testimonial six -->
  <div  class="testimonialsix_container align_center" *ngIf="item.section_type == 'Custom Section' && item.section_name =='Testimonials Style 1'">
    <div class="sliders">
      <div class="testimonialsix_header">
        <div>
          {{item.title}}
        </div>
        <div>
          <ion-button (click)="swipe_back()" class="slide_btn" size="small" fill="clear"><ion-icon name="chevron-back-outline"></ion-icon></ion-button>
          <ion-button (click)="swipe_next()" class="slide_btn" size="small" fill="clear"><ion-icon name="chevron-forward-outline"></ion-icon></ion-button>
        </div>
      </div>
      <ion-slides class="web-slider mouse testimonial_slides" loop="true"
      [options]="db.ismobile?mobile_slider:web_slider_one" #testimonialSix>
        <ion-slide *ngFor="let data of item.data">
          <div class="testimonialThree_card">
            <div class="image_position">
              <div class="testimonialThree_image">
                <img [src]="db.product_img(data.image)" alt="">
            </div>
            </div>
                <div class="testimonial_details">
                    <div class="mg_10 name">{{data.name}}</div>
                    <div class="mg_10 role">{{data.posted_by}}</div>
                    <div class="mg_30 content webkit-text" [style.--line]="5">{{data.content}}</div>
                    <div class="mg_30">
                      <ion-icon color="warning" name="star"></ion-icon>
                      <ion-icon color="warning" name="star"></ion-icon>
                      <ion-icon color="warning" name="star"></ion-icon>
                      <ion-icon color="warning" name="star"></ion-icon>
                      <ion-icon color="warning" name="star"></ion-icon>
                    </div>
                </div>
              </div>
        </ion-slide>
      </ion-slides>
    </div>
  </div>
  <!-- testimonial Five -->
  <div class="testimonialFive_container align_center" *ngIf="item.section_type == 'Custom Section' && item.section_name =='Testimonials Style 2'">  
    <ion-slides class="web-slider mouse testimonial_slides"  loop="true" [options]="db.ismobile?mobile_slider:web_slider_two" #testimonialFive>
    <!-- <ion-slide *ngFor="let data of testimonialThree.data">
      <div class="testimonialThree_card">
        <div class="testimonialThree_image">
          <img [src]="data.image" alt="">
        </div>
        <div class="testimonial_col2">
          <div class="col2_content">
            <div class="command">
              {{data.command}}
            </div>
            <div class="mg_40 content" [style.--line]="5">
              {{data.content}}
            </div>
            <div class="testimonialFive_info mg_40">
              <div>
                <div class="name">
                  {{data.name}}
                </div>
                <div class="mg_10 role">
                  {{data.role}}
                </div>
              </div>
              <div>
                <ion-icon (click)="slideFive_back()" name="arrow-back-outline"></ion-icon>
                <ion-icon (click)="slideFive_next()" name="arrow-forward-outline"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-slide> -->
    </ion-slides>
    <ion-button (click)="slideFive_back()" size="small" fill="clear"><ion-icon name="chevron-back-outline"></ion-icon></ion-button>
    <ion-button (click)="slideFive_next()" size="small" fill="clear"><ion-icon name="chevron-forward-outline"></ion-icon></ion-button>
  </div>    
<!--  testimonial four-->
<div class="testimonialFour_container align_center" *ngIf="item.section_type == 'Custom Section' && item.section_name =='Testimonials Style 2'">
  <!-- <div class="title">
    {{testimonialThree.title}}
  </div> -->
  <div class="line">
  </div>
  <ion-slides class="web-slider mouse testimonial_slides"  loop="true" [options]="db.ismobile?mobile_slider:web_slider_two"> 
  <!-- <ion-slide *ngFor="let data of testimonialThree.data">
    <div class="testimonialThree_card">
      <div class="testimonialThree_image">
        <img [src]="data.image" alt="">
      </div>
      <div class="testimonial_col2">
        <div class="command">
          "{{data.command}}"
        </div>
        <div class="mg_40 content" [style.--line]="5">
          {{data.content}}
        </div>
        <div class="mg_40 name">
          {{data.name}}
        </div>
        <div class="mg_10 role">
          {{data.role}}
        </div>
      </div>
    </div>
  </ion-slide> -->
  </ion-slides>
</div>
  <!-- testimonial three-->

  <div class="testimonialThree_container align_center" *ngIf="item.section_type == 'Custom Section' && item.section_name =='Testimonials Style 2'">
    <!-- <div class="title">
      {{testimonialThree.title}}
    </div> -->
    <!-- <div class="subtitle">
      {{testimonialThree.subtitle}}
    </div> -->
    <ion-slides class="web-slider mouse testimonial_slides" pager="true" loop="true" 
    [options]="db.ismobile?mobile_slider:web_slider_one">
    <!-- <ion-slide *ngFor="let data of testimonialThree.data">
      <div class="testimonialThree_card">
        <div class="testimonialThree_image">
          <img [src]="data.image" alt="">
        </div>
        <div class="mg_40 command">
          "{{data.command}}"
        </div>
        <div class="mg_10">
          <ion-icon color="warning" name="star"></ion-icon>
          <ion-icon color="warning" name="star"></ion-icon>
          <ion-icon color="warning" name="star"></ion-icon>
          <ion-icon color="warning" name="star"></ion-icon>
          <ion-icon color="warning" name="star"></ion-icon>
        </div>
        <div class="mg_10 content" [style.--line]="5">
          {{data.content}}
        </div>
        <div class="mg_40 name">
          {{data.name}}
        </div>
        <div class="mg_10 role">
          {{data.role}}
        </div>
      </div>
    </ion-slide> -->
    </ion-slides>
  </div>
    </div>