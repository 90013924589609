import {
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

import {
  ModalController,
  Platform,
} from '@ionic/angular';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['../login/login.page.scss','./register.page.scss'],
})
  
export class RegisterPage implements OnInit {
  background_image='/assets/imgs/login-bg.jpg'
  constructor(public db:DbService,private router:Router,private platform:Platform,public modalCtrl:ModalController) {
    
  }

  ngOnInit() {
    this.db.ismobile = this.db.checkmobile();
  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.db.checkmobile();
  }
     
  img_style(data, type) {
    if (type == 'color') 
      return { 'background': data };
     else if (type == 'img') 
      return { 'background': 'url(' + this.db.product_img(data) + ') no-repeat'};
  }

  ionViewDidLeave(){
    this.db.otp_form = false;
  }

}

