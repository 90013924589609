import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss','../orders/orders.component.scss'],
})

export class WalletComponent implements OnInit {
  
  @Input() load;
  page_no = 1;
  // no_info = false;
  
  // temp = false;
  // wallet_amount ;
  constructor(public db:DbService,private alertCtrl:AlertController) {
      // this.temp = false;
      // this.db.get_website_settings();
      this.db.get_wallet_detail(this.page_no);
      // this.db.get_razorpay_settings();
   }


  ngOnInit() {
    this.db.ismobile = this.db.checkmobile();
    // if(){
        // if(this.load){

        // }  else{
        //   this.get_wallet_details();
        //   this.db.get_razorpay_settings();
        // }
    // }
    // if(!this.temp){
    //   this.db.razorpay_payment.subscribe(res =>{
    //       this.temp = true;
    //       console.log(this.temp)
    //     // this.tempy = res['razorpay_payment_id']
    //     if( res['description'] == 'Insert Wallet Amount' && res['razorpay_payment_id'] ){
    //           this.payment_capture(res['amount'],res['razorpay_payment_id']);
    //     }
    //   })
    // }


  }


  doRefresh(event) {
    this.page_no = 1;
    this.db.get_wallet_detail(this.page_no);
    // this.db.get_razorpay_settings();
    setTimeout(() => {  event.target.complete(); }, 700);
  }


  loadData(data) {
    if(!this.db.website_settings.no_more_wallet_history){
      this.page_no = this.page_no + 1;
      this.db.get_wallet_detail(this.page_no);
    }
    setTimeout(()=>{ data.target.complete() },400);
  }

  withdraw(){
    let data ={
      "customer_id" : localStorage['customerRefId'],
      "amount" : this.db.wallet_detail.current_wallet_amount
    }
    this.db.wallet_amount(data).subscribe(res =>{
        if(res.message.status == "Success"){
          this.db.alert("Your withdrawal request has been successfully submitted");
        }else {
          this.db.alert(res.message.msg);
        }
    })
  }

    // async load_razorpay(amount) {

    //   var options = {
    //     "key": this.db.razorpay_settings.api_key,
    //     "amount": (amount * 100).toString(),
    //     "currency": "INR",
    //     "name": "Meatton",
    //     "description": 'Insert Wallet amount',
    //     "image": (this.db.razorpay_settings.site_logo ? this.db.product_img(this.db.razorpay_settings.site_logo) : null),
    //     "prefill": {
    //         "name": localStorage.customerName,
    //         "email": localStorage.vendorId ? localStorage.email : localStorage.email,
    //         "contact": localStorage.Customerphone
    //     },
    //     // "notes": {
    //     //     "Order id": order.name
    //     // },
    //     "theme": {
    //         "color": "#000000"
    //     },
    //     "modal": {
    //       "backdropclose": false,
    //       "ondismiss": () => {  setTimeout(() => { }, 2000); }
    //     }
    //     // ,"handler": (response) => {
    //     //    if(response.razorpay_payment_id) {
    //     //       console.log("successsed..!")
    //     //         this.payment_capture(response.razorpay_payment_id,amount);
    //     //   }
    //     // },
    //   };

    //   try {
    //     let data = (await Checkout.open(options));
    //           console.log(data['response'])
    //           // this.payment_capture(data['response']['razorpay_payment_id'],amount);
    //           //  this.db.alert(data['response']['razorpay_payment_id']);
    //     } catch (error) {
    //          error = JSON.parse(error)
    //          console.log()
    //          this.db.alert('Payment Failed'); //Doesn't appear at all
    //     }
      
    //   // // if(this.order_info.subscription_id)
    //   // //   options.subscription_id = this.order_info.subscription_id;
    //   // console.log(this.db.ismobile)
    //   //   if(this.db.ismobile){
    //   //     try {
    //   //         let data = (await Checkout.open(options));
    //   //         console.log(data.response);
    //   //         console.log("working.",data.response)
    //   //         this.db.alert("success..")
    //   //       } catch (error) {
    //   //         console.log(error.message)
    //   //         // this.presentAlert(error.message); //Doesn't appear at all
    //   //       }
    //   //   }else{
    //   //     var rzp1 = new window.Razorpay(options,successCallback, cancelCallback);
    //   //     rzp1.open(); 
    //   //   }
  
    //   //   var successCallback = (success) =>{
    //   //      
    //   //   };
  
    //   //   var cancelCallback = (error) =>{
    //   //       setTimeout(() => {}, 2000);
    //   //       this.db.alert(error);
    //   //   };
        
    // }
    

    
}
