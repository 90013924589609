<div class="container">
    <ion-refresher slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="">
        <h6 class="fnt-20 myAccount-titles" *ngIf="!db.ismobile">Wallet</h6>
        <!-- <ion-button *ngIf="db.wallet_detail && db.wallet_detail.current_wallet_amount > 0" class="withdraw_button wallet_btn" fill="clear" (click)="withdraw()">Withdraw</ion-button> -->
    </div>
    <div *ngIf="!db.website_settings.wallet_no_history">
        <div class="d__flex mb-10" *ngIf="db.cust_name">
            <div class="d__flex wallet__container">
                <div class="wallet__info total">
                    <div class="wallet__title ion-text-center"> <span class="open_sans__font">{{db.wallet_detail && db.wallet_detail.total_wallet_amount? (db.wallet_detail.total_wallet_amount | currency:"₹": false : '2.0-0') : (0 | currency:"₹") }}</span></div>
                    <div class="wallet__text fnt-12 ion-text-center">Total</div>
                </div>
                <div class="wallet__info current">
                    <div class="wallet__title ion-text-center"><span class="open_sans__font">{{db.wallet_detail && db.wallet_detail.current_wallet_amount?((db.wallet_detail.current_wallet_amount | currency:"₹") ): (0 | currency:"₹")}}</span> </div>
                    <div class="wallet__text fnt-12 ion-text-center webkit-text" [style.--line]="1">Current Value</div>
                </div>
                <div class="wallet__info locked">
                    <!-- | currency:'&#8377;' -->
                    <div class="wallet__title ion-text-center"><span class="open_sans__font">{{db.wallet_detail && db.wallet_detail.locked_in_amount? ((db.wallet_detail.locked_in_amount | currency:"₹")) :( 0 | currency:"₹") }} </span></div>
                    <div class="wallet__text fnt-12 ion-text-center webkit-text" [style.--line]="1">Locked Amount</div>
                </div>
            </div>
            <div class="insert__wallet wallet__info mouse" (click)="db.deposit()" *ngIf="!db.ismobile && db.website_settings.allow_customer_to_addwallet == 1">
                <div class="wallet__title ion-text-center webkit-text" [style.--line]="1">Deposit Wallet Amount</div>
                <div class="wallet__text ion-text-center">
                    <ion-icon name="add-outline"></ion-icon> deposit</div>
            </div>
        </div>


        <div class="table__data mt-10" *ngIf="db.wallet_detail && !db.ismobile">
            <div class="table__container d__flex table__header">
                <div>Order Id</div>
                <div>Transaction Date</div>
                <div>Status</div>
                <!-- <div>Transaction Type</div> -->
                <div>Amount</div>
                <div>Notes</div>
            </div>
            <!--  [ngClass]="item.name == (choosed_order && choosed_order.name)?'active-order':''" (click)="choosed(item)" -->
            <div class="main-item mouse" *ngFor="let item of db.wallet_detail.transactions;let i =index">
                <div class=" table__items" [class.table__container]="!db.ismobile" [class.d__flex]="!db.ismobile" [class.mobile__wallet]="db.ismobile">
                    <div>{{item.name}}</div>
                    <div>{{item.transaction_date | date:'medium'}}</div>
                    <div><span class="dot" [class.success]="item.status == 'Credited'" [class.shipped]="item.status == 'Shipped'" [class.placed]="item.status == 'Placed'"></span>{{item.status}}</div>
                    <!-- <div>{{item.transaction_type}}</div> -->
                    <div [ngClass]="item.status == 'Credited'?'credited':'debited'"> <span class="open_sans__font">{{item.status == 'Credited'?'+':'-'}} {{item.amount | currency:"₹"}}</span></div>
                    <div>{{item.notes}}</div>
                    <!-- <div><span class="dot" [class.success]="item.payment_status == 'Paid'" [class.pending]="item.payment_status == 'Pending'" ></span>{{item.payment_status}}</div> -->
                </div>
            </div>
        </div>

        <div class="table__data" *ngIf="db.wallet_detail && db.ismobile">
            <div class="main-item mouse" *ngFor="let item of db.wallet_detail.transactions;let i =index">
                <div class="table__items" [class.mobile__wallet]="db.ismobile">
                    <div class="d__flex just-s">
                        <div>
                            <div class="fnt-14">{{item.name}}</div>
                            <div class="fnt-11 transaction__date">{{item.transaction_date | date:'medium'}}</div>

                            <!-- <div>{{item.transaction_type}}</div> -->
                        </div>
                        <div>
                            <div class="fnt-14" [ngClass]="item.status == 'Credited'?'credited':'debited'"><span class="open_sans__font">{{item.status == 'Credited'?'+':'-'}} {{item.amount | currency:"₹"}}</span> </div>
                            <!-- <div class="fnt-12"><span class="dot" [class.success]="item.status == 'Credited'" [class.shipped]="item.status == 'Shipped'" [class.placed]="item.status == 'Debited'"></span>{{item.status}}</div> -->
                        </div>
                    </div>
                    <div class="fnt-10 notes">{{item.notes}}</div>
                </div>
            </div>
        </div>
    </div>

    <ion-infinite-scroll *ngIf="!db.website_settings.no_more_wallet_history" threshold="300px" (ionInfinite)="loadData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText=""></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <div class="oops-icon1" *ngIf="(db.website_settings.enable_wallet == 1 && db.website_settings.wallet_no_history)">
        <ion-icon src="/assets/icon/empty_stack_icons/wallet-null.svg"></ion-icon>
        <h6 class="m-0 fnt-12 oops-text">No Wallet Transactions Found</h6>
        <p class="m-0">Sorry, your wallet is empty!</p>
        <ion-button fill="clear" class="sub_now" *ngIf="db.cust_name && db.website_settings.allow_customer_to_addwallet == 1" (click)="db.deposit()">Add wallet Money</ion-button>
    </div>



</div>