
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss'],
})
export class OtpLoginComponent implements OnInit {
  otp_form:any = FormGroup;
  otp_verify;
  verificationId;
  otp;
  shake;
  windowRef: any;
  otp_submit;
  mobile_no;
  
  @Input() check_out;
  @Input() number_for_checkout;
  constructor(private formbuilder:FormBuilder,private router:Router,public db:DbService,) { }

  ngOnInit() {
    this.windowRef = this.db.windowRef;
    this.otp_form = this.formbuilder.group({
        mobile_no: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        otp: ['',[Validators.required]]
      })

    this.db.otp_send.subscribe(res =>{
      res == 'sent' ?( this.otp_verify = true ): null
    })
    // this.recaptcha();
  }


  get  otp_verifyy(){
    return this.otp_verify ? true : false;
  }
  

  get phonenumber() {
    return this.otp_form.get('mobile_no');
  }

  get otp_() {
    return this.otp_form.get('otp');
  }


  async sendLoginCode(){
    this.otp_submit = true;
    if(this.otp_form.controls.mobile_no.status == 'VALID'){
           this.db.send_otp({mobile_no : this.mobile_no}).subscribe(res => {
              if (res.message.status == 'Success') {
                this.verificationId = true;
                this.otp_submit = false;
                setTimeout( ()=>{ this.otp_verify = true },250)
              } else {
                this.db.alert(res.message.message)
              }
            },error => { console.error(error)})
    }else{
      this.shake = true;
      setTimeout( () => { this.shake = false },400)
    }
  }

  resend(){
    this.otp_form.controls.otp.setValue('');
    this.sendLoginCode();
  }
  
  // recaptcha() {
  //   const auth = getAuth();
  //   this.windowRef.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
  //     'size': 'invisible',
  //     'callback': (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       console.log("recaptcha solved")
  //       // onSignInSubmit();
  //     },
      
  //   }, auth);
  //     // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
  //     //   size: 'invisible'
  //     // });
  //     // this.windowRef.recaptchaVerifier.render();
  // }


  verify_otp() {
    this.otp_submit = true;
    if(this.otp_form.controls.otp.status == 'VALID'){
         this.db.verify_otp({mobile_no : this.mobile_no,otp:this.otp}).subscribe(res => {
      if (res.message.status == 'Success') {
        this.otp_submit = false;
          if(res.message.type == 'Customer'){
            localStorage['api_key'] = res.message.api_key;
            localStorage['api_secret'] = res.message.api_secret;
            localStorage['Customerphone'] = this.mobile_no;
            localStorage['customerRefId'] = res.message.customer_id;
            localStorage['email'] = res.message.customer_email;
            localStorage['customerUser_id'] = res.message.customer_email;
            localStorage['CustomerName'] = res.message.customer_name;
            this.db.cust_email = res.message.customer_email;
            this.db.cust_name = res.message.customer_name;
            this.db.customer_Info({email : res.message.customer_email}).subscribe(res =>{
              // console.log(res,'sfs')
              if (res.message) {
                this.db.store_customer_info(res)
              }
            })
            this.db.ismobile ? this.db.dismiss() : null;
            this.db.get_address();
            this.router.navigateByUrl('/');
            this.db.normal_login = true
          }
          else if(res.message.type == 'Customer Registration'){
            localStorage['otp_token'] = res.message.data.uuid;
            this.router.navigateByUrl('/web-page/customer-on-boarding/'+ res.message.data.name);
            this.db.normal_login = true;
          }
      } else {
        this.db.alert(res.message.message)
      }
    },error => { console.error(error)})
     
    } else{
      this.shake = true;
      setTimeout( () => { this.shake = false },400)
    }
  }

  otp_success(){
    this.db.customer_Info({phone : this.mobile_no}).subscribe(res => {
      if (res.message == undefined) {
            localStorage['otp_mobile'] = this.otp_form.controls.mobile_no.value;
            this.router.navigateByUrl('/web-page/customer-on-boarding');
            this.db.normal_login = true
      } else {
        this.get_customer_info(res.message[0].user_id, true);
      }
    },error => { console.error(error)})
  }

  get_customer_info(data, value) {
    this.db.customer_Info({email:data}).subscribe(res => {
      if (res.message) {
          this.db.store_customer_info(res)
          this.db.dismiss();
          this.router.navigateByUrl('/')
      } else {
          this.get_customer_info(data, value)
      }
    },error => { this.db.alert('Check E-mail ID / Password'); console.error(error)})
  }

  get_country_list() {
    this.db.get_country_list().subscribe((r: any) => {
    }, error => { console.log(error)})
  }
  
}
