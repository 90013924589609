import { Component, Input, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-select-attribute',
  templateUrl: './select-attribute.component.html',
  styleUrls: ['./select-attribute.component.scss'],
})
export class SelectAttributeComponent  implements OnInit {
  @Input() product_detail;
  main_image;
  isApiCallInProgress = false;
  constructor(public db:DbService) { }

  ngOnInit() {
    // console.log(this.product_detail);
    if(this.product_detail){
      this.get_cart_item();
      this.check_image();
      this.db.mycart_emit.subscribe(res => {
        // console.log(res);
        if(res == 'getted'){
          this.get_recommeded();
        }
      })
      // this.db.alert_animate.subscribe((res:any) =>{
      //   this.alert_animate = true;
      //   // this.alert_animatings.push(res);
      //   // console.log(res);
      //   setTimeout( () => {  this.alert_animatings.shift();},1500);
      // })
      
    }
    // this.get_product_detail(this.product_detail.route);
  }

    //addtocart
    async addtocart(value,option,name) {
      if(this.isApiCallInProgress){
        return
      }
      this.isApiCallInProgress = false
      if (!localStorage['api_key'] && !localStorage['api_secret'] && !localStorage['CustomerId']) {
        this.db.close_modal()
      // this.router.navigate(["/login"]);
    }
      value.ids = option.attribute_id;
      value.selected_cart_id = this.db.check_cart_id(value.ids);
      value.selected_attribute = ' <div class="attribute"> '+option.variant_text+'</span> </div>'
      this.select(option.attribute_id,name);
      // !value.ids ? (value.ids ='' ,value.selected_attribute ='') : null
      option.count = this.db.check_attribute(option.attribute_id);
      value.count = option.count;
      this.db.select_addtocart(value,option)
      this.isApiCallInProgress = false
      // setTimeout(()=>{this.db.select_addtocart(value,option)},1000);
      
      // value.has_variants == 0 ? this.db.addtocart(value) : this.select_attribute(value);
      // value.has_variants == 1 ?  this.router.navigateByUrl('/pr/'+value.route) : this.db.addtocart(value)
    }
  
    //Remove Cart
    async removetocart(value,option,name) {
      if(this.isApiCallInProgress){
        return
      }
      this.isApiCallInProgress = true
      value.ids = option.attribute_id;
      value.selected_cart_id = this.db.check_cart_id(value.ids);
      value.selected_attribute = ' <div class="attribute"> </span>'+option.variant_text+'</span> </div>'
      this.select(option.attribute_id,name);
      value.count = option.count = this.db.check_attribute(option.attribute_id);
      this.db.select_removetocart(value,option);
      this.isApiCallInProgress = false
      // value.has_variants == 1 ?  this.router.navigateByUrl('/pr/'+value.route) :  this.db.removetocart(value)
    }

  get_recommeded() {
    this.product_detail.count = this.db.checkcart(this.product_detail.name);
    this.product_detail.wish_count = this.db.checkWishCart(this.product_detail.name);
  
    if(this.product_detail.vendor_price_list && this.product_detail.vendor_price_list.length != 0){
      this.product_detail.vendor_price_list.find(res =>{
        if(res.variants && res.variants != 0){
            res.variants.map(r =>{
              if(r.is_pre_selected == 1 && r.selected_variant){
                r.count = this.db.check_attribute(r.attribute_id);
                this.product_detail.count = r.count;
              }
            })
        } 
      })
      //  else if(this.product_detail.show_price == 0){
      //   this.get_selected_table_attribute()
      // } 
    }
  }


  get_cart_item(){
    this.db.get_cart_items()
  }

  // select_attribute(attribute){
    
  // }

  select(attribute,id){
    this.db.is_variant = true;
    this.product_detail['vendor_price_list'].find(res => {
      if(res.business == id){
        res.variants.map(d =>{
          if(d.attribute_id == attribute){
            d.is_pre_selected = 1;
            d.selected_variant = true;
            d.stock_alert_message = d.stock != 0 ? undefined : "No stock Available";
          }else{
            d.is_pre_selected = 0;
            d.selected_variant = false;
          }
        })
      }
  
    })
    // this.product_detail.stock_alert_message = stock;
  }

    check_image(){
      if(this.product_detail.images){
        this.main_image =  this.check_active_image(this.product_detail.images)
      }else{
        this.main_image = this.product_detail.product_image
      }
    }

    check_active_image(images){
      let temp = ''
      if(images.length != 0){
          images.map(res=>{
            if(res.is_primary == 1){        
              res.product_image ?  temp = res.detail_image :  temp = res.image
            }
        })
      }
      return this.db.product_img(temp)
    }

    check_disabled(item) {
      return (item.stock == 0) || item.disable_add_to_cart_button == 1 || item.stock_alert_message || item.count >= item.maximum_order_qty
    }
    
    
    // check_attribute_cart(attribute){  
    // this.product_detail['vendor_price_list'].find(res => {
    //   if(res.variants && res.variants != 0){
    //     let ids = '';
    //     let selected_attribute ='';
    //     let selected_cart_id =''
    //     let selected_cart_count = ''
    //     res.variants.map(data =>{  
    //     data.count = this.check_attribute(data.attribute_id);
    //     // data.iscart = this.check_attribute(data.name+'\n');
    //     // data.count = data.iscart
    //    if(data.attribute_id == attribute){   
    //     //  ids+=data.name+'\n';
    //      ids+=data.attribute_id; 
    //      selected_cart_id = this.check_cart_id(ids);
    //      selected_cart_count = data.count;
    //     //  selected_attribute += ' <div class="attribute"><span class="attr-title">'+data.attribute_id+'</span> : <span>'+data.option_value+'</span> </div>'
    //      selected_attribute += ' <div class="attribute"> '+data.variant_text+'</span> </div>'
    //     //  this.product_detail.stock = data.stock;
    //      this.product_detail.price = data.product_price,
    //      this.product_detail.old_price = data.old_price;
    //     //  data.stock != 0 ? this.db.close_modal() : '';
    //     // this.product_detail.stock_alert_message = data.stock != 0 ? undefined : "No stock Available";
    //     }
    //   })
    //  this.product_detail.selected_cart_id = selected_cart_id
    //  this.product_detail.ids = ids;
    //  this.product_detail.count = selected_cart_count;
    //  // this.product_detail.product_variants.length > 1 ? this.product_detail.count =  this.check_attribute(this.product_detail.ids) : null 
    //  this.product_detail.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
    //   }
    // })
    // // this.check_attribute_stock();
    // // console.log(this.product_detail);
    // }
    
    
    // check_attribute_stock(){
    // var data = {
    //   "product": this.product_detail.name,
    //   "attribute_id": this.product_detail.ids,
    //   "cart_qty": this.db.plusCount(this.product_detail.count),
    //   "variant_html" :''
    // }
    // this.db.validate_attributes_stock(data).subscribe(res => {
    //   if(res.message){
    //     if( res.message.status == "Success"){
    //       this.product_detail.stock = res.message.stock
    //       // this.product_detail.price = res.message.price,
    //       // this.product_detail.old_price = res.message.old_price
    //       this.product_detail.discount_label = res.message.discount.discount_label
    //       this.product_detail.discount_rule = res.message.discount.discount_rule
    //       this.product_detail.stock_alert_message = undefined
    //     }else if( res.message.status == 'Failed'){
    //       // this.product_detail.price = res.message.price,
    //       // this.product_detail.old_price = res.message.old_price
    //       this.product_detail.stock_alert_message = res.message.message
    //     }
    //   }
    // })
    // }
    
    
    // check(data){
    //   console.log(data)
    //   console.log(data.drop_down)
    // }
    

    // check_attribute(id){
    //   var cnt = 0;
    //   let array = [...this.db.marketplace_items,...this.db.fl_items]
    //   array.find( res =>{
    //     if(res.attribute_ids == id){
    //       cnt += res.quantity
    //     }
    //   })
    //   return cnt;
    //   }
      
      
    //   check_cart_id(id){
    //   let cart_id =''
    //   let array = [...this.db.marketplace_items,...this.db.fl_items]
    //   array.find( res =>{
    //     if(res.attribute_ids == id){
    //       if(res.quantity != 0){
    //         cart_id = res.name
    //       }
    //     }
    //   })
    //   return cart_id;
    //   }

      // check_disabled(item) {
      //   return ((item.stock == 0 || item.stock <= item.count)) || item.stock_alert_message;
      // }

}


