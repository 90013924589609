import {
  Component,
  Input,
  OnInit,
  HostListener
} from '@angular/core';
import { LocationStrategy } from '@angular/common';
import {
  NavigationEnd,
  Router,
} from '@angular/router';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.page.html',
  styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit {
  @Input() order_detail;
  constructor(private router:Router,public db:DbService,private location: LocationStrategy) { }

  ngOnInit() {

  // const modalState = {
  //   modal : true,
  //   desc : 'fake state for our modal'
  // };

  // history.pushState(modalState, '');

}


// @HostListener('window:popstate', ['$event'])
// dismissModal() {
//   if(this.db.ismobile){
//     setTimeout(() => {
//       this.router.navigateByUrl('/tabs/my-orders');
//     }, 500);
//   }
// }


}
