import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  Meta,
  Title,
} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private metaTitle:Title,private meta:Meta,private db:DbService,private platformLocation: PlatformLocation) { }

  store_meta(data){
    this.metaTitle.setTitle(data.meta_title);
    this.meta.updateTag(  { property: 'og:title', content:  data.meta_title });
    this.meta.updateTag({ property: 'twitter:title', content: data.meta_title });
    this.meta.updateTag({ name: 'keywords', content: data.meta_keywords });
    this.meta.updateTag(  { name : "description",content : data.meta_description }  );
    this.meta.updateTag(  { property : "og:description",content : data.meta_description } );
    this.meta.updateTag(  { property : "twitter:description",content : data.meta_description } );
    this.db.product_img(data.image ) ?  this.meta.updateTag(  { property : "og:image",content : this.db.product_img(data.image) } ) : null
    this.db.product_img(data.image ) ? this.meta.updateTag(  { property : "twitter:image",content : this.db.product_img(data.image ) } ) : null
  }  
}
