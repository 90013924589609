import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {

  is_address;
  @Input() billing;
  @Input() modal;
  constructor(public modalCtrl: ModalController,public alertCtrl: AlertController,public db:DbService,public loadingCtrl: LoadingController) { }

  ngOnInit() {
    if(this.db.cust_name || this.db.guest_cust_name){
      this.db.get_address();
    }  
  }
  
  backbtn(){
    this.modalCtrl.dismiss();
  }

  select_address(addr){
    this.modalCtrl.dismiss(addr);
  }

  deleteData(li, index) {
    this.db.delete_address(li,index) 
  }

  async update(alertmsg,datass) {
    let loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'Please Wait...'
    });
    await loader.present();
    var child_address:any=[]
    for (let k = 0; k<  this.db.address_list.length; k++) {
      if( this.db.address_list[k].is_default != true)
         this.db.address_list[0].is_default = true
      	var address_data={
	        last_name: this.db.address_list[k].last_name,
	        city: this.db.address_list[k].city,
	        first_name: this.db.address_list[k].first_name,
	        zipcode: this.db.address_list[k].zipcode,
	        state: this.db.address_list[k].state,
	        phone: this.db.address_list[k].phone,
	        address: this.db.address_list[k].address,
	        country: this.db.address_list[k].country,
	        is_default: this.db.address_list[k].is_default,
        }

      	child_address.push(address_data)
    }
    	var values = {
	        first_name:localStorage['Customerfirst_name'],
	        last_name:localStorage['Customerlast_name'],
	        phone:localStorage['Customerphone'],
	        email:localStorage['CustomerId'],
	        name:localStorage['customerRefId'],
	        address:child_address
        }
    this.db.insert_edit_address(values).subscribe(data =>{
        setTimeout(() => { loader.dismiss();  }, 50);
        this.db.get_address();
      }, error => {
        setTimeout(() => {loader.dismiss(); }, 50);
      });
  }
  
}
