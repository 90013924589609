import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-order-reason',
  templateUrl: './order-reason.page.html',
  styleUrls: ['./order-reason.page.scss'],
})
export class OrderReasonPage implements OnInit {

  // reason_list:any;
  constructor(public db:DbService,public modalCtrl: ModalController) { }

  ngOnInit() {
    if(this.db.reason_list && this.db.reason_list.length == 0){
      this.get_cancel_reason();
    }
  }

  get_cancel_reason(){
    this.db.get_cancel_reasons().subscribe(res => {
      if(res && res.message.status == 'Success')
      this.db.reason_list = res.message.data;
    })
  }

  select_reason(item){
    this.modalCtrl.dismiss(item)
  }

}
