import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-category-bar',
  templateUrl: './category-bar.component.html',
  styleUrls: ['./category-bar.component.scss'],
})
export class CategoryBarComponent implements OnInit {
  category_child;
  mobile_child;
  skeleton;
  ismobile;
  show_category = true
  show_hide = true;
  // @Input() category_data:any;
  @Output() change =  new EventEmitter();
  constructor(public db:DbService,private router:Router,private location:Location) { 
    // this.enable_category_menu()
  }

  ngOnInit() {
    this.ismobile = this.db.checkmobile();
    // console.log('db.category',this.db.category)
      // this.get_category();
      
      // console.log(this.db.childs);
      // this.db.childs.child_2 ? this.show_hide = false : '';
      this.db.active_category.subscribe((res:any)=>{
        // console.log(res);
        if(res){
          let data = res.split('/')
          // console.log(data)
          if(this.db.category && this.db.category.length != 0){
            this.db.category.map((val:any)=>{
              data.map((data:any)=>{
                if(val.route == data){
                val.active_child1 = true;
              }
              })
            })
          }
        }
      })
  }
  
  // get_category() {
  //   if(this.db.category.length == 0){
  //     this.db.get_parent_category();
  //     // var data = { domain: this.db.domain,show_count :1  }
  //     // this.db.get_parent_categories(data).subscribe(data => {
  //     //     this.db.category = data.message;
  //     //     console.log('db.category',this.db.category)
  //     //   }, error => { console.log(JSON.stringify(error.json()));});
  //     }       
  // }

  @HostListener('window:resize', ['$event'])
  private func(){
    this.ismobile = this.db.checkmobile();
  }



goto(route,child_no){
  // this.show_category = true
  // console.log(this.db.childs.child_1)
  // console.log(route,child_no)
    this.db.sub_data = route.child ? route.child : [];
    this.skeleton = true;
    setTimeout(() => { this.skeleton = false;  }, 600);
    this.db.cat_bar = route.route;
    if((child_no == 'child-1' && route.route != this.db.childs.child_1)){
        this.db.childs.child_1 = route.route;
        this.db.childs.child_1_name = route.category_name;
        // console.log('this.db.childs.child_1_name',this.db.childs.child_1_name)
        // console.log('this.db.childs',this.db.childs)
        // console.log(route.category_name)
        this.db.childs.child_2 = undefined
        this.db.childs.child_3 = undefined
        this.db.childs.child_2_name = undefined
        this.db.childs.child_3_name = undefined
        this.db.ismobile ? '' : this.location.replaceState("/shop/"+route.route);
        !this.db.ismobile ?  this.router.navigate(['/shop/'+route.route]) : ''
    } else if((child_no == 'child-2' && route.route != this.db.childs.child_2) ){
        this.db.childs.child_2 = route.route;
        // console.log(this.db.childs.child_2)
        // console.log(route.category_name)
        this.db.childs.child_2_name = route.category_name;
        // console.log('this.db.childs.child_2_name',this.db.childs.child_2_name)
        this.db.childs.child_3 = undefined
        this.db.childs.child_3_name = undefined;
        // console.log('this.db.childs',this.db.childs)
        // +this.db.childs.child_1+"/"+
        this.location.replaceState("/shop/"+route.route);
        this.router.navigate(['/shop/'+route.route])

    }else if((child_no == 'child-3' && route.route != this.db.childs.child_3)){
        this.db.childs.child_3 = route.route;
        this.db.childs.child_3_name = route.category_name;
        // console.log('this.db.childs',this.db.childs)
        this.location.replaceState("/shop/"+route.route);
        this.router.navigate(['/shop/'+route.route])

    }

    route.default_view ?  this.db.product_box.view = route.default_view : this.db.product_box.view = 'Grid View'

    if(!this.ismobile && this.db.choosed_product_id != route.name){
      this.db.choosed_brands = [];
      this.db.choosed_attributes = [];
      this.db.choosed_product_id = route.route; 
      this.change.emit();
    }   
}

shop_product(route){
  // this.db.choosed_product_id = route;
  // localStorage.category_route = id;
  this.router.navigateByUrl('/shop/'+route);
}

  navigate(value){
    // console.log(value);
    this.router.navigateByUrl('/category-landing/'+ value.route);
  }
}
