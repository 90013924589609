import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ForgetComponent } from 'src/app/components/forget/forget.component';
import { DbService } from 'src/app/services/db.service';
import { ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-normal-login',
  templateUrl: './normal-login.component.html',
  styleUrls: ['./normal-login.component.scss'],
})
export class NormalLoginComponent implements OnInit {
  focus :any= {}; //Focus styling
  loginform:any = FormGroup;
  submitted = false;
  shake;
  alert_message;
  show_pass = true;
  @Input() affiliated;
  // remember_me = false;
  remember_me_check = false;

  constructor(public modalCtrl: ModalController,public cookie : CookieService,public db:DbService,private formbuilder:FormBuilder,private router:Router) { }

  ngOnInit() {


    let cookie_username = '';
    let cookie_password = '';
    
    if(this.cookie.get('username') && this.cookie.get('password')){
        cookie_username = this.cookie.get('username');
        // this.user_name = cookie_username;
        cookie_password = this.cookie.get('password');
        this.db.login_role = this.cookie.get('user_type');
        this.remember_me_check = true;
    }


    this.loginform = this.formbuilder.group({
      username : new FormControl (cookie_username,[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      password : new FormControl(cookie_password,[Validators.required])
    })

    // this.loginform = this.formbuilder.group({
    //   username : new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    //   // username : new FormControl ('',[Validators.required,Validators.email]),
    //   password : new FormControl('',[Validators.required])
    // })

  } 

  get username(){
    return this.loginform.get('username');
  }

  get password(){
    return this.loginform.get('password');
  }

  async forgot(){
    let modal = await this.modalCtrl.create({
      component: ForgetComponent,
      cssClass: !this.db.ismobile ? 'forget-popup' : 'address-popup',
      componentProps: { modal : true }
    });
    await modal.present();
    let { data } = await modal.onWillDismiss();
    if(data) {
      this.db.otp_form = false;
      // console.log(data)
    }
  }
  //Form login Method..
  login() {
    this.submitted = true;
    // console.log(this.loginform.value)
    this.submitted = true;

    if (this.loginform.status == 'VALID') {
        let data = {
          usr: this.loginform.value.username,
          pwd: this.loginform.value.password,
          // get_user_token: 1,
          // is_redirect: 0
        }
        this.db.getLogin(data).subscribe(data => {
        
          if(data && data.status != 'Failed'){
            // console.log(data.message);
            localStorage['CustomerPwd'] = this.loginform.value.password;
            // is_shop_user == 0
            if(data.message){
              this.db.customer_Info({email : this.loginform.value.username}).subscribe(res => {

                let queryparam = { 'username' : this.loginform.value.username, 'password':this.loginform.value.password,'customer_id':res.message[0] && res.message[0].name ? res.message[0].name : res.message[0].name }
                
                if(this.remember_me_check){
                  this.db.store_login_info(queryparam);
                }else{
                   if(this.cookie.get('customer_id') == queryparam.customer_id){
                    this.cookie.delete('username');
                    this.cookie.delete('password');
                  }
                }
                    this.db.store_customer_info(res);
                    this.db.sass_login(this.loginform.value.username,this.loginform.value.password);
                    this.db.dismiss();
                    this.router.navigateByUrl('/');       
              }, error => {
                this.shake = true;
                setTimeout( () => { this.shake = false },400)
                console.log(JSON.stringify(error.json()));
              })

            }else{ 
              data.message.shop_user.message = [];
              data.message.shop_user.api_key = data.message.api_key;
              data.message.shop_user.api_secret = data.message.api_secret;
              data.message.shop_user.roles_list = data.message.roles_list;
              
              data.message.shop_user.message.push(data.message.shop_user);
              
              // console.log('data',data.message);
              this.db.store_customer_info_ventor(data.message.shop_user)
              this.db.dismiss();  
            }
          }else{
            this.shake = true;
            setTimeout( () => { this.shake = false },400);
            if(data.message && data.status == 'Failed'){
              // this.alert_message = data.message;
              this.db.alert(data.message);
            }
          }
      },err => {
        this.shake = true;
        setTimeout( () => { this.shake = false },400)
        this.alert_message = 'Check Email/Password';
        // this.db.alert('Check Email/Password')
        console.log(err)
      });
    }else{
      this.shake = true;
      setTimeout( () => { this.shake = false },400)
    }
  }

  // remember(evt){
  //   this.remember_me = evt.detail.checked;
  // }

  remember_me(eve){
    // console.log(eve.detail.checked);
    this.remember_me_check = eve.detail.checked
  }

}
