<ion-icon *ngIf="!db.ismobile" class="close-icon" name="close-outline" (click)="dismiss_modal()"></ion-icon>
<div class="map__location" *ngIf="db.website_settings.enable_map == 1;else zip_based;">
  <div class="maps-container">
    <div class="d__flex location__input" #location_dropdownn>
      <div class="location__search w-100">
        <input #addresstext class="input" placeholder="Search your location" type="text">
      </div>
      <ion-button fill="clear" class="m-0 location-button-wb ion-text-capitalize" (click)="db.current_locate('')">
        <ion-icon name="locate-outline"></ion-icon>
      </ion-button>
    </div>
    <div>
      <app-maps></app-maps>
    </div>
  </div>
  <p class="m-0 fnt-12 location__warning" *ngIf="db.location_error && false">{{db.location_error}}</p>
  <div class="container">
    <ion-grid>
      <form class="m-5 p-10" [formGroup]='address_form'>
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">Address </ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.address">
            <ion-input name="address" [disabled]="true" [(ngModel)]="db.location_info.address" (ionFocus)="focus.address = true" (ionBlur)="focus.address = false" class="form-input" autofocus="true" autocomplete="false" required="true" formControlName="address"></ion-input>
          </div>
          <div *ngIf="submitted && !address?.valid">
            <p class="error m-0" *ngIf="address.errors.required">address is required</p>
          </div>
        </div>
        <div class="search__location" *ngIf="db.location_sugesstions.length != 0 && db.website_settings.enable_multi_store == 0">
          <ion-item button class="fnt-13" *ngFor="let item of db.location_sugesstions | slice:0:50" (click)="db.validate_location(item,'')">
            <!-- <div>{{item.description}}</div> -->
          </ion-item>
        </div>
        <div class="d__flex separator" *ngIf="db.website_settings.account_settings.enable_house_type">
          <div class="input-item" *ngIf="db.website_settings.account_settings.enable_house_type">
            <ion-label color="grey" class="input__title" position="stacked">House Type </ion-label>
            <div lines="none" class="custom-input" [class.focused]="focus.house_type">
              <!-- <ion-input name="house_type" class="form-input" (ionFocus)="focus.house_type = true" (ionBlur)="focus.house_type = false" formControlName="house_type" autocomplete="false" required="true"></ion-input> -->
              <ion-select name="house_type" formControlName="house_type" (ionFocus)="focus.house_type = true" (ionBlur)="focus.house_type = false" class="form-input form-select" interface="popover" [value]="address_info.house_type">
                <ion-select-option value="Individual">Individual</ion-select-option>
                <ion-select-option value="Apartment">Apartment</ion-select-option>
              </ion-select>
            </div>
            <div *ngIf="submitted && !house_type?.valid">
              <p class="error m-0" *ngIf="house_type.errors.required">House Type is required</p>
            </div>
          </div>
          <div class="input-item" *ngIf="db.website_settings.account_settings.enable_house_type">
            <ion-label color="grey" class="input__title" position="stacked"> {{house_type.value =='Individual'?'House no,House name, street name':'Flat no, Apartment/Villa name'}} </ion-label>
            <div lines="none" class="custom-input" [class.focused]="focus.door_no">
              <ion-input name="door_no" (ionFocus)="focus.door_no = true" (ionBlur)="focus.door_no = false" class="form-input" autofocus="true" autocomplete="false" required="true" formControlName="door_no"></ion-input>
            </div>
            <div *ngIf="submitted && !door_no?.valid">
              <p class="error m-0" *ngIf="door_no.errors.required"> {{house_type.value =='Individual'?'House no,House name, street name':'Flat no, Apartment/Villa name'}} is required</p>
            </div>
          </div>
        </div>
        <div class="input-item" *ngIf="db.website_settings.account_settings.enable_landmark">
          <ion-label color="grey" class="input__title" position="stacked">Landmark</ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.landmark">
            <ion-input name="landmark" class="form-input" (ionFocus)="focus.landmark = true" (ionBlur)="focus.landmark = false" formControlName="landmark" autocomplete="false"></ion-input>
          </div>
          <div *ngIf="submitted && !landmark?.valid">
            <p class="error m-0" *ngIf="landmark.errors.required">Landmark is required</p>
          </div>
        </div>
        <div class="d__flex checkbox__container  gap-10">
          <div class="checkbox gap-5">
            <ion-checkbox [checked]="" formControlName="is_default"></ion-checkbox> Set as Default
          </div>
        </div>
        <div>
          <ion-button fill="clear" class="btn-lg mt-15" expand="full" (click)="address_type == 'Edit'?edit_address():insert_address()">{{address_type == 'Edit'?'Update':'Save'}}</ion-button>
        </div>
      </form>
    </ion-grid>
  </div>
</div>
<ng-template class="container" #zip_based>
  <ion-grid>
    <form class="m-5 p-10" [formGroup]='address_form'>
      <div class="d__flex separator">
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">{{db.website_settings.account_settings.enable_lastname == 1 ? 'First Name':'Name'}}</ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.first_name">
            <ion-input (ionFocus)="focus.first_name = true" (ionBlur)="focus.first_name = false" name="first_name" class="form-input" autofocus="true" autocomplete="false" required="true" formControlName="first_name"></ion-input>
          </div>
          <div *ngIf="submitted && !first_name?.valid">
            <p class="error m-0" *ngIf="first_name.errors.required">First Name is required</p>
          </div>
        </div>
        <div *ngIf="db.website_settings.account_settings.enable_lastname == 1" class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">Last Name </ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.last_name">
            <ion-input (ionFocus)="focus.last_name = true" (ionBlur)="focus.last_name = false" name="last_name" class="form-input" autofocus="true" autocomplete="false" formControlName="last_name"></ion-input>
          </div>
          <div *ngIf="submitted && !last_name?.valid">
            <p class="error m-0" *ngIf="last_name.errors.required">Last Name is required</p>
          </div>
        </div>
      </div>
      <div class="input-item">
        <ion-label color="grey" class="input__title" position="stacked">Address </ion-label>
        <div lines="none" class="custom-input" [class.focused]="focus.address">
          <ion-input name="address" (ionFocus)="focus.address = true" (ionBlur)="focus.address = false" class="form-input" autofocus="true" autocomplete="false" required="true" formControlName="address"></ion-input>
        </div>
        <div *ngIf="submitted && !address?.valid">
          <p class="error m-0" *ngIf="address.errors.required">address is required</p>
        </div>
      </div>
      <!-- [disabled]="db.address_disable ? true : false" -->
      <div class="d__flex separator">
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked"> Pincode </ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.zipcode">
            <ion-input *ngIf="db.zipcode_base_search" name="zipcode" type="number" (ionChange)="db.search_location($event)" autocomplete="off" placeholder="Search Pincodes..." formControlName="zipcode"></ion-input>
            <ion-input *ngIf="!db.zipcode_base_search" name="zipcode" class="form-input" formControlName="zipcode" (ionFocus)="focus.zipcode = true" (ionBlur)="focus.zipcode = false" autocomplete="false" required="true"></ion-input>
          </div>
          <div class="error-container">
            <p class="m-0 error" *ngIf="submitted && zipcode && !zipcode.valid && zipcode.errors.required">Zipcode is required</p>
            <p class="m-0 error" *ngIf=" submitted&& zipcode && !zipcode.valid && zipcode.errors.pattern ">Enter a valid Zipcode</p>
          </div>
        </div>
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked"> Town/City </ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.city">
            <ion-input [disabled]="false" name="city" class="form-input" (ionFocus)="focus.city = true" (ionBlur)="focus.city = false" formControlName="city" autocomplete="false" required="true" [(ngModel)]="db.address.city ? db.address.city : address_info.city"></ion-input>
          </div>
          <div *ngIf="submitted && !city?.valid">
            <p class="error m-0" *ngIf="city.errors.required">City is required</p>
          </div>
        </div>
      </div>
      <div class="d__flex separator">
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">State </ion-label>
          <!-- [(ngModel)]="db.address.state ? db.address.state : address_info.state"   -->
          <div lines="none" class="custom-input" [class.focused]="focus.state">
            <!-- <ion-input *ngIf="db.address_disable" [disabled]="false" name="state" formControlName="state" (ionFocus)="focus.state = true" (ionBlur)="focus.state = false" class="form-input form-select" interface="popover" [(ngModel)]="db.address.state ? db.address.state : address_info.state"></ion-input> -->
            <select name="state" formControlName="state" (ionFocus)="focus.state = true" (ionBlur)="focus.state = false" class="form-input form-select" interface="popover" [value]="db.address.state ? db.address.state : address_info.state">
              <option *ngFor="let c of states_list" value="{{c.name}}">{{c.name}}</option>
            </select>
          </div>
          <div *ngIf="submitted && !state?.valid">
            <p class="error m-0" *ngIf="state.errors.required">State is required</p>
          </div>
        </div>
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">Country/Region </ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.country">
            <ion-select [disabled]="true" name="country" formControlName="country" (ionFocus)="focus.country = true" (ionBlur)="focus.country = false" class="form-input form-select" interface="popover" (ionChange)="value_changed($event)" [(ngModel)]="db.address.country ? db.address.country : address_info.country" value="{{address_info.country}}">
              <ion-select-option *ngFor="let c of country_list" value="{{c.name}}">{{c.name}}</ion-select-option>
            </ion-select>
          </div>
          <div *ngIf="submitted && !country?.valid">
            <p class="error m-0" *ngIf="country.errors.required">Country/Region is required</p>
          </div>
        </div>
      </div>
      <div class="d__flex separator">
        <div class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">Phone </ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.phone">
            <ion-input name="phone" class="form-input" type="number" formControlName="phone" (ionFocus)="focus.phone = true" (ionBlur)="focus.phone = false" autocomplete="false" required="true"></ion-input>
          </div>
          <div class="error-container">
            <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.required">Enter Your Mobile Number</p>
            <p class="m-0 error" *ngIf="submitted && !phone.valid && phone.errors.pattern">Enter a valid Mobile Number</p>
          </div>
        </div>
        <div *ngIf="db.website_settings.account_settings.enable_landmark == 1" class="input-item">
          <ion-label color="grey" class="input__title" position="stacked">Landmark</ion-label>
          <div lines="none" class="custom-input" [class.focused]="focus.landmark">
            <ion-input name="landmark" class="form-input" (ionFocus)="focus.landmark = true" (ionBlur)="focus.landmark = false" formControlName="landmark" autocomplete="false"></ion-input>
          </div>
          <div *ngIf="submitted && !landmark?.valid">
            <p class="error m-0" *ngIf="landmark.errors.required">Landmark is required</p>
          </div>
        </div>
      </div>
      <div class="d__flex checkbox__container  gap-10">
        <ion-item lines="none" button="false" class="checkbox gap-5 m-0">
          <ion-checkbox [checked]="" formControlName="is_default"></ion-checkbox> Set as Default
        </ion-item>
        <!-- <div *ngIf="db.check_available_zipcode(address_form.value.zipcode)" class="location-warning">
          <ion-icon name="warning-outline"></ion-icon>
          <p  class="m-0">{{db.check_available_zipcode(address_form.value.zipcode)}}</p>
        </div> -->
        <!-- <div class="avail__locat mouse" *ngFor="let item of db.area_list | slice:0:8">{{item.name}}</div> -->
      </div>
      <div>
        <ion-button fill="clear" class="btn-lg mt-15" expand="full" (click)="check_address_pincode()">{{address_type == 'Edit'?'Update':'Save'}}</ion-button>
      </div>
    </form>
  </ion-grid>
</ng-template>
