import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { ReturnResonsComponent } from 'src/app/components/return-resons/return-resons.component';
import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-make-return',
  templateUrl: './make-return.page.html',
  styleUrls: ['./make-return.page.scss'],
})
export class MakeReturnPage implements OnInit {

  @Input() order_id;
  
  return_details;

  return_products;
  qty;
  currentDate

  constructor(public db:DbService,public modalCtrl:ModalController) { }

  ngOnInit() {
    // console.log('order_id',this.order_id);
      this.get_reason_return();
      this.get_retuen_products();   
      
      let today  = new Date();
      this.currentDate = formatDate(today, 'yyyy-MM-dd', 'en-US');

  }

  get_reason_return(){
  this.db.get_return_request_details().subscribe(r=>{
    // console.log(r);
    this.return_details = r.message
  })
  }

  get_retuen_products(){
    let data ={
      order_id : this.order_id
    }
    this.db.get_return_request_info(data).subscribe(res=>{
      //  console.log(res)
       this.return_products = res.message;

   // if(this.db.website_settings.return_based_on == "Delivery Date"){
     // (this.return_products.eligible_items).map(r=>{
     //  r.delivery_date = this.check_delivery_date(r.delivery_date);
     // })
    // }else{
    //   this.return_products.order_date = this.checck_order_date(this.return_products.order_date)
    // }

      // this.get_recommeded();
    })
  }


  get_recommeded() {
    this.return_products.order_item.find(res => {
      res.count = this.db.checkcart(res.name)
    })
    // console.log('get_recommeded',this.return_products)
  }

  check_delivery_date(delivery_date){
    const d = new Date(delivery_date);
    d.setDate(d.getDate() + this.db.website_settings.return_request_period);
    delivery_date = formatDate(d, 'yyyy-MM-dd', 'en-US');
    // console.log(delivery_date)
    return delivery_date;
  }

  checck_order_date(order_date){
    const d = new Date(order_date);
    d.setDate(d.getDate() + this.db.website_settings.return_request_period);
    order_date = formatDate(d, 'yyyy-MM-dd', 'en-US');
    return order_date;
  }

  add(value){
    // console.log(value)
    if(value.count < value.quantity){
      value.count = value.count + 1;
      this.qty = true;
    }
  }

  remove(value){
    // console.log(value)
    if(value.quantity >= 0){
      value.count = value.count - 1;
      this.qty = true;
   }
  }

  async return(product){
  // if(product.count == 0){
  //   this.db.alert('Please select any product by changing its quantity')
  // }else {
    // console.log('product',product);
    let products=[{"item":product.item,"quantity":product.count,"price":product.price,"amount":product.amount}]
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: ReturnResonsComponent,
      cssClass:  'return-popup',
      componentProps: {  
        return_details : this.return_details,
        order_id : this.order_id,
        product_id : product.name
      }
    })
    this.db.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.db.modal = false;
    }
   // }
 }
}
