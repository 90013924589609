<ion-app>
  <div class="__overlay" #overlay *ngIf=" !db.ismobile  && db.location_popup && false">
    <div class="location-popup" #location_popups>
      <app-location-popup></app-location-popup>
    </div>
  </div>
  <!-- db.path == '/login' ||  -->
  <app-header *ngIf="!(db.path == '/business-registration' || db.path == '/thankyou' || db.path == '/web-page/customer-on-boarding' || db.path?.split('/')[1] == 'web-page' || ((db.path?.split('/')[1] == 'vendor-registration')))" [cat_products]="db.category" (cart_pop)="db.cart_popup($event)" class="desktop-header" [desktop]="true"></app-header>
  <ion-menu contentId="main" menuId="main" [side]="db.ismobile?'start':'end'" (ionDidClose)='menuclose()'>
    <ion-content class="menu__content" [ngClass]="db.ismobile?'side-menu-bg':''">
      <app-mobile-menu [menu]="db.menu" *ngIf="db.ismobile"></app-mobile-menu>
      <div *ngIf="!db.ismobile" [ngClass]="db.mycart ? (db.marketplace_items && db.marketplace_items.length !=0 || db.fl_items && db.fl_items.length !=0) ?'cart-menu':'':'wish-list'">
        <div class="segment">
          <div class="mouse btn ion-activatable ripple-parent" [ngClass]="db.mycart?'active':''" (click)="db.cartChange('mycart')">
            <ion-ripple-effect></ion-ripple-effect>Shopping Cart <ion-badge>{{db.cart_count}}</ion-badge>
          </div>
          <!-- <div class="mouse btn ion-activatable ripple-parent" [ngClass]="db.wishcart?'active':''" (click)="db.cartChange('wishcart')">
            <ion-ripple-effect></ion-ripple-effect>Wishlist <ion-badge>{{db.wish_count}}</ion-badge>
          </div> -->
        </div>
        <div *ngIf="db.wishcart">
          <app-wish-list [load]="true" [yorcart_wish_web]="true"></app-wish-list>
        </div>
        <div *ngIf="db.mycart">
          <app-your-cart [load]="true" [margin_bottom]="true" [summary_bill]="true" [yorcart_wish_web]="true"></app-your-cart>
        </div>
      </div>
    </ion-content>
    <ion-footer *ngIf="!db.android && !db.wishcart && !db.mycart && false" class="footer_play_ios">
      <a [href]="db.website_settings && db.website_settings.app_settings && db.website_settings.app_settings.playstore_url" class="image ion-text-center"><img class="android_img" src="/assets/districo/android-dwld.png" alt=""></a>
      <a [href]="db.website_settings && db.website_settings.app_settings && db.website_settings.app_settings.ios_url" class="image ion-text-center"><img class="ios_img" src="/assets/districo/ios-dwld.png" alt=""></a>
    </ion-footer>
  </ion-menu>
  <ion-content>
    <div *ngIf="!db.ismobile">
      <div class="separate-cart mouse" *ngIf="db.path == '/p/homepage' && db.path?.split('/')[1] != 'shop'" (click)="db.cart_popup('mycart')">
        <ion-badge>{{db.cart_count}}</ion-badge>
        <div>
          <ion-icon src="/assets/icon/unique-cart.svg"></ion-icon>
        </div>
      </div>
      <div *ngIf="db.quick_view_none" class="tostifyy success__alert">
        <div class="tostify d__flex " *ngFor="let item of add_animatings;let i =index" (click)="add_animatings.splice(i,1)">
          <div class="success-txt d__flex">
            <div class="image" *ngIf="item.images; else detail_img"><img src="{{db.product_img(item.images[0].detail_thumbnail )}}"></div>
            <ng-template #detail_img>
              <div class="image"><img src="{{db.product_img(item.product_image)}}"></div>
            </ng-template>
            <div class="text_grid">
              <span class="title">{{item.item}}</span>
              <span class="sucss">Added to cart</span>
            </div>
          </div>
          <ion-icon name="close-outline"></ion-icon>
        </div>
      </div>
      <div class="tostifyy tostify__alert" *ngIf="!db.modal || db.ismobile || !db.ismobile">
        <div class="tostify d__flex " *ngFor="let item of alert_animatings;let i= index" (click)="alert_animatings.splice(i,1)">
          <div> <span>{{item}}</span></div>
          <ion-icon name="close-outline"></ion-icon>
        </div>
      </div>
    </div>
    <ion-router-outlet (ionScroll)="scrolled($event)" [animation]="myCustomPageTransition" main id="main"></ion-router-outlet>
    <div *ngIf="tabs" class="tab">
      <ion-tab-bar>
        <ion-tab-button *ngFor="let item of db.tabs_items" tab="home" [routerLink]="item.route" routerDirection="root" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact:true}">
          <ion-icon class="icon" [src]="item.icon"></ion-icon>
        </ion-tab-button>
      </ion-tab-bar>
    </div>
  </ion-content>
</ion-app>
<div class="loader" *ngIf="db.loader">
  <ion-spinner name="lines"></ion-spinner>
</div>
