import {
  DatePipe,
  Location,
} from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NavigationExtras,
  Router,NavigationEnd, ActivatedRoute 
} from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import {
  AlertController,
  IonContent,
  IonDatetime,
  IonSlides,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { VideoPopupComponent } from '../video-popup/video-popup.component';
declare var onYouTubePlayerAPIReady: any;

@Component({
  selector: 'app-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss'],
})

export class PageBuilderComponent implements OnInit {
  focus : any = {};
  left_arrow = false;
  @ViewChild('scrollContent') scroll!:ElementRef;
  @Input() viewContent;
  @Input() about_us;
  @Input() affiliated;
  @Input() seller_sub;
  @Input() sub_header;
  emailform : any = FormGroup;
  FAQ = false;
  @Input() home;
  @ViewChild(IonContent) private content!: IonContent;
  @ViewChild(IonSlides) slides:any = IonSlides;
  @ViewChild(IonDatetime, { static: true }) datetime!: IonDatetime;
  birthday_info:any={};
  submitted = false;
  video;
  month_list:any=[{name:'January'},{name:'February'},{name:'March'},{name:'April'},{name:'May'},{name:'June'},{name:'July'},{name:'August'},{name:'September'},{name:'October'},{name:'November'},{name:'December'}]
  slideOpts = {
    initialSlide: 1,
    speed: 400
  };
  child:any = [];
  sub_child;
  childCategory;
  question_data: any = [];
  enable_videos = false
  enable_videos_content = false
  news_letters_email_form:any = FormGroup;
  plan_details:any = [];
  subscription_details;
  btn;
  vimeo_video = 'https://player.vimeo.com/video/553307005?autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0&controls=0'  
  constructor(public db:DbService,public navCtrl:NavController,private alertCtrl:AlertController,private platform:Platform,private modalCtrl:ModalController,
    private datepipe:DatePipe,private location:Location,private formBuilder:FormBuilder,private router:Router,public sanitizer: DomSanitizer,private route:ActivatedRoute) {
    this.db.scroll_event.desktop_header = true;
    this.birthday_info.month = 'January';
    this.db.ismobile = this.db.checkmobile();
    this.db.content_video = false;
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.enable_video();
      }
   });
   }

  ngOnInit() {
    this.enable_videos = false
    this.enable_videos_content = false
    // this.db.mycart_emit.subscribe(res => {
      // console.log("cart emitted",this.viewContent);
      // res == 'getted' ? this.get_recommeded() : null 
    // })
    // let btn_json;
    // let redirect_url;
    // btn_json = this.viewContent.find(res => res.btn);
    this.db.content_video = false;

    this.viewContent.map(res =>{
      if(res.section_type == 'Static Section' && res.section_name == 'Content Video'){
        this.db.youtube_id = res.youtube_id;
        this.db.content_video = true;
      }
      if(res.section_type == 'Predefined Section' && res.section_name == 'FAQs'){
        res.data.map((res) =>{
          res.FAQ = false;
          this.question_data = res;
        })
      }
      // if(res.section_name == 'Membership Plan'){
      //   this.get_plan('Customer')
      // }
      // if(res.section_name == 'Seller Membership Plan'){
      //   this.get_plan('Vendor')
      // }
    })

    this.enable_video();
    this.db.ismobile = this.db.checkmobile();
    this.emailform = this.formBuilder.group({
      email : new FormControl('',[Validators.required,Validators.email]),
      birth_date : new FormControl((''),[Validators.required]),
      month: new FormControl((this.birthday_info.month || ''), Validators.required)
    })
    setTimeout(()=>{this.category_tabs_auto_select();},1000);
    this.news_letters_email_form =  this.formBuilder.group({
      news_letters_email:new FormControl('',[Validators.required,Validators.email])
    })
  
      // this.db.get_razorpay_settings();
      this.db.get_paymentgateway_settings();
      this.route.params.subscribe((res:any) => {
        this.db.header_menu = '/p/'+(res.page_route);
      })
  }

  
  sanitize_url(data){
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+data+"?autoplay=1&loop=1&title=0&byline=0&portrait=0&api=1&player_id=1");
  }
  sanitize_youtube_url(data){
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+data+"?&autoplay=1&loop=1&rel=0&showinfo=0&controls=0&color=white&iv_load_policy=3");
  }

  scrolled(event){
    if(event.target.scrollLeft != 0){
      this.left_arrow = true;
    }else if(event.target.scrollLeft == 0){
      this.left_arrow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  private func(){
    this.db.ismobile  = this.db.checkmobile();
  }

  get news_letters_email(){
    return this.news_letters_email_form.get('news_letters_email')
  }

  get email(){
    return this.emailform.get('email')
  }

  get birth_date(){
    return this.emailform.get('birth_date')
  }

  get month(){
    return this.emailform.get('month')
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.enable_video();
  }

  ionViewWillEnter() {
    this.db.content_video = false;
    this.db.childs = {};
    this.submitted = false;
    this.email.setValue('');
    this.month.setValue('January');
    this.birth_date.setValue('')
    this.content.scrollToTop();

     setTimeout(()=>{ this.enable_video(),2500});
  }

  enable_video(){
    let a = this.viewContent.find(res => res.youtube_id);
    let b = this.viewContent.find(res => res.youtube_video_id);
    if(a  && a.youtube_id){
      this.enable_videos = true;
      let w = this.db.ismobile ? '620px': '100%'
      let h = this.db.ismobile ? '350px': '100%'
      setTimeout(()=>{new onYouTubePlayerAPIReady(a.youtube_id,w,h);},1000);
    } 
    if(b  && b.youtube_video_id){
      // console.log("enable video",b.youtube_video_id)
      this.enable_videos_content = true;
      let w = '100%'
      let h = '100%'
      setTimeout(()=>{new onYouTubePlayerAPIReady(b.youtube_video_id,h,w);},1000);
    } 
  }

  slidePrev() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }

  mobile_slider = {
    // freeMode: true,
    // slidesPerView: this.db.ismobile? 1.2 : 1,
    spaceBetween: 1 ,
    speed: 400,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    },
  }

  web_slider = {
    // freeMode: true,
    speed: 400,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay:6000,
      disableOnInteraction: false
    },
  }

  // slideOpts = {
  //   // freeMode: true,
  //   slidesPerView: 1.3,
  //   spaceBetween: 10,
  //   speed: 400,
  //   loop: true,
  //   centeredSlides: true,
  //   autoplay: {
  //     delay: 1700,
  //     disableOnInteraction: false
  //   },
  //   navigation : true
  // }

  testimonial_slide ={
    // freeMode: true,
    slidesPerView: 4,
    spaceBetween: 8,
    speed: 400,
    loop: true,
    // centeredSlides: true,
    autoplay: {
      delay: 1700,
      disableOnInteraction: false
    },
  }

    //addtocart
    async addtocart(value) {
      // console.log(value)
      !value.ids ? (value.ids ='' ,value.selected_attribute ='') : null
      value.product_attributes && value.product_attributes.length != 0 ?   this.router.navigateByUrl('/pr/'+value.route) : this.db.addtocart(value)
    }

    async removetocart(value) {
      !value.ids ? (value.ids ='' ,value.selected_attribute ='') : null
      value.product_attributes && value.product_attributes.length != 0 ?   this.router.navigateByUrl('/pr/'+value.route) :this.db.removetocart(value)
    } 

    
    birthday_club(){
      this.submitted = true;
      if(this.emailform.status == 'VALID'){
      if(Number(this.emailform.value.birth_date) > 0 && Number(this.emailform.value.birth_date) < 32){
        let data = { email: this.emailform.value.email , domain: this.db.domain  }
          this.db.check_birthdayclub(this.emailform.value.email).subscribe(res=>{
            if(res.data && res.data.length != 0 && res.data[0].name){
                this.inert_birthday_club();
            } else {
                 this.db.alert('We could not find your email with our system.Please register as customer in meatton and then try to register as birthday club member.');
            }
          })
        }  else{
          this.db.alert('Birth Date value must be less than or equal to 31')
        }
        // this.db.insert_email_subscription(data).subscribe(res =>{
        //   this.db.alert(res.message);
        // })
      }
    }

    get_recommeded(){
      // console.log("view content ",this.db.viewContent)
      if(this.db.viewContent && this.db.viewContent.length != 0){
          this.db.viewContent.map(res => {
            if (res.data && res.data.length != 0 && (res.section_type == 'Lists' || res.section_type == 'Predefined Section' || res.section_type == 'Custom Section') ) {
              res.data.map(r => {
                r.temp_disabled = true;
                r.count = this.db.checkcart(r.name);
                r.wish_count = this.db.checkWishCart(r.name);
                r.temp_disabled = false;
                // if (r.vendor_price_list && r.vendor_price_list.length != 0) {
                //   r.vendor_price_list.find(rec =>{
                //     if(rec.variants && rec.variants != 0){
                //         rec.variants.map(data =>{
                //           if(data.attribute_id == rec.variants[0].attribute_id && !data.selected_variant && !this.db.is_variant){
                //             this.check_attribute_cart(r,r.vendor_price_list[0]);
                //           }else{
                //             data.count = this.check_attribute(data.attribute_id);
                //             res.count = data.count;
                //           }  
                //         })
                //     } 
                //   })
                // }
              })
            }
          })
      }
    }


    addtowish(value){
      if(value.wish_count != 0){
        this.db.removewish(value)
      } else if(value.wish_count == 0){
        if(value.has_variants == 0) {
          this.db.addtowish(value);
        } else {
          this.check_all_attributes_selected(value)
          // this.db.ismobile? this.router.navigateByUrl('/pr/'+value.route) : this.router.navigateByUrl('/pr/'+value.route)
            // this.db.ismobile? this.router.navigateByUrl('/pr/'+value.route) : this.openQuickView(value)
          // this.product_detail.ids ?   this.db.addtowish(value) : this.db.alert_animate.next("Please select attributes..!")
        }
      }
    }

    check_all_attributes_selected(value) {
    if(value.select_vendor_list){
      let select_values = value.select_vendor_list.variants.find(res=> res.radio_checked == 1)
      value.selected_attribute = select_values.variant_text;
      value.ids = select_values.attribute_id;
      this.db.addtowish(value) 
    }
  }

    inert_birthday_club(){
      // console.log(this.emailform.value)
      // let formData = {};
      // formData.doctype = "BirthDay Club Member"
      // formData.day = $('#birth_date').val();
      // formData.month = $('#birth_month').val();
        // formData.email = $('#birth_email').val();
      //   let empty = this.dateValue.split(' ')
      // const d = new Date(this.dateValue);
      // const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      let data = {
          doctype : "BirthDay Club Member",
          email : this.emailform.value.email,
          day : this.emailform.value.birth_date,
          month : this.emailform.value.month,
      }
      let datas = { doc : data}
      this.db.inert_birthdayclub(datas).subscribe(res =>{
        if(res.message){
          this.db.alert("Congratulations Your a Birthday Club member..!")
        } else {
          var b = JSON.parse(res._server_messages);
          var b1 = JSON.parse(b);
           this.db.alert(b1.message);
        }
        // console.log(res);
      })
    }

    // get_data_blogger(item){
    //   this.db.get_blogger_detail(item.blogger).subscribe(res =>{
    //     if(res.data){
    //       return res.data
    //     }
    //   })     
    // }

    changeDate(data){
      let temp = ''
      let latest_date:any =this.datepipe.transform(data, 'dd');
      let html = latest_date.split('-')
      html.map(res => {  temp = res })
      return temp
    }


    img_style(data, type) {
      if (type == 'color') {
        return { 'background': data };
      } else if (type == 'img') {
        return { 'background': 'url(' + this.db.product_img(data) + ')center center / 100% auto no-repeat', 'background-size': 'contain' };
      } else if (type == 'img-cover') {
        return { 'background': 'url(' + this.db.product_img(data) + ')center center / 100% auto no-repeat', 'background-size': 'contain' };
      }  else if (type == 'colorcode') {
        return { 'color': data };
      }else if (type == 'bgcolor') {
        return { 'background': data, 'color': data };
      } else if (type == 'full-bg-img') {
        return { 'background': 'url(' + this.db.product_img(data) + ') no-repeat'}
      } else if (type == 'bg-image') {
        return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat'}
      } else if (type == 'About-bg-image') {
        return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat',"background-attachment": "fixed","background-position": "center","background-size": "cover"}
      } else if (type == 'full-bg-img__') {
        return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat',"min-height": "160px","background-size": "cover","position":"relative"};
      } else if (type == 'full-bg-img_App_download') {
        return { 'background' : 'url('+ this.db.product_img(data) +')'};
      }
    }




    view_all(item){
      // console.log(item);
      let data={
        route:item
      }
      this.db.get_all_route(data).subscribe(res=>{
        // console.log(res);
        let data = res;
        // console.log(data.message.route_detail[0].route);
        if(data.message.type == 'Product'){}
            // this.navCtrl.push(ItemDescriptionPage, {item:data.message.route_detail[0], page:'home'})
          if(data.message.type == 'Product Category'){}
            // this.navCtrl.push(ProductlistPage, {item:data.message.route_detail[0], page:'home1'})
          if(data.message.type == 'Pages'){}
            // this.navCtrl.push(AddcarPage, {item:data.message.route_detail[0], page:'home'})
          if(data.message.type == 'Product Brand'){}
            // this.navCtrl.push(ProductlistPage, {item:data.message.route_detail[0], page:'home2'})
          if(data.message.type == 'Page Section'){this.viewall(data.message.route_detail[0].route)}
            
      })
    }


    viewall(data){
      // console.log(data);
      let params: NavigationExtras = {
                queryParams: {'id': data},
               }
      this.navCtrl.navigateForward(['viewall'], params);
      // this.router.navigateByUrl('/viewall/'+data);
    }


    emitScroll(scroll, direction) {    
        if (direction == 'right') {
          this.scroll.nativeElement.scrollLeft += 610;
         } else if (direction == 'left') {
        this.scroll.nativeElement.scrollLeft -= 610;
         }   
    }

  redirect_next_page(routUrl){
   
    if(routUrl){
      if(routUrl.indexOf('https') == 0){
      }else if(routUrl.indexOf('https') == -1){
        if(routUrl != ''){
          this.db.get_all_route( {'route' : routUrl }).subscribe((res)=>{
            if(res && res.message.type == 'Product Category')
                this.db.ismobile ? this.router.navigateByUrl('/shop' + '/' +routUrl) :this.router.navigateByUrl('/shop'+ '/' + routUrl);
            else if(res && res.message.type == 'Product')
                this.db.ismobile ? this.router.navigateByUrl('/pr'+ '/'+routUrl) :this.router.navigateByUrl('/pr'+ '/'+routUrl);
            else if(res && res.message.type == 'Pages'){
              // this.db.ismobile ? this.router.navigateByUrl('/pr'+routUrl) :this.router.navigateByUrl('/pr'+routUrl);
            }else if(res && res.message.type == 'Product Brand')
              this.db.ismobile ? this.router.navigateByUrl('/brands'+'/'+routUrl) :this.router.navigateByUrl('/brands'+'/'+routUrl);
            else if(res && res.message.type == 'Page Section')
              this.viewall(routUrl)
            else 
              this.router.navigateByUrl(routUrl)
        },(err) =>{ })
       } else {
        this.router.navigateByUrl(routUrl)
       }
      }
    }
  }

  go_via_route(route){
    !this.db.ismobile ? this.router.navigateByUrl('/viewall/'+route):this.router.navigateByUrl('/viewall/'+route);
  }

  async open_video() {
    let modal = await this.modalCtrl.create({
      component: VideoPopupComponent,
      cssClass: "video_popup",
      componentProps: {
          youtube_id: "pjAPUWV1ds8"
      }
   });
  //  this.modal = true;
    await modal.present();
    let { data } = await modal.onWillDismiss();
  }
// }, 1000);

  category_tabs(product,name){
    this.child = product.child;
    this.childCategory = name;
  }

  category_tabs_auto_select(){
    let c = 0;
    this.db.category.map(res => {
      if(res.child.length != 0 && c==0){
        this.child = res.child;
        this.childCategory = res.name; 
        c++;
      }
    }) 
  }

  news_letters(){
    this.submitted = true;
    if(this.news_letters_email_form.status == 'VALID'){
      let data = {
        email : this.news_letters_email_form.value.news_letters_email
      }
      this.db.insert_email_subscription(data).subscribe(res=>{
         if(res.message == "Success"){
            this.db.alert('Email submitted successfully');
            this.news_letters_email_form.reset();
            this.submitted = false;
         }
      })
    }
  }

  // Subscription Plan
  
  // get_plan(role) {
  //   this.db.ismobile = this.db.checkmobile();
  //   this.db.list_subscription_plans({role:role}).subscribe(res => {
  //     this.plan_details = res.message;
  //   })
  // }



  subscriptions(event, type) {
    if(type == 'vendor')
      localStorage['role'] == 'Vendor' ? this.insert_subscription(event) : this.db.alert("Only for seller. please visit the customer's subscription")
    else if(type == 'customer')
      localStorage['role'] != 'Vendor' ? this.insert_subscription(event) : this.db.alert("Only for customers. please visit the seller's subscription")
  }

  insert_subscription(event){
    var today : any  = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' +  dd;
        let data = {
          "party": localStorage['customerRefId'] ? localStorage['customerRefId'] : localStorage['CustomerId'],
          "party_name": localStorage['Customerfirst_name'] ? localStorage['Customerfirst_name'] : localStorage['CustomerName'],
          "start": today,
          "subscription_plan" : event.name,
          "paymentmethod" : "PAY-M00002",
          "party_type": localStorage['role'] == 'Vendor' ? 'Shop User' : ''
        }
        this.db.insert_web_subscription(data).subscribe(res => {
          if (res.message.status == "Failed") {
            var b = JSON.parse(res._server_messages);
            var b1 = JSON.parse(b);
            this.db.alert(b1.message);
          }
          else if (res.message.status == true) {
              this.subscription_details = res.message;
              this.router.navigateByUrl('/my-profile/subscriptions')
              // this.db.load_razorpay(this.subscription_details.order.total_amount,'Subscription,'+this.subscription_details.order.name);
          }
        })
  }


  product = [
    {
    "product": "ITEM-00026",
    "item": "Ladies' Finger",
    "business": "BS-00012",
    "center": "Bangalore DC",
    "price_list": "Standard Selling",
    "product_price": "PP00817",
    "price": 80,
    "count": 0,
    "old_price": 85,
    "has_variants": 1,
    "short_description": null,
    "tax_category": "CGST-18",
    "full_description": null,
    "sku": "Lfn",
    "name": "ITEM-00026",
    "route": "ladies-fingers-",
    "inventory_method": "Track Inventory By Product Attributes",
    "disable_add_to_cart_button": 0,
    "weight": 0,
    "gross_weight": 0,
    "approved_total_reviews": 0,
    "category": "MC-00001",
    "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
    "product_brand": null,
    "brand_route": null,
    "actual_price": 80,
    "actual_old_price": 300,
    "item_title": "Ladies' Finger",
    "role_based_price": 0,
    "vendor_price_list": [
        {
            "price_model": "Standard Pricing",
            "business": "BS-00012",
            "vendor_name": "Milano store",
            "product_price": 80,
            "old_price": 85,
            "discount_percentage": 73,
            "stock": 50,
            "default_variant": {
                "attribute_id": "75bd6cbd9d\n",
                "product_price": 80,
                "old_price": 85,
                "discount_percentage": 6,
                "variant_text": "Weight : 1kg",
                "stock": 49
            },
            "variants": [
                {
                    "attribute_id": "75bd6cbd9d\n",
                    "product_price": 80,
                    "old_price": 85,
                    "discount_percentage": 6,
                    "variant_text": "Weight : 1kg",
                    "stock": 49
                },
                {
                    "attribute_id": "ad9d1112df\n",
                    "product_price": 150,
                    "old_price": 180,
                    "discount_percentage": 17,
                    "variant_text": "Weight :  3kg",
                    "stock": 50
                },
                {
                    "attribute_id": "c65dceff04\n",
                    "product_price": 280,
                    "old_price": 300,
                    "discount_percentage": 7,
                    "variant_text": "Weight :  5kg",
                    "stock": 46
                }
            ]
        }
    ],
    "discount_percentage": 6
},
{
  "product": "ITEM-00026",
  "item": "Ladies' Finger",
  "business": "BS-00012",
  "center": "Bangalore DC",
  "price_list": "Standard Selling",
  "product_price": "PP00817",
  "price": 80,
  "count": 0,
  "old_price": 85,
  "has_variants": 1,
  "short_description": null,
  "tax_category": "CGST-18",
  "full_description": null,
  "sku": "Lfn",
  "name": "ITEM-00026",
  "route": "ladies-fingers-",
  "inventory_method": "Track Inventory By Product Attributes",
  "disable_add_to_cart_button": 0,
  "weight": 0,
  "gross_weight": 0,
  "approved_total_reviews": 0,
  "category": "MC-00001",
  "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
  "product_brand": null,
  "brand_route": null,
  "actual_price": 80,
  "actual_old_price": 300,
  "item_title": "Ladies' Finger",
  "role_based_price": 0,
  "vendor_price_list": [
      {
          "price_model": "Standard Pricing",
          "business": "BS-00012",
          "vendor_name": "Milano store",
          "product_price": 80,
          "old_price": 85,
          "discount_percentage": 73,
          "stock": 50,
          "default_variant": {
              "attribute_id": "75bd6cbd9d\n",
              "product_price": 80,
              "old_price": 85,
              "discount_percentage": 6,
              "variant_text": "Weight : 1kg",
              "stock": 49
          },
          "variants": [
              {
                  "attribute_id": "75bd6cbd9d\n",
                  "product_price": 80,
                  "old_price": 85,
                  "discount_percentage": 6,
                  "variant_text": "Weight : 1kg",
                  "stock": 49
              },
              {
                  "attribute_id": "ad9d1112df\n",
                  "product_price": 150,
                  "old_price": 180,
                  "discount_percentage": 17,
                  "variant_text": "Weight :  3kg",
                  "stock": 50
              },
              {
                  "attribute_id": "c65dceff04\n",
                  "product_price": 280,
                  "old_price": 300,
                  "discount_percentage": 7,
                  "variant_text": "Weight :  5kg",
                  "stock": 46
              }
          ]
      }
  ],
  "discount_percentage": 6
},
{
  "product": "ITEM-00026",
  "item": "Ladies' Finger",
  "business": "BS-00012",
  "center": "Bangalore DC",
  "price_list": "Standard Selling",
  "product_price": "PP00817",
  "price": 80,
  "count": 0,
  "old_price": 85,
  "has_variants": 1,
  "short_description": null,
  "tax_category": "CGST-18",
  "full_description": null,
  "sku": "Lfn",
  "name": "ITEM-00026",
  "route": "ladies-fingers-",
  "inventory_method": "Track Inventory By Product Attributes",
  "disable_add_to_cart_button": 0,
  "weight": 0,
  "gross_weight": 0,
  "approved_total_reviews": 0,
  "category": "MC-00001",
  "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
  "product_brand": null,
  "brand_route": null,
  "actual_price": 80,
  "actual_old_price": 300,
  "item_title": "Ladies' Finger",
  "role_based_price": 0,
  "vendor_price_list": [
      {
          "price_model": "Standard Pricing",
          "business": "BS-00012",
          "vendor_name": "Milano store",
          "product_price": 80,
          "old_price": 85,
          "discount_percentage": 73,
          "stock": 50,
          "default_variant": {
              "attribute_id": "75bd6cbd9d\n",
              "product_price": 80,
              "old_price": 85,
              "discount_percentage": 6,
              "variant_text": "Weight : 1kg",
              "stock": 49
          },
          "variants": [
              {
                  "attribute_id": "75bd6cbd9d\n",
                  "product_price": 80,
                  "old_price": 85,
                  "discount_percentage": 6,
                  "variant_text": "Weight : 1kg",
                  "stock": 49
              },
              {
                  "attribute_id": "ad9d1112df\n",
                  "product_price": 150,
                  "old_price": 180,
                  "discount_percentage": 17,
                  "variant_text": "Weight :  3kg",
                  "stock": 50
              },
              {
                  "attribute_id": "c65dceff04\n",
                  "product_price": 280,
                  "old_price": 300,
                  "discount_percentage": 7,
                  "variant_text": "Weight :  5kg",
                  "stock": 46
              }
          ]
      }
  ],
  "discount_percentage": 6
},
{
  "product": "ITEM-00026",
  "item": "Ladies' Finger",
  "business": "BS-00012",
  "center": "Bangalore DC",
  "price_list": "Standard Selling",
  "product_price": "PP00817",
  "price": 80,
  "count": 0,
  "old_price": 85,
  "has_variants": 1,
  "short_description": null,
  "tax_category": "CGST-18",
  "full_description": null,
  "sku": "Lfn",
  "name": "ITEM-00026",
  "route": "ladies-fingers-",
  "inventory_method": "Track Inventory By Product Attributes",
  "disable_add_to_cart_button": 0,
  "weight": 0,
  "gross_weight": 0,
  "approved_total_reviews": 0,
  "category": "MC-00001",
  "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
  "product_brand": null,
  "brand_route": null,
  "actual_price": 80,
  "actual_old_price": 300,
  "item_title": "Ladies' Finger",
  "role_based_price": 0,
  "vendor_price_list": [
      {
          "price_model": "Standard Pricing",
          "business": "BS-00012",
          "vendor_name": "Milano store",
          "product_price": 80,
          "old_price": 85,
          "discount_percentage": 73,
          "stock": 50,
          "default_variant": {
              "attribute_id": "75bd6cbd9d\n",
              "product_price": 80,
              "old_price": 85,
              "discount_percentage": 6,
              "variant_text": "Weight : 1kg",
              "stock": 49
          },
          "variants": [
              {
                  "attribute_id": "75bd6cbd9d\n",
                  "product_price": 80,
                  "old_price": 85,
                  "discount_percentage": 6,
                  "variant_text": "Weight : 1kg",
                  "stock": 49
              },
              {
                  "attribute_id": "ad9d1112df\n",
                  "product_price": 150,
                  "old_price": 180,
                  "discount_percentage": 17,
                  "variant_text": "Weight :  3kg",
                  "stock": 50
              },
              {
                  "attribute_id": "c65dceff04\n",
                  "product_price": 280,
                  "old_price": 300,
                  "discount_percentage": 7,
                  "variant_text": "Weight :  5kg",
                  "stock": 46
              }
          ]
      }
  ],
  "discount_percentage": 6
},
{
  "product": "ITEM-00026",
  "item": "Ladies' Finger",
  "business": "BS-00012",
  "center": "Bangalore DC",
  "price_list": "Standard Selling",
  "product_price": "PP00817",
  "price": 80,
  "count": 0,
  "old_price": 85,
  "has_variants": 1,
  "short_description": null,
  "tax_category": "CGST-18",
  "full_description": null,
  "sku": "Lfn",
  "name": "ITEM-00026",
  "route": "ladies-fingers-",
  "inventory_method": "Track Inventory By Product Attributes",
  "disable_add_to_cart_button": 0,
  "weight": 0,
  "gross_weight": 0,
  "approved_total_reviews": 0,
  "category": "MC-00001",
  "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
  "product_brand": null,
  "brand_route": null,
  "actual_price": 80,
  "actual_old_price": 300,
  "item_title": "Ladies' Finger",
  "role_based_price": 0,
  "vendor_price_list": [
      {
          "price_model": "Standard Pricing",
          "business": "BS-00012",
          "vendor_name": "Milano store",
          "product_price": 80,
          "old_price": 85,
          "discount_percentage": 73,
          "stock": 50,
          "default_variant": {
              "attribute_id": "75bd6cbd9d\n",
              "product_price": 80,
              "old_price": 85,
              "discount_percentage": 6,
              "variant_text": "Weight : 1kg",
              "stock": 49
          },
          "variants": [
              {
                  "attribute_id": "75bd6cbd9d\n",
                  "product_price": 80,
                  "old_price": 85,
                  "discount_percentage": 6,
                  "variant_text": "Weight : 1kg",
                  "stock": 49
              },
              {
                  "attribute_id": "ad9d1112df\n",
                  "product_price": 150,
                  "old_price": 180,
                  "discount_percentage": 17,
                  "variant_text": "Weight :  3kg",
                  "stock": 50
              },
              {
                  "attribute_id": "c65dceff04\n",
                  "product_price": 280,
                  "old_price": 300,
                  "discount_percentage": 7,
                  "variant_text": "Weight :  5kg",
                  "stock": 46
              }
          ]
      }
  ],
  "discount_percentage": 6
},
{
  "product": "ITEM-00026",
  "item": "Ladies' Finger",
  "business": "BS-00012",
  "center": "Bangalore DC",
  "price_list": "Standard Selling",
  "product_price": "PP00817",
  "price": 80,
  "count": 0,
  "old_price": 85,
  "has_variants": 1,
  "short_description": null,
  "tax_category": "CGST-18",
  "full_description": null,
  "sku": "Lfn",
  "name": "ITEM-00026",
  "route": "ladies-fingers-",
  "inventory_method": "Track Inventory By Product Attributes",
  "disable_add_to_cart_button": 0,
  "weight": 0,
  "gross_weight": 0,
  "approved_total_reviews": 0,
  "category": "MC-00001",
  "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
  "product_brand": null,
  "brand_route": null,
  "actual_price": 80,
  "actual_old_price": 300,
  "item_title": "Ladies' Finger",
  "role_based_price": 0,
  "vendor_price_list": [
      {
          "price_model": "Standard Pricing",
          "business": "BS-00012",
          "vendor_name": "Milano store",
          "product_price": 80,
          "old_price": 85,
          "discount_percentage": 73,
          "stock": 50,
          "default_variant": {
              "attribute_id": "75bd6cbd9d\n",
              "product_price": 80,
              "old_price": 85,
              "discount_percentage": 6,
              "variant_text": "Weight : 1kg",
              "stock": 49
          },
          "variants": [
              {
                  "attribute_id": "75bd6cbd9d\n",
                  "product_price": 80,
                  "old_price": 85,
                  "discount_percentage": 6,
                  "variant_text": "Weight : 1kg",
                  "stock": 49
              },
              {
                  "attribute_id": "ad9d1112df\n",
                  "product_price": 150,
                  "old_price": 180,
                  "discount_percentage": 17,
                  "variant_text": "Weight :  3kg",
                  "stock": 50
              },
              {
                  "attribute_id": "c65dceff04\n",
                  "product_price": 280,
                  "old_price": 300,
                  "discount_percentage": 7,
                  "variant_text": "Weight :  5kg",
                  "stock": 46
              }
          ]
      }
  ],
  "discount_percentage": 6
},
{
  "product": "ITEM-00026",
  "item": "Ladies' Finger",
  "business": "BS-00012",
  "center": "Bangalore DC",
  "price_list": "Standard Selling",
  "product_price": "PP00817",
  "price": 80,
  "count": 0,
  "old_price": 85,
  "has_variants": 1,
  "short_description": null,
  "tax_category": "CGST-18",
  "full_description": null,
  "sku": "Lfn",
  "name": "ITEM-00026",
  "route": "ladies-fingers-",
  "inventory_method": "Track Inventory By Product Attributes",
  "disable_add_to_cart_button": 0,
  "weight": 0,
  "gross_weight": 0,
  "approved_total_reviews": 0,
  "category": "MC-00001",
  "product_image": "/files/download 11-4-17-2023,7:54:47PM_250x250.jpg",
  "product_brand": null,
  "brand_route": null,
  "actual_price": 80,
  "actual_old_price": 300,
  "item_title": "Ladies' Finger",
  "role_based_price": 0,
  "vendor_price_list": [
      {
          "price_model": "Standard Pricing",
          "business": "BS-00012",
          "vendor_name": "Milano store",
          "product_price": 80,
          "old_price": 85,
          "discount_percentage": 73,
          "stock": 50,
          "default_variant": {
              "attribute_id": "75bd6cbd9d\n",
              "product_price": 80,
              "old_price": 85,
              "discount_percentage": 6,
              "variant_text": "Weight : 1kg",
              "stock": 49
          },
          "variants": [
              {
                  "attribute_id": "75bd6cbd9d\n",
                  "product_price": 80,
                  "old_price": 85,
                  "discount_percentage": 6,
                  "variant_text": "Weight : 1kg",
                  "stock": 49
              },
              {
                  "attribute_id": "ad9d1112df\n",
                  "product_price": 150,
                  "old_price": 180,
                  "discount_percentage": 17,
                  "variant_text": "Weight :  3kg",
                  "stock": 50
              },
              {
                  "attribute_id": "c65dceff04\n",
                  "product_price": 280,
                  "old_price": 300,
                  "discount_percentage": 7,
                  "variant_text": "Weight :  5kg",
                  "stock": 46
              }
          ]
      }
  ],
  "discount_percentage": 6
}
]

}



