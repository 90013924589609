import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss'],
})
export class GuestLoginComponent implements OnInit {

   loginform:any = FormGroup;
   submitted = false;
   focus :any= {}; //Focus styling
   shake;
   alert_message;
   show_pass = true;
   @Input() name;
   @Input() email_id;
   @Input() phone_no;

  constructor(private formbuilder:FormBuilder,public db:DbService,public modalctrl:ModalController) { }

  ngOnInit() {
    this.loginform = this.formbuilder.group({
      first_name : new FormControl(this.name,[Validators.required]),
      email : new FormControl (this.email_id,[Validators.required,Validators.email]),
      phone: [this.phone_no,[Validators.required, Validators.pattern("[0-9]{10}")]],
      })
  }

  get email(){
    return this.loginform.get('email');
  }

  get first_name(){
    return this.loginform.get('first_name');
  }

  get phone() {
    return this.loginform.get('phone');
  }


  //Form login Method..
  login() {
    this.submitted = true;
    // console.log(this.loginform)
    // console.log(this.loginform.value)
    this.submitted = true;

    if (this.loginform.status == 'VALID') {
      let data={
        first_name:this.loginform.value.first_name,
        email:this.loginform.value.email,
        phone: this.loginform.value.phone,
        customer_id:localStorage['customerRefId'],
        is_mobile:1
      }

    // this.db.store_geust_customer_info(this.loginform.value);

    // this.db.update_guest_customer_data(data).subscribe(res=>{
    //   console.log(res)
    // })
    this.db.update_checkout_guest_customer(data).subscribe(res=>{
      // console.log(res);
      // console.log(res._server_messages);
      // get_customer_info
      if(res.message && res.message['status'] == "success"){
        // this.db.store_gest_customer_info(res.message['customer'])
        let data=[{'email':res.message.email,'first_name':this.loginform.value.first_name,'phone':this.loginform.value.phone,'name':res.message.name}]
        this.db.check_redif = -1
        this.db.store_geust_customer_info(data);
        this.db.get_address();
        this.db.getShippingMethods();
        this.db.ismobile ? this.modalctrl.dismiss() : '';
      } else {
        var b = JSON.parse(res._server_messages);
        var b1 = JSON.parse(b);
        this.alert_message = b1.message
        this.db.alert(this.alert_message);
      }
    })
    }else{
      this.shake = true;
      setTimeout( () => { this.shake = false },400)
    }
  }

}
