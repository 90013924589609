<div class="main-width mini-100">
  <div class="" >

    <ul class="Bread-crumb mb-15" *ngIf="!db.ismobile && !product_info">
        <li class="d__flex fnt-15"> <a class="mr-5 mouse" routerLink="/" >Home</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
        <li class="d__flex fnt-15 secondary-name" *ngIf="!db.childs.child_1_name && !db.childs.child_2_name && !db.childs.child_3_name">
          <ion-skeleton-text class="mr-5 secondary-skele mouse" *ngIf="!product_detail.product_categories" animated ></ion-skeleton-text>
          <a class="mr-5 mouse secondary-name" *ngIf="product_detail.item_categories" routerLink="/shop/{{product_detail.item_categories.route}}" routerDirection="forward"  >{{product_detail.item_categories.category_name}}</a><ion-icon class="mr-5" name="chevron-forward"></ion-icon>
        </li>

        <li class="d__flex" *ngIf="db.childs.child_1_name"> <a class="mr-5 mouse" (click)="db.childs.child_2_name = undefined" (click)="db.childs.child_3_name = undefined"  [routerLink]="'/shop/'+db.childs.child_1" routerDirection="forward" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_1_name}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
        <li class="d__flex" *ngIf="db.childs.child_2_name"> <a class="mr-5 mouse" (click)="db.childs.child_3_name = undefined" [routerLink]="'/shop/'+db.childs.child_1+'/'+db.childs.child_2" routerDirection="forward" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_2_name}}</a>  <ion-icon class="mr-5" name="chevron-forward"></ion-icon>  </li>
        <li class="d__flex" *ngIf="db.childs.child_3_name"> <a class="mr-5 mouse" [routerLink]="'/shop/'+db.childs.child_1+'/'+db.childs.child_2+'/'+db.childs.child_3" routerDirection="forward" routerLinkActive="active-bread" [routerLinkActiveOptions]="{exact: true}">{{db.childs.child_3_name}}</a>  </li>
        <li class="d__flex fnt-15 primary-name">
          <ion-skeleton-text class="mouse mr-5 primary-skele" *ngIf="!product_detail.item" animated ></ion-skeleton-text>
          <a class="mouse mr-5 primary active-bread primary-name" *ngIf="product_detail.item">{{product_detail.item}}</a>
        </li>
    </ul>


    <div class="container">

      <div class="sticky-image ">
        <div class="image-container" >

          <div *ngIf="!db.ismobile && product_detail.image && product_detail.images.length > 1" class="sub-images">
              <div class="image mouse" *ngFor="let image of product_detail.images" [ngClass]="(main_image == (image.product_image || image.image))?'active-image':''"  (mouseenter)="main_image = (image.product_image || image.image)" (click)="main_image = (image.product_image || image.image)">
                  <img *ngIf="image.detail_thumbnail" src="{{db.product_img(image.detail_thumbnail)}}" alt="" defaultImage>
              </div>
              <!-- <div *ngIf="!product_detail.images">
                  <div class="image mouse" *ngFor="let i of [1,1,1]">
                    <ion-skeleton-text *ngIf="skeleton" animated class="m-0"></ion-skeleton-text>
                  </div>
              </div> -->
          </div>

          <div class="main-image" *ngIf="!db.ismobile" >
              <ion-skeleton-text *ngIf="skeleton && !main_image " animated class="m-0"></ion-skeleton-text>
              <img *ngIf="main_image || product_detail.product_image" src="{{db.product_img(main_image ||product_detail.product_image)}}" defaultImage="" src="">
            
              <!-- <ion-slides pager="true" [options]="db.ismobile ? mobile_slider : web_slider" loop="true">
                <ion-slide *ngFor="let image of product_detail.images">
                  <ion-skeleton-text *ngIf="skeleton &&  !main_image" animated class="m-0"></ion-skeleton-text>
                  <img src="{{db.product_img(image.product_image)?db.product_img(image.product_image):db.product_img(image.image)}}" alt="" defaultImage>
                </ion-slide>
              </ion-slides> -->

              <span class="m-0" class="discount" *ngIf="product_detail.discount_percentage">{{product_detail.discount_percentage}}% Off</span>
          </div>

          <div *ngIf="db.ismobile">
            <div *ngIf="skeleton &&  !main_image" class="row-slider row just-c w-90">
              <div class="slider-section w-100">
                  <ion-thumbnail class="br-7 w-100">
                      <ion-skeleton-text animated></ion-skeleton-text>
                  </ion-thumbnail>
              </div>
            </div>

            <ion-slides pager="true" class="mobile-main-image">
              <ion-slide *ngFor="let image of product_detail.images">
                <!-- <ion-skeleton-text *ngIf="skeleton &&  !main_image" animated class="m-0 max-height-slide"></ion-skeleton-text> -->
                <img src="{{db.product_img(image.product_image)?db.product_img(image.product_image):db.product_img(image.image )}}" alt="" defaultImage>
              </ion-slide>
            </ion-slides>
          </div>

        </div>
      </div>

      <div class="description">

        <ion-skeleton-text *ngIf="skeleton && !product_detail.item" animated style="height: 30px;" class="my-5 w-75 br-3"></ion-skeleton-text>

        <div *ngIf="false && db.ismobile && (product_detail.free_item || product_detail.rating)" class="d__flex just-s mobile__star__rating">
          <div class="d__flex free-shipping"> <div *ngIf="product_detail.free_item"><ion-icon class="fnt-12" name="information-circle-outline"></ion-icon> Free Shipping</div></div>
          <div class="star-rating" *ngIf="product_detail.rating"> <ion-icon src="/assets/districo/star-02.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:product_detail.rating"></ion-icon> </div>
        </div>

        <div class="mobile__desc mb-5">
          <div>

            <div class="d__flex just-s">
              <h6 class="product__name">{{product_detail.item}}</h6>
              <!-- <div *ngIf="!db.ismobile && false" class="d__flex mouse"  (click)="db.share()"> <span>Share</span> <ion-icon name="arrow-redo-outline" class="share-icon"></ion-icon></div> -->
            </div>

            <div class="" *ngIf="skeleton &&  !product_detail.short_description && !product_detail.rating">
              <ion-skeleton-text  animated class="m-0 w-50 my-2" style="height: 20px;"></ion-skeleton-text>
              <ion-skeleton-text  animated class="m-0 w-25 my-2" style="height: 20px;"></ion-skeleton-text>
            </div>

            <div class="" *ngIf="product_detail.short_description || product_detail.rating">
              <p class="m-0 sub__name webkit-text" [style.--line]="3" *ngIf="product_detail.short_description"> {{product_detail.short_description}}  </p>
              <div class="star-rating" *ngIf="product_detail.rating"> <ion-icon class="star" src="/assets/districo/star-02.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:product_detail.rating"></ion-icon> </div>
              <!-- <div class="star-rating" *ngIf="product_detail.rating"> <ion-icon class="star" name="star"></ion-icon> {{ product_detail.rating}}</div> -->
            </div>

            <div class="m-0 brand-name" *ngIf="product_detail.brands && product_detail.brands.length !=0 ">by<span class="brand_title alternative mouse" *ngFor="let b of product_detail.brands" routerLink="/brands/{{b.route}}">{{b.brand_name}}</span></div>
          
            <!-- <div class="product__categories fnt-14" *ngIf="product_detail.product_categories && product_detail.product_categories.length != 0">
              <span>Categories : </span> <a *ngFor="let item of product_detail.product_categories;let i = index" routerLink="/shop/{{item.route}}" [routerDirection]="db.ismobile?null:'root'">{{item.category_name}} <span *ngIf="i > i-1"></span></a>
            </div> -->

            <div class="d__flex weight__specify">
              <div *ngIf="product_detail.gross_weight">Gross Wt:  {{product_detail.gross_weight >= 1000?product_detail.gross_weight/1000:product_detail.gross_weight}}  {{product_detail.gross_weight >= 1000?'Kg':'gms'}} </div>
              <span [class.net]="product_detail.weight" *ngIf="product_detail.weight"> / </span>
              <div  *ngIf="product_detail.weight">Net Wt: {{product_detail.weight >= 1000?product_detail.weight/1000:product_detail.weight}} {{product_detail.weight >= 1000 ?'Kg':'gms'}} </div>
            </div>

            <div class="prices" *ngIf="(product_detail.price || product_detail.old_price) && product_detail.show_price != 0">
              <div class="m-0 price">
                <span *ngIf="product_detail.price">{{product_detail.price  | currency:db.website_settings.currency}}</span>
              </div>
              <div class="m-0 s-price l-t">
                <span *ngIf="product_detail.old_price != 0">{{product_detail.old_price  | currency:db.website_settings.currency}}</span>
              </div>
            </div>
            
            <div class="d__flex gap-10" *ngIf="skeleton &&  !product_detail.price && !product_detail.old_price">
              <ion-skeleton-text  animated class="m-0" style="width:90px;height: 10px;"></ion-skeleton-text>
              <ion-skeleton-text  animated class="m-0" style="width:90px;height: 10px;"></ion-skeleton-text>
            </div>

            <div *ngIf="product_detail.business_name" class="m-0 seller d__flex">
              <img src="{{product_detail.restaurant_logo}}">
              <h4>{{product_detail.business_name}}</h4>
              <!-- <div class="seller_title"></div> -->
              <span class="title_name mouse" routerLink="/vendor-products/{{product_detail.business_route}}">Visit {{product_detail.business_name}}</span>
            </div>

          </div>
        
        </div>


  <!--Products Attribute Variants -->
        <div class="attributes" *ngIf="product_detail.product_attributes && product_detail.product_attributes.length != 0">

            <!-- Not a table format -->
          <div *ngIf="product_detail.show_price != 0">
            <div *ngFor="let item of product_detail.product_attributes">
              <h6 class="attribute__name">{{item.attribute}}</h6>

              <!-- drop down list -->
              <div #attribute_dropdown *ngIf="item.control_type == 'Dropdown List'" class="attribute__drop__down">          
              <!-- Customize drop down  list -->
                <div class="d__flex selected__drop__down mouse" *ngIf="false">
                  <div class="selected__txt" (click)="item.drop_down = 1" ><div> {{db.check_attribute_label(item.options)}} </div> <ion-icon name="chevron-down-outline" class="fnt-12"></ion-icon></div>
                  <div *ngIf=" item.drop_down == 1" class="drop__down__options" (mouseleave)="item.drop_down = 0">
                    <div [class.active__drop__option]="option.is_pre_selected && option.is_pre_selected == 1" class="drop__down__option mouse"  *ngFor="let option of item.options" (click)="select(item.attribute,option.name)"  (click)="item.drop_down = 0">
                      {{option.option_value}}
                      <!-- <span >{{option.iscart}}</span>  -->
                    </div>
                  </div>
                </div>

                <ion-select placeholder="Select" interface="popover" [value]="check_active_radio(item.options)" class="drop__down__options" (mouseleave)="item.drop_down = 0" (ionChange)="select_dropdown($event,item.attribute)" >
                  <ion-select-option   *ngFor="let option of item.options" [value]="option.name">{{option.option_value}}  </ion-select-option>
                </ion-select>
              </div>

              <!-- Radio button list -->
              <div #attibute_dropdown *ngIf="item.control_type == 'Radio Button List'">
                  <!-- Customize radio button -->
                  <!-- <div class="d__flex flex-w" *ngIf="false">
                    <div *ngFor="let option of item.options" class="radio__attribute__option  d__flex mouse" [class.active__radio__option]="option.is_pre_selected && option.is_pre_selected == 1" (click)="select(item.attribute,option.name)" >
                      <div class="main-dot d__flex just-c"><div class="inner-dot d__flex"> </div> </div>
                      <div>{{option.option_value}}</div>
                      <div class="attribute-cart" *ngIf="option.iscart != 0 && false" > <ion-icon *ngIf="false" src="/assets/icon/unique-cart.svg"></ion-icon> <ion-badge >{{option.iscart}}</ion-badge></div>
                    </div>
                  </div> -->

                  <ion-radio-group class="d__flex flex-w gap-10" [value]="check_active_radio(item.options)" (ionChange)="select_radio($event,item.attribute)" >
                    <ion-item lines="none" *ngFor="let option of item.options" class="radio__attribute__option mouse gap-10" [class.active__radio__option]="option.is_pre_selected && option.is_pre_selected == 1" [class.image-option]="option.images && option.images.length != 0" >
                      <ion-radio slot="start" [value]="option.name" [class.image-radio]="option.images && option.images.length != 0"></ion-radio>
                      <div>
                        <img *ngIf="option.images && option.images.length != 0"  [src]="check_active_image(option.images)">
                        <ion-label class="ion-text-center fnt-12"  [style.max-width]="'300px'"> {{option.option_value}}</ion-label> 
                      </div>
                      <div class="attribute-cart" *ngIf="option.iscart != 0 && false" > <ion-icon src="/assets/icon/unique-cart.svg"></ion-icon> <ion-badge >{{option.iscart}}</ion-badge></div>
                    </ion-item>
                  </ion-radio-group>
              </div>

            </div>
          </div>


        <!-- Table format -->
        <app-table-attribute *ngIf="product_detail.show_price == 0" [product_detail]="product_detail"></app-table-attribute>

        
      </div>


      <div  class="variant__error" [class.shaking]="variant_error_shaking" *ngIf="variant_error">Please select all attribute variants</div>

      <div class="descriptions">

          <div *ngIf="product_detail.highlights && product_detail.highlights.length != 0" class="highlights">
              <div>
                <ion-skeleton-text *ngIf="skeleton &&  !product_detail.highlights" style="width: 14%;" animated class="m-0"></ion-skeleton-text>
                <h6>Highlights</h6>
              </div>
              <ul>
                <div  *ngIf="skeleton && !product_detail.highlights"><li class="d__flex"  *ngFor="let item of [1,2,3,4,5]"><ion-skeleton-text animated class="m-0"></ion-skeleton-text></li></div>
                <li *ngFor="let list of product_detail.highlights">{{list.highlights}}</li>
              </ul>
          </div>

          <div class="d__flex gap-10 bg-white" *ngIf="skeleton &&  !product_detail.show_price && !product_detail.show_price && !db.ismobile">
            <ion-skeleton-text  animated class="m-0" style="width:150px;height: 45px;"></ion-skeleton-text>
            <ion-skeleton-text  animated class="m-0" style="width:60px;height: 45px;"></ion-skeleton-text>
            <ion-skeleton-text  animated class="m-0" style="width:60px;height: 45px;"></ion-skeleton-text>
          </div>

          <!-- Normal add to cart -->
          <div class="d__flex main-buttons gap-10" *ngIf="product_detail.show_price &&!db.ismobile && product_detail.show_price != 0">

            <app-cart-button [big_button]="true" [product_info]="product_detail" (addto_cart)="addtocart(product_detail,'shopping')" (removeto_cart)="removetocart(product_detail)"></app-cart-button>
            
            <div *ngIf="db.website_settings.enable_multi_vendor == 1" class="mouse buy_now ion-activatable ripple-parent" (click)="addtocart(product_detail,'buy_now')">
              <ion-ripple-effect></ion-ripple-effect> 
              <div class="d__flex buy_now_button gap-5">
                <ion-icon src='/assets/icon/cart.svg'></ion-icon>
                <span class="add__txt" > Buy Now</span>
              </div>         
            </div>
          
            <!-- <ion-button  class="wishlist__button" fill="clear"   (click)="addtocart(product_detail,'wishlist')"> 
              <ion-icon [name]="product_detail.wish_count && product_detail.wish_count != 0 ? 'heart':'heart-outline'"></ion-icon>
            </ion-button> -->

            <ion-button  class="share__button" fill="clear"   (click)="db.share('product')"> 
              <ion-icon name='arrow-redo-outline' ></ion-icon> 
            </ion-button> 

            <!-- <div class="cart-buttons" *ngIf="!db.check_stocks(product_detail) && (!db.check_attribute_stock(product_detail.stock_alert_message,product_detail))" >

              <div  class="cart__button d__flex ion-activatable ripple-parent" [class.mouse]="product_detail.count == 0" (click)="(product_detail.count == 0 && !(db.check_disabled(product_detail)) && !product_detail.stock_alert_message)?addtocart(product_detail,'shopping'):''" [class.disabled]="db.check_disabled(product_detail) || product_detail.stock_alert_message" [ngClass]="product_detail.count == 0?'not__added':'__added'">

                  <ion-ripple-effect *ngIf="product_detail.count == 0"></ion-ripple-effect>

                  <ion-button class="mouse __button" *ngIf="product_detail.count && product_detail.count != 0"  (click)="removetocart(product_detail)" fill="clear"><span>-</span></ion-button>
                  
                  <div class="d__flex gap-10">
                    <ion-icon *ngIf="product_detail.count == 0" src='/assets/districo/add-cart.svg'></ion-icon>
                    <span class="add webkit-text"  [class._added]="product_detail.count != 0" [style.--line]="1" [class.no-add]="product_detail.count == 0" > <span [class.__dtl__added]="product_detail.count && product_detail.count != 0" *ngIf=" !product_detail.temp_disabled"> {{product_detail.count && (product_detail.count != 0)?product_detail.count: 'Add To Cart'}}</span>  <ion-spinner class="ion-text-center" *ngIf="product_detail.temp_disabled"></ion-spinner></span>
                  </div> 
                  
                  <ion-button class="mouse __button" *ngIf="product_detail.count && product_detail.count != 0" (click)="( product_detail.count != 0 && !product_detail.stock_alert_message )?addtocart(product_detail,'shopping'):''" [disabled]="db.check_disabled(product_detail)" fill="clear" ><span>+</span></ion-button>

              </div>
            </div> -->
            <!-- <ion-button *ngIf="db.check_stocks(product_detail) || db.check_attribute_stock(product_detail.stock_alert_message,product_detail)" (click)="db.notify_me(product_detail)"  class="notify-me" expand="full" fill="clear">Notify Me</ion-button> -->

          </div>

          <div class="alternative stock" *ngIf="product_detail.show_inventory == 1 && product_detail.product_attributes.length == 0 && !(product_detail.inventory_method == 'Track Inventory' && product_detail.stock == 0)">In Stock</div>

          <p class="m-0 fnt-12 no-stock" *ngIf="product_detail.stock_alert_message">{{product_detail.stock_alert_message}}</p>

          <p class="m-0 stock alternative"  *ngIf="product_detail.inventory_method == 'Track Inventory' && product_detail.stock == 0">No stock</p>

          <ul *ngIf="!product_detail.full_description && skeleton" class="bg-white">
            <li class="d__flex"  *ngFor="let item of [1,2,3,4,5]"><ion-skeleton-text animated class="my-2" style="height: 20px;width:90%;"></ion-skeleton-text></li>
            <ion-skeleton-text animated class="my-2" style="height: 20px;width:50%;"></ion-skeleton-text>
            <ion-skeleton-text animated class="my-2" style="height: 20px;width:25%;"></ion-skeleton-text>
            <ion-skeleton-text animated class="my-2" style="height: 20px;width:90%;"></ion-skeleton-text>
          </ul>

          <div class="product-desc" *ngIf="product_detail.full_description">
            <div class="title__heading d__flex just-s">
              <h6 class="">Product Detail</h6>
              <ion-icon (click)="show_more('showmore1')" *ngIf="db.ismobile" [name]="!showmore.showmore1?'add-outline':'remove-outline'"></ion-icon>
            </div>
            <div class="desc" >
              <div [class.main-desc]="db.ismobile" [ngClass]="showmore.showmore1?'showmore':'hide'" [innerHTML]="product_detail.full_description"> </div>
              <div  class="show-button" *ngIf="db.ismobile && false"><ion-button (click)="show_more('showmore1')" shape="rounded" fill="clear" >{{showmore.showmore1?'Hide':'Show more.,'}}</ion-button></div>
            </div>
          </div>

          <div class="return-policy"  *ngIf="product_detail.return_description">
            <div class="title__heading d__flex just-s">
              <h6 class="">Variable Weight Adjustment Policy</h6>
              <ion-icon (click)="show_more('showmore2')" *ngIf="db.ismobile" [name]="!showmore.showmore2?'add-outline':'remove-outline'"></ion-icon>
            </div>
            <div class="return-desc">
              <div class="m-0" [class.main-desc]="db.ismobile" [ngClass]="showmore.showmore2?'showmore':'hide'" >{{product_detail.return_description}}</div>
              <div class="show-button" *ngIf="db.ismobile && false"><ion-button (click)="show_more('showmore2')" shape="rounded" fill="clear" >{{showmore.showmore2?'Hide':'Show more.,'}}</ion-button></div>
            </div>
          </div>

          <div class="product-desc" *ngIf="product_detail.product_specification && product_detail.product_specification.length !=0">
            <div class="title__heading d__flex just-s">
              <h6 class="">Product Specification</h6>
              <ion-icon (click)="show_more('showmore5')" *ngIf="db.ismobile" [name]="!showmore.showmore5?'add-outline':'remove-outline'"></ion-icon>
            </div>
          
            <div *ngIf="showmore.showmore5 || !db.ismobile" class="specification"  [class.main-desc]="db.ismobile" [ngClass]="showmore.showmore5?'showmore':'hide'"> 
                <div *ngFor="let specification of product_detail.product_specification">
                    <h2 *ndIf="specification.name" class="m-0">specification.name</h2>
                    <div class="d__flex specification_group" *ngFor="let group of specification.groups">
                      <p class="m-0 attr">{{group.specification_attribute}} : </p>
                      <p class="m-0 opts">{{group.options}}</p>
                    </div>
                </div>
            </div>
          
          </div>

          <div class="review" *ngIf="product_detail">

            <div class=" inner-review">
              <div  class="title__heading d__flex just-s">
                <h6>Ratings & Reviews</h6>
                <ion-icon (click)="show_more('showmore3')" *ngIf="db.ismobile" [name]="!showmore.showmore3?'add-outline':'remove-outline'"></ion-icon>
              </div>
            </div>
            <div  [class.main-desc]="db.ismobile" class="review-desc" [ngClass]="showmore.showmore3?'showmore':'hide'">
              <ion-item lines="none" *ngFor="let item of product_detail.reviews">
                <div>
                  <div class="d__flex reviewer">
                    <ion-icon    class="icon px-15" src="/assets/districo/admin-p.svg"></ion-icon>
                    <p class="user m-0">{{item.customer}}</p>
                    <!-- <div class="d__flex">
                      <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      <p class="m-0">{{item.creation | date}}</p>
                    </div> -->
                  </div>

                  <div class="d__flex rating_con">
                    <div class="rating"> 
                      <div class="star-rating" *ngIf="item.rating"> <ion-icon src="/assets/districo/star-02.svg" *ngFor="let item of [0,1,2,3,4] | slice:0:item.rating"></ion-icon> </div>
                      <!-- <div class="star-rating" *ngIf="product_detail.rating"> <ion-icon class="star" name="star"></ion-icon> {{ item.rating}}</div> -->
                      <!-- <ion-icon name="star"></ion-icon>{{item.rating}} -->
                    </div>
                    <p *ngIf="false" class="star-rating-title">{{item.review_title}}</p>
                  </div>
                  <div class="rating-message"> {{item.review_message}} </div>

                </div>
              </ion-item>
            
              <div class="d__flex just-s mb-10">
                <p *ngIf="product_detail.reviews && product_detail.reviews.length == 0" class="m-0">No reviews yet</p>
                <div class="d__flex m-5 review-button_con">
                  <ion-icon name="add-outline"></ion-icon>
                  <ion-button class="review-button" fill="clear" (click)="openReview(product_detail.name,'review')"> Add a review</ion-button>
                </div>
              </div>
          
            </div>
            <!-- class="main-desc" *ngIf="product_detail.reviews.length == 0"-->
            <!-- <div class="show-button" *ngIf="product_detail.reviews && product_detail.reviews.length !=0 "><ion-button shape="rounded" fill="clear" (click)="show_more('showmore3')">{{showmore.showmore3?'Hide':'Show more'}}</ion-button></div> -->
          </div>

          <div class="ask-question" >
            <div  class="title__heading d__flex just-s">
              <h6>Questions</h6>
              <ion-icon (click)="show_more('showmore4')" *ngIf="db.ismobile" [name]="!showmore.showmore4?'add-outline':'remove-outline'"></ion-icon>
            </div>
            <div>
              <div   [class.main-desc]="db.ismobile"  class="ask-desc" [ngClass]="showmore.showmore4?'showmore':'hide'">
                <div class="d__flex just-s mb-10">
                  <p  class="m-0">No questions yet</p>
                  <div class="d__flex m-5 review-button_con">
                    <ion-icon name="help"></ion-icon>
                    <ion-button class="review-button" fill="clear" (click)="openReview(product_detail.name,'question')">Ask Question</ion-button>
                  </div>
                
                </div>
              
            </div>
            </div>
          </div>

      </div>


      </div>

    </div>
  </div>

  <!-- Related products -->
  <div class="related mt-25" *ngIf="related_products && related_products.length != 0 && db.website_settings && db.website_settings.enable_related_products == 1">
    <div class="d__flex just-s rele">
      <p class="related-heading">Related Products</p>
    </div>
    <div class="m-10">
      <app-product-box [scrollable]="db.ismobile?'grid-1-50':'grid-5'" [data]="related_products" (addto_cart)="related_addtocart($event)" (removeto_cart)="related_removetocart($event)" (add_wish)="addtowish($event)" (remove_wish)="addtowish($event)"></app-product-box>
      <!-- <app-grid-view [scrollable]="true" [arrow]="!db.ismobile" [data]="related_products" (add_wish)="db.addtowish($event)" (remove_wish)="db.removewish($event)" (addto_cart)="related_addtocart($event)" (removeto_cart)="related_removetocart($event)" ></app-grid-view> -->
    </div>
  </div>

  <!-- Related products -->
  <div class="related mt-25" *ngIf="recently_viewed && recently_viewed.length != 0 && db.website_settings && db.website_settings.enable_related_products == 1">
    <div class="d__flex just-s rele">
      <p class="related-heading">Recently viewed Products</p>
    </div>
    <div class="m-10">
      <app-product-box [scrollable]="db.ismobile?'grid-1-50':'grid-5'" [data]="recently_viewed" (addto_cart)="related_addtocart($event)" (removeto_cart)="related_removetocart($event)" (add_wish)="addtowish($event)" (remove_wish)="addtowish($event)"></app-product-box>
      <!-- <app-grid-view [scrollable]="true" [arrow]="!db.ismobile" [data]="related_products" (add_wish)="db.addtowish($event)" (remove_wish)="db.removewish($event)" (addto_cart)="related_addtocart($event)" (removeto_cart)="related_removetocart($event)" ></app-grid-view> -->
    </div>
  </div>

</div>