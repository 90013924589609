<div class="account_settings">

    <form [formGroup]="profile_form">

        <h2 *ngIf="!db.ismobile" class="fnt-20 myAccount-titles">Personal Information</h2>

        <div *ngIf="db.website_settings.account_settings" class="d__flex personal__info">
            <div class="input__item">
                <p class="input__title">{{db.website_settings.account_settings.enable_lastname == 1 ? 'First Name':'Full Name'}}</p>
                <div class="custom-input" [class.focused]="focus.first_name">
                    <ion-input [disabled]="true" readonly (ionFocus)="focus.first_name = true" (ionBlur)="focus.first_name = false" placeholder="{{db.website_settings.account_settings.enable_lastname == 1 ? 'First Name':'Name'}}" name="first_name" formControlName="first_name" [(ngModel)]="userinfo.first_name"></ion-input>
                </div>
                <div *ngIf="submitted &&  !first_name?.valid && ( first_name?.dirty|| first_name?.touched) ">
                    <p class="m-0 errors" *ngIf="first_name.errors.required">Name is required</p>
                    <p class="m-0 errors" *ngIf="first_name.errors.maxlength">Name is not valid</p>
                </div>
            </div>


            <div class="input__item" lines="none" *ngIf="db.website_settings.account_settings.enable_lastname == 1">
                <p class="input__title">Last Name</p>
                <div class="custom-input" [class.focused]="focus.last_name">
                    <ion-input (ionFocus)="focus.last_name = true" (ionBlur)="focus.last_name = false" placeholder="Last Name" name="last_name" formControlName="last_name" [(ngModel)]="userinfo.last_name"></ion-input>
                </div>
                <div *ngIf="submitted && !last_name?.valid && ( last_name?.dirty|| last_name?.touched) ">
                    <p class="m-0 errors" *ngIf="last_name.errors.required">Last Name is required</p>
                    <p class="m-0 errors" *ngIf="last_name.errors.maxlength">Last Name is not valid</p>
                </div>
            </div>

            <div class="input__item" lines="none">
                <p class="input__title">Email</p>
                <div class="custom-input" [class.focused]="focus.email">
                    <ion-input (ionFocus)="focus.email = true" [disabled]="true" readonly (ionBlur)="focus.email = false" placeholder="Email" type="email" name="email" formControlName="email" [(ngModel)]="userinfo.email"></ion-input>
                </div>
                <div *ngIf="submitted && !email?.valid">
                    <p class="error" *ngIf="email.errors.required">Email-Id is required</p>
                    <p class="error" *ngIf="email.errors.email">Email-Id is not valid</p>
                </div>
            </div>

            <div class="input__item" lines="none">
                <p class="input__title">Phone Number</p>
                <div class="custom-input" [class.focused]="focus.phone">
                    <ion-input [disabled]="true" readonly (ionFocus)="focus.phone = true" (ionBlur)="focus.phone = false" placeholder="Phone Number" type="number" name="phone" formControlName="phone" [(ngModel)]="userinfo.phone"></ion-input>
                </div>
                <div *ngIf="submitted && !phone?.valid">
                    <p class="m-0 errors" *ngIf="phone.errors.required"> Phone Number is required </p>
                    <p class="m-0 errors" *ngIf="phone.errors.pattern"> Not a Valid Mobile number </p>
                </div>
            </div>

            <div class="input__item">
                <p class="input__title">Business Name</p>
                <div class="custom-input" [class.focused]="focus.business_name">
                    <ion-input [disabled]="true" readonly (ionFocus)="focus.business_name = true" (ionBlur)="focus.business_name = false" placeholder="Business Name" name="business_name" formControlName="business_name" [(ngModel)]="customer_info.business_name"></ion-input>
                </div>
                <div *ngIf="submitted &&  !business_name?.valid && ( business_name?.dirty|| business_name?.touched) ">
                    <p class="m-0 errors" *ngIf="business_name.errors.required">Business Name is required</p>
                    <p class="m-0 errors" *ngIf="business_name.errors.maxlength">Business Name is not valid</p>
                </div>
            </div>

            <div class="input__item">
                <p class="input__title">Store Name</p>
                <div class="custom-input" [class.focused]="focus.store_name">
                    <ion-input [disabled]="true" readonly (ionFocus)="focus.store_name = true" (ionBlur)="focus.store_name = false" placeholder="Business Name" name="store_name" formControlName="store_name" [(ngModel)]="customer_info.store_name"></ion-input>
                </div>
                <div *ngIf="submitted &&  !store_name?.valid && (store_name?.dirty|| store_name?.touched) ">
                    <p class="m-0 errors" *ngIf="store_name.errors.required">Business Name is required</p>
                    <p class="m-0 errors" *ngIf="store_name.errors.maxlength">Business Name is not valid</p>
                </div>
            </div>

            <div class="input__item" lines="none">
                <p class="input__title">Business Phone Number</p>
                <div class="custom-input" [class.focused]="focus.business_phone">
                    <ion-input [disabled]="true" readonly (ionFocus)="focus.business_phone = true" (ionBlur)="focus.business_phone = false" placeholder="Business Phone Number" type="number" name="business_phone" formControlName="business_phone" [(ngModel)]="customer_info.business_phone"></ion-input>
                </div>
                <div *ngIf="submitted && !business_phone?.valid">
                    <p class="m-0 errors" *ngIf="business_phone.errors.required"> Business Phone Number is required </p>
                    <p class="m-0 errors" *ngIf="business_phone.errors.pattern"> Not a Valid Business Phone number </p>
                </div>
            </div>

            <div class="input__item">
                <p class="input__title">Business Address</p>
                <div class="custom-input" [class.focused]="focus.business_address">
                    <ion-textarea [disabled]="true" readonly class="text-area fnt-14" [(ngModel)]="userinfo.business_address" formControlName="business_address" placeholder="Business Address"></ion-textarea>
                    <!-- <ion-input (ionFocus)="focus.business_name = true" (ionBlur)="focus.business_name = false" placeholder="Business Name" name="business_name" formControlName="business_name" [(ngModel)]="userinfo.business_name"></ion-input> -->
                </div>
                <div *ngIf="submitted &&  !business_address?.valid && ( business_address?.dirty|| business_address?.touched) ">
                    <p class="m-0 errors" *ngIf="business_address.errors.required">Business Name is required</p>
                    <p class="m-0 errors" *ngIf="business_address.errors.maxlength">Business Name is not valid</p>
                </div>
            </div>
            
            <div class="input__item" lines="none" *ngIf="false">
                <p class="input__title">Pan Card No</p>
                <div class="custom-input" [class.focused]="focus.pan_card_no">
                    <ion-input (ionFocus)="focus.pan_card_no = true" (ionBlur)="focus.pan_card_no = false" placeholder="Pan Card No" name="pan_card_no" formControlName="pan_card_no" [(ngModel)]="db.account_settings.pan" ></ion-input>
                </div>
                <div *ngIf="submitted && !pan_card_no?.valid">
                    <p class="m-0 errors" *ngIf="pan_card_no.errors.required"> pan_card_no Number is required </p>
                    <!-- <p class="m-0 errors" *ngIf="phone.errors.pattern"> Not a Valid Mobile number </p> -->
                </div>
            </div>

            <div class="input__item" lines="none" *ngIf="false">
                <p class="input__title">Adhaar Card No</p>
                <div class="custom-input" [class.focused]="focus.adhaar_card_no">
                    <ion-input (ionFocus)="focus.adhaar_card_no = true" (ionBlur)="focus.adhaar_card_no = false" placeholder="Adhaar Card No" type="number" name="adhaar_card_no" formControlName="adhaar_card_no"  [(ngModel)]="db.account_settings.adhaar_card_no"></ion-input>
                </div>
                <div *ngIf="submitted && !adhaar_card_no?.valid">
                    <p class="m-0 errors" *ngIf="adhaar_card_no.errors.required"> adhaar_card_no card Number is required </p>
                    <!-- <p class="m-0 errors" *ngIf="phone.errors.pattern"> Not a Valid Mobile number </p> -->
                </div>
            </div>

            <!-- <div class="input__item" lines="none" >
            <p class="input__title">Email</p>
            <div class="custom-input" [class.focused]="focus.email">
              <ion-input (ionFocus)="focus.email = true" (ionBlur)="focus.email = false" placeholder="Email" name="email" formControlName="email" [(ngModel)]="userinfo.email"></ion-input>
            </div>
            <div *ngIf="! email?.valid && ( email?.dirty || email?.touched) ">
              <p color="danger" *ngIf=" email.errors.required"> Email is required </p>
              <p color="danger" *ngIf=" email.errors.email"> Invalid email id  </p>
            </div>
          </div> -->

        </div>

        <div *ngIf="false" class="divider"></div>

        <h2 *ngIf="false" class="fnt-20 myAccount-titles">Account Information</h2>

        <div *ngIf="false" class="d__flex account__info p-10">
            <div class="input__item" lines="none">
                <p class="input__title">Email</p>
                <div class="custom-input" [class.focused]="focus.email">
                    <ion-input (ionFocus)="focus.email = true" [disabled]="true" readonly (ionBlur)="focus.email = false" placeholder="Email" type="email" name="email" formControlName="email" [(ngModel)]="userinfo.email"></ion-input>
                </div>
                <div *ngIf="submitted && !email?.valid">
                    <p class="error" *ngIf="email.errors.required">Email-Id is required</p>
                    <p class="error" *ngIf="email.errors.email">Email-Id is not valid</p>
                </div>
            </div>

           <!-- <div class="input__item" lines="none">
          <p class="input__title">New Password</p>
          <div class="custom-input"  [class.focused]="focus.password">
            <ion-input (ionFocus)="focus.password = true" (ionBlur)="focus.password = false" name="password" type="password"  formControlName="password" placeholder="New Password"></ion-input>
          </div>
        </div>
  
        <div class="input__item" lines="none" >
          <p class="input__title">Confirm Password</p>
          <div class="custom-input" [class.focused]="focus.cpassword">
            <ion-input (ionFocus)="focus.cpassword = true" (ionBlur)="focus.cpassword = false" name="cpassword" type="password" formControlName="cpassword"  placeholder="confirm Password"></ion-input>
          </div>
        </div> -->
        </div>

        <div *ngIf="false" class="divider"></div>

        <!-- <h2 class="fnt-20 myAccount-titles">Bank details</h2> -->


        
        <div *ngIf="false" class="d__flex account__info p-10">

            <div class="input__item" lines="none">
                <p class="input__title">Gpay Upi Id</p>
                <div class="custom-input" [class.focused]="focus.gapy_id">
                    <ion-input (ionFocus)="focus.gapy_id = true" (ionBlur)="focus.gapy_id = false" placeholder="Gpay Upi ID" type="gapy_id" name="gapy_id" formControlName="gapy_id"  [(ngModel)]="db.account_settings.gapy_id"></ion-input>
                </div>
                <div *ngIf="submitted && !gapy_id?.valid">
                    <p class="error" *ngIf="gapy_id.errors.required">Gpay-Id is required</p>
                    <!-- <p class="error" *ngIf="email.errors.email">Email-Id is not valid</p> -->
                </div>
            </div>


            <div class="input__item" lines="none">
                <p class="input__title">Paytm Upi Id</p>
                <div class="custom-input" [class.focused]="focus.paytm_id">
                    <ion-input (ionFocus)="focus.paytm_id = true" (ionBlur)="focus.paytm_id = false" placeholder="Paytm Upi ID" type="paytm_id" name="paytm_id" formControlName="paytm_id"  [(ngModel)]="db.account_settings.paytm_id"></ion-input>
                </div>
                <div *ngIf="submitted && !paytm_id?.valid">
                    <p class="error" *ngIf="paytm_id.errors.required">Gpay-Id is required</p>
                    <!-- <p class="gapy_iderror" *ngIf="email.errors.email">Email-Id is not valid</p> -->
                </div>
            </div>

            <!-- <div class="input__item" lines="none">
          <p class="input__title">New Password</p>
          <div class="custom-input"  [class.focused]="focus.password">
            <ion-input (ionFocus)="focus.password = true" (ionBlur)="focus.password = false" name="password" type="password"  formControlName="password" placeholder="New Password"></ion-input>
          </div>
        </div>
  
        <div class="input__item" lines="none" >
          <p class="input__title">Confirm Password</p>
          <div class="custom-input" [class.focused]="focus.cpassword">
            <ion-input (ionFocus)="focus.cpassword = true" (ionBlur)="focus.cpassword = false" name="cpassword" type="password" formControlName="cpassword"  placeholder="confirm Password"></ion-input>
          </div>
        </div> -->
        </div>

        <div *ngIf="false" class="mt-10 mb-10" [ngClass]="db.ismobile?'ion-text-center':'ion-text-right'">
            <ion-button class="action-button" [class.w-20]="!db.ismobile" fill="clear" (click)="update()">Save</ion-button>
        </div>

    </form>
    
</div>