<!-- <ion-header>
  <ion-toolbar>
    <ion-title>{{db.location}}</ion-title>
  </ion-toolbar>
</ion-header> -->

<ion-content>

  <div class="category-head">
    <ion-text>{{db.city}}</ion-text>
    <ion-icon (click)="choose()" name="close-outline"></ion-icon>
  </div>

  <ion-item button class="d__felx just-s" *ngFor="let item of data" (click)="selected_pin(item)">
      <p class="m-0 ">{{item.area}} - {{item.zipcode}}</p>
  </ion-item>
</ion-content>
