<div class="delivery__slots__container">
  <!-- <ion-icon *ngIf="db.ismobile" class="close-icon" (click)="dismiss()" name="close-outline"></ion-icon> -->
  <div *ngIf="db.ismobile && false">
    <ion-item class="mouse" lines="none">
      <ion-label class="small__heading fnt-14">Choose Shipping Method</ion-label>
    </ion-item>
    <ion-list class="shipping__method fnt-14">
      <ion-radio-group class="d__flex" [value]="db.shipping_settings && db.shipping_settings.selected_shipping_method?db.shipping_settings.selected_shipping_method:''" (ionChange)="select_method($event)">
        <ion-item *ngFor="let item of db.shipping_methods" class="no-bg options" lines="none">
          <ion-radio slot="start" value="{{item.name}}"></ion-radio>
          <ion-label [class.bold_name-15-500]="db.ismobile">{{item.shipping_method_name}}</ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <div class="alet_msg fnt-12" *ngIf="alert_message == 'Delivery'">please choose Shipping Method</div>
  </div>
  <!-- <ion-button id="open-modal">Open Datetime Modal</ion-button> -->
  <!-- <ion-modal trigger="open-modal"> -->
  <!-- <ion-datetime >
    <ion-buttons slot="buttons">
      <ion-button >Good to go!</ion-button>
      <ion-button >Reset</ion-button>
    </ion-buttons>
  </ion-datetime> -->
  <!-- </ion-modal> -->
  <div *ngIf="db.delivery_slots.length != 0" class="delivery__slot__container">
    <ion-item class="mouse" lines="none">
      <ion-label class="fnt-16 m-0 bold_name-15-500 fnt-500">Choose a Delivery Slot</ion-label>
    </ion-item>
    <!-- <div class="d__flex slot__headings" *ngIf="!db.ismobile">
    <div class="flex-3">Category</div>
  </div> -->
    <div class="slots">
      <div *ngFor="let item of db.delivery_slots;let i = index">
        <!-- <div class="flex-3">{{item.category_name}}</div> -->
        <!-- {{item.dates_lists[0].slots | json}} -->
        <div>
          <!-- <div class="small__heading">Delivery Date</div> -->
          <div class="d__flex gap-10 scrollable__dates fnt-13" [class.flex-w]="!db.ismobile">
            <!-- item.dates_lists[0].slots = slots.slots -->
            <div class="fnt-14" *ngFor="let slots of item.dates_lists" [class.disabled]="slots.allowed == 0" (click)="slots.allowed == 1 ? item.selected_time_slots = slots: null" (click)="check(slots)" [class.active__date]="item.selected_time_slots && item.selected_time_slots.format_date == slots.format_date"> {{slots.format_date}} </div>
          </div>
          <div class="alet_msg fnt-12" *ngIf="alert_message == 'slot_date'">please choose Delivery Date slot</div>
          <!-- <div class="small__heading">Delivery Time</div> -->
          <div class="scrollable__times d__flex fnt-13">

            <!-- (ionChange)="select_radio($event,item)" -->

            <ion-radio-group class="d__flex flex-w gap-5" [value]="item.selected_time_slots && item.selected_time_slots.selected_time && item.selected_time_slots.selected_time.slot_label" >
              <ion-item [disabled]="item.selected_time_slots ? false : true" (click)="item.selected_time_slots.selected_time = times" lines="none" *ngFor="let times of (!item.selected_time_slots ? item.dates_lists[0].slots : item.selected_time_slots.slots)" class="radio__attribute__option mouse gap-10" >
                <ion-radio slot="start" [value]="times.slot_label"></ion-radio>
                <p class="m-0 small_name">{{times.slot_label}}</p>
              </ion-item>
            </ion-radio-group>

            <!-- <div *ngFor="let times of (!item.selected_time_slots ? item.dates_lists[0].slots : item.selected_time_slots.slots)" (click)="item.selected_time_slots.selected_time = times" [class.active__time]="item.selected_time_slots && item.selected_time_slots.selected_time && item.selected_time_slots.selected_time.slot_label == times.slot_label "> {{times.slot_label}} </div> -->
          </div>
          <div class="alet_msg fnt-12" *ngIf="alert_message == 'slot_time'">please choose Delivery Time slot</div>
        </div>
        <!-- <div class="d__flex" *ngIf="!db.ismobile && false">
          <div>
            <div class="slot__heading">Delivery Date</div>
            <ion-select placeholder="Choose" (ionChange)="changed_date($event,'date')" [value]="item.selected_time_slots ?item.selected_time_slots :undefined " interface="popover">
              <ion-select-option value="undefined">Choose date</ion-select-option>
              <ion-select-option *ngFor="let slots of item.dates_lists" [value]="slots"> {{slots.format_date}}</ion-select-option>
            </ion-select>
          </div>
          <div>
            <div class="slot__heading">Delivery Time</div>
            <ion-select placeholder="Choose" *ngIf="item.selected_time_slots" (ionChange)="changed_time($event,'time')" [value]="item.selected_time_slots && item.selected_time_slots.selected_time ?item.selected_time_slots.selected_time :undefined " interface="popover">
              <ion-select-option value="undefined">Choose Time</ion-select-option>
              <ion-select-option *ngFor="let times of item.selected_time_slots.slots" [value]="times"> {{times.slot_label}}</ion-select-option>
            </ion-select>
          </div>
        </div> -->
        <!-- <div *ngIf="false">
          <div class="date__slot fnt-14">
            <div class="d__flex just-s" *ngIf="false" (click)="item.slots_dropdown = true"> {{item.selected_time_slots?item.selected_time_slots.format_date:'Choose date'}} <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
            <ul class="slot__dates" *ngIf="item.slots_dropdown && false" (mouseleave)="item.slots_dropdown = false">
              <li *ngFor="let slots of item.dates_lists" (click)="item.selected_time_slots = slots" (click)="item.slots_dropdown = false "> {{slots.format_date}} </li>
            </ul>
          </div>
          <div class="time_slot">
            <div class="slot__input d__flex just-s" (ionFocus)="item.times_dropdown = true" (click)="item.times_dropdown = true">{{item.selected_time_slots && item.selected_time_slots.selected_time ?item.selected_time_slots.selected_time.slot_label:'Choose time'}} <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
            <ul class="slot__times" *ngIf="item.times_dropdown && item.selected_time_slots" (mouseleave)="item.times_dropdown = false">
              <li *ngFor="let times of item.selected_time_slots.slots" (click)="item.selected_time_slots.selected_time = times" (click)="item.times_dropdown = false "> {{times.slot_label}} </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div> <!-- delivery_slot.length == 0 &&  delivery_slot[0].selected_time_slots.selected_time.slot_label -->
  </div>
  <!-- <ion-button *ngIf="db.ismobile" expand="full" fill="clear" (click)="choose_slot()">{{check_label()}}</ion-button> -->
</div>
