import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-return-resons',
  templateUrl: './return-resons.component.html',
  styleUrls: ['./return-resons.component.scss'],
})
export class ReturnResonsComponent implements OnInit {

  @Input() return_details;
  @Input() order_id;
  @Input() product_id;

  focus:any={};
  submitted = false;
  return_reason :any =FormGroup;
  check_box_value = false;
  alert_message;
  shake = false;
  bankinfo = false;
  return_request_reason;
  return_request_action;
  
  constructor(public modalCtrl:ModalController,public formBuilder:FormBuilder,public db: DbService) { }

  ngOnInit() {
     this.return_request_reason = this.return_details.return_request_reason[0].name;
     this.return_request_action = this.return_details.return_request_action[0].name;
     this.get_form();
  }

get_form(){
    if(!this.bankinfo){
      this.return_reason = this.formBuilder.group({ 
        reason : new FormControl(this.return_request_reason||'',Validators.required),
        return_action : new FormControl(this.return_request_action||'',Validators.required),
        comments : new FormControl('')
      });
    } else if(this.bankinfo){
    this.return_reason = this.formBuilder.group({ 
    reason : new FormControl(this.return_request_reason||'',Validators.required),
    return_action : new FormControl(this.return_request_action||''),
    comments : new FormControl(''),
    bank_name : new FormControl('',Validators.required),
    account_holder_name : new FormControl('',Validators.required),
    account_no : new FormControl('',Validators.required),
    confirm_ac_no : new FormControl('',Validators.required),
    ifsc_no: new FormControl('',Validators.required),
  });
    }
  }

get reason(){
  return this.return_reason.get('reason');
}

get return_action(){
  return this.return_reason.get('return_action');
}
get bank_name(){
  return this.return_reason.get('bank_name');
}

get account_holder_name(){
  return this.return_reason.get('account_holder_name');
}

get account_no(){
  return this.return_reason.get('account_no');
}

get confirm_ac_no(){
  return this.return_reason.get('confirm_ac_no');
}

get ifsc_no(){
  return this.return_reason.get('ifsc_no');
}


chech_box(){
  if(this.check_box_value){
    this.check_box_value = false;
  }else{
    this.check_box_value = true;
  }
}

value_changed_reason(evt){
  if(evt.detail.value) {
    this.return_request_reason = evt.detail.value;
    // this.get_form();
  }
}

value_changed(evt) {
  // console.log(evt.detail.value)
  if(evt.detail.value) {
    evt.detail.value == 'Return' ? this.bankinfo =true :   this.bankinfo =false;
    this.return_request_action = evt.detail.value;
    this.get_form();
  }
}

submit(){
  // console.log(this.return_reason)
  this.submitted = true;
  if(this.return_reason.status == 'VALID'){
     if(!this.check_box_value){
      this.db.alert('please read and select term and conditions');
     }else if(this.return_reason.value.account_no != this.return_reason.value.confirm_ac_no){
      this.db.alert("Account no confirm Account no can't match..!");
    } else{
       this.get(this.return_reason.value);
       // this.db.alert("Your return request has been successfully submitted");
    }
    
}

}

get(value){
// console.log(value)
let data = {
  return_reason:value.reason,
  return_action:value.return_action,
  comments:value.comments,
  order:this.order_id,
  products:this.product_id,
  account_no:value.account_no,
  bank_name:value.bank_name,
  account_holder_name:value.account_holder_name,
  ifsc_no:value.ifsc_no
}

let datas = {
  reason_for_return:value.reason,
  // return_action:value.return_action,
  remarks:value.comments,
  order_id:this.order_id,
  order_item:this.product_id,
}

let d = this.bankinfo ? data : datas
// console.log(d)
  this.db.create_return_request(d).subscribe(res => {
  // console.log('res',res);  
  if(res.message.status == "Success"){
    this.db.get_retuen_products(this.order_id);
    this.modalCtrl.dismiss();
    this.db.alert(res.message.message);
  }
  else
    this.db.alert(res.message.message);
})
}

}
