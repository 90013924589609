import { HttpClient } from '@angular/common/http';
import {
  Component,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['../registe-r/registe-r.component.scss','./log-in.component.scss'],
})
export class LogInComponent implements OnInit {

  user = null;
  token = null;
  country_list: any = [];
  @ViewChild('prefixVal') prefixVal;
  pwd_policy_msg;
  checks:any ;
  url:any;
  constructor(private http: HttpClient,public db:DbService,private modalCtrl:ModalController,private alertCtrl:AlertController,private platform:Platform,private router:Router,private formbuilder:FormBuilder,private ngzone:NgZone) {
   
   var cur_url = window.location.href.split('/');
   this.url = cur_url[cur_url.length - 1]
   }

  async fb_login() {
   
  }
 
  ngOnInit() {
    this.db.ismobile = this.db.checkmobile();
  }

  @HostListener('window:resize', ['$event'])
  private func(){
    this.db.ismobile = this.db.checkmobile();
  }

// Once the auth provider finished the authentication flow, and the auth redirect completes,
// redirect the user to the profile page
redirectLoggedUserToProfilePage(res) {
  // As we are calling the Angular router navigation inside a subscribe method, the navigation will be triggered outside Angular zone.
  // That's why we need to wrap the router navigation call inside an ngZone wrapper
  this.ngzone.run(() => {
    // this.router.navigate(['profile']);
  });
}

store_google_info(res){
  var social_data={
    email :res.user.providerData[0].email,
    user_name :res.user.providerData[0].displayName,
    provider :res.user.providerData[0].providerId,
    uid :res.user.providerData[0].uid
  }
this.get_customer_info(res.user.providerData[0].email, true);
this.db.get_social_login(social_data).subscribe(res => {
},err => {  console.error(err); })
}

  get_customer_info(data, value) {
    this.db.customer_Info({email:data}).subscribe(res => {
      if (res.message) {
          this.db.store_customer_info(res)
          this.db.dismiss();
      } else {
          this.get_customer_info(data, value)
      }
    },error => { this.db.alert('Check E-mail ID / Password'); console.error(error)})
  }

  clear_otp(){
    localStorage['otp_mobile'] = undefined
  }

}
