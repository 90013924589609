import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-quickview',
  templateUrl: './quickview.page.html',
  // '../product-detail/product-detail.page.scss'
  styleUrls: ['./quickview.page.scss',],
})
export class QuickviewPage implements OnInit {
  @Input() product_detail;
  showmore:any = {};
  main_image;
  alert_animate = false;
  alert_animatings:any = [];
  add_animatings:any = [];

  constructor(public modalCtrl:ModalController,public db:DbService,private router:Router,private route:ActivatedRoute) { }

  ngOnInit() {

    if(this.product_detail){
      this.get_cart_item();
      this.check_image();
      this.db.mycart_emit.subscribe(res => {
        // console.log(res);
        if(res == 'getted'){
          this.get_recommeded();
        }
      })
      this.db.alert_animate.subscribe((res:any) =>{
        this.alert_animate = true;
        // this.alert_animatings.push(res);
        // console.log(res);
        setTimeout( () => {  this.alert_animatings.shift();},1500);
      })

      this.db.added_animate.subscribe(res =>{
        // this.added_animate = true;
        this.add_animatings.push(res);
        setTimeout( () => {  this.add_animatings.shift();},2000);
      })
      
    }

      // this.get_product_detail(this.product_detail.route);
    // console.log("this.product_detail",this.product_detail);
  }

  get_product_detail(route) {
    let data = {
      route: route,
      customer: this.db.cust_name ? localStorage['customerRefId'] : ''

    }
      this.db.get_product_details(data).subscribe(res => {
        this.product_detail = res.message;
        console.log(this.product_detail);
        if(this.product_detail){
          this.get_cart_item();
          this.check_image();
          this.db.mycart_emit.subscribe(res => {
            // console.log(res);
            if(res == 'getted'){
              this.get_recommeded();
            }
          })
          // this.db.alert_animate.subscribe((res:any) =>{
          //   this.alert_animate = true;
          //   // this.alert_animatings.push(res);
          //   // console.log(res);
          //   setTimeout( () => {  this.alert_animatings.shift();},1500);
          // })
          
        }
      })
  }


check_image(){
  if(this.product_detail.images){
    this.main_image =  this.check_active_image(this.product_detail.images)
  }else{
    this.main_image = this.product_detail.product_image
  }
}


check_active_image(images){
  let temp = ''
  if(images.length != 0){
      images.map(res=>{
        if(res.is_primary == 1){        
          res.product_image ?  temp = res.detail_image :  temp = res.image
        }
    })
  }
  return this.db.product_img(temp)
}


check_active_detail_thumbnail(images){
  let temp = ''
  if(images.length != 0){
      images.map(res=>{
        if(res.is_primary == 1){        
          res.thumbnail ?  temp = res.thumbnail :  temp = res.image
        }
    })
  }
  return this.db.product_img(temp)
}



show_more(button) {
  if(button == 'showmore1'){
    this.showmore.showmore1 ?  this.showmore.showmore1 = false : this.showmore.showmore1 = true
  } else  if(button == 'showmore2'){
    this.showmore.showmore2 ?  this.showmore.showmore2 = false : this.showmore.showmore2 = true
  } else  if(button == 'showmore3'){
    this.showmore.showmore3 ?  this.showmore.showmore3 = false : this.showmore.showmore3 = true
  } else  if(button == 'showmore4'){
    this.showmore.showmore4 ?  this.showmore.showmore4 = false : this.showmore.showmore4 = true
  }
}



select_dropdown(event,type){
  this.select(type,event.detail.value) 
}

select_radio(event,type){
this.select(type,event.detail.value)
}

get_cart_item(){
this.db.get_cart_item();
}


removetocart(value) {
    this.db.removetocart(value)
}


async addtocart(value,type) {  
  // console.log(value.count);
 
  if(type == 'shopping'){

      if(value.count != 0){
        // this.db.addtocart(value);
        this.db.update_cart(value,'');
      } else if(value.count == 0) {
        if(value.has_variants == 0) {
            this.db.addtocart(value);
        } else {
          this.check_all_attributes_selected(type,value)
          // this.product_detail.ids ? this.db.addtocart(value) : this.db.alert_animate.next("Please select attributes..!")
        }
      }

  } else if(type == 'wishlist'){
    if(value.wish_count != 0){
      this.db.removewish(value)
    } else if(value.wish_count == 0){
      // if(value.has_variants == 0) {
        this.db.addtowish(value);
      // } else {
        // this.check_all_attributes_selected(type,value)
        // this.product_detail.ids ?   this.db.addtowish(value) : this.db.alert_animate.next("Please select attributes..!")
      // }
       
    }
  }
}


check_all_attributes_selected(type,value){
 if(value.has_variants > 0){
   this.router.navigate(['/pr/'+ value.route]);
   setTimeout(()=>{
     this.modalCtrl.dismiss();
   },1500)
 }
}



check_attribute(id){
var cnt = 0;
this.db.marketplace_items.find( res =>{
  if(res.attribute_ids == id){
    cnt += res.quantity
  }
})
return cnt;
}

check_cart_id(id){
let cart_id =''
this.db.marketplace_items.find( res =>{
  if(res.attribute_ids == id){
    if(res.quantity != 0){
      cart_id = res.name
    }
  }
})
return cart_id;
}

get_recommeded() {
  this.product_detail.count = this.db.checkcart(this.product_detail.name);
  this.product_detail.wish_count = this.db.checkWishCart(this.product_detail.name);

  if(this.product_detail.product_attributes && this.product_detail.product_attributes.length != 0){

    if(this.product_detail.show_price != 0){
      this.check_attribute_cart();
    } else if(this.product_detail.show_price == 0){
      this.get_selected_table_attribute()
    }
}

}


select(type,attribute){
this.product_detail['product_attributes'].find(res => {
  if(res.attribute == type){
    res.options.map(d =>{ 
      if(d.name == attribute){
        d.is_pre_selected = 1
        if(d.images &&  d.images.length != 0 && res.control_type != 'Radio Button List'){
            this.product_detail.images = d.images;  
            this.check_image()
        }
      }else{
        d.is_pre_selected = 0
      }
    })
  }
})
this.check_attribute_cart();
}


check_attribute_cart(){  
let ids = '';
let selected_attribute ='';
let selected_cart_id =''
let selected_cart_count = ''
this.product_detail['product_attributes'].find(res => {
res.options.map(data =>{  
    data.iscart = this.check_attribute(data.name+'\n');
   if(data.is_pre_selected == 1){   
     ids+=data.name+'\n';
     selected_cart_id = this.check_cart_id(ids);
     selected_cart_count = data.iscart
     selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+data.option_value+'</span> </div>'
   } 
 })
})
this.product_detail.selected_cart_id = selected_cart_id
this.product_detail.ids = ids;
this.product_detail.count = selected_cart_count;
this.product_detail.product_attributes.length > 1 ? this.product_detail.count =  this.check_attribute(this.product_detail.ids) : null 
this.product_detail.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
this.check_attribute_stock();
}


check_attribute_stock(){
var data = {
  "product": this.product_detail.name,
  "attribute_id": this.product_detail.ids,
  "cart_qty": this.db.plusCount(this.product_detail.count),
  "variant_html" :''
}
this.db.validate_attributes_stock(data).subscribe(res => {
  if(res.message){
    if( res.message.status == "Success"){
      this.product_detail.stock = res.message.stock
      this.product_detail.price = res.message.price,
      this.product_detail.old_price = res.message.old_price
      this.product_detail.discount_label = res.message.discount.discount_label
      this.product_detail.discount_rule = res.message.discount.discount_rule
      this.product_detail.stock_alert_message = undefined
    }else if( res.message.status == 'Failed'){
      this.product_detail.price = res.message.price,
      this.product_detail.old_price = res.message.old_price
      this.product_detail.stock_alert_message = res.message.message
    }
  }
})
}

//TAble attribute
get_selected_table_attribute(){
this.product_detail.product_attributes[0].options.map(value =>{
  if(!value.ids){

    let ids = ''
    let separate_ids = ''
    let selected_attribute = ''
    let separate_selected_Attribute = ''

      separate_ids += value.name+'\n';
      separate_selected_Attribute += ' <div class="attribute"><span class="attr-title">'+this.product_detail.product_attributes[0].attribute+'</span> : <span>'+value.option_value+'</span> </div>'

      this.product_detail.other_attributes.map(res =>{
        res.options.map(option=>{
          option.is_select_parent = value
          if(option.is_pre_selected == 1){
            ids += option.name+'\n';
            selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+option.option_value+'</span> </div>'
          }
        })
      })

      ids = separate_ids + ids
      selected_attribute = separate_selected_Attribute + selected_attribute

     
      value.ids = ids;
      value.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
      value.count =  this.check_attribute(ids);
      value.selected_cart_id = this.check_cart_id(ids);

      var data = {
        "product": this.product_detail.name,
        "attribute_id":  value.ids,
        "cart_qty": this.db.plusCount(value.count),
        "variant_html" : value.selected_attribute
      }
  
        this.db.validate_attributes_stock(data).subscribe(data => {
          if(data.message && data.message.status == "Success"){
            value.stock = data.message.stock
            value.price = data.message.price,
            value.old_price = data.message.old_price
            value.discount_label = data.message.discount.discount_label
            value.discount_rule = data.message.discount.discount_rule
            value.stock_alert_message = undefined
          }else if(data.message && data.message.status == 'Failed'){
            value.price = data.message.price,
            value.old_price = data.message.old_price
            value.stock_alert_message = data.message.message
          }
        })

  } else {
        value.count =  this.check_attribute(value.ids);
        value.selected_cart_id = this.check_cart_id(value.ids);
  }

})

}



changed_table_dropdown(event,item,attribute){
item.attribute = this.product_detail.product_attributes[0].attribute
this.select_attribute(item,attribute,event.detail.value);
}

//parent is main attribute single  option  ex:curry cut
//Value is other attribute option
select_attribute(parent_attribute,child_attribute,attr_option){ 
this.product_detail.other_attributes.map(res =>{
  if(res.name == child_attribute.name){
      res.options.map(option=>{
           option.is_select_parent = parent_attribute;
          if(option.name == attr_option){
              option.is_pre_selected = 1; 
          } else {
              option.is_pre_selected = 0;
          }
      })
  }
})
// console.log()
this.get_selected_attributes(parent_attribute.name);
}




get_selected_attributes(parent_id){
this.product_detail.product_attributes[0].options.map(res => {
  if(res.name == parent_id){

    let ids = ''
    let separate_ids = ''
    let selected_attribute = ''
    let separate_selected_Attribute = ''

    this.product_detail.other_attributes.map(res =>{
      res.options.map((option,index)=>{
          if(option.is_pre_selected == 1 && option.is_select_parent && option.is_select_parent != ''){
              // console.log(option.is_select_parent.name)
              separate_ids = option.is_select_parent.name+'\n'
              separate_selected_Attribute = ' <div class="attribute"><span class="attr-title">'+option.is_select_parent.attribute+'</span> : <span>'+option.is_select_parent.option_value+'</span> </div>'
              ids += option.name+'\n';
              selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+option.option_value+'</span> </div>'
          }
      })
    })

     ids = separate_ids + ids
     selected_attribute = separate_selected_Attribute + selected_attribute

     
      res.selected_cart_id = this.check_cart_id(ids);
      res.ids = ids;
      res.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
      res.count = this.check_attribute(res.ids);

    var data = {
      "product": this.product_detail.name,
      "attribute_id":  res.ids,
      "cart_qty":this.db.plusCount(this.product_detail.count),
      "variant_html" : res.selected_attribute
    }

    this.db.validate_attributes_stock(data).subscribe(data => {
      // console.log(data)
      if(data.message.status == "Success"){
        res.price = data.message.price,
        res.old_price = data.message.old_price
        res.discount_label = data.message.discount.discount_label
        res.discount_rule = data.message.discount.discount_rule
        res.stock_alert_message = undefined
      }else if(data.message.status == 'Failed'){
        res.price = data.message.price,
        res.old_price = data.message.old_price
        res.stock_alert_message = data.message.message
      }
    })

   

  }
})

}


check_active(item,option){
if(item.ids){
let v = item.ids.split('\n');
  return  v.includes(option.name)
} else {
if(option.is_pre_selected == 1){
  return option.is_pre_selected == 1
}
}

}

check_table_radio(item,options){
let temp =''

if(item.ids){
let v = item.ids.split('\n');
options.map(res =>{
    v.map(value =>{
        if(value == res.name){
            temp =  res.name
            // console.log(res)
        }
    })
})
} else {
options.map(option =>{
  if(option.is_pre_selected == 1){
    return option.name
  }
})
}
// console.log(temp)
return temp
}



// Check attribute lable
check_attribute_label(data,item){
let temp_label ='Select'
if(data && data.length != 0){
  data.map(res =>{
    if(item.ids){
      let v = item.ids.split('\n');
        if(v.includes(res.name))  {
          temp_label = res.option_value
        }
    } else {
      temp_label = res.option_value
    } 
  })
}
return temp_label;
}


//check active radio 
check_active_radio(options){
// console.log(options)
let temp = ''
options.map(res =>{
    if(res.is_pre_selected == 1){
      
        temp = res.name
    }
})
return temp;
}

table_addtocart(data){
  this.product_detail.count = data.count
  this.product_detail.price = data.price
  this.product_detail.ids = data.ids;
  this.product_detail.selected_cart_id = data.selected_cart_id;
  this.product_detail.selected_attribute = data.selected_attribute
    if(data.count != 0){
      this.db.update_cart(this.product_detail,'');
    } else if(data.count == 0) {
      this.db.addtocart(this.product_detail);
    }
}

table_removetocart(data){
this.product_detail.selected_cart_id = data.selected_cart_id;
this.product_detail.count = data.count
this.removetocart(this.product_detail);
}


}
