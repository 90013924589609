<div class="cart-container">

  <ion-refresher slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="">
  <h2 class="fnt-20 myAccount-titles" *ngIf="!db.ismobile && no_footer">Favorite List</h2>
  <ion-button class="web_btn" *ngIf="!db.ismobile && db.wish_count != 0 && no_footer" fill="clear" expand="full" (click)="moveallto_Cart()">Move All To Cart</ion-button>
  </div>
  <!-- <div class="oops-icon1" >
    <ion-icon src="/assets/icon/empty_stack_icons/no-wishlist.svg"></ion-icon>
        <h6 class="m-0 fnt-12 oops-text"></h6>
        
  </div> -->

  <div class="oops-icon1" [class.yorcart_wish_web]="yorcart_wish_web"
    *ngIf="db.flStore_wishlist && db.flStore_wishlist.length ==0 && db.marketPlace_wishlist && db.marketPlace_wishlist.length == 0">
    <!-- <ion-icon src="/assets/icon/empty_stack_icons/wishlist-null.svg"></ion-icon> -->
    <img *ngIf="db.website_settings && db.website_settings.app_settings" class="icon" button
      [src]="db.product_img(db.website_settings.app_settings.no_wishlist)">
    <h6 class="m-0 fnt-12 oops-text">No Wishlist Items!</h6>
    <p>Sorry, your wishlist is empty!</p>
    <!-- <ion-button fill="clear" class="sub_now">Add Products</ion-button> -->
  </div>


  <div class="container">


    <ion-list *ngIf="false && !db.show_wish && ((db.flStore_wishlist && db.flStore_wishlist.length != 0) || (db.marketPlace_wishlist && db.marketPlace_wishlist.length != 0))">
      <ion-item *ngFor="let item of db.flStore_wishlist.length != 0 ? db.flStore_wishlist : db.marketPlace_wishlist.length != 0 ? db.marketPlace_wishlist : [0,1,2]" class="skeleton" style="height:17vh">
        <ion-thumbnail slot="start">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text [animated]="true" style="width: 80%;height:20px"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 60%;height:20px"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 30%;height:20px"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>

    <div *ngIf="db.show_wish && db.marketPlace_wishlist && db.marketPlace_wishlist.length != 0" class="sections">
       
      <div class="head_div" >
        <ion-label class="m-0 bold_name-15">Farmlink</ion-label>
        <P class="m-0 count_v" *ngIf="db.marketPlace_wishlist">{{db.marketPlace_wishlist.length}} <span>Item</span><span *ngIf="db.marketPlace_wishlist.length != 1">s</span> </P>
      </div>

      <div class="ion-padding" slot="content">
        <div class="list-item d__flex" *ngFor="let item of db.marketPlace_wishlist">
          <div class="image" [class.page-design]="db.path == '/my-profile/wishlist'" [class.menu]="db.wishcart">
            <img src="{{db.product_imgs(item.image)}}" defaultImage="" alt="">
          </div>

          <div class="description">
            <p class="m-0 product__name medium_name">{{item.product_name}}</p>
            <p class="m-0 cat__price open_sans__font">{{item.price | currency: db.website_settings.currency}}</p>
            <div class="attribute_d" [innerHTML]="item.attribute_description"></div>
            <p class="m-0 sold_by_d" *ngIf="item.vendor_name">Sold By : <span class="sold_by">{{item.vendor_name}}</span></p>
            <p class="m-0" *ngIf="item.note">Note: {{item.note}}</p>
            <div class="d__flex just-s ion-align-items-baseline" *ngIf="!db.ismobile"></div>

            <div class="buttons just-s">
              <ion-button class="fnt-12 cart" fill="clear" (click)="movetocart(item)"> add <ion-icon class="mouse"
                  src="/assets/icon/cart.svg"></ion-icon></ion-button>
              <ion-button class="fnt-12 trash" fill="clear" (click)="removewish(item,'')"> remove <ion-icon
                  class="mouse " src="/assets/icon/delete.svg"></ion-icon></ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>

        <div *ngIf="db.show_wish && db.flStore_wishlist && db.flStore_wishlist.length != 0" class="sections">
       
          <div class="head_div" >
            <ion-label class="m-0 bold_name-15">Direct</ion-label>
            <P class="m-0 count_v" *ngIf="db.flStore_wishlist">{{db.flStore_wishlist.length}} <span>Item</span><span *ngIf="db.flStore_wishlist.length != 1">s</span> </P>
          </div>

        <div class="fl_store" class="ion-padding" slot="content">
          <div class="list-item d__flex" *ngFor="let item of db.flStore_wishlist">
            <div class="image" [class.page-design]="db.path == '/my-profile/wishlist'" [class.menu]="db.wishcart">
              <img src="{{db.product_imgs(item.image)}}" defaultImage="" alt="">
            </div>

            <div class="description">
              <p class="m-0 product__name medium_name">{{item.product_name}}</p>
              <p class="m-0 cat__price open_sans__font">{{item.price | currency: db.website_settings.currency}}</p>
              <div class="attribute_d" [innerHTML]="item.attribute_description"></div>
              <p class="m-0 sold_by_d" *ngIf="item.vendor_name">Sold By : <span class="sold_by">{{item.vendor_name}}</span></p>
              <p class="m-0" *ngIf="item.note">Note: {{item.note}}</p>
              <div class="d__flex just-s ion-align-items-baseline" *ngIf="!db.ismobile"></div>

              <div class="buttons just-s">
                <ion-button class="fnt-12 cart" fill="clear" (click)="movetocart(item)"> add <ion-icon class="mouse"
                    src="/assets/icon/cart.svg"></ion-icon></ion-button>
                <ion-button class="fnt-12 trash" fill="clear" (click)="removewish(item,'')"> remove <ion-icon
                    class="mouse " src="/assets/icon/delete.svg"></ion-icon></ion-button>
              </div>
            </div>
          </div>
        </div>
        </div>




  </div>


  <div class="cart__footer" *ngIf="!db.ismobile && db.wish_count != 0 && db.wishcart && !no_footer">
    <!-- <div class="cart__footer"> -->
    <ion-button fill="clear" expand="full" (click)="moveallto_Cart()">Move All To Cart</ion-button>
    <!-- </div> -->
  </div>
</div>