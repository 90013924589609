import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { ModalController } from '@ionic/angular';
import { PincodePopupPage } from '../pincode-popup/pincode-popup.page';

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {
  myform;
  autocompleteInput: any;
  pincodes:any=[];
  showmore = false;

  @Input() select_store;

  @ViewChild('addresstext') addresstext!: ElementRef;
  stores = [{ name : 'All Stores',display_name: 'All Stores' },{ name : 'FL Store',display_name:'Direct' }, {name : 'FL MarketPlace', display_name:'Farmlink'}]
  constructor(private formBuilder:FormBuilder,public db:DbService,private ngzone:NgZone,public modalCtrl:ModalController,public router:Router) { 
    this.db.scroll_event.desktop_header  = false;
  }

  ngOnInit() {
    this.db.cust_name = localStorage['CustomerName'] ? localStorage['CustomerName'] : undefined
    this.db.cust_email = localStorage['email'] ? localStorage['email'] : undefined
    // this.db.mycart_emit = false;
    // this.db.wish_cart_emit = false;
    this.db.checkmobile();
  }

  currentlocation(){
    this.db.location = undefined;
    this.db.current_locate('');
  }

  navigation(){
    !localStorage['CenterName']  ? this.db.alert('Please select the buyer centre') : this.router.navigateByUrl('/tabs/home')
    !localStorage['CenterName'] ? this.router.navigateByUrl('/location') :''
  }

  bread_crumb(name){
    this.pincodes = [];
  }

  ngOnDestroy(): void {
    // this.db.mycart_emit.();
    // this.db.wish_cart_emit.unsubscribe();
    // this.db.choosed_attributes = [];
  }

  store_select(item){
    this.db.store_name = item.detail.value;
    // this.db.store_name = item;
    localStorage['store_name'] = item.detail.value;
    this.db.store_selection.next('store_selected');
    this.db.get_website_settings();
    (this.db.center_name || localStorage['customerRefId']) ? this.modalCtrl.dismiss() : null;
  }

  centre_selection(item){
    localStorage['CenterName'] = item.detail.value;
    // this.db.center_name = item.label;
    this.db.center_name = item.detail.value;
    this.db.store_selection.next('store_selected');
    this.db.get_website_settings();
    !this.db.ismobile ? this.modalCtrl.dismiss() : this.router.navigateByUrl('/tabs/home');
  }


  shop = [
    // {
    //   title: 'Wishlist',
    //   route: '/my-profile/wishlist',
    //   icon: '/assets/icon/heart.svg',
    //   enable: 1
    // },
    {
      title: 'MyCart',
      route: '/my-profile/yourcart',
      icon: '/assets/icon/mycart.svg',
      enable: 1
    },
    {
      title: 'Orders',
      route: '/my-profile/my-orders',
      icon: '/assets/icon/cart.svg',
      enable: 1
    },
    {
      title: 'Profile',
      route: '/my-profile/my-account',
      icon: '/assets/icon/user-sidemenu.svg',
      enable: 1
    },
  ]

}
