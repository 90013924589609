<div class="container">
  <ion-refresher slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <h2 class="fnt-20  myAccount-titles" *ngIf="!db.ismobile">My order list</h2>
  <div class="oops-icon1" *ngIf="db.website_settings.order_info">
    <img *ngIf="db.website_settings && db.website_settings.app_settings" class="icon" button [src]="db.product_img(db.website_settings.app_settings.no_order)">
    <h6 class="m-0 fnt-12 oops-text">No Order Items!</h6>
    <p>Sorry, Order list is empty!</p>
  </div>
  <div *ngIf="!db.website_settings.order_info && db.order_items.length != 0">
    <div class="table__data " *ngIf="!db.ismobile ">
      <div class="table__container d__flex table__header">
        <div *ngFor="let column of order_column">{{column}}</div>
      </div>
      <div class="main-item mouse" *ngFor="let item of db.order_items;let i =index" routerLink="/my-profile/order/{{item.name}}">
        <div class="table__container d__flex table__items">
          <div class="order__id">{{item.name}}</div>
          <div>{{item.creation | date: 'dd-MMM-yyyy'}}</div>
          <div><span class="dot" [class.cancelled]="item.status == 'Cancelled'" [class.success]="item.status == 'Completed'" [class.shipped]="item.status == 'Shipped'" [class.order_delivered]="item.status == 'Delivered'" [class.placed]="item.status == 'Placed'"></span>{{item.status}}</div>
          <div><span class="dot" [class.success]="item.payment_status == 'Paid'" [class.pending]="item.payment_status == 'Pending'"></span>{{item.payment_status}}</div>
          <div class="open_sans__font"> {{item.total_amount | currency:db.website_settings.currency}}</div>
          <div class="open_sans__font"> {{item.paid_amount | currency:db.website_settings.currency}}</div>
          <div class="open_sans__font"> {{item.total_refund_amount | currency:db.website_settings.currency}}</div>
          <div><span class="dot" [class.success]="item.refund_status == 'Processed'" [class.pending]="item.refund_status == 'Pending'" [class.no_refund]="item.refund_status == null"></span>{{item.refund_status ? item.refund_status : 'NA'}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="db.ismobile && show_order">
      <ion-list>
        <ion-item *ngFor="let item of db.order_items" routerLink="/order-detail/{{item.name}}">
          <div class="w-100 orders">
            <div class="d__flex just-s order-mb mb-5"><span class="id__ medium_name">{{item.name}}</span> <span class="medium_name open_sans__font price-color">{{item.total_amount | currency:db.website_settings.currency}}</span></div>
            <div class="d__flex just-s order-date-mb fnt-11 mb-5"><span class="medium_name">{{item.creation | date: 'dd-MMM-yyyy' }} {{item.order_time}}</span></div>
            <div class="d__flex just-s status-mb fnt-13 mb-5">
              <p class="m-0 items_ medium_name">Paid:   <span class="medium_name open_sans__font price-color">{{item.paid_amount | currency:db.website_settings.currency}}</span></p>
              <div [class]="get_status_col(item.status)" class="fnt-13">
                {{item.status == 'Placed'?'Order':''}} {{item.status}}</div>
            </div>
            <div class="d__flex just-s status-mb fnt-13">
              <p *ngIf="item.total_refund_amount" class="m-0 items_ medium_name">Refund:   <span class="medium_name open_sans__font price-color">{{item.total_refund_amount | currency:db.website_settings.currency}}</span></p>
              <div *ngIf="item.refund_status" [class]="get_status_col(item.refund_status)" class="fnt-13">{{item.refund_status}}</div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
    <ion-list *ngIf="!show_order && db.ismobile">
      <ion-item *ngFor="let item of db.order_items" class="skeleton" style="height:13vh">
        <div class="child1">
          <h6>
            <ion-skeleton-text [animated]="true" style="width: 150px;height:15px"></ion-skeleton-text>
          </h6>
         <h6>
            <ion-skeleton-text [animated]="true" style="width: 150px;height:15px"></ion-skeleton-text>
          </h6>
         <h6>
            <ion-skeleton-text [animated]="true" style="width: 150px;height:15px"></ion-skeleton-text>
          </h6>
        </div>
        <div class="child2">
          <p>
            <ion-skeleton-text [animated]="true" style="width: 115px;height:15px"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 103px;height:15px;margin: 40px 11px 0 11px;"></ion-skeleton-text>
          </p>
        </div>
      </ion-item>
    </ion-list>
  </div>
  <ion-infinite-scroll *ngIf="!db.no_orders_products && !db.website_settings.order_info" threshold="300px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText=""></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</div>
