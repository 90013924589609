<div class="tooltip-container"
     (mouseenter)="showTooltip = true"
     (mouseleave)="showTooltip = false"
     (click)="toggleTooltip()">
  <button class="custom-button">
    {{ actionText }}
  </button>
  <div class="tooltip" [class.show]="showTooltip">
    {{ tooltipText }}
  </div>
</div>
