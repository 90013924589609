import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter

} from '@angular/core';

import { DbService } from 'src/app/services/db.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  @Input() form_data;
  @Input() myform;
  @Input() submitted;
  @Input() current_value;
  // @Output() checkbox_value_change = new EventEmitter();
  // @Output() select_value_change = new EventEmitter();
  // @Output() get_dropdown = new EventEmitter();
  @Input() form_values;
  @Input() image_uploaded_detail;
  @Input() box_width;
  @Output() changing_data = new EventEmitter();

  tomorrow;
  today;
  category;

 image;

  constructor(public db:DbService,public chf:ChangeDetectorRef) { }

  ngOnInit() {
    const d = new Date();
    this.today = formatDate(d, 'yyyy-MM-dd', 'en-US');
    d.setDate(d.getDate() + 1);
    // console.log(this.form_data)
    this.tomorrow = formatDate(d, 'yyyy-MM-dd', 'en-US');
    // Object.keys(this.myform.controls).forEach(key => {
    //   this.myform.get(key).setValue(this.form_values[key])
    //   console.log(this.myform.controls)
    // });
    this.image_uploaded_detail = [];
  }


  select_value_change(event,data){
    // console.log(data);
      if(data.name == 'inventory_method' || data.name == 'percentage_or_amount'){
          this.form_data.map(res => {
          res.group_fileds.map(value =>{

            if( data.name == 'inventory_method' && value.name == 'maximum_order_qty' || value.name == 'stock'){
              event.detail.value == 'Dont Track Inventory' ?  value.hidden = true : value.hidden = false
            } 

            if(data.name == 'percentage_or_amount' && (value.name == 'preorder_amount' || value.name == 'preorder_percent') ){
              this.myform.get(value.name).setValue(this.form_values[value.name])
              if( value.name == 'preorder_percent' ){
                // 
                event.detail.value != 'Preorder Percentage' ?  (value.required = false,value.hidden = true) : (value.hidden = false,value.required = true )  
                // console.log(value)
              } 
              if( value.name == 'preorder_amount') {
                // value.hidden = true,
                event.detail.value != 'Preorder Amount' ? (value.required = false,value.hidden = true) : (value.hidden = false,value.required = true )  
                // console.log(value)
              }
              
            } 

          })
        })

      }

      this.chf.detectChanges();
      
   }


   checkbox_value_change(event,data,options){
    // console.log("enable_preorder_product",event,data,options)
    // console.log("Changing...",event.detail.value)
    if(options.group_title == 'SHIPPING INFO' || options.group_title == 'PREORDER' ){

      if(data.name == 'enable_shipping'){
        this.form_data.map(res =>{
          res.group_fileds.map(value =>{
            
              if(value.name == 'free_shipping' || value.name == 'additional_shipping_cost'){
                  event.detail.value ?  value.hidden = true : value.hidden = false
                  if(value.name == 'additional_shipping_cost'){
                    value.value == false
                  }
              } 

              
          })
        })
      }

      if(data.name == 'free_shipping'){
        this.form_data.map(res =>{
          res.group_fileds.map(value =>{
              if (value.name == 'additional_shipping_cost'){
                if( !event.detail.value){
                  value.hidden = true
                  this.myform.get(value.name).setValue(0)
                } else { 
                  value.hidden = false
                  this.myform.get(value.name).setValue(this.form_values[value.name])
                }
       
              } 
          })
        })
      }

      if(data.name == 'enable_preorder_product'){
        this.form_data.map(res =>{
          res.group_fileds.map(value =>{
              if (value.name == 'percentage_or_amount' || value.name == 'preorder_percent' || value.name == 'preorder_amount'){

                  if(event.detail.value){
                    value.hidden = true
                    
                  } else {
                    // value.required = true
                    value.name != 'preorder_amount' ?  value.hidden = false : null
                  }

                  if( event.detail.value && value.name == 'percentage_or_amount'){
                      value.value = 'Preorder Percentage'
                      value.name == 'preorder_amount' ? (value.hidden = true,value.required = false) : null;
                      value.name == 'preorder_percent' ? value.required = true : null
                  }
              } 
          })
        })
      }
  

    }

 }


  get_dropdown(data){
    // console.log(data)
      // let v = []
      // console.log(v)
      // if(!data.options){
      //   this.db.get_doctype_datas(data.dropdown_api).subscribe(res =>{
      //     data.options = res.data
          
      //     // return res.data
      //   })
      // }

      // switch(data){
      //   case 'get_category_list':
      //     v =  this.categorylist
      //     break;
      // }
      
      // data.options = v
      // console.log("get doctype data",data)
      // return data.options = v
  }



  onSelectFile(event,item,options) {

    let fileSize = event.target.files[0].size; // you will get the uploaded file size

    let a = fileSize/1048;

    if (a <= 2000) {
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        var file: File = event.target.files[0];
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
           reader.onload = (event: any) => {
            // console.log(event);
           //  console.log(file.name);
            let prescription_file = file.name;
            let imagedata = event.target.result;
            item.image_upload = event.target.result;
   
            let data = {
             "filename":prescription_file,
             "content":imagedata,
            }
   
           this.myform[item.name] = data;
      
          }
    
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }else{
      this.db.alert('Maximum allowed file size is 2MB');
    }
 }
  

 onMulti_SelectFile(event,item,options) {
// console.log(event)
for(let i=0 ; i < event.target.files.length;i++){
  console.log( event.target.files[i]);
  let fileSize = event.target.files[i].size; // you will get the uploaded file size
  let a = fileSize/1048;

if (a <= 2000) {
  if (event.target.files[i]) {
    var filesAmount = event.target.files.length;
    var file: File = event.target.files[i];
    // for (let i = 0; i < filesAmount ; i++) {
      var reader = new FileReader();
       reader.onload = (event: any) => {
        // console.log(event);
        console.log(event.target.result);
        let prescription_file = file.name;
        let imagedata = event.target.result;
        item.image_upload = event.target.result;
        
        let data = {
          "filename":prescription_file,
          "content":imagedata,
         }
      
         console.log(data)

       if(item.multi_upload){
         let datas:any=[];
         if(this.myform[item.name] && this.myform[item.name].length != 0){
           console.log(this.myform[item.name])
           this.myform[item.name].map((res:any)=>{
             datas.push(res)
           })
         } else{
           datas.push(data);
         }
        //  datas.push(data);
         this.myform[item.name] = datas;
       }else{
         this.myform[item.name] = data;
       }
      
       }
      reader.readAsDataURL(event.target.files[i]);
    //  }
   }
  }else{
   this.db.alert('Maximum allowed file size is 2MB');
 }
}

}

//  file_upload(file){
//  console.log(file)
//  var reader = this.db.product_img(file)
//  reader = new FileReader();
//            reader.onload = (event: any) => {
//             console.log(event);
//            //  console.log(file.name);
//             let prescription_file = file.name;
//             let imagedata = event.target.result;
//             // item.image_upload = event.target.result;
   
//             let data = {
//              "filename":prescription_file,
//              "content":imagedata,
//             }
   
//             console.log(data)
//           //  this.myform[item.name] = data;
      
//           }
//  }

 

 get__string(item){
     
  let other_partners = ''
        
  if(item.array.length != 0){
    item.array.map((res,i)=>{

      if(res.category){
        if(item.array.length - 1 > i){
          other_partners += res.category + ',';
          this.myform.get(item.name).setValue(other_partners)
        }else{
          other_partners += res.category;
          this.myform.get(item.name).setValue(other_partners)
        }
      }  

    })
   }
}


get__(item){
   if(item.field == 'string'){
       this.get__string(item);
   }
}

 checkbox_value(c,item){
    // console.log(c,item)
    if(item.arguments == 'array'){
      this.category = {"category": c.name};
      c.check =!  c.check;
      if(item.array.length == 0){
        item.array.push(this.category);
        item.value = c.name;
        this.get__(item)
      }else{
        let d = item.array.find(res=> c.name == res.category)
      
         if(d){
          d == c.name ? '' : '';
          item.array.splice(item.array.findIndex(a => a.category === d) , 1); 
          this.get__(item)
         } else{
           item.array.push(this.category);
           this.get__(item)
         }
   
        }
    }else if(item.arguments == 'true_r_false'){
      c.check =!  c.check;
      this.myform.get(item.name).setValue(c.check)
    }
  

 }

 Options(eve,item){
   
   if(eve.detail.value){
     item.value = eve.detail.value;
     this.myform.get(item.name).setValue(eve.detail.value);
     item.checking_values ? this.changing_data.emit(item) : null
   }
 }

}
