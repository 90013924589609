<ion-grid class="top-bar-2-bg">
  <ion-row class="main-width">
    <ion-col class="flex-30">
      <p class="null-space bor-right"><a routerLink="/p/about-us">About Us</a></p>
      <p class="null-space"><a routerLink="/my-profile/dashboard">My account</a></p>
    </ion-col>
    <ion-col class="flex-60 just-cen">
        <app-marquee-text [marqueeText]="db.website_settings && db.website_settings.market_place_settings && db.website_settings.market_place_settings.order_cancel_disclaimer ? db.website_settings.market_place_settings.order_cancel_disclaimer : ''"></app-marquee-text>
    </ion-col>
    <ion-col class="d__flex just-e">
      <p class=""><a routerLink="/my-profile/my-orders">Track Order</a></p>
      <!--bor-right <p class="null-space fnt-15">English<ion-icon name="chevron-down-outline" class="drop"></ion-icon></p> -->
    </ion-col>
  </ion-row>
</ion-grid>
<div class="desktop__header" [class.without_location]="db.website_settings.enable_multi_vendor == 1">
  <ion-row *ngIf="db.header_info" class=" d__flex just-s header__">
    <div class="d__flex logos gap-50">
      <a routerLink="/" routerDirection="root">
        <img class="home__logo mouse " [src]="db.store_name != 'FL Store' ? 'assets/imgs/Farmlink-logo-2.svg' : 'assets/imgs/FL-Direct.svg'">
      </a>
      <!-- <div *ngIf="!db.cust_email && !db.cust_name" class="location__container border mouse" fill="clear" id="trigger-button" (click)="db.path != '/checkout'?db.center_modal():null">
        <div class="">
          <h6 class="location__title m-0">Centre <ion-icon class="fnt-12" name="chevron-down-outline"></ion-icon>
          </h6>
          <div class="fnt-12 location-subtitle webkit-text" [style.--line]="1">{{db.center_name}}</div>
        </div>
      </div> -->
    </div>
    <div class="store_selection mouse">
      <div class="market_change" (click)="db.select_store()">
        <!-- {{db.cust_name ? db.center_name : 'Centre'}} -->
        <h6 class="m-0 fnt-11 fnt-500">{{db.center_name ? db.center_name : 'Centre'}}<ion-icon class="floatRight" slot="end" name="chevron-down-outline"></ion-icon>
        </h6>
        <div class="store_ position-relative  fnt-13 webkit-text" [style.--line]="1">{{db.replace_txt(db.store_name)}} <span *ngIf="!db.cust_email && !db.cust_name && db.center_name && false" class="fnt-12 fnt-600">/ {{db.center_name}}</span></div>
      </div>
    </div>
    <app-search-products class="searchbar"></app-search-products>
    <div *ngIf="(db.header_info && db.header_info.is_static_menu == 0 && db.header_info.is_menu_full_width == 0) && db.secondary_header">
      <app-secondary-header [cat_products]="cat_products" [css_hide]="'css_hide'"></app-secondary-header>
    </div>
    <div class="d__flex menu__buttons">
      <!-- <div class="custom-button  wish__cart__badge d__flex m-0  mouse" (click)="cart_pop.emit('wishcart')">
        <div class="d__column">
          <img class="icon" *ngIf="db.website_settings && db.website_settings.app_settings" [src]="db.product_img(db.website_settings.app_settings.wishlist_icon)" alt="">
          <ion-badge>{{db.wish_count}}</ion-badge>
        </div>
      </div> -->
      <div class="custom-button cart__badge d__flex m-0  mouse" (click)="cart_pop.emit('mycart')">
        <div class="d__column">
          <img class="icon" *ngIf="db.website_settings && db.website_settings.app_settings" [src]="db.product_img(db.website_settings.app_settings.cart_icon)" alt="">
          <ion-badge>{{db.cart_count}}</ion-badge>
        </div>
      </div>
      <div #dropdown2 class="custom-button mouse ion-text-right position-relative" (mouseenter)="(db.cust_name || db.guest_cust_name)?drop_down2 = true:null" (click)="openLogin()" (mouseleave)="drop_down2 = false">
        <div class="d__column" (click)="(db.cust_name || db.guest_cust_name) ? drop_down2 = !drop_down2:null">
          <img class="icon" *ngIf="db.website_settings && db.website_settings.app_settings" [src]="db.product_img(db.website_settings.app_settings.user_icon)" alt="">
          <span class="webkit-text black" [style.--line]="1">{{(db.cust_name || db.guest_cust_name) ? (db.cust_name || db.guest_cust_name):''}}</span>
        </div>
        <div *ngIf="drop_down2 && (db.cust_name || db.guest_cust_name)" class="dropdown-box">
          <ion-item button *ngFor="let item of _menu" (click)="drop_down2 = false" [routerLink]="'/'+item.route" lines="none"> {{item.title}}</ion-item>
          <!-- <ion-item button lines="none" (click)="drop_down2 = false" (click)="cart_pop.emit('wishcart')"> Wishlist</ion-item> -->
          <ion-item button lines="none" (click)="drop_down2 = false" (click)="db.logout()"> Logout </ion-item>
        </div>
      </div>
    </div>
  </ion-row>
</div>
