import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss'],
})
export class SlidersComponent implements OnInit {


  @Input() image;
  @Input() category_slide;
  @Output() clicked = new EventEmitter();

  slideOpts = {
    freeMode: true,
    slidesPerView: 1.2,
    spaceBetween: 7,
    speed: 400,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 1500
    },
  }

  CatSlideOpts = {
    freeMode: true,
    slidesPerView: 1.5,
    slidesOffsetBefore: 11,
    spaceBetween: 10
  }

  ismobile;
  constructor(public db:DbService) { 
   
  }

  ngOnInit() {
    this.ismobile = this.db.checkmobile;
  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.ismobile = this.db.checkmobile();
  }
  
}
