import { Injectable } from '@angular/core';
import { DbService } from '../services/db.service';
import { NavigationEnd, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
path;

  constructor(public db:DbService , private router:Router) { }
  IsLoggedIn(){
    return localStorage.getItem('customerRefId')
  }

  get_path(){
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url;
        this.db.path = event.url;      
        let url = this.db.path.split('/')
        // console.log(this.path)  
        if(this.IsLoggedIn() && this.path == '/login') {
          // this.router.navigate(['/'])
        }               
        // if(this.path == '/' || this.path == '/register' || url[1] == 'p' || this.path == '/contact-us'){
           
        // }
      }
    });
  }
}
