import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, MenuController } from '@ionic/angular';
import { DbService } from 'src/app/services/db.service';
import $ from 'jquery';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  showmore1 = false;
  showmore2 = false;
  showmore3 = false;
  showmore4 = false;
  showmore5 = false;
  showmore6 = false;

  show_center = false;
  stores = [{ name : 'All Stores',display_name: 'All Stores' },{ name : 'FL Store',display_name:'Direct' }, {name : 'FL MarketPlace', display_name:'Farmlink'}]
  // stores = [{ name : 'All Stores',route: '' },{ name : 'FL Store',route:'/p/fl-store-page' }, {name : 'FL MarketPlace', route:'/p/fl-marketplace'}]
  @Input() menu;
  constructor(public db:DbService,private router:Router, private modalCtrl:ModalController, public menuCtrl:MenuController) { }

  ngOnInit() { 
    
  }

  altlyfes =[
    {
      title: 'About us',
      route:'/p/about-us',
      icon: '/assets/icon/About-us.svg',
      enable: 0
    },
    {
      title: 'Altlyfer',
      route:'/p/altlyfer',
      icon: '/assets/icon/communityy.svg',
      enable: 0
    },
    {
      title: 'Altlyfer Events',
      route: '/p/altlyfer-events',
      icon: '/assets/icon/events-01.svg',
      enable: 0,
    },
    // {
    //   title: 'Become a affiliate',
    //   route: '/p/affiliated',
    //   icon: '/assets/icon/communityy.svg',
    //   enable: 1,
    // },
    // {
    //   title: 'Blogs',
    //   route: '/blog-list',
    //   icon: '/assets/icon/blog.svg',
    //   enable: 1
    //   // (this.db.website_settings && this.db.website_settings.enable_loyalty == 1) ? 1 : 0
    // },
    {
      title: 'Contact Us',
      route: '/contact-us',
      icon: '/assets/icon/contact-us.svg',
      enable: 1
    },
    {
      title: 'FAQ',
      route: '/p/faqs',
      icon: '/assets/icon/question.svg',
      enable: 1
    },
    {
      title: 'Affiliate program',
      route:'/affiliate-program',
      icon: '/assets/icon/affiliate-program.svg',
      enable: 0,
    }
  ]

  Sell_with_us =[
    {
      title: 'Sell with us',
      route: '/p/whysellwithus',
      icon: '/assets/icon/sell-with-us/sell-with-us.svg',
      enable: 1,
    },
    {
      title: 'Fees & Pricing',
      route: '/p/fees-and-pricing',
      icon: '/assets/icon/sell-with-us/price.svg',
      enable: 0,
    },
    {
      title: 'Beginners Guideline',
      route: 'https://cdn.gokommerce.com/altlyfes/1RXGTJIE_ALTLYFES_beginners_guide_1.pdf',
      icon: '/assets/icon/sell-with-us/guidelines.svg',
      enable: 0,
    },
    {
      title: 'Policies For Seller',
      route: '/p/guidelines-and-acceptance-policies-for-content-of-your-shop-at-altlyfescom',
      icon: '/assets/icon/sell-with-us/policy.svg',
      enable: 0,
    },

    {
      title: 'Seller Criteria',
      route: '/p/seller-selection-criteria-1',
      icon: '/assets/icon/sell-with-us/criteria.svg',
      enable: 0,
    },
    {
      title: 'Seller Subscription',
      route: '/p/seller-subscription',
      icon: '/assets/icon/sell-with-us/subscription.svg',
      enable: 0,
    },
  ]

  seller_community:any=[];
 
   shop = [
    {
      title: 'Categories',
      route: '/tabs/category',
      icon: '/assets/icon/category.svg',
      enable: 1
    },
    // {
    //   title: 'Wishlist',
    //   route: '/tabs/wishlist',
    //   icon: '/assets/icon/heart.svg',
    //   enable: 1
    // },
    {
      title: 'MyCart',
      route: '/yourcart',
      icon: '/assets/icon/mycart.svg',
      enable: 1
    },
    {
      title: 'Orders',
      route: '/my-orders',
      icon: '/assets/icon/cart.svg',
      enable: 1
    },
    {
      title: 'Profile',
      route: '/tabs/my-profile',
      icon: '/assets/icon/user-sidemenu.svg',
      enable: 1
    },
    {
      title: 'Wallet',
      route: '/tabs/wallet',
      icon: '/assets/icon/wallet.svg',
      enable: 0
      // (this.db.website_settings && this.db.website_settings.enable_wallet == 1) ? 1 : 0
    },
    {
      title: 'Reward Points',
      route: '/reward-points',
      icon: '/assets/icon/Reward.svg',
      enable: 0
      // (this.db.website_settings && this.db.website_settings.enable_loyalty == 1) ? 1 : 0
    },
  ]

  policy =[  
    {
      title: 'Terms & conditions',
      route:'/terms/terms-condition',
      icon: '/assets/icon/terms.svg',
      enable: 1
    },
    {
      title:'Privacy Policy',
      route:'/terms/privacy-policy',
      icon: '/assets/icon/privacy-policy.svg',
      enable: 1
    },
    // {
    //   title:'Return Policy',
    //   route:'/terms/return-policy',
    //   icon: '/assets/icon/privacy-policy.svg',
    //   enable: 1
    // },
    {
      title:'Cancellation Policy',
      route:'/terms/return-policy',
      icon: '/assets/icon/cancellation-policy.svg',
      enable: 0
    },
    {
      title:'Prohibited Items Policy',
      route:'/p/prohibited-items-policy',
      icon: '/assets/icon/cancellation-policy.svg',
      enable: 0
    },
    {
      title:'Community Policy',
      route:'/p/community-policy',
      icon: '/assets/icon/privacy-policy.svg',
      enable: 0
    }
  ]

  store_settings =[  
    {
      title: 'All Stores',
      display_name: 'All Stores',
      route:'',
      icon: '/assets/icons/All-store.svg',
      enable: 1
    },
    {
      title:'FL Store',
      display_name:'Direct',
      route:'',
      icon: '/assets/icons/FL-Direct.svg',
      enable: 1
    },
    {
      title:'FL MarketPlace',
      display_name:'Farmlink',
      route:'',
      icon: '/assets/icons/Farmlink.svg',
      enable: 1
    }
  ]

  get_store_value(item){
   return this.db.store_name == item.title
  }

  store_select(item){

    this.db.store_name = item.detail.value;
    // this.db.store_name = item;
    localStorage['store_name'] = item.detail.value;
    this.db.store_selection.next('store_selected');
    this.db.get_website_settings();
    
    // this.db.store_name = item;
    // localStorage['store_name'] = item;
    // this.db.store_selection.next('store_selected')
    // this.modalCtrl.dismiss();
  }

  redirect(){
    !this.db.center_name ? this.router.navigateByUrl('/location') : null;
  }

  select_center(){

    // this.db.select_store();
    
    if(!this.db.cust_email && !this.db.cust_name){
      this.menuCtrl.close();
      this.router.navigateByUrl('/location');
    }else{
      this.show_center =! this.show_center;
    }
  }

}
