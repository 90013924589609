<!-- Search bar  -->
<div #search_dropdown class="web__searchbar fnt-14" [ngClass]="db.video_header?'white__':'black__'" [class.focused]="search_down">
      <!-- (mouseup)="searchproduct($event)" (ngModelChange)="searchproduct($event)"  -->
    <!-- dinesh -->
    <div class="d__flex" *ngIf="!db.ismobile">
      <!-- <div class="search-filters"> -->
        <!-- <div interface="popover" class="p-10 d__flex mouse" (click)="product_shop = true" value="Product" placeholder="Filter">
          <ion-select-option value="Product">Product</ion-select-option> (ionChange)="onSelectChangevalue($event)"
          <ion-select-option value="Shop">Shop</ion-select-option>
          {{filter}} 
 
        </div> -->
        <!-- <div *ngIf="product_shop" (mouseleave)="product_shop = false" class="productshop_dropdown">
          <p>SEARCH ITEMS</p>
          <h6 (click)="filter_fun('Product')" (click)="product_shop = false" class="product mouse">Product</h6>
          <h6 (click)="filter_fun('Shop')" (click)="product_shop = false" class="product mouse">Shop</h6>
        </div> -->
      <!-- </div> -->
      
      <ion-input  class="ion-input" (ionFocus)="search_down = true" (click)="show_category = true" (ionBlur)="search_down = false" autocomplete="off" (ionChange)="filter == 'Product'?searchproduct($event):search_shops($event)"  name="search" [(ngModel)]="keyword"  (keyup.enter)="filter == 'Product'?icon_searchproduct($event):icon_searchshop($event)" [placeholder]="filter == 'Product' ? 'Search products' : 'Search Shops'"></ion-input>
      <!-- routerLink="/search" [queryParams]="{ q: keyword }"  routerDirection="forward"  routerLink="/search" [queryParams]="{ q: keyword }"  -->
      <ion-button fill="clear" class="search-btn" (click)="filter == 'Product'?icon_searchproduct($event):icon_searchshop($event)">
        <img class=" icon mouse" *ngIf="db.website_settings && db.website_settings.app_settings && db.website_settings.app_settings.header_search_icon" [src]="db.product_img(db.website_settings.app_settings.header_search_icon)">
      </ion-button>
    </div>
  
<!-- [class.focused]="search_down" -->
    <div *ngIf="keyword && db.search_productss.length != 0 && search_value" id="search__down" class="search__dropdown mouse" (mouseleave)="search_value = false">

      <div class="search__product d__flex gap-10" *ngFor="let item of db.search_productss;let i = index" [id]="i+'s'" [class.active_dropdown]="i == active_dropdwon" (click)="db.search_productss = []" (click)="db.childs.child_1_name = undefined" routerLink="/pr/{{item.route}}">
          <ion-avatar><img [src]="db.product_imgs(item.product_image )"> </ion-avatar>
          <ion-label class="fnt-14 webkit-text " [style.--line]="2" >{{item.item}}</ion-label>
          <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>

    </div>

    <div *ngIf="keyword && db.search_shop.length != 0 && search_Shop" id="search__down" class="search__dropdown mouse" icon_searchshop (mouseleave)="search_Shop = false">

      <div class="search_shop d__flex gap-10" *ngFor="let item of db.search_shop;let i = index" [id]="i+'s'" [class.active_dropdown]="i == active_dropdwon" (click)="db.search_shop = []" (click)="db.childs.child_1_name = undefined" routerLink="/vendor-products/{{item.route}}">
        <ion-avatar><img [src]="item.restaurant_logo?db.product_img(item.restaurant_logo):'assets/imgs/default.png'"> </ion-avatar>
        <ion-label class="fnt-14 webkit-text " [style.--line]="2" >{{item.restaurant_name| titlecase}}</ion-label>
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>

    </div>

<!-- empty_search_productss.length != 0 && search_value -->
    <div *ngIf="!keyword && db.viewcontent_serach && db.viewcontent_serach.length != 0  && show_category" id="search__down" class="empty_search__dropdown d__flex"  (mouseleave)="search_value = false">
      
      <h6 class="m-0 search_e">Enter a text to search a products...</h6>

      <h6 class="m-0 top_e">Top categories</h6>
      <div class="grid_item_search" *ngFor="let item of db.viewcontent_serach.slice(0,9);let i = index" [id]="i+'s'"  (click)="show_category = false"  (click)="db.childs.child_1_name = undefined" [routerLink]="!db.ismobile ? '/shop/'+item.route : '/shop/'+item.route">
        <div class="image">
          <img [src]="db.product_imgs(item.mobile_image || item.image)"> 
        </div>
        <div class="title">
           <ion-label>{{item.category_name  || item.item_title}}</ion-label>
        </div> 
      </div>
    </div>

</div>