
<div class="container">

    <div class="container-desc-header ion-text-center">
      <h1>SignUp for free!</h1>
      <div>
        <p class="m-0">Already registered? <span class="mouse user-select alternative"   (click)="db.registerform = false" (click)="check()">Sign In Now</span></p>
      </div>
    </div>

    <div class="w-100">
        <app-new-signup></app-new-signup>
    </div>
    
   </div>