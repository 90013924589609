<!-- Created by Gopi -->
<div [class.d__flex]="(!tabs_top || (tabs_top && tabs_top == 0))" class="container__ main-width">

  <div *ngIf="profile_menu && (tabs_left && tabs_left == 1)" class="side_one">
    <app-profile-card (menu_name)="menu_name($event)" [profile_menu]="profile_menu"
      type="edit-profile"></app-profile-card>
  </div>

  <div *ngIf="profile_menu && (tabs_top && tabs_top == 1)" class="tabs_top">
    <app-tabs-button [border_none]="true" (menu_name)="menu_name($event)" [change_color_none]="true"
      [tabs_array]="profile_menu" type="edit-profile"></app-tabs-button>
  </div>

  <div class="side_two">
    <div class="webform__container">

      <div
        *ngIf="section_break_data && section_break_data_2 && section_break_name && section_break_name.length > 0 && info.length > 0"
        class="form-full-container">
        <div class="form-container enable-border">
          <form [formGroup]="form_data">
            <div class="form-data">

              <div class="align__c"
                [ngClass]="{'rows-container':((section_break_data_2 && (section_break_data && !section_break_data[section].label)) ||( section_break_data_2 && section_break_data[section] && section_break_data[section].label)) }"
                *ngFor="let section of section_break_name">

                <!-- <h3  class="m-0 Heading" *ngIf="section_break_data[section].heading" >{{section_break_data[section].heading}}</h3> -->
                <h3 class="m-0 Heading" *ngIf="section_break_data[section].label">{{section_break_data[section].label}}
                </h3>

                <div [style.margin-right]='flex_margin'
                  [ngClass]="{'data-manl-div-check':(each.fieldtype == 'Check'),'data-manl-div':(each.fieldtype != 'Check')}"
                  *ngFor="let each of section_break_data[section]" [style.flex]='section_break_data[section].flex'
                  [id]="each.fieldname">

                  <h6 *ngIf="each.fieldtype == 'Data'" class="label-1">{{each.label}}<span class="impt-star"
                      *ngIf="each.reqd == 1">*</span></h6>
                  <div class="custom-input" *ngIf="each.fieldtype == 'Data' && each.options != 'Email'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" type="text" (ionChange)="assign_business($event,each)" [placeholder]="each.placeholder" [formControlName]="each.fieldname"
                      class="form-input"></ion-input>
                  </div>
                  <div class="custom-input" *ngIf="each.fieldtype == 'Data' && each.options == 'Email'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" type="text" [placeholder]="each.placeholder" [formControlName]="each.fieldname"
                      class="form-input"></ion-input>
                  </div>


                  <!-- Link and Select Multi select-->
                  <h6 *ngIf="each.fieldtype == 'Link_multi_select'" class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div *ngIf="each.fieldtype == 'Link_multi_select'" class="div__">
                    <!-- <ng-multiselect-dropdown
                  [placeholder]="'Any'"
                  [settings]="dropdownSettings"
                  [data]="all_link_opts[each.options]"
                  [(ngModel)]="selectedItems[each.options]"
                  [ngModelOptions]="{standalone: true}"
                  (onSelect)="multivalue_select_link($event,each,all_link_opts[each.options])"
                  (onSelectAll)="onSelectAll($event)"
                  (onDeSelect)="onItemDeSelect($event,each,all_link_opts[each.options])"
                >
                </ng-multiselect-dropdown> -->
                  </div>


                  <!-- Check box -->

                  <div class="custom-input-check" *ngIf="each.fieldtype == 'Check'">
                    <!-- (ionChange)="check_box(each)"  -->
                    <!-- (each.default == '1' || each.default == 1) ? 'true' : 'false' -->

                    <ion-checkbox [formControlName]="each.fieldname" (ionChange)="check_box(each)"
                      [checked]="(each.default == '1' || each.default == 1) ? 'true' : 'false'"
                      color="primary"></ion-checkbox>
                  </div>
                  <h6 *ngIf="each.fieldtype == 'Check'" class="label-1 ion-check-label">{{each.label}}{{each.description}}<span
                      class="impt-star" *ngIf="each.reqd == 1"> *</span></h6>

                  <!-- Link and Select-->

                  <h6 *ngIf="each.fieldtype == 'Link' || each.fieldtype == 'Select'" class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="custom-input" *ngIf="each.fieldtype == 'Link'">
                    <!-- [placeholder]="each.placeholder" -->
                    <ng-select [id]="each.fieldname + 'ng-select'" #select [items]="all_link_opts[each.options]" class="medium_name"
                      appendTo=".align__c" [clearable]="false" [searchable]="false"
                      bindLabel="id" bindValue="id" [multiple]="false" [formControlName]="each.fieldname"
                      [searchFn]="db.CustomSearch" (click)="focusof()" (change)="ng_select($event,each)" (search)="db.onSearch($event)">
                      <ng-template ng-header-tmp>
                        <div class="custome_input d__flex gap-5 just-s">
                          <input #customInput style="width: 100%;line-height: 25px; border:unset"
                            [placeholder]="'search'" type="text" (input)="select.filter($any($event.target).value)"
                            (change)="$event.stopPropagation()" (keydown)="select.handleKeyDown($event)" />
                          <img style="width: 15px;" src="../../../../assets/icon/search.svg">
                          <!-- <ion-button class="add_new_" fill="clear">
                           <img style="width: 15px;" src="assets/icon/add_black.svg">
                           <span class="m-0 title">Create</span>
                        </ion-button> -->

                        </div>
                      </ng-template>

                    </ng-select>
                    <!-- <ion-select [formControlName]="each.fieldname" class="select-1" [placeholder]="each.placeholder" interface="popover">
                    <ion-select-option></ion-select-option>
                    <ion-select-option *ngFor="let opts of all_link_opts[each.options]" [value]="opts">
                      {{opts}}
                    </ion-select-option>
                  </ion-select> -->
                  </div>

                  <div class="custom-input" *ngIf="each.fieldtype == 'Select'">
                    <!-- [placeholder]="each.placeholder" -->
                    <ng-select [id]="each.fieldname + 'ng-select'" #select [items]="each.options" class="medium_name" 
                      appendTo=".align__c" [clearable]="false" [searchable]="false" bindLabel="" bindValue=""
                      [multiple]="false" [formControlName]="each.fieldname" [searchFn]="db.CustomSearch"
                      (click)="focusof()" (search)="db.onSearch($event)">
                      <ng-template ng-header-tmp>
                        <div class="custome_input d__flex gap-5 just-s">
                          <input #customInput style="width: 100%;line-height: 25px; border:unset"
                            [placeholder]="'search'" type="text" (input)="select.filter($any($event.target).value)"
                            (change)="$event.stopPropagation()" (keydown)="select.handleKeyDown($event)" />
                          <img style="width: 15px;" src="../../../../assets/icon/search.svg">
                          <!-- <ion-button class="add_new_" fill="clear">
                           <img style="width: 15px;" src="assets/icon/add_black.svg">
                           <span class="m-0 title">Create</span>
                        </ion-button> -->

                        </div>
                      </ng-template>

                    </ng-select>
                    <!-- <ion-select [formControlName]="each.fieldname" class="select-1" [placeholder]="each.placeholder" interface="popover">
                    <ion-select-option></ion-select-option>
                    <ion-select-option *ngFor="let opts of all_link_opts[each.options]" [value]="opts">
                      {{opts}}
                    </ion-select-option>
                  </ion-select> -->
                  </div>


                  <!-- Geo location -->

                  <h6 *ngIf="each.fieldtype == 'Geo Location'" class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="loaction" *ngIf="each.fieldtype == 'Geo Location'">

                    <div class="maps-container">
                      <div class="d__flex location__input" #location_dropdownn>

                        <div class="location__search w-100">
                          <input (focus)="map_focus()" #addresstext class="input" placeholder="Search your location"
                            type="text">
                        </div>

                        <ion-button (click)="db.current_locate('get_map_fields')" fill="clear"
                          class="m-0 location-button-wb ion-text-capitalize"> <ion-icon
                            name="locate-outline"></ion-icon> </ion-button>
                      </div>
                      <div><app-maps [get_map_fields]="true"></app-maps></div>
                    </div>

                  </div>




                  <!-- start -->

                  <!-- <h6 *ngIf="(each.fieldtype == 'Link' || each.fieldtype == 'Select') && (each.options !='Job Category')" class="label-1">{{each.label}}
                  <span class="impt-star" *ngIf="false &&  each.reqd == 1"> *</span>
                </h6>
              
                <h6 *ngIf="(each.fieldtype == 'Link' || each.fieldtype == 'Select') && (each.options == 'Job Category') && (each.label != 'Job sub category')" class="label-1">{{each.label}}
                  <span class="impt-star" *ngIf="false &&  each.reqd == 1"> *</span>
                </h6> -->

                  <!-- edit -->
                  <h6
                    *ngIf="sub_category && (each.fieldtype == 'Link' || each.fieldtype == 'Select') && (each.options =='Job Category') && ((each.label == 'Job sub category'))"
                    class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <!-- edit -->



                  <!-- Int && Float && currency-->
                  <h6
                    *ngIf="each.fieldtype == 'Int' || each.fieldtype == 'Phone' || each.fieldtype == 'Float' || each.fieldtype == 'Currency'"
                    class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="custom-input"
                    *ngIf="each.fieldtype == 'Int' || each.fieldtype == 'Phone' || each.fieldtype == 'Float' || each.fieldtype == 'Currency'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" id="inputBox" step="any" min="0" type="number" [placeholder]="each.placeholder"
                      [formControlName]="each.fieldname" class="form-input">
                    </ion-input>
                  </div>

                  <!-- Attach && Attach Image -->
                  <h6 id="attach" *ngIf="each.fieldtype == 'Attach' || each.fieldtype == 'Attach Image'"
                    class="label-1">
                    {{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="image-div_" *ngIf="each.fieldtype == 'Attach' || each.fieldtype == 'Attach Image'">
                    <input #file_upload class="image-d" (change)="changeListener($event,each)" type="file"
                      [formControlName]="each.fieldname" class="form-input">

                    <div [class.attached]="each.file_url" class="d__flex browser">
                      <div (click)="file_upload.click()" class="mouse d__flex name">
                        <!-- <span>Browse</span> -->
                        <img src="/assets/icon/file_upload.svg">
                        <!-- <ion-icon name="chevron-down-outline"></ion-icon> -->
                      </div>
                      <div class="d__flex value_div">
                        <a (click)="each.file_url ? this.db.preview_image(db.product_img(each.file_url)) : file_upload.click()"
                          class="m-0 mouse value_ webkit-text" [style.--line]="1">{{each.file_url ? each.file_url :
                          'Upload a file'}}</a>
                        <!-- <ion-icon *ngIf="each.file_url" (click)="remove_form_value(each)" class="close_icon" name="close-outline"></ion-icon> -->
                      </div>
                    </div>
                    <!-- <div class="d__flex browser">
                     <div (click)="file_upload.click()" class="mouse d__flex name">
                       <span>Browse</span>
                       <ion-icon name="chevron-down-outline"></ion-icon>
                     </div>
                     <a (click)="each.file_url ? this.db.preview_image(db.product_img(each.file_url)) : file_upload.click()" class="m-0 mouse value_ webkit-text" [style.--line]="1">{{each.file_url ? each.file_url : 'Upload a file'}}</a>
                  </div> -->

                  </div>



                  <!-- End -->
                  <!-- Date -->
                  <h6 *ngIf="each.fieldtype == 'Date'" class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="custom-input" *ngIf="each.fieldtype == 'Date'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" type="date" [formControlName]="each.fieldname" class="form-input"></ion-input>
                    <!-- <ion-input [placeholder]="each.label" onfocus="(this.type='date')"
                    onblur="if(this.value==''){this.type='text'}" [formControlName]="each.fieldname" class="form-input">
                  </ion-input> -->
                  </div>

                  <!-- Datetime-->
                  <h6 *ngIf="each.fieldtype == 'Datetime'" class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="custom-input" *ngIf="each.fieldtype == 'Datetime'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" type="datetime-local" [formControlName]="each.fieldname" class="form-input"></ion-input>
                  </div>

                  <!-- text editor -->

                  <h6
                    *ngIf="each.fieldtype == 'Text Editor' || each.fieldtype == 'Code' || each.fieldtype == 'HTML' || each.fieldtype == 'HTML Editor' || each.fieldtype == 'Markdown Editor'"
                    class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="custom-input"
                    *ngIf="each.fieldtype == 'Text Editor' || each.fieldtype == 'Code' || each.fieldtype == 'HTML' || each.fieldtype == 'HTML Editor' || each.fieldtype == 'Markdown Editor'">
                    <!-- <quill-editor class="text-editor" [formControlName]="each.fieldname" [styles]="{height: '285px'}">
                  </quill-editor> -->
                  </div>

                  <!-- time-->

                  <h6 *ngIf="each.fieldtype == 'Time'" class="label-1">{{each.label}}
                    <span class="impt-star" *ngIf="each.reqd == 1"> *</span>
                  </h6>

                  <div class="custom-input" *ngIf="each.fieldtype == 'Time'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" type="time" [formControlName]="each.fieldname" class="form-input">
                    </ion-input>
                  </div>

                  <!-- Text,small text,long text -->
                  <h6
                    *ngIf="each.fieldtype == 'Text' || each.fieldtype == 'Long Text' || each.fieldtype == 'Small Text'"
                    class="label-1">{{each.label}}<span class="impt-star" *ngIf="each.reqd == 1"> *</span></h6>

                  <div class="custom-input"
                    *ngIf="each.fieldtype == 'Text' || each.fieldtype == 'Long Text' || each.fieldtype == 'Small Text'">
                    <!-- <ion-input type="text" [formControlName]="each.fieldname" class="form-input"></ion-input> -->
                    <ion-textarea class="text-area" [formControlName]="each.fieldname"
                      placeholder="Enter text here..."></ion-textarea>
                    <!-- <textarea ng-model="myTextarea">Enter text here...</textarea> -->
                  </div>

                  <!-- Password -->
                  <h6 *ngIf="each.fieldtype == 'Password'" class="label-1">{{each.label}}<span class="impt-star"
                      *ngIf="each.reqd == 1">*</span></h6>

                  <div class="custom-input" *ngIf="each.fieldtype == 'Password'">
                    <ion-input [disabled]="each.read_only == 1 ? true : false" type="Password" [formControlName]="each.fieldname" class="form-input"></ion-input>
                  </div>

                  <!-- (Table Field) Child table -->
                  <h6 *ngIf="each.fieldtype == 'Table'" class="label-1">{{each.label}}<span class="impt-star"
                      *ngIf="each.reqd == 1"> *</span></h6>

                  <div (click)="open_child_modal(each.options,each.fieldname)"
                    *ngIf="each.fieldtype == 'Table' && (!test_child_data[each.fieldname] || (test_child_data[each.fieldname] && test_child_data[each.fieldname].length == 0))"
                    class="d__flex mouse browser__">
                    <div class="mouse d__flex name">
                      <img src="/assets/icon/upload.svg">
                    </div>
                    <div class="d__flex value_div">
                      <a class="m-0 mouse value_ webkit-text" [style.--line]="1">Upload a files</a>
                    </div>
                  </div>

                  <div class="table_view"
                    *ngIf="db.ismobile && each.fieldtype == 'Table' && (test_child_data[each.fieldname] && test_child_data[each.fieldname].length != 0)">

                    <div *ngFor="let dt of test_child_data[each.fieldname];let k=index;"
                      class="d__flex mouse browser__">
                      <div class="mouse d__flex name">
                        <!-- <img [src]="db.attach_filter(each.fieldname == 'customer_identity_proof' ? dt.identity_proof_attachment : (each.fieldname == 'customer_address_proof' ? dt.address_proof_attachment : each.fieldname == 'customer_business_document' ? dt.document_attach : ''),'_green')"> -->
                      </div>
                      <div class="value_div">

                        <div class="box_ d__flex">
                          <h6 [ngStyle]="{'width': '170px'}" class="m-0 bold_name-14 webkit-text" [style.--line]="1">
                            {{each.fieldname == 'customer_identity_proof' ? dt.identity_proof : (each.fieldname ==
                            'customer_address_proof' ? dt.address_proof : each.fieldname == 'customer_business_document'
                            ? dt.business_documents : '') }}</h6>
                          <div class="icons gap-5 d__flex">
                            <ion-icon class="close_icon"
                              (click)="edit_child_modal(test_child_data[each.fieldname],each.options,each.fieldname,k)"
                              name="create-outline"></ion-icon>
                            <ion-icon class="close_icon delete" name="trash-outline"
                              (click)="delete_records1(test_child_data[each.fieldname],each.fieldname,dt)"></ion-icon>
                          </div>
                        </div>

                        <a class="value_d m-0 mouse gray_cr small_name webkit-text" [style.--line]="1">{{each.fieldname
                          == 'customer_identity_proof' ? dt.identity_proof_attachment : (each.fieldname ==
                          'customer_address_proof' ? dt.address_proof_attachment : each.fieldname ==
                          'customer_business_document' ? dt.document_attach : '') }}</a>

                      </div>
                    </div>

                    <ion-button fill="clear" class="child-table-btn"
                      (click)="open_child_modal(each.options,each.fieldname)">Add more files</ion-button>

                  </div>

                  <div class="table"
                    *ngIf="!db.ismobile && each.fieldtype == 'Table' && (test_child_data[each.fieldname] && test_child_data[each.fieldname].length != 0)">
                    <!-- [ngClass]='test_child_data[each.fieldname]?"main-con-child-table":"main-con-child-table main-con-child-table-no-border"' -->
                    <!-- ':(test_child_data[each.fieldname] || !test_child_data[each.fieldname]), -->
                    <div class="main-con-child-table"
                      [ngClass]="{'main-con-child-table-no-border':(test_child_data[each.fieldname] && test_child_data[each.fieldname].length == 0) || !test_child_data[each.fieldname] }">
                      <div class="con-child-table">
                        <div class="main-header">
                          <!-- Sno div -->
                          <div class="sno-selectall">
                            <div class="s-no-select-all">
                              <div class="select-all">
                                <ion-button class="header-select-all-btn"
                                  *ngIf="test_child_data[each.fieldname]  && (!test_child_data[each.fieldname].select_all || test_child_data[each.fieldname].select_all!=1) && test_child_data[each.fieldname].length>0; "
                                  (click)="select_all(test_child_data[each.fieldname])">
                                  <ion-icon name="square-outline"></ion-icon>
                                </ion-button>
                                <ion-button class="header-unselect-all-btn"
                                  *ngIf="test_child_data[each.fieldname]  && test_child_data[each.fieldname].select_all && test_child_data[each.fieldname].select_all==1"
                                  (click)="unselect_all(test_child_data[each.fieldname])">
                                  <ion-icon name="checkbox-outline"></ion-icon>
                                </ion-button>
                              </div>
                              <div class="s-no">S.no.</div>
                            </div>
                          </div>
                          <!-- header name div -->
                          <div class="table-header">
                            <div class="sub-header" *ngFor="let head of each.child_header"
                              [style.margin-left]="header_flex_margin" [style.flex]="head.flex">
                              <div class="header-content">
                                <div class="header-namee">{{head.label}}</div>
                              </div>
                            </div>
                          </div>
                          <!-- Edit div -->
                          <div class="edit"
                            [ngClass]="{'edit-content-with-border':each.child_header.length==0,'':each.child_header.length>0}">
                            <div class="edit-content">
                              Option</div>
                          </div>
                        </div>
                        <!-- Content Div -->
                        <div class="main-content" *ngFor="let dt of test_child_data[each.fieldname];let k=index;">
                          <div class="table-content">
                            <!-- sno and select -->
                            <div class="select-sno">
                              <div class="d__flex each-select-sno">
                                <ion-icon
                                  (click)="dt.select ? select_unselect(dt,test_child_data[each.fieldname],k) : select_unselect(dt,test_child_data[each.fieldname],k)"
                                  name="square-outline"></ion-icon>
                                <span class="sno-c">{{k+1}}.</span>
                                <!-- <div class="select-c">
                                <ion-button fill="clear" class="each-select-btn" *ngIf="!dt.select" (click)="select_unselect(dt,test_child_data[each.fieldname],k)">
                                  <ion-icon name="square-outline"></ion-icon>
                                </ion-button>
                                <ion-button fill="clear" class="each-select-btn" *ngIf="dt.select" (click)="select_unselect(dt,test_child_data[each.fieldname],k)">
                                  <ion-icon name="checkbox-outline">
                                  </ion-icon>
                                </ion-button>
                              </div>
                              <div class="sno-c">
                                {{k+1}}.
                              </div> -->
                              </div>
                            </div>

                            <!-- Content -->
                            <div class="t-content">
                              <div [ngClass]="dt[head.fieldname]?'each-data-con':'each-data-con no-each-data-con'"
                                *ngFor="let head of each.child_header" [style.margin-left]="header_flex_margin"
                                [style.flex]="head.flex">
                                <div class="each-cont-con">{{dt[head.fieldname]}}</div>
                              </div>
                            </div>
                            <!-- Edit -->
                            <div class="edit-each-content">
                              <div class="edit-c">
                                <ion-button class="edit-btn-con"
                                  (click)="edit_child_modal(test_child_data[each.fieldname],each.options,each.fieldname,k)">
                                  <ion-icon name="create-outline">
                                  </ion-icon>
                                </ion-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- If its has no data -->
                        <div class="no-data-image"
                          *ngIf="!test_child_data[each.fieldname] || test_child_data[each.fieldname].length == 0">
                          <div class="no-image">
                            <ion-icon name="alert-circle-outline"></ion-icon>
                          </div>
                          <div class="no-data">No Data</div>
                        </div>
                      </div>
                    </div>
                    <div class="delete-add-btn">
                      <div class="delete-child-btn d__flex just-c"
                        (click)="delete_records(test_child_data[each.fieldname],each.fieldname)"
                        *ngIf="test_child_data[each.fieldname] && test_child_data[each.fieldname].select && test_child_data[each.fieldname].select==1">
                        <ion-icon name="trash-outline"></ion-icon>
                      </div>
                      <ion-button class="child-table-btn" (click)="open_child_modal(each.options,each.fieldname)">Add
                        more files</ion-button>
                    </div>
                  </div>

                  <!-- help message -->
                  <p *ngIf="each.help" class="help_msg m-0">{{each.help}}</p>

                  <!-- Error msg required field and mail field-->
                  <div class="error-msg"
                    *ngIf="submitted && each.reqd == 1 && ((form_data.get(each.fieldname) && form_data.get(each.fieldname).errors) || (form_data.get(each.fieldname) && form_data.get(each.fieldname).status))">
                    <p class="error-msg-alert"
                      *ngIf="form_data.get(each.fieldname).errors && form_data.get(each.fieldname).errors.required">
                      {{each.label == ' ' ? each.placeholder : each.label}} is required *</p>
                    <p class="error-msg-alert"
                      *ngIf="form_data.get(each.fieldname).errors && form_data.get(each.fieldname).errors.email && form_data.get(each.fieldname).status=='INVALID'">
                      Please Enter Valid {{each.label}} *</p>
                  </div>
                  <div class="error-msg"
                    *ngIf="submitted && each.reqd != 1 && ((form_data.get(each.fieldname) && form_data.get(each.fieldname).errors) || (form_data.get(each.fieldname) && form_data.get(each.fieldname).status) )">
                    <p class="error-msg-alert"
                      *ngIf="form_data.get(each.fieldname).errors && form_data.get(each.fieldname).errors.email && form_data.get(each.fieldname).status=='INVALID'">
                      Please Enter Valid {{each.label}} *</p>
                  </div>
                  <div class="error-msg"
                    *ngIf="submitted && (each.reqd != 1 || each.reqd == 1) && ((form_data.get(each.fieldname) && form_data.get(each.fieldname).errors && form_data.get(each.fieldname).errors.pattern))">
                    <p class="error-msg-alert"
                      *ngIf="form_data.get(each.fieldname).errors && form_data.get(each.fieldname).errors.pattern && form_data.get(each.fieldname).status=='INVALID'">
                      Please Enter Valid {{each.label}} *</p>
                  </div>
                  <!-- End error -->


                </div>
                <!-- </ion-row> -->
              </div>
            </div>
            <div *ngIf="false" class="alert__scam__message d__flex align__c">
              <div class="terms-condition">
                <ion-checkbox formControlName="terms_condt" checked='false' color="primary"></ion-checkbox>
                <h6> I Agree with the <a class="terms-condti mouse" routerDirection="back" target="_blank"
                    href="/terms/terms-and-condition">terms and conditions</a></h6>
              </div>
              <!-- <div class="alert__txt">
              <span class="alert-btn" routerDirection="back" routerLink="/terms/scam-alert">Alert!</span>
              <h6 class="check-alert">* Never accept check payments from strangers.</h6><span routerDirection="back" routerLink="/terms/scam-alert">
              <a>
                <img class="span_alert_gif" src="/assets/img/scam-fraud-alert.gif">
              </a></span>
            </div>   -->
            </div>




            <!-- Error msg -->

            <div [class.button_positions_end]="button_positions == 'end'" class="submit-btn d__flex just-s">
              <div *ngIf="navigation_count == 0"></div>
              <ion-button *ngIf="navigation_count > 0" fill="clear" class="btn tertiary-btn" (click)="back()"><ion-icon
                  *ngIf="false" class="icon-start" slot="start" src="assets/icon/goback.svg"></ion-icon> Go
                Back</ion-button>
              <ion-button *ngIf="wizard_json.length - 1 != navigation_count" fill="clear"
                class="btn next-btn primary-btn" (click)="next()">Next</ion-button>
              <ion-button *ngIf="wizard_json.length - 1 == navigation_count" fill="clear" class="btn primary-btn"
                (click)="save_details1('')">Submit</ion-button>
            </div>



          </form>
        </div>
      </div>

    </div>

  </div>

</div>