<ion-header [class.clear_bg_a_border]="clear_bg_a_border" class="mobile-header" [ngClass]="!no_home?'no-shadow':''" >

  <ion-toolbar  *ngIf="!no_home">

    <ion-buttons button slot="start">
      <ion-button button class="menu__" (click)="openMenu()">
        <ion-icon class="icon menu"   src="/assets/imgs/menu.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <!-- *ngIf="title == 'home'" -->
    <div slot="start" *ngIf="false" class="mobile-logo d__flex" routerLink="/">
      <img class="home__logo mouse"  [src]="db.product_img(db.website_settings.website_logo)" >
    </div>

    <ion-title *ngIf="title != 'home'" class="">{{title}}</ion-title>
    

    <ion-button fill="clear" slot="end" *ngIf="no_search_icon && false" routerLink="/search" >
      <ion-icon class="icon px-15" slot="end" src="/assets/icon/search.svg"></ion-icon>
    </ion-button>

    <div class="mobile__location" *ngIf="home && false">
      <div class="d__flex mobile-location" >
        <ion-icon class="loc-icon" src="assets/imgs/location.svg" ></ion-icon>
        <div>
          <p class="m-0 fnt-15" *ngIf="db.location_info">{{db.location_info.city}}<ion-icon name="chevron-down-outline"></ion-icon> </p>

          <!-- <span class="loc fnt-16" *ngIf="db.location">{{db.location_info.city}} <ion-icon name="chevron-down-outline"></ion-icon></span> -->
        </div>
      </div>
    </div>

    <div *ngIf="tabs" class="d__flex just-c">
      <div class="d__flex Icon_logo">
        <img class="home__logo mouse" [src]="db.store_name != 'FL Store' ? 'assets/imgs/Farmlink.svg' : 'assets/imgs/FL-Direct_1.svg'">

      </div>
      <!-- <div slot="start" class="mobile-logo d__flex" routerLink="/">
        <img class="home__logo mouse"  [src]="'/assets/imgs/icon.png'" >
      </div>
      <div class="market_change">
        <h6 class="m-0 fnt-14">Store</h6>
        <div (click)="dropdown =! dropdown" (click)="db.select_store()" class="store_ position-relative fnt-14">{{ db.store_name }} <ion-icon slot="end" name="chevron-down-outline"></ion-icon></div>
      </div> -->
    </div>

   
    <!-- db.product_img(db.website_settings.app_settings.cart_icon) -->

    <ion-button fill="clear" class="cart-icon" slot="end" routerLink="/checkout" *ngIf="false">
      <img class=" icon" button *ngIf="db.website_settings && db.website_settings.app_settings" [src]="'/assets/icon/cart.svg'" alt=""> 
      <ion-badge *ngIf="db.cart_count && db.cart_count != 0" class="cart-badge">{{db.cart_count}}</ion-badge>
    </ion-button>

  </ion-toolbar>


  <ion-toolbar class="no__home"  *ngIf="no_home">
    <ion-back-button *ngIf="!db.edit_order_id"   slot="start" icon="chevron-back-outline" (click)="backbtn()" [defaultHref]="back_route?back_route:null"  (click)="!back_route?db.back():''" ></ion-back-button>
    <ion-title> {{title}}</ion-title>
    <div *ngIf="title == 'My Cart' && db.ismobile && db.cart_count != 0" class="clear__cart mouse d__flex" (click)="db.clearcart()">
      <ion-icon src="/assets/icon/delete.svg"></ion-icon>
      <div class="clearcart">{{db.edit_order_id ? "Cancel" : "Clear cart"}}</div>
    </div>
    <div *ngIf="title == 'Wishlist' && db.ismobile && db.wish_count != 0" class="clear__cart mouse d__flex" (click)="moveallto_Cart()">
      <!-- <ion-icon src="/assets/icon/delete.svg"></ion-icon> -->
      <div class="clearcart">Move All To Cart</div>
    </div>
      <!-- <ion-button fill="clear" slot="end" (click)="db.clearcart()" >
      <ion-icon class="icon px-15" slot="end" src="/assets/icon/delete.svg"></ion-icon> Clear cart
    </ion-button> -->
    <app-mobile-header2 *ngIf="cat_list"></app-mobile-header2>
    <div slot="start" class="mobile-logo d__flex" routerLink="/" *ngIf="false">
      <!-- <img class="home__logo mouse"  [src]="'/assets/imgs/Chops-logo-white.svg'"> -->
      <img class="home__logo mouse"  [src]="db.product_img(db.website_settings.website_logo)" >
    </div>
    <!-- <ion-button slot="end" fill="clear" (click)="db.share('product')" *ngIf="title == 'Product Detail'">  <ion-icon   class=" icons" slot="end" name="share-social-outline"></ion-icon></ion-button> -->
    <ion-button fill="clear" *ngIf="!no_cart && false" class="cart-icon" slot="end" routerLink="/checkout" [replaceUrl]="true" >
      
      <!-- db.product_img(db.website_settings.app_settings.cart_icon) -->
      
      <img class=" icon" button *ngIf="db.website_settings && db.website_settings.app_settings" [src]="'/assets/icon/cart.svg'" alt=""> 
      <ion-badge *ngIf="db.cart_count && db.cart_count != 0" class="cart-badge">{{db.cart_count}}</ion-badge>
    </ion-button>

  </ion-toolbar>

</ion-header>