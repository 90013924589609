import { Location } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Meta,
  Title,
} from '@angular/platform-browser';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import {
  FiltersPopupPage,
} from 'src/app/pages/filters-popup/filters-popup.page';
import { QuickviewPage } from 'src/app/pages/quickview/quickview.page';
import { DbService } from 'src/app/services/db.service';
import { SelectAttributeComponent } from '../categories/select-attribute/select-attribute.component';

import {
  ActionSheetController,
  // IonSlides,
  ModalController,
} from '@ionic/angular';

// import {
//   MessageInsertComponent,
// } from '../messages/message-insert/message-insert.component';

@Component({
  selector: 'app-brand-com',
  templateUrl: './brand-com.component.html',
  styleUrls: ['../grid-view/grid-view.component.scss','../categorylist/categorylist.component.scss','./brand-com.component.scss'],
})
export class BrandComComponent implements OnInit {
 
  category_filters;
  no_products = false;
  page_no = 1;
  category_child;
  skeleton = true;
  sortby_drop_down = false;
  choosed_product_id;
  brands_products:any =[];
  sort_text;
  bread_crumb;
  list_height;
  search_key;
  focus :any = {};
  vendor_info:any;
  Info;
  keywords__search;
  bg_color;
  is_show_sort = true;
  @Input() type;
  @Input() search_values;
  @Input() keyword;
  sortings = [
    { text: 'Relevance', role: '' },
    { text: 'Name: A-Z', role: 'name_asc' },
    { text: 'Name: Z-A', role: 'name_desc' },
    { text: 'Price: Low-High', role: 'price_asc' },
    { text: 'Price: High-Low', role: 'price_desc' }
  ]

  // vendor_category = []
  sort= '';
  // modalCtrl: any;
  filtered_vendor;

  shop = true;
  about = false;
  review = false;
  policies = false;
  
  // @ViewChild(IonSlides) slides: IonSlides;

  mobile_slider = {
    // freeMode: true,
    // slidesPerView: this.db.ismobile? 1.2 : 1,
    spaceBetween: 1 ,
    speed: 400,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
  }

  web_slider = {
    // freeMode: true,
    speed: 400,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay:3000,
      disableOnInteraction: false
    },
  }

  constructor(public db:DbService,private meta:Meta,private meta_title:Title,public router:Router,private location:Location,private route:ActivatedRoute,private modalCtrl:ModalController,private actionSheetCtrl:ActionSheetController) { }

  ngOnInit() {
    // console.log('hi')
    this.list_height = window.innerHeight  - 20 - 135 - 180 + 145; 
    this.db.ismobile ? this.db.product_box.view == 'Grid View' : this.db.product_box.view == 'List View';
    if(this.type == 'brand'){
      this.route.params.subscribe(res =>{
        this.choosed_product_id = this.route.snapshot.paramMap.get('brand')+'/'+this.route.snapshot.paramMap.get('id');
        this.bread_crumb = this.route.snapshot.paramMap.get('id');
        })   
        this.db.product_box.row_count = 5
       this.get_brands();
    } else if(this.type == 'vendor'){
      this.bg_color = this.db.getRandomColor();
      this.route.params.subscribe(res =>{
        this.choosed_product_id = this.route.snapshot.paramMap.get('vendor-products')
        // console.log(this.choosed_product_id);
        // this.bread_crumb=this.route.snapshot.paramMap.get('vendor-products');restaurant_name
        })
        this.db.product_box.row_count = 4
        this.get_vendor();
    } else if(this.type == 'search'){
        this.bread_crumb = this.keyword;
        this.search_key =  this.keyword;
        
        this.searchproducts(this.keyword)
    }
  
    // this.get_cat_products('');
    this.db.mycart_emit.subscribe(res => {
      // console.log(res);
      res == 'getted' ?   this.get_recommeded() : null
    })
}

    tabs_fun(arg){
      // console.log("arg",arg)
        if(arg == 'Shop'){
          this.shop = true;
          this.about = false;
          this.review = false;
          this.policies = false;
        }else if(arg == 'About'){
          this.shop = false;
          this.about = true;
          this.review = false;
          this.policies = false;
        }else if(arg == 'Reviews'){
          this.shop = false;
          this.about = false;
          this.review = true;
          this.policies = false;
        }else if(arg == 'Policies'){
          this.shop = false;
          this.about = false;
          this.review = false;
          this.policies = true;
        }
    }

// Brands
  get_brands(){
    
    let data ={
      "route": this.choosed_product_id,
      "sort_by": this.sort,
      "page_no": this.page_no,
      "page_size": 10,
      "isMobile":1,
      customer : localStorage['customerRefId']
    }
  // this.get_category_page_filters(this.db.choosed_product_id)
  this.db.get_brand_based_products(data).subscribe(res=>{
    // this.brands_products = res.message;
    if (res.message && res.message.length != 0) {
      if(this.page_no == 1){
        this.brands_products =  res.message;
      }else {
        this.brands_products = [...this.brands_products,...res.message.products]
        //  res.message.map( d => {  this.cat_products.push(d);  });
      }
    this.get_cart_item();
  } else {
    this.no_products = true;
    this.page_no == 1 ?  this.brands_products = [] : null
  }
  })
}


// Vendor
    get_vendor(){
        
      let data ={
        "route": this.choosed_product_id,
        "sort_by": this.sort,
        "page_no": this.page_no,
        "page_size": 24,
        "isMobile":1,
        "show_reviews": 1,
        "business": this.vendor_info && this.vendor_info.business ? this.vendor_info.business : undefined,
        customer: localStorage['customerRefId']
      }
    // this.get_category_page_filters(this.db.choosed_product_id)
    this.db.get_vendor_based_products(data).subscribe(res => {
      // console.log(res);

      // this.brands_products = res.message;
      if (res.message && res.message.length != 0 && !res.message.status) {
          this.vendor_info = res.message.info
          this.Info = res.message.vendor_reviews
          // this.db.category = res.message.info.business_category
          if(this.page_no == 1){
            this.brands_products =  res.message.products;
            if(res.message.info.restaurant_name){
              this.bread_crumb = res.message.info.restaurant_name;   
            }
          }else {
            // console.log("--------",res)
            this.brands_products = [...this.brands_products,...res.message.products]
            //  res.message.map( d => {  this.cat_products.push(d);  });
          }
            this.get_cart_item();
        } else {
          this.no_products = true;
          this.page_no == 1 ?  this.brands_products = [] : null
        }
    })
    }


    // Search
    searchproducts(d) {
      if(d != ''){
      var data = { 
        "searchTxt": d, 
        page_no: this.page_no, 
        page_len: 15,
        "sort_by": this.sort,
        centre: localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] :''
      }
      // this.get_category_page_filters(d);
          this.db.search_products(data).subscribe(res => {
            this.db.product_box.row_count = 5
            if (res.message && res.message.length != 0) {
                // this.set_is_pre_select(res.message)
              if(this.page_no == 1){
                this.brands_products =  res.message;
                // console.log('this.brands_products',this.brands_products)
              }else {
                this.brands_products = [...this.brands_products,...res.message.products]
                //  res.message.map( d => {  this.cat_products.push(d);  });
              }
            this.get_cart_item();
          } else {
            this.no_products = true;
            this.page_no == 1 ?  this.brands_products = [] : null
          }
          });
        }
        else{
          this.no_products = true;
            this.page_no == 1 ?  this.brands_products = [] : null
        }

    } 

    set_is_pre_select(array){
      array.map(res=>{
        if(res.vendor_price_list && res.vendor_price_list[0] && res.vendor_price_list[0].variants){
          res.vendor_price_list[0].variants.map((r,i)=>{
            if(i == 0){
              r.is_pre_selected = 1;
            }else{
              r.is_pre_selected = 0;
            }
          })
        }
      })
    }

    get_search_vendor_products(name){
      if (this.keywords__search != '' && this.keywords__search != undefined) {
      let data = {  "business": name, "searchTxt": this.keywords__search, page_no: 1, page_len: 15   }

        this.db.search_products(data).subscribe(r => {
            this.brands_products =  r.message; 
            this.is_show_sort = false;
            this.get_cart_item();
        })
      }else{
          this.get_vendor();
          this.is_show_sort = true;
      }
    }


    get_category_page_filters(value){
      // console.log('this.category_filters',this.category_filters)/
      let data = { route : value}
      this.db.get_category_page_filters(data).subscribe(res =>{      
        this.category_filters = res.message;
        this.store_meta(res.message)
        // console.log('this.category_filters',this.category_filters)
        // if(!this.db.ismobile && ( this.category_filters && (this.category_filters.attribute_list.length != 0 || this.category_filters.brand_list.length != 0 || this.category_filters.category_list.all_categories.length != 0 || this.category_filters.category_list.child_categories.length != 0))){
          // console.log('trueeeeeeeeeeeee');
          // console.log('this.category_filters.brand_list',this.category_filters.brand_list)
        // }
        // console.log("category__filter",this.category_filters)
          this.db.choosed_attributes.find((res:any) =>{
            this.db_check(res.attribute,res.value)
          })
      })
    }


    store_meta(data){
      if(data.meta_info){
        this.meta_title.setTitle(data.meta_title);
        this.meta.updateTag({name : "description",content :data.meta_description});
        this.meta.updateTag({property : "og:description",content :data.meta_description} );
        this.meta.updateTag({ property : "twitter:description",content :data.meta_description} ); 
      }
    }

    db_check(attributes,attribute){
      this.category_filters.attribute_list.map(value =>{
          if(attributes == value.attribute_unique_name){
              value.options.map(res =>{
                  if( (attribute).includes(res.unique_name)){
                    res.choosed = 1
                  } else{
                    res.choosed = 0
                  }
              })
          }
        })
    }



  get_cart_item() {
    this.db.get_cart_item();
  }  

  loadData(data) {
    if(!this.no_products){
      this.page_no = this.page_no + 1;
      if(this.type == 'brands'){
        this.get_brands();
      }else if(this.type == 'vendor'){
        !this.filtered_vendor ? this.get_vendor() : this.get_filtered_products(this.filtered_vendor)
      }else if(this.type == 'search'){
        this.searchproducts(this.search_key);
      }
    }
    setTimeout(()=>{ data.target.complete() },400);
  }
  
  change_view() {
    this.db.product_box.view == 'Grid View' ?  this.db.product_box.view = 'List View' :  this.db.product_box.view = 'Grid View';
  }

  async select_sort(data) {
    const actionsheet = await this.actionSheetCtrl.create({
      header: data,
      buttons: this.sortings
    })
    await actionsheet.present();
    const { role } = await actionsheet.onDidDismiss();
    if(role != 'backdrop'){
      this.db.minimax_price.mini_price = '';
      this.db.minimax_price.max_price = '';
      this.get_cat_products(role);
    }
  }


  get_cat_products(sort) {
    this.page_no = 1;
    this.no_products = false;
    // this.skeleton = true;
    // setTimeout(() => { this.skeleton = false;  }, 900);
    this.sort = sort;
    this.get_recommeded();
    this.sortby_drop_down ? this.sortby_drop_down = false : null
    this.smoothscroll();
    // this.category_products();
    // this.get_brands();
    if(this.type == 'brands'){
      this.get_brands();
    }else if(this.type == 'vendor'){
      this.get_vendor();
    }else if(this.type == 'search'){
      this.searchproducts(this.search_key);
    }
  }

  smoothscroll(){
    let val = document.getElementById("content") as HTMLInputElement
    val.scrollIntoView({behavior:'smooth'});
    // window.scroll({   top: 0, left: 0,  behavior: 'innersmooth'  });
  }


  get_filtered_products(data){
    // console.log("DATA",data)
    this.filtered_vendor = data
    this.choosed_product_id = data
    // console.log("DATACHI",this.choosed_product_id)
    this.no_products ? this.page_no = 1 : null
    this.category_products() 
  }


  category_products() {
    let attributes  = [];
    let datas = {
      "route": this.choosed_product_id,
      // "category": this.db.choosed_product_id,
      "sort_by": this.sort  ,//sort,
      "min_price": this.db.minimax_price.mini_price,// min,
      "max_price": this.db.minimax_price.max_price ,//max,
      "page_no": this.page_no,//page,
      "page_size": 12,
      "brands": this.db.choosed_brands.toString(), //brands,   this.db.choosed_attributes.toString()
      "attributes": attributes, //attributes,
      "productsid": '' ,//productsid,
      "isMobile": 1,
      "rating": this.db.rating,
      "domain" : this.db.domain,
      centre: localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] : '',
      
       'customer' : localStorage['customerRefId'],
       'business_id' : this.vendor_info.name
    }
    
    // this.get_category_page_filters(this.db.choosed_product_id)
    this.db.sub_product_category(datas).subscribe(res=>{
      if (res.message && res.message.length != 0) {
          if(this.page_no == 1){
            this.brands_products =  res.message;
            // this.db.category_bar_web = true;
            
          } else if(this.brands_products.length !=0){
            this.brands_products = [...this.brands_products,...res.message.products]
            this.db.category_bar_web = true;
            // console.log("page number not 1",this.brands_products)
            //  res.message.map( d => {  this.cat_products.push(d);  });
          }else{
            this.brands_products = [...this.brands_products,...res.message.products]
            this.db.category_bar_web = true;
          }
        this.get_cart_item();
      } else {
        this.no_products = true;
        this.page_no == 1 ?  this.brands_products = [] : null;
        // this.db.category_bar_web = false;
      }
    })
  }



  async select_filter(data){
    const modal = await this.modalCtrl.create({
      component:FiltersPopupPage,
      componentProps:{
        brands : this.category_filters
      }
    })
    await modal.present();
    let value =await modal.onWillDismiss();
    if(value.data == 'success'){
      this.get_cat_products('')
    }
  }


  async openQuickView(product_info){
    let temp_path = '';
    const modal = await this.modalCtrl.create({
      component: QuickviewPage,
      cssClass:  'quick-view-popup',
      componentProps: {  product_detail : product_info }
    })
    temp_path = location.pathname;
    this.location.replaceState('/pr/'+product_info.route);
    this.db.modal = true;
    await modal.present();
    let data = await modal.onWillDismiss();
    if(data){
      this.db.modal = false;
      this.location.replaceState(temp_path);
    }
  }

    //addtocart
    async addtocart(value) {
      this.db.seller_price = {};
      // console.log(value);
      !value.ids ? (value.ids ='' ,value.selected_attribute ='') : null
      value.vendor_price_list[0].variants && value.vendor_price_list[0].variants.length == 0 ? this.db.addtocart(value) : this.select_attribute(value);

      // value.product_attributes && value.product_attributes.length != 0 ?  this.openQuickView(value) : this.db.addtocart(value)
    }
  
    //Remove Cart
    async removetocart(value) {
      this.db.seller_price = {};
      value.vendor_price_list[0].variants && value.vendor_price_list[0].variants.length == 0 ? this.db.removetocart(value) : this.select_attribute(value);

      // this.db.removetocart(value)
    }


    async select_attribute(product_info){
      const modal = await this.modalCtrl.create({
        component: SelectAttributeComponent,
        cssClass:  this.db.ismobile ? 'attribute_change_mobile' : 'attribute_change_web',
        componentProps: {  product_detail : product_info }
      })
  
      this.db.modal = true;
      await modal.present();
      let data = await modal.onWillDismiss();
      if(data){
        this.db.modal = false;
      }
    }
  


    addtowish(value){
      this.db.seller_price = {};
      if(value.wish_count != 0){
        this.db.removewish(value);
      } else if(value.wish_count == 0){
        this.db.addtowish(value);
        // if(value.product_attributes.length == 0) {
        //   this.db.addtowish(value);
        // } else {
        //     this.db.ismobile? this.router.navigateByUrl('/pr/'+value.route) : this.openQuickView(value)
        //   // this.product_detail.ids ?   this.db.addtowish(value) : this.db.alert_animate.next("Please select attributes..!")
        // }
      }
    }
  
    get_recommeded() {
      // this.brands_products.find(res => {
      //   res.count = this.db.checkcart(res.name)
      //   res.wish_count = this.db.checkWishCart(res.name)
      // })

      this.brands_products.find(res => {
        res.count = this.db.checkcart(res.name)
        res.wish_count = this.db.checkWishCart(res.name);
        if (res.vendor_price_list && res.vendor_price_list.length != 0) {
          res.vendor_price_list.find(rec =>{
            if(rec.variants && rec.variants != 0){
                rec.variants.map(r =>{
                  if(r.attribute_id == rec.variants[0].attribute_id && !r.selected_variant && !this.db.is_variant){
                    this.db.check_attribute_cart(res,res.vendor_price_list[0]);
                  }else{
                    r.count = this.db.check_attribute(r.attribute_id);
                    res.count = r.count;
                  }  
                })
            } 
          })
        }
      })

    }


  



    share = [
      {
        'title':'Contact Shop Owner',
        'icon':'/assets/icon/vendor/contact.svg',
        'link' :'/contact-us/vendor'
      },
      // {
      //   'title':'Report This Shop',
      //   'icon':'/assets/icon/about.svg'
      //   // 'icon':'/assets/icon/vendor/favourite.svg',
      // },
      {
        'title':'Share',
        'icon':'/assets/icon/vendor/share.svg',
      }
    ]


    goto(title){
      if(title == 'Report This Shop'){
        this.report();
      }else if(title == 'Share'){
         this.db.share('brand');
      }
    }

    async raiseQuestion(){
      // const modal = await this.modalCtrl.create({
      //   component: MessageInsertComponent,
      //   cssClass:'review-poup',
      //   componentProps:{
      //     info : { type : 'General' , business : this.vendor_info }
      //   }
      // })

      // await modal.present()
    }


    async report(){
      // const modal = await this.modalCtrl.create({
      //   component: MessageInsertComponent,
      //   cssClass:'review-poup',
      //   componentProps:{
      //     info : { type : 'report' , business : this.vendor_info }
      //   }
      // })

      // await modal.present()
    }


    slidePrev() {
      // this.slides.slidePrev();
    }
  
    slideNext() {
      // this.slides.slideNext();
    }


}
