import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.page.html',
  styleUrls: ['./social-share.page.scss'],
})
export class SocialSharePage implements OnInit {
  share_url;
  // 
  name = 'Get Current Url Route Demo';
  currentRoute: any;

  @Input() item;
  // 
  constructor(public modalCtrl:ModalController,public db:DbService) {
    
      // this.share_url ="https://meatton-tridotstech.web.app"+location.pathname
      // this.share_url = window.location.href +'?refer='+localStorage.referral_code
      // console.log(location.pathname)
    this.get_share();


      
   }



   get_share(){
    // this.db.website_settings_getted.subscribe(res =>{
    //   if(res == 'done'){
        if(this.db.website_settings.enable_referral == 1){
          location.pathname == '/my-profile/referral' ? this.share_url = this.db.website_settings.app_settings.website_url +'/home?refer='+localStorage['referral_code'] : this.share_url = this.db.website_settings.app_settings.website_url + location.pathname +'?refer='+localStorage['referral_code'] 
        } else {
          this.share_url = window.location.href
        }
      // } 
  //   })
   }


  ngOnInit() {
    // console.log(this.router.url,"--");
    // console.log(window.location.href);
    // console.log("get path",this.getHostname())
  }

  dismiss(){
    this.modalCtrl.dismiss()
  }

  

}
