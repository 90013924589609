<app-order-status-notify *ngIf="db.path?.split('/')[1] == 'thankyou' || db.path?.split('/')[1] == 'thankyou-success'"
    [order_detail]="order_detail"></app-order-status-notify>

<div class="container order__info__container order__dtl__container" *ngIf="order_detail">

    <!-- <ion-refresher slot="fixed" pullFactor="0.5" pullMin="100" pullMax="200" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
    </ion-refresher> -->
    <div [class.profile]="profile" class="inner__container">
        <app-marquee-text [marqueeText]="db.website_settings && db.website_settings.market_place_settings && db.website_settings.market_place_settings.order_cancel_disclaimer ? db.website_settings.market_place_settings.order_cancel_disclaimer : ''"></app-marquee-text>
        <!-- order_title -->
        <div class="d__flex just-s myAccount-titles" [class.myAccount]="!button_hide">
            <h2 class="bold_name-15 m-0" *ngIf="!db.ismobile">Order Detail</h2>
            <div class="d__flex gap-5">
                <ion-button *ngIf="order_detail.status && (order_detail.status == 'Completed' || order_detail.status == 'Delivered') && !feedback" fill="clear" (click)="openReview(order_detail)">Feedback</ion-button>
                <ion-button *ngIf="order_detail.payment_method_name === 'Cash On Delivery' && order_detail.status && order_detail.status == 'Placed' && !button_hide" fill="clear" (click)="edit_order(order_detail)">Edit Order</ion-button>
                <div *ngIf="allow_download_invoice" (click)="download(order_detail)" class="download mouse">
                    <ion-icon name="download-outline"></ion-icon>
                </div>
            </div>
        </div>
        <!-- <div class="order-desc-row">
          <div class="d__flex py-15 bb just-s">
              <div > <span class="order-title">Order# </span> <br> <span >{{order_detail.name}}</span></div>
              <div > <span class="order-title">Total </span><span > {{order_detail.total_amount | currency:db.website_settings.currency}}</span></div>
          </div>
          <div class="d__flex py-15 bb just-s">
              <div > <span class="order-title">Order Placed </span> <br> <span >{{order_detail.order_date}}</span></div>
              <div > <span class="order-title">Payment Method </span> <br> <span>{{order_detail.payment_type}}</span> </div>
          </div>
      </div> -->

        <div class="d__flex top__container">
            <div class="top__child">
                <div class="order__info">
                    <div class="info__title">Shipping Method </div>
                    <div class="info__text">{{order_detail.shipping_method_name}}</div>
                </div>
                <div class="order__info">
                    <div class="info__title">Payment Method</div>
                    <div class="info__text">{{order_detail.payment_method_name}}</div>
                </div>
            </div>
            <div class="top__child">
                <div class="order__info">
                    <div class="info__title">Order Status</div>
                    <div class="info__text"><span class="dot" [class.success]="order_detail.status == 'Completed'"
                            [class.shipped]="order_detail.status == 'Shipped'" [class.cancelled]="order_detail.status == 'Cancelled'"
                            [class.placed]="order_detail.status == 'Placed'"></span>{{order_detail.status}}</div>
                </div>
                <div class="order__info">
                    <div class="info__title">Payment Status</div>
                    <div class="info__text"><span class="dot" [class.success]="order_detail.payment_status == 'Paid'"
                            [class.pending]="order_detail.payment_status == 'Pending'" [class.cancelled]="order_detail.payment_status == 'Cancelled'"></span>
                        {{order_detail.payment_status}}</div>
                </div>
            </div>
            <div class="top__child">
                <div *ngIf="order_detail.refund_status" class="order__info">
                    <div>
                        <div class="info__title">Refund Status <div class="dot_info"><app-tooltip [actionText]="'i'" [tooltipText]="'Refund amount will be credited in 7-10 working days.'"></app-tooltip></div></div>
                    </div>
                    <div class="info__text"><span class="dot" [class.success]="order_detail.refund_status == 'Processed'"
                        [class.cancelled]="order_detail.refund_status == 'Failed'"></span>{{order_detail.refund_status}}</div>
                </div>
                <div *ngIf="order_detail.total_refund_amount" class="order__info">
                    <div class="info__title">Refund Amount</div>
                    <div class="info__text fnt-13 open_sans__font">{{order_detail.total_refund_amount | currency:db.website_settings.currency}}</div>
                </div>
            </div>
            <div class="top__child">
                <div *ngIf="order_detail.transaction_id" class="order__info">
                    <div class="info__title">Transaction ID</div>
                    <div class="info__text">{{order_detail.transaction_id}}</div>
                </div>
                <div *ngIf="order_detail.custom_refund_date" class="order__info">
                    <div class="info__title">Refund Date</div>
                    <div class="info__text">{{order_detail.custom_refund_date}}</div>
                </div>
            </div>
            <div class="address">
                <h6 class="m-0 order__heading border_none">Delivery Address</h6>
                <div [ngClass]="db.ismobile ?'fnt-13':'fnt-15'"> <span class="order-title"> </span> <span class="address_txt">
                        {{order_detail.group_account_name}}</span></div>
                <div [ngClass]="db.ismobile ?'fnt-13':'fnt-15'"> <span class="order-title"> </span> <span class="address_txt">
                        {{order_detail.shipping_shipping_address}}
                        <br> {{order_detail.shipping_city}} - {{order_detail.shipping_zipcode}} <br>
                        <!-- {{order_detail.shipping_state}} {{order_detail.shipping_country}} -->
                    </span>
                    <span class="address_txt">{{order_detail.shipping_phone}}</span>
                </div>
            </div>
            <div *ngIf="false" class=" address">
                <h6 class="m-0 order__heading">BILLING ADDRESS</h6>
                <div [ngClass]="db.ismobile ?'fnt-13':'fnt-15'"> <span class="order-title"> </span> <span>
                        {{order_detail.first_name}} {{order_detail.last_name}}</span></div>
                <div [ngClass]="db.ismobile ?'fnt-13':'fnt-15'"> <span class="order-title"> </span> <span>
                        {{order_detail.address}}
                        <br> {{order_detail.city}} - {{order_detail.zipcode}} <br>
                        <!-- {{order_detail.state}} {{order_detail.country}} -->
                    </span>
                    <span>{{order_detail.phone}}</span>
                </div>
            </div>

        </div>


        <div class="item-desc order__items__web" *ngIf="!db.ismobile">
            <div class="d__flex just-s web__head">
                <div>
                    <h6 class="m-0 order__heading">Item Details</h6>
                    <p class="m-0 fnt-12">Ordered on {{order_detail.order_date | date:'MMM-dd,YYYY'}}
                        {{order_detail.order_time}}</p>
                </div>
                <div>
                    <!-- *ngIf="db.website_settings && db.website_settings.enable_returns_system == 1 &&( order_detail.status == 'Completed' || order_detail.pre_status == 'Completed')&& db.make_return"  -->
                    <ion-button
                        *ngIf="db.website_settings && db.website_settings.enable_returns_system == 1 &&( order_detail.status == 'Completed' || order_detail.pre_status == 'Completed')&& db.make_return"
                        fill="clear" (click)="make_return(order_detail.name)">Make Return</ion-button>
                    <ion-button *ngIf="db.website_settings && db.website_settings.enable_reorder == 1 && !button_hide" fill="clear"
                        (click)="reorder(order_detail.name)">Reorder</ion-button>
                    <ion-button *ngIf="db.website_settings.is_order_time_window_closed === 0 &&  allow_cancel_order && !button_hide" fill="clear" (click)="cancel_order(order_detail.name)">Cancel
                        Order</ion-button>
                    <!-- <ion-button fill="clear"  *ngIf="messages.length == 0" (click)="raiseQuestion()">Ask question</ion-button> -->
                </div>

            </div>
            <ion-row class="headings">
                <ion-col class="ion-text-center" size="6"> ITEM NAME </ion-col>
                <ion-col class="ion-text-center" size="2"> PRICE </ion-col>
                <ion-col class="ion-text-center" size="1"> QTY </ion-col>
                <ion-col class="ion-text-center" size="2"> MARKDOWN </ion-col>
                <ion-col class="ion-text-center" size="1"> TOTAL </ion-col>
            </ion-row>
            <ion-row
                *ngFor="let d of (order_detail.Items || order_detail.items || order_detail.order_item || order_detail.order_item)">
                <ion-col class="d__flex" size="6">
                    <div class="image__container">
                        <div class="free__item" *ngIf="d.is_free_item == 1">
                            <div class="free__item__text fnt-12">FREE</div>
                        </div>
                        <img [src]="db.product_imgs(d.item_image)">
                    </div>
                    <div>
                        <div class="medium_name">{{d.item_name}} </div>
                        <div *ngIf="d.attribute_description" class="attribute_d" [innerHTML]="d.attribute_description"></div>
                        <p class="m-0 sold_by_d" *ngIf="d.business_name">Sold By : <span class="sold_by">{{d.business_name}}</span></p>
                    </div>
                </ion-col>
                <ion-col size="2" class="d__flex medium_name ion-text-center open_sans__font"> {{d.price | currency:db.website_settings.currency}}
                </ion-col>
                <ion-col size="1" class="d__flex medium_name ion-text-center open_sans__font"> {{d.quantity}} </ion-col>
                <ion-col size="2" class="d__flex medium_name ion-text-center open_sans__font"> {{d.se_discount_amount | currency:db.website_settings.currency}}
                </ion-col>
                <ion-col size="1" class="d__flex medium_name ion-text-center open_sans__font"> {{d.amount | currency:db.website_settings.currency}}
                </ion-col>
            </ion-row>

            <div *ngIf="order_detail.delivery_slot" class="delivery__slot">
                <h6 class="fnt-14 mb-5 bold_name-15-500">Delivery slot</h6>
                <!-- {{order_detail.delivery_slot | json}} -->
                <div class="d__flex fnt-12 gap-10 medium_name gray_cr" *ngFor="let item of order_detail.delivery_slot">
                    <!-- | date :'MMM-dd, yyyy' -->
                    <div class="medium_name gray_cr">{{item.delivery_date }}</div>
                    <div class="medium_name gray_cr">{{item.from_time}}</div>
                    <div class="medium_name gray_cr" *ngIf="item.from_time != item.to_time"> - {{item.to_time }}</div>
                </div>
            </div>
        </div>


        <div *ngIf="db.ismobile" class="order__items p-10">
            <div class="d__flex just-s payment__info">
                <h6 class="m-0 order__heading">Item Details</h6>
                <ion-button
                    *ngIf="db.website_settings && db.website_settings.enable_returns_system == 1 &&( order_detail.status == 'Completed' || order_detail.pre_status == 'Completed')&& db.make_return"
                    fill="clear" (click)="make_return(order_detail.name)">Make Return</ion-button>
                <ion-button *ngIf="db.website_settings && db.website_settings.enable_reorder == 1 && !button_hide" fill="clear"
                    (click)="reorder(order_detail.name)">Reorder</ion-button>
                <!-- <ion-button  fill="clear" >Cancel Order</ion-button> -->
                <ion-button
                    *ngIf="(order_detail.status == 'Placed' || order_detail.status == 'Packed') && !button_hide && db.website_settings && db.website_settings.allow_cancel_order == 1 && db.website_settings.is_order_time_window_closed == 0"
                    fill="clear" (click)="cancel_order(order_detail.name)">Cancel Order</ion-button>
                <!-- <ion-button fill="clear"  *ngIf="messages.length == 0" (click)="raiseQuestion()">Ask question</ion-button> -->
            </div>
            <div *ngFor="let d of (order_detail.Items || order_detail.items || order_detail.order_item || order_detail.order_item)">
                <div class="product_d gap-10">
                    <div class="image__container__mb">
                        <div class="free__item" *ngIf="d.is_free_item == 1">
                            <div class="free__item__text">FREE</div>
                        </div>
                        <img alt="" [src]="db.product_imgs(d.item_image)">
                        <!-- <img *ngIf="!d.item_image" src="/assets/imgs/noimg.jpg"> -->
                    </div>
                    <div class="info__container_mb">
                        <div class="medium_name">{{d.item_name}} </div>
                        <div *ngIf="d.attribute_description" class="attribute_d" [innerHTML]="d.attribute_description"></div>
                        <p class="m-0 sold_by_d" *ngIf="d.business_name">Sold By : <span class="sold_by">{{d.business_name}}</span></p>
    
                        
    
                        <!-- <div class="fnt-13 mb-3">{{d.item_name}} </div>
                        <div [innerHTML]="d.attribute_description"></div> -->
    
    
                        <!-- <ion-col size="3" class="ion-text-center">  </ion-col>
                        <ion-col size="2" class="ion-text-center">  </ion-col>
                        <ion-col size="2" class="ion-text-center">   </ion-col> -->
                    </div>
                </div>
                <div class="info__price__container_mb gap-10">
                    <div class="price_mb">
                        <p class="m-0 fnt-9">Price</p>
                        <div class="fnt-13 open_sans__font">{{d.price | currency:db.website_settings.currency}}</div>
                    </div>
                    <div class="qty_mb">
                        <p class="m-0 fnt-9">Qty</p>
                        <div class="fnt-13">{{d.quantity}}</div>
                    </div>
                    <div class="total_mb">
                        <p class="m-0 fnt-9">Markdown</p>
                        <div class="fnt-13 open_sans__font">{{d.se_discount_amount | currency:db.website_settings.currency}}</div>
                    </div>
                    <div class="total_mb">
                        <p class="m-0 fnt-9">Amount</p>
                        <div class="fnt-13 open_sans__font">{{d.amount | currency:db.website_settings.currency}}</div>
                    </div>
                </div>     
            </div>
            <div *ngIf="order_detail.delivery_slot" class="delivery__slot">
                <h6 class="fnt-14 mb-5 medium_name">Delivery slot</h6>
                <!-- {{order_detail.delivery_slot | json}} -->
                <div class="d__flex fnt-12 gap-10" *ngFor="let item of order_detail.delivery_slot">
                    <div class="medium_name-13 gray_cr">{{item.delivery_date | date :'MMM-dd,yyyy'}}</div>
                    <div class="medium_name-13 gray_cr">{{item.from_time}}</div>
                    <div class="medium_name-13 gray_cr" *ngIf="item.from_time != item.to_time">{{item.to_time }}</div>
                </div>
            </div>
        </div>


        <div class="payment-desc">
            <div class=" d__flex just-s payment__info payment_de">
                <h6 class="m-0 order__heading">Payment Details</h6>
                <ion-button
                *ngIf="order_detail.outstanding_amount > 0 && order_detail.payment_method_name == 'UPI Payment' && order_detail.status == 'Placed'"
                    fill="clear" (click)="db.submit_payment_form(order_detail.name,order_detail.outstanding_amount,order_detail.customer_name,order_detail.custom_payment_retries)">Pay
                    Now</ion-button> 
                    <!-- (click)="db.load_razorpay(order_detail.outstanding_amount,'Order Repay')" -->
            </div>

            <div class="price_box">
                <div class="d__flex just-s py-5 order__info__item" *ngIf="order_detail.total_qty">
                    <p class="m-0 medium_name-14-400">Total Quantity</p>
                    <p class="m-0 medium_name-14-400">{{ order_detail.total_qty }}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item">
                    <p class="m-0 medium_name-14-400">Sub Total</p>
                    <p class="m-0 medium_name-14-400 open_sans__font">{{order_detail.order_subtotal | currency:db.website_settings.currency}}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item">
                    <p class="m-0 medium_name-14-400">Total Markdown Amount</p>
                    <p class="m-0 medium_name-14-400 open_sans__font">{{order_detail.toal_markdown_amount | currency:db.website_settings.currency}}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item">
                    <p class="m-0 medium_name-14-400">Delivery charges</p>
                    <p class="m-0 medium_name-14-400 open_sans__font">{{order_detail.shipping_charges | currency:db.website_settings.currency}}</p>
                </div>
                <div *ngIf="order_detail.discount != 0" class="d__flex just-s py-5 order__info__item">
                    <p class="m-0 medium_name-14-400">Discount</p>
                    <p class="m-0 medium_name-14-400 open_sans__font">{{order_detail.discount | currency:db.website_settings.currency}}</p>
                </div>
                <div *ngIf="order_detail.total_tax_amount" class="d__flex just-s py-5 order__info__item">
                    <!-- <p class="m-0">Tax</p> -->
                    <p class="m-0 medium_name-14-400">Tax {{(db.website_settings && db.website_settings.included_tax) ? '(Incl. Tax)' : ''}}</p>
                    <p class="m-0 medium_name-14-400 open_sans__font" *ngIf="tax && tax.length !=0">{{tax[0].amount | currency:db.website_settings.currency}}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item" *ngIf="order_detail.paid_using_wallet > 0">
                    <p class="medium_name-14-400 m-0">Paid Using Wallet</p>
                    <p class="medium_name-14-400 m-0 open_sans__font">{{order_detail.paid_using_wallet | currency:db.website_settings.currency}}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item" *ngIf="order_detail.redeem_loyalty_points == 1 ">
                    <p class="medium_name-14-400 m-0">Loyalty Amount ({{order_detail.loyalty_points}} pts)</p>
                    <p class="medium_name-14-400 m-0 open_sans__font">{{order_detail.loyalty_amount | currency:db.website_settings.currency}}</p>
                </div>
                <ng-container
                    *ngIf="order_detail.order_additional_charges && order_detail.order_additional_charges.length != 0">
                    <div class="d__flex just-s py-5 order__info__item"
                        *ngFor="let item of order_detail.order_additional_charges">
                        <p class="m-0 medium_name-14-400">{{item.charge_name}}</p>
                        <p class="m-0 medium_name-14-400 open_sans__font">{{item.amount | currency:db.website_settings.currency}}</p>
                    </div>
                </ng-container>
                <div class="d__flex just-s total order__info__item">
                    <p class="medium_name-14-400 m-0">Total</p>
                    <p class="medium_name-14-400 m-0 open_sans__font">{{order_detail.total_amount | currency:db.website_settings.currency}}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item">
                    <p class="medium_name-14-400 m-0">Paid Amount</p>
                    <p class="medium_name-14-400 m-0 open_sans__font">{{order_detail.paid_amount | currency:db.website_settings.currency}}</p>
                </div>
                <div class="d__flex just-s py-5 order__info__item" *ngIf="order_detail.outstanding_amount > 0">
                    <p class="medium_name-14-400 m-0">Outstanding amount</p>
                    <p class="medium_name-14-400 m-0 open_sans__font">{{order_detail.outstanding_amount | currency:db.website_settings.currency}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="raised__questions" *ngIf="order_detail && feedback">
    <div class="header_history">
        <h4 class="m-0 bold_name-15">Feedback</h4>
    </div>
    <div class="messages">
        <div class="mouse container_box">
            <div class="d__flex p-10">
                <span><img src="/assets/icon/user.svg" /></span>
                <span>
                    <h6 class="m-0 h2">{{feedback.comments}}</h6>
                    <p class="published m-0">{{feedback.posted_on | date}}</p>
                </span>
            </div>
        </div>
    </div>
</div>
<!-- && db.website_settings.account_settings && db.website_settings.account_settings.enable_message_forum == 1 -->
<div class="raised__questions"
    *ngIf="messages && order_detail && db.website_settings && false">
    <div class="header_history">
        <h4 class="m-0">History</h4>
        <div class="d__flex mouse comment_con">
            <img src="/assets/icon/chats.svg" />
            <p class="comment m-0"> {{messages.length==0? 'No Comments' : messages.length +'Comments'}} </p>
        </div>
    </div>
    <div class="messages">
        <div class="message_box ">
            <div class="d__flex text_area">
                <div class="p-10 user_logo">
                    <span><img src="/assets/icon/user.svg" /></span>
                </div>
                <div class="test_area">
                    <ion-textarea [(ngModel)]="reply_message" placeholder="Enter a message..."></ion-textarea>
                </div>
            </div>

            <div class="ion-text-right">
                <ion-button fill="clear" [disabled]="send_message" class="ion-text-capitalize"
                    (click)="post_ticket()">Send a message</ion-button>
            </div>

          
        </div>
        <div class="mouse container_box" *ngFor="let item of messages">
            <div class="d__flex p-10">
                <span><img src="/assets/icon/user.svg" /></span>
                <span>
                    <h6 class="m-0 h2">{{db.cust_name}}</h6>
                    <p class="published m-0">{{item.creation | date:'MMM dd,YYYY h:mm a'}}</p>
                </span>
            </div>
            <div class="conversation ">
                <div class="m-0 fnt-14" [innerHtml]="item.message"></div>
                <app-message-reply [page]="'order'" [text_align]="'left'" [reply_to]="item"></app-message-reply>
            </div>
        </div>
    </div>
</div>