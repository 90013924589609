import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() actionText: string = '';
  @Input() tooltipText: string = '';
  showTooltip: boolean = false;
  tooltipLeft: string = '50%';
  tooltipTop: string = '';

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }

}
