<app-header *ngIf="!modal_contrl" [no_home]="true" title="Address" [back_route]="db.ismobile?'/my-profile':'/'" [no_cart]="true" ></app-header>

<ion-header translucent="true" *ngIf="db.ismobile && modal_contrl">
    <ion-toolbar >
      <ion-back-button slot="start"  defaultHref="/yourcart" (click)="modalCtrl.dismiss()"></ion-back-button>
      <ion-title> Address</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content > <app-address [billing]="billing"></app-address></ion-content>
