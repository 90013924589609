<!-- <app-header title="Feedback" [no_home]="true" [no_cart]="true"></app-header> -->
<ion-content>
  <div class="close-button" >
    <ion-button fill="clear" (click)="modalCtrl.dismiss()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </div>
  <div class="container">
    <div class="border-b">
      <h6 class="title ">Feedback</h6>
    </div>
    <form [formGroup]="enquiryform">
      <div lines="none" class="m-7">
        <p class="input__title pb5"> Rating</p>
        <ionic5-star-rating #rating activeIcon="star" defaultIcon="star-outline" activeColor="#FF6E00" defaultColor="#aaaaaa" fontSize="32px" (ratingChanged)="logRatingChange($event)" formControlName="ratings"> </ionic5-star-rating>
      </div>
      <div *ngIf="submitted && !ratings?.valid ">
        <p color="danger" *ngIf=" ratings.errors.required"> Rating is required </p>
      </div>
      <div lines="none">
        <p class="input__title"> Message</p>
        <div class="custom-input">
          <ion-textarea placeholder="Enter the message" name="message" formControlName="message"></ion-textarea>
        </div>
      </div>
      <div *ngIf="submitted && !message?.valid ">
        <p color="danger" *ngIf=" message.errors.required"> Message is required </p>
      </div>
      
    </form>
    <ion-button class="save-button" fill="clear" (click)="submit()"> Submit</ion-button>
  </div>
</ion-content>
