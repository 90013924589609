import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { MakeReturnPage } from 'src/app/pages/make-return/make-return.page';
import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  ModalController,
  LoadingController,
  AnimationController
} from '@ionic/angular';

// import {
//   MessageInsertComponent,
// } from '../messages/message-insert/message-insert.component';
// import {
//   MessageReplyComponent,
// } from '../messages/message-reply/message-reply.component';
import {OrderFeedbackPage} from 'src/app/pages/order-feedback/order-feedback.page';
import { OrderReasonPage } from 'src/app/pages/order-reason/order-reason.page';

declare global {
  interface Window {
    Razorpay: any;
  }
}

@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class OrderdetailComponent implements OnInit {
  // [x: string]: any;
  @Input() order_detail;
  @Input() profile;
  @Input() button_hide;
  
  tax:any =[];
  reply_message;
  messages:any = [];
  order_id
  send_message = false;
  allow_cancel_order:boolean = false;
  feedback:any;
  allow_download_invoice:any;
  constructor(public db:DbService,public animationCtrl:AnimationController,private modalCtrl:ModalController,private router:Router,private route:ActivatedRoute,private alertCtrl:AlertController,public loadingCtrl: LoadingController) { 
    setTimeout(() => {window.dispatchEvent(new Event('resize')); }, 600);
  }

  ngOnInit() {

     setTimeout(() => {window.dispatchEvent(new Event('resize')); }, 600);
     this.db.get_paymentgateway_settings();
    //  this.db.get_razorpay_settings();
    //  console.log('11111111');

    //  this.route.params.subscribe((res:any) =>{
    //   // console.log(res);
    //   if(res && res.order_id){
    //       this.get_order_details(res.order_id);
    //   }
    // })
    // if(this.order_detail){
    //   this.tax && this.tax.length !=0 ?  JSON.parse(this.order_detail.tax_json) : '';
    //   console.log('tax',this.tax);
    //   this.db.website_settings && this.db.website_settings.enable_returns_system == 1 ? this.db.get_retuen_products(this.order_detail.name) :''
    //  }
        this.route.params.subscribe(async (res:any) =>{
        let loader = await this.loadingCtrl.create({ message: 'Please Wait...'});
        await loader.present();
        try {
          if(res && res.order_id){
            this.order_id = res.order_id
            setTimeout(() => { 
              this.get_order_details(res.order_id) 
              loader.dismiss() 
            }, 2000);
               
            this.db.website_settings && this.db.website_settings.enable_returns_system == 1 ? this.db.get_retuen_products(res.order_id) :''
            if(!this.db.website_settings.account_settings){
              this.db.website_settings_getted.subscribe(value =>{
              
                this.db.website_settings.enable_returns_system == 1 ? this.db.get_retuen_products(res.order_id) :null
              })
            }
          }
        } catch (error) {
          console.log("error in order details",error)
          loader.dismiss()
        }
      })
  
     
      
      // this.db.razorpay_payment.subscribe((res:any) =>{
    //     console.log(res);
    //     if(res['razorpay_payment_id'] && res['description'] == 'Insert Wallet Amount'){
    //       this.payment_capture(res['razorpay_payment_id'], res['amount'])
    // }
      //   if(res['razorpay_payment_id'] && res['description'] == 'Order Repay'){
      //         this.payment_capture(this.order_detail.name,res['razorpay_payment_id'])
      //   }
      // })

      this.db.get_cancel_reasons().subscribe(res => {
        if(res && res.message.status == 'Success')
          this.db.reason_list = res.message.data;
        })

      // this.db.repayment_success.subscribe(res =>{
      //   console.log(res)
      //   console.log(this.db.path)
      //   res == 'repaid' ?  this.get_order_details(this.order_id) : null
      // })
    }

  ngAfterViewInit(): void {
    setTimeout(() => {window.dispatchEvent(new Event('resize')); }, 600);
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
  }
  
  ionViewWillEnter(){
    this.route.params.subscribe((res:any) =>{
      // console.log(res);
      if(res && res.order_id){
          this.get_order_details(res.order_id);
      }else{
        //  this.href = this.router.url;
        //  console.log(this.router.url);
        //  let d = this.href.split('/');
        //  console.log('d',d)
        //  console.log('d',d[3])
        //  this.get_order_details(d[3]);
      }
    })
  }

    async reorder(order_id){
        let data  ={
          order_id : order_id,
          customer : localStorage['customerRefId']
        }
        let loader = await this.loadingCtrl.create({ message: 'Please Wait...' });
        await loader.present();
        this.db.reorder(data).subscribe((res:any) =>{
          // console.log(res);
          if(res.message && res.message.status =='Success'){
           res.message.message ? this.db.alert(res.message.message) : ''
          this.modalCtrl.dismiss();
          setTimeout(() => {
            loader.dismiss();
            this.db.ismobile?  this.router.navigateByUrl('/yourcart') : this.router.navigateByUrl('/my-profile/yourcart')
          },1000);
         }else{
            loader.dismiss();
            this.db.alert(res.message.message)
         }
         
        })
    }
  async cancel_order(order_id){
    const alert = await this.alertCtrl.create({
    header: 'Alert',
    message: 'Do you want to cancel this order?',
    buttons: [
      {
        text: 'Yes',
        handler: () => {
        // setTimeout(() => {
            this.openReason(order_id)
        // },1000);

          
      // let data  ={
      //   order : order_id
      // }
      // this.db.cancel_order(data).subscribe((res:any) =>{
      //   // console.log(res);
      //   if(res.message.status == "Success"){
      //     this.get_order_details(order_id);
      //     this.allow_cancel_order = false;
      //     !this.db.ismobile?  this.router.navigateByUrl('/my-profile/my-orders') : null;
      //     this.db.alert('Your order has been cancelled'); 
      //     // this.get_order_details(order_id);   
      //   }else
      //     res.message.message? this.db.alert(res.message.message) : this.db.alert("Failed, Please try again later");
      // })
          }
      },
      {
        text: 'No',
        handler: () => { 
          this.alertCtrl.dismiss() }
      }
    ]
    })
     await alert.present();
    }

    async openReason(values) {
      const modal = await this.modalCtrl.create({
        component: OrderReasonPage,
        cssClass: this.db.ismobile ? 'reason_popup' : 'order-reason-web',
        componentProps: {
          order_id: values,
        },

        enterAnimation: this.enterAnimation,
        leaveAnimation: this.leaveAnimation,
      })
      await modal.present();
      await modal.onWillDismiss().then((res:any) => {
        if(res && res.data.label){
          this.cancel_orders(res.data.value,values)
        }
      });
  }

  async cancel_orders(value,values){
    let loader = await this.loadingCtrl.create({ message: 'Please Wait...'});
    await loader.present();
    let data  ={
        order_id : values,
        cancel_reason:value
      }
    this.db.cancel_order(data).subscribe((res:any) =>{
        console.log(res);
        if(res.message.status == "Success"){
          this.get_order_details(values);
          loader.dismiss()
          this.db.alert('Your order has been cancelled');
          this.allow_cancel_order = false;
          if(this.db.order_items.length > 0){
            let check = this.db.order_items.findIndex(obj => obj.name == values)
            this.db.order_items[check].status = 'Cancelled';
            !this.db.ismobile?  this.router.navigateByUrl('/my-profile/my-orders') : this.router.navigateByUrl('/tabs/my-orders');
          }
           
        }else {
          loader.dismiss()
          res.message.message? this.db.alert(res.message.message) : this.db.alert("Failed, Please try again later");
        }
        //loader.dismiss()
      },error=>{  
        loader.dismiss()
        console.log("error while cancelling order", error)
        this.db.alert('Something went wrong try again later') 
      })
  }


  enterAnimation = (baseEl: HTMLElement) => { 
    var root:any = baseEl.shadowRoot;
    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        this.db.ismobile ? { offset: 0, opacity: '0.09', transform: 'translateY(100%)' } : { offset: 0, opacity: '0.09', transform: 'translateX(100%)' },
        this.db.ismobile ? { offset: 1, opacity: '1', transform: 'translateY(0)' } : { offset: 1, opacity: '1', transform: 'translateX(0)' },
      ]);
    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  leaveAnimation = (baseEl: HTMLElement) => {
    return this.enterAnimation(baseEl).direction('reverse');
  };
    
  
    formatAMPM(date) {
      // console.log(date)
      date = Number(date)
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
  
  
    get_order_details(data){
        this.db.get_order_info({order_id: data}).subscribe(res =>{
          // console.log(res);
          
          this.order_detail = res.message.info;
          this.allow_download_invoice = res.message.allow_download_invoice
          this.order_detail.delivery_slot = res.message.delivery_slot; 
          this.messages = res.message.messages;
          this.feedback = res.message.feedback;
          let total_qty = 0;
          this.order_detail.order_item.map(item =>{
            total_qty += item.quantity;
            this.order_detail.total_qty = total_qty;
          })
          console.log('qty',this.order_detail.total_qty)
          this.messages.map((r:any) =>{
            r.comments.reverse();
          })
          console.log("this.order_detail.status",this.order_detail.status)
          console.log("tallow_cancel_order",this.db.website_settings)
          if (this.db.website_settings.allow_cancel_order == 1){
            console.log("this.order_detail.status",this.order_detail.status)
          let expiry_date=new Date(this.order_detail.order_date);
          let days = this.db.website_settings.cancel_order_days ? this.db.website_settings.cancel_order_days : ["Placed", "Packed"].includes(this.order_detail.status) ? this.allow_cancel_order = true: this.allow_cancel_order =false;
          if(days){
            expiry_date.setDate(expiry_date.getDate() + days);
            if ((expiry_date>= new Date()) && ["Placed", "Packed"].includes(this.order_detail.status))
              this.allow_cancel_order = true;
            }
        }
          // if(this.order_detail){
          //   let a;
          //   let b;
          //  let tax_a = JSON.parse(this.order_detail.tax_json);
          //   tax_a.map(res =>{
          //     if(res.type == 'SGST'){
          //       a = res.amount;
          //     }else if(res.type == 'IGST'){
          //       b = res.amount;
          //     }
          //   })
          //   this.tax = a + b;
          // }
          if(this.order_detail && this.order_detail.tax_json){
            this.tax = JSON.parse(this.order_detail.tax_json);
            // console.log('tax',this.tax)
          }
          window.dispatchEvent(new Event('resize'))
          // if(this.db.website_settings && this.db.website_settings.enable_multi_vendor == 1){
          
          //   // this.available_delivery_date = [];
          //   // this.available_order_date = [];
    
          //   //  let date = formatDate(new Date(), 'dd-MM-yyyy', 'en');
    
          //   //  console.log(date);
          //   // (this.order_detail.Items || this.order_detail.order_item).map(r=>{
          //   //   this.db.website_settings.return_based_on == "Delivery Date" ? this.check_delivery_date(date,r.delivery_date) : this.checck_order_date(date,r.creation)
          //   // })
          // }
        })
    }
  
  
    
    async payment_capture(order_id,id) {
      // console.log("Payment id",id,order_id)
      // if (check_payment.payment_method == 'Razor Pay' ||  check_payment.payment_type == 'Online Payment') {
          var updatedate = {  'order_id': order_id,  'transaction_id': id  }
          this.db.update_order_status(updatedate).subscribe(r => {
              // console.log("Order Status Updated..",r);
              // this.db.path?.split('/')[1] == 'thankyou' ? this.repayment_success.next('repaid') : null
              if(r.message.status){  
                  this.get_order_details(this.order_id);
                  if(this.db.ismobile){
                      this.modalCtrl.dismiss('reload');
                    // this.router.navigateByUrl('/my-orders')
                    }else{
                      this.modalCtrl.dismiss();
                      this.db.ordered.next('ordered');
                      // this.router.navigateByUrl('/my-profile/my-orders');
                    }
              }
          }, error => { console.log(JSON.stringify(error.json())); });
  }
  
  
  
    async make_return(order_id){
      this.modalCtrl.dismiss();
      const modal = await this.modalCtrl.create({
        component: MakeReturnPage,
        cssClass:  'make-return-popup',
        componentProps: {  order_id : order_id }
      })
      this.db.modal = true;
      await modal.present();
      let data = await modal.onWillDismiss();
      if(data){
        this.db.modal = false;
      }
     }
    // doRefresh(event) {
    //   console.log('Begin async operation');
    //   this.get_order_list();
    //   setTimeout(() => {
    //     console.log('Async operation has ended');
    //     event.target.complete();
    //   }, 700);
    // }

    async raiseQuestion(){
      // const modal = await this.modalCtrl.create({
      //   component: MessageInsertComponent,
      //   cssClass:'review-poup',
      //   componentProps:{
      //     info : { type : 'Order' , order_id : this.order_detail.name  }
      //   }
      // })

      // await modal.present();
      // modal.onWillDismiss().then(res =>{
      //   res.data == 'success' ? this.ngOnInit() : null
      // })
    }

    async openReply(data){
      // const modal = await this.modalCtrl.create({
      //  component: MessageReplyComponent,
      //  cssClass : 'message-reply-popup',
      //  componentProps:{
      //    reply_to : data
      //  }
      // })
      // await modal.present();
      // modal.onWillDismiss().then(res =>{
      //   res.data == 'success' ? this.ngOnInit() : null
      // })
   }


   post_ticket(){
     // console.log()
     this.send_message = true;
     if(this.reply_message != '' && this.reply_message != undefined){
      let id = this.order_detail.name;
      let data={
       business: "",
       customer: localStorage['customerRefId'],
       message:this.reply_message,
       order_reference: id,
       raised_by: localStorage['CustomerName'],
       raised_customer_email: localStorage['CustomerId'],
       raised_customer_phone: localStorage['Customerphone'],
       raised_to: "",
       type: "Order",
      }
      this.db.add_ticket(data).subscribe(res =>{
        // console.log(res);
        if(res.data){
          this.db.alert("Your Question has been raised successfully...");
          this.send_message = false;
          this.get_order_details(this.order_detail.name);
          this.reply_message = '';
        }      
         
      })
     }else{
        this.send_message = false;
       this.db.alert('please enter a message');
     }   
  
  } 

  // edit_order(values){

  //   let data ={
  //     order_id: values.name,
  //     customer: localStorage['customerRefId'],
  //     set_cookie: 0
  //   }

  //   this.db.enable_edit_order(data).subscribe(res =>{
  //       console.log(res);
  //   })

  // }

  download(values){

    let data ={
      order_id: values.name,
    }

    this.db.get_order_invoices(data).subscribe(res =>{
        if(res && res.message && res.message.status == 'Success'){
          const link = document.createElement('a');
          link.target = '_blank';
          // link.href = this.db.baseUrl + res.message.url;
          res.message.url = res.message.url.replace(/'/g, '"');
          link.href = res.message.url
          // console.log(link.href)
          link.setAttribute('visibility', 'hidden');
          link.click();
        }else{
          this.db.alert(res.message.message)
          // this.db.alert('Something went wrong try again later')
        }
       },error=>{ this.db.alert('Something went wrong try again later') })

  }

  async edit_order(values) {
 
    let array = [...this.db.marketplace_items, ...this.db.fl_items]

    if(array.length != 0){
      const alert = await this.alertCtrl.create({
        header: 'Alert',
        message: 'Your cart contains items. Please remove cart items otherwise click yes to clear all cart items',
        buttons: [
          {
            text: 'No ',
            handler: () => { this.alertCtrl.dismiss() }
          },
          {
            text: 'Yes',
            handler: () => {
              this.edit_order_details(values)
            }
          }
        ]
      })
      await alert.present();
    }else{
      this.edit_order_details(values);
    }
    
  }

  async openReview(values) {
      const modal = await this.modalCtrl.create({
        component: OrderFeedbackPage,
        cssClass: 'feedback-poup',
        componentProps: {
          order_id: values.name,
        }
      })
      await modal.present();
      await modal.onWillDismiss().then((res:any) => {
           this.get_order_details(values.name);
      });
  }

  async edit_order_details(values){
    let loader = await this.loadingCtrl.create({ message: 'Please Wait...'});
    await loader.present();
      let data ={
        order_id: values.name,
      }
      this.db.enable_edit_order(data).subscribe(res =>{
          if(res && res.message && res.message.status && res.message.status == 'Success'){
            this.db.get_cart_item();
            localStorage['edit_order_id'] = values.name;
            this.db.edit_order_id = values.name;
            setTimeout(() => {
              loader.dismiss();
              this.db.ismobile ? this.router.navigateByUrl('tabs/yourcart') : this.router.navigateByUrl('/checkout');
            }, 1500);
            setTimeout(() =>{
              this.db.ismobile ? '': this.db.cart_popup('mycart')
            },2000)
          }else{
            loader.dismiss();
            this.db.alert( res.message.message ? res.message.message : 'Something went wrong try again later')
          }
      },error=>{  loader.dismiss(),this.db.alert('Something went wrong try again later') })
  }
}
  