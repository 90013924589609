import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import {
  MenuController,
  ModalController,
  NavController,
  AlertController
} from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit {
  @Input() back_route;
  @Input() clear_bg_a_border;
  @Input() no_home;
  @Input() no_cart;
  @Input() title;
  @Input() home;
  @Input() no_search_icon;
  @Input() tabs;
  @Input() cat_list;

  dropdown = false;
  store_name = 'Fl Store & Fl MarketPlace';

  // {name : 'Fl Store & Fl MarketPlace' }
  constructor(private router: Router,private alertCtrl: AlertController,public db:DbService,private modalCtrl:ModalController,public menuCtrl:MenuController,private navCtrl: NavController) { }

  ngOnInit() {
    // console.log(this.back_route)
  }

  openMenu() {
    this.menuCtrl.open();

  }

  backbtn(){
    this.back_route == '/' ? this.navCtrl.navigateRoot('/') : null
  }

  marketplace_check(){
    this.db.flMarket = true;
  }

  async moveallto_Cart() {
    let alert = await this.alertCtrl.create({
      header: "Alert",
      message: 'Are you sure to Move all to cart items?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => { }
        },
        {
          text: 'Ok',
          handler: () => {
            this.moveallto_Carts()
          }
        }
      ]
    })

    await alert.present();
  }

  moveallto_Carts() {
    let data = { user: localStorage['customerUser_id'], customer: localStorage['customerRefId'] }
    this.db.movealltocart(data).subscribe(res => {
      if (res.message.status == "Failed") 
        this.db.alert(res.message.message);
      else if (res.message.status == "Success"){ 
        this.db.get_cart_item();
        this.router.navigateByUrl('/tabs/yourcart');
      }
    }, error => { console.log(JSON.stringify(error.json())); })
  }


}
