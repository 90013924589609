<!-- view purticuler category
<pre>
    {{db.childs | json}}
</pre> -->
<!-- *ngIf="!category_data" mobile_cat-->
<ul *ngIf="!db.ismobile" [ngClass]="db.category && db.category.length != 0?'category-bar mobile_cat':''">
  <li class="main-category mobile_main" *ngFor="let item of db.category">
    <!-- {{db.childs.child_1}} -- {{item.route}} -->
    <div class="boder-b" *ngIf="(db.childs.child_1 == item.route && !ismobile) && !db.show_All_Category && item.products_count">
      <!-- && item.child.length == 0 -->
      <a *ngIf="" class="d__flex mouse child__1 gap-10 p-10 category-list" (click)="item.show_category =! item.show_category" [routerLink]="ismobile  ? '/shop/'+ item.route : null" (click)="db.childs.child_1 != item.route ? goto(item,'child-1'):''" routerDirection="forward" [class.active-parent]="item.route == db.childs.child_1">
        <div class="category__img" *ngIf="item.mobile_image && db.ismobile"> <img [src]="db.product_img(item.mobile_image)"></div>
        <ion-icon [name]="(db.childs.child_1 == item.route) && !item.show_category && item.child && item.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
        <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
        <p class="m-0 count-c" *ngIf="db.ismobile && item.products_count"><small>{{item.products_count}} Items</small></p>
      </a>
      <!---->
      <ul *ngIf="db.childs.child_1 == item.route && !ismobile && !item.show_category" class="child__2">
        <!-- {{item.child  | json}} -->
        <li *ngFor="let sub of item.child">
          <div *ngIf="sub.products_count > 0" class="child-2-li">
            <a (click)="goto(sub,'child-2')" [routerLink]="!ismobile?null:'/shop/'+sub.route" routerDirection="forward" routerDirection="forward" [class.active-child]="sub.route == db.childs.child_2">
              <div class="d__flex mouse p-5">
                <div class="category__img" *ngIf="sub.category_image && db.ismobile && false"> <img [src]="db.product_img(sub.category_image)"></div>
                <ion-icon (click)="sub.show_hide =! sub.show_hide" [name]="(db.childs.child_2 == sub.route)&& !sub.show_hide && sub.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
                <p (click)="sub.show_hide =! sub.show_hide" class="m-0 category__name_child2">{{sub.category_name}}</p>
                <!--  -->
              </div>
            </a>
          </div>
          <ul *ngIf="!sub.show_hide && !ismobile && db.childs.child_2 == sub.route" class="child__3">
            <li class="d__flex p-5" button *ngFor="let sub_child of sub.child">
              <div class="category__img" *ngIf="sub.sub_child && sub_child.products_count > 0"> <img [src]="db.product_img(sub.sub_child)"></div>
              <a *ngIf="sub_child.products_count > 0" (click)="goto(sub_child,'child-3')">
                <p class="m-0 mouse p-5-10 category__name" [ngClass]="(sub_child.route == db.childs.child_3)?'active-child':''"> - {{sub_child.category_name}}</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="categories_b" *ngIf="db.show_All_Category || ismobile && item.products_count" (click)="item.active_child1 = item.active_child1 ? false : true">
      <a *ngIf="item.products_count" class="mouse child__1 category-list" (click)="db.childs.child_1 != item.route ? goto(item,'child-1'):''" [routerLink]="ismobile  ? '/shop/'+item.route : null" routerDirection="forward" [class.active-parent]="item.route == db.childs.child_1">
        <!-- <div class="d__flex">
                <div class="category__img" *ngIf="db.ismobile"> <img [src]="item.mobile_image ? db.product_img(item.mobile_image) : '/assets/imgs/noimg.jpg'"></div>
                <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
               </div> -->
        <div class=" d__flex just-s p-10" (click)="item.active_child1 = item.active_child1 ? false : true">
          <div class="category__img d__flex" *ngIf="db.ismobile" (click)="item.active_child1 = item.active_child1 ? false : true">
            <img [src]="item.mobile_image ? db.product_img(item.mobile_image) : '/assets/imgs/noimg.jpg'">
            <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
          </div>
          <!-- <p class="m-0" *ngIf="db.ismobile"><small>{{item.products_count}} Items</small></p> -->
          <ion-icon (click)="item.show_category =! item.show_category" [name]="(db.childs.child_1 == item.route) && item.show_category && item.child && item.child.length != 0  ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
        </div>
      </a>
      <ul *ngIf="db.childs.child_1 == item.route && item.show_category && !ismobile" class="child__2-all">
        <li *ngFor="let sub of item.child" class="child-2-li">
          <!-- <div > -->
          <a (click)="goto(sub,'child-2')" [routerLink]="!ismobile?null:'/shop/'+sub.route" routerDirection="forward">
            <div class="d__flex mouse p-5" [class.active-child]="sub.category_name == db.childs.child_2_name">
              <div class="category__img" *ngIf="sub.category_image && db.ismobile && false"> <img [src]="db.product_img(sub.category_image)"></div>
              <ion-icon (click)="show_hide = !show_hide" [name]="(db.childs.child_2 == sub.route) && show_hide && sub.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
              <p class="m-0 category__name_child2">{{sub.category_name}}</p>
            </div>
          </a>
          <!-- </div> -->
          <!-- {{db.childs.child_2}}=={{sub.route}} -->
          <ul *ngIf="show_hide && !ismobile && db.childs.child_2 == sub.route" class="child__3">
            <li class="d__flex p-5" button *ngFor="let sub_child of sub.child">
              <div class="category__img" *ngIf="sub.sub_child "> <img [src]="db.product_img(sub.sub_child)"></div>
              <a (click)="goto(sub_child,'child-3')">
                <p class="m-0 mouse p-5-10 category__name" [ngClass]="(sub_child.name == db.childs.child_3)?'active-child':''"> - {{sub_child.category_name}}</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </li>
</ul>
<!-- <div *ngIf="category_data">
    <div class="fresh_offer p-10">
        <h4 class="m-0">Explore the</h4>
        <h3 class="m-0">Fresh offering</h3>
    </div>
    <ul class="d__flex p-10 flex-w gap-10 categories_data">
        <li *ngFor="let item of category_data.child" (click)="shop_product(item.route)" >
          <div class="mouse category-child">
            <div class="category__img"> <img [src]="item.category_image ? db.product_img(item.category_image):'/assets/imgs/noimg.jpg'"></div>
            <p class="m-0 category__name_child1 child_cat" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name | lowercase}}</p>
            <ion-icon *ngIf="item.sub_child && item.child.length != 0" name="caret-up"></ion-icon>
          </div>
        </li>
    </ul>
</div> -->
<!-- view all category -->
<!-- <ul [ngClass]="db.category.length != 0?'category-bar':''" *ngIf="false">
  <li class="main-category" *ngFor="let item of db.category">
    <div class="boder-b">
      <a class="d__flex mouse child__1 gap-10 category-list" (click)="item.show_category =! item.show_category" (click)="db.childs.child_1 != item.route ? goto(item,'child-1'):''" [routerLink]="ismobile  ? '/shop/'+item.route : null" routerDirection="forward" [class.active-parent]="item.route == db.childs.child_1">
        <div class="category__img" *ngIf="item.mobile_image && db.ismobile && false"> <img [src]="db.product_img(item.mobile_image)"></div>
        <ion-icon [name]="(db.childs.child_1 == item.route) && show_hide && item.child && item.child.length != 0  ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
        <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
        <p class="m-0 count-c" *ngIf="db.ismobile && item.products_count"><small>{{item.products_count}} Items</small></p>
      </a>
      <ul *ngIf="db.childs.child_1 == item.route && !ismobile && item.show_category" class="child__2">
        <li *ngFor="let sub of item.child">
          <div class="child-2-li">
            <a (click)="goto(sub,'child-2')" [routerLink]="!ismobile?null:'/shop/'+sub.route" routerDirection="forward" routerDirection="forward" [class.active-child]="sub.route == db.childs.child_2">
              <div class="d__flex mouse p-5">
                <div class="category__img" *ngIf="sub.category_image && db.ismobile && false"> <img [src]="db.product_img(sub.category_image)"></div>
                <ion-icon (click)="show_hide?show_hide= false:show_hide = true" [name]="(db.childs.child_2 == sub.route)&& show_hide && sub.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
                <p class="m-0 category__name_child2">{{sub.category_name}}</p>
              </div>
            </a>
          </div>
          <ul *ngIf="show_hide && !ismobile && db.childs.child_2 == sub.route" class="child__3">
            <li class="d__flex p-5" button *ngFor="let sub_child of sub.child">
              <div class="category__img" *ngIf="sub.sub_child "> <img [src]="db.product_img(sub.sub_child)"></div>
              <a (click)="goto(sub_child,'child-3')" [routerLink]="!ismobile?null:'/shop/'+sub_child.route">
                <p class="m-0 mouse p-5-10 category__name" [ngClass]="(sub_child.name == db.childs.child_3)?'active-child':''"> - {{sub_child.category_name}}</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </li>
</ul> -->
<!-- New -->
<ul *ngIf="db.ismobile" class="mob" [ngClass]="db.category && db.category.length != 0?'category-bar mobile_cat':''">
  <li class="main-category mobile_main" *ngFor="let item of db.category">
    <!-- {{db.childs.child_1}} -- {{item.route}} -->
    <div class="boder-b" *ngIf="(db.childs.child_1 == item.route && !ismobile) && !db.show_All_Category && item.products_count > 0">
      <!-- && item.child.length == 0 ! [routerLink]="ismobile  ? '/shop/'+ item.route : null" (click)="db.childs.child_1 != item.route ? goto(item,'child-1'):''"-->
      <a class="d__flex mouse child__1 gap-10 p-10 category-list" (click)="item.show_category =! item.show_category" routerDirection="forward" [class.active-parent]="item.route == db.childs.child_1">
        <div class="category__img" *ngIf="item.mobile_image && db.ismobile"> <img [src]="db.product_img(item.mobile_image)"></div>
        <ion-icon [name]="(db.childs.child_1 == item.route) && item.show_category && item.child && item.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
        <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
        <p class="m-0 count-c" *ngIf="db.ismobile && item.products_count"><small>{{item.products_count}} Items</small></p>
      </a>
      <!---->
      <ul *ngIf="item.active_child1 && item.child && item.child.length != 0" class="child__2">
        <li *ngFor="let sub of item.child">
          <div class="child-2-li">
            <a (click)="goto(sub,'child-2')" [routerLink]="!ismobile?null:'/shop/'+sub.route" routerDirection="forward" routerDirection="forward" [class.active-child]="sub.route == db.childs.child_2">
              <div class="d__flex mouse p-5">
                <div class="category__img" *ngIf="sub.category_image && db.ismobile && false"> <img [src]="db.product_img(sub.category_image)"></div>
                <ion-icon (click)="show_hide?show_hide= false:show_hide = true" [name]="(db.childs.child_2 == sub.route)&& show_hide && sub.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
                <p (click)="show_hide = !show_hide" class="m-0 category__name_child2">{{sub.category_name}}</p>
                <!--  -->
              </div>
            </a>
          </div>
          <!-- <ul *ngIf="show_hide && !ismobile && db.childs.child_2 == sub.route" class="child__3">
                        <li class="d__flex p-5" button *ngFor="let sub_child of sub.child">
                            <div class="category__img" *ngIf="sub.sub_child "> <img [src]="db.product_img(sub.sub_child)"></div>
                            <a (click)="goto(sub_child,'child-3')">
                                <p class="m-0 mouse p-5-10 category__name" [ngClass]="(sub_child.name == db.childs.child_3)?'active-child':''"> - {{sub_child.category_name}}</p>
                            </a>
                        </li>
                    </ul> -->
        </li>
      </ul>
    </div>
    <!--Start-->
    <div class="categories_b" *ngIf="ismobile && item.products_count">
      <!--[routerLink]="ismobile  ? '/shop/'+item.route : null" ! db.childs.child_1 != item.route ?-->
      <!--  (click)="item.active_child1 =! item.active_child1" (click)="goto(item,'child-1')" (click)="ismobile && item.child.length != 0 ? navigate(item) : null"  -->
      <a class="mouse child__1 category-list" (click)="navigate(item)" [routerLink]="ismobile && item.child.length == 0 ? '/shop/'+item.route : null" routerDirection="forward" [class.active-parent]="item.route == db.childs.child_1">
        <!-- <div class="d__flex">
                <div class="category__img" *ngIf="db.ismobile"> <img [src]="item.mobile_image ? db.product_img(item.mobile_image) : '/assets/imgs/noimg.jpg'"></div>
                <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
               </div> -->
        <div class=" d__flex just-s p-10">
          <div class="category__img d__flex" *ngIf="db.ismobile">
            <img [src]="item.mobile_image ? db.product_img(item.mobile_image) : '/assets/imgs/noimg.jpg'">
            <p class="m-0 category__name_child1" [class.active-category__name_child1]="item.route == db.childs.child_1">{{item.category_name}}</p>
          </div>
          <!-- <p class="m-0" *ngIf="db.ismobile"><small>{{item.products_count}} Items</small></p> -->
          <ion-icon [name]=" item.active_child1 && item.child && item.child.length != 0  ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
        </div>
      </a>
      <!--db.childs.child_1 == item.route-->
      <ul *ngIf=" item.child && item.child.length != 0 && item.active_child1" [class.max]="db.ismobile" class="child__2-all">
        <ion-item *ngFor="let sub of item.child" class="child-2-li">
          <!-- <div > -->
          <a (click)="goto(sub,'child-2')" [routerLink]="!ismobile?null:'/shop/'+sub.route" routerDirection="forward">
            <div class="d__flex mouse p-5" [class.active-child]="sub.category_name == db.childs.child_2_name">
              <p class="m-0 category__name_child2">{{sub.category_name}}</p>
              <ion-icon (click)="show_hide = !show_hide" [name]="(db.childs.child_2 == sub.route) && show_hide && sub.child.length != 0 ?'chevron-down-outline':'chevron-forward-outline'"></ion-icon>
            </div>
          </a>
          <!-- </div> -->
          <!-- {{db.childs.child_2}}=={{sub.route}} -->
          <!-- <ul *ngIf="show_hide && !ismobile && db.childs.child_2 == sub.route" class="child__3">
                        <li class="d__flex p-5" button *ngFor="let sub_child of sub.child">
                            <div class="category__img" *ngIf="sub.sub_child "> <img [src]="db.product_img(sub.sub_child)"></div>
                            <a (click)="goto(sub_child,'child-3')">
                                <p class="m-0 mouse p-5-10 category__name" [ngClass]="(sub_child.name == db.childs.child_3)?'active-child':''"> - {{sub_child.category_name}}</p>
                            </a>
                        </li>
                    </ul> -->
        </ion-item>
      </ul>
    </div>
  </li>
</ul>
