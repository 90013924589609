import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-mobile-header2',
  templateUrl: './mobile-header2.component.html',
  styleUrls: ['./mobile-header2.component.scss','./../mobile-header/mobile-header.component.scss'],
})
export class MobileHeader2Component implements OnInit {
  @Input() select_store:any;
  stores = [{ name : 'All Stores',route: '' },{ name : 'FL Store',route:'/p/fl-store-page' }, {name : 'FL MarketPlace', route:'/p/fl-marketplace'}]
  constructor(public db:DbService,public modalCtrl:ModalController,private router:Router) { }

  ngOnInit() {

  }

  store_select(item){
    this.db.store_name = item;
    localStorage['store_name'] = item;
    this.db.store_selection.next('store_selected')
    this.modalCtrl.dismiss();
  }

  goto_search(){
    this.router.navigate(["/search"]);
  }

}
