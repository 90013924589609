import { Location, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar } from '@capacitor/status-bar';
import {
  AlertController,
  AnimationController,
  MenuController,
  ModalController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { DbService } from './services/db.service';
import { Geolocation } from '@capacitor/geolocation';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  coordinates : any
  path;
  tabs = false;
  modal_popup = true;
  added_animate = false;
  alert_animate = false;
  add_animatings: any = [];
  alert_animatings: any = [];
  @ViewChild('location_popups') location_popups !:ElementRef;
  @ViewChild('overlay') overlay !:ElementRef;
  
  myCustomPageTransition = ((baseEl: any, opts?: any) => { 
    var anim1 = this.animationCtrl.create()
      .addElement(opts.leavingEl)
      .duration(600)
      .iterations(1)
      .easing('ease-out')
      .fromTo('opacity', '1', '0.0')
    var anim2 = this.animationCtrl.create()
      .addElement(opts.enteringEl)
      .duration(600)
      .iterations(1)
      .easing('ease-out')
      .fromTo('opacity', '0.0', '1')
     var anim2 = this.animationCtrl.create()
      .duration(600)
      .iterations(1)
      .addAnimation([anim1, anim2]);
    return anim2;
  });

  constructor(public db:DbService,public location:Location,private router:Router,private animationCtrl:AnimationController,private platform:Platform,private modalCtrl:ModalController,private popupCtrl:PopoverController,private menuCtrl:MenuController,private zone: NgZone,@Inject(PLATFORM_ID) private platformId,private alertCtl:AlertController,private appVersion: AppVersion) {
    this.db.location_popup = false;
    this.db.isBrowser = isPlatformBrowser(platformId);
    this.db.content_video = false;
  }

  
  ngOnInit() {
    this.checkLocationEnabled()
      this.db.wishcart = false;
      this.db.mycart = false;
      this.db.ismobile = this.db.checkmobile();
      localStorage['store_name'] ? this.db.store_name = localStorage['store_name'] : ''
      this.db.get_website_settings();
      // localStorage['store_name'] ? this.db.store_name = localStorage['store_name'] : ''
      !this.db.cust_email && !this.db.cust_name ? this.db.get_warehouse() : null;
      !localStorage['CenterName'] && this.db.ismobile ? this.router.navigateByUrl('/location') : null;
      if(localStorage['customerName_vendor']){
        this.db.vendor_storage();
      }

      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        if((localStorage['CenterName'] || localStorage['CustomerId'])){
          if(!this.db.edit_order_id){
            this.location.back();
          }
        }
      });

    if(this.db.ismobile){
      if(this.platform.is('android') && environment.production){
        this.get_app_version()
      }
      this.platform.ready().then(res =>{
        if((this.db.ismobile || res == 'ios' || res == 'ipad' || res == 'iphone' || res == 'mobile' || res == 'tablet') && res != 'dom'){
          this.db.android = true;
          this.get_oneSignal_id();
          StatusBar.setBackgroundColor({color:'#256b5a'});
          this.platform.backButton.subscribe(() => {
            const url = this.router.url;

            if(!this.db.edit_order_id){
              if(url == '/tabs/category' || url == '/tabs/wishlist' || url == '/tabs/my-profile'){
                this.router.navigateByUrl('/tabs/home')
              } else if (url == "/tabs/home") {
                    App.exitApp();
              }else if(this.db.ismobile && url.includes('order-detail/')){
                this.router.navigateByUrl('/tabs/my-orders');
              }
            }
         })
        } 
     })
    } 

      // this.db.log_rocket().subscribe(res => {
      //     res &&  res.enabled && environment.production ? LogRocket.init(res.id) : null
      // }, error => { console.log(JSON.stringify(error.json()));  });

      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.path = event.url;
          this.db.path = event.url;
          if (this.path =='/' || this.path == '/home' || this.path == '/category' || this.path == '/wishlist' || this.path == '/my-profile') {
            this.tabs = true;
            this.db.tabs_val = true;     
          }else{
            this.tabs = false;
            this.db.tabs_val = false;     
          } 
        }
      });


      this.db.store_cust_info()

      if(this.db.edit_order_id &&  (this.db.edit_order_id !== 'undefined' || this.db.edit_order_id !== undefined)){
        this.db.clearEditOrderCart()
      }

      if(localStorage['setlocation'] && localStorage['setlocation'] != undefined){
        this.db.store_info()
      }else{
         this.db.current_locate('');
      }
    
      this.db.added_animate.subscribe(res =>{
        this.added_animate = true;
        this.add_animatings.push(res);
        setTimeout( () => {  this.add_animatings.shift();},2000);
      })
    
    this.db.alert_animate.subscribe(res =>{
      this.alert_animate = true;
      this.alert_animatings.push(res) 
      setTimeout( () => {  this.alert_animatings.shift();}, this.db.ismobile? 700: 1500 );
    })

  }

  async checkLocationAccuracy() {
  // const status = await Geolocation.checkPermissions();
  // if (status.location === 'granted') {
  //   console.log(status)
  //   // Location permission is granted.
  //   // const result = await Geolocation.requestAccuracy();
  //   // if (result === 'OK') {
  //   //   // Location accuracy is set to high accuracy.
  //   // } else {
  //   //   // Location accuracy request failed.
  //   // }
  // } else {
  //   // Location permission is not granted.
  // }
    const status = await Geolocation.checkPermissions();
    console.log(status)
    if (status.location === 'granted') {
      // console.log(status)
      // // Location permission is granted.
      // const result = await Geolocation.requestPermissions({ enableHighAccuracy: true });
      // console.log(result)
      // if (result === 'OK') {
      //   // Location accuracy is set to high accuracy.
      // } else {
      //   // Location accuracy request failed.
      // }
    } else {
      // Location permission is not granted.
    }
}

    get_oneSignal_id(){
      this.db.get_oneSignal_id().subscribe(res =>{
        res.is_key && res.is_key != '' ? this.db.OneSignalInit(res.key) : '';
      },error => { console.log(JSON.stringify(error.json()));})
    }
    

    scrolled(event){
      if(event.scrollTop){
      }
    }

    get_app_version(){
      this.db.get_app_version().subscribe(res =>{
        if(res && res.version){
          this.appVersion.getVersionNumber().then(value =>{
            this.db.app_version = value;
            let new_version  = value
            let force = res && res.force ? res.force : false
            new_version != res.version && res.show_popup ? this.update_notify(res.version,new_version,force) : null
          })
  
        }
      })
    }
  
     async update_notify(version,new_version,force){
        const alert = await this.alertCtl.create({
          header : 'Update Available',
          message :'A new version(' + version  + ') of this app is available for download. Must update it from PlayStore !',
          subHeader: 'current version : ' + new_version,
          buttons : [
            {
              text : 'cancel',
              handler: () => {
                force ? App.exitApp() : null
              }
            },
            {
              text:'Update',
              handler : () =>{
                window.open("https://play.google.com/store/apps/details?id="+environment.app_config.playstore_id, '_system');
                App.exitApp()
              }
            }
          ]
        })  
  
        await alert.present();
      }
  
    ionViewWillEnter() {
    }  

    menuclose() {   
      this.db.slider_button = true; 
      this.db.wishcart = false;
      this.db.mycart = false;
    }
    
    enable_mobile_category(){
      this.db.firebase_watchig_mobile_Product_List().subscribe((res) => {
           if(res == 'show'){
                this.db.show_parent_and_Category = true
            }else{
                this.db.show_parent_and_Category = false
            }
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if( !this.db.ismobile && this.overlay && this.overlay.nativeElement.contains(event.target) && this.db.location_popup && !this.location_popups.nativeElement.contains(event.target) ){
            !this.db.location_error && this.db.location ? this.db.location_popup = false : this.shake()  
       }  
    } 

    shake(){
      if(this.db.location_error || this.db.location_info.permission_error ){
        this.location_popups.nativeElement.classList.add('shaking')
        setTimeout(() =>{ this.location_popups.nativeElement.classList.remove('shaking') },700)
      }
    }

    async checkLocationEnabled() {
      try {
        this.coordinates = await Geolocation.getCurrentPosition();
        console.log('Current position:', this.coordinates);
      } catch (error) {
        console.error('Error getting location:', error);
        this.enable_location()
         // You can pass options if necessary
      }
    }
  
    async enable_location() {
      const alert = await this.alertCtl.create({
        cssClass: 'my-custom-alert-class',
        header: "Enable Location",
        message: 'For a better experience, turn on device location, Which uses Google Location Service',
        buttons: [{
          text: 'No',
          handler: () => {
            this.alertCtl.dismiss();
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.db.get_warehouse()
            // NativeSettings.open({
            //   optionAndroid: AndroidSettings.Location, 
            //   optionIOS: IOSSettings.LocationServices
            // });
          }
        }]
      });
      await alert.present();
    }
}


