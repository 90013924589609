import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  Input,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {

  drop_down2 = false;
  @Output() cart_pop = new EventEmitter();
  @Input() cat_products;
  @ViewChild('addresstext') addresstext!: ElementRef;
  @ViewChild('dropdown2', { read :ElementRef}) dropdown2 !: ElementRef;
  @Input() default_header;
  pageloaded = false;
  constructor(public db:DbService,public zone: NgZone,public router:Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.pageloaded = true;
    }, 1500);
  }

  hide_dropdown2(event) {
    const xTouch = event.clientX;
    const yTouch = event.clientY;
    const rect = this.dropdown2.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;
    if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
      this.drop_down2 = false;
    }
  }

  openLogin(){
    if(!(this.db.cust_name || this.db.guest_cust_name))
      this.router.navigate(['/login'])
  }

  redirect(){
    this.router.navigateByUrl('/');
  }

  _menu = [
    {
      title: 'My Profile',
      route: '/my-profile/dashboard'
    },
    {
      title: 'My Orders',
      route: '/my-profile/my-orders'
    },
  ]

}
