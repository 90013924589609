import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss'],
})
export class CartButtonComponent implements OnInit {
  @Input() product_info;
  @Input() big_button;
  @Input() change_button_color;
  @Output() add_wish = new EventEmitter();
  @Output() remove_wish = new EventEmitter();
  @Output() addto_cart = new EventEmitter();
  @Output() removeto_cart = new EventEmitter();
  @Output() vendor_price_event = new EventEmitter();
  constructor(public db:DbService) { }

  ngOnInit() {
  }

}
