import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { DbService } from 'src/app/services/db.service';

import {
  LoadingController,
  ModalController,
} from '@ionic/angular';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss'],
})
export class VideoPopupComponent implements OnInit {
  // id = "pjAPUWV1ds8"
  @Input() youtube_id: any;
  constructor(public db:DbService,public sanitizer: DomSanitizer,private modalCtrl: ModalController, public loadingController: LoadingController) { }

  ngOnInit() {
    this.presentLoading();
  }


  sanitize_popup(data){
    // console.log("Data",data)
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+ data +"?autoplay=1&;rel=0&amp;controls=0&amp&amp;showinfo=0&amp;modestbranding=1;");
  }

  close_popup(){
    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'loading...',
      duration: 800
    });
    await loading.present();

    // this.youtube_id = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

}
