import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})


export class ProfileComponent implements OnInit {
  userinfo: any = { };
  profile_form:any = FormGroup;
  cpassword;
  customer_info:any = {};
  focus :any = {};
  submitted = false;
  constructor(private formBuilder:FormBuilder,public db:DbService) { }

  ngOnInit() {
    this.db.acctount_setting_info(localStorage['customerRefId']).subscribe(r=>{
      this.customer_info = r.data;
      this.db.account_settings.pan =  r.data.pan_card_no
      this.db.account_settings.adhaar_card_no =  r.data.adhaar_card_no
      this.db.account_settings.gapy_id =  r.data.gapy_id
      this.db.account_settings.paytm_id =  r.data.paytm_id

      this.userinfo.first_name = (this.customer_info && this.customer_info.full_name) ? this.customer_info.full_name : localStorage['Customerfirst_name']
      this.userinfo.last_name = (this.customer_info && this.customer_info.last_name) ? this.customer_info.last_name : localStorage['Customerlast_name']
      this.userinfo.email = (this.customer_info && this.customer_info.email) ? this.customer_info.email : localStorage['CustomerId']
      this.userinfo.phone = (this.customer_info && this.customer_info.phone) ? this.customer_info.phone : localStorage['Customerphone']
      
      if(this.customer_info){
        this.userinfo.business_address = (this.customer_info.business_address ?  (this.customer_info.business_address + ', ') : '') + (this.customer_info.business_city ?  (this.customer_info.business_city + ', ') : '') + (this.customer_info.business_state ?  (this.customer_info.business_state + ', ') : '') + (this.customer_info.business_country ?  (this.customer_info.business_country + '- ') : '') + (this.customer_info.business_zip ?  (this.customer_info.business_zip) : '')
        console.log(this.userinfo.business_address);
      }

      
    });

    this.profile_form = this.formBuilder.group({ 
      first_name: new FormControl((this.userinfo.first_name || ''), Validators.required),
      last_name: new FormControl((this.userinfo.last_name || '')),
      email: new FormControl((this.userinfo.email || ''),[ Validators.required,Validators.email]),
      phone: new FormControl((this.userinfo.phone || ''),[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      business_name: new FormControl((this.customer_info.business_name || ''), Validators.required),
      store_name: new FormControl((this.customer_info.store_name || ''), Validators.required),
      business_phone: new FormControl((this.customer_info.business_phone || ''),[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      business_address: new FormControl((this.userinfo.business_address || ''), Validators.required),
      password: new FormControl(('') ),
      cpassword: new FormControl(('')),
      pan_card_no: new FormControl(('')),
      adhaar_card_no: new FormControl(('')),
      gapy_id: new FormControl(('')),
      paytm_id: new FormControl((''))
    });
  }

  get first_name(){
    return this.profile_form.get('first_name');
  } 

  get store_name(){
    return this.profile_form.get('store_name');
  } 

  get business_address(){
    return this.profile_form.get('business_address');
  } 


  get business_name(){
    return this.profile_form.get('business_name');
  } 

  get last_name() {
    return this.profile_form.get('last_name');
  }

  get email() {
    return this.profile_form.get('email');
  }
  
  get phone() {
    return this.profile_form.get('phone');
  } 

  get business_phone() {
    return this.profile_form.get('business_phone');
  } 

  get password() {
    return this.profile_form.get('password');
  } 

  get pan_card_no() {
    return this.profile_form.get('pan_card_no');
  } 

  get adhaar_card_no() {
    return this.profile_form.get('adhaar_card_no');
  } 

  get c_password() {
    return this.profile_form.get('cpassword');
  } 
  

  get gapy_id() {
    return this.profile_form.get('gapy_id');
  } 

  get paytm_id() {
    return this.profile_form.get('paytm_id');
  } 

  update() {
    this.submitted = true;
    // if (this.profile_form.value.password == this.profile_form.value.cpassword ) {
    // } else {
    //   this.db.alert("Wrong Password..!")
    //  }
        if(this.profile_form.status == 'VALID') {
          this.userinfo = this.profile_form.value;
          this.userinfo.name = localStorage['customerRefId'];
          this.userinfo.doctype = 'Customers';
          delete this.userinfo['cpassword'];
          this.userinfo.domain = this.db.domain;
          this.userinfo.doctype = 'Customers';
          // console.log(this.userinfo);
    
          this.db.update_user(this.userinfo,localStorage['customerRefId']).subscribe((res: any) => {
            // console.log(res)
            if(res.message && !res.exc_type){
              localStorage['CustomerName'] = res.message.first_name + ' ' + res.message.last_name;
              localStorage['Customeremaul'] = res.message.email;
              this.db.cust_email = res.message.email;
              this.db.cust_name = res.message.first_name + ' ' + res.message.last_name;
              localStorage['Customerphone'] = res.message.phone;
              localStorage['Customerfirst_name'] = res.message.first_name
              localStorage['Customerlast_name'] = res.message.last_name
              localStorage['customerRefId'] = res.message.name
              this.db.alert("Profile Updated successfully..")
            } else {
              this.userinfo.phone = localStorage['Customerphone']
              var b = JSON.parse(res._server_messages);
              var b1 = JSON.parse(b);
              this.db.alert(b1.message)
            }
          }, error => {  console.log(error);})
        } else {
          // this.db.alert("Please Fill the Inputs..!")
        }
    
  }




}
