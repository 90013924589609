import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  HostListener,
  NgZone
} from '@angular/core';
import { Router } from '@angular/router';

import { RegisterPage } from 'src/app/pages/register/register.page';
import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
} from '@ionic/angular';

import { DeliverySlotComponent } from '../delivery-slot/delivery-slot.component';

@Component({
  selector: 'app-your-cart',
  templateUrl: './your-cart.component.html',
  styleUrls: ['./your-cart.component.scss','../cart-button/cart-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class YourCartComponent implements OnInit {
  @Input() load;
  @Input() margin_bottom;
  @Input() no_title;
  @Input() no_footer;
  @Input() summary_bill;
  @Input() yorcart_wish_web;
  @Input() yorcart_wish_webs;

  fl_items_show_more:any = false;
  marketplace_items_show_more:any = false;
  url:any;
  is_empty = false;
  constructor(public Ngzone : NgZone, public db: DbService,private menuCtrl:MenuController,private loadingCtrl:LoadingController,private router:Router,private modalCtrl:ModalController,private alertCtrl:AlertController) {
    this.db.ismobile = this.db.checkmobile();
    localStorage['guest_CustomerName'] ? this.db.guest_cust_name = localStorage['guest_CustomerName'] : null
    if(this.db.ismobile && (this.db.cust_name || this.db.guest_cust_name)){
      this.db.getShippingMethods();
    }
    this.db.cancel_promo();
    var cur_url = window.location.href.split('/');
    this.url = cur_url[cur_url.length - 1]
  }

  ngOnInit() {
    this.db.selected_address = localStorage['Address'] === "undefined" ? '' : JSON.parse(localStorage['Address']);
    this.db.mycart_emit.subscribe(res => {
      res == 'getted' ? this.get_values() : null 
    })
    this.db.shipping_settings.shipping_charges = 0
    this.db.shipping_settings.selected_shipping_method = undefined
    this.db.shipping_settings.selected_shipping_method_name = undefined
    this.db.get_cart_item();
    if( this.db.shipping_methods && this.db.shipping_methods && this.db.shipping_methods.length > 0){
      setTimeout(() => { this.db.select_shipping_method(this.db.shipping_methods[0].name) }, 2000);
    }

    if(localStorage['Business_address']){
      this.db.business_address = localStorage['Business_address']  ? JSON.parse(localStorage['Business_address']) : '';
    }
    else
      this.get_addr() 
   }

   get_addr(){
     this.db.customer_Info({email:localStorage['email']}).subscribe(res => {
      if (res.message) {
          var business_addr_info:any ={};
          business_addr_info.business_name = res.message[0].business_name
          business_addr_info.business_phone = res.message[0].business_phone
          business_addr_info.business_address = res.message[0].business_address
          business_addr_info.business_landmark = res.message[0].business_landmark
          business_addr_info.business_city = res.message[0].business_city
          business_addr_info.business_state = res.message[0].business_state
          business_addr_info.business_zip = res.message[0].business_zip
          business_addr_info.business_country = res.message[0].business_country
          localStorage['Business_address'] = JSON.stringify(business_addr_info);
          this.db.business_address = localStorage['Business_address']  ? JSON.parse(localStorage['Business_address']) : '';
      } 
    },error => { this.db.alert('Check E-mail ID / Password'); console.error(error)})
   }
  close_menu() {
    this.menuCtrl.close();
  }

  get_values(){
    // this.db.get_order_discounts();    
    this.Ngzone.run(()=>{
      this.db.fl_items = this.db.fl_items;
    })

    this.func()
  }

  async addtocart(list) {
    list.temp_disabled = true
      if(list && !list.no_stock){
        var data = {
        "ProductId": list.product,
        "attributeId":  list.attribute_ids,
        "qty": this.db.plusCount(list.quantity),
        business:list.business
        }
        this.db.validate_product_cart_qty(data).subscribe(data => {
          if(data.message && data.message.status == 'Success'){
            this.update(list);
          } else {
            data.message.message ? this.db.alert(data.message.message): null;
            list.temp_disabled = false;
            // this.db.marketplace_items.map(res =>{
            // if(res.product == list.product){
            //   res.no_stock = true
            //   list.temp_disabled = false;
            // }
            // })
          }
      })
    }
  }

  update(list){
    var datas = {  name: list.name, qty: this.db.plusCount(list.quantity),  qty_type:"",business:list.business }
    this.db.update_cart_items(datas).subscribe(data => {
      list.temp_disabled = false;
      if(data.message.status == 'Failed'){
        this.db.alert(data.message.message)
        // var d = JSON.parse(data._server_messages);
        // var d1 = JSON.parse(d);
        // this.db.alert(d1.message)
      } else{
        this.db.get_cart_item();
        this.db.wallet_settings_getted.next('done');
      }
    }, error => { console.log(JSON.stringify(error.json())); });
  }

  @HostListener('window:resize', ['$event'])
  private func(){
    this.db.ismobile  = this.db.checkmobile();
  }

  async remove(list,type) {
      const alert = await this.alertCtrl.create({
        subHeader:type == 'remove'?'Are you sure want to remove the product?':'Are you sure want to delete the product?',
        buttons:[
          {
            text:'Cancel',
          },
          {
            text:'Ok',
            handler: () => {
              if(type == 'remove'){
                this.remove_cart(list)
              }
              else if(type == 'delete'){
                //  this.db.fl_items = [];
                 this.delete_cart(list);
              }
                
            }
          }
        ]
      })
      if(type == 'remove' ){
        this.remove_cart(list)
      } else {
        await alert.present();
      }

  }

  

  remove_cart(list){
    list.temp_disabled = true;
    var datas;
    if(list.minimum_order_qty && list.minimum_order_qty == list.quantity)
       datas={  name: list.name, qty: 0, qty_type:"" }
    else
       datas={  name: list.name, qty: this.db.removeCount(list.quantity), qty_type:"" }
    if(datas.qty == 0){
      this.remove(list,'delete')
      list.temp_disabled = false;
    }else{
      this.db.update_cart_items(datas).subscribe(data => {
        list.temp_disabled = false;
        if(data.message.status == 'Failed')
          this.db.alert(data.message.message)
        else{
          this.db.wallet_settings_getted.next('done');
          this.db.get_cart_item();
        }
      }, error => {console.log(JSON.stringify(error.json())); });
   }
  }

 async delete_cart(list) {
    var datass={  name : list.name,  customer: localStorage['customerRefId'], user:"" }
    this.db.delete_cart_items(datass).subscribe(data => {
       if(data._server_messages){
         var b = JSON.parse(data._server_messages);
         var b1 = JSON.parse(b);
         this.db.alert(b1.message)
       }
      //  this.db.sub.next('cart emitted');
      this.db.get_cart_item();
    }, error => {
      console.log(JSON.stringify(error.json()));
    });
 }

  async check_stocks(){
   if(this.db.website_settings.enable_guest_checkout == 1){
      // if(!this.db.billing_address  || this.db.billing_address == ''){
      //   this.db.alert("Please select Billing address..")
      // } 
      // else{
        this.router.navigateByUrl("/checkout");
      // }
   } else{
    let data = { customer_id : localStorage['customerRefId'] }
    this.db.check_cartitems_stock_mob(data).subscribe(res =>{
            if(res.message.status){
              if(!this.db.cust_name){
                     this.open_modal();
              }else{
                this.db.wallet_settings_getted.next('done');
                // this.db.delivery_slots = [];
                // this.db.selected_slot = [];
                // this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method ? this.db.shipping_settings.selected_shipping_method = undefined : null
                // this.db.getShippingMethods();
                this.router.navigateByUrl('/checkout');
              }
            } else 
              this.db.alert(res.message.message);
        })
   }
  }


    async open_modal(){
          const modal = await this.modalCtrl.create({
            component: RegisterPage,
            cssClass:'auth-popup',
            componentProps: {
              type: 'login'
            }
        })
        this.db.modal = true
        await modal.present();
        await modal.onWillDismiss().then( res =>{
          this.db.modal_popup = true;
          this.db.opened_login_modal = false;
          this.db.modal = false;
          this.router.navigateByUrl('/checkout')
        });
    }

   ionViewWillDismiss(){
      this.db.selected_slot = [];
   }

   async open_delivery_slot(){
       const modal  = await this.modalCtrl.create({
         component:DeliverySlotComponent,
         cssClass:'actionsheet-popup',
       })
       await modal.present();
       let data = await modal.onWillDismiss();
       if(data){  
          if(this.db.delivery_slots.length != 0){
            this.db.delivery_slots.map(value =>{
              if(value.selected_time_slots){
                let d = {
                  category : value.category,
                  date : value.selected_time_slots.date,
                  from_time : value.selected_time_slots.selected_time.from_time,
                  to_time :  value.selected_time_slots.selected_time.to_time,
                }
                this.db.selected_slot_label.date =  value.selected_time_slots.format_date
                this.db.selected_slot_label.time =  value.selected_time_slots.selected_time.time_format
                this.db.selected_slot.push(d);
              }
            })
          }
       }
   }  

   check_methods(){
      let temp = false;
      if(this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method_name){
          if(this.db.delivery_slots.length != 0){
            this.db.selected_slot.length != 0 && this.db.selected_address && this.db.selected_address.name ? temp = true : null
          } else {
            return temp = true;
          }
      }
      return temp;
   }

   go_login(){
      this.db.website_settings.enable_guest_checkout == 1 ?  this.db.checkout_openLogin('guest') : this.db.openLogin();
   }

}
