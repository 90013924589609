import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.page.html',
  styleUrls: ['./address-list.page.scss'],
})
export class AddressListPage implements OnInit {
  @Input() modal;
  @Input() billing;
  @Input() modal_contrl;

  constructor(public modalCtrl: ModalController,public alertCtrl: AlertController,public db:DbService,public loadingCtrl: LoadingController) { 

  }

  ngOnInit() {
    // this.get_address()      
  }

}
