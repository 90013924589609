
<ion-content>
  <div class="category-head">
    <ion-text>Filters</ion-text>
    <ion-icon (click)="modalCtrl.dismiss('success')" name="close-outline"></ion-icon>
  </div>
  
  <app-filters [brands]="brands"></app-filters>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="modalCtrl.dismiss('success')">
      OK
    </ion-fab-button>
  </ion-fab>
</ion-content>
