  <!-- Not a table format -->
  <div *ngIf="product_detail.show_price != 0">
    <div *ngFor="let item of product_detail.product_attributes">
      <h6 class="attribute__name">{{item.attribute}}</h6>

      <!-- drop down list -->
      <div #attribute_dropdown *ngIf="item.control_type == 'Dropdown List'" class="attribute__drop__down">          

      <!-- Customize drop down  list -->
        <div class="d__flex selected__drop__down mouse" *ngIf="false">
          <div class="selected__txt" (click)="item.drop_down = 1" ><div> {{db.check_attribute_label(item.options)}} </div> <ion-icon name="chevron-down-outline" class="fnt-12"></ion-icon></div>
          <div *ngIf=" item.drop_down == 1" class="drop__down__options" (mouseleave)="item.drop_down = 0">
            <div [class.active__drop__option]="option.is_pre_selected && option.is_pre_selected == 1" class="drop__down__option mouse"  *ngFor="let option of item.options" (click)="select(item.attribute,option.name)"  (click)="item.drop_down = 0">
              {{option.option_value}}
              <span >{{option.iscart}}</span> 
            </div>
          </div>
        </div>

        <ion-select placeholder="Select" interface="popover" [value]="check_active_radio(item.options)" class="drop__down__options" (mouseleave)="item.drop_down = 0" (ionChange)="select_dropdown($event,item.attribute)" >
          <ion-select-option   *ngFor="let option of item.options" [value]="option.name">{{option.option_value}}  </ion-select-option>
        </ion-select>
      </div>

      <!-- Radio button list -->
      <div class="radio_div" #attibute_dropdown *ngIf="item.control_type == 'Radio Button List'">
        
          <!-- Customize radio button -->
          <div class="d__flex flex-w" *ngIf="false">
            <div *ngFor="let option of item.options" class="radio__attribute__option  d__flex mouse" [class.active__radio__option]="option.is_pre_selected && option.is_pre_selected == 1" (click)="select(item.attribute,option.name)" >
              <div class="main-dot d__flex just-c"><div class="inner-dot d__flex"> </div> </div>
              
              <div>{{option.option_value}}</div>
              <div class="attribute-cart" *ngIf="option.iscart != 0 && false" > <ion-icon *ngIf="false" src="/assets/icon/unique-cart.svg"></ion-icon> <ion-badge >{{option.iscart}}</ion-badge></div>
            </div>
          </div>

          <ion-radio-group class="d__flex flex-w gap-10" [value]="check_active_radio(item.options)" (ionChange)="select_radio($event,item.attribute)" >
            <ion-item lines="none" *ngFor="let option of item.options" class="radio__attribute__option mouse gap-10" [class.active__radio__option]="option.is_pre_selected && option.is_pre_selected == 1" [class.image-option]="option.images && option.images.length != 0" >
              <ion-radio slot="start" [value]="option.name" [class.image-radio]="option.images && option.images.length != 0"></ion-radio>
              <div >
                <img *ngIf="option.images && option.images.length != 0"  [src]="check_active_image(option.images)">
                <ion-label class="ion-text-center fnt-12"  [style.max-width]="'300px'"> {{option.option_value}}</ion-label> 
              </div>
              <div class="attribute-cart" *ngIf="option.iscart != 0 && false" > <ion-icon src="/assets/icon/unique-cart.svg"></ion-icon> <ion-badge >{{option.iscart}}</ion-badge></div>
            </ion-item>
          </ion-radio-group>
      </div>

      <!-- colors -->
      <div class="colors d__flex" *ngIf="item.control_type == 'Color Boxes'">
         <div  *ngFor="let option of item.options" class="boxs mouse" [class.select]="option.is_pre_selected && option.is_pre_selected == 1" (click)="select(item.attribute,option.name)" >
           <div class="tooltip"  [style.background]="option.attribute_color" >
            <span class="tooltiptext">{{option.option_value}}</span>
           </div>
         </div>
        
      </div>

      <!-- Check Box -->
      <!-- <div *ngIf="item.control_type == 'Check Box List'">

      </div> -->
      <!-- Table -->
      <!-- <div *ngIf="item.control_type == 'Table'">
        
      </div> -->

    </div>
  </div>