import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { RegisterPage } from 'src/app/pages/register/register.page';
import { DbService } from 'src/app/services/db.service';

import { IonGrid, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss'],
})
export class GridViewComponent implements OnInit {
  // @ViewChild('scrollContent') scroll: ElementRef;
  @ViewChild('scrollContent') scroll!:ElementRef;
  // @Input() row;
  @Input() data;
  @Input() type;
  @Input() arrow;
  @Input() background_tile;
  @Input() background_image;
  @Input() category_p;
  @Input() search;
  @Input() viewall;
  @Output() add_wish = new EventEmitter();
  @Output() remove_wish = new EventEmitter();
  @Output() addto_cart = new EventEmitter();
  @Output() removeto_cart = new EventEmitter();
  @Input() scrollable;

  @Input() img;
  @Input() position;
  // @Input() l_horizontal_image;
  // @Input() r_horizontal_image;
  @Input() bg_horizontal_image;
 

   l_horizontal_image;
   r_horizontal_image;
  // ismobile;
  modal_popup = true;
  left_arrow = false;
  constructor(private mc:ModalController,private router:Router,public db:DbService,private modalCtrl:ModalController) {  }

  ngOnInit() {

    // console.log('dataaaaa========',this.data)
    this.db.ismobile = this.db.checkmobile();

    (this.position && this.position != '') ? this.position.toLowerCase() : null;

    if(this.db.ismobile){
      this.l_horizontal_image = this.img;
      // console.log('image',this.img)
    }else{
     
      if(this.position == 'right'){
        this.r_horizontal_image = this.img;
         } else  if(this.position == 'left'){
          this.l_horizontal_image = this.img;
        }else if((this.position == '' )||(this.position != 'right') || (this.position != 'left')){
        this.l_horizontal_image = this.img;
        }

    }
     
  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.db.ismobile = this.db.checkmobile();
  }

  img_style(data, type) {
    if (type == 'color') {
      return { 'background': data };
    }else if (type == 'img') {
      return { 'background': 'url(' + this.db.product_img(data) + ')center center / 100% auto no-repeat', 'background-size': 'contain' };
    }else if (type == 'colorcode') {
      return { 'color': data };
    }else if (type == 'bgcolor') {
      return { 'background': data, 'color': data };
    } else if (type == 'full-bg-img') {
      return { 'background': 'url(' + this.db.product_img(data) + ') no-repeat', 'background-size': '230px', 'background-position-y': 'center'};
    } else if (type == 'bg-image') {
      return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat'}
    } else if (type == 'About-bg-image') {
      return { 'background' : 'url('+ this.db.product_img(data) +') no-repeat',"background-attachment": "fixed","background-position": "center","background-size": "cover"}
    }
  }

 async addtowish(data, count) {
    data.wish_count  = count;
    if(localStorage['customerRefId']){
      data.product_attributes && data.product_attributes.length != 0 ?  this.router.navigateByUrl('/pr/'+data.route) : this.add_wish.emit(data);
    } else {
        if (this.modal_popup && !this.db.cust_name){
          this.modal_popup = false;
          const modal = await this.modalCtrl.create({
            component: RegisterPage,
            cssClass: 'auth-popup',
            componentProps: {
              type: 'login'
            }
          })
          this.db.opened_login_modal = true;
          await modal.present();
          await modal.onWillDismiss().then( res =>{
            this.modal_popup = true;
            this.add_wish.emit(data);
           });
        }
    } 
  }
  

  removewish(data,count) {
      data.wish_count  = count;
     this.remove_wish.emit(data);
  }



  emitScroll(scroll, direction,img) {
    if(img){
      if (direction == 'right') {
        this.scroll.nativeElement.scrollLeft += 620;
       } else if (direction == 'left') {
      this.scroll.nativeElement.scrollLeft -= 620;
       }
    } else {
      if (direction == 'right') {
        this.scroll.nativeElement.scrollLeft += 602;
       } else if (direction == 'left') {
      this.scroll.nativeElement.scrollLeft -= 602;
       }
    }
    
  }

  scrolled(event){
    if(event.target.scrollLeft != 0){
      this.left_arrow = true;
    }else if(event.target.scrollLeft == 0){
      this.left_arrow = false;
    }
  } 


}
