import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-search-products',
  templateUrl: './search-products.component.html',
  styleUrls: ['./search-products.component.scss'],
})
export class SearchProductsComponent implements OnInit {
  search_down = false;
  product_shop = false;
  @Input() keyword;
  active_dropdwon = 0;
  filter:any = 'Product'
  search_value = true;
  search_Shop = false;
  show_category;

  @Input() search_page;
  @ViewChild('search_dropdown') search_dropdown !:ElementRef;
  constructor(public db:DbService,public router:Router) { }

  ngOnInit() {
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event) { 
    if(this.search_down && this.db.search_productss.length != 0 ){
      if(event.key == 'ArrowDown'){ 
        (this.db.search_productss.length - 1) >  this.active_dropdwon ?  this.active_dropdwon += 1 : null
      }else if(event.key == 'ArrowUp') {
        this.active_dropdwon != 0 ?  this.active_dropdwon -= 1 : null
      }else if( event.key == 'Enter'){

      }

         let val = document.getElementById(this.active_dropdwon+'s') as HTMLInputElement
         val.scrollIntoView({ behavior: "smooth", block: "start",  inline: "nearest" });
      
    }else if(this.search_down && this.db.search_shop.length !=0 ){
      if(event.key == 'ArrowDown'){ 
        (this.db.search_shop.length - 1) >  this.active_dropdwon ?  this.active_dropdwon += 1 : null
      }else if(event.key == 'ArrowUp') {
        this.active_dropdwon != 0 ?  this.active_dropdwon -= 1 : null
      }else if( event.key == 'Enter'){

      }
      let  val = document.getElementById(this.active_dropdwon+'s') as HTMLInputElement
      val.scrollIntoView({ behavior: "smooth", block: "start",  inline: "nearest" });   
    }

  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.search_dropdown && !this.db.ismobile  && !this.search_dropdown.nativeElement.contains(event.target) ? (this.db.search_productss = [] , this.keyword ='',this.search_value = false,this.show_category = false) : null      
  } 
  filter_fun(val){
    this.filter = val
  }

  icon_searchproduct(d){
    if (this.keyword != '' && this.keyword != undefined) {
      this.search_value = false;
      this.router.navigateByUrl('/search/' + this.keyword);
    }
  }

  icon_searchshop(d){
    if (this.keyword != '' && this.keyword != undefined) {
      this.db.shop_list = this.db.search_shop
      this.search_Shop = false;
      this.router.navigateByUrl('/shop-list/' + this.keyword);
    }
  }


  searchproduct(d) {
    this.search_value = true;
    if (this.keyword != '' && this.keyword != undefined) {
       !this.search_page ? (this.db.searchproducts(this.keyword),this.active_dropdwon = 0) : null
    }else
      this.db.search_productss = [];
  }

  search_shops(d) {
    this.search_Shop = true;
    if (this.keyword != '' && this.keyword != undefined) {
       !this.search_page ? (this.db.searchshops(this.keyword),this.active_dropdwon = 0) : null
    }else
      this.db.search_shop = [];
  }


}
