import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';


import { CookieService } from 'ngx-cookie-service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-new-signup',
  templateUrl: './new-signup.component.html',
  styleUrls: ['./new-signup.component.scss'],
})
export class NewSignupComponent implements OnInit {
  shake = false; //Focus styling
  alert_message;
  show_pass= true;
  register_data :any= {};
  submitted = false;
  labels = true ;
  focus :any= {};
  verified_number = false;
  register_form:any = FormGroup;
  @Input() affiliated;
  @Output() scrollToTop = new EventEmitter();
  constructor(private formBuilder:FormBuilder,public db:DbService,private loadingCtrl:LoadingController,private cookie:CookieService,private router:Router) { }

  ngOnInit() {
    this.db.affiliated_status = false;
    localStorage['otp_mobile'] && localStorage['otp_mobile'] != undefined && localStorage['otp_mobile'] != 'undefined' ? (this.register_data.phone = localStorage['otp_mobile'], this.verified_number = true) : (this.verified_number = false)
    
    localStorage['referer'] && localStorage['referer'] != null && localStorage['referer'] != 'undefined' ? (this.register_data.referral = localStorage['referer']) : null
    // console.log(localStorage.referer)

    this.db.checkmobile();
    if(!this.affiliated){
      this.register_form = this.formBuilder.group({
        first_name : new FormControl('',[Validators.required]),
        last_name :new FormControl(''),
        email : new FormControl('',[Validators.required,Validators.email]),
        pwd : new FormControl('',[Validators.required]),
        referral : new FormControl(''),
        phone: new FormControl('',[Validators.required,Validators.pattern("^((\\+91-?) |0)?[0-9]{10}$")])
      }) 
    }else{
      this.register_form = this.formBuilder.group({
        first_name : new FormControl('',[Validators.required]),
        last_name :new FormControl(''),
        email : new FormControl('',[Validators.required,Validators.email]),
        pwd : new FormControl('',[Validators.required]),
        // referral : new FormControl(''),
        website : new FormControl('',[Validators.required]),
        promote_us : new FormControl('',[Validators.required]),
        about_us : new FormControl('')
        // phone: new FormControl('',[Validators.required,Validators.pattern("^((\\+91-?) |0)?[0-9]{10}$")])
      }) 
    }
    
    
  }

  get first_name(){
    return this.register_form.get('first_name')
  }

  get last_name(){
    return this.register_form.get('last_name')
  }

  get email(){
    return this.register_form.get('email')
  }

  get pwd(){
    return this.register_form.get('pwd')
  }

  get phone(){
    return this.register_form.get('phone')
  }

  get website(){
    return this.register_form.get('website')
  }
  
  get promote_us(){
    return this.register_form.get('promote_us')
  }
  get about_us(){
    return this.register_form.get('about_us')
  }
  
  async register() {
    // console.log(this.register_form)
    this.submitted = true;
    this.register_data =  this.register_form.value;
    this.register_data.domain = this.db.domain;
    // this.register_form.get('referral').value
    
    this.db.website_settings.enable_referral == 1 ? this.register_data.referral_code = this.cookie.get('refer') : null
    if(this.register_form.status == 'VALID'){
      let loader = await this.loadingCtrl.create({ message: 'Please Wait...'});
      await loader.present();
      this.db.register(this.register_data).subscribe(res => {
        setTimeout(() => { loader.dismiss(); }, 50);
        if(res.message.status != 'failed'){
          this.db.customer_Info({user:res.message.customer.name}).subscribe(res => {
            // console.log(res);
              this.alert_message =''
              localStorage['otp_mobile'] = undefined;
              this.db.store_customer_info(res);
              this.db.sass_login(this.register_data.email,this.register_data.pwd);
              // this.db.get_cart_item();
              loader.dismiss();
              this.db.dismiss()
              // !this.db.ismobile && (location.pathname !='/register') ? this.modalCtrl.dismiss() : this.router.navigateByUrl('/tabs/home') 
            }, error => { console.log(JSON.stringify(error)); });
        }else{
          this.shake = true;
          var b = JSON.parse(res._server_messages);
          var b1 = JSON.parse(b);
          this.alert_message = b1.message
          setTimeout( () => { this.shake = false },400)
          // this.db.alert(b1.message)
        }
      },err=>{ console.log(err)})
    }else{
      this.shake = true;
      setTimeout( () => { this.shake = false },400)
    }
  }

  affiliated_register(){
    // console.log(this.register_form)
    this.submitted = true;
    // this.register_data =  this.register_form.value;
    let  data = {
      "first_name" : this.register_form.value.first_name,
      "last_name" :this.register_form.value.last_name,
      "email": this.register_form.value.email,
      "new_password": this.register_form.value.pwd,
      "website": this.register_form.value.website,
      "promote_us": this.register_form.value.promote,
      "hear_about_us": this.register_form.value.about_us
     }

    if(this.register_form.status == 'VALID'){
      this.db.insert_affiliate_registration(data).subscribe(res => {
        if(res.message.status == 'Success'){
          // setTimeout( () => { this.db.alert("Successfully Registered"); },400)
            // this.router.navigateByUrl('/')
            this.register_form.reset();
            this.db.affiliated = true;
            this.scrollToTop.emit(); 
            this.db.affiliated_status = true;
        }else if(res.message.status == 'Failed'){
          this.shake = true;
          this.alert_message = res.message.message
          // var b = JSON.parse(res._server_messages);
          // var b1 = JSON.parse(b);
          // this.alert_message = b1.message
          setTimeout( () => { this.shake = false },400)
          // this.db.alert(b1.message)
        }
      },err=>{ console.log(err)})
    }else{
      this.shake = true;
      setTimeout( () => { this.shake = false },400)
    }
  }


} 
