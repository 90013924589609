<ion-header translucent="true" *ngIf="db.ismobile && !db.otp_form">
  <ion-toolbar>
    <ion-back-button slot="start" defaultHref="/tabs/home" (click)="db.opened_login_modal?modalCtrl.dismiss():''"></ion-back-button>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="!db.guest_form" [ngClass]="!db.ismobile?'main-container':''">
    <ion-icon *ngIf="!db.ismobile" name="close-outline" class="close mouse" (click)="db.otp_form = false" (click)="modalCtrl.dismiss()"></ion-icon>
    <div *ngIf="!db.ismobile" class="image-container" [ngStyle]="db.img_style(db.website_settings.login_image,'img')"> </div>
    <div class="form-container">
      <div *ngIf="!db.otp_form">
        <div *ngIf="db.registerform">
          <app-registe-r></app-registe-r>
        </div>
        <div *ngIf="!db.registerform">
          <app-log-in></app-log-in>
        </div>
      </div>
      <div *ngIf="db.otp_form">
        <app-forget></app-forget>
      </div>
    </div>
  </div>
  <div *ngIf="db.guest_form">
    <app-guest-login></app-guest-login>
  </div>
</ion-content>
