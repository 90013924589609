

<div class="return_resons">
   
  <div class="heading">
    <div class="heading_one">
      <h6 class="title">Return Request</h6>
     <div class="mouse" (click)="modalCtrl.dismiss()">
      <ion-icon name="close-outline"></ion-icon>
    </div>
    </div>
  </div>

<div class="form p-10">

<form [formGroup]="return_reason">
  
 <div>
  
  <!-- <h2 class="h2 m-0">Return Info</h2> -->
  
  <div class="input-item">
    <ion-label color="grey" class="input__title" position="stacked">Return Reason</ion-label>
    <div lines="none" class="custom-input" [class.focused]="focus.reason">
      <select name="reason" formControlName="reason"  (ionBlur)="focus.reason = false" class="form-input form-select" interface="popover" (ionChange)="value_changed_reason($event)" [value]="return_request_reason">
        <option *ngFor="let c of return_details.return_request_reason" value="{{c.name}}">{{c.name}}</option>
      </select>
    </div>
    <div *ngIf="submitted && !reason?.valid"> 
      <p class="error m-0" *ngIf="reason.errors.required">Return reason is required</p> 
    </div>
  </div>

  <!-- <div class="input-item">
    <ion-label color="grey" class="input__title" position="stacked">Return Action </ion-label>
    <div lines="none" class="custom-input" [class.focused]="focus.return_action">
      <select name="return_action" formControlName="return_action" (ionFocus)="focus.return_action = true" (ionBlur)="focus.return_action = false" class="form-input form-select" interface="popover" (ionChange)="value_changed($event)" [value]="return_request_action">
        <option *ngFor="let c of return_details.return_request_action" [value]="c.name">{{c.name}}</option>
      </select>
    </div>
    <div *ngIf="submitted && !return_action?.valid"> 
      <p class="error m-0" *ngIf="return_action.errors.required">State is required</p> 
    </div>
  </div> -->

  <div class="input-item" >
    <ion-label color="grey" class="input__title" position="stacked">Comments </ion-label>
    <div lines="none" class="custom-input" [class.focused]="focus.comments">
      <!-- <ion-input placeholder="Enter your Message..,"   name="subject"  autofocus="true" autocomplete="false" required="true" formControlName="subject"></ion-input> -->
      <ion-textarea placeholder="Enter your Comments..," class="form-input" (ionFocus)="focus.comments = true" (ionBlur)="focus.message = false" formControlName="comments"></ion-textarea>
    </div>
  </div>
</div> 

<div *ngIf="bankinfo && db.website_settings.collecting_the_bank_details != 0">   
  <h2 class="h2 m-0">Bank Info</h2>

  <div class="input-item row">
    <label  class="input__title">Bank Name</label>
    <div class="custom-input" [ngClass]="focus.foucs5?'focused':''">
      <ion-input (ionFocus)="focus.foucs5 = true" (ionBlur)="focus.foucs5 = false" type="text" placeholder="Bank Name" name="bank_name" formControlName="bank_name"></ion-input>
    </div>

    <div *ngIf="submitted && !bank_name?.valid"> 
      <p class="error m-0" *ngIf="bank_name.errors.required">Bank Name is required</p> 
    </div>

  </div>

  <div class="input-item row">
    <label  class="input__title">Account Holder Name</label>
    <div class="custom-input" [ngClass]="focus.foucs7?'focused':''">
      <ion-input (ionFocus)="focus.foucs7 = true" (ionBlur)="focus.foucs7 = false" type="text" placeholder="Account Holder Name" name="account_holder_name" formControlName="account_holder_name"></ion-input>
    </div>

    <div *ngIf="submitted && !account_holder_name?.valid"> 
      <p class="error m-0" *ngIf="account_holder_name.errors.required">Account Holder Name is required</p> 
    </div>

  </div>

  
  <div class="input-item row">
    <label  class="input__title">Account No</label>
    <div class="custom-input" [ngClass]="focus.foucs6?'focused':''">
      <ion-input (ionFocus)="focus.foucs6 = true" (ionBlur)="focus.foucs6 = false" type="tele" placeholder="Account No" name="account_no" formControlName="account_no"></ion-input>
    </div>

    <div *ngIf="submitted && !account_no?.valid"> 
      <p class="error m-0" *ngIf="account_no.errors.required">Account No is required</p> 
    </div>

  </div>
  
  <div class="input-item row">
    <label  class="input__title">Confirm Account No</label>
    <div class="custom-input" [ngClass]="focus.foucs9?'focused':''">
      <ion-input (ionFocus)="focus.foucs9 = true" (ionBlur)="focus.foucs9 = false" type="tele" placeholder="Confirm Account No" name="confirm_ac_no" formControlName="confirm_ac_no"></ion-input>
    </div>

    <div *ngIf="submitted && !confirm_ac_no?.valid"> 
      <p class="error m-0" *ngIf="confirm_ac_no.errors.required">Confirm Account No is required</p> 
    </div>

  </div>

  <div class="input-item">
    <label  class="input__title">IFSC Code</label>
    <div class="custom-input" [ngClass]="focus.foucs8?'focused':''">
      <ion-input (ionFocus)="focus.foucs8 = true" (ionBlur)="focus.foucs8 = false" type="text" placeholder="IFSC Code" name="ifsc_no" formControlName="ifsc_no"></ion-input>
    </div>

    <div *ngIf="submitted && !ifsc_no?.valid"> 
      <p class="error m-0" *ngIf="ifsc_no.errors.required">IFSC Code is required</p> 
    </div>

  </div>

</div>

</form>

<div class="d__flex">
  <span  (click)="chech_box()"><ion-checkbox ></ion-checkbox></span>
  <p class="m-0 agree"> By filling this form, I agree to <a target="_blank" href="/terms/terms-condition" class="term mouse">Terms & Conditions</a></p>
</div>
 

 <div class="log-button ion-text-center">
   <ion-button   class="action-button" [ngClass]="shake?'shaking':''" expand="full" fill="clear" (click)="submit()">Submit</ion-button>
   <p class="m-0 error" *ngIf="alert_message">{{alert_message}}</p>
 </div>


</div>

</div>