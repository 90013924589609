import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pincode-popup',
  templateUrl: './pincode-popup.page.html',
  styleUrls: ['./pincode-popup.page.scss'],
})
export class PincodePopupPage implements OnInit {
  
  @Input() data;

  constructor(public db :DbService,public modalCtrl:ModalController,public router:Router) { }

  ngOnInit() {
    // console.log(this.data)
  }

  
  selected_pin(item){
    // this.selected_pincode = item.zipcode;
    // this.selected_pincode = item.zipcode;
    localStorage['zipcode']  = item.zipcode;
    // localStorage.
    // localStorage.pincode_area = item.area;
    // console.log(item)
    this.db.location = item.area +","+item.city+"-"+item.zipcode
    this.db.zipcode_availbility(item.zipcode,item);
    this.modalCtrl.dismiss();
    this.router.navigateByUrl('/p/ecomerce-builder');
  }

  choose(){
    this.modalCtrl.dismiss();
  }

}
