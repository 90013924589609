import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { PincodePopupPage } from 'src/app/pages/pincode-popup/pincode-popup.page';
import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-location-popup',
  templateUrl: './location-popup.component.html',
  styleUrls: ['./location-popup.component.scss'],
})
export class LocationPopupComponent implements OnInit {
  myform;
  autocompleteInput:any;
  pincodes:any=[];
  // selected_city;
  selected_pincode;

  @ViewChild('addresstext') addresstext!: ElementRef;
  constructor(public db:DbService,public modalCtrl:ModalController,public router:Router,private ngzone:NgZone) { 
    this.db.scroll_event.desktop_header  = false;    
    this.selected_pincode = localStorage['zipcode'] ? localStorage['zipcode'] : '';
  }


  ngOnInit() {}


  ngAfterViewInit() {
      this.db.getPlaceAutocomplete(this.addresstext.nativeElement,'')
  }

  
  // getPlaceAutocomplete() {
  //   const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
  //       {
  //         componentRestrictions: { country: 'IN' },
  //         types: ['establishment']  // 'establishment' / 'address' / 'geocode'
  //       });

  //       // console.log(autocomplete)
  //       google.maps.event.addListener(autocomplete, 'place_changed', (res) => {
  //         // console.log(res)
  //           const place = autocomplete.getPlace();
  //           // console.log(place);
  //           this.db.validate_location(place)
  //       });
  //    let  GoogleAutocomplete = new google.maps.places.AutocompleteService();
  //       GoogleAutocomplete.getPlacePredictions({ input: this.autocompleteInput,componentRestrictions: {country: 'IN'}  },
  //       (predictions, status) => {
  //         this.ngzone.run(() => {
  //           predictions.forEach((prediction) => {
  //             //  console.log(prediction)
  //            //this.autocompleteItems.push(prediction);
  //           });
  //         });
  //     });  
  //  }



   zip_code(zipcode){
    if(localStorage['zipcode'] && localStorage['zipcode'] == zipcode ){
        return true
    } 
  }

  city(city){ 
        return true; 
  }

  currentlocation(){
    // this.db.locationpopup = false;
    this.db.location = undefined;
    // (fetch_location)=""
    // this.fetch_location.emit();
    
    this.db.current_locate('');
  }


  
  // bread_crumb
  bread_crumb(name){
    this.pincodes = [];
  }



  selected_cities(item){
      // this.db.location_info.state = item.state
      // this.selected_city =  item.name;
      localStorage.removeItem('zipcode');
      localStorage.removeItem('setlocation');
      this.db.location ='';
      this.db.choose_multistore_location(item);
      this.check_pincode(item);
  }



  check_pincode(item){
    // console.log(item)
    // localStorage.state = item.restaurant_name
    this.pincodes = [];

    this.db.website_settings.location_areas.map((r:any) =>{
       if(r.city == item.city){
        r.info = item
        this.pincodes.push(r);
       }
    })

    if(this.pincodes.length == 0){

    }
    // console.log(this.pincodes);
    this.db.ismobile ? this.open_pincode_popup(this.pincodes) : '';
  }



  selected_pin(item){
    console.log(item)
    localStorage['zipcode']  = item.zipcode;
    // localStorage.pincode_area = item.area;
    // this.db.location = localStorage.pincode_area + '-' + localStorage.zipcode;
    this.db.location = item.area +","+item.city+"-"+item.zipcode
    this.db.zipcode_availbility(item.zipcode,item);
    // this.modalCtrl.dismiss();
    this.db.location_popup = false
    this.db.ismobile ?  this.router.navigateByUrl('/tabs/home') : null
  }


  async open_pincode_popup(pincode){
      const modal = await this.modalCtrl.create({
        component : PincodePopupPage,
        cssClass: "pincode-popup",
        componentProps: {    data : pincode },
      })
      // this.db.modal_stacks.push('location-modal');
      await modal.present()
      await modal.onWillDismiss().then(res =>{
        // this.db.modal_stacks = this.db.modal_stacks.filter(item => item !== 'location-modal')
      })
  }

  navigation(){
    this.db.available_location  ? this.router.navigateByUrl('/unavilable-location') :this.router.navigateByUrl('/tabs/home')
  }

}
