import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

// declare const google :any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit,AfterViewInit,OnDestroy {
  // @Input() lat;
  // @Input() lng;
  map:any;
  @Input() height;
  @Input() get_map_fields;
  @ViewChild('mapElement') mapElement!:ElementRef;
  @Output() current = new EventEmitter();
  @Output() get_map_values = new EventEmitter();
  constructor(private db:DbService,private geo:Geolocation) { 
     
  }

  ngOnInit() {
       this.db.located.subscribe(res =>{
         if(res == 'located'){
          this.ngAfterViewInit();
          // console.log(this.db.location_info)
         }
       })

       this.sub = this.db.load_map.subscribe(res=>{
        // console.log(res);
        if(res == 'get'){
          // this.ngAfterViewInit();
          // console.log(this.db.location_info)
         }
       })
  }

  sub:any;
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


  // async current_locate() {
  //   this.geo.getCurrentPosition().then((resp) => {
  //       this.db.latitude = resp.coords.latitude //13.0381896 //
  //       this.db.longitude = resp.coords.longitude// 80.1565461 //
  //       console.log(resp)
  //       this.ngAfterViewInit();
  //       let locations = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
  //       this.db.decode(locations)
  //    }).catch((error) => {
  //      //  this.db.alert_animate.next('Please enable location..,');
  //        console.log('Error getting location', error);
  //       //  this.ismobile ? this.router.navigateByUrl('/location') : null
  //         !this.db.location ? this.db.locationpopup = true :null
  //   });
  // }


  ngAfterViewInit() {
    // this.db.current_locate();
        // this.lat =  13.0514944 // this.db.latitude
        // this.lng =  80.2062336  //this.db.longitude
        // console.log("maploading",this.db.location_info)
        // console.log(this.db.location_info)

        if(typeof(this.db.location_info.latitude == 'string')){
          this.db.location_info.latitude = Number(this.db.location_info.latitude);
        }

        if(typeof(this.db.location_info.longitude == 'string')){
          this.db.location_info.longitude = Number(this.db.location_info.longitude);
        }
        
        this.map = new google.maps.Map(this.mapElement.nativeElement, 
          { 
             zoom: 18,
             center: { lat: this.db.location_info.latitude ,lng :this.db.location_info.longitude },
            // center: { lat: this.db.map_register_info.latitude ? this.db.map_register_info.latitude : this.db.location_info.latitude ,lng :this.db.map_register_info.longitude ? this.db.map_register_info.longitude : this.db.location_info.longitude },
             disableDefaultUI: true,
             clickableIcons: false ,
             draggable:true
        });

        // The marker, positioned at Uluru
        const marker = new google.maps.Marker({
          position: { lat: this.db.location_info.latitude ,lng :this.db.location_info.longitude},
          // position: { lat: this.db.map_register_info.latitude ? this.db.map_register_info.latitude : this.db.location_info.latitude ,lng :this.db.map_register_info.longitude ? this.db.map_register_info.longitude : this.db.location_info.longitude },
          map: this.map,
          draggable: false,
          visible:false
        });

        this.map.addListener('center_changed', () => {
          let center = this.map.getCenter();
          marker.setPosition(center);
          this.get_map_fields ? this.db.decode(center,'get_map_fields') :  this.db.decode(center,'');
          // this.geocode_location(center);
        });

      // this.map.addListener("click", (event: google.maps.MapMouseEvent) => {
      //   this.addMarker(event.latLng!);
      // });

      // this.geocoder = new google.maps.Geocoder;
      // if(check_geocode) {
      //   this.geocode_location(latlng)
      // }
  }



  // addMarker(position: google.maps.LatLng | google.maps.LatLngLiteral) {
  //     const marker = new google.maps.Marker({
  //       position,
  //       map :this.map,
  //       // icon: '/assets/icon/location.svg',
  //     });

  //     // marker.setMap(null);

  //     // console.log(marker)
  //     var lat = marker.getPosition().lat();
  //     var lng = marker.getPosition().lng();
  //     // console.log(lat)
  //     // console.log(lng)
  // }

  // addmk(event:google.maps.MapMouseEvent){
  //    this.addMarker(event.latLng!);
  // }

}
