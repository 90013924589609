import {
  Component,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss','../orders/orders.component.scss'],
})
export class DashBoardComponent implements OnInit {
  constructor(public db:DbService) { }

  ngOnInit() {
    this.get_customer_dashboard()
  }
  get_customer_dashboard(){
    let data = {customer_id : localStorage['customerRefId']}
      this.db.get_customer_dashboard(data).subscribe(res =>{
          // console.log(res)
       res.message.status == 'success' ?  this.db.dashboard_info = res.message.data : null
      })
  }
}
