import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() brands:any;
  @Output() filter =  new EventEmitter();
  ismobile;
  @ViewChild('brands_container',{ read: ElementRef })  brands_container!:ElementRef;
  @ViewChild('attributes_container',{read :ElementRef}) attributes_container!:ElementRef;

  
  filters_values:any;

  constructor(public db:DbService,private router:Router,private route:ActivatedRoute) { }

  ngOnInit() {

    this.ismobile = this.db.checkmobile();

    this.sub = this.db.remove_brands.subscribe(res=>{

      if(res && res == 'get'){
        let value = JSON.stringify(this.db.stored_filters)
        this.brands = JSON.parse(value);
        this.db.category_filters = this.brands;
      }
    })

    // console.log('brands',this.brands)
    setTimeout(() =>{
      // let c = document.getElementById('brands-container').offsetHeight
      // console.log(c)
      // console.log(this.brands_container.nativeElement);
      // console.log(this.attributes_container.nativeElement.getBoundingClientRect())
    },1000)

  }

  sub:any;
  ngOnDestroy(): void {
   this.sub.unsubscribe();
  }

  ngOnChanges(changes) {
    // console.log("changes maded...",changes)
  }

  ionViewDidEnter(){
     let value = JSON.stringify(this.brands)
     this.filters_values = JSON.parse(value);

    // this.sub = this.db.remove_brands.subscribe(res=>{
    //   console.log(res)
    //   if(res && res == 'get'){
    //     let value = JSON.stringify(this.filters_values)
    //     this.brands = JSON.parse(value);
    //   }
    // })
  }
  
  @HostListener('window:resize', ['$event'])
  private func(){
    this.ismobile = this.db.checkmobile();
  }
  
  goforward(route){
    this.db.choosed_product_id = route
    this.router.navigate(['/category/'+route]);
  }

  choose_brands(brand){
      brand['checked'] = brand['checked'] ?  false : true;
      if(!this.db.choosed_brands.includes(brand.unique_name ? brand.unique_name : brand.unique_name)){
        this.db.choosed_brands.push(brand.unique_name ? brand.unique_name : brand.unique_name);
      }else{
          this.db.choosed_brands.map((element,index)=>{
            if(element == (brand.unique_name ? brand.unique_name : brand.unique_name)){
              this.db.choosed_brands.splice(index,1);  
            }
        });
      }

      // console.log(this.db.choosed_brands)
    if(this.db.choosed_brands.length != 0){
      this.router.navigate([location.pathname], { queryParams: { brands : this.db.choosed_brands.toString() } } );
    }else{
      this.router.navigate([location.pathname]);
    }

  }


choose_attributes(attributes:any,values:any){
  values['checked'] = values['checked'] ?  false : true;
  values['choosed'] = values['choosed'] ?  0 : 1;
  let value:any;
  let attribute_uniques:any = [];
  // console.log(attributes)
      attributes.options.map(v =>{
        // if(v.choosed == 1 ){
        if(v.checked){
          attribute_uniques.push(v.unique_name ? v.unique_name : v.option_value);
        }
      })

      // value = { "attribute": attributes.attribute_unique_name,"value": attribute_uniques.toString() }; .toString()
      value = { "attribute": attributes.attribute_unique_name ? attributes.attribute_unique_name : attributes.attribute, "value": attribute_uniques };

      if(this.db.choosed_attributes.length == 0){
          this.db.choosed_attributes.push(value);
      }else{
        this.db.choosed_attributes.find((res,index)=>{
          if(res && res.attribute == (attributes.attribute_unique_name ? attributes.attribute_unique_name : attributes.attribute)){ 
            this.db.choosed_attributes.splice(index,1)
          }else if( res = undefined){
            this.db.choosed_attributes.splice(index,1)
          }
        })
        
        if(attribute_uniques.length != 0){
          this.db.choosed_attributes.push(value)
        }
      }

      // console.log(this.db.choosed_attributes);
      // this.router.navigate(['/category/'+this.db.choosed_product_id], { queryParams: this.db.choosed_attributes ,queryParamsHandling: 'merge' });
      this.filter.emit();
      // console.log(this.db.choosed_attributes)
 
  }

  stars =[
    {
      icon :[],
      value : "",
      title: 'All'
    },
    {
      icon: ['star','star-outline','star-outline','star-outline'],
      value : 1
    },
    {
      icon: ['star','star','star-outline','star-outline'],
      value : 2
    },
    {
      icon: ['star','star','star','star-outline'],
      value : 3
    },
    {
      icon: ['star','star','star','star'],
      value : 4
    }

  ]
}