import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
// import { Console } from 'console';

import { DbService } from 'src/app/services/db.service';



@Component({
  selector: 'app-normal-attribute',
  templateUrl: './normal-attribute.component.html',
  styleUrls: ['./normal-attribute.component.scss'],
})
export class NormalAttributeComponent implements OnInit {
  @Input() product_detail;
  @Input() main_image;
  attribute_images : any;
  select_color;
  @Output() check_image = new EventEmitter();
  @Output() vendor_price_event = new EventEmitter();
  @Output() count = new EventEmitter();
  constructor(public db:DbService) { }

  ngOnInit() {
    // console.log(this.product_detail);
    this.db.other = false;
    this.db.pre_select = false;
  }
  
    //check active radio 
  check_active_radio(options){
    // console.log(options,this.product_detail.vendor_price_list);
    let temp = ''
      options != undefined ? options.map(res =>{
          if(res.is_pre_selected == 1){
              temp = res.name
          }
      }): ''
      return temp;
  }



  check_active_image(images){
    let temp = ''
    if(images.length != 0){
        images.map(res=>{
          if(res.is_primary == 1){        
            res.detail_image ?  temp = res.detail_image :  temp = res.product_image || res.image
          }
      })
    }
    return temp
  }

  select_dropdown(event,type){
    this.select(type,event.detail.value) 
  }

  select_radio(event,type){
    this.select(type,event.detail.value)
  }

  select(type,attribute){
      this.product_detail['product_attributes'].find(res => {
        if(res.attribute == type){
          res.options.map(d =>{ 
            if(d.name == attribute){
              d.is_pre_selected = 1
              if(d.images && d.images.length != 0 ){
                  this.product_detail.images = d.images;
                  // this.db.video_list = true;  
                  this.check_image.emit();
              }
              if(this.product_detail.vendor_price_list && this.product_detail.vendor_price_list.length != 0){
                this.db.vendor_price_list = this.product_detail.vendor_price_list.filter(res =>  d.name + '\n' == res.attribute_id);
              }
            }else{
              d.is_pre_selected = 0
            }
          })
        }
      })
      this.check_attribute_cart();
      // this.check_attribute_stock();
  }


  check_attribute_cart(){  
    let img_data = [];
    let ids = '';
    let selected_attribute ='';
    let selected_cart_id =''
    let selected_cart_count = ''
    this.product_detail['product_attributes'].find(res => {
      res.options.map(data =>{ 
          data.iscart = this.check_attribute(data.name+'\n');
        if(data.is_pre_selected == 1){   
          ids+=data.name+'\n';
          selected_cart_id = this.check_cart_id(ids);    
          selected_cart_count = data.iscart
          selected_attribute += ' <div class="attribute"><span class="attr-title">'+res.attribute+'</span> : <span>'+data.option_value+'</span> </div>'
          // this.product_detail['product_variant'].find(res => { 
          //   // console.log(JSON.parse(res.image_list))
          //   let img_data = [];
          //   // console.log(res.attribute_id ,"=", ids)
          //   // if(res.attribute_id == ids){
          //     img_data = JSON.parse(res.image_list);
          //     if(data.images && data.images.length == 0){
          //       this.product_detail.images = img_data;
          //     }
          //   // }
          // })
        } 
      })
    })
    this.product_detail.selected_cart_id = selected_cart_id;
    this.product_detail.ids = ids;
    this.product_detail.count = selected_cart_count;
    this.product_detail.vendor_price_list.map(res=>{
      if(res.attribute_id == this.product_detail.ids)
        this.vendor_price_event.emit(res)
          // this.vendor_price_event(res);
    })
    // this.vendor_price_event.emit(this.db.seller_price ? this.db.seller_price : this.product_detail.vendor_price_list[0])
    // this.count.emit(this.db.seller_price ? this.db.seller_price : this.product_detail.vendor_price_list[0])
    this.product_detail.product_attributes.length > 1 ? this.product_detail.count = this.check_attribute(this.product_detail.ids) : null
    // this.product_detail.vendor_price_list.length > 1 ? this.product_detail.count = this.seller_price(this.product_detail.ids) : null
    this.product_detail.selected_attribute = '<div class="cart-attributes">'+selected_attribute+'</div>'
    this.check_attribute_stock();

  //   this.product_detail['product_variant'].find(res => {    
  //     if(res.attribute_id == ids && res.image_list){
  //     img_data = JSON.parse(res.image_list);
  //     this.product_detail.images = img_data;
  //     // this.product_detail['product_attributes'].find(rec => {
  //     //   rec.options.map(data =>{  
  //     //     if(data.images && data.images.length == 0 ){
  //     //       img_data.map(d =>{
  //     //         if(d.is_primary == 1){
  //     //           console.log(d)
  //     //           this.product_detail.images = img_data;
  //     //           console.log(this.product_detail);
  //     //         }
  //     //       })
            
  //     //       // this.db.pre_select = true;
  //     //       this.check_image.emit();
  //     //     }
          
  //     //    })
  //     // })
  //     this.check_image.emit();
  //       if(res.video_list && res.video_list.length !=0){
  //         this.product_detail.product_video = res.video_list;
  //         res.video_list.map(rec =>{
  //             this.db.video_list = rec.youtube_video_id;
  //         })
  //       }
  //       if(res.product_title == ''){
  //         this.product_detail.item = this.product_detail.item_name ? this.product_detail.item_name : this.product_detail.item
  //       }else{
  //         this.product_detail.item_name =  this.product_detail.item_name ? this.product_detail.item_name : this.product_detail.item;
  //         this.product_detail.item = res.product_title;
  //       }
  //       // this.product_detail.
  //     }
  //  })
  }


 check_attribute_stock(){
    var data = {
      "product": this.product_detail.name,
      "attribute_id": this.product_detail.ids,
      "cart_qty": this.db.plusCount(this.product_detail.count),
      "variant_html" : '',
      'business': this.db.seller_price.business ? this.db.seller_price.business : this.product_detail.vendor_price_list[0].business,
      'centre': localStorage['CenterName'] && !localStorage['customerRefId'] ? localStorage['CenterName'] : '',
    }
    this.db.validate_attributes_stock(data).subscribe(res => {
      if(res.message){
        // this.product_detail.inventory_method = 'Track Inventory'
        if( res.message.status == "Success"){
          this.product_detail.stock = res.message.stock
          this.product_detail.price = res.message.price,
          this.product_detail.price == 0 ? this.db.seller_price.vendor_name ='': '';
          this.product_detail.old_price = res.message.old_price
          this.product_detail.discount_percentage = res.message.discount_percentage
          // this.product_detail.discount_label = res.message.discount.discount_label
          // this.product_detail.discount_rule = res.message.discount.discount_rule
          this.product_detail.stock_alert_message = undefined
          this.db.close_modal();
          // this.count.emit(this.product_detail.ids)
        }else if( res.message.status == 'Failed'){
          this.count.emit(this.product_detail.ids)
          this.db.seller_price.vendor_name ='';
          this.product_detail.stock = res.message.stock
          this.product_detail.price = res.message.price,
          this.product_detail.old_price = res.message.old_price
          this.product_detail.stock_alert_message = res.message.message
        }
      }
    })
  }

  seller_price(id){
    var cnt = 0;
    this.product_detail.vendor_price_list.find( (res:any) =>{
      if(res.attribute_id == id){
        cnt += res.quantity
      }
    })
    return cnt;
  }

  check_attribute(id){
    var cnt = 0;
    let array = [...this.db.marketplace_items,...this.db.fl_items];
    array.find( (res:any) =>{
      // console.log(id + " ==== " + res.attribute_ids)
      if(res.attribute_ids == id){
        cnt += res.quantity
      }
    })
    return cnt;
  }


  check_cart_id(id){
    let cart_id ='';
    let array = [...this.db.marketplace_items,...this.db.fl_items];
    array.find( (res:any) =>{
      if(res.attribute_ids == id){
        if(res.quantity != 0){
          cart_id = res.name
        }
      }
    })
    return cart_id;
  }


//   check_image(){
//     if(this.product_detail.images){
//       this.main_image =  this.check_active_image(this.product_detail.images)
//     }else{
//       this.main_image = this.product_detail.product_image
//     }
//  }

 selected_color(item){
  this.select_color = item.name
 }


}
