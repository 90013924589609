import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['../secondary-header/secondary-header.component.scss','../web-header/web-header.component.scss','./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit {
  drop_down2 = false;
  // @Output() openModal = new EventEmitter();
  @Output() cart_pop = new EventEmitter();
  @Input() web_header;

  constructor(public db: DbService) {
    
  } 

  ngOnInit() { 
    // console.log("Header",this.db.header_data);
  }
  
  _menu = [
    {
      title: 'My Profile',
      route: '/my-profile/dashboard'
    },
    {
      title: 'My Orders',
      route: '/my-profile/my-orders'
    },
  ]
}
